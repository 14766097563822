import { Col, UploadProps } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {
    BulkUploadContainer,
    CustomRow,
    DivDownloadTemplate,
    DivInformationTemplateFirstRow,
    FormUpload,
    UploadBtDiv,
    UploadedFileCard,
    UploadedFileCardInnerContainer,
} from 'views/custom-declaration/products/bulk-upload/ListBulkUploadFiles.styles';
import { InboxOutlined } from '@ant-design/icons';
import FileExcelOutlined from '@ant-design/icons/lib/icons/FileExcelOutlined';
import { TextExtraSmall } from 'components/ui/base/typography';
import Notification from 'components/ui/base/notification/Notification';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import { Button as AntButton, Typography } from 'antd';
import { colors } from 'theme';

const { Text: AntText } = Typography;

export type BulkUploadUploadFunc = (
    options: Parameters<NonNullable<UploadProps<any>['customRequest']>>[0]
) => any | Promise<any>;
export type BulkUploadDownloadFunc = () => Promise<{ preSignedUrl: string } | null>;

export enum FileType {
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
}

type Props = {
    disabled?: boolean;
    onUpload?: BulkUploadUploadFunc;
    onDownload?: BulkUploadDownloadFunc;
    onDelete?: () => void;
    hasDontOverrideFirstRowText?: boolean;
    withNotification?: boolean;
    fileType?: FileType[] | FileType;
    showFile?: boolean;
};

const BulkUpload = ({
    disabled = false,
    onDownload,
    onUpload,
    onDelete,
    hasDontOverrideFirstRowText = false,
    withNotification = true,
    fileType,
    showFile = false,
}: Props) => {
    const { t } = useTranslation('customs_declarations');

    const [file, setFile] = useState<File>();

    const handleUpload = async (options: Parameters<BulkUploadUploadFunc>[0]) => {
        const fileExt = (options.file as File).name.split('.').at(-1)?.toLowerCase();

        if (fileType) {
            if (
                // There is no file extension OR
                !fileExt ||
                // The file extension doesn't match the allowed file types OR
                (typeof fileType === 'string' && fileType.toLowerCase() !== fileExt) ||
                // The file extension doesn't match the allowed file types (Array)
                (Array.isArray(fileType) && !fileType.map((type) => type.toLowerCase()).includes(fileExt))
            ) {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.file-extension-error-message.title'),
                    description: t('bulk-upload.file-extension-error-message.description'),
                });
                return;
            }
        }

        const response = await onUpload?.(options);

        if (withNotification) {
            if (response) {
                Notification({
                    type: 'success',
                    messageTitle: t('bulk-upload.file-handle-success-message.title'),
                    description: t('bulk-upload.file-handle-success-message.description'),
                });

                setFile(options.file as File);
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.file-handle-error-message.title'),
                    description: t('bulk-upload.file-handle-error-message.description'),
                });
            }
        }
    };

    const handleDownload = async () => {
        const response = await onDownload?.();

        if (response) {
            window.open(response.preSignedUrl);
        }
    };

    const handleDelete = async () => {
        setFile(undefined);
        onDelete?.();
    };

    return (
        <BulkUploadContainer>
            <CustomRow className={`${disabled ? 'disabled' : ''}`}>
                <Col span={24}>
                    <FormUpload disabled={disabled}>
                        <Dragger
                            id="file-upl"
                            maxCount={1}
                            disabled={disabled}
                            customRequest={handleUpload}
                            showUploadList={false}
                            style={{ width: 400 }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ color: disabled ? colors.grey : colors.blueLink }} />
                            </p>
                            <p className="ant-upload-text" style={{ color: disabled ? colors.grey : 'initial' }}>
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or
                                other band files
                            </p>
                        </Dragger>
                    </FormUpload>
                </Col>
            </CustomRow>
            <DivDownloadTemplate disabled={disabled}>
                <AntText style={{ color: 'inherit' }} onClick={() => !disabled && handleDownload()} disabled={disabled}>
                    <FileExcelOutlined /> Download Spreadsheet Template link
                </AntText>
            </DivDownloadTemplate>
            {hasDontOverrideFirstRowText && (
                <DivInformationTemplateFirstRow>
                    <TextExtraSmall>
                        First row of template is for demonstration purposes and should not be overwritten
                    </TextExtraSmall>
                </DivInformationTemplateFirstRow>
            )}
            <UploadBtDiv>
                <Button
                    size="large"
                    type="primary"
                    disabled={disabled}
                    onClick={() => {
                        document.getElementById('file-upl')?.click();
                    }}
                >
                    Upload Spreadsheet File <FileExcelOutlined />
                </Button>
            </UploadBtDiv>
            {!disabled && showFile && file && (
                <UploadedFileCard size="small">
                    <UploadedFileCardInnerContainer>
                        <AntText ellipsis={true}>{file.name}</AntText>
                        <AntButton type="text" shape="circle" icon={<DeleteOutlined />} onClick={handleDelete} />
                    </UploadedFileCardInnerContainer>
                </UploadedFileCard>
            )}
        </BulkUploadContainer>
    );
};

export default BulkUpload;
