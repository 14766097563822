import { ProductTemplate } from '../../../store/products-templates/products';
import { removeEmptyObjectsFromDeclarationArrays } from '../../declarations/utils/form-utils';

export const handleAddDeclarationProductToProductTemplates = async (
    id: string,
    products: any,
    saveProductTemplateFunction: Function | undefined,
    declarationTemplateId: string | undefined,
    isCds: boolean | undefined
) => {
    if (!(products?.total && saveProductTemplateFunction)) throw new Error('No products');

    const declarationProducts = [...products?.list];
    let productToAddToTemplate = declarationProducts.find((product) => product.id === id);

    if (!productToAddToTemplate) throw new Error('No product with such id found');

    productToAddToTemplate = removeEmptyObjectsFromDeclarationArrays({ ...productToAddToTemplate });

    if (isCds) productToAddToTemplate = productToAddToTemplate.governmentAgencyGoodsItem;

    const productTemplate: ProductTemplate = {
        ...productToAddToTemplate,
        declarationTemplateId,
        tags: [],
    };

    await saveProductTemplateFunction(productTemplate);
};
