import Button from 'components/ui/base/button';
import { H5 } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, device } from 'theme';

export const DivDDFeatures = styled.div`
    color: ${colors.darkPurple};
    margin-top: 6.602rem;
`;

export const Subtitle = styled(H5)`
    justify-content: center;
`;

export const ContentDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 4.331rem;
    width: 100%;
`;

export const RowDiv = styled.div`
    width: 100%;
    height: 8.1rem;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 1.8rem;
    display: flex;
    flex-direction: row;
`;

export const Text = styled(H5)`
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    margin-left: 3.4rem;

    @media ${device.mobileL} {
        font-size: 1.6rem;
    }
`;

export const Image = styled.img`
    width: 4.3rem;
    height: 4.3rem;
`;
export const DivRtl = styled.div`
    direction: rtl;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 30%;
    margin-right: 6.3rem;
`;

export const ButtonDiv = styled.div`
    margin-top: 6.8rem;
`;

export const ContinueButton = styled(Button)`
    width: 30.7rem;
`;
