import { EditOutlined, MailOutlined } from '@ant-design/icons';
import Checkbox from 'components/ui/base/checkbox/Checkbox';
import { Text, TextBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const InfoDiv = styled.div`
    margin-bottom: 4.1rem;
`;

export const CheckboxDiv = styled.div`
    margin-bottom: 1rem;
`;

export const Label = styled(TextBold)`
    margin-right: 0.5rem;
`;

export const StyledCheckBox = styled(Checkbox)`
    margin-right: 0.8rem;
`;

export const EditIcon = styled(EditOutlined)`
    margin-left: 2.6rem;
    cursor: pointer;
    font-size: 2.2rem;
    &:hover {
        color: ${colors.purpleBlue};
    }
`;

export const MailIcon = styled(MailOutlined)`
    margin-left: 0.8rem;
`;

export const SaveIcon = styled(Text)`
    cursor: pointer;
`;
