import { ReactElement } from 'react';
import styled from 'styled-components';
import { HollowButton } from 'views/declarations/common/box44/Box44';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const ButtonsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

interface Props {
    onAdd: () => void;
    onRemove: () => void;
    canAdd?: boolean;
    canRemove?: boolean;
}

const MultipleButtons = ({ onAdd, onRemove, canAdd = true, canRemove }: Props): ReactElement => {
    return (
        <ButtonsContainer>
            {canRemove && <HollowButton shape="circle" onClick={onRemove} icon={<MinusOutlined />} />}
            {canAdd && <HollowButton shape="circle" onClick={onAdd} icon={<PlusOutlined />} />}
        </ButtonsContainer>
    );
};

export default MultipleButtons;
