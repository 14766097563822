import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { DeclarationHistory, DeclarationSortParameters } from 'store/declarations/declaration';
import { getDeclarationStyle } from 'views/custom-declaration/declaration-table/DeclarationColumns';
import { FlexDiv, SpanEllipsis } from 'views/custom-declaration/declaration-table/components/DeclarationsTable.styled';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import { Column } from './DeclarationHistoryTable';

const dmsCodeNotificationTypeMap: Record<string, string> = {
    '01': 'DMSACC',
    '02': 'DMSRCV',
    '03': 'DMSREJ',
    '05': 'DMSCTL',
    '06': 'DMSDOC',
    '07': 'DMSRES',
    '08': 'DMSROG',
    '09': 'DMSCLE',
    '10': 'DMSINV',
    '11': 'DMSREQ',
    '13': 'DMSTAX',
    '14': 'DMSCPI',
    '15': 'DMSCPR',
    '16': 'DMSEOG',
    '17': 'DMSEXT',
    '18': 'DMSGER',
    '50': 'DMSALV',
    '51': 'DMSQRY',
};

export const HistoryUtils = {
    columns: {
        statusHistory: (
            keys: Column,
            onShowErrors: ({ errors, showRawData }: { errors: Object; showRawData: boolean }) => void
        ): ColumnsType<any> => {
            const cols: ColumnsType<any> = [];
            if (keys.date) {
                cols.push({
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: (text: string, record: any) => {
                        const date = new Date((record && keys.date && record[keys.date]) ?? '');
                        const testId = 'date-column';
                        return record && keys.date && record[keys.date] ? (
                            {
                                children: (
                                    <FlexDiv data-testid={testId}>
                                        <span>{getHour(date)}</span>
                                        <span>{dateStandardFormat(date)}</span>
                                    </FlexDiv>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                    align: 'left',
                });
            }
            if (keys.user) {
                cols.push({
                    title: 'User',
                    dataIndex: 'user',
                    key: 'user',
                    render: (text: string, record: any) => {
                        const testId = 'user-column';
                        return record && keys.user && record[keys.user] ? (
                            {
                                children: <SpanEllipsis data-testid={testId}>{record[keys.user]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.action) {
                cols.push({
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text: string, record: any) => {
                        const testId = 'action-column';
                        return record && keys.action && record[keys.action] ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>{toTitleCase(record[keys.action])}</SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.notificationType) {
                cols.push({
                    title: 'Notification Type',
                    dataIndex: 'notification-type',
                    key: 'notification-type',
                    render: (text: string, record: any) => {
                        const testId = 'notification-type-column';
                        return record && keys.notificationType && record[keys.notificationType] ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        {HistoryUtils.Cds.dmsCodeToNotificationType(record[keys.notificationType])}
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.description) {
                cols.push({
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                    render: (text: string, record: any) => {
                        const testId = 'description-column';
                        return record && keys.description && record[keys.description] ? (
                            {
                                children: <SpanEllipsis data-testid={testId}>{record[keys.description]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.errors) {
                cols.push({
                    title: 'Errors',
                    dataIndex: 'errors',
                    key: 'errors',
                    render: (text: string, record: any) => {
                        const testId = 'errors-column';
                        return record && keys.errors && record.errors ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        <Button
                                            danger
                                            type="link"
                                            onClick={() => onShowErrors({ errors: record.errors, showRawData: false })}
                                        >
                                            View errors
                                        </Button>
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.rawMessage) {
                cols.push({
                    title: 'Raw Data',
                    dataIndex: 'rawMessage',
                    key: 'rawMessage',
                    render: (text: string, record: any) => {
                        const testId = 'raw-data-column';
                        return record && record.rawMessage ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        <Button
                                            danger
                                            type="link"
                                            onClick={() => onShowErrors({ errors: record.errors, showRawData: true })}
                                        >
                                            View raw data
                                        </Button>
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            return cols;
        },
        formHistory: [
            {
                title: 'Type',
                dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
                key: DeclarationSortParameters.MESSAGE_TYPE,
                render: (text: string, record: DeclarationHistory, index: number) => {
                    return record ? (
                        {
                            props: getDeclarationStyle(record),
                            children: <SpanEllipsis>{toTitleCase(record.type)}</SpanEllipsis>,
                        }
                    ) : (
                        <SpanEllipsis>-</SpanEllipsis>
                    );
                },
                ellipsis: true,
                align: 'left',
            },
            {
                title: 'Date',
                dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
                key: DeclarationSortParameters.MESSAGE_TYPE,
                render: (text: string, record: DeclarationHistory, index: number) => {
                    const date = record.createdAt ? new Date(record.createdAt) : null;
                    return date ? (
                        {
                            props: getDeclarationStyle(record),
                            children: (
                                <FlexDiv>
                                    <span>{getHour(date)}</span>
                                    <span>{dateStandardFormat(date)}</span>
                                </FlexDiv>
                            ),
                        }
                    ) : (
                        <SpanEllipsis>-</SpanEllipsis>
                    );
                },
                ellipsis: true,
            },
        ] as ColumnsType<DeclarationHistory>,
    },
    Cds: {
        dmsCodeToNotificationType(dmsCode: string): string | undefined {
            return dmsCodeNotificationTypeMap[dmsCode] ?? undefined;
        },
    },
};
