import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { Declaration } from 'store/declarations/declaration';
import { JobActions } from './actionType';
import { JobResponse } from './job';

interface JobEntities {
    [key: string]: JobResponse;
}
interface DeclarationsFromJobEntities {
    [key: string]: Declaration[];
}

export interface JobsState {
    isLoading: boolean;
    error?: ErrorResponse;
    jobs: ListPayload<JobResponse>;
    entities: JobEntities;
    tags: string[];
    declarations: DeclarationsFromJobEntities;
}

export const jobsInitialState: Readonly<JobsState> = {
    isLoading: false,
    jobs: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
    tags: [],
    declarations: {},
};

const jobs: Reducer<JobsState, JobActions> = (state = jobsInitialState, action): JobsState => {
    switch (action.type) {
        case 'LIST_JOBS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_JOBS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                jobs: action.payload,
            };
        case 'LIST_JOBS_ERROR':
            return {
                ...state,
                isLoading: false,
                jobs: state.jobs,
                error: action.error,
            };
        case 'CREATE_JOB_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'CREATE_JOB_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_JOB_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_JOB_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_JOB_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_JOB_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_JOB_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'EDIT_JOB_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_JOB_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'LIST_JOB_TAGS':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_JOB_TAGS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                tags: [...action.payload],
            };
        case 'LIST_JOB_TAGS_ERROR':
            return {
                ...state,
                isLoading: false,
            };
        case 'DELETE_JOB_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_JOB_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_JOB_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default jobs;
