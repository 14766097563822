import { Spin } from 'antd';
import { useAppSelector } from 'config/hooks';
import { LoadingPayload } from 'store/global-overlay/globalOverlay';
import styled from 'styled-components';
import { H6 } from '../../typography';

const { Main, StyledH6 } = {
    Main: styled.div`
        display: flex;
        flex-direction: column;
        gap: 2rem;

        justify-content: center;
        align-items: center;
    `,
    StyledH6: styled(H6)<{ show: boolean }>`
        background-color: white;
        padding: 1rem;
        border-radius: 8px;
        transition: opacity 100ms;
        opacity: ${({ show }) => (show ? 1 : 0)};
    `,
};

const LoadingOverlay = () => {
    const payload: LoadingPayload | undefined = useAppSelector((state) => state.globalOverlay.payload);

    return (
        <Main>
            <Spin />
            <StyledH6 show={!!payload?.message} style={{ margin: 0 }} color="white">
                {payload?.message}
            </StyledH6>
        </Main>
    );
};

export default LoadingOverlay;
