import Notification from 'components/ui/base/notification/Notification';
import useSession from 'hooks/useSession';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveHmrcToken } from 'store/customers/client';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const HmrcConfirmation: FC<{}> = () => {
    let query = useQuery();
    const { userInfo } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        const code = query.get('code');
        const doSave = (code: string) => {
            saveHmrcToken(userInfo?.customerId!, code)
                .then((response) => navigate('/settings/hmrc', { replace: true }))
                .catch((error) => {
                    console.log(error);
                    Notification({
                        type: 'error',
                        messageTitle: 'Error connecting to HMRC',
                        description: error.message,
                    });
                    navigate('/settings/hmrc', { replace: true });
                });
        };

        if (code) {
            doSave(code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>Loading....</div>;
};

export default HmrcConfirmation;
