import { FC } from 'react';
import { ListTagLayout } from './ListTags.styles';
import Tag from './Tag';
import { getTagColor } from './utils';

interface Props {
    tags: string[];
    gap?: string | number;
    vertical?: boolean;
}

const ListTags: FC<Props> = ({ tags, gap = '0.75rem', vertical = false }) => {
    return (
        <ListTagLayout gap={gap} vertical={vertical}>
            {tags?.map((t, index) => (
                <Tag color={getTagColor(t)} key={`tags-${index}`}>
                    {t}
                </Tag>
            ))}
        </ListTagLayout>
    );
};
export default ListTags;
