import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { VehiclesActions } from './actionType';
import { Vehicle } from './vehicles';

interface CargoEntities {
    [key: string]: Vehicle;
}
export interface VehiclesState {
    isLoading: boolean;
    error?: ErrorResponse;
    vehicles: ListPayload<Vehicle>;
    entities: CargoEntities;
}

export const vehiclesInitialState: Readonly<VehiclesState> = {
    isLoading: false,
    vehicles: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const vehicles: Reducer<VehiclesState, VehiclesActions> = (state = vehiclesInitialState, action): VehiclesState => {
    switch (action.type) {
        case 'LIST_VEHICLES_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_VEHICLES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                vehicles: action.payload,
            };
        case 'LIST_VEHICLES_ERROR':
            return {
                ...state,
                isLoading: false,
                vehicles: state.vehicles,
                error: action.error,
            };
        case 'CREATE_VEHICLE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_VEHICLE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_VEHICLE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_VEHICLE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_VEHICLE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_VEHICLE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_VEHICLE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_VEHICLE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_VEHICLE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_VEHICLE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_VEHICLE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_VEHICLE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default vehicles;
