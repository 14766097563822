import { useDispatch } from 'react-redux';
import { doCollapseDeclarationHeader, doExpandDeclarationHeader } from 'store/declaration-header/action';
import { useAppSelector } from '../config/hooks';

const useDeclarationHeader = () => {
    const dispatch = useDispatch();
    const declarationHeaderCollapsed = useAppSelector((state) => state.declarationHeader.collapsed);

    const collapseDeclarationHeader = () => {
        dispatch(doCollapseDeclarationHeader());
    };

    const expandDeclarationHeader = () => {
        dispatch(doExpandDeclarationHeader());
    };

    return {
        declarationHeaderCollapsed,
        collapseDeclarationHeader,
        expandDeclarationHeader,
    };
};

export default useDeclarationHeader;
