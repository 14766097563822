import { memo } from 'react';
import { EDocketFormStyledComponents } from '../EDocketForm.styles';
import { InputProps } from 'antd';
import EDocketLabel from './EDocketLabel';

const { Input } = EDocketFormStyledComponents;

type Props = { label?: string } & InputProps;

const EDocketFormInput = ({ label, ...inputProps }: Props) => {
    return (
        <>
            <EDocketLabel label={label} />
            <Input {...inputProps} />
        </>
    );
};

export default memo(EDocketFormInput, (prev, next) => {
    return prev?.value === next?.value;
});
