import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import { useCallback, useState } from 'react';
import { Customer } from 'store/customers/customer';

type SelectCustomerFunc = (customer: Customer) => void;

const useSearchCustomer = () => {
    const [show, setShow] = useState(false);
    const [selectCustomerFunc, setSelectCustomerFunc] = useState<SelectCustomerFunc>(() => () => {});

    const { showErrorNotification } = useDeclarationNotifications();

    const handleSelectCustomer = useCallback<SelectCustomerFunc>(
        (customer) => {
            if (!customer.eori) {
                showErrorNotification(
                    'No identification number',
                    'The selected customer does not have a identification number'
                );
                return;
            }

            selectCustomerFunc(customer);
            setShow(false);
        },
        [selectCustomerFunc, showErrorNotification]
    );

    const triggerShowDrawer = (onSelectCustomer: SelectCustomerFunc) => {
        setShow(true);
        setSelectCustomerFunc(() => onSelectCustomer);
    };

    const triggerHideDrawer = () => setShow(false);

    return { show, triggerShowDrawer, triggerHideDrawer, handleSelectCustomer };
};

export default useSearchCustomer;
