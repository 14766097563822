import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { FormikProps, setNestedObjectValues } from 'formik';
import Fuse from 'fuse.js';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationProducts from 'hooks/useDeclarationProducts';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { StyledHeader } from 'views/declarations/Form.styles';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import CdsExportDeclarationUtils from './utils';
import useDeclarations from 'hooks/useDeclarations';
import { ProductContext } from '../../common/declaration-view/ProductContext';
import { FormSection } from '../../common/declaration-view/DeclarationView';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
    declarationErrors: ProductsErrors;
}

const UkExportTabContent: FC<Props> = ({
    formik,
    productsFormik,
    declaration,
    cancelProducts,
    clearCancel,
    declarationErrors,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const { declarationId }: { declarationId?: string } = useParams();
    const location = useLocation();
    const { declarationTemplate } = useDeclarations();
    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
    );
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const [myProducts, setMyProducts] = useState<CdsExportGovernmentAgencyGoodsItem[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { addProductsTemplatesToUkExport } = useDeclarationProducts();
    const { declarationValidation, setFormAction } = useDeclarationValidation();
    const { products, deleteUkExportProduct, listUkExportProducts, getUkExportProduct, createUkExportProduct } =
        useProducts();

    const { saveAsDraft } = useOutletContext<{ saveAsDraft: Function }>() ?? {};

    const controlProduct = useContext(ProductContext);
    const navigate = useNavigate();

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        expandDeclarationFooter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        if (cancelProducts) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    useEffect(() => {
        if (declarationHasItems) {
            const myProducts = products?.list as CdsExportGovernmentAgencyGoodsItem[];
            setMyProducts(myProducts);
        } else {
            setMyProducts([]);
        }
    }, [declarationHasItems, products?.list, products?.total]);

    useEffect(() => {
        if (searchQuery && myProducts) {
            const array = [...myProducts];
            const fuse = new Fuse(array, {
                keys: ['goodsDescription', 'baseCommodityCode'],
            });
            const res: unknown[] = fuse.search(searchQuery);
            setMyProducts(res as CdsExportGovernmentAgencyGoodsItem[]);
        }
        if (!searchQuery) {
            setMyProducts(myProducts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const addNewProduct = useCallback(async () => {
        controlProduct.setProductId?.(undefined);
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        if (!declarationId) return;
        const product = await createUkExportProduct(
            declarationTemplate?.template?.product.defaults ?? ({} as any),
            declarationId
        );
        listUkExportProducts(declarationId);
        navigate(`/declarations/${declarationId}/products/${product.id}`);
    }, [
        controlProduct,
        productsFormik,
        setDeclarationFooterType,
        declarationId,
        createUkExportProduct,
        declarationTemplate?.template?.product.defaults,
        listUkExportProducts,
        navigate,
    ]);

    useEffect(() => {
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const handleDeclarationView = useMemo(() => {
        const handleDeleteProducts = async (id: string) => {
            setShowProducts(false);
            const response = await deleteUkExportProduct(id, declaration.id!);
            if (response && declarationId) {
                Notification({
                    type: 'success',
                    messageTitle: 'Product Deleted',
                    description: 'Product has been successfully deleted!',
                });
                return listUkExportProducts(declarationId);
            }
        };

        const handleEditProducts = async (id: string) => {
            if (declarationHasItems) {
                const product = await getUkExportProduct(declaration.id!, id);
                if (product) {
                    if (controlProduct.setProductId) {
                        controlProduct.setProductId(product.id);
                    }
                    productsFormik.setValues(
                        CdsExportDeclarationUtils.transformProductForClient(
                            (product as any)?.governmentAgencyGoodsItem as CdsExportGovernmentAgencyGoodsItem
                        )
                    );
                    if (!!declarationValidation.pressedSubmit) {
                        await productsFormik.validateForm().then((v) => {
                            productsFormik.setTouched(setNestedObjectValues(v, true));
                            return v;
                        });
                    }
                    navigate(`/declarations/${declarationId}/products/${product.id}`);
                }
            }
        };

        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    setSearchQuery,
                    errors: declarationErrors,
                    data: myProducts,
                    onDelete: handleDeleteProducts,
                    onEdit: handleEditProducts,
                    itemsNumber: declarationHasItems,
                    internalType: DeclarationInternalType.EXPORT,
                    saveAsDraft,
                }}
            />
        );
    }, [
        saveAsDraft,
        formik,
        productsFormik,
        declarationErrors,
        myProducts,
        declarationHasItems,
        deleteUkExportProduct,
        declaration.id,
        declarationId,
        listUkExportProducts,
        getUkExportProduct,
        controlProduct,
        declarationValidation.pressedSubmit,
        navigate,
    ]);

    const handleAddProducts = async (productTemplates: any) => {
        setShowProducts(false);
        const productTemplatesIds = productTemplates.map((template: any) => template.id!);
        const response = await addProductsTemplatesToUkExport(declaration.id!, productTemplatesIds);
        if (response && declarationId) {
            Notification({
                type: 'success',
                messageTitle: 'Product Added',
                description: 'Product Template has been successfully added to declaration!',
            });
            await listUkExportProducts(declarationId!);
            navigate(`/declarations/${declarationId}/products`);
        }
    };

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                internalType={DeclarationInternalType.EXPORT}
                declarationCountry={DeclarationCountry.UK}
            />
            <StyledHeader>
                <MasterProductDeclarationNav
                    selectedBt={selectedBt}
                    addNewProduct={addNewProduct}
                    setShowProducts={setShowProducts}
                    country={DeclarationCountry.UK}
                />
            </StyledHeader>
            <DeclarationFormTabContent>{handleDeclarationView}</DeclarationFormTabContent>
            <Drawer
                title={`View all ${
                    declaration.declarationInternalType === 'IMPORT_NEW' ? 'H2' : 'B1'
                } product templates`}
                width="786"
                visible={showProducts}
                onClose={() => {
                    setShowProducts(false);
                }}
            >
                {declaration && showProducts && (
                    <DeclarationsProductsTemplates
                        addProductsToDeclaration={handleAddProducts}
                        declarationTemplate={declarationTemplate}
                    />
                )}
            </Drawer>
        </>
    );
};
export default UkExportTabContent;
