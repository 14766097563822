import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id' | 'typeCode';

export const identificationOfWarehouseBlockValidation: {
    childValidators: {
        'goodsShipment.warehouse': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.warehouse': {
            childValidators: {
                id: [validators.maxLength(35)],
                typeCode: [validators.exact(1)],
            },
        },
    },
};

export const identificationOfWarehouseBlockRequiredValidation: {
    childValidators: {
        'goodsShipment.warehouse': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.warehouse': {
            childValidators: {
                id: [validators.required(), validators.maxLength(35)],
                typeCode: [validators.required(), validators.exact(1)],
            },
        },
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const IdentificationOfWarehouseBlock = ({
    path = 'goodsShipment.warehouse',
    declarationOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '2/7', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormInput
                        required={declarationOptions?.[formType]?.fieldOptions?.id?.required}
                        maxLength={35}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('identificationOfWarehouse.identifier')}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }) => (
                    <FormSelect
                        required={declarationOptions?.[formType]?.fieldOptions?.typeCode?.required}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('identificationOfWarehouse.type')}
                        selectOptions={cdsCodelists?.customsWarehouseTypes}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default IdentificationOfWarehouseBlock;
