import { Modal, ModalProps, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import DashboardTable from 'components/ui/composed/dashboard/DashboardTable';
import { ListPayload } from 'core/http/response';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Customer } from 'store/customers/customer';
import { HollowButton } from 'views/declarations/common/box44/Box44';
import Fuse from 'fuse.js';
import SearchBar from 'components/ui/base/searchbar/SearchBar';
import { debounce } from 'lodash';

interface Props extends ModalProps {
    customers: ListPayload<Customer> | undefined;
    onConfirm: (customerId: string) => void | Promise<void>;
}

const CustomerSelectionModal = ({ customers: customersProp, onConfirm, ...modalProps }: Props): ReactElement => {
    const [customers, setCustomers] = useState<ListPayload<Customer> | undefined>(customersProp);
    useEffect(() => {
        setCustomers(customersProp);
    }, [customersProp]);

    const fuse = useMemo(() => new Fuse(customers?.list ?? [], { keys: ['name'], threshold: 0.2 }), [customers?.list]);

    const search = useCallback(
        (searchTerm: string | undefined | null) => {
            if (!searchTerm) {
                setCustomers(customersProp);
                return;
            }
            setCustomers(
                (prev) => ({ ...prev, list: fuse.search(searchTerm).map(({ item }) => item) } as ListPayload<Customer>)
            );
        },
        [customersProp, fuse]
    );

    const debouncedSearch = useMemo(
        () => debounce((searchTerm: string | undefined | null) => search(searchTerm), 200),
        [search]
    );

    const columns: ColumnsType<Customer> = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                align: 'left',
                render: (text: string, record, index: number) => <span>{record.name}</span>,
            },
            {
                title: 'Role',
                dataIndex: 'Role',
                key: 'Role',
                align: 'left',
                render: (text: string, record, index: number) => <span>{record.type}</span>,
            },
        ];
    }, []);

    const [selectedCustomer, setSelectedCustomer] = useState<string>();

    const handleSelectCustomer = useCallback((customerIds: string[]) => {
        setSelectedCustomer(customerIds[0]);
    }, []);
    const handleConfirm = useCallback(() => {
        if (!selectedCustomer) {
            notification.error({ message: 'Please select a customer to continue' });
            return;
        }

        onConfirm(selectedCustomer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalProps.onCancel, onConfirm, selectedCustomer]);

    useEffect(() => {
        return () => {
            setSelectedCustomer(undefined);
        };
    }, []);

    return (
        <Modal
            title="Choose a customer"
            closable={false}
            footer={[<HollowButton onClick={handleConfirm}>Confirm</HollowButton>]}
            destroyOnClose
            {...modalProps}
        >
            <div style={{ height: 'calc(100vh - 380px)', overflow: 'auto' }}>
                <SearchBar onChange={debouncedSearch} />
                <DashboardTable
                    rowSelectionType="radio"
                    doSelectOnClick
                    onRowSelection={handleSelectCustomer}
                    withPagination={false}
                    columns={columns}
                    data={customers}
                />
            </div>
        </Modal>
    );
};

export default CustomerSelectionModal;
