import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Individual } from './individual';

// TODO add query params
export const listIndividuals = (params?: Partial<PaginatedParams>): Promise<ListPayload<Individual>> =>
    axiosClient
        .get<ListResponse<Individual>>(`${config.cmsUrl}/individuals`, { params })
        .then((response) => response.data.payload);

export const createIndividual = (individual: Individual): Promise<Individual> =>
    axiosClient
        .post<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals`, individual)
        .then((response) => response.data.payload);

export const editIndividual = (individualId: string, individual: Partial<Individual>): Promise<Individual> =>
    axiosClient
        .put<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals/${individualId}`, individual)
        .then((response) => response.data.payload);

export const getIndividual = (individualId: string): Promise<Individual> =>
    axiosClient
        .get<SuccessResponse<Individual>>(`${config.cmsUrl}/individuals/${individualId}`)
        .then((response) => response.data.payload);

export const deleteIndividual = (individualId: string): Promise<SuccessResponse<void>> =>
    axiosClient.delete<SuccessResponse<void>>(`${config.cmsUrl}/individuals/${individualId}`).then((response) => {
        return response.data;
    });
