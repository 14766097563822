import { useState } from 'react';

interface Props {
    onSubmit: Function;
}

const useLoadingButton = ({ onSubmit }: Props) => {
    const [loading, setIsLoading] = useState(false);

    const handleOnPressButton = async () => {
        setIsLoading(true);
        await onSubmit();
        setIsLoading(false);
    };

    return {
        loading,
        handleOnPressButton,
    };
};
export default useLoadingButton;
