import { LinkButton } from 'components/ui/base/button/Button.styles';
import Drawer from 'components/ui/base/drawer/Drawer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SubmissionErrorsList from './SubmissionErrorsList';

export interface SubmissionError {
    code?: string;
    message: string;
}

interface Props {
    errors: SubmissionError[] | null | undefined;
    open?: boolean;
    onClose?: () => void;
}

const SubmissionErrorsDrawer: FC<Props> = ({ errors, open = false, onClose }) => {
    const { t } = useTranslation('common');

    const handleClose = (): void => {
        onClose?.();
    };

    return (
        <Drawer title="Declaration errors" visible={open} onClose={handleClose} width={820} warning={true}>
            <LinkButton
                href="https://www.revenue.ie/en/online-services/support/software-developers/documents/import-control-system/import-control-system.pdf"
                target="_blank"
            >
                {t('conditionsDocument')}
            </LinkButton>
            <SubmissionErrorsList errors={errors} />
        </Drawer>
    );
};

export default SubmissionErrorsDrawer;
