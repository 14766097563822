import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { JourneysActions } from './actionType';
import { Journey } from './journeys';


interface JourneyEntities {
    [key: string]: Journey;
}
export interface JourneysState {
    isLoading: boolean;
    error?: ErrorResponse;
    journeys: ListPayload<Journey>;
    entities: JourneyEntities;
}

export const journeysInitialState: Readonly<JourneysState> = {
    isLoading: false,
    journeys: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const journeys: Reducer<JourneysState, JourneysActions> = (state = journeysInitialState, action): JourneysState => {
    switch (action.type) {
        case 'LIST_JOURNEYS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_JOURNEYS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                journeys: action.payload,
            };
        case 'LIST_JOURNEYS_ERROR':
            return {
                ...state,
                isLoading: false,
                journeys: state.journeys,
                error: action.error,
            };
        case 'CREATE_JOURNEYS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_JOURNEYS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_JOURNEYS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_JOURNEYS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_JOURNEYS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_JOURNEYS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_JOURNEYS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_JOURNEYS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_JOURNEYS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_JOURNEYS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_JOURNEYS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_JOURNEYS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default journeys;
