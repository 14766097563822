import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../config/hooks';
import { doGetCountries } from '../store/countries/actions';

const useCountries = () => {
    const countries = useAppSelector((state) => state.countries.countries);
    const isLoading = useAppSelector((state) => state.countries.isLoading);
    const error = useAppSelector((state) => state.countries.error);

    const dispatch = useAppDispatch();

    const getCountries = useCallback(() => dispatch(doGetCountries()), [dispatch]);

    const getNameFromCountryCode = useCallback(
        (countryCode?: string) => {
            if (countryCode) {
                return countries.list.find((country) => country.countryCode === countryCode)?.name;
            }
        },
        [countries.list]
    );

    useEffect(() => {
        if (!countries.list.length && !isLoading && !error) {
            getCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        countries,
        isLoading,
        error,
        getNameFromCountryCode,
    };
};

export default useCountries;
