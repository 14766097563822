import { useCallback, useEffect } from 'react';
import { doCreateJob, doDeleteJob, doEditJob, doGetJob, doListJobs, doListTags } from 'store/jobs/action';
import { JobResponse } from 'store/jobs/job';
import { useAppDispatch, useAppSelector } from '../config/hooks';

interface UseJobsProps {
    jobId?: string;
    loadDeclarations?: boolean;
}

const useJobs = ({ jobId }: UseJobsProps = {}) => {
    const jobs = useAppSelector((state) => state.jobs.jobs);
    const job = useAppSelector((state) => (jobId ? state.jobs.entities[jobId] : ({} as JobResponse)));
    const isLoading = useAppSelector((state) => state.jobs.isLoading);
    const error = useAppSelector((state) => state.jobs.error);
    const tagsList = useAppSelector((state) => state.jobs.tags);

    const dispatch = useAppDispatch();

    const createJob = useCallback((customerId?: string) => dispatch(doCreateJob(customerId)), [dispatch]);

    const listJobs = useCallback((params?: any) => dispatch(doListJobs(params)), [dispatch]);

    const getJob = useCallback((jobId: string) => dispatch(doGetJob(jobId)), [dispatch]);

    const editJob = useCallback((jobId: string, job: JobResponse) => dispatch(doEditJob(jobId, job)), [dispatch]);

    const listTags = useCallback((params?: any) => dispatch(doListTags(params)), [dispatch]);
    const deleteJob = useCallback((jobId: string) => dispatch(doDeleteJob(jobId)), [dispatch]);

    useEffect(() => {
        if (jobId && jobId !== job?.id) {
            getJob(jobId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    return {
        isLoading,
        error,
        jobs,
        job,
        tagsList,
        createJob,
        listJobs,
        getJob,
        editJob,
        listTags,
        deleteJob,
    };
};

export default useJobs;
