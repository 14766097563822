import { useEffect } from 'react';

const ErrorFallback = ({ error }: { error: Error }) => {
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
};

export default ErrorFallback;
