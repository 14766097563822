import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'rateNumeric';

export const exchangeRateBlockValidation: {
    childValidators: {
        currencyExchange: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        currencyExchange: {
            childValidators: {
                rateNumeric: [validators.number(), validators.float(12, 5)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ExchangeRateBlock = ({ path = 'currencyExchange', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/15', []);
    return (
        <DeclarationField pathPrefix={path} name="rateNumeric">
            {({ form, field }: FieldProps<any>) => (
                <DeclarationNumberInput
                    refNumber={ucc}
                    label={t('exchangeRate')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default ExchangeRateBlock;
