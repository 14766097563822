import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import IrelandProductsTable from 'views/declarations/ireland/common/IrelandProductsTable';
import EnsProductsTable from 'views/declarations/ireland/ens/cards/products/EnsProductsTable';
import EtdProductsTable from 'views/declarations/ireland/etd/products/EtdProductTable';
import UkProductsTable from 'views/declarations/uk/common/UkProductsTable';
import TsdProductsTable from 'views/declarations/ireland/tsd/products/TsdProductTable';
import useProductsTemplates from '../../../../hooks/useProductsTemplates';
import { handleAddDeclarationProductToProductTemplates } from '../../../custom-declaration/products/productTemplateUtils';
import { ListPayload } from 'core/http/response';
import { ProductContext } from '../../common/declaration-view/ProductContext';
import { getTableChangeParams, TableChangeParams } from '../../../../utils/tableHelpers';

interface NavigationProductsTable {
    comingBackFromProductView?: boolean;
}

const DeclarationProductsTable: FC<{}> = () => {
    const {
        setSearchQuery,
        errors,
        data,
        declarationType,
        onDelete,
        onEdit,
        internalType,
        viewOnly,
        itemsNumber,
        pageNumber,
    } = useDeclarationProductsTable();
    const { declarationId } = useParams<{ declarationId: string }>();
    const { state } = useLocation();
    const comingBackFromProductView = (state as NavigationProductsTable)?.comingBackFromProductView;
    const { declaration } = useDeclarations();
    const {
        products,
        listIrelandH1Products,
        listIrelandH7Products,
        listIrelandExportProducts,
        listIrelandEnsProducts,
        listUkExportProducts,
        listIrelandEtdProducts,
        listIrelandTsdProducts,
    } = useProducts();
    const { saveProductTemplate } = useProductsTemplates({});
    const navigate = useNavigate();
    const { setProductId } = useContext(ProductContext);

    useEffect(() => {
        setProductId?.(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = useCallback(
        async (id: string) => {
            const products = await onDelete?.(id);
            if (products?.total === 0) {
                navigate(`/declarations/${declarationId}/products/new`, { replace: true });
            }
        },
        [declarationId, navigate, onDelete]
    );

    const getAddDeclarationProductToProductTemplate = useCallback(
        (productId: string) =>
            handleAddDeclarationProductToProductTemplates(
                productId,
                products,
                saveProductTemplate,
                declaration?.templateId,
                Boolean(declaration?.cdsExportDeclaration)
            ),
        [declaration, products, saveProductTemplate]
    );

    const listProductsRequest = useMemo(() => {
        let req;
        if (declaration?.irelandImportDeclaration) {
            req = listIrelandH1Products;
        } else if (declaration?.irelandH7ImportDeclaration) {
            req = listIrelandH7Products;
        } else if (declaration?.ieExportDeclaration) {
            req = listIrelandExportProducts;
        } else if (declaration?.entrySummaryDeclaration) {
            req = listIrelandEnsProducts;
        } else if (declaration?.cdsExportDeclaration) {
            req = listUkExportProducts;
        } else if (declaration?.irelandETDDeclaration) {
            req = listIrelandEtdProducts;
        } else if (declaration?.irelandImportTemporaryStorageDeclaration) {
            req = listIrelandTsdProducts;
        }
        return req;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration]);

    useEffect(() => {
        const checkItems = async (declarationId: string) => {
            const response = await listProductsRequest?.(declarationId);

            const size = response?.total ?? 0;
            const productId = response?.list?.[0]?.id;

            if (size === 0) {
                navigate(`/declarations/${declarationId}/products/new`, { replace: true });
            }
            if (size === 1 && productId) {
                navigate(
                    `/declarations/${declarationId}${
                        viewOnly ? `/view-only/products/${productId}` : `/products/${productId}`
                    }`,
                    {
                        replace: true,
                    }
                );
            }
        };

        if (declarationId && declaration?.id === declarationId && !comingBackFromProductView) {
            checkItems(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, declaration?.id, declarationId, comingBackFromProductView, viewOnly]);

    const onChange = useCallback(
        ({ pagination, sorter }: TableChangeParams) =>
            declarationId && listProductsRequest?.(declarationId, { ...getTableChangeParams({ sorter, pagination }) }),
        [declarationId, listProductsRequest]
    );

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    return (
                        <>
                            {declaration.declarationInternalType !== DeclarationInternalType.ENS &&
                                declaration.declarationInternalType !== DeclarationInternalType.ETD &&
                                declaration.declarationInternalType !== DeclarationInternalType.TSD && (
                                    <IrelandProductsTable
                                        errors={errors}
                                        internalType={internalType}
                                        data={data}
                                        declarationType={declarationType}
                                        onDelete={handleDelete}
                                        onEdit={onEdit}
                                        onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                        totalItems={itemsNumber}
                                        viewOnly={viewOnly}
                                        onChange={onChange}
                                        pageNumber={pageNumber}
                                    />
                                )}
                            {declaration.declarationInternalType === DeclarationInternalType.ENS && (
                                <EnsProductsTable
                                    errors={errors}
                                    data={data as EnsGoodsShipmentItem[]}
                                    onDelete={handleDelete}
                                    viewOnly={viewOnly}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    onChange={onChange}
                                />
                            )}
                            {declaration.declarationInternalType === DeclarationInternalType.ETD && (
                                <EtdProductsTable
                                    errors={errors}
                                    data={data as EtdGoodsShipmentItem[]}
                                    onDelete={handleDelete}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    viewOnly={viewOnly}
                                    onChange={onChange}
                                />
                            )}

                            {declaration.declarationInternalType === DeclarationInternalType.TSD && (
                                <TsdProductsTable
                                    errors={errors}
                                    data={data as EtdGoodsShipmentItem[]}
                                    onDelete={handleDelete}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    viewOnly={viewOnly}
                                    onChange={onChange}
                                />
                            )}
                        </>
                    );
                }
                case DeclarationExternalEntity.CDS:
                    return (
                        <UkProductsTable
                            errors={errors}
                            internalType={internalType}
                            data={data as CdsExportGovernmentAgencyGoodsItem[]}
                            onDelete={handleDelete}
                            onEdit={onEdit}
                            onAddToTemplate={getAddDeclarationProductToProductTemplate}
                            viewOnly={viewOnly}
                            onChange={onChange}
                        />
                    );
                default:
                    <></>;
            }
        }
        return <></>;
    }, [
        onChange,
        declaration,
        errors,
        internalType,
        data,
        handleDelete,
        onEdit,
        getAddDeclarationProductToProductTemplate,
        viewOnly,
        declarationType,
        itemsNumber,
        pageNumber,
    ]);

    return (
        <>
            <Row gutter={16} wrap={false} style={{ marginBottom: '2.5rem' }}>
                <Col flex="auto">
                    <SearchBar
                        onSearch={(value) => setSearchQuery(value)}
                        inputPlaceholder="Search by tag, commodity code and description of goods"
                    />
                </Col>
            </Row>

            {container}
        </>
    );
};
export default DeclarationProductsTable;

function useDeclarationProductsTable() {
    return useOutletContext<{
        setSearchQuery: (value: string) => void;
        data:
            | H1GoodsShipmentItem[]
            | H7GoodsShipmentItem[]
            | IrelandExportItem[]
            | EnsGoodsShipmentItem[]
            | EtdGoodsShipmentItem[]
            | CdsExportGovernmentAgencyGoodsItem[];
        internalType?: DeclarationInternalType;
        declarationType?: DeclarationName;
        onDelete?: (id: string) => Promise<ListPayload<any>>;
        onEdit: (id: string) => Promise<void>;
        loading?: boolean;
        viewOnly?: boolean;
        errors?: boolean[];
        itemsNumber: number;
        pageNumber: number;
    }>();
}
