import { ensBox44KeyNames, ensPathBox44Products } from 'views/declarations/common/box44/box-44-utils';
import { GoodsShipment } from '../../../../store/declarations/ireland/entry-summary-declaration';
import IrelandImportDeclarationUtils from '../import/utils';

const IrelandEnsDeclarationUtils = {
    transformProductForClient: (product: GoodsShipment | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToObject,
            ensPathBox44Products,
            ensBox44KeyNames,
            product
        );
    },
    transformProductForServer: (product: GoodsShipment | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToArray,
            ensPathBox44Products,
            ensBox44KeyNames,
            product
        );
    },
};

export default IrelandEnsDeclarationUtils;
