import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import EDocketFormTextArea from './inputs/EDocketFormTextArea';

const { SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik>;

const EDocketFormGeneral = <TFormik extends FormikProps<EDocketFormType>>({ formik, t }: Props<TFormik>) => {
    return (
        <SectionMain>
            <Row gutter={[40, 25]}>
                <Col xl={12}>
                    <EDocketFormTextArea
                        label={t('supplierTrader.label')}
                        name="trader"
                        onChange={formik.handleChange}
                        value={formik.values.trader}
                        rows={3}
                        placeholder={t('supplierTrader.placeholder')}
                    />
                </Col>
                <Col xl={12}>
                    <EDocketFormTextArea
                        label={t('consigneeReceiver.label')}
                        name="receiver"
                        onChange={formik.handleChange}
                        value={formik.values.receiver}
                        rows={3}
                        placeholder={t('consigneeReceiver.placeholder')}
                    />
                </Col>
                <Col xl={12}>
                    <EDocketFormTextArea
                        label={t('haulier.label')}
                        name="haulier"
                        onChange={formik.handleChange}
                        value={formik.values.haulier}
                        rows={3}
                        placeholder={t('haulier.placeholder')}
                    />
                </Col>
            </Row>
        </SectionMain>
    );
};

export default EDocketFormGeneral;
