import Button from 'components/ui/base/button/Button';
import { H5 } from 'components/ui/base/typography';
import FormPassword from 'components/ui/composed/formPassword/FormPassword';
import { useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmationRegistration } from 'store/session/client';
import { InvitePageProps, OnboardingSection } from 'views/authentication/invite/Invite';
import { ChangePwdNextBt, FormDiv, TitleSection } from 'views/authentication/invite/Invite.styles';
import * as Yup from 'yup';

interface FormPwd {
    password: string;
    confirmPassword: string;
}

interface ChangePasswordProps extends InvitePageProps {
    token: string;
}

const formValidation = Yup.object().shape({
    password: Yup.string().required('Password is missing.'),
    confirmPassword: Yup.string().required('Confirm Password is wrong or missing.'),
});

const ChangePassword: FC<ChangePasswordProps> = ({ token, onChangePage }) => {
    const { t } = useTranslation('onboarding');
    const { signIn, isLogged, userInfo } = useSession();

    useEffect(() => {
        if (isLogged && userInfo) {
            onChangePage(OnboardingSection.CHECKLIST);
        }
    }, [isLogged, userInfo, onChangePage]);

    const handleSubmit = async (values: FormPwd) => {
        const body = { ...values, token };
        const response = await confirmationRegistration(body);

        await signIn({ email: response.email, password: body.password, remember: false });
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validateOnMount: false,
        validationSchema: formValidation,
        enableReinitialize: true,
        onSubmit: (values: FormPwd) => handleSubmit(values),
    });

    const handleContinue = async () => {
        //  onChangePage(OnboardingSection.CHECKLIST);
        formik.handleSubmit();
    };

    return (
        <>
            <TitleSection>
                <H5>{t('insert_pwd')}</H5>
            </TitleSection>
            <FormDiv>
                <FormPassword
                    icon
                    label={t('passwordLabel')}
                    fieldProps={formik.getFieldProps('password')}
                    fieldMeta={formik.getFieldMeta('password')}
                />
                <FormPassword
                    icon
                    label={t('confirmPasswordLabel')}
                    fieldProps={formik.getFieldProps('confirmPassword')}
                    fieldMeta={formik.getFieldMeta('confirmPassword')}
                />
            </FormDiv>
            <ChangePwdNextBt>
                <Button size="large" style={{ width: '10.0rem' }} type="primary" onClick={handleContinue}>
                    {t('continue')}
                </Button>
            </ChangePwdNextBt>
        </>
    );
};
export default ChangePassword;
