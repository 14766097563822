import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const ExtraItemDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('Extra item details')}>
            <FormCardContainer>
                <FormInput
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.cusCode`, props)}
                    refNumber="6.13"
                    label={t('goodsItems.cusCode')}
                    tooltip={getH1TooltipsByRefNumberAndField('6.13', t('goodsItems.cusCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    maxLength={2}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.typeGoods`, props)}
                    refNumber="6.19"
                    label={t('goodsItems.typeGoods')}
                    tooltip={getH1TooltipsByRefNumberAndField('6.19', t('goodsItems.typeGoods'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`ucr`, props)}
                    refNumber="2.4"
                    label={t('ucr')}
                    tooltip={getH1TooltipsByRefNumberAndField('2.4', t('ucr'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`taxTotalAmount`, props)}
                    refNumber="4.7"
                    label={t('taxTotalAmount')}
                    refTooltip={getH1TooltipsByRefNumberAndField('4.7', t('taxTotalAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`valuationInformationCurrency`, props)}
                    refNumber="4.18"
                    label={t('valuationInformationCurrency')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.18', t('valuationInformationCurrency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`valuationInformationAmount`, props)}
                    refNumber="4.18"
                    label={t('valuationInformationAmount')}
                    refTooltip={getH1TooltipsByRefNumberAndField('4.18', t('valuationInformationAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryDestination`, props)}
                    refNumber="5.8"
                    label={t('countryDestination')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.8', t('country'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`regionDestination`, props)}
                    refNumber="5.9"
                    label={t('regionDestination')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.9', t('regionDestination'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryDispatch`, props)}
                    refNumber="5.14"
                    label={t('countryDispatch')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.14', t('Country of Dispatch'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryPreferentialOrigin`, props)}
                    refNumber="5.16"
                    label={t('countryPreferentialOrigin')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.16', t('countryPreferentialOrigin'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationDatepicker
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`acceptanceDate`, props)}
                    refNumber="5.31"
                    label={t('goodsShipment.acceptanceDate')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.31', t('goodsShipment.acceptanceDate'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    maxLength={6}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`quotaOrderNumber`, props)}
                    refNumber="8.1"
                    label={t('quotaOrderNumber')}
                    tooltip={getH1TooltipsByRefNumberAndField('8.1', t('quotaOrderNumber'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`transactionNature`, props)}
                    refNumber="8.5"
                    label={t('transactionNature')}
                    tooltip={getH1TooltipsByRefNumberAndField('8.5', t('transactionNature'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={aisCodelists?.natureOfTransaction}
                    condensed
                    codeValidation={[validators.maxLength(2)]}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ExtraItemDetailsCard;
