import { useCallback, useEffect } from 'react';
import { doGetIrelandCodelists, doGetUkCodelists } from 'store/codelists/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useCodelists = (load = false) => {
    const dispatch = useAppDispatch();
    const aisCodelists = useAppSelector((state) => state.codelists.irelandCodelists?.ais);
    const cdsCodelists = useAppSelector((state) => state.codelists.ukCodelists?.cds);
    const aesCodelists = useAppSelector((state) => state.codelists.irelandCodelists?.aes);

    const getIrelandCodelists = useCallback(() => dispatch(doGetIrelandCodelists()), [dispatch]);

    const getUkCodelists = useCallback(() => dispatch(doGetUkCodelists()), [dispatch]);

    useEffect(() => {
        if (load) {
            getIrelandCodelists();
            getUkCodelists();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIrelandCodelists, getUkCodelists]);

    return {
        aisCodelists,
        cdsCodelists,
        getIrelandCodelists,
        getUkCodelists,
        aesCodelists,
    };
};

export default useCodelists;
