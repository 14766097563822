import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'totalGrossMassMeasure' | 'grossMassMeasure';

export const grossMassBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        totalGrossMassMeasure: [validators.number(), validators.float(11, 3)],
    },
};

export const grossMassBlockProductValidation: {
    childValidators: {
        'commodity.goodsMeasure': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.goodsMeasure': {
            childValidators: {
                grossMassMeasure: [validators.number(), validators.float(11, 3)],
            },
        },
    },
};

export const cdsImportGrossMassBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        totalGrossMassMeasure: [validators.number(), validators.float(16, 6)],
    },
};

export const cdsImportGrossMassBlockProductValidation: {
    childValidators: {
        'commodity.goodsMeasure': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.goodsMeasure': {
            childValidators: {
                grossMassMeasure: [validators.number(), validators.float(16, 6)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {
    isProduct?: boolean;
}

const GrossMassBlock = ({ path, fieldOptions, isProduct }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/5', []);

    return (
        <DeclarationField pathPrefix={path} name={isProduct ? 'grossMassMeasure' : 'totalGrossMassMeasure'}>
            {({ form, field }: FieldProps<any>) => (
                <FormInput
                    refNumber={ucc}
                    label={t('goodsItems.grossMass')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default GrossMassBlock;
