import { InputProps, Spin } from 'antd';
import { StyledInfoCircleFilled } from 'App.styles';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    StyledInput,
    SuccessDiv,
    SuccessIcon,
    SuccessLabel,
} from './FormInput.styles';

export interface FormInputProps {
    type?: InputProps['type'];
    label?: string;
    children?: ReactNode;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    showSuccess?: boolean;
    tooltip?: string;
    refClicked?: (refNumber: string | number) => void;
    refNumber?: string;
    isSearching?: boolean;
    condensed?: boolean;
}

const FormInput: FC<FormInputProps> = ({
    type,
    label,
    fieldMeta,
    fieldProps,
    fieldHelper,
    disabled,
    required,
    maxLength,
    autoFocus,
    showSuccess,
    tooltip,
    refClicked,
    refNumber,
    isSearching,
    condensed,
}) => {
    const { t } = useTranslation('common');

    useEffect(() => {
        if (fieldProps.value === '' && fieldHelper) {
            fieldHelper.setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.value]);

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    const iconInput = useMemo(() => {
        if (!!isSearching) {
            return <Spin size="small" />;
        }
        return showSuccess ? <SuccessIcon /> : <span />;
    }, [isSearching, showSuccess]);

    return (
        <>
            <LabelDiv>
                {label && <InputLabel>{label}</InputLabel>}
                {required && <InputLabelRequired>*</InputLabelRequired>}
                {tooltip && (
                    <IconTooltip
                        title={label}
                        tooltip={tooltip ?? t('defaultTooltip')}
                        icon={<StyledInfoCircleFilled />}
                        tooltipClicked={handleRefClick}
                    />
                )}
            </LabelDiv>
            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <InputDiv>
                    <StyledInput
                        type={type}
                        showSuccess={showSuccess ?? false}
                        suffix={iconInput}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        maxLength={maxLength}
                        status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                        {...fieldProps}
                        size={condensed ? 'middle' : 'large'}
                        condensed={condensed}
                    />
                </InputDiv>

                {!showSuccess ? (
                    <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                        {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                    </ErrorDiv>
                ) : (
                    <SuccessDiv>
                        <SuccessLabel>The EORI code is valid!</SuccessLabel>
                    </SuccessDiv>
                )}
            </FormItem>
        </>
    );
};

export default FormInput;
