import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { WCOLocationsFormikFields } from '../WCOTypes';
import { wcoLocationsValidationSchema } from './wcoValidationSchemas';

const initialValues: WCOLocationsFormikFields = {
    companyName: '',
    locations: [
        {
            locationName: '',
            address: '',
        },
    ],
};

const useFormikWCOLocations = () => {
    const formik = useFormik({
        initialValues,
        validationSchema: wcoLocationsValidationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);

    return formik;
};

export default useFormikWCOLocations;
