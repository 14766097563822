import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import useFormUtils from '../../../../../hooks/useFormUtils';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import HideComponent from '../../../../../utils/HideComponent';
import { ValidationSchema, validators } from '../../export/validations/validations';

export const taricCodesBlockValidation: {
    childValidators: {
        'commodity.classification.trc': ValidationSchema<'id'>;
    };
} = {
    childValidators: {
        'commodity.classification.trc': {
            childValidators: {
                id: [validators.exact(2)],
            },
        },
    },
};

export const taricCodesRequiredBlockValidation: {
    childValidators: {
        'commodity.classification.trc': ValidationSchema<'id'>;
    };
} = {
    childValidators: {
        'commodity.classification.trc': {
            childValidators: {
                id: [validators.required(), validators.exact(2)],
            },
        },
    },
};

interface Props extends BlockProps<'id'>, DeclarationBlockProps<AvailableForms, 'id'> {}

const TaricCodesBlock = ({ path = 'commodity.classification.trc', hide, declarationOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '6/15', []);
    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('taricCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                        required={declarationOptions?.[formType]?.fieldOptions?.id?.required}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default TaricCodesBlock;
