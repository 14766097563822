import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { RoutesActions } from './actionType';
import { Route } from './route';


interface RouteEntities {
    [key: string]: Route;
}
export interface RoutesState {
    isLoading: boolean;
    error?: ErrorResponse;
    entities: RouteEntities;
}

export const routesInitialState: Readonly<RoutesState> = {
    isLoading: false,
    entities: {},
};

const routes: Reducer<RoutesState, RoutesActions> = (state = routesInitialState, action): RoutesState => {
    switch (action.type) {
        case 'CREATE_ROUTE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_ROUTE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_ROUTE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_ROUTE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_ROUTE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_ROUTE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_ROUTE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_ROUTE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_ROUTE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_ROUTE_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_ROUTE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_ROUTE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default routes;
