import { Modal, ModalProps, Form, Input } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { ReactElement } from 'react';
import { HollowButton } from './box44/Box44';
import { InfoText } from './DeclarationInformation';

interface Props extends ModalProps {
    onClose: () => void;
    handlePresentationNotification: (data?: { presentationCustomsOffice?: string }) => Promise<void>;
}

const PresentationNotificationModal = ({
    onClose,
    handlePresentationNotification,
    ...modalProps
}: Props): ReactElement => {
    return (
        <Modal
            title={<H5>Presentation Notification</H5>}
            onCancel={onClose}
            footer={[
                <HollowButton onClick={onClose} size="large">
                    Close
                </HollowButton>,
                <HollowButton form="presentationNotification" htmlType="submit" inverted size="large">
                    Send
                </HollowButton>,
            ]}
            {...modalProps}
        >
            <InfoText>Please populate Presentation Person and send Presentation Notification.</InfoText>
            <Form
                style={{ marginTop: '1rem', height: 100 }}
                id="presentationNotification"
                layout="vertical"
                onFinish={handlePresentationNotification}
            >
                <Form.Item
                    name="presentationCustomsOffice"
                    label="Presentation Person:"
                    rules={[
                        {
                            required: true,
                            message: 'Presentation Person must be at least 8 characters.',
                            type: 'string',
                            min: 8,
                        },
                    ]}
                >
                    <Input style={{ resize: 'none' }} placeholder="Enter presentation person" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PresentationNotificationModal;
