import config from 'config';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import { Route } from './route';

export const getRoute = (routeId: string): Promise<Route> =>
    axiosClient
        .get<SuccessResponse<Route>>(`${config.journeysUrl}/routes/${routeId}`)
        .then((response) => response.data.payload);

export const postRoute = (route: Route): Promise<Route> =>
    axiosClient
        .post<SuccessResponse<Route>>(`${config.journeysUrl}/routes`, route)
        .then((response) => response.data.payload);

export const putRoute = (route: Route): Promise<Route> =>
    axiosClient
        .put<SuccessResponse<Route>>(`${config.journeysUrl}/routes`, route)
        .then((response) => response.data.payload);

export const deleteRoute = (routeId: string): Promise<Route> =>
    axiosClient
        .delete<SuccessResponse<Route>>(`${config.journeysUrl}/routes/${routeId}`)
        .then((response) => response.data.payload);
