import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const CustomOfficeCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const fields = props.getFields ? props.getFields() : [];

    return (
        <section
            id={'tsd-goods-information-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-goods-information-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('tsd.customOffice')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`customsOfficeOfLodgement`, props)}
                            label={t('tsd.customsOfficeLodgement')}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={8}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`supervisingCustomsOffices`, props)}
                            label={t('tsd.customsOfficeOfDestination')}
                        />
                    </Col>
                </Row>
            </FormCard>
        </section>
    );
};

export default CustomOfficeCard;
