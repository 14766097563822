import {
    ArrivalAtExitDeclaration,
    ArrivalAtExitGoodsShipment,
    Carrier,
    IeArrivalExitConsignment,
    UnloadingLocation,
} from 'store/declarations/ireland/arrival-at-exit-declaration';
import { Shape } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

export const carrierDraftValidation = Yup.object().shape<Shape<Carrier>>({
    identifier: Yup.string().nullable(),
    name: Yup.string().nullable(),
    address: Yup.object().shape({
        country: Yup.string().nullable(),
        streetAndNumber: Yup.string().nullable(),
        postCode: Yup.string().nullable(),
        city: Yup.string().nullable(),
    }),
});

export const unloadingLocationDraftValidation = Yup.object().shape<Shape<UnloadingLocation>>({
    arrivalNotificationDate: Yup.string().nullable(),
    arrivalNotificationPlaceIdentifier: Yup.string().nullable(),
});

export const consignmentDraftValidation = Yup.object().shape<Shape<IeArrivalExitConsignment>>({
    customsSubPlaceIdentifier: Yup.string().nullable(),
    goodsLocationIdentifier: Yup.string().nullable(),
    goodsLocationCode: Yup.string().nullable(),
    unloadingLocation: unloadingLocationDraftValidation,
    carrier: carrierDraftValidation,
});

export const goodsShipmentDraftValidation = Yup.object().shape<Shape<ArrivalAtExitGoodsShipment>>({
    exitCustomsOffice: Yup.string().nullable(),
    warehouseIdentification: Yup.string().nullable(),
    storingFlag: Yup.boolean().nullable(),
    consignment: consignmentDraftValidation,
});

export const ieArrivalAtExitDraftValidation = Yup.object().shape<Shape<ArrivalAtExitDeclaration>>({
    mrn: Yup.string().max(22).nullable(),
    goodsShipment: goodsShipmentDraftValidation,
});
