import { FC } from 'react';
import { TagLayout } from './Tag.styles';

interface Props {
    children: string;
    color?: string;
}
const Tag: FC<Props> = ({ children, color }) => {
    return <TagLayout color={color}>{children}</TagLayout>;
};

export default Tag;
