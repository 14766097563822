import { FC } from 'react';

const PackageIcon: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 179 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M89.5274 95.2172L177.027 48.2898C177.694 47.9325 178.5 48.4151 178.5 49.171V165.377C178.5 165.759 178.283 166.107 177.941 166.275L90.4412 209.291C89.7767 209.618 89 209.135 89 208.394V96.0984C89 95.7299 89.2026 95.3913 89.5274 95.2172Z"
                fill="#FCB043"
                stroke="#FCB043"
            />
            <path
                d="M90.5226 95.2425L3.65795 48.3942C2.95229 48.0136 2.95896 46.999 3.66956 46.6277L90.5343 1.24332C90.8259 1.09098 91.1737 1.09179 91.4646 1.24548L177.353 46.6298C178.058 47.0024 178.065 48.01 177.365 48.3919L91.4762 95.2403C91.1792 95.4023 90.8204 95.4031 90.5226 95.2425Z"
                fill="#FFCE94"
                stroke="#FFCE94"
            />
            <path
                d="M101 186.5L113.139 180.073C115.345 178.906 118 180.505 118 183V183V183C118 185.153 116.81 187.13 114.907 188.138L101 195.5V186.5Z"
                fill="#403A46"
            />
            <path
                d="M101 172.5L122.978 162.133C125.08 161.141 127.5 162.675 127.5 165V165V165C127.5 166.841 126.467 168.526 124.827 169.362L101 181.5V172.5Z"
                fill="#403A46"
            />
            <path
                d="M89.9726 95.2172L2.47263 48.2898C1.80645 47.9325 1 48.4151 1 49.171V165.377C1 165.759 1.21672 166.107 1.55881 166.275L89.0588 209.291C89.7233 209.618 90.5 209.135 90.5 208.394V96.0984C90.5 95.7299 90.2974 95.3913 89.9726 95.2172Z"
                fill="#E2791B"
                stroke="#E2791B"
            />
            <path d="M35 83.5V65.5L56 76.5V95L35 83.5Z" fill="#B6C8CE" stroke="#B6C8CE" />
            <path d="M125 18.5L145.5 29.5L56.5 77L35.5 65.5L125 18.5Z" fill="#DEF2FC" stroke="#DEF2FC" />
        </svg>
    );
};
export default PackageIcon;
