import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const StyledLegRow = styled.div`
    margin-bottom: 1.8rem;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px 0px 0px 10px;
`;

const StyledLegDate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.7rem 2rem 1.2rem 2rem;
    border-radius: 35px 0px 0px 10px;
    text-align: center;
    font-weight: ${fonts.weight.bold};
`;

export const StyledInProgressLegDate = styled(StyledLegDate)`
    background: linear-gradient(180deg, #ffa48d 0%, #fd4385 100%);
    color: ${colors.white};
`;

export const StyledAssignedLegDate = styled(StyledLegDate)`
    background-image: linear-gradient(to right, ${colors.darkPurple}, ${colors.darkPurple} 15%, ${colors.white} 15%);
    color: ${colors.darkPurple};
`;

export const StyledLegDetails = styled.div`
    padding: 1.2rem;
    width: 100%;
`;

export const StyledDayAndMonth = styled.div`
    p {
        margin-bottom: 0;
    }
`;

export const StyledAddressRow = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
`;

export const StyledInfoRow = styled.div`
    display: flex;
    gap: 1rem;
    text-align: center;
`;

export const StyledInfo = styled.div`
    margin-top: 1rem;

    p {
        margin-bottom: 0;
    }
`;

export const StyledTitle = styled.div`
    color: ${colors.darkGrey};
`;
