import { get } from 'lodash';
import { ParsedCard, ParsedField, ParsedForm, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { GoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { parseDeclarationCustomer } from '../validation/utils';

const countCustomerNumber = (array: ParsedCard[]) =>
    array.reduce((acc, parsedCard) => {
        if (parsedCard.name.includes('Customer')) acc = acc + 1;
        return acc;
    }, 1);

export const parseInvoiceResponse = (defaultParse: ParsedForm, declaration: IrelandImportDeclaration) => {
    parseExporter(defaultParse.masterDetails, declaration);
    parseImporter(defaultParse.masterDetails, declaration);
    parseDeclarant(defaultParse.masterDetails, declaration);
    parseRepresentative(defaultParse.masterDetails, declaration);
    parseSeller(defaultParse.masterDetails, declaration);
    parseBuyer(defaultParse.masterDetails, declaration);

    parseDeclarationItems(defaultParse.items, declaration);
    const parsedDeclaration = {
        masterDetails: defaultParse.masterDetails,
        items: defaultParse.items,
    };
    return parsedDeclaration;
};

const parseExporter = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const exporterPath = 'exporter';
    const exporterValue = get(declaration, exporterPath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);

    const fields: ParsedField[] = parseDeclarationCustomer(exporterValue, '', `customer${customerCount}`);

    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

const parseImporter = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const importerPath = 'goodsShipment.importer';
    const importerValue = get(declaration, importerPath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);

    const fields: ParsedField[] = parseDeclarationCustomer(importerValue, '', `customer${customerCount}`);
    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};
const parseDeclarant = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const declarantPath = 'declarant';
    const declarantValue = get(declaration, declarantPath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);
    const fields: ParsedField[] = parseDeclarationCustomer(declarantValue, '', `customer${customerCount}`);
    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

const parseRepresentative = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const representativePath = 'representative';
    const representativeValue = get(declaration, representativePath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);
    const fields: ParsedField[] = parseDeclarationCustomer(representativeValue, '', `customer${customerCount}`);
    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

const parseSeller = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const sellerPath = 'goodsShipment.seller';
    const sellerValue = get(declaration, sellerPath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);
    const fields: ParsedField[] = parseDeclarationCustomer(sellerValue, '', `customer${customerCount}`);
    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

const parseBuyer = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const buyerPath = 'goodsShipment.buyer';
    const buyerValue = get(declaration, buyerPath) as any;

    const customerCount = countCustomerNumber(masterDetailsParsedCards);
    const fields: ParsedField[] = parseDeclarationCustomer(buyerValue, '', `customer${customerCount}`);
    if (fields.length === 0) return;
    else
        masterDetailsParsedCards.splice(customerCount, 0, {
            name: `Customer ${customerCount}`,
            fields,
        });
};

const parseDeclarationItems = (parsedItemDetails: ParsedItem[], declaration: IrelandImportDeclaration) => {
    const itemsPath = 'goodsShipment.goodsShipmentItem';
    const itemsValue = (get(declaration, itemsPath) as unknown as any[]) || [];

    parsedItemDetails.forEach((item, index) => {
        parseSuggestedTarics(item.errors, itemsValue[index]);

        const customItemsPathPrefix = `product${index}`;

        const fields: ParsedField[] = [];

        item.errors?.forEach((parsedCard: ParsedCard) =>
            parsedCard.fields?.forEach((parsedField: ParsedField) =>
                fields.push({ ...parsedField, path: `${customItemsPathPrefix}.${parsedField.path}` })
            )
        );

        const itemFieldsWithPathPrefixes = {
            index: item.index,
            fields,
        };

        if (item.errors && item.errors.length > 0) {
            parsedItemDetails[index] = itemFieldsWithPathPrefixes;
        }
    });
};

const parseSuggestedTarics = (parsedItemDetails: ParsedCard[] | undefined, item: GoodsShipmentItem) => {
    const fields: ParsedField[] = [];

    const suggestedTaricsArrayPath = 'suggestedTarics';
    const suggestedTaricArrayValue = get(item, suggestedTaricsArrayPath) as unknown as any[];

    const commodityCodeValues: string[] = [];
    const taricCodeValues: string[] = [];

    suggestedTaricArrayValue?.forEach((taricPair) => {
        const commodityCodeValue = get(taricPair, 'commodityCode');
        commodityCodeValues.push(commodityCodeValue);

        const taricCodeValue = get(taricPair, 'taricCode');
        taricCodeValues.push(taricCodeValue);
    });

    const commodityCodePath = 'goodsInformation.combinedNomenclatureCode';
    const taricCodePath = 'goodsInformation.taricCode';

    const goodsInformationCommodityCode = get(item, commodityCodePath);
    const goodsInformationTaricCode = get(item, taricCodePath);

    if (!(goodsInformationCommodityCode && goodsInformationTaricCode)) {
        fields.push({
            name: `Commodity Code`,
            message: commodityCodeValues,
            path: commodityCodePath,
        });

        fields.push({ name: `Taric Code`, message: taricCodeValues, path: taricCodePath });
    }

    if (fields.length > 0 && parsedItemDetails) {
        parsedItemDetails.unshift({ name: 'Suggested Tarics', fields });
    }
};
