import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import customs_declarations from 'translations/customs_declarations/translations.json';
import dashboard from 'translations/dashboard/translations.json';
import common from '../translations/common/translations.json';
import customers from '../translations/customers/translations.json';
import form from '../translations/form/translations.json';
import login from '../translations/login/translations.json';
import onboarding from '../translations/onboarding/translations.json';
import settings from '../translations/settings/translations.json';
import termsAndConditions from '../translations/termsAndConditions/translation.json';
import weighbridge from '../translations/weighbridge/translations.json';
import edocket from '../translations/edocket/translations.json';

export const resources = {
    en: {
        login,
        form,
        common,
        customs_declarations,
        dashboard,
        settings,
        onboarding,
        customers,
        termsAndConditions,
        weighbridge,
        edocket,
    },
} as const;

i18n.use(initReactI18next).init({
    lng: 'en',
    ns: [
        'login',
        'form',
        'common',
        'customs_declarations',
        'dashboard',
        'settings',
        'onboarding',
        'customers',
        'termsAndConditions',
        'weighbridge',
        'edocket',
    ],
    resources,
    keySeparator: '.',
});
