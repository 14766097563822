import WCOUsersStyledComponents from '../styles/WCOUsers.styles';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { useState } from 'react';

const { DeleteButton } = WCOUsersStyledComponents;

type Props = {
    onOk: () => void | Promise<void>;
};

const DeleteUserButton = ({ onOk }: Props) => {
    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState<boolean>(false);

    const handleOpen = () => {
        setIsDeleteConfirmationModalVisible(true);
    };

    const handleClose = () => {
        setIsDeleteConfirmationModalVisible(false);
    };

    return (
        <>
            <DeleteButton onClick={handleOpen}>Delete</DeleteButton>

            <CustomModal
                title={<H5>Do you want to remove this user?</H5>}
                centered
                visible={isDeleteConfirmationModalVisible}
                onOk={() => {
                    onOk();
                    handleClose();
                }}
                onCancel={handleClose}
                width={762}
                contentText="If you remove this user, you will lose all the information associated with him."
            />
        </>
    );
};

export default DeleteUserButton;
