import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';
const ConsignmentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            title={t('consignment.title')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            state={getCardState([], props)}
        >
            <FormInput
                {...getFormikProps(`customsSubPlaceIdentifier`, props)}
                label={t('arrivalAtExit.customsSubPlaceIdentifier')}
                tooltip={t('arrivalAtExit.customsSubPlaceIdentifier')}
            />
            <FormInput
                {...getFormikProps(`goodsLocationIdentifier`, props)}
                label={t('arrivalAtExit.goodsLocationIdentifier')}
                tooltip={t('arrivalAtExit.goodsLocationIdentifier')}
            />
            <FormInput
                {...getFormikProps(`goodsLocationCode`, props)}
                label={t('arrivalAtExit.goodsLocationCode')}
                tooltip={t('arrivalAtExit.goodsLocationCode')}
            />
            <DeclarationDatepicker
                {...getFormikProps(`unloadingLocation.arrivalNotificationDate`, props)}
                label={t('arrivalAtExit.arrivalNotificationDate')}
                tooltip={t('arrivalAtExit.arrivalNotificationDate')}
            />
            <FormInput
                {...getFormikProps(`unloadingLocation.arrivalNotificationPlaceIdentifier`, props)}
                label={t('arrivalAtExit.arrivalNotificationPlaceIdentifier')}
                tooltip={t('arrivalAtExit.arrivalNotificationPlaceIdentifier')}
            />
        </FormCard>
    );
};

export default ConsignmentCard;
