import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'id' | 'categoryAndType';

export const deferredPaymentBlockValidation: {
    childValidators: {
        additionalDocument: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalDocument: {
            childValidators: {
                id: [validators.number(), validators.exact(7)],
                categoryAndType: [validators.exact(4)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const DeferredPaymentBlock = ({ path = 'additionalDocument', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '2/6', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('additionalDocumentID')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="categoryAndType">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('deferredPaymentCategory/Type')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.deferredPaymentDocuments}
                        condensed
                        codeValidation={[validators.exact(4)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default DeferredPaymentBlock;
