import { FC } from 'react';
import Icon from '@ant-design/icons';

const ChevronLeftSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.3122 3.4107V2.20289C11.3122 2.0982 11.1918 2.04039 11.1106 2.10445L4.06684 7.60601C4.007 7.65256 3.95857 7.71215 3.92526 7.78025C3.89195 7.84836 3.87463 7.92317 3.87463 7.99898C3.87463 8.0748 3.89195 8.14961 3.92526 8.21771C3.95857 8.28582 4.007 8.34541 4.06684 8.39195L11.1106 13.8935C11.1934 13.9576 11.3122 13.8998 11.3122 13.7951V12.5873C11.3122 12.5107 11.2762 12.4373 11.2168 12.3904L5.59184 7.99976L11.2168 3.60758C11.2762 3.5607 11.3122 3.48726 11.3122 3.4107Z"
            fill="#262626"
        />
    </svg>
);

const ChevronLeftIcon: FC<any> = (props) => <Icon component={ChevronLeftSvg} {...props} />;

export default ChevronLeftIcon;
