import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { AisBusinessRulesActions } from './actionType';

import { AisBusinessRule } from './ais-business-rule';

export interface AisBusinessRulesState {
    isLoading: boolean;
    error?: ErrorResponse;
    rules: ListPayload<AisBusinessRule>;
}

export const aisBusinessRulesInitialState: Readonly<AisBusinessRulesState> = {
    isLoading: false,
    rules: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const aisBusinessRules: Reducer<AisBusinessRulesState, AisBusinessRulesActions> = (
    state = aisBusinessRulesInitialState,
    action
): AisBusinessRulesState => {
    switch (action.type) {
        case 'LIST_AIS_BUSINESS_RULES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_AIS_BUSINESS_RULES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                rules: action.payload,
            };
        case 'LIST_AIS_BUSINESS_RULES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default aisBusinessRules;
