import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCountries from 'hooks/useCountries';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormSelect from '../formSelect/FormSelect';
import { normalizeCountriesToSelect } from '../selects/utils';

export interface CountryProps {
    refNumber?: string;
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    icon?: boolean;
    required?: boolean;
    placeholder?: string;
}

const CountrySelect: FC<CountryProps> = ({
    refNumber,
    label,
    fieldProps,
    icon,
    fieldMeta,
    required,
    fieldHelper,
    placeholder,
}) => {
    const { t } = useTranslation('common');
    const { countries } = useCountries();

    const countriesList = useMemo(() => normalizeCountriesToSelect(countries.list), [countries.list]);

    return (
        <>
            {countries && (
                <FormSelect
                    required={required}
                    label={!label ? t('address.country') : label}
                    placeholder={placeholder ?? ''}
                    selectOptions={countriesList}
                    fieldProps={fieldProps}
                    fieldMeta={fieldMeta}
                    fieldHelper={fieldHelper}
                />
            )}
        </>
    );
};

export default CountrySelect;
