import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import { ValidationSchema, validators } from '../../export/validations/validations';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'dutyRegimeCode';

export const preferenceBlockValidation: {
    childValidators: {
        'commodity.dutyTaxFee': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.dutyTaxFee': {
            childValidators: {
                dutyRegimeCode: [validators.number(), validators.exact(3)],
            },
        },
    },
};

export const preferenceBlockRequiredValidation = {
    childValidators: {
        dutyRegimeCode: [validators.required(), validators.number(), validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const PreferenceBlock = ({ declarationOptions, path = 'commodity.dutyTaxFee' }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '4/17', []);
    return (
        <>
            <DeclarationField name="dutyRegimeCode" pathPrefix={path}>
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('preference')}
                        {...getFormikProps(field.name, form)}
                        condensed
                        required={declarationOptions?.[formType]?.fieldOptions?.dutyRegimeCode?.required}
                        selectOptions={cdsCodelists?.preferenceTypes}
                        codeValidation={[validators.number(), validators.exact(3)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default PreferenceBlock;
