import { Form } from 'antd';
import PhoneNumber from 'awesome-phonenumber';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import { TextExtraSmallBold } from 'components/ui/base/typography';
import FormEori from 'components/ui/composed/formEori/FormEori';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { InputLabelRequired } from 'components/ui/composed/formInput/FormInput.styles';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import FormTextArea from 'components/ui/composed/formTextArea/FormTextArea';
import { ErrorResponse } from 'core/http/response';
import { objectIsEmpty, removeEmptyStringFromObject } from 'core/utils/objects';
import { setNestedObjectValues, useFormik } from 'formik';
import useLoadingButton from 'hooks/useLoadingButton';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCustomer, editCustomer } from 'store/customers/client';
import { Customer, CustomerType, customerTypeLabels } from 'store/customers/customer';
import { Eori } from 'store/eori/eori';
import { Address } from 'store/individuals/individual';
import CustomerAddressForm from 'views/customers/components/CustomerAddressForm';
import {
    getEoriValidation,
    getExactLengthValidation,
    invalidEoriMessage,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import useSession from '../../../hooks/useSession';
interface Props {
    refreshCustomers: Function;
    closeModal: () => void;
    handleOk: (newCustomer?: any) => void;
    handleEdit?: (customer?: any) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    customer?: Customer;
}

const customerSchema = (phoneCountryCode?: string) => {
    return Yup.object().shape({
        referenceCode: getExactLengthValidation('referenceCode', 3).required('Reference code is required.').nullable(),
        eori: getEoriValidation().nullable(),
        name: Yup.string().required('Customer name is required.').nullable(),
        type: Yup.string().required('Customer type is required.').nullable(),
        contact: Yup.object()
            .shape(
                {
                    name: Yup.string().required('Contact name is required.').nullable(),
                    email: Yup.string()
                        .email('Email is invalid.')
                        .when('phoneNumber', (phone) => {
                            if (!phone) {
                                return Yup.string().required('Phone number or email is required').nullable();
                            }
                            return Yup.string().nullable();
                        })
                        .nullable(),
                    phoneNumber: Yup.number()
                        .when('email', (email) => {
                            if (!email) {
                                return Yup.string().required('Phone number or email is required').nullable();
                            }
                            return Yup.string().nullable();
                        })
                        .nullable()
                        .when('phoneCountryCode', (phoneCountryCode) => {
                            return phoneCountryCode && Yup.number().required('Phone number is required.');
                        })
                        .nullable(),
                },
                [['phoneNumber', 'email']]
            )
            .required('Contact is required'),
        address: Yup.object()
            .shape({
                addressLine1: Yup.string().when('address', (address) => {
                    if (!address?.addressLine1) {
                        return Yup.string().required('Address Line 1 is required');
                    }
                    return Yup.string().nullable();
                }),
                city: Yup.string().when('address', (address) => {
                    if (!address?.city) {
                        return Yup.string().required('City is required');
                    }
                    return Yup.string().nullable();
                }),
                postCode: Yup.string().when('address', (address) => {
                    if (!address?.postCode) {
                        return Yup.string().required('Post Code is required');
                    }
                    return Yup.string().nullable();
                }),
                country: Yup.string().when('address', (address) => {
                    if (!address?.country) {
                        return Yup.string().required('Country is required');
                    }
                    return Yup.string().nullable();
                }),
            })
            .default(undefined),
    });
};

const CreateNewCustomer: FC<Props> = ({
    refreshCustomers,
    closeModal,
    handleOk,
    customer,
    handleEdit,
    handleCreateError,
    handleEditError,
}) => {
    const [phoneCountryCode, setPhoneCountryCode] = useState<string | undefined>();
    const { t } = useTranslation('customers');

    const {
        getFieldProps,
        resetForm,
        getFieldMeta,
        setValues,
        errors,
        values,
        validateForm,
        setTouched,
        setFieldError,
        setFieldValue,
        setFieldTouched,
        getFieldHelpers,
        submitForm,
    } = useFormik<Partial<Customer & { phoneNumber?: string; phoneCountryCode?: string }>>({
        initialValues: {
            contact: {},
        },
        validationSchema: customerSchema(phoneCountryCode),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createNewCustomerSubmit(values),
    });
    const { customerId } = useSession();

    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: submitForm });

    const {
        data: newCustomer,
        doRequest: doCreateCustomer,
        isLoading: loadingCreate,
        error: createError,
    } = useRequest(createCustomer);

    const {
        data: editedCustomer,
        doRequest: doEditCustomer,
        isLoading: loadingEdit,
        error: editError,
    } = useRequest(editCustomer);

    useEffect(() => {
        if (!customer) {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubErrors = (err: ErrorResponse) => {
        err?.subErrors?.forEach((e) => {
            if (
                e.field === 'city' ||
                e.field === 'postCode' ||
                e.field === 'country' ||
                e.field === 'addressLine1' ||
                e.field === 'addressLine2'
            ) {
                setFieldError(`address.${e.field}`, e.message);
                setFieldTouched(`address.${e.field}`, true, false);
            } else {
                setFieldError(e.field, e.message);
            }
        });
    };
    const createNewCustomerSubmit = async (
        values: Partial<Customer & { contact: { phoneCountryCode?: string; phoneNumber?: string } }>
    ) => {
        let body = {
            ...values,
            brokerIds: [customerId],
        };

        if (!body.type) {
            body.type = CustomerType.TRADER;
        }

        if (values.contact?.phoneCountryCode && values.contact?.phoneNumber) {
            const contact = { ...body.contact };

            body = {
                ...body,
                contact: {
                    ...contact,
                    phoneNumber: `${values.contact.phoneCountryCode}${values.contact.phoneNumber}`,
                },
            };
        }

        if (body.address) {
            const { address } = body;
            body.address = removeEmptyStringFromObject(address);
            if (objectIsEmpty(body.address)) {
                delete body['address'];
            }
        }

        if (customer && customer.id) {
            await doEditCustomer(customer.id, body as Partial<Customer>);
        } else {
            await doCreateCustomer(body as Customer);
        }
    };

    useEffect(() => {
        if (loadingCreate === false && newCustomer !== null) {
            if (!createError) {
                refreshCustomers();
                resetForm();
                handleOk(newCustomer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreate, newCustomer, createError]);

    useEffect(() => {
        setPhoneCountryCode(values.contact?.phoneCountryCode);
    }, [values]);

    useEffect(() => {
        if (loadingEdit === false && editedCustomer !== null && handleEdit) {
            if (!editError) {
                refreshCustomers();
                resetForm();
                handleEdit(editedCustomer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEdit, editedCustomer, editError]);

    useEffect(() => {
        if (createError && handleCreateError) {
            handleCreateError(createError);
            const err: ErrorResponse = { ...createError };
            handleSubErrors(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createError]);

    useEffect(() => {
        if (editError && handleEditError) {
            handleEditError(editError);
            const err: ErrorResponse = { ...editError };
            handleSubErrors(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editError]);

    useEffect(() => {
        if (customer?.contact) {
            if (customer.contact?.phoneNumber) {
                const phone = new PhoneNumber(customer.contact.phoneNumber);
                const data = {
                    ...customer,
                    contact: {
                        ...customer.contact,
                        phoneCountryCode: `+${phone.getCountryCode().toString()}`,
                        phoneNumber: phone.getNumber('significant'),
                    },
                };
                setValues(data);
            } else {
                setValues(customer);
            }
        } else {
            setValues({ ...customer, contact: {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const onCloseButton = () => {
        closeModal();
        resetForm();
    };

    const checkRequired = () => {
        return !!!getFieldProps('contact.phoneNumber').value && !!!getFieldProps('contact.email').value;
    };

    const handleConfirm = async () => {
        const validation = await validateForm();
        if (Object.keys(validation).length > 0) {
            setTouched(setNestedObjectValues(validation, true));
        }

        if (objectIsEmpty(validation)) {
            await handleOnPressButton(); //handleSubmit();
        } else {
            console.log(errors);
        }
    };

    const fillEoriInfo = useCallback(
        (eori: Eori) => {
            setFieldValue('name', eori?.name);
            setFieldValue('eori', eori?.eori);
            setFieldValue('address.addressLine1', eori?.street);
            setFieldValue('address.city', eori?.city);
            setFieldValue('address.postCode', eori?.postCode);
        },
        [setFieldValue]
    );

    const onError = useCallback(() => {
        setFieldError('eori', invalidEoriMessage);
    }, [setFieldError]);

    const onSuccess = useCallback(() => {
        setFieldError('eori', undefined);
    }, [setFieldError]);

    const hasValues = useMemo(
        () =>
            !!getFieldProps('name').value ||
            !!getFieldProps('address.addressLine1').value ||
            !!getFieldProps('address.city').value ||
            !!getFieldProps('address.postCode').value,
        [getFieldProps]
    );

    useEffect(() => {
        const { address } = values;

        const checkAddressWithAllFieldsEmpty = (values: Address) => {
            const isNotEmpty = Object.entries(values).filter(([key, value]) => value?.length > 0);
            return isNotEmpty.length === 0;
        };

        if (address && (objectIsEmpty(address) || checkAddressWithAllFieldsEmpty(address))) {
            const newValues = { ...values, address: undefined };
            setValues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <Form layout="vertical" style={{ paddingRight: '4rem' }}>
            <FormInput
                required
                label="Customer name"
                fieldMeta={getFieldMeta('name')}
                fieldProps={getFieldProps('name')}
            />

            <FormSelect
                required
                hideKeys
                label="Customer type"
                fieldMeta={getFieldMeta('type')}
                fieldProps={getFieldProps('type')}
                selectOptions={customerTypeLabels}
            />

            <FormEori
                hasValues={hasValues}
                fieldMeta={getFieldMeta('eori')}
                fieldProps={getFieldProps('eori')}
                fieldHelper={getFieldHelpers('eori')}
                onApply={fillEoriInfo}
                onError={onError}
                onSuccess={onSuccess}
                tooltip={t('common.eori_tooltip')}
            />

            <FormInput
                required
                label="Reference Code"
                maxLength={3}
                tooltip={t('common.reference_code_info')}
                fieldMeta={getFieldMeta('referenceCode')}
                fieldProps={getFieldProps('referenceCode')}
            />

            <FormInput
                label="Trader Account Number"
                fieldMeta={getFieldMeta('traderAccountNumber')}
                fieldProps={getFieldProps('traderAccountNumber')}
                tooltip={t('common.trader_account_number_tooltip')}
            />

            <FormInput
                label="Deferred account number"
                fieldMeta={getFieldMeta('deferredAccountNumber')}
                fieldProps={getFieldProps('deferredAccountNumber')}
                tooltip={t('common.deferred_account_number_tooltip')}
            />

            <FormInput label="VAT Number" fieldMeta={getFieldMeta('vat')} fieldProps={getFieldProps('vat')} />
            <FormInput
                required
                label="Contact name"
                fieldMeta={getFieldMeta('contact.name')}
                fieldProps={getFieldProps('contact.name')}
            />
            <p>
                <InputLabelRequired>*</InputLabelRequired>{' '}
                <TextExtraSmallBold>An email and/or Phone number is required</TextExtraSmallBold>
            </p>
            <FormInput
                required={checkRequired()}
                label="Email Address"
                fieldMeta={getFieldMeta('contact.email')}
                fieldProps={getFieldProps('contact.email')}
            />
            <FormPhoneNumber
                required={checkRequired()}
                fieldCodeProps={getFieldProps('contact.phoneCountryCode')}
                fieldCodeMeta={getFieldMeta('contact.phoneCountryCode')}
                fieldNumberProps={getFieldProps('contact.phoneNumber')}
                fieldNumberMeta={getFieldMeta('contact.phoneNumber')}
            />
            <CustomerAddressForm
                fieldProps={{
                    addressLine1: getFieldProps('address.addressLine1'),
                    addressLine2: getFieldProps('address.addressLine2'),
                    postCode: getFieldProps('address.postCode'),
                    city: getFieldProps('address.city'),
                    country: getFieldProps('address.country'),
                }}
                fieldMeta={{
                    addressLine1: getFieldMeta('address.addressLine1'),
                    addressLine2: getFieldMeta('address.addressLine2'),
                    postCode: getFieldMeta('address.postCode'),
                    city: getFieldMeta('address.city'),
                    country: getFieldMeta('address.country'),
                }}
                label={{
                    addressLine1: 'Address Line 1',
                    addressLine2: 'Address Line 2',
                    postCode: 'Post Code',
                    city: 'City',
                    country: 'Country',
                }}
            />

            <FormTextArea
                label="Special instructions"
                fieldMeta={getFieldMeta('specialInstructions')}
                fieldProps={getFieldProps('specialInstructions')}
            />
            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button size="large" type="primary" onClick={() => handleConfirm()} loading={loading}>
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateNewCustomer;
