import { Button, Drawer } from 'antd';
import { H5 } from 'components/ui/base/typography';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { setNestedObjectValues, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import axiosClient from 'config/axios';
import config from 'config';
import { Template } from 'store/template/template';
import editTemplateNameValidation from '../validations/editTemplateNameValidation';
import useTemplates from 'hooks/useTemplates';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import getAllDeclarationTemplateNames from 'utils/requests/getAllDeclarationTemplateNames';
import { declarationExternalTypeToCountry } from 'components/ui/composed/template/template-utils';
import { MessageTypes } from '../../../store/declarations/enums/common/template-types';

const Container = styled.div`
    margin-top: 2rem;
    > div:nth-child(even) {
        margin-bottom: 1rem;
    }
`;

interface Props {
    visible: boolean;
    onClose: () => void;
    template?: Template;
}

const EditTemplateNameDrawer = ({ visible, onClose, template }: Props): ReactElement => {
    const { listTemplates } = useTemplates();

    const [templateNames, setTemplateNames] = useState<Set<string> | undefined>();
    useEffect(() => {
        getAllDeclarationTemplateNames({
            templateData: {
                country: declarationExternalTypeToCountry(template?.declarationExternalType),
                declarationType: template?.declarationInternalType,
                formType: template?.declarationName as MessageTypes,
            },
        }).then(setTemplateNames);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template?.id]);

    const formik = useFormik({
        initialValues: { templateName: template?.templateName },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: () => {},
        validationSchema: editTemplateNameValidation(templateNames),
    });

    const handleCancel = () => {
        onClose();
        formik.resetForm();
    };

    const handleEdit = async () => {
        const valid = await formik.validateForm();
        if (!isEmpty(valid)) {
            formik.setTouched(setNestedObjectValues(valid, true));
            return;
        }

        if (!template) return;

        // Edit template name
        let country = template.declarationExternalType === 'REVENUE' ? 'ireland' : 'cds';

        if (country === 'ireland' && template.declarationInternalType?.toLowerCase() === 'export') country = 'ie';

        const type = template.declarationInternalType?.toLowerCase();
        const formType = template.declarationName as MessageTypes; /**declarationName is formType (H1, H7, B1 etc..) */
        await axiosClient.put(`${config.declarationTemplatesUrl}/${country}/${type}/${template?.id}`, {
            ...template,
            ...formik.values,
        });

        // Refresh listing
        await listTemplates(
            country === 'cds' ? DeclarationCountry.UK : DeclarationCountry.IRELAND,
            type?.toUpperCase() as DeclarationInternalType,
            formType
        );

        onClose();
    };

    return (
        <Drawer visible={visible} onClose={onClose} placement="right">
            <H5>Edit Template Name</H5>
            <Container>
                <DeclarationInput
                    required
                    {...getFormikProps(`templateName`, formik)}
                    condensed
                    label="Template Name"
                />
            </Container>

            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" onClick={handleEdit}>
                    Edit
                </Button>
            </div>
        </Drawer>
    );
};

export default EditTemplateNameDrawer;
