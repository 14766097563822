import { Col as AntCol } from 'antd';
import { TextExtraSmall } from 'components/ui/base/typography';
import { RoutingStatus as RoutingStatusEnum } from 'store/declarations/declaration';
import styled from 'styled-components';
import { colors, fonts } from 'theme';
import Button from '../../../components/ui/base/button/Button';

interface StatusProps {
    $status: RoutingStatusEnum;
}

interface CollapseProps {
    $collapsed?: boolean;
}

export const MainDiv = styled.div<CollapseProps>`
    width: 100%;
    background: ${colors.white};
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 0px 24px rgba(9, 0, 255, 0.07);
    border-radius: 4px;
    margin-bottom: 0.8rem;
    padding: 10px 10px 10px 10px;
    min-height: ${(props) => (props.$collapsed ? '4.5rem' : '9.5rem')};
`;

export const HeaderTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 2.4rem;
`;

export const TimeDiv = styled.div`
    background: #ffa318;
    color: ${colors.white};
    min-width: 8.3rem;
    height: 24px;
    padding: 0px 8px;
    margin-left: 1rem;
    border-radius: 0.4rem;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
`;

export const StyledInfoButton = styled(Button)`
    padding: 0;
    width: fit-content;
    font-size: 11px;
`;

export const FieldGroupDiv = styled.div`
    display: flex;
    align-items: center;
    line-height: 2.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FieldGroupRemarks = styled.div`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FieldTextRemarks = styled.div``;

export const SectionButton = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Title = styled.h6`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
`;
export const FieldsLabels = styled(TextExtraSmall)`
    font-size: 1.1rem;
`;

export const StatusFieldsLabels = styled(TextExtraSmall)`
    font-size: 1.1rem;
    margin-right: 1rem;
`;

export const FieldsText = styled(TextExtraSmall)`
    margin-left: 0.5rem;
    font-size: 1.1rem;
    font-weight: ${fonts.weight.regular};
`;

const getRoutingStatusColor = (status: RoutingStatusEnum) => {
    switch (status) {
        case RoutingStatusEnum.RED:
            return colors.red;
        case RoutingStatusEnum.GREEN:
            return colors.darkGreen1;
        case RoutingStatusEnum.ORANGE:
            return colors.orange1;
        case RoutingStatusEnum.YELLOW:
            return colors.yellow;
        default:
            return colors.grey;
    }
};

export const RoutingStatus = styled.span<StatusProps>`
    width: 1.6rem;
    height: 1.6rem;
    background-color: ${({ $status }) => getRoutingStatusColor($status)};
    border-radius: 50%;
    display: inline-block;
`;

export const ErrorButton = styled.span`
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: center;
    color: ${colors.error};
    cursor: pointer;
    margin-right: 2.9rem;
`;

export const HideButton = styled(Button)`
    bottom: 0;
`;

export const CollapseButton = styled(Button)`
    margin-left: 1rem;
`;

export const Col = styled(AntCol)`
    padding-right: 1.5rem !important;
`;

export const ReverseCol = styled(Col)`
    flex-direction: row-reverse;
`;

export const DivHideButtom = styled.div`
    display: flex;
    justify-content: end;
    align-items: end;
    height: 100%;
`;
