import { useAppDispatch, useAppSelector } from 'config/hooks';
import { useCallback, useEffect } from 'react';
import { doCreateJourney, doDeleteJourney, doEditJourney, doGetJourney, doListJourneys } from 'store/journeys/action';
import { Journey, JourneyParams } from 'store/journeys/journeys';

interface UseJourneysProps {
    journeyId?: string;
}

const useJourneys = ({ journeyId }: UseJourneysProps = {}) => {
    const journeys = useAppSelector((state) => state.journeys.journeys);
    const journey = useAppSelector((state) => (journeyId ? state.journeys.entities[journeyId] : undefined));
    const isLoading = useAppSelector((state) => state.journeys.isLoading);
    const error = useAppSelector((state) => state.journeys.error);

    const dispatch = useAppDispatch();

    const listJourneys = useCallback(
        (params?: Partial<JourneyParams>) => {
            return dispatch(doListJourneys(params));
        },
        [dispatch]
    );

    const createJourney = useCallback(
        (journey: Journey) => {
            return dispatch(doCreateJourney(journey));
        },
        [dispatch]
    );

    const editJourney = useCallback(
        (journey: Journey) => {
            return dispatch(doEditJourney(journey));
        },
        [dispatch]
    );

    const getJourney = useCallback(
        (journeyID: String) => {
            return dispatch(doGetJourney(journeyID));
        },
        [dispatch]
    );

    const deleteJourney = useCallback(
        (journeyID: String) => {
            return dispatch(doDeleteJourney(journeyID));
        },
        [dispatch]
    );

    useEffect(() => {
        if (journeyId && journeyId !== journey?.id) {
            getJourney(journeyId);
        }
    }, [journeyId, getJourney, journey]);

    return {
        journeys,
        journey,
        listJourneys,
        createJourney,
        editJourney,
        getJourney,
        deleteJourney,
        isLoading,
        error,
    };
};
export default useJourneys;
