import { CopyOutlined, ContainerOutlined, PrinterOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Button from 'components/ui/base/button';
import Tooltip from 'components/ui/base/tooltip/Tooltip';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { enumToText } from 'core/utils/enum-to-text';
import { FC, MouseEventHandler, ReactElement, useCallback, useMemo } from 'react';
import { Declaration, DeclarationHistory, DeclarationSortParameters } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';
import {
    getDeclarantName,
    getDeclarationName,
    getDeclarationPayload,
    getExporter,
    getImporter,
    getMrn,
    getReferenceNumber,
} from 'views/declarations/utils/declaration-utils';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import DeclarationDocumentsStatusTag from '../../../components/ui/composed/declarations/declaration-documents/DeclarationDocumentsStatusTag';
import {
    DivTableActionInline,
    StyledCopyOutlined,
    StyledFileOutlined,
    StyledIsCoreTemplate,
} from '../DeclarationDashboard.styles';
import DeclarationDate from './components/DeclarationDate';
import { FlexDiv, SpanEllipsis, SpanMR5Icon, StyledButton, TooltipDiv } from './components/DeclarationsTable.styled';
import DescriptionOfGoods from './components/DescriptionOfGoods';
import ExporterIcon from './components/icons/ExporterIcon';
import ImporterIcon from './components/icons/ImporterIcon';
import { DeclarationTableType } from './DeclarationsTable';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

interface ActionEvents {
    onDuplicate?: (record: any) => void;
    onArchive?: (recordIds: string[]) => void;
    onPrint?: (recordId: string) => void;
    onMirror?: (record: any) => void;
    onDelete?: (record: any) => void;
    onUnarchive?: (record: any) => void;
}

export const getDeclarationStyle = (record: any) => {
    const declarationStatus = record.status ?? record.declaration.status;
    const documentsUploadRequested =
        record?.irelandImportDeclaration?.documentsUploadRequested ??
        record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
        record?.cdsExportDeclaration?.documentsUploadRequested;

    if (documentsUploadRequested) {
        return { style: { background: `${colors.lightOrange2}`, border: 'none' } };
    }

    if (declarationStatus === DeclarationStatus.REJECTED || declarationStatus === DeclarationStatus.INVALID) {
        return { style: { background: `${colors.invalid}`, border: 'none' } };
    }

    return {};
};

export const getInternalType = (declaration: Declaration) => {
    if (declaration.declarationInternalType === 'IMPORT_NEW') {
        return enumToText('IMPORT');
    }
    return enumToText(declaration.declarationInternalType);
};

const getDeclarationType = (declaration: Declaration) => {
    const internalType = getInternalType(declaration);
    const name = getDeclarationName(declaration);
    const country = declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK';
    const declarationType = `${internalType} (${name})`;
    return (
        <FlexDiv>
            <SpanEllipsis>{country}</SpanEllipsis>
            <SpanEllipsis>{declarationType}</SpanEllipsis>
        </FlexDiv>
    );
};

const FullItemDescription: FC<{ declaration: Declaration }> = ({ declaration }) => (
    <TooltipDiv>
        <DescriptionOfGoods fullDescription declaration={declaration} />
    </TooltipDiv>
);

const DeclarationIds: FC<{ declaration: Declaration }> = ({ declaration }) => {
    const mrn = getMrn(declaration);
    const ref = getReferenceNumber(declaration);
    return (
        <FlexDiv>
            <SpanEllipsis>Ref: {ref}</SpanEllipsis>
            <SpanEllipsis>MRN: {mrn}</SpanEllipsis>
        </FlexDiv>
    );
};

const DeclarationDescription: FC<{ declaration: Declaration }> = ({ declaration }) => {
    return (
        <FlexDiv>
            <DescriptionOfGoods declaration={declaration} />
        </FlexDiv>
    );
};

const ImporterAndExporterInformation: FC<{ declaration: Declaration; dataTestId?: string }> = ({
    declaration,
    dataTestId,
}) => {
    let payload = useMemo(() => getDeclarationPayload(declaration), [declaration]);
    if (!payload) return <></>;

    payload = payload?.cdsExportDeclarationPayload ?? payload;

    const importer = getImporter(payload);
    const exporter = getExporter(payload);

    return (
        <FlexDiv data-testid={dataTestId}>
            <Row align="middle" wrap={false}>
                <SpanMR5Icon>
                    <ImporterIcon />
                </SpanMR5Icon>
                <SpanEllipsis> {importer?.name ?? importer?.eori ?? 'N/A'}</SpanEllipsis>
            </Row>
            <Row align="middle" wrap={false}>
                <SpanMR5Icon>
                    <ExporterIcon />
                </SpanMR5Icon>
                <SpanEllipsis> {exporter?.name ?? exporter?.eori ?? 'N/A'}</SpanEllipsis>
            </Row>
        </FlexDiv>
    );
};

const AgentAndDeclarant: FC<{ declaration: any; dataTestId?: string }> = ({ declaration, dataTestId }) => {
    const agent = declaration?.individual?.name ?? '-';
    const payload = getDeclarationPayload(declaration);
    const declarant = getDeclarantName(payload);
    return (
        <FlexDiv data-testid={dataTestId}>
            <SpanEllipsis>{agent}</SpanEllipsis>
            <SpanEllipsis>{declarant}</SpanEllipsis>
        </FlexDiv>
    );
};

const StyledLink = styled(Link)`
    transition: 100ms;
    border-radius: 50%;
    padding: 0.75rem;
    display: flex;

    :hover {
        background-color: #e6e9ed;
    }
`;

const dataColumns: ColumnsType<any> = [
    {
        title: 'Decl. Type',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-type-row`;
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }} data-testid={dataTestId}>
                            <SpanEllipsis>{getDeclarationType(record)}</SpanEllipsis>
                            {record.parentDeclarationId && (
                                <StyledLink
                                    to={`/declarations/${record.parentDeclarationId}`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <LinkOutlined />
                                </StyledLink>
                            )}
                        </div>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Id',
        dataIndex: DeclarationSortParameters.ID,
        key: DeclarationSortParameters.ID,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-id-row`;
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <SpanEllipsis data-testid={dataTestId}>
                            <DeclarationIds declaration={record} />
                        </SpanEllipsis>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Descr. of goods',
        dataIndex: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        key: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-description-row`;
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<FullItemDescription declaration={record} />}
                        >
                            <SpanEllipsis data-testid={dataTestId}>
                                <DeclarationDescription declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: 'Decl. Status',
        dataIndex: DeclarationSortParameters.STATUS,
        key: DeclarationSortParameters.STATUS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-status-row`;
            return record.status ? (
                {
                    props: getDeclarationStyle(record),
                    children: <DeclarationStatusTag status={record.status} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: 'Importer & Exporter',
        dataIndex: DeclarationSortParameters.EXPORTER,
        key: DeclarationSortParameters.EXPORTER,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-importer-and-exporter-row`;
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <ImporterAndExporterInformation declaration={record} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Agent & Declarant',
        dataIndex: DeclarationSortParameters.DECLARANT,
        key: DeclarationSortParameters.DECLARANT,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-agent-and-declarant-row`;
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <AgentAndDeclarant declaration={record} dataTestId={dataTestId} />,
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Documents',
        dataIndex: DeclarationSortParameters.DOCUMENTS,
        key: DeclarationSortParameters.DOCUMENTS,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-documents-row`;
            return record.status ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <DeclarationDocumentsStatusTag
                            documentsUploadRequested={
                                record?.irelandImportDeclaration?.documentsUploadRequested ??
                                record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
                                record?.cdsExportDeclaration?.documentsUploadRequested
                            }
                            uploadedDocuments={record.uploadedFiles}
                            dataTestId={dataTestId}
                        />
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Last Modified date',
        dataIndex: DeclarationSortParameters.CREATED_AT,
        key: DeclarationSortParameters.CREATED_AT,
        render: (text: string, record: any) => {
            const dataTestId = `declaration-last-modified-date-row`;
            return record.createdAt ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<DeclarationDate createdDate declaration={record} />}
                        >
                            <SpanEllipsis data-testid={dataTestId}>
                                <DeclarationDate declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis data-testid={dataTestId}>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
];

const getArchivedActions = (handleCoreTemplate?: Function, setAsTemplate?: Function, viewDocuments?: Function) => [
    {
        title: 'Commands',
        dataIndex: 'actions',
        key: 'actions',
        _render: (text: string, record: any, index: number) => (
            <DivTableActionInline>
                <Tooltip placement="top" title={record.isCoreTemplate ? 'Unmark as core' : 'Mark as core'}>
                    <StyledIsCoreTemplate
                        $isCoreTemplate={record.isCoreTemplate}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            handleCoreTemplate && handleCoreTemplate(record.id!);
                        }}
                    ></StyledIsCoreTemplate>
                </Tooltip>
                <Tooltip placement="top" title={'Use as template'}>
                    <StyledCopyOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            setAsTemplate && setAsTemplate(record.id!, DeclarationTableType.ARCHIVED);
                        }}
                    ></StyledCopyOutlined>
                </Tooltip>
                <Tooltip placement="top" title={'Documents'}>
                    <StyledFileOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            viewDocuments && viewDocuments(record.id!);
                        }}
                    ></StyledFileOutlined>
                </Tooltip>
            </DivTableActionInline>
        ),
        get render() {
            return this._render;
        },
        set render(value) {
            this._render = value;
        },
    },
];

const getCoreTemplateActions = (handleCoreTemplate?: Function, setAsTemplate?: Function, viewDocuments?: Function) => [
    {
        title: 'Commands',
        dataIndex: 'actions',
        key: 'actions',
        render: (text: string, record: any, index: number) => {
            return (
                <DivTableActionInline>
                    <Tooltip placement="top" title={record.isCoreTemplate ? 'Unmark as core' : 'Mark as core'}>
                        <StyledIsCoreTemplate
                            $isCoreTemplate={record.isCoreTemplate}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCoreTemplate && handleCoreTemplate(record.id!);
                            }}
                        ></StyledIsCoreTemplate>
                    </Tooltip>
                    <Tooltip placement="top" title={'Use as template'}>
                        <StyledCopyOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                                setAsTemplate && setAsTemplate(record.id!, DeclarationTableType.CORE_TEMPLATE);
                            }}
                        ></StyledCopyOutlined>
                    </Tooltip>
                    <Tooltip placement="top" title={'Documents'}>
                        <StyledFileOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                                viewDocuments && viewDocuments(record.id!);
                            }}
                        ></StyledFileOutlined>
                    </Tooltip>
                </DivTableActionInline>
            );
        },
    },
];

const DeleteButton = ({ record, onClick }: { record: any; onClick?: (recordId: string) => void }): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record.id);
        },
        [onClick, record.id]
    );

    const disabled = record.status !== DeclarationStatus.DRAFT;
    return (
        <Tooltip title={disabled ? '' : 'Delete'}>
            <StyledButton disabled={disabled} size="small" onClick={handleClick} testId="declaration-delete-button-row">
                <DeleteOutlined />
            </StyledButton>
        </Tooltip>
    );
};

const PrintButton = ({ record, onClick }: { record: any; onClick?: (recordId: string) => void }): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record.id);
        },
        [onClick, record.id]
    );

    const disabled = record.status === DeclarationStatus.DRAFT;
    return (
        <Tooltip title={disabled ? '' : 'Documents'}>
            <StyledButton disabled={disabled} size="small" onClick={handleClick} testId="declaration-print-button-row">
                <PrinterOutlined />
            </StyledButton>
        </Tooltip>
    );
};
const DuplicateButton = ({ record, onClick }: { record: any; onClick?: (record: any) => void }): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );
    return (
        <Tooltip title="Duplicate">
            <StyledButton size="small" onClick={handleClick} testId="declaration-duplicate-button-row">
                <CopyOutlined />
            </StyledButton>
        </Tooltip>
    );
};
const ArchiveButton = ({ record, onClick }: { record: any; onClick?: (recordIds: string[]) => void }): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.([record.id]);
        },
        [onClick, record.id]
    );
    return (
        <Tooltip title="Archive">
            <Button size="small" onClick={handleClick} testId="declaration-archive-button-row">
                <ContainerOutlined />
            </Button>
        </Tooltip>
    );
};
const UnarchiveButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (recordIds: string[]) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.([record.id]);
        },
        [onClick, record.id]
    );
    return (
        <Tooltip title="Unarchive">
            <Button size="small" onClick={handleClick} testId="declaration-unarchive-button-row">
                <ContainerOutlined />
            </Button>
        </Tooltip>
    );
};

const MirrorButton = ({ record, onClick }: { record: any; onClick?: (record: any) => void }): ReactElement | null => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );

    const disabled = record.status !== DeclarationStatus.RELEASED && record.status !== DeclarationStatus.RISKED;

    // TODO Mirroring - Show when necessary
    const hidden = record.cdsExportDeclaration?.messageType !== 'B1';

    if (hidden) return null;

    return (
        <Tooltip title={disabled ? '' : 'Mirror'}>
            <StyledButton disabled={disabled} size="small" onClick={handleClick} testId="declaration-mirror-button-row">
                <LinkOutlined />
            </StyledButton>
        </Tooltip>
    );
};

const getActionsColumns = (actions: ActionEvents) => {
    return [
        {
            title: 'Commands',
            dataIndex: 'Actions',
            key: 'Actions',
            width: 180,
            align: 'right' as any,
            render: (text: string, record: any) => {
                return {
                    props: getDeclarationStyle(record),
                    children: (
                        <DivTableActionInline data-testid="declaration-commands-row">
                            {actions.onPrint && <PrintButton record={record} onClick={actions.onPrint} />}
                            {actions.onMirror && <MirrorButton record={record} onClick={actions.onMirror} />}
                            {actions.onDelete && <DeleteButton record={record} onClick={actions.onDelete} />}
                            {actions.onDuplicate && <DuplicateButton record={record} onClick={actions.onDuplicate} />}
                            {actions.onArchive && <ArchiveButton record={record} onClick={actions.onArchive} />}
                            {actions.onUnarchive && <UnarchiveButton record={record} onClick={actions?.onUnarchive} />}
                        </DivTableActionInline>
                    ),
                };
            },
        },
    ];
};

const historyColumns: ColumnsType<DeclarationHistory> = [
    {
        title: 'Type',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: DeclarationHistory, index: number) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <SpanEllipsis>{toTitleCase(record.type)}</SpanEllipsis>,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Date',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: DeclarationHistory, index: number) => {
            const date = record.createdAt ? new Date(record.createdAt) : null;
            return date ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <FlexDiv>
                            <span>{getHour(date)}</span>
                            <span>{dateStandardFormat(date)}</span>
                        </FlexDiv>
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
];

export const dashboardColumns = (actions: ActionEvents) => {
    return !isEmpty(actions) ? [...dataColumns, ...getActionsColumns(actions)] : [...dataColumns];
};

export const declarationHistoryColums = () => {
    return [...historyColumns];
};

export const declarationColumsArchived = (
    handleCoreTemplate?: Function,
    setAsTemplate?: Function,
    viewDocuments?: Function
) => {
    const cols = dataColumns.filter((col) => col.title !== 'Decl. Status');
    const archivedActions = getArchivedActions(handleCoreTemplate, setAsTemplate, viewDocuments);
    return [...cols, ...archivedActions];
};

export const declarationColumnsCoreTemplate = (
    handleCoreTemplate?: Function,
    setAsTemplate?: Function,
    viewDocuments?: Function
) => {
    const cols = dataColumns.filter((col) => col.title !== 'Decl. Status');
    const coreTemplateActions = getCoreTemplateActions(handleCoreTemplate, setAsTemplate, viewDocuments);
    return [...cols, ...coreTemplateActions];
};

export default dashboardColumns;
