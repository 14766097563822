import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';

interface Props extends DeclarationFormCardProps {
    hideUnload?: boolean;
}

const ConsignmentCard = (props: Props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('consignment.title')}>
            <FormCardContainer>
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.consignment.loadingPlace`, props)}
                    label="Loading place"
                    condensed
                />
                {!props.hideUnload && (
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`goodsShipment.consignment.unloadingPlace`, props)}
                        label="Unloading place"
                        condensed
                    />
                )}
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ConsignmentCard;
