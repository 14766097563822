import Divider from 'components/ui/base/divider';
import Progress from '../../../components/ui/base/progress/Progress';
import { LegendSpan, MainProgressDiv, ProgressDiv, ViewButton } from './DriverProgress.styles';

const DriverProgress = () => {
    return (
        <MainProgressDiv>
            <ProgressDiv>
                <Progress strokeLinecap="square" type="circle" percent={75} format={(percent) => `${percent}`} />
                <LegendSpan>Driver Availability</LegendSpan>
                <ViewButton type="primary">Book Job</ViewButton>
            </ProgressDiv>
            <ProgressDiv>
                <Divider type="vertical" style={{ height: '100%' }} />
            </ProgressDiv>
            <ProgressDiv>
                <Progress strokeLinecap="square" type="circle" percent={65} format={(percent) => `${percent}`} />
                <LegendSpan>Delayed</LegendSpan>
                <ViewButton>View</ViewButton>
            </ProgressDiv>
            <ProgressDiv>
                <Progress strokeLinecap="square" type="circle" percent={30} format={(percent) => `${percent}`} />
                <LegendSpan>Free Drivers</LegendSpan>
                <ViewButton>View</ViewButton>
            </ProgressDiv>
            <ProgressDiv>
                <Progress strokeLinecap="square" type="circle" percent={40} format={(percent) => `${percent}`} />
                <LegendSpan>On Time</LegendSpan>
                <ViewButton>View</ViewButton>
            </ProgressDiv>
        </MainProgressDiv>
    );
};

export default DriverProgress;
