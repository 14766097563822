import { SearchOutlined } from '@ant-design/icons';
import Drawer from 'components/ui/base/drawer/Drawer';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormCheckbox from 'components/ui/composed/formCheckbox/FormCheckbox';
import { defaultPagination } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { useRequestPromise } from 'hooks/useRequest';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { listDeclarations as listDeclarationsRequest } from 'store/declarations/client';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import DeclarationTable, { DeclarationTableType } from 'views/custom-declaration/declaration-table/DeclarationsTable';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';
const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const [searchDeclarationDrawer, setSearchDeclarationDrawer] = useState(false);

    const defaultListDeclarationArgs = useMemo(
        () => ({
            ...defaultPagination,
            declarationInternalType: [DeclarationInternalType.EXPORT],
            declarationExternalEntity: [DeclarationExternalEntity.REVENUE],
        }),
        []
    );
    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise(listDeclarationsRequest, { args: [defaultListDeclarationArgs] });

    useEffect(() => {
        if (searchDeclarationDrawer) listDeclarations(defaultListDeclarationArgs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDeclarationDrawer]);

    return (
        <section>
            <FormCard
                title={t('masterDetails')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                action={
                    !props.viewOnly
                        ? {
                              title: 'Search Export Declaration',
                              onClick: () => setSearchDeclarationDrawer(true),
                              icon: <SearchOutlined />,
                          }
                        : undefined
                }
                state={getCardState([], props)}
            >
                <FormInput
                    maxLength={22}
                    {...getFormikProps(`mrn`, props)}
                    label={t('remissionRefund.mrn')}
                    tooltip={t('remissionRefund.mrn')}
                />
                <FormInput
                    {...getFormikProps(`goodsShipment.exitCustomsOffice`, props)}
                    label={t('arrivalAtExit.exitCustomsOffice')}
                    tooltip={t('arrivalAtExit.exitCustomsOffice')}
                />
                <FormInput
                    {...getFormikProps(`goodsShipment.warehouseIdentification`, props)}
                    label={t('arrivalAtExit.warehouseIdentification')}
                    tooltip={t('arrivalAtExit.warehouseIdentification')}
                />
                <FormCheckbox
                    {...getFormikProps(`goodsShipment.storingFlag`, props)}
                    label={t('arrivalAtExit.storingFlag')}
                    tooltip={t('arrivalAtExit.storingFlag')}
                />
            </FormCard>
            <Drawer
                title="Search Declaration"
                width="1098"
                visible={searchDeclarationDrawer}
                onClose={() => {
                    setSearchDeclarationDrawer(false);
                }}
            >
                {declarations && (
                    <DeclarationTable
                        data={declarations as ListPayload<DeclarationAndAgent>}
                        loading={isLoading}
                        type={DeclarationTableType.SELECTION}
                        onSelect={(record: Declaration) => {
                            if (record.mrn) {
                                getFormikProps(`mrn`, props)?.fieldHelper?.setValue(record.mrn);
                            }
                            setSearchDeclarationDrawer(false);
                        }}
                    />
                )}
            </Drawer>
        </section>
    );
};

export default MasterDetailsCard;
