import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useWeighbridges from './useWeighbridges';

const useSetup = () => {
    const location = useLocation();
    const { weighbridges } = useWeighbridges();

    const isInSetupScreen: boolean = useMemo(() => Boolean(location.pathname.match(/setup/)), [location]);

    /**
     * Based on whether the user has been EMAIL INVITED and has NEVER CALIBRATED any device of their weighbridges
     * TODO Add EMAIL INVITED check
     */
    const isInSetupProcess = useMemo<boolean>(
        () => !weighbridges.list.find(({ device }) => device?.calibrations?.length !== 0),
        [weighbridges.list]
    );

    return { isInSetupScreen, isInSetupProcess };
};

export default useSetup;
