import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Divider from 'components/ui/base/divider';
import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useSession from 'hooks/useSession';
import useVehicles from 'hooks/useVehicles';
import { FC, useEffect, useState } from 'react';
import { Vehicle } from 'store/vehicles/vehicles';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import { CustomModal } from 'views/declarations/common/ModalPreviewSadAndClearanceSlip.styles';
import CreateVehicle from './components/CreateVehicle';
import VehiclesTable from './components/VehiclesTable';

const VehiclesManagement: FC<{}> = () => {
    const { listVehicles, vehicles, isLoading, getVehicle, deleteVehicle, error } = useVehicles();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const [vehicle, setVehicle] = useState<Vehicle>();
    const [showVehicleDrawer, setShowVehicleDrawer] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
    const { userInfo } = useSession();

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Vehicles',
                path: '',
            },
        ]);
        listVehicles({ customerId: userInfo?.customerId! });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddNewVehicle = () => {
        setShowVehicleDrawer(true);
        setVehicle({ customerId: userInfo?.customerId } as Vehicle);
    };

    const handleEditVehicle = async (id: String) => {
        const response = await getVehicle(id);
        if (response?.id) {
            setVehicle(response);
        }
        setShowVehicleDrawer(true);
    };

    const handleSort = () => {};

    const handlePagination = () => {};

    const handleDetails = () => {
        //TODO details
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setShowDeleteDrawer(true);
    };

    const handleCreateError = () => {
        //TODO error
    };

    const handleEditError = () => {
        //TODO error
    };

    function debouncedSearch(value: string): void {
        throw new Error('Function not implemented.');
    }

    const clearVehicleData = () => {
        setVehicle(undefined);
        setShowVehicleDrawer(false);
    };

    const handleAddNewVehicleSuccess = () => {
        setShowVehicleDrawer(false);
        listVehicles({ customerId: userInfo?.customerId });
    };

    return (
        <>
            <>
                <Row>
                    <Col span={18}>
                        <H5>Vehicles</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={handleAddNewVehicle}>
                            Add New Vehicle
                        </Button>
                    </ReverseCol>
                </Row>
                <Divider />
                <SearchBar
                    onSearch={(value: string) => debouncedSearch(value)}
                    inputPlaceholder="Search by reference, status, type"
                    onClear={() => {
                        listVehicles({ customerId: userInfo?.customerId });
                    }}
                />
                <VehiclesTable
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEditVehicle}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    data={vehicles}
                    loading={isLoading}
                />
            </>
            <Drawer
                title={!vehicle || !vehicle?.id ? 'Add New Vehicle' : 'Edit Vehicle'}
                width={627}
                visible={showVehicleDrawer}
                onClose={clearVehicleData}
            >
                <CreateVehicle
                    closeDrawer={clearVehicleData}
                    handleOk={handleAddNewVehicleSuccess}
                    vehicle={vehicle}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                />
            </Drawer>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these journeys?</H5>
                    ) : (
                        <H5>Do you want to remove this journey?</H5>
                    )
                }
                centered
                visible={showDeleteDrawer}
                onOk={async () => {
                    const toDelete: string[] = [...deleteIds];

                    for (let i = 0; i < toDelete.length; i++) {
                        await deleteVehicle(toDelete[i]);
                    }

                    if (!error) {
                        Notification({
                            type: 'success',
                            messageTitle: 'Vehicle Deleted',
                            description: 'Vehicle has been successfully deleted!',
                        });
                    }
                    listVehicles({ customerId: userInfo?.customerId });
                    setShowDeleteDrawer(false);
                }}
                onCancel={() => setShowDeleteDrawer(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these journeys, you will lose all the information associated to them.'
                        : 'If you remove this journey, you will lose all the associated information.'
                }
            />
        </>
    );
};
export default VehiclesManagement;
