import useDeclarations from 'hooks/useDeclarations';
import { FC, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

interface Props {}

const DeclarationHistory: FC<Props> = () => {
    const { declarationId, historyId } = useParams();
    const { declarationHistory, getDeclarationHistory } = useDeclarations();

    useEffect(() => {
        if (declarationId) getDeclarationHistory(declarationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const declaration = useMemo(() => {
        if (declarationHistory) {
            return declarationHistory.find((history) => history.id === historyId);
        }
    }, [historyId, declarationHistory]);

    return (
        <Outlet
            context={{
                declaration,
            }}
        />
    );
};

export default DeclarationHistory;
