import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import useCodelists from '../../../../../hooks/useCodelists';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'methodCode';

export const valuationMethodBlockValidation: {
    childValidators: {
        customsValuation: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        customsValuation: {
            childValidators: {
                methodCode: [validators.required(), validators.number(), validators.exact(1)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ValuationMethodBlock = ({ fieldOptions, path = 'customsValuation' }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/16', []);
    return (
        <>
            <DeclarationField name="methodCode" pathPrefix={path}>
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('valuationMethod')}
                        {...getFormikProps(field.name, form)}
                        condensed
                        selectOptions={cdsCodelists?.valuationMethodTypes}
                        codeValidation={[validators.number(), validators.exact(1)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default ValuationMethodBlock;
