import { BackToList, CodeDiv, MessageDiv, ShowDetailsDiv, StyledErrorRow } from '../DeclarationErrorsDrawer.styles';
import { Text } from 'components/ui/base/typography';
import { RevenueImportErrorDetails } from '../revenue-errors/RevenueImportErrorDetails';
import useFormUtils from '../../../../../hooks/useFormUtils';
import RevenueExportErrorDetails from '../revenue-errors/RevenueExportErrorDetails';
import { useMemo, useState } from 'react';
import { AesInvalidError, AesRejectedError } from '../../../../../store/declarations/ireland/export-declaration';
import { SubmissionErrors } from '../../../../../store/declarations/ireland/ireland-notification';

const AisAesDeclarationErrors = ({
    errors,
    onClose,
    latestNotification,
    showRawData = false,
}: SpecificDeclarationErrorsProps) => {
    const { isAes } = useFormUtils();
    const [showRawMessage, setShowRawMessage] = useState<boolean>(showRawData);
    const [selectedError, setSelectedError] = useState<
        (SubmissionErrors & (AesRejectedError & AesInvalidError)) | undefined
    >(undefined);

    const renderListOfErrors = (errors: any) =>
        errors.map((error: any, i: number) => (
            <StyledErrorRow justify="space-between" key={`error-row-${i}`}>
                <CodeDiv>
                    <Text>{error?.irelandTranslationTable?.code ?? error?.errorCode ?? '-'}</Text>
                </CodeDiv>
                <MessageDiv>
                    <Text>
                        {error?.irelandTranslationTable?.message ?? error?.errorReason ?? error?.errorText ?? '-'}
                    </Text>
                </MessageDiv>
                <ShowDetailsDiv>
                    <span onClick={() => setSelectedError(error)}>Show details</span>
                </ShowDetailsDiv>
                <ShowDetailsDiv>
                    <span onClick={() => setShowRawMessage(true)}>Show raw data</span>
                </ShowDetailsDiv>
            </StyledErrorRow>
        ));

    const errorDetails = useMemo(() => {
        let detailsContent;

        if (showRawMessage) {
            detailsContent = <div>{latestNotification?.rawMessage}</div>;
        } else if (isAes) {
            detailsContent = <RevenueExportErrorDetails details={selectedError} onClose={onClose} />;
        } else {
            detailsContent = <RevenueImportErrorDetails details={selectedError} onClose={onClose} />;
        }

        return (
            <>
                <BackToList onClick={() => (showRawMessage ? setShowRawMessage(false) : setSelectedError(undefined))}>
                    Back to list
                </BackToList>
                {detailsContent}
            </>
        );
    }, [isAes, latestNotification?.rawMessage, onClose, showRawMessage, selectedError]);

    if (!selectedError && !showRawMessage) return <>{renderListOfErrors(errors)}</>;
    else return <>{errorDetails}</>;
};

export default AisAesDeclarationErrors;
