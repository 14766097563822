import { cloneDeep, merge } from 'lodash';
import { irelandExportB1ValidationHeader, irelandExportB1ValidationItem } from './IrelandExportB1Validation';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeepPartial from 'types/DeepPartial';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        referenceNumberUCR: REMOVE,
        additionalReference: REMOVE,
        transportCharges: REMOVE,
        natureOfTransaction: REMOVE,
        statisticalValue: REMOVE,
        transportDocument: REMOVE,
        origin: {
            childValidators: {
                countryOfOrigin: [
                    validators.required(),
                    ...irelandExportB1ValidationItem.childValidators.origin.childValidators.countryOfOrigin,
                ],
            },
        },
        commodity: {
            childValidators: {
                dangerousGoods: REMOVE,
            },
        },
    },
};
export const irelandExportB3ValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        exportOperation: {
            childValidators: {
                specificCircumstanceIndicator: REMOVE,
                security: REMOVE,
                invoiceCurrency: REMOVE,
                totalAmountInvoiced: REMOVE,
            },
        },
        deferredPayment: REMOVE,
        goodsShipment: {
            childValidators: {
                natureOfTransaction: REMOVE,
                deliveryTerms: REMOVE,
                additionalReference: REMOVE,
                warehouse: {
                    childValidators: {
                        identifier: [
                            validators.required,
                            ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators.warehouse
                                .childValidators.identifier,
                        ],
                        type: [
                            validators.required,
                            ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators.warehouse
                                .childValidators.type,
                        ],
                    },
                },
                consignment: {
                    childValidators: {
                        referenceNumberUCR: REMOVE,
                        countryOfRoutingOfConsignment: REMOVE,
                        modeOfTransportAtTheBorder: [REMOVE],
                        consignor: REMOVE,
                        consignee: {
                            childValidators: {
                                identificationNumber: [
                                    validators.required(),
                                    ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators
                                        .consignment.childValidators.consignee.childValidators.identificationNumber,
                                ],
                            },
                        },
                        carrier: REMOVE,
                        transportDocument: REMOVE,
                        transportCharges: REMOVE,
                        transportEquipment: {
                            childValidators: {
                                seal: REMOVE,
                            },
                        },
                    },
                },
                goodsItem: irelandExportB3ValidationItem,
            },
        },
    },
};
export const irelandExportB3ValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
