import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import LocationOfGoodsBlock, { locationOfGoodsBlockValidation } from '../blocks/LocationOfGoodsBlock';
import { ExtractValidationSchema } from '../validations/validations';

export const locationOfGoodsCardValidation = {
    childValidators: {
        'goodsShipment.consignment.goodsLocation': locationOfGoodsBlockValidation,
    },
};
export type LocationOfGoodsCardValidation = ExtractValidationSchema<typeof locationOfGoodsCardValidation>;

const LocationOfGoodsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('goodsLocation')} hidden={props.hidden}>
            <FormCardContainer>
                <LocationOfGoodsBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default LocationOfGoodsCard;
