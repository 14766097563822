import { useAppDispatch, useAppSelector } from 'config/hooks';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { useCallback } from 'react';
import { doListActiveWeighbridgeDevices, doListWeighbridges } from 'store/weighbridges/action';
import { WeighbridgeDeviceResponse } from 'store/weighbridges/weighbridge';

const useWeighbridges = () => {
    const weighbridges = useAppSelector((state) => state.weighbridges.weighbridges);
    const dispatch = useAppDispatch();
    // TODO add loading and error states...

    const listActiveWeighbridgeDevices = useCallback((): ListPayload<WeighbridgeDeviceResponse> => {
        return dispatch(doListActiveWeighbridgeDevices());
    }, [dispatch]);

    const listWeighbridges = useCallback(
        (params?: Partial<PaginatedParams>) => dispatch(doListWeighbridges(params)),
        [dispatch]
    );

    return {
        listActiveWeighbridgeDevices,
        weighbridges,
        listWeighbridges,
    };
};
export default useWeighbridges;
