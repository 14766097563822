import { cloneDeep } from 'lodash';
import {
    createIrelandCodeLists,
    createUkCodeLists,
    deleteIrelandCodeLists,
    deleteUkCodeLists,
    updateIrelandCodeLists,
    updateUkCodeLists,
} from '../../../../../store/codelists/client';
import { IrelandCodelistsNamesForServer, UkCodelistsNamesForServer } from '../../../../../store/codelists/code';

export const transformCodelistForTable = (codelistData: any) => {
    const copyCodelistData = cloneDeep(codelistData);

    for (let i = 0; i < copyCodelistData?.length; i++) {
        copyCodelistData[i]['code'] = copyCodelistData[i]['id'];
        copyCodelistData[i]['description'] = copyCodelistData[i]['value'];
        copyCodelistData[i]['id'] = copyCodelistData[i]['idServer'];
        copyCodelistData[i]['key'] = `${i}_${copyCodelistData[i]['code']}`;

        delete copyCodelistData[i]['value'];
        delete copyCodelistData[i]['idServer'];
    }

    return copyCodelistData;
};

type CodelistMenuDrawerMapHelpers = {
    [key in string]: {
        createCodelist: Function;
        listCodelists: string;
        deleteCodelist: Function;
        updateCodelist: Function;
        getCodelistName: (codelistData: any) => string;
    };
};

const codelistMenuDrawerMapHelpers: CodelistMenuDrawerMapHelpers = {
    UK: {
        createCodelist: createUkCodeLists,
        listCodelists: 'getUkCodelists',
        deleteCodelist: deleteUkCodeLists,
        updateCodelist: updateUkCodeLists,
        getCodelistName: (codelistData) =>
            UkCodelistsNamesForServer[codelistData?.[0]['name'] as keyof typeof UkCodelistsNamesForServer],
    },
    IRELAND: {
        createCodelist: createIrelandCodeLists,
        listCodelists: 'getIrelandCodelists',
        deleteCodelist: deleteIrelandCodeLists,
        updateCodelist: updateIrelandCodeLists,
        getCodelistName: (codelistData) =>
            IrelandCodelistsNamesForServer[codelistData?.[0]['name'] as keyof typeof IrelandCodelistsNamesForServer],
    },
};

export type ListFunctions = 'getUkCodelists' | 'getIrelandCodelists';

export default codelistMenuDrawerMapHelpers;
