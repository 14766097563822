import { ARadio } from 'components/ui/base/radio/Radio';
import { FC, useState } from 'react';
import { RadioGroup } from './CustomerEoriAddressRadioButton.styles';

interface Props {
    children: JSX.Element[];
}
const CustomerEoriAddressRadioButton: FC<Props> = ({ children }) => {
    const [value, onChange] = useState<string | number | undefined>(0);
    return (
        <>
            <RadioGroup value={value} onChange={(e) => onChange(e.target.value)}>
                <ARadio value={0}>Eori number</ARadio>
                <ARadio value={1}>Customer Address</ARadio>
            </RadioGroup>
            {value === 0 && children && children[0]}
            {value === 1 && children && children[1]}
        </>
    );
};

export default CustomerEoriAddressRadioButton;
