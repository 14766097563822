import { Row } from 'antd';
import Notification from 'components/ui/base/notification/Notification';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { ErrorResponse } from 'core/http/response';
import { objectIsEmpty } from 'core/utils/objects';
import { setNestedObjectValues, useFormik } from 'formik';
import useLoadingButton from 'hooks/useLoadingButton';
import useSession from 'hooks/useSession';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Address, CustomerType, customerTypeLabels } from 'store/customers/customer';
import { IndividualType } from 'store/individuals/individual';
import * as Yup from 'yup';
import { DivInfo, Info, SignInButton, SignUpButton, StyledDiv, Title } from './SignUpForm.styles';

const formValidation = Yup.object().shape({
    fullName: Yup.string().required('Field Full name is required.'),
    companyName: Yup.string().required('Field Company name is required.'),
    companyType: Yup.string().required('Field Company type is required.').nullable(),
    email: Yup.string().required('Field Email is required.').email('Invalid email address'),
    phoneCode: Yup.string().nullable().required('Field Phone code is required.'),
    phone: Yup.string()
        .nullable()
        .required('Field Phone number is required.')
        .matches(/^[0-9]{1,9}$/, 'Must contain from 1 to 9 digits.'),
    eori: Yup.string().required('EORI is required.').nullable(),
});

interface SignUpInfo {
    companyName: string;
    companyType?: CustomerType;
    email: string;
    fullName: string;
    phoneCode: string;
    phone: string;
    eori: string;
    address?: Address;
}

interface Props {
    handleUserIsCreated: Function;
}

const SignUpForm: FC<Props> = ({ handleUserIsCreated }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('login');
    const { signUp, isLoading, error } = useSession();
    const [didMount, setDidMount] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const formik = useFormik<SignUpInfo>({
        initialValues: {
            companyName: '',
            companyType: undefined,
            email: '',
            fullName: '',
            phoneCode: '',
            phone: '',
            eori: '',
        },
        validateOnMount: true,
        validationSchema: formValidation,
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const getIndividualType = (customerType: CustomerType) => {
        switch (customerType) {
            case CustomerType.CUSTOMS_BROKER:
                return IndividualType.BROKER_ADMIN;
            case CustomerType.HAULIER:
                return IndividualType.HAULIER_ADMIN;
            case CustomerType.TRADER:
                return IndividualType.TRADER_ADMIN;
            default:
                throw Error('Customer should have a valid type');
        }
    };

    useEffect(() => {
        if (error && !isLoading && !didMount) {
            Notification({
                type: 'error',
                messageTitle: t('error.SignUpError'),
                description: (error as ErrorResponse).message, // FIXME what if it is not error response...
            });
        }

        if (didMount === true) {
            setDidMount(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (submitted) {
            if (!isLoading && !error) {
                setSubmitted(false);
                handleUserIsCreated();
                Notification({
                    type: 'success',
                    messageTitle: t('signUp'),
                    description: t('new_customer'),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted, isLoading, error]);

    const goToSignIn = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const handleSignUp = useCallback(async () => {
        const handleSubmit = async (form: SignUpInfo) => {
            const customerParams = {
                name: form.companyName,
                type: form.companyType,
                eori: form.eori,
                address: form.address,
            };

            const individualParams = {
                type: getIndividualType(form.companyType!),
                email: form.email,
                fullName: form.fullName,
                phone: `${form.phoneCode}${form.phone}`,
            };

            await signUp({
                customer: customerParams,
                individual: individualParams,
            });
            setSubmitted(true);
        };

        const validate = async () => {
            const v = await formik.validateForm().then((v) => {
                formik.setTouched(setNestedObjectValues(v, true));
                return v;
            });
            if (!objectIsEmpty(v)) {
                return false;
            } else {
                return true;
            }
        };

        const valid = await validate();
        if (valid) {
            await handleSubmit(formik.values);
        } else {
            Notification({
                type: 'error',
                messageTitle: t('signUp'),
                description: t('error.formRequired'),
            });
        }
    }, [signUp, t, formik]);

    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: handleSignUp });

    return (
        <>
            <StyledDiv>
                <DivInfo>
                    <Info>Unified Logistics, always delivering</Info>
                </DivInfo>
                <Title>{t('signUp')}</Title>
                <FormInput
                    label={t('fullNameLabel')}
                    fieldProps={formik.getFieldProps('fullName')}
                    fieldMeta={formik.getFieldMeta('fullName')}
                />
                <FormInput
                    label={t('emailLabel')}
                    fieldProps={formik.getFieldProps('email')}
                    fieldMeta={formik.getFieldMeta('email')}
                />
                <FormInput
                    label={t('companyNameLabel')}
                    fieldProps={formik.getFieldProps('companyName')}
                    fieldMeta={formik.getFieldMeta('companyName')}
                />
                <FormSelect
                    hideKeys
                    label={t('companyTypeLabel')}
                    fieldProps={formik.getFieldProps('companyType')}
                    fieldMeta={formik.getFieldMeta('companyType')}
                    fieldHelper={formik.getFieldHelpers('companyType')}
                    selectOptions={customerTypeLabels}
                />
                <FormPhoneNumber
                    fieldCodeProps={formik.getFieldProps('phoneCode')}
                    fieldNumberProps={formik.getFieldProps('phone')}
                    fieldCodeMeta={formik.getFieldMeta('phoneCode')}
                    fieldNumberMeta={formik.getFieldMeta('phone')}
                    fieldNumberHelper={formik.getFieldHelpers('phone')}
                    fieldCodeHelper={formik.getFieldHelpers('phoneCode')}
                />
                <FormInput
                    label={t('eori')}
                    fieldProps={formik.getFieldProps('eori')}
                    fieldHelper={formik.getFieldHelpers('eori')}
                    fieldMeta={formik.getFieldMeta('eori')}
                    tooltip={t('common.eori_tooltip')}
                />
                <SignUpButton
                    block
                    type="primary"
                    size="large"
                    onClick={async () => await handleOnPressButton()}
                    loading={loading}
                >
                    {t('signUp')}
                </SignUpButton>
                <Row justify="center">
                    {t('alreadyKnowDddispatch')}
                    <SignInButton type="link" onClick={() => goToSignIn()}>
                        {t('loginTitle')}
                    </SignInButton>
                </Row>
            </StyledDiv>
        </>
    );
};

export default SignUpForm;
