import { BoxNumber } from 'components/ui/base/box-number/box-number.styles';
import { Text } from 'components/ui/base/typography';
import styled from 'styled-components';

export const FormHelpContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormSection = styled.div`
    margin-top: 4.6rem;
`;

export const FormSectionHeader = styled.div`
    display: flex;
    height: 4rem;
`;

export const FormSectionDescription = styled.div`
    margin-top: 0.8rem;
`;

export const StyledBoxNumber = styled(BoxNumber)`
    margin-right: 0.8rem;
`;

export const Description = styled(Text)`
    white-space: pre-wrap;
`;
