import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IrelandRefundGoodsInformation,
    IrelandRefundTypeOfDuties,
} from 'store/declarations/ireland/refunds/remission/ireland-refund-remission';
import { CardContent } from 'views/declarations/common/box44/Box44Card.styles';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const RemissionGoodsInformation: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    const formik = useFormik<IrelandRefundGoodsInformation>({
        initialValues: {},
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const formikTypeOfDuties = useFormik<IrelandRefundTypeOfDuties>({
        initialValues: {},
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItemTypeOfDuties(values);
        },
    });

    const {
        setValues,
        getFieldProps: formGetFieldProps,
        getFieldHelpers: formGetFieldHelpers,
        getFieldMeta: formGetFieldMeta,
        resetForm,
    } = formik;

    const { modalVisible, handleEdit, handleCancel, onClickAddItem, handleDelete, addItem } = useModalMultipleCards({
        formikProps: {
            fieldProps: getFieldProps('goodsInformation'),
            fieldHelper: getFieldHelpers('goodsInformation'),
            fieldMeta: getFieldMeta ? getFieldMeta('goodsInformation') : undefined,
        },
        setValues,
        resetForm,
    });

    const {
        setValues: setTypeOfDuties,
        getFieldProps: formTypeOfDutiesGetFieldProps,
        getFieldHelpers: formTypeOfDutiesGetFieldHelpers,
        getFieldMeta: formTypeOfDutiesGetFieldMeta,
        resetForm: resetFormTypeOfDuties,
    } = formikTypeOfDuties;

    const {
        modalVisible: modalVisibleTypeOfDuties,
        handleEdit: handleEditTypeOfDuties,
        handleCancel: handleCancelModalTypeOfDuties,
        onClickAddItem: onClickAddTypeOfDuties,
        handleDelete: handleDeleteTypeOfDuties,
        addItem: addItemTypeOfDuties,
    } = useModalMultipleCards({
        formikProps: {
            fieldProps: formGetFieldProps('typeOfDuties'),
            fieldHelper: formGetFieldHelpers('typeOfDuties'),
            fieldMeta: formGetFieldMeta('typeOfDuties'),
        },
        setValues: setTypeOfDuties,
        resetForm: resetFormTypeOfDuties,
    });

    const goodsInformationDataList = useMemo(() => {
        const list = getFieldProps('goodsInformation').value;
        if (Array.isArray(list)) {
            return list.map((obj: IrelandRefundGoodsInformation, index) => {
                return [
                    {
                        field: t('goodsItems.goodsDescription'),
                        value: obj.goodsDescription,
                    },
                    {
                        field: t('goodsItems.taricCode'),
                        value: obj.taricCode,
                    },
                    {
                        field: t('quantity'),
                        value: obj.quantity?.toString(),
                    },
                    {
                        field: t('amount'),
                        value: obj.amount?.toString(),
                    },
                    {
                        field: t('currency'),
                        value: obj.currency,
                    },
                ];
            });
        }
        return [];
    }, [getFieldProps, t]);

    const typeOfDutiesList = useMemo(() => {
        const list = formGetFieldProps('typeOfDuties').value;
        if (Array.isArray(list)) {
            return list.map((obj: IrelandRefundTypeOfDuties, index) => {
                return [
                    {
                        field: t('remissionRefund.unionCode'),
                        value: obj.unionCode,
                    },
                    {
                        field: t('remissionRefund.nationalCode'),
                        value: obj.nationalCode,
                    },
                ];
            });
        }
        return [];
    }, [formGetFieldProps, t]);

    return (
        <section
            id={'remission-goods-information-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('remission-goods-information-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('goodsInformation')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <CardContent>
                    <CardList
                        data={goodsInformationDataList}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        viewOnly={viewOnly ?? false}
                    />
                    {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
                </CardContent>
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`combinedNomenclatureCode`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('remissionRefund.combinedNomenclatureCode')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`taricCode`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('goodsItems.taricCode')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`taricAdditionalCode`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('goodsItems.taricAdditionalCode')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`nationalAdditionalCommodityCode`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('nationalAdditionalCommodityCode')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`goodsDescription`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('goodsItems.goodsDescription')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`measurementUnit`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('measurementUnit')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`quantity`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('quantity')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`currency`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('currency')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`amount`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('amount')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <CardContent>
                            <CardList
                                data={typeOfDutiesList}
                                onDelete={handleDeleteTypeOfDuties}
                                onEdit={handleEditTypeOfDuties}
                                viewOnly={viewOnly ?? false}
                            />
                            {!viewOnly && <Button onClick={onClickAddTypeOfDuties}>Add item</Button>}
                        </CardContent>
                        <ModalEditCardListItem
                            visible={modalVisibleTypeOfDuties}
                            onCancel={handleCancelModalTypeOfDuties}
                            width={1020}
                            formik={formikTypeOfDuties}
                        >
                            <FormInput
                                viewOnly={viewOnly}
                                {...getFormikProps(`unionCode`, {
                                    getFieldHelpers: formTypeOfDutiesGetFieldHelpers,
                                    getFieldProps: formTypeOfDutiesGetFieldProps,
                                    getFieldMeta: formTypeOfDutiesGetFieldMeta,
                                })}
                                label={t('remissionRefund.unionCode')}
                            />
                            <FormInput
                                viewOnly={viewOnly}
                                {...getFormikProps(`nationalCode`, {
                                    getFieldHelpers: formTypeOfDutiesGetFieldHelpers,
                                    getFieldProps: formTypeOfDutiesGetFieldProps,
                                    getFieldMeta: formTypeOfDutiesGetFieldMeta,
                                })}
                                label={t('remissionRefund.nationalCode')}
                            />
                        </ModalEditCardListItem>
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};
export default RemissionGoodsInformation;
