import { Form } from 'antd';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import Notification from 'components/ui/base/notification/Notification';
import { H5Style } from 'components/ui/base/typography/Typography.styles';
import FormPassword from 'components/ui/composed/formPassword';
import { PWD_REGEX } from 'core/utils/constants';
import { useFormik } from 'formik';
import useLoadingButton from 'hooks/useLoadingButton';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { updatePassword } from 'store/session/client';
import * as Yup from 'yup';
import { StyledCard, StyledTextExtraSmall } from '../Settings.styles';

export interface UpdatePassword {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const updatePasswordSchema = (t: Function) =>
    Yup.object().shape({
        oldPassword: Yup.string().required(t('changePassword.error.oldPasswordRequired')),
        newPassword: Yup.string()
            .matches(PWD_REGEX, t('changePassword.rules'))
            .required(t('changePassword.error.newPasswordRequired')),
        confirmPassword: Yup.string()
            .required(t('changePassword.error.confirmPasswordRequired'))
            .oneOf([Yup.ref('newPassword'), null], t('changePassword.error.mustMatch')),
    });

const ChangePassword: FC = () => {
    const { t } = useTranslation('settings');

    const { isValid, getFieldProps, resetForm, getFieldMeta, submitForm, validateForm } = useFormik<UpdatePassword>({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: updatePasswordSchema(t),
        onSubmit: (values) => updatePasswordSubmit(values),
    });
    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: submitForm });

    const updatePasswordSubmit = async (values: UpdatePassword) => {
        const credentials = { oldPassword: values.oldPassword, newPassword: values.newPassword };
        const result = await updatePassword(credentials);
        if (result) {
            resetForm();
            Notification({
                type: 'success',
                messageTitle: 'Change password',
                description: t('successMessages.changePassword'),
            });
        }
    };

    const personalInfoView = () => {
        return (
            <Form layout="vertical" style={{ width: '100%' }}>
                <H5Style style={{ marginBottom: '3.8rem' }}>{t('settings.changePassword')}</H5Style>
                <FormPassword
                    label={t('changePassword.oldPassword')}
                    icon
                    fieldProps={getFieldProps('oldPassword')}
                    fieldMeta={getFieldMeta('oldPassword')}
                />
                <FormPassword
                    label={t('changePassword.newPassword')}
                    fieldMeta={getFieldMeta('newPassword')}
                    fieldProps={getFieldProps('newPassword')}
                />
                <FormPassword
                    label={t('changePassword.confirmPassword')}
                    fieldMeta={getFieldMeta('confirmPassword')}
                    fieldProps={getFieldProps('confirmPassword')}
                />

                <FormItem>
                    <StyledTextExtraSmall>{t('changePassword.rules')}</StyledTextExtraSmall>
                </FormItem>
                <FormItem style={{ textAlign: 'right' }}>
                    <Button
                        disabled={!isValid}
                        style={{ minWidth: '108px' }}
                        loading={loading}
                        onClick={async () => {
                            await validateForm();
                            await handleOnPressButton();
                            Notification({
                                type: 'success',
                                messageTitle: 'Change password',
                                description: t('successMessages.changePassword'),
                            });
                        }}
                    >
                        {t('form.save')}
                    </Button>
                </FormItem>
            </Form>
        );
    };

    return (
        <>
            <StyledCard>{personalInfoView()}</StyledCard>
        </>
    );
};

export default ChangePassword;
