import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useFormikContext } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import { handleToggleHelp } from 'views/declarations/utils/form-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { validators } from 'views/declarations/uk/export/validations/validations';

const GuaranteeCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const formik = useFormikContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { getFieldHelpers, getFieldMeta, getFieldProps } = useMemo(() => formik, [formik.values]);

    return (
        <MultipleItemsCard
            getFieldHelpers={formik.getFieldHelpers}
            getFieldProps={formik.getFieldProps}
            initialValues={{
                guaranteeType: '',
                guaranteeReference: [],
            }}
            list={(list) => [
                { field: 'Guarantee Type', value: list.guaranteeType },
                { field: 'Guarantee Reference', value: list.guaranteeReference?.length.toString() ?? '0' },
            ]}
            condensed
            path="guarantee"
            title="Guarantee"
        >
            {(path) => (
                <>
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={props.viewOnly}
                            condensed
                            fieldMeta={getFieldMeta(addPathPrefix(path, 'guaranteeType'))}
                            fieldProps={getFieldProps(addPathPrefix(path, 'guaranteeType'))}
                            fieldHelper={getFieldHelpers(addPathPrefix(path, 'guaranteeType'))}
                            refNumber="8.2"
                            label={t('type')}
                            tooltip={getH1TooltipsByRefNumberAndField('8.2', t('type'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={aisCodelists?.guaranteeType}
                            codeValidation={[validators.maxLength(1)]}
                        />
                    </FormCardContainer>
                    <MultipleItemsCard
                        style={{ marginTop: 10 }}
                        getFieldHelpers={formik.getFieldHelpers}
                        getFieldProps={formik.getFieldProps}
                        initialValues={{
                            grn: '',
                            otherGuaranteeReference: '',
                            accessCode: '',
                            currency: '',
                            amount: '',
                            guaranteeCustomsOffice: '',
                        }}
                        list={(list) => [
                            { field: 'GRN', value: list.grn },
                            { field: 'Other Guarantee Reference', value: list.otherGuaranteeReference },
                            { field: 'Access Code', value: list.accessCode },
                            { field: 'Currency', value: list.currency },
                            { field: 'Amount', value: list.amount },
                            { field: 'Guarantee Customs Office', value: list.guaranteeCustomsOffice },
                        ]}
                        condensed
                        path={addPathPrefix(path, 'guaranteeReference')}
                        title="Guarantee Reference"
                    >
                        {(path) => (
                            <FormCardContainer>
                                <DeclarationInput
                                    maxLength={24}
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'grn'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'grn'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'grn'))}
                                    refNumber="8.3"
                                    label={t('guaranteeReference.grn')}
                                    tooltip={getH1TooltipsByRefNumberAndField('8.3', t('guaranteeReference.grn'))}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                                <DeclarationInput
                                    maxLength={35}
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'otherGuaranteeReference'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'otherGuaranteeReference'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'otherGuaranteeReference'))}
                                    refNumber="8.3"
                                    label={t('guaranteeReference.otherGuaranteeReference')}
                                    tooltip={getH1TooltipsByRefNumberAndField(
                                        '8.3',
                                        t('guaranteeReference.otherGuaranteeReference')
                                    )}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                                <DeclarationInput
                                    maxLength={4}
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'accessCode'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'accessCode'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'accessCode'))}
                                    refNumber="8.3"
                                    label={t('guaranteeReference.accessCode')}
                                    tooltip={getH1TooltipsByRefNumberAndField(
                                        '8.3',
                                        t('guaranteeReference.accessCode')
                                    )}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                                <CurrencySelect
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'currency'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'currency'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'currency'))}
                                    refNumber="8.3"
                                    tooltip={getH1TooltipsByRefNumberAndField('8.3', t('currency'))}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                                <DeclarationNumberInput
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'amount'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'amount'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'amount'))}
                                    refNumber="8.3"
                                    label={t('amount')}
                                    tooltip={getH1TooltipsByRefNumberAndField('8.3', t('amount'))}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                                <DeclarationInput
                                    maxLength={8}
                                    viewOnly={props.viewOnly}
                                    fieldMeta={getFieldMeta(addPathPrefix(path, 'guaranteeCustomsOffice'))}
                                    fieldProps={getFieldProps(addPathPrefix(path, 'guaranteeCustomsOffice'))}
                                    fieldHelper={getFieldHelpers(addPathPrefix(path, 'guaranteeCustomsOffice'))}
                                    refNumber="8.3"
                                    label={t('guaranteeReference.guaranteeCustomsOffice')}
                                    tooltip={getH1TooltipsByRefNumberAndField(
                                        '8.3',
                                        t('guaranteeReference.guaranteeCustomsOffice')
                                    )}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                    condensed
                                />
                            </FormCardContainer>
                        )}
                    </MultipleItemsCard>
                </>
            )}
        </MultipleItemsCard>
    );
};
export default GuaranteeCard;
