import { FC } from 'react';
import { CustomDiv } from './Container.styles';

interface Props {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const Container: FC<Props> = ({ children, style }) => {
    return <CustomDiv style={style}>{children}</CustomDiv>;
};

export default Container;
