import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';
import { H4 } from '../typography';

export const WarningTitle = styled(H4)`
    color: ${colors.error};
`;

export const WarningIcon = styled(WarningOutlined)`
    margin-left: 2.5rem;
`;

export const CloseIcon = styled(CloseOutlined)`
    color: ${colors.darkPurple};
    font-size: 2.1rem;
`;

export const ADrawer = styled(Drawer)`
    .ant-drawer-close {
        line-height: 4rem;
        align-self: baseline;
    }
`;
