import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { CurrenciesActions } from './actionTypes';
import { Currency } from './currency';

export interface CurrenciesState {
    isLoading: boolean;
    currencies: ListPayload<Currency>;
    error?: ErrorResponse;
}

export const currenciesInitialState: CurrenciesState = {
    isLoading: false,
    currencies: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const currencies: Reducer<CurrenciesState, CurrenciesActions> = (
    state = currenciesInitialState,
    action: CurrenciesActions
): CurrenciesState => {
    switch (action.type) {
        case 'GET_CURRENCIES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_CURRENCIES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                currencies: action.payload,
                error: undefined,
            };
        case 'GET_CURRENCIES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default currencies;
