export const IEH7_DataStructure = {
    goodsShipment: {
        valuationInformation: {},
        exporter: {},
        importer: {},
        locationOfGoodsAddress: {},
        documentsAuthorisations: {
            supportingDocument: {},
        },
    },
    declarant: {},
};

export const IEH7_ItemDataStructure = {
    additionalProcedure: [],
    documentsAuthorisations: {
        previousDocument: [],
        supportingDocument: [],
        additionalInformation: [],
    },
    valuationInformation: {
        transportDocument: [],
    },
    exporter: {},
    itemAmountInvoicedIntrinsicValue: {},
    dutiesAndTaxes: [],
    additionalFiscalReference: [],
};
