import { TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const Ellipse = styled.div`
    border: 2px solid #efefef;
    box-sizing: border-box;
    border-radius: 4rem;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background: ${colors.lightGrey2};
`;

export const EllipseText = styled(TextSmallBold)`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 22px;
    color: ${colors.darkGrey};
`;

export const DetailsRow = styled.div`
    display: flex;
    margin-top: 0.8rem;
`;

export const DetailsLabel = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

export const TagsLabel = styled(TextSmallBold)`
    margin-right: 3.6rem;
`;

export const StatusLabel = styled(TextSmallBold)`
    margin-right: 2.8rem;
`;
