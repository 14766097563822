import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    StyledNumberInput,
    ViewOnlyLabel,
} from './DeclarationInput.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import MultipleButtons from '../MultipleButtons';
import { useLocation, useOutletContext } from 'react-router-dom';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import { useRegisterField } from '../../template/CardContext';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import { InputNumberProps, Tooltip } from 'antd';
import { debounce, kebabCase } from 'lodash';
import useDeclarations from 'hooks/useDeclarations';
import { flushSync } from 'react-dom';
import { TestProps } from 'core/utils/testTypes';
import { useMirrorMetaContext } from '../../mirroring/MirrorMetaContext';
import { LinkOutlined } from '@ant-design/icons';

export interface FormNumberInputProps extends InputNumberProps, TestProps {
    label?: string;
    refTooltip?: string;
    tooltip?: string | false;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps?: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    maxLength?: number;
    viewOnly?: boolean;
    integerNumbers?: boolean;
    min?: string;
    max?: string;
    disabled?: boolean;
    stringMode?: boolean;
    refClicked?: (refNumber: string | number) => void;
    fieldEvents?: {
        onBlur?: (value: string | null) => void;
        onChange?: (value: number | null) => void;
    };
    value?: number;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

const DeclarationNumberInput: FC<FormNumberInputProps> = ({
    label,
    refNumber,
    refTooltip,
    tooltip,
    fieldMeta: fieldMetaProp,
    fieldProps: fieldPropsProp,
    fieldHelper: fieldHelpersProp,
    required,
    viewOnly: viewOnlyProp,
    min = '0',
    disabled,
    refClicked,
    fieldEvents,
    value: valueProp,
    maxLength,
    condensed,
    multipleF,
    specialName,
    hidden,
    testId,
    ...numberInputProps
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { declaration } = useDeclarations();

    const { meta, handleOpenModal } = useMirrorMetaContext();

    const isFieldMirrored = useMemo(() => {
        if (!(fieldPropsProp?.name && meta)) return undefined;

        return meta?.[fieldPropsProp?.name]?.isMirrored;
    }, [fieldPropsProp?.name, meta]);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const showTooltip = useMemo(
        () =>
            debounce(() => {
                if (!tooltip) return;
                setTooltipVisible(true);
            }, 200),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const hideTooltip = () => {
        if (!tooltip) return;
        showTooltip?.cancel();
        setTooltipVisible(false);
    };

    useRegisterField({ path: fieldPropsProp?.name, required, hidden });
    const { isViewable, isInvisible } = useFieldTemplateMetaData(specialName ?? fieldPropsProp?.name);

    const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const [localValue, setLocalValue] = useState<number | undefined | null>(undefined);

    const { onBlur, ...otherFieldEvents } = fieldEvents ?? {};

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    useEffect(() => {
        if (hidden) return;
        if (fieldProps?.value === '' && fieldHelpers) {
            fieldHelpers.setValue(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps?.value]);

    const showRefTooltip = useMemo(() => refTooltip && refTooltip.length > 0, [refTooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
    }, [
        template,
        isTemplateViewOnly,
        outletContext?.amendment,
        isViewable,
        viewOnlyProp,
        inViewOnly,
        declaration?.archived,
    ]);

    const value = useMemo(() => {
        if (localValue !== undefined) return localValue;
        return valueProp ?? fieldProps?.value;
    }, [fieldProps?.value, localValue, valueProp]);

    const handleTemplateCheckboxChange = useMemo(() => {
        return numberInputProps.onChange ?? otherFieldEvents.onChange;
    }, [numberInputProps.onChange, otherFieldEvents.onChange]);

    const handleOpenMirrorSeveringModal = useCallback(() => {
        if (!isFieldMirrored) return;

        const revertValueCallback = () => setLocalValue(valueProp ?? fieldProps?.value);

        handleOpenModal?.(revertValueCallback);
    }, [isFieldMirrored, valueProp, fieldProps?.value, handleOpenModal]);

    const render = useMemo(
        () => (
            <>
                {viewOnly ? (
                    <>
                        <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                        {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                        <TextSmall data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}>
                            {fieldProps?.value ?? '-'}
                        </TextSmall>
                    </>
                ) : (
                    <>
                        <LabelDiv condensed={condensed}>
                            {label && (
                                <InputLabel>
                                    {label}
                                    {required && (
                                        <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                    )}
                                </InputLabel>
                            )}

                            {refNumber && <RefDiv>{refNumber}</RefDiv>}
                            {showRefTooltip && (
                                <IconTooltip
                                    title={label}
                                    tooltip={refTooltip ?? t('defaultTooltip')}
                                    icon={<StyledInfoCircleFilled />}
                                    tooltipClicked={handleRefClick}
                                />
                            )}
                            {isFieldMirrored && (
                                <Tooltip title={'Mirrored field'}>
                                    <LinkOutlined
                                        style={{ marginLeft: '0.8rem', color: '#00CCFF', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            )}
                        </LabelDiv>
                        {template && (
                            <TemplateCheckboxes
                                required={required}
                                fieldPath={fieldPropsProp?.name}
                                specialName={specialName}
                                onChange={handleTemplateCheckboxChange}
                            />
                        )}
                        <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                            <InputDiv>
                                <Tooltip visible={tooltipVisible} overlay={tooltip}>
                                    <StyledNumberInput
                                        mirrored={isFieldMirrored}
                                        onMouseOver={showTooltip}
                                        onMouseOut={hideTooltip}
                                        maxLength={maxLength}
                                        placeholder={toPlaceholder(label) ?? ''}
                                        name={fieldProps?.name}
                                        data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                        min={min}
                                        disabled={disabled || isInvisible}
                                        status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                        multiple={fieldProps?.multiple}
                                        checked={fieldProps?.checked}
                                        {...fieldMeta}
                                        {...otherFieldEvents}
                                        size={condensed ? 'middle' : 'large'}
                                        condensed={condensed}
                                        {...numberInputProps}
                                        value={value}
                                        onFocus={(e) => {
                                            setFocused(true);
                                            setLocalValue(e.target.value ? Number(e.target.value) : null);
                                        }}
                                        onBlur={(e) => {
                                            flushSync(() => {
                                                if (numberInputProps.onChange)
                                                    numberInputProps?.onChange?.(e.target.value);
                                                else
                                                    fieldProps?.onChange({
                                                        target: {
                                                            value: e.target.value || null,
                                                            name: fieldProps.name,
                                                        },
                                                    });
                                            });
                                            setFocused(false);
                                            setLocalValue(undefined);
                                            if (onBlur) onBlur?.(e.target.value);
                                            else fieldProps?.onBlur(e);
                                            fieldHelpers?.setTouched(true);
                                        }}
                                        onChange={(value: number | null) => {
                                            setLocalValue(value);
                                            handleOpenMirrorSeveringModal();
                                        }}
                                    />
                                </Tooltip>
                                {multipleF && <MultipleButtons {...multipleF} />}
                            </InputDiv>
                            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                                {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                    <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                )}
                            </ErrorDiv>
                        </FormItem>
                    </>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldMeta?.error, fieldMeta?.touched, value, onBlur, disabled, localValue, viewOnly]
    );

    if (hidden || (isInvisible && !template)) return null;

    return render;
};

export default DeclarationNumberInput;
