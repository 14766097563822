import faker from '@faker-js/faker';
import config from 'config';
import axiosClient from 'config/axios';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import { Leg, LegParams, LegStatus, TransportType } from './leg';

export const dummyLeg = (legId = faker.datatype.uuid()) => ({
    id: legId,
    status: faker.helpers.arrayElement([LegStatus.ASSIGNED, LegStatus.COMPLETED, LegStatus.IN_PROGRESS]),
    journeyId: faker.datatype.uuid(),
    isFirstLeg: faker.datatype.boolean(),
    isLastLeg: faker.datatype.boolean(),
    previousLegId: faker.datatype.uuid(),
    nextLegId: faker.datatype.uuid(),
    description: faker.commerce.productDescription(),
    specialInstructions: faker.commerce.productMaterial(),
    driverId: faker.datatype.uuid(),
    vehicleId: faker.datatype.uuid(),
    startDateTime: faker.date.soon().toISOString(),
    endDateTime: faker.date.soon().toISOString(),
    transportType: faker.helpers.arrayElement([
        TransportType.AIR,
        TransportType.RAIL,
        TransportType.SEA,
        TransportType.ROAD,
    ]),
    destination: {
        address: {
            addressLine1: faker.address.streetAddress(),
            addressLine2: faker.address.buildingNumber(),
            addressLine3: faker.address.secondaryAddress(),
            addressLine4: faker.address.stateAbbr(),
            city: faker.address.cityName(),
            countryCode: faker.address.countryCode(),
            postalCode: faker.address.zipCode(),
            stateOrProvince: faker.address.state(),
        },
        coordinates: {
            latitude: faker.address.latitude(),
            longitude: faker.address.longitude(),
        },
        customerId: faker.datatype.uuid(),
        description: faker.address.direction(),
        id: faker.datatype.uuid(),
        specialInstruction: faker.commerce.productAdjective(),
    },
    destinationId: faker.datatype.uuid(),
    origin: {
        address: {
            addressLine1: faker.address.streetAddress(),
            addressLine2: faker.address.buildingNumber(),
            addressLine3: faker.address.secondaryAddress(),
            addressLine4: faker.address.stateAbbr(),
            city: faker.address.cityName(),
            countryCode: faker.address.countryCode(),
            postalCode: faker.address.zipCode(),
            stateOrProvince: faker.address.state(),
        },
        coordinates: {
            latitude: faker.address.latitude(),
            longitude: faker.address.longitude(),
        },
        customerId: faker.datatype.uuid(),
        description: faker.address.direction(),
        id: faker.datatype.uuid(),
        specialInstruction: faker.commerce.productAdjective(),
    },
    originId: faker.datatype.uuid(),
});

/* export const listLegs = (journeyId: string, params?: Partial<LegParams>): Promise<ListPayload<Leg>> =>
    Promise.resolve({ list: [...Array(10)].map(() => dummyLeg()), pageNumber: 0, pageSize: 1, total: 2 }); */
export const listLegs = (journeyId: string, params?: Partial<LegParams>): Promise<ListPayload<Leg>> =>
    axiosClient
        .get<ListResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs`, { params })
        .then((response) => response.data.payload);

/* export const getLeg = (journeyId: string, legId: string): Promise<Leg> => Promise.resolve(dummyLeg(legId)); */

export const getLeg = (journeyId: string, legId: string): Promise<Leg> =>
    axiosClient
        .get<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs/${legId}`)
        .then((response) => response.data.payload);

export const postLeg = (journeyId: string, leg: Leg): Promise<Leg> =>
    axiosClient
        .post<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs`, leg)
        .then((response) => response.data.payload);

export const putLeg = (journeyId: string, leg: Leg): Promise<Leg> =>
    axiosClient
        .put<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs`, leg)
        .then((response) => response.data.payload);

export const deleteLeg = (journeyId: string, legId: string): Promise<Leg> =>
    axiosClient
        .delete<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs/${legId}`)
        .then((response) => response.data.payload);

// TODO confirm and switch to correct endpoint
export const startLeg = (journeyId: string, legId: string): Promise<Leg> =>
    axiosClient
        .put<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs/${legId}/start`)
        .then((response) => response.data.payload);

// TODO confirm and switch to correct endpoint
export const completeLeg = (journeyId: string, legId: string): Promise<Leg> =>
    axiosClient
        .put<SuccessResponse<Leg>>(`${config.journeysUrl}/journeys/${journeyId}/legs/${legId}/complete`)
        .then((response) => response.data.payload);
