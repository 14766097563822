import { format, parseISO } from 'date-fns';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { FilterResult, FilterType } from './filter-result';

export const getFiltersForDeclaration = (filters: FilterResult[]) => {
    const result: {
        startDate: string;
        endDate: string;
        declarationStatus: DeclarationStatus[];
        declarationType: DeclarationName[];
    } = { startDate: '', endDate: '', declarationStatus: [], declarationType: [] };

    filters.forEach((filter) => {
        if (!filter.value) return;

        switch (filter.type) {
            case FilterType.DATE:
                const date = parseISO(filter.value[0]);
                result.startDate = format(date, 'yyyy-MM-dd');
                result.endDate = result.startDate;
                break;
            case FilterType.DECLARATION_STATUS:
                result.declarationStatus = filter.value as DeclarationStatus[];
                break;
            case FilterType.DECLARATION_TYPE:
                result.declarationType = filter.value as DeclarationName[];
                break;
            default:
                break;
        }
    });

    return result;
};
