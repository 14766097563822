import { FieldInputProps, FieldMetaProps } from 'formik';
import { FC } from 'react';
import { ErrorDiv, ErrorLabel, InputLabel, LabelDiv } from '../declarations/formInput/DeclarationInput.styles';
import { DatePickerDiv, StyledDatepicker } from './FormDatepicker.styles';

export interface FormDatepickerProps {
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    showTime?: boolean;
}

const FormDatepicker: FC<FormDatepickerProps> = ({ label, fieldProps, fieldMeta, showTime }) => {
    return (
        <>
            <LabelDiv>{label && <InputLabel>{label}</InputLabel>}</LabelDiv>
            <DatePickerDiv>
                <StyledDatepicker fieldProps={fieldProps} showTime={showTime}></StyledDatepicker>
            </DatePickerDiv>
            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
            </ErrorDiv>
        </>
    );
};

export default FormDatepicker;
