import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import useFormUtils from '../../../../../hooks/useFormUtils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import IdentityOfMeansOfTransportBlock, {
    identityOfMeansOfTransportAtDepartureBlockValidation,
    identityOfMeansOfTransportOnArrivalBlockValidation,
} from '../blocks/IdentityOfMeansOfTransportBlock';

export const identityOfMeansOfTransportAtDepartureCardValidation = {
    selfValidators: [identityOfMeansOfTransportAtDepartureBlockValidation],
};

export const identityOfMeansOfTransportOnArrivalCardValidation = {
    selfValidators: [identityOfMeansOfTransportOnArrivalBlockValidation],
};

const IdentityOfMeansOfTransport: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();

    return (
        <NewFormCard
            title={t(
                formType?.includes('EXPORT')
                    ? 'identityOfMeansOfTransportAtDeparture'
                    : 'identityOfMeansOfTransportOnArrival'
            )}
            hidden={props.hidden}
        >
            <FormCardContainer>
                <IdentityOfMeansOfTransportBlock
                    path={
                        formType?.includes('EXPORT')
                            ? 'goodsShipment.consignment.departureTransportMeans'
                            : 'goodsShipment.consignment.arrivalTransportMeans'
                    }
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default IdentityOfMeansOfTransport;
