export const requestedProcedureCodeOptions = [
    { id: '10', value: 'Permanent Export or Dispatch' },
    { id: '11', value: 'Inward Processing (IP) Prior Export Equivalence (PEE)' },
    { id: '21', value: 'Temporary export under Outward Processing (OP)' },
    { id: '22', value: 'Temporary export of dispatch under Outward Processing (OP) if not covered by Procedure 21' },
    { id: '23', value: 'Temporary export for return of goods in the unaltered state (Returned Goods Relief (RGR))' },
    { id: '31', value: 'Re-export or dispatch of non-Union goods following a Special Procedure' },
] as const;

export const previousProcedureCodeOptions = {
    '10': [
        { id: '07', value: 'Permanent Export or Dispatch of goods from an Excise Warehouse' },
        { id: '40', value: 'Permanent Export or Dispatch of Union goods or goods in Free Circulation and Home Use' },
        { id: '42', value: 'Permanent Export or Dispatch of goods previously placed under Onward Supply Relief (OSR)' },
        { id: '44', value: 'Permanent Export of goods previously entered to End Use' },
    ] as const,
    '11': [{ id: '00', value: 'Inward Processing (IP) Prior Export Equivalence (PEE)' }] as const,
    '21': [
        { id: '00', value: 'Temporary Export under Outward Processing (OP)' },
        { id: '44', value: 'Temporary Export under Outward Processing (OP) of goods previously entered to End Use' },
        {
            id: '51',
            value: 'Temporary Export under Outward Processing (OP) for goods previously entered to Inward Processing (IP)',
        },
        {
            id: '54',
            value: 'Temporary Export under Outward Processing (OP) for goods previously entered to Inward Processing (IP) in another Member State',
        },
    ] as const,
    '22': [
        {
            id: '00',
            value: 'Temporary Export or Dispatch under Outward Processing (OP) for goods not covered under Requested Procedure Code 21',
        },
        {
            id: '44',
            value: 'Temporary Export under Outward Processing (OP) for goods not covered under Requested Procedure Code 21, previously entered to End Use',
        },
    ] as const,
    '23': [
        {
            id: '00',
            value: 'Temporary Export or Dispatch of Union goods or goods in Free Circulation and/ or Home Use which are intended to be returned under Returned Goods Relief (RGR)',
        },
    ] as const,
    '31': [
        { id: '51', value: 'Re-export or Dispatch of non-Union goods previously entered to Inward Processing (IP)' },
        { id: '53', value: 'Re-export or Dispatch of non-Union goods previously entered to Temporary Admission (TA)' },
        {
            id: '54',
            value: 'Re-export of non-Union goods previously entered to IP in another Member State',
        },
        {
            id: '71',
            value: 'Re-export or Dispatch of non-Union goods previously entered to Customs Warehousing',
        },
    ] as const,
} as const;

export const additionalProcedureCodeAllOptions = {
    A04: { id: 'A04', value: 'Re-export or dispatch of goods to discharge an Inward Processing Procedure (VAT only)' },
    B51: { id: 'B51', value: 'Goods temporarily (re)exported for repair under guarantee using Outward Processing' },
    B52: {
        id: 'B52',
        value: 'Defective goods temporarily (re)exported for replacement under guarantee using Outward Processing',
    },
    B53: {
        id: 'B53',
        value: 'Outward Processing under agreements with third countries, possibly combined with VAT only Outward Processing',
    },
    B54: { id: 'A04', value: 'Goods temporarily dispatched under VAT only Outward Processing' },
    C12: {
        id: 'C12',
        value: 'Re-export of educational, scientific and cultural materials; scientific instruments and apparatus as listed in Annex II to Council Regulation (EC) No 1186/2009',
    },
    D01: { id: 'D01', value: 'Pallets (including pallet accessories and equipment)' },
    D02: { id: 'D02', value: 'Containers (including container accessories and equipment)' },
    D03: { id: 'D03', value: 'Means of road, rail, air, sea and inland waterway transport' },
    D04: { id: 'D04', value: 'Personal effects and goods for sports purposes previously imported by travellers' },
    D05: { id: 'D05', value: 'Welfare material for seafarers' },
    D06: { id: 'D06', value: 'Disaster relief material' },
    D07: { id: 'D07', value: 'Medical, surgical and laboratory equipment' },
    D08: { id: 'D08', value: 'Animals (twelve months or more)' },
    D09: { id: 'D09', value: 'Goods for use in frontier zone' },
    D10: { id: 'D10', value: 'Sound, image or data carrying media' },
    D11: { id: 'D11', value: 'Publicity material' },
    D12: { id: 'D12', value: 'Professional equipment' },
    D13: { id: 'D13', value: 'Pedagogic material and scientific equipment' },
    D15: { id: 'D15', value: 'Packings, empty' },
    D16: {
        id: 'D16',
        value: 'Moulds, dies, blocks, drawings, sketches, measuring, checking and testing instruments and other similar articles',
    },
    D17: { id: 'D17', value: 'Special tools and instruments (six months)' },
    D18: { id: 'D18', value: 'Goods, subject to tests, experiments or demonstrations' },
    D19: {
        id: 'D19',
        value: 'Goods imported, subject to satisfactory acceptance tests, in connection with a sales contract',
    },
    D20: {
        id: 'D20',
        value: 'Goods used to carry out tests, experiments or demonstrations without financial gain (six months)',
    },
    D21: { id: 'D21', value: 'Samples' },
    D22: { id: 'D22', value: 'Replacement means of production (six months)' },
    D23: { id: 'D23', value: 'Goods for events or for sale' },
    D24: { id: 'D24', value: 'Goods for approval (six months)' },
    D25: { id: 'D25', value: 'Works of art, collectors’ items and antiques' },
    D26: { id: 'D26', value: 'Goods imported with a view to their sale by auction' },
    D27: { id: 'D27', value: 'Spare parts, accessories and equipment' },
    D28: { id: 'D28', value: 'Goods imported in particular situations having no economic effect' },
    D29: { id: 'D29', value: 'Goods imported for a period not exceeding three months' },
    D30: {
        id: 'D30',
        value: 'Means of Transport, for persons established outside the customs Territory of the Union, or for persons preparing for the transfer of their normal place of residence outside that Territory',
    },
    D51: { id: 'D51', value: 'Temporary Admission with partial relief from duties' },
    F44: { id: 'F44', value: 'Inward Processing; customs debt under Article 86(3) of the Code' },
    F75: {
        id: 'F75',
        value: 'Goods dispatched in the context of trade with Special Fiscal Territories or territories with which the EU has formed a Customs Union (Article 1(3) of the Code)',
    },
    '000': {
        id: '000',
        value: 'No other Additional Procedure Code applies',
    },
    '0GD': {
        id: '0GD',
        value: 'Certain goods consigned by other Government Departments and agencies',
    },
    '0MG': {
        id: '0MG',
        value: 'Goods for Military Use Abroad',
    },
    '1CD': {
        id: '1CD',
        value: 'Controlled Drugs using Simplified Procedures',
    },
    '1CG': {
        id: '1CG',
        value: 'Controlled Goods using Simplified Procedures',
    },
    '1DP': {
        id: '1DP',
        value: 'Goods exported or dispatched under diplomatic privilege (official use)',
    },
    '1MP': {
        id: '1MP',
        value: 'Prior export of equivalent milk and milk products (EX/IM)',
    },
    '1NO': {
        id: '1NO',
        value: 'Goods exported or dispatched for US and other NATO visiting forces (official use)',
    },
    '1NP': {
        id: '1NP',
        value: 'Goods exported or dispatched by US and other NATO visiting forces personnel (goods for personal use)',
    },
    '1SW': {
        id: '1SW',
        value: 'Export of goods to discharge the shipwork or platform end-use procedure',
    },
    '1VW': {
        id: '1VW',
        value: 'Goods liable to VAT only being exported or dispatched from customs warehousing',
    },
    '2CD': {
        id: '2CD',
        value: 'Controlled drugs, re-exported or dispatched from a special procedure using Simplified Procedures',
    },
    '2CG': {
        id: '2CG',
        value: 'Controlled goods, re-exported or dispatched from a special procedure using Simplified Procedures',
    },
    '2DP': {
        id: '2DP',
        value: 'Goods exported or dispatched under Diplomatic Privilege (personal use)',
    },
    '2ES': {
        id: '2ES',
        value: 'Excise goods being exported or dispatched from onward supply relief, moving under an excise duty suspension, for movements not originating in the UK',
    },
    '2IE': {
        id: '2IE',
        value: 'Indirect Export: Goods exiting the UK where an export declaration has not been made in the other Member State and the goods cannot be declared by an oral act or act by other means',
    },
    '3LV': {
        id: '3LV',
        value: 'Low Value Export or Dispatch to a Single Recipient)',
    },
    '3NS': {
        id: '3NS',
        value: 'Home Use goods that are excluded from external trade statistics being exported or dispatched',
    },
    '3PE': {
        id: '3PE',
        value: 'Export or Dispatch of Personal Effects',
    },
    '3PM': {
        id: '3PM',
        value: 'Export or Dispatch of Private Means of Transport',
    },
    '3RI': {
        id: '3RI',
        value: 'Rejected Imports',
    },
    '46P': {
        id: '46P',
        value: 'The exportation of replacement goods following the prior import of processed products obtained from equivalent goods under Outward Processing (Prior Import Equivalence)',
    },
    '48P': {
        id: '48P',
        value: 'Export of defective goods under Outward Processing following the prior import of replacement products',
    },
} as const;

const getWithKeys = <TObject extends Record<string, any>>(object: TObject, keys: (keyof TObject)[]) => {
    return keys.map((key) => object[key]);
};

export const additionalProcedureCodeOptions = {
    '1007': getWithKeys(additionalProcedureCodeAllOptions, ['F75', '000', '1CG']),
    '1040': getWithKeys(additionalProcedureCodeAllOptions, [
        'C12',
        'F75',
        '000',
        '0GD',
        '0MG',
        '1CD',
        '1CG',
        '1DP',
        '1NO',
        '1NP',
        '2DP',
        '2IE',
        '3LV',
        '3NS',
        '3PE',
        '3PM',
        '3RI',
    ]),
    '1042': getWithKeys(additionalProcedureCodeAllOptions, ['F75', '000', '1CD', '1CG', '2ES']),
    '1044': getWithKeys(additionalProcedureCodeAllOptions, ['000', '0MG', '1SW', '2CD', '2CG']),
    '1100': getWithKeys(additionalProcedureCodeAllOptions, ['F44', '000', '1MP']),
    '2100': getWithKeys(additionalProcedureCodeAllOptions, ['000', '0GD', '46P']),
    '2144': getWithKeys(additionalProcedureCodeAllOptions, ['000', '0MG', '1SW', '46P']),
    '2151': getWithKeys(additionalProcedureCodeAllOptions, ['B51', 'B52', 'F44', '000', '0GD', '46P', '48P']),
    '2154': getWithKeys(additionalProcedureCodeAllOptions, ['B51', 'B52', 'F44', '000', '46P', '48P']),
    '2200': getWithKeys(additionalProcedureCodeAllOptions, [
        'B51',
        'B52',
        'B53',
        'B54',
        'F75',
        '000',
        '0GD',
        '46P',
        '48P',
    ]),
    '2244': getWithKeys(additionalProcedureCodeAllOptions, ['B51', 'B52', '000', '0MG', '1SW', '46P', '48P']),
    '2300': getWithKeys(additionalProcedureCodeAllOptions, [
        'F75',
        '000',
        '0GD',
        '0MG',
        '1CD',
        '1CG',
        '1DP',
        '1NO',
        '1NP',
        '2DP',
    ]),
    '3151': getWithKeys(additionalProcedureCodeAllOptions, ['A04', 'F44', 'F75', '000', '0GD', '2CD', '2CG']),
    '3153': getWithKeys(additionalProcedureCodeAllOptions, [
        'D01',
        'D02',
        'D03',
        'D04',
        'D05',
        'D06',
        'D07',
        'D08',
        'D09',
        'D10',
        'D11',
        'D12',
        'D13',
        'D15',
        'D16',
        'D17',
        'D18',
        'D19',
        'D20',
        'D21',
        'D22',
        'D23',
        'D24',
        'D25',
        'D26',
        'D27',
        'D28',
        'D29',
        'D30',
        'D51',
        '000',
    ]),
    '3154': getWithKeys(additionalProcedureCodeAllOptions, ['A04', 'F44', '000']),
    '3171': getWithKeys(additionalProcedureCodeAllOptions, [
        'F75',
        '000',
        '0MG',
        '1DP',
        '1NO',
        '1NP',
        '1VW',
        '2CD',
        '2CG',
        '2DP',
    ]),
};
