import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode } from 'react';

export interface MultiFormInputProps {
    label?: string;
    tooltip?: string;
    error?: string;
    icon?: ReactNode;
    children?: ReactNode;
    fieldHelpers: FieldHelperProps<any>;
    path: string;
    refNumber?: string;
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    getFieldMeta?: (name: string) => FieldMetaProps<any>;
    maxLength?: number;
    required?: boolean;
    viewOnly?: boolean;
    maxNumberOfInputs?: number;
    refClicked?: (refNumber: string | number) => void;
    objectInput?: string;
    condensed?: boolean;
}

const MultiDeclarationInput: FC<MultiFormInputProps> = (props) => {
    const {
        path,
        fieldHelpers,
        icon,
        label,
        tooltip,
        refNumber,
        maxLength,
        required,
        viewOnly,
        maxNumberOfInputs,
        objectInput,
        condensed,
    } = props;
    const arrayInputs = props.getFieldProps(path).value;

    const handleRemove = (index: number) => {
        const newArray = arrayInputs.filter((value: any, i: number) => index !== i);
        fieldHelpers.setValue(newArray);
    };

    const handleAdd = () => {
        if (!arrayInputs) {
            fieldHelpers.setValue([undefined]);
        } else {
            arrayInputs.push(undefined);
            fieldHelpers.setValue(arrayInputs);
        }
    };

    if (!arrayInputs || arrayInputs.length < 1) {
        return (
            <FormInput
                refClicked={props.refClicked}
                viewOnly={viewOnly}
                required={required}
                fieldProps={props.getFieldProps(`${path}.0`)}
                fieldHelper={props.getFieldHelpers(`${path}.0`)}
                icon={icon}
                label={label}
                tooltip={tooltip}
                refNumber={refNumber}
                maxLength={maxLength}
                multiple={{
                    node: (
                        <PlusOutlined
                            onClick={handleAdd}
                            style={{ marginLeft: '1.94rem', fontSize: '20pt', marginTop: '3px' }}
                        />
                    ),
                }}
                condensed={condensed}
            />
        );
    }

    const inputIcon = (index: number) => {
        if (index === arrayInputs.length - 1) {
            if (maxNumberOfInputs !== index + 1) {
                return {
                    node: (
                        <PlusOutlined
                            onClick={handleAdd}
                            style={{ marginLeft: '1.94rem', fontSize: '20pt', marginTop: '3px' }}
                        />
                    ),
                };
            } else {
                return {
                    node: (
                        <MinusOutlined
                            onClick={() => handleRemove(index)}
                            style={{ marginLeft: '1.94rem', fontSize: '20pt', marginTop: '3px' }}
                        />
                    ),
                };
            }
        } else {
            return {
                node: (
                    <MinusOutlined
                        onClick={() => handleRemove(index)}
                        style={{ marginLeft: '1.94rem', fontSize: '20pt', marginTop: '3px' }}
                    />
                ),
            };
        }
    };

    if (objectInput) {
        return arrayInputs.map((obj: any, index: number) => {
            const multiple = inputIcon(index);
            return (
                <FormInput
                    refClicked={props.refClicked}
                    viewOnly={viewOnly}
                    required={required}
                    key={index}
                    fieldProps={props.getFieldProps(`${path}.${index}.${objectInput}`)}
                    fieldHelper={props.getFieldHelpers(`${path}.${index}.${objectInput}`)}
                    icon={icon}
                    label={label}
                    tooltip={tooltip}
                    fieldMeta={props.getFieldMeta && props.getFieldMeta(`${path}.${index}.${objectInput}`)}
                    multiple={multiple}
                    refNumber={refNumber}
                    maxLength={maxLength}
                    condensed={condensed}
                />
            );
        });
    } else {
        return arrayInputs.map((obj: any, index: number) => {
            const multiple = inputIcon(index);
            return (
                <FormInput
                    refClicked={props.refClicked}
                    viewOnly={viewOnly}
                    required={required}
                    key={index}
                    fieldProps={props.getFieldProps(`${path}.${index}`)}
                    fieldHelper={props.getFieldHelpers(`${path}.${index}`)}
                    icon={icon}
                    label={label}
                    tooltip={tooltip}
                    fieldMeta={props.getFieldMeta && props.getFieldMeta(`${path}.${index}`)}
                    multiple={multiple}
                    refNumber={refNumber}
                    maxLength={maxLength}
                    condensed={condensed}
                />
            );
        });
    }
};
export default MultiDeclarationInput;
