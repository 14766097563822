export enum DeclarationName {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    H7 = 'H7',
    I1 = 'I1',
    I2 = 'I2',
    ENS = 'ENS',
    FULL_DEC = 'FULL DEC',
    EXPORT = 'EXPORT',
    ETD = 'ETD',
    TSD = 'TSD',
    ARRIVAL = 'Arrival at Exit',
    TEMPORARY = 'TEMPORARY STORAGE',
    UK_H1 = 'UK_H1',
    B1 = 'B1',
}

export const declarationNameLabels = [
    {
        id: DeclarationName.H1,
        value: 'Declaration for release for free circulation and Special procedure specific use declaration for end-use',
    },
    { id: DeclarationName.H2, value: 'Special procedure - storage – declaration for customs warehousing' },
    { id: DeclarationName.H3, value: 'Special procedure – specific use – declaration for temporary admission' },
    { id: DeclarationName.H4, value: 'Special procedure – processing – declaration for inward processing' },
    {
        id: DeclarationName.H5,
        value: 'Declaration for the introduction of goods in the context of trade with special fiscal territories',
    },
    { id: DeclarationName.H6, value: 'Customs declaration in postal traffic for release for free circulation' },
    {
        id: DeclarationName.H7,
        value: 'Customs declaration for release for free circulation in respect of a consignment which benefits from a relief from import duty in accordance with Article 23(1) or Article 25(1) of Regulation (EC) No 1186/2009',
    },
    { id: DeclarationName.I1, value: 'Import Simplified declaration' },
    {
        id: DeclarationName.I2,
        value: "Presentation of goods to customs in case of entry in the declarant's records or in the context of customs declarations lodged prior to the presentation of the goods at import",
    },
] as const;
