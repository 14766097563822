import Button from 'components/ui/base/button';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import { H5, Text, TextSmall } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { useFormik } from 'formik';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import styled from 'styled-components';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const InfoText = styled(TextSmall)`
    margin-bottom: 1rem;
`;

const formValidation = Yup.object().shape({
    reason: Yup.string().required(getRequiredMessage('reason')),
});

interface Props {
    declarationId: string;
    declarationType: DeclarationInternalType;
    visible: boolean;
    handleCancel: () => void;
}
const InvalidateModal: FC<Props> = ({ visible, handleCancel, declarationId, declarationType }) => {
    const { invalidateDeclaration, error, isLoading } = useDeclarations();
    const { t } = useTranslation('customs_declarations');

    const formik = useFormik<{
        reason: string;
    }>({
        initialValues: {
            reason: '',
        },
        validationSchema: formValidation,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleCancel]);

    const handleInvalidate = useCallback(
        async (reason: string) => {
            const res = await invalidateDeclaration(declarationId, reason, declarationType);
            if (res && !error && !isLoading) {
                handleCancel();
                Notification({
                    type: 'success',
                    messageTitle: t('invalidate.invalidate-success-title'),
                    description: t('invalidate.invalidate-success-message'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('invalidate.invalidate-error-title'),
                    description: t('invalidate.invalidate-error-message'),
                });
            }
        },
        [declarationId, handleCancel, t, error, isLoading, invalidateDeclaration, declarationType]
    );

    const handleSubmit = async (values: { reason: string }) => {
        await handleInvalidate(values.reason);
    };

    return (
        <CustomModal
            title={<H5>Invalidate declaration</H5>}
            centered
            visible={visible}
            onCancel={handleCancel}
            width={676}
            equalButtons
            footer={[
                <Button onClick={() => handleCancel()} size="large">
                    Cancel
                </Button>,
                <Button type="primary" onClick={() => formik.handleSubmit()} size="large">
                    <Text>Invalidate</Text>
                </Button>,
            ]}
        >
            <InfoText>Please enter the reason to invalidate the declaration.</InfoText>
            <FormInput
                label="Reason"
                fieldProps={formik.getFieldProps('reason')}
                fieldMeta={formik.getFieldMeta('reason')}
            />
        </CustomModal>
    );
};
export default InvalidateModal;
