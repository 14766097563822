import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import useHideEntities from '../../../../../hooks/useHideEntities';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import CustomsOfficeOfExitBlock, { customsOfficeOfExitBlockValidation } from '../blocks/CustomsOfficeOfExitBlock';
import CustomsOfficeOfPresentationBlock, {
    customsOfficeOfPresentationBlockValidation,
} from '../blocks/CustomsOfficeOfPresentationBlock';
import { ExtractValidationSchema } from '../validations/validations';

export const customsOfficesCardValidation = {
    selfValidators: [customsOfficeOfPresentationBlockValidation, customsOfficeOfExitBlockValidation],
};
export type CustomsOfficesCardValidation = ExtractValidationSchema<typeof customsOfficesCardValidation>;

export const cdsImportOfficesCardValidation = {
    selfValidators: [customsOfficeOfPresentationBlockValidation],
};

interface Props extends DeclarationFormCardProps {}

const CustomsOfficesCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <NewFormCard title={t('customsOfficeOfPresentation')} hidden={props.hidden}>
            <FormCardContainer>
                <CustomsOfficeOfPresentationBlock />
                <CustomsOfficeOfExitBlock hide={isFormType(['IMPORT_H1', 'IMPORT_H2'])} />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CustomsOfficesCard;
