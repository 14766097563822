import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { objectIsEmpty } from 'core/utils/objects';
import { FormikProps, setNestedObjectValues } from 'formik';
import { FC, useMemo } from 'react';

export const hasNoValuesDeep = (value: Object | string | undefined): boolean => {
    if (typeof value !== 'object' || value == null) return !value;

    let empty = true;
    for (let v of Object.values(value)) {
        if (!hasNoValuesDeep(v)) {
            empty = false;
            break;
        }
    }
    return empty;
};

interface Props {
    visible: boolean;
    formik: FormikProps<any>;
    children: React.ReactNode;
    onCancel: () => void;
    width?: number;
    testId?: string;
}
const ModalEditCardListItem: FC<Props> = ({ visible, children, formik, width = 676, onCancel, testId }) => {
    const { validateForm, setTouched, handleSubmit, resetForm, values } = formik;

    const disabledOkButton = useMemo(() => hasNoValuesDeep(values), [values]);

    const handleAddItem = async () => {
        const validate = await validateForm().then((v) => {
            setTouched(setNestedObjectValues(v, true));
            return v;
        });
        if (objectIsEmpty(validate)) {
            await handleSubmit();
        }
    };

    const handleCancel = () => {
        resetForm();
        onCancel();
    };

    return (
        <CustomModal
            title={<H5>Add item</H5>}
            centered
            cancelText="Cancel"
            confirmText="Continue"
            disabledOkButton={disabledOkButton}
            testId={testId ?? 'add-item-edit-card-list-modal'}
            visible={visible}
            onOk={handleAddItem}
            onCancel={handleCancel}
            width={width}
        >
            {children}
        </CustomModal>
    );
};
export default ModalEditCardListItem;
