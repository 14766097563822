import { cloneDeep } from 'lodash';
import { DeclarationCustomer } from '../../../../../store/declarations/common/declaration-customer';
import {
    transformAdditionalDocumentForClient,
    transformAdditionalDocumentForServer,
    transformCommodityCodesForClient,
    transformCommodityCodesForServer,
} from '../../export/utils';

class CdsImportH1DeclarationUtils {
    static transformForClient(_cdsDeclaration: any) {
        const cdsDeclaration = cloneDeep(_cdsDeclaration);
        const payload = { ...cdsDeclaration.cdsExportDeclarationPayload };

        const typeCode = payload?.typeCode?.substring(0, 2);
        const additionalTypeCode = payload?.typeCode?.substring(2);

        const deferredPayments = payload?.additionalDocument;
        const buyer = payload?.goodsShipment?.buyer;
        const seller = payload?.goodsShipment?.seller;

        const deferredPaymentsMapped = deferredPayments?.map((obj: any) => {
            return {
                id: obj.id,
                categoryAndType: `${obj.categoryCode}${obj.typeCode}`,
            };
        });

        return {
            ...cdsDeclaration,
            cdsExportDeclarationPayload: {
                ...payload,
                typeCode,
                additionalTypeCode,
                additionalDocument: deferredPaymentsMapped,
                goodsShipment: {
                    ...payload.goodsShipment,
                    buyer: transformUkCustomerForClient(buyer),
                    seller: transformUkCustomerForClient(seller),
                    governmentAgencyGoodsItem: cdsDeclaration.cdsExportGovernmentAgencyGoodsItems?.map((item: any) =>
                        this.transformProductForClient(item.governmentAgencyGoodsItem)
                    ),
                },
            },
        };
    }

    static transformForServer(_cdsDeclaration: any) {
        const cdsDeclaration = cloneDeep(_cdsDeclaration);
        const payload = { ...cdsDeclaration.cdsExportDeclarationPayload };

        delete payload.goodsShipment?.governmentAgencyGoodsItem;

        let typeCode = payload.typeCode;
        if (payload.additionalTypeCode) {
            typeCode += payload.additionalTypeCode;
            delete payload.additionalTypeCode;
        }

        const deferredPayments = payload?.additionalDocument;
        const buyer = payload?.goodsShipment?.buyer;
        const seller = payload?.goodsShipment?.seller;

        const deferredPaymentsMapped = deferredPayments?.map((obj: any) => {
            const categoryAndType = obj?.categoryAndType;
            delete obj?.categoryAndType;

            return {
                ...obj,
                id: obj.id,
                categoryCode: categoryAndType?.charAt(0),
                typeCode: categoryAndType?.substring(1),
            };
        });

        return {
            ...cdsDeclaration,
            declarationType: cdsDeclaration.cdsExportDeclarationPayload?.typeCode,
            additionalDeclarationType: cdsDeclaration.cdsExportDeclarationPayload?.additionalTypeCode,
            cdsExportDeclarationPayload: {
                ...payload,
                typeCode,
                additionalDocument: deferredPaymentsMapped,
                goodsShipment: {
                    ...payload.goodsShipment,
                    buyer: transformUkCustomerForServer(buyer),
                    seller: transformUkCustomerForServer(seller),
                },
            },
        };
    }

    static transformProductForClient(_product: any) {
        const product = cloneDeep(_product);
        const buyer = product?.buyer;
        const seller = product?.seller;
        let currentCode: string | undefined;
        let previousCode: string | undefined;
        let additionalCurrentCode: (string | undefined)[] = [];

        (product?.governmentProcedure as any)?.forEach((procedure: any, index: number) => {
            if (index === 0) {
                currentCode = procedure.currentCode;
                previousCode = procedure.previousCode;
            } else {
                additionalCurrentCode?.push(procedure.currentCode);
            }
        });

        const commodityCodes = transformCommodityCodesForClient(product);
        const additionalDocument = transformAdditionalDocumentForClient(product);

        return {
            ...product,
            buyer: transformUkCustomerForClient(buyer),
            seller: transformUkCustomerForClient(seller),
            governmentProcedure: {
                currentCode,
                previousCode,
                additionalCurrentCode,
            },
            commodity: {
                ...product?.commodity,
                classification: commodityCodes,
            },
            additionalDocument,
        };
    }

    static transformProductForServer(_product: any) {
        const product = cloneDeep(_product);
        const buyer = product?.buyer;
        const seller = product?.seller;
        const additionalCurrentCode = product.governmentProcedure?.additionalCurrentCode?.map(
            (additionalCurrentCode: any) => ({
                currentCode: additionalCurrentCode,
            })
        );

        let procedures: any = [
            {
                currentCode: product.governmentProcedure?.currentCode,
                previousCode: product.governmentProcedure?.previousCode,
            },
        ];

        if (additionalCurrentCode) {
            procedures = [...procedures, ...additionalCurrentCode];
        }

        const commodityCodes = transformCommodityCodesForServer(product);
        const additionalDocument = transformAdditionalDocumentForServer(product);

        return {
            ...product,
            governmentProcedure: procedures,
            buyer: transformUkCustomerForServer(buyer),
            seller: transformUkCustomerForServer(seller),
            commodity: {
                ...product?.commodity,
                classification: commodityCodes,
            },
            additionalDocument,
        };
    }
}

const transformUkCustomerForClient = (customer: any) => {
    if (!customer) return customer;
    const phoneNumber = customer?.communication?.id;
    delete customer?.communication?.id;

    return {
        ...customer,
        phoneNumberCode: phoneNumber?.slice(0, 2),
        phoneNumber: phoneNumber?.substring(2),
    };
};

const transformUkCustomerForServer = (customer: DeclarationCustomer | undefined) => {
    if (!customer) return customer;

    const phoneNumber = `${customer.phoneNumberCode}${customer.phoneNumber}`;

    delete customer.phoneNumber;
    delete customer.phoneNumberCode;

    return { ...customer, communication: { id: phoneNumber } };
};

export default CdsImportH1DeclarationUtils;
