import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { WCOCompanyDetailsFormikFields } from '../WCOTypes';
import { wcoCompanyDetailsValidationSchema } from './wcoValidationSchemas';

const initialValues: WCOCompanyDetailsFormikFields = {
    companyName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    phoneCode: '',
    phoneNumber: '',
    city: '',
    country: '',
};

const useFormikWCOCompanyDetails = () => {
    const formik = useFormik({
        initialValues,
        validationSchema: wcoCompanyDetailsValidationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);

    return formik;
};

export default useFormikWCOCompanyDetails;
