import { useDispatch } from 'react-redux';
import { doGetDeclarationProductTaxes } from 'store/declaration-product-taxes/action';
import { useAppSelector } from '../config/hooks';

const useDeclarationProductTaxes = (declarationId: string | undefined) => {
    const state = useAppSelector((state) => state.commodityCodeTaxes);
    const dispatch = useDispatch();

    const getDeclarationProductTaxes = async () => {
        if (!declarationId) return null;
        return await dispatch(doGetDeclarationProductTaxes(declarationId));
    };

    return {
        ...state,
        getDeclarationProductTaxes,
    };
};
export default useDeclarationProductTaxes;
