import FormInput from 'components/ui/composed/formInput/FormInput';
import { useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, ReturnLoginBt, SendLinkBt } from './ForgotPassword.styles';

const formValidation = Yup.object().shape({
    email: Yup.string().email('Email invalid').required('Email is required.').nullable(),
});

const EmailForm: FC<{ handleEmailSent: Function }> = ({ handleEmailSent }) => {
    const { isLoading, forgotPassword, error } = useSession();
    const [sendEmail, setSendEmail] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('login');

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: formValidation,
        onSubmit: (values: { email: string }) => handleSubmit(values),
    });

    useEffect(() => {
        if (isLoading === false && sendEmail && error === undefined) {
            handleEmailSent();
        }
    }, [isLoading, sendEmail, error, handleEmailSent]);

    useEffect(() => {
        if (error !== undefined && sendEmail) {
            formik.getFieldHelpers('email').setError('The email is not recognized by the system.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, sendEmail]);

    const handleSubmit = (form: { email: string }) => {
        forgotPassword(form.email);
        setSendEmail(true);
    };

    return (
        <>
            <Form layout="vertical">
                <FormInput
                    label="Email"
                    fieldMeta={formik.getFieldMeta('email')}
                    fieldProps={formik.getFieldProps('email')}
                    fieldHelper={formik.getFieldHelpers('email')}
                />
                <SendLinkBt
                    type="primary"
                    size="large"
                    onClick={() => {
                        formik.handleSubmit();
                    }}
                >
                    {t('forgot_password.send_reset_link')}
                </SendLinkBt>
            </Form>
            <ReturnLoginBt
                type="link"
                size="large"
                onClick={() => {
                    navigate('/');
                }}
            >
                {t('forgot_password.return_to_login')}
            </ReturnLoginBt>
        </>
    );
};

export default EmailForm;
