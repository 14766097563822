import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import AdditionalInformationCard from './cards/AdditionalInformationCard';
import ConsigneeCard from './cards/ConsigneeCard';
import CustomOfficeCard from './cards/CustomOfficeCard';
import GoodsInformationCard from './cards/GoodsInformationCard';
import HolderOfTheTransitProcedureCard from './cards/HolderOfTheTransitProcedureCard';
import PlacesCard from './cards/PlacesCard';
import ProducedDocumentsWritingOffCard from './cards/ProducedDocumentsWritingOffCard';
import RepresentativeCard from './cards/RepresentativeCard';
import SimplifiedDeclarationDocumentWritingOffCard from './cards/SimplifiedDeclarationDocumentWritingOffCard';
import SupplyChainActorCard from './cards/SupplyChainActorCard';
import TransportInformationCard from './cards/TransportInformationCard';

export interface MasterDetailsIrelandEtdCards {
    cards: Card[];
}

const etdDeclarationSkeleton: MasterDetailsIrelandEtdCards = {
    cards: [
        {
            key: 'etd-goods-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'goodsInformation',
            valid: false,
            component: GoodsInformationCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'declaration.goodsInformation',
            getFields: () => [
                { name: `grossMass`, required: true },
                { name: `totalPackageNumber`, required: true },
            ],
        },
        {
            key: 'etd-custom-office-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 2,
            defaultOpen: false,
            title: 'customOffice',
            valid: false,
            component: CustomOfficeCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'declaration.customsOffices',
            getFields: () => [
                { name: `customsOfficeLodgement`, required: false },
                { name: `customsOfficeOfDestination`, required: false },
            ],
        },
        {
            key: 'etd-representative-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'representative',
            valid: false,
            component: RepresentativeCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'declaration.parties.representative',
            getFields: () => [
                { name: `eori`, required: false },
                { name: `name`, required: false },
                { name: `streetAndNumber`, required: false },
                { name: `country`, required: false },
                { name: `postCode`, required: false },
                { name: `city`, required: false },
                { name: `tin`, required: false },
            ],
        },
        {
            key: 'holder-of-the-transit-procedure-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'holderOfTheTransitProcedure',
            valid: false,
            component: HolderOfTheTransitProcedureCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'declaration.parties.holderOfTheTransitProcedure',
            getFields: () => [
                { name: `eori`, required: false },
                { name: `name`, required: false },
                { name: `streetAndNumber`, required: false },
                { name: `country`, required: false },
                { name: `postCode`, required: false },
                { name: `city`, required: false },
                { name: `tin`, required: false },
            ],
        },
        {
            key: 'etd-consignee-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'consignee',
            valid: false,
            component: ConsigneeCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.parties.consignee',
            getFields: () => [
                { name: `eori`, required: false },
                { name: `name`, required: false },
                { name: `streetAndNumber`, required: false },
                { name: `country`, required: false },
                { name: `postCode`, required: false },
                { name: `city`, required: false },
                { name: `tin`, required: false },
            ],
        },
        {
            key: 'etd-supply-chain-actor-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'supplyChainActor',
            valid: false,
            component: SupplyChainActorCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.parties',
            getFields: () => [
                { name: `roleCode`, required: false },
                { name: `traderIdentification`, required: false },
            ],
        },
        {
            key: 'etd-simplified-document-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: SimplifiedDeclarationDocumentWritingOffCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: () => [
                { name: `previousDocumentType`, required: false },
                { name: `previousDocumentLineId`, required: false },
                { name: `previousDocumentIdentifier`, required: false },
            ],
        },
        {
            key: 'etd-produced-documents-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: ProducedDocumentsWritingOffCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: () => [
                { name: `documentType`, required: false },
                { name: `documentIdentifier`, required: false },
            ],
        },
        {
            key: 'etd-additional-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'additionalInformation',
            valid: false,
            component: AdditionalInformationCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: () => [
                { name: `additionalInformationCode`, required: false },
                { name: `additionalInformationText`, required: false },
            ],
        },
        {
            key: 'etd-transport-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: TransportInformationCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.transportInformation',
            getFields: () => [
                { name: `departureTransportMeans.identificationType`, required: false },
                { name: `departureTransportMeans.identificationNumber`, required: false },
                { name: `nationality`, required: false },
            ],
        },
        {
            key: 'etd-palces-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: PlacesCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.datesPlaces',
            getFields: () => [
                { name: `countryDestination`, required: false },
                { name: `transportationPlace.unlocode`, required: false },
                { name: `transportationPlace.countryCode`, required: false },
                { name: `transportationPlace.place`, required: false },
            ],
        },
    ],
};
export default etdDeclarationSkeleton;
