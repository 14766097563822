import { Button, Layout, Row } from 'antd';
import { Text, TextLarge, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';
const { Header, Content } = Layout;

interface ButtonProps {
    marginRight?: string;
}

export const StyledLayout = styled(Layout)`
    height: 100vh;
`;

export const StyledHeader = styled(Header)`
    background-color: ${colors.white};
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 16.8rem;
    padding: 0;
    margin: 0;
`;

export const StyledContent = styled(Content)`
    background: ${colors.white};
    padding: 0 3.2rem 0;
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
`;

export const ContentDiv = styled.div`
    background: ${colors.white};
    width: 61.6rem;
    height: 100%;
`;

export const BackButton = styled(Text)`
    cursor: pointer;
`;

export const HeaderContent = styled.div`
    padding: 1.6rem 3.2rem 3.2rem;
    width: 100%;
    height: 100%;
`;

export const ActiveButton = styled(Button)<ButtonProps>`
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
    height: 3.2rem;
    background: ${colors.lightGrey2};
    border: none;
    color: ${colors.purpleBlue};
    font-size: 1.4rem;
    border-radius: 2px;
    &:hover {
        color: ${colors.purpleBlue};
        border: none;
        background: ${colors.lightGrey2};
    }
    &:focus {
        color: ${colors.purpleBlue};
        border: none;
        background: ${colors.lightGrey2};
    }
`;

export const NotActiveButton = styled(Button)<ButtonProps>`
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
    border: none;
    height: 3.2rem;
    color: ${colors.purpleBlue};
    font-size: 1.4rem;
    border-radius: 2px;
    &:hover {
        background: ${colors.lightGrey2};
        border: none;
        color: ${colors.purpleBlue};
    }
    &:focus {
        color: ${colors.purpleBlue};
        border: none;
        background: ${colors.lightGrey2};
    }
`;

export const ButtonsRow = styled(Row)`
    width: 61.6rem;
`;

export const Title = styled(TextLarge)`
    font-weight: ${fonts.weight.bold};
`;

export const Body = styled(TextSmall)`
    padding-top: 0.8rem;
    padding-bottom: 3.6rem;
    white-space: pre-wrap;
`;

export const HeaderRow = styled(Row)`
    height: 6.4rem;
    margin-bottom: 2.4rem;
`;

export const DefinitionRow = styled(Row)`
    padding-bottom: 3.6rem;
`;
