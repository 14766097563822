import config from 'config';
import { ListPayload, ListResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { AisBusinessRule } from './ais-business-rule';

export const listAisBusinessRules = (): Promise<ListPayload<AisBusinessRule>> => {
    return axiosClient
        .get<ListResponse<AisBusinessRule>>(`${config.resourcesUrl}/revenue/ais/business-rules`)
        .then((response) => response.data.payload);
};
