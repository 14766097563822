import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { colors, fonts } from 'theme';

export const BreadcrumbItem = styled.span`
    font-size: 1.4rem;
    font-weight: ${fonts.weight.semibold};
`;

export const BreadcrumbLastItem = styled.span`
    color: ${colors.purpleBlue};
    font-size: 1.4rem;
    font-weight: ${fonts.weight.semibold};
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
    margin: 2.5rem 0;
    margin-left: 2.4rem;
`;
