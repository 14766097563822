import { FC } from 'react';
import { Avatar as AntAvatar } from 'antd';

interface AvatarProps {
    name: string;
}

const getInitials = (name: string) => {
    if (name) {
        const names = name.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    } else {
        return;
    }
};

const Avatar: FC<AvatarProps> = ({ name }) => {
    return (
        <AntAvatar style={{ backgroundColor: '#383752', marginRight: '0.9rem' }} shape="square" size="small">
            {getInitials(name)}
        </AntAvatar>
    );
};

export default Avatar;
