import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useHideEntities from '../../../../../../hooks/useHideEntities';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { DeclarationFormCardProps } from '../../../../common/declaration.form.card';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { ValidationSchema, validators } from '../../../export/validations/validations';
import CalculationOfTaxesBlock, { calculationOfTaxesBlockValidation } from '../../blocks/CalculationOfTaxesBlock';
import CalculationOfTaxesMethodOfPaymentBlock, {
    calculationOfTaxesMethodOfPaymentBlockValidation,
} from '../../blocks/CalculationOfTaxesMethodOfPaymentBlock';
import CalculationOfTaxesTaxBaseBlock, {
    calculationOfTaxesTaxBaseBlockValidation,
} from '../../blocks/CalculationOfTaxesTaxBaseBlock';
import CalculationOfTaxesTaxTypeBlock, {
    calculationOfTaxesTaxTypeBlockValidation,
} from '../../blocks/CalculationOfTaxesTaxTypeBlock';
import CalculationOfTaxesTotalBlock, {
    calculationOfTaxesTotalBlockValidation,
} from '../../blocks/CalculationOfTaxesTotalBlock';
import PreferenceBlock, {
    preferenceBlockRequiredValidation,
    preferenceBlockValidation,
} from '../../blocks/PreferenceBlock';
import QuotaOrderNumberBlock, { quotaOrderNumberBlockValidation } from '../../blocks/QuotaOrderNumberBlock';

export const dutyTaxFeeCardValidation: ValidationSchema<any> = {
    childValidators: {
        'commodity.dutyTaxFee': {
            selfValidators: [
                validators.minLength(1),
                calculationOfTaxesTaxTypeBlockValidation,
                calculationOfTaxesTaxBaseBlockValidation,
                calculationOfTaxesBlockValidation,
                calculationOfTaxesTotalBlockValidation,
                calculationOfTaxesMethodOfPaymentBlockValidation,
                quotaOrderNumberBlockValidation,
                preferenceBlockRequiredValidation,
            ],
            stopAtNull: true,
        },
    },
};

export const smallerDutyTaxFeeCardValidation = {
    selfValidators: [preferenceBlockValidation],
};

const DutyTaxFeeCard = ({
    viewOnly,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    toggleHelp,
    cardTotal,
    propsPathPrefix,
    keyCard,
}: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    const isImportH2 = useMemo(() => isFormType(['IMPORT_H2']), [isFormType]);

    return (
        <MultipleItemsCard
            title={t('dutyTaxFee.title')}
            keyCard={keyCard}
            viewOnly={viewOnly}
            path={propsPathPrefix!}
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            getFieldHelpers={getFieldHelpers}
            toggleHelp={toggleHelp}
            cardTotal={cardTotal}
            initialValues={{
                typeCode: '',
                specificTaxBaseQuantity: '',
                specificTaxBaseQuantityCurrencyId: '',
                quotaOrderID: '',
                dutyRegimeCode: '',
                payment: {
                    paymentAmount: '',
                    paymentAmountCurrencyId: '',
                    taxAssessedAmount: '',
                    taxAssessedAmountCurrencyId: '',
                    methodCode: '',
                },
            }}
            list={(list) =>
                isFormType(['IMPORT_H2'])
                    ? [{ field: t('preference'), value: list.dutyRegimeCode }]
                    : [
                          {
                              field: t('calculationOfTaxesTaxType'),
                              value: list.typeCode,
                          },
                          {
                              field: t('calculationOfTaxesTaxBaseQuantity'),
                              value: list.specificTaxBaseQuantity,
                          },
                          {
                              field: t('calculationOfTaxesTaxBaseUnitAndQualifier'),
                              value: list.specificTaxBaseQuantityCurrencyId,
                          },
                          {
                              field: t('calculationOfTaxesPayableTaxAmount'),
                              value: list.payment?.paymentAmount,
                          },
                          {
                              field: t('calculationOfTaxesTaxTypePayableTaxAmountCurrency'),
                              value: list.payment?.paymentAmountCurrencyId,
                          },
                          {
                              field: t('calculationOfTaxeTotal'),
                              value: list.payment?.taxAssessedAmount,
                          },
                          {
                              field: t('calculationOfTaxesTotalCurrency'),
                              value: list.payment?.taxAssessedAmountCurrencyId,
                          },
                          { field: t('calculationOfTaxesMethodOfPayment'), value: list.payment?.methodCode },
                          { field: t('quotaOrderNumber'), value: list.quotaOrderID },
                          { field: t('preference'), value: list.dutyRegimeCode },
                      ]
            }
            hidden={isFormType(['EXPORT_B1'])}
            condensed
            refNumber={isImportH2 ? '4/17' : ['4/3', '4/4', '4/6', '4/7', '4/8', '8/1', '4/17']}
            required={!isImportH2}
        >
            {(path) => (
                <FormCardContainer>
                    <CalculationOfTaxesTaxTypeBlock path={path} hide={isImportH2} />
                    <CalculationOfTaxesTaxBaseBlock path={path} hide={isImportH2} />
                    <CalculationOfTaxesBlock path={path} hide={isImportH2} />
                    <CalculationOfTaxesTotalBlock path={path} hide={isImportH2} />
                    <CalculationOfTaxesMethodOfPaymentBlock path={path} hide={isImportH2} />
                    <QuotaOrderNumberBlock path={path} hide={isImportH2} />
                    <PreferenceBlock
                        path={path}
                        declarationOptions={{ UK_IMPORT_H1: { fieldOptions: { dutyRegimeCode: { required: true } } } }}
                    />
                </FormCardContainer>
            )}
        </MultipleItemsCard>
    );
};

export default DutyTaxFeeCard;
