import Button from 'components/ui/base/button';
import Modal from 'components/ui/base/modal/Modal';
import { FC, useMemo } from 'react';
import { Exchange } from 'store/exchanges/exchange';
import {
    ContainerButtonDiv,
    ContainerH5Text,
    ContainerText,
    CurrencyText,
    DoApplyText,
    TitleText,
} from './ModalExchangeRating.styles';

interface Props {
    isOpen: boolean;
    close: Function;
    onConfirm: (currency: Exchange) => void;
    currency?: Exchange;
}

const ModalExchangeRating: FC<Props> = ({ isOpen, close, currency, onConfirm }) => {
    const currencyText = useMemo(() => {
        return `${currency?.currency.name}: ${currency?.rate}`;
    }, [currency]);

    return (
        <Modal
            title={
                <ContainerH5Text>
                    <TitleText>Exchange Rate</TitleText>
                </ContainerH5Text>
            }
            centered
            visible={isOpen}
            onCancel={() => close()}
            width={553}
            cancelText="Cancel"
            footer={
                <ContainerButtonDiv>
                    <Button
                        key="goToSettings"
                        onClick={() => {
                            close();
                        }}
                        size="large"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        key="goToDeclaration"
                        onClick={() => {
                            if (currency) {
                                onConfirm(currency);
                            }
                            close();
                        }}
                        size="large"
                    >
                        Confirm
                    </Button>
                </ContainerButtonDiv>
            }
        >
            <ContainerButtonDiv style={{ marginBottom: '2.4rem' }}>
                <DoApplyText>{currency?.availableFrom && `Date of validity: ${currency?.availableFrom}`}</DoApplyText>
            </ContainerButtonDiv>
            <ContainerH5Text>
                <CurrencyText>{currencyText}</CurrencyText>
            </ContainerH5Text>
            <ContainerText>
                <DoApplyText>Do you want to apply?</DoApplyText>
            </ContainerText>
        </Modal>
    );
};

export default ModalExchangeRating;
