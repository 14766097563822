import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import AdditionalInformationCard from './cards/AdditionalInformationCard';
import CustomOfficeCard from './cards/CustomOfficeCard';
import DeclarantCard from './cards/DeclarantCard';
import PlacesCard from './cards/PlacesCard';
import PresentationCard from './cards/PresentationCard';
import ProducedDocumentsWritingOffCard from './cards/ProducedDocumentsWritingOffCard';
import RepresentativeCard from './cards/RepresentativeCard';
import SimplifiedDeclarationDocumentWritingOffCard from './cards/SimplifiedDeclarationDocumentWritingOffCard';
import SupplyChainActorCard from './cards/SupplyChainActorCard';
import TransportInformationCard from './cards/TransportInformationCard';
import ActiveBorderTransportMeansCard from './cards/ActiveBorderTransportMeansCard';
import ContainerIdentificationCard from './cards/ContainerIdentificationCard';

export interface MasterDetailsIrelandTsdCards {
    cards: Card[];
}

const tsdDeclarationSkeleton: MasterDetailsIrelandTsdCards = {
    cards: [
        {
            key: 'tsd-custom-office-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'customOffice',
            valid: false,
            component: CustomOfficeCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'customsOffices',
            getFields: () => [
                { name: `supervisingCustomsOffices`, required: false },
                { name: `customsOfficeOfLodgement`, required: false },
            ],
        },
        {
            key: 'tsd-representative-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 2,
            defaultOpen: false,
            title: 'representative',
            valid: false,
            component: RepresentativeCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'parties.representative',
            getFields: () => [
                { name: `identifier`, required: false },
                { name: `status`, required: false },
            ],
        },
        {
            key: 'tsd-declarant-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'declarant',
            valid: false,
            component: DeclarantCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'parties.declarant',
            getFields: () => [{ name: `identifier`, required: false }],
        },
        {
            key: 'tsd-presentation-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'presentation',
            valid: false,
            component: PresentationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment',
            getFields: () => [
                { name: `totalGrossMass`, required: false },
                { name: `presentationTrader`, required: false },
                { name: `firstEntryCustomsOffice`, required: false },
            ],
        },
        {
            key: 'tsd-active-border-transport-means-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 5,
            defaultOpen: false,
            title: 'modeOfTransportAtTheBorder',
            valid: false,
            component: ActiveBorderTransportMeansCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.presentation.activeBorderTransportMeans',
            getFields: () => [
                { name: `type`, required: false },
                { name: `identification`, required: false },
            ],
        },
        {
            key: 'tsd-simplified-documents-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 6,
            defaultOpen: false,
            title: 'simplifiedDeclarationDocumentWritingOff',
            valid: false,
            component: SimplifiedDeclarationDocumentWritingOffCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: (index) => [
                { name: `simplifiedDeclarationDocumentWritingOff.${index}.previousDocumentType`, required: false },
                {
                    name: `simplifiedDeclarationDocumentWritingOff.${index}.previousDocumentIdentifier`,
                    required: false,
                },
                {
                    name: `simplifiedDeclarationDocumentWritingOff.${index}.previousDocumentIdentifier`,
                    required: false,
                },
            ],
        },
        {
            key: 'tsd-additional-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 7,
            defaultOpen: false,
            title: 'additionalInformation',
            valid: false,
            component: AdditionalInformationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: (index) => [
                { name: `additionalInformation.${index}.additionalInformationCode`, required: false },
                { name: `additionalInformation.${index}.additionalInformationText`, required: false },
            ],
        },
        {
            key: 'tsd-produced-documents-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 8,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: ProducedDocumentsWritingOffCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.documentsAuthorisations',
            getFields: () => [
                { name: `documentType`, required: false },
                { name: `documentIdentifier`, required: false },
            ],
        },
        {
            key: 'tsd-places-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 9,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: PlacesCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsShipment.datesPlaces',
            getFields: () => [{ name: `countryDestination`, required: false }],
        },
        {
            key: 'tsd-transport-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 10,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: TransportInformationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.transportInformation',
            getFields: () => [
                { name: `departureTransportMeans.identificationType`, required: false },
                { name: `departureTransportMeans.identificationNumber`, required: false },
                { name: `nationality`, required: false },
            ],
        },
        {
            key: 'tsd-container-identification-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 11,
            defaultOpen: false,
            title: 'containerIdentificationNumbers',
            valid: false,
            component: ContainerIdentificationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.transportInformation.containerIdentificationNumber',
            getFields: (index) => [{ name: `${index}.containerIdentificationNumber`, required: false }],
        },
        {
            key: 'tsd-supply-chain-actor-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 12,
            defaultOpen: false,
            title: 'supplyChainActor',
            valid: false,
            component: SupplyChainActorCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsShipment.supplyChainActors',
            getFields: (index) => [
                { name: `${index}.roleCode`, required: false },
                { name: `${index}.traderIdentification`, required: false },
            ],
        },
    ],
};
export default tsdDeclarationSkeleton;
