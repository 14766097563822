export enum IrelandExportMessageType {
    B1 = 'B1', // Export declaration and re-export declaration
    B2 = 'B2', // Special procedure - processing - Declaration for outward processing
    B3 = 'B3', // Declaration for Customs warehousing of Union goods
    B4 = 'B4', // Declaration for dispatch of goods in the context of trade with special fiscal territories
    C1 = 'C1', // Export Simplified declaration
    C2 = 'C2', // Presentation of goods to Customs in case of entry in the declarant’s records or in the context of Customs declarations lodged prior to the presentation of the goods at export
}

export const irelandExportMessageTypeLabels = [
    { key: IrelandExportMessageType.B1, value: '(B1) Export declaration and re-export declaration' },
    {
        key: IrelandExportMessageType.B2,
        value: '(B2) Special procedure - processing - Declaration for outward processing',
    },
    { key: IrelandExportMessageType.B3, value: '(B3) Declaration for Customs warehousing of Union goods' },
    {
        key: IrelandExportMessageType.B4,
        value: '(B4) Declaration for dispatch of goods in the context of trade with special fiscal territories',
    },
    { key: IrelandExportMessageType.C1, value: '(C1) Export Simplified declaration' },
    {
        key: IrelandExportMessageType.C2,
        value: "(C2) Presentation of goods to Customs in case of entry in the declarant's records or in the context of Customs declarations lodged prior to the presentation of the goods at export",
    },
] as const;
