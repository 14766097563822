import moment from 'moment';
import { EDocket } from './eDocketTypes';

export const eDocketDataAfterFirstWeight: EDocket = {
    dateGenerated: moment().toISOString(),
    driver: {
        trailerIdNumber: '12',
        vehicleRegisterNumber: '12',
    },
    firstWeight: {
        date: moment().toISOString(),
        goodsInOut: 'Some goods',
        image: 'image',
        time: moment().format('HH:mm'),
        units: 'units',
        weight: '100',
    },
    haulier: 'String',
    id: 'yes123',
    product: {
        accountNumber: 'yes11231',
        code: 'asdf',
        description: 'asdf',
        from: 'some person',
    },
    receiver: 'receiver',
    trader: 'trader',
};

export const eDocketDataAfterSecondWeight: EDocket = {
    ...eDocketDataAfterFirstWeight,
    secondWeight: {
        date: moment().toISOString(),
        goodsInOut: 'Some goods 2',
        image: 'image',
        time: moment().format('HH:mm'),
        units: 'units',
        weight: '1002',
    },
};

export const eDocketDataAfterAfterWeight: EDocket = {
    ...eDocketDataAfterSecondWeight,
    afterWeight: {
        elapsedTime: '01:20',
        netWeight: '12',
        units: 'asdf',
        comments: 'Some comment',
    },
};

export const eDocketDataAfterOperatorConfirmation: EDocket = {
    ...eDocketDataAfterAfterWeight,
    signatures: {
        operator: 'Operator Operatrov',
        driver: null,
    },
};

export const eDocketDataAfterDriverSignature: EDocket = {
    ...eDocketDataAfterAfterWeight,
    signatures: {
        operator: 'Operator Operatrov',
        driver: 'Driver Driverov',
    },
};
