import { FC } from 'react';
import { BreadcrumbLastItem, BreadcrumbItem, StyledBreadcrumb } from './Breadcrum.styles';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

export interface CustomRoute {
    breadcrumbName: string;
    path: string;
}

interface Props {
    routes: CustomRoute[];
}

const Breadcrumb: FC<Props> = ({ routes }) => {
    const itemRender = (route: Route, param: any, routes: any, paths: any) => {
        const last = routes.indexOf(route) === routes.length - 1;

        if (last) {
            return <BreadcrumbLastItem>{route.breadcrumbName}</BreadcrumbLastItem>;
        }

        const hasRoute = route.path !== '';

        return hasRoute ? (
            <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
        ) : (
            <BreadcrumbItem>{route.breadcrumbName}</BreadcrumbItem>
        );
    };

    return <StyledBreadcrumb itemRender={itemRender} routes={routes}></StyledBreadcrumb>;
};
export default Breadcrumb;
