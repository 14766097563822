import {
    LoadingOutlined
} from '@ant-design/icons';
import { Alert as AntAlert } from 'antd';
import Modal from 'components/ui/base/modal/Modal';
import { Text, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const PreviewSadText = styled(TextSmall)`
    color: ${colors.darkPurple};
    display: block;
    margin-bottom: 1.8rem;
`;

export const TotalInformationTitle = styled(Text)`
    color: ${colors.darkPurple};
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
`;

export const TotalInformationLabel = styled.span`
    color: ${colors.darkPurple};
    margin-top: 2.2rem;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
`;

export const TotalInformationSection = styled.div`
    margin-top: 2.2rem;
`;

export const TotalInformationParam = styled.div`
    margin-top: 0.8rem;
`;

export const HyperLinkDiv = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const ErrorGenerateDocument = styled.div`
     color: ${colors.lightRed}
`;


export const CustomModal = styled(Modal)`
    .ant-modal-body {
        max-height: 70.5rem;
        overflow-y: auto;
    }
`;

export const Alert = styled(AntAlert)`
    margin-top: 1.5rem;
`;

export const LoadingDocuments = styled(LoadingOutlined)`
    font-size: 2.5rem;
    margin-left: 1rem;
`;