import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import { ValidationSchema, validators } from '../validations/validations';
import HideComponent from '../../../../../utils/HideComponent';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'id';

export const countryOfDispatchExportCodeBlockRequiredValidation: {
    childValidators: {
        'goodsShipment.exportCountry': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.exportCountry': {
            childValidators: {
                id: [validators.required(), validators.exact(2)],
            },
        },
    },
};

export const countryOfDispatchExportCodeBlockOptionalValidation: {
    childValidators: {
        'goodsShipment.exportCountry': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.exportCountry': {
            childValidators: {
                id: [validators.exact(2)],
            },
        },
    },
};

export const countryOfDispatchExportCodeItemBlockValidation: {
    childValidators: {
        exportCountry: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        exportCountry: {
            childValidators: {
                id: [validators.exact(2)],
            },
        },
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const CountryOfDispatchExportCodeBlock = ({
    path = 'goodsShipment.exportCountry',
    hide,
    declarationOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '5/14', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <CountrySelect
                        refNumber={ucc}
                        required={declarationOptions?.[formType]?.fieldOptions?.id?.required}
                        label={t('countryOfDispatch/exportCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CountryOfDispatchExportCodeBlock;
