import { Divider } from 'antd';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useJobs from 'hooks/useJobs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { JobResponse } from 'store/jobs/job';
import Button from '../../../components/ui/base/button';
import { H5 } from '../../../components/ui/base/typography';
import useSession from '../../../hooks/useSession';
import CreateNewDeclarationCard from '../../jobs/components/CreateNewDeclarationCard';
import AddTeamMember from './AddTeamMember';
import DashboardSearchBar from './DashboardSearchBar';
import Container from 'components/ui/base/container';
import { SpaceBetween } from '../../../components/styles/layout.styles';
import JobsTable from '../../jobs/components/tables/JobsTable';

const Dashboard: FC = () => {
    const navigate = useNavigate();
    const { isAdminUser } = useSession();
    const { t } = useTranslation('dashboard');
    const { createJob } = useJobs();
    const { setBreadcrumbRoutes } = useBreadcrumb();

    const [createUserDrawerOpen, setCreateUserDrawerOpen] = useState<boolean>(false);
    const [createDeclarationModalVisible, setCreateDeclarationModalVisible] = useState<boolean>(false);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Home',
                path: '',
            },
            {
                breadcrumbName: 'Dashboard',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewJob = () => {
        createJob().then((job: JobResponse) => navigate(`/jobs/${job.id}`, { state: { job } }));
    };

    return (
        <Container>
            <SpaceBetween>
                <H5>{t('dashboardTitle')}</H5>
                {isAdminUser && (
                    <Button
                        style={{ width: 200 }}
                        size="large"
                        type="primary"
                        onClick={() => setCreateUserDrawerOpen(true)}
                    >
                        {t('btAddTeamClerk')}
                    </Button>
                )}
            </SpaceBetween>

            <SpaceBetween style={{ marginTop: '2rem' }}>
                <H5>{t('subTitle')}</H5>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button style={{ width: 200 }} size="large" type="primary" onClick={createNewJob}>
                        {t('btCreateNewJob')}
                    </Button>
                    <Button
                        style={{ width: 200 }}
                        size="large"
                        type="primary"
                        onClick={() => setCreateDeclarationModalVisible(true)}
                    >
                        {t('btCreateDeclarations')}
                    </Button>
                </div>
            </SpaceBetween>

            <Divider />

            <DashboardSearchBar />

            <CreateNewDeclarationCard
                isActive={createDeclarationModalVisible}
                handleCancel={() => setCreateDeclarationModalVisible(false)}
            />
            <AddTeamMember visible={createUserDrawerOpen} closeDrawer={() => setCreateUserDrawerOpen(false)} />

            <JobsTable />
        </Container>
    );
};
export default Dashboard;
