import { listExchange } from "./client";

export const doListExchanges: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_EXCHANGE' });
    try {
        const payload = await listExchange();
        dispatch({
            type: 'LIST_EXCHANGE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_EXCHANGE_ERROR', error: e?.response?.data });
    }
};