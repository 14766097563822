import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons/lib/icons';
import { Col, Row, Upload } from 'antd';
import Button from 'components/ui/base/button/Button';
import Modal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import { ErrorLabel } from 'components/ui/composed/formInput/FormInput.styles';
import FormPassword from 'components/ui/composed/formPassword/FormPassword';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { setNestedObjectValues, useFormik } from 'formik';
import useDigitalCertificates from 'hooks/useDigitalCertificates';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    digitalCertificateType,
    UploadDigitalCertificateRequest,
} from 'store/digital-certificates/digital-certificates';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import { Filename, StyledFilePdfOutlined, UploadDiv } from './DigitalCertificates.styles';

interface Props {
    visible: boolean;
    handleCancel: () => void;
    handleOk: () => void;
}

const schema = () =>
    Yup.object().shape({
        type: Yup.string().required(getRequiredMessage('certificateType')),
        password: Yup.string().required(getRequiredMessage('password')),
        file: Yup.mixed().required('No file selected'),
    });

const AddDigitalCertificate: FC<Props> = ({ visible, handleCancel, handleOk }) => {
    const { t } = useTranslation('settings');
    const { uploadDigitalCertificate, error, isLoading } = useDigitalCertificates();
    const [uploadedClicked, setUploadedClicked] = useState<boolean>(false);

    const {
        isValid,
        getFieldProps,
        getFieldHelpers,
        resetForm,
        getFieldMeta,
        handleSubmit,
        validateForm,
        setTouched,
        setFieldValue,
    } = useFormik<Partial<UploadDigitalCertificateRequest>>({
        initialValues: {},
        validateOnMount: true,
        validateOnBlur: true,
        enableReinitialize: true,
        validationSchema: schema(),
        onSubmit: (values) => uploadCertificates(values),
    });

    useEffect(() => {
        if (uploadedClicked && !isLoading) {
            if (!error) {
                handleOk();
                resetForm();
                Notification({
                    type: 'success',
                    messageTitle: t('digitalCertificate.uploadSuccessTitle'),
                    description: t('digitalCertificate.uploadSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('digitalCertificate.uploadErrorTitle'),
                    description: t('digitalCertificate.uploadErrorMessage'),
                });
            }
            setUploadedClicked(false);
        }
    }, [uploadedClicked, isLoading, error, handleOk, t, resetForm]);

    const fileError = useMemo(() => !!getFieldMeta('file').touched && !!getFieldMeta('file').error, [getFieldMeta]);

    const uploadCertificates = async (values: Partial<UploadDigitalCertificateRequest>) => {
        await uploadDigitalCertificate(values as UploadDigitalCertificateRequest);
        setUploadedClicked(true);
    };

    const cancel = () => {
        resetForm();
        handleCancel();
    };

    const validate = async () => {
        await validateForm().then((v) => {
            setTouched(setNestedObjectValues(v, true));
            return v;
        });
    };
    const handleConfirm = async () => {
        return isValid ? handleSubmit() : await validate();
    };

    const handleFile = useMemo(() => {
        const getFile = async (options: any) => {
            const { file } = options;
            setFieldValue('file', file);
        };

        const file = getFieldProps('file').value;
        if (file) {
            return (
                <>
                    <Row align="middle">
                        <Col>
                            <Filename>
                                <StyledFilePdfOutlined /> {file?.name}
                            </Filename>
                        </Col>
                        <ReverseCol>
                            <Button
                                size="small"
                                onClick={() => {
                                    setFieldValue('file', undefined);
                                }}
                            >
                                {t('form.delete')} <DeleteOutlined />
                            </Button>
                        </ReverseCol>
                    </Row>
                </>
            );
        } else {
            return (
                <>
                    <Upload maxCount={1} customRequest={getFile} showUploadList={false}>
                        <Button size="large" type="primary">
                            {t('digitalCertificate.upload')} <FileAddOutlined />
                        </Button>
                    </Upload>
                    {fileError && <ErrorLabel>{getFieldMeta('file').error}</ErrorLabel>}
                </>
            );
        }
    }, [setFieldValue, getFieldMeta, fileError, getFieldProps, t]);

    return (
        <>
            <Modal
                title={<H5>{t('digitalCertificate.addCertificate')}</H5>}
                centered
                visible={visible}
                onCancel={cancel}
                onOk={handleConfirm}
                width={670}
                confirmText={t('form.confirm')}
                cancelText={t('form.cancel')}
            >
                <FormSelect
                    hideKeys
                    label={t('digitalCertificate.labels.type')}
                    fieldProps={getFieldProps('type')}
                    fieldHelper={getFieldHelpers('type')}
                    fieldMeta={getFieldMeta('type')}
                    selectOptions={digitalCertificateType}
                />

                <FormPassword
                    icon
                    label={t('digitalCertificate.labels.password')}
                    fieldProps={getFieldProps('password')}
                    fieldMeta={getFieldMeta('password')}
                />

                <UploadDiv>{handleFile}</UploadDiv>
            </Modal>
        </>
    );
};

export default AddDigitalCertificate;
