import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import DeclarationCheckbox from 'components/ui/composed/declarations/formCheckbox/DeclarationCheckbox';
import { validators } from 'views/declarations/uk/export/validations/validations';

const ItemValueDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('Value Details')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`itemAmount`, props)}
                    refNumber="4.14"
                    label={t('itemAmount')}
                    refTooltip={getH1TooltipsByRefNumberAndField('4.14', t('itemAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`valuationMethod`, props)}
                    refNumber="4.16"
                    label={t('valuationMethod')}
                    selectOptions={aisCodelists?.valuationMethod}
                    tooltip={getH1TooltipsByRefNumberAndField('4.16', t('valuationMethod'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />
                <DeclarationCheckbox
                    viewOnly={props.viewOnly}
                    {...getFormikProps('valuationIndicator1', props)}
                    refNumber="4.13"
                    label={t('valuationIndicator') + ' 1'}
                    tooltip={getH1TooltipsByRefNumberAndField('4.13', t('valuationIndicator'))}
                    condensed
                />
                <DeclarationCheckbox
                    viewOnly={props.viewOnly}
                    {...getFormikProps('valuationIndicator2', props)}
                    refNumber="4.13"
                    label={t('valuationIndicator') + ' 2'}
                    tooltip={getH1TooltipsByRefNumberAndField('4.13', t('valuationIndicator'))}
                    condensed
                />
                <DeclarationCheckbox
                    viewOnly={props.viewOnly}
                    {...getFormikProps('valuationIndicator3', props)}
                    refNumber="4.13"
                    label={t('valuationIndicator') + ' 3'}
                    tooltip={getH1TooltipsByRefNumberAndField('4.13', t('valuationIndicator'))}
                    condensed
                />
                <DeclarationCheckbox
                    viewOnly={props.viewOnly}
                    {...getFormikProps('valuationIndicator4', props)}
                    refNumber="4.13"
                    label={t('valuationIndicator') + ' 4'}
                    tooltip={getH1TooltipsByRefNumberAndField('4.13', t('valuationIndicator'))}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ItemValueDetailsCard;
