import Button from 'components/ui/base/button';
import useLoadingButton from 'hooks/useLoadingButton';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    edit: boolean;
    onSave: () => void;
    onCancel: () => void;
    onEdit: () => void;
}

const FormFooter: FC<Props> = ({ edit, onSave, onCancel, onEdit }) => {
    const { t } = useTranslation('settings');
    const { loading, handleOnPressButton } = useLoadingButton({ onSubmit: onSave });
    const footerView = useMemo(() => {
        if (edit) {
            return (
                <>
                    <Button
                        style={{ marginRight: '0.8rem', minWidth: '108px' }}
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        style={{ minWidth: '108px' }}
                        type="primary"
                        onClick={() => {
                            handleOnPressButton();
                        }}
                        loading={loading}
                    >
                        {t('form.save')}
                    </Button>
                </>
            );
        } else {
            return (
                <Button
                    style={{ minWidth: '108px' }}
                    onClick={() => {
                        onEdit();
                    }}
                >
                    {t('form.edit')}
                </Button>
            );
        }
    }, [edit, onCancel, onEdit, t, handleOnPressButton, loading]);

    return footerView;
};
export default FormFooter;
