import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import useCodelists from '../../../../../../hooks/useCodelists';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const DeliveryTermsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { aisCodelists } = useCodelists();

    return (
        <NewFormCard title={t('Delivery terms')}>
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`incotermCode`, props)}
                    refNumber="4.1"
                    label={t('goodsShipment.incotermCode')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.incotermCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={aisCodelists?.incotermCode}
                    condensed
                    codeValidation={[validators.exact(3)]}
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryCode`, props)}
                    label={t('goodsShipment.countryCode')}
                    refNumber="4.1"
                    tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.countryCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`place`, props)}
                    numbersAndLetters
                    refNumber="4.1"
                    label={t('goodsShipment.locationName')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.locationName'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default DeliveryTermsCard;
