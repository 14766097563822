import { useAppDispatch, useAppSelector } from "config/hooks";
import { useCallback } from "react";
import { doCreateRoute, doDeleteRoute, doEditRoute, doGetRoute } from "store/journeys/routes/action";
import { Route } from "store/journeys/routes/route";

interface UseRoutesProps {
    routeId?: string;
}

const useRoutes  = ({ routeId }: UseRoutesProps={}) => {
    const route = useAppSelector((state) => (routeId ? state.routes.entities[routeId] : ({} as Route)));
    const isLoading = useAppSelector((state) => state.routes.isLoading);
    const error = useAppSelector((state) => state.routes.error);

    const dispatch = useAppDispatch();

    const createRoute = useCallback((route: Route) => {
       return dispatch(doCreateRoute(route));
    },[dispatch]);

    const editRoute = useCallback((route: Route) => {
        return dispatch(doEditRoute(route))
    },[dispatch]);
    
    const getRoute = useCallback((routeID: String) => {
        return dispatch(doGetRoute(routeID));
    },[dispatch]);

    const deleteRoute = useCallback((routeID: String) => {
        return dispatch(doDeleteRoute(routeID))
    },[dispatch]);

    return{
        route,
        createRoute,
        editRoute,
        getRoute,
        deleteRoute,
        isLoading,
        error
    }

}
export default useRoutes;