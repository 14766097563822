import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const BoxNumber = styled.div`
    display: flex;
    justify-content: center;
    background: ${colors.darkGrey};
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: ${fonts.weight.semibold};
    color: ${colors.white};
    width: 4.1rem;
    border-radius: 0.4rem;
    align-items: center;
`;
