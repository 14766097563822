import { getRequiredMessage } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const editTemplateNameValidation = (templateNames?: Set<string> | null) =>
    Yup.object().shape({
        templateName: Yup.string()
            .required(getRequiredMessage('field'))
            .nullable()
            .test({
                name: 'unique-name',
                message: 'This name already exists',
                test: (value, context) => {
                    if (value == null) return true;
                    return !templateNames?.has(value);
                },
            }),
    });

export default editTemplateNameValidation;
