import { useDispatch } from 'react-redux';
import { useAppSelector } from '../config/hooks';
import { NotificationType } from '../components/ui/base/notification/Notification';
import { doSetNotification, doClearNotification } from '../store/notification/action';

const useNotification = () => {
    const dispatch = useDispatch();
    const notification = useAppSelector((state) => state.notification);

    const clearFeedback = () => {
        if (!notification?.messageTitle) return;
        dispatch(doClearNotification());
    };

    const setSuccessNotification = (title: string, message: string) =>
        message &&
        dispatch(doSetNotification({ type: 'success' as NotificationType, messageTitle: title, description: message }));

    const setErrorNotification = async (title: string, message: string, key?: string) => {
        if (message && (notification.messageTitle !== title || notification.description !== message)) {
            message &&
                dispatch(
                    doSetNotification({
                        type: 'error' as NotificationType,
                        messageTitle: title,
                        description: message,
                        key,
                    })
                );
        }
    };

    return {
        notification,
        setSuccessNotification,
        setErrorNotification,
        clearFeedback,
    };
};

export default useNotification;
