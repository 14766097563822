import { NotifyEmails } from 'store/declarations/declaration';
import {
    amendIrelandEntrySummaryDeclaration,
    amendIrelandExportDeclaration,
    amendIrelandImportDeclaration,
    createIrelandArrivalAtExitDeclaration,
    createIrelandENSDeclaration,
    createIrelandEtdDeclaration,
    createIrelandExportDeclaration,
    createIrelandH7ImportDeclaration,
    createIrelandImportDeclaration,
    createUkExportDeclaration,
    createIrelandTSDDeclaration,
    duplicateDeclaration,
    getDeclaration,
    getDeclarationDocuments,
    getDeclarationHistory,
    invalidateDeclaration,
    invalidateExportDeclaration,
    submitIrelandENSDeclaration,
    listDeclarations,
    submitDeclaration,
    submitIrelandImportDocuments,
    submitIrelandTSDDeclaration,
    submitImportPresentationNotification,
    updateCoreTemplateStatus,
    updateIrelandArrivalAtExitDeclaration,
    updateIrelandENSDeclaration,
    updateIrelandEtdDeclaration,
    updateIrelandExportDeclaration,
    updateIrelandH7ImportDeclaration,
    updateIrelandImportDeclaration,
    updateIrelandTSDDeclaration,
    updateUkExportDeclaration,
    createIrelandH1ImportDeclarationWithFile,
    getTemplate,
    cancelCdsDeclaration,
    listArchivedDeclarations,
    cancelAmendmentIrelandExportDeclaration,
} from './client';
import { DeclarationDocumentsFilterFields, DeclarationFilterFields } from './declaration';
import { ArrivalAtExitDeclaration } from './ireland/arrival-at-exit-declaration';
import {
    ElectronicTransportDocument,
    ElectronicTransportDocumentPayload,
} from './ireland/electronic-transport-document';
import { IrelandEntrySummaryDeclarationPayload } from './ireland/entry-summary-declaration';
import { IrelandExportDeclarationPayload } from './ireland/export-declaration';
import { IrelandH7ImportDeclarationPayload } from './ireland/h7-import-declaration';
import { IrelandImportDeclarationPayload } from './ireland/import-declaration';
import { IrelandTemporaryStorageDeclarationPayload } from './ireland/temporary-storage-declaration';
import { CdsExportDeclarationPayload } from './uk/export-declaration';

export const doListDeclarations: Function =
    (params: Partial<DeclarationFilterFields>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_DECLARATIONS_REQUEST' });

        try {
            const payload = await listDeclarations(params);
            dispatch({
                type: 'LIST_DECLARATIONS_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_DECLARATIONS_ERROR', error: e?.response?.data });
        }
    };

export const doListArchivedDeclarations: Function =
    (params: Partial<DeclarationFilterFields>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_DECLARATIONS_REQUEST' });

        try {
            const payload = await listArchivedDeclarations(params);
            dispatch({
                type: 'LIST_DECLARATIONS_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_DECLARATIONS_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandImportDeclaration: Function =
    (customerId: string, declaration: IrelandImportDeclarationPayload, templateId?: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_REQUEST' });

        try {
            const payload = await createIrelandImportDeclaration(customerId, declaration, templateId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandH1ImportDeclarationWithFile: Function =
    (file: File, jobId: string, customerId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_REQUEST' });

        try {
            const payload = await createIrelandH1ImportDeclarationWithFile(file, jobId, customerId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

// TODO change action types?
export const doCreateIrelandH7ImportDeclaration: Function =
    (customerId: string, declaration: IrelandH7ImportDeclarationPayload, templateId?: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_REQUEST' });

        try {
            const payload = await createIrelandH7ImportDeclaration(customerId, declaration, templateId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandExportDeclaration: Function =
    (customerId: string, declaration: IrelandExportDeclarationPayload, templateId?: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_EXPORT_DECLARATION_REQUEST' });

        try {
            const payload = await createIrelandExportDeclaration(customerId, declaration, templateId);
            dispatch({
                type: 'CREATE_IRELAND_EXPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateUkExportDeclaration: Function =
    (customerId: string, declaration: CdsExportDeclarationPayload, templateId?: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'CREATE_UK_EXPORT_DECLARATION_REQUEST' });

        try {
            const payload = await createUkExportDeclaration(customerId, declaration, templateId);
            dispatch({
                type: 'CREATE_UK_EXPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_UK_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandImportDeclaration: Function =
    (customerId: string, declarationId: string, declaration: IrelandImportDeclarationPayload) =>
    async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_DECLARATION_REQUEST' });

        try {
            const payload = await updateIrelandImportDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

// TODO change action types?
export const doUpdateIrelandH7ImportDeclaration: Function =
    (customerId: string, declarationId: string, declaration: IrelandH7ImportDeclarationPayload) =>
    async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_DECLARATION_REQUEST' });

        try {
            const payload = await updateIrelandH7ImportDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandExportDeclaration: Function =
    (customerId: string, declarationId: string, declaration: IrelandExportDeclarationPayload) =>
    async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_EXPORT_DECLARATION_REQUEST' });

        try {
            const payload = await updateIrelandExportDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'UPDATE_IRELAND_EXPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateUkExportDeclaration: Function =
    (customerId: string, declarationId: string, declaration: CdsExportDeclarationPayload) =>
    async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_UK_EXPORT_DECLARATION_REQUEST' });

        try {
            const payload = await updateUkExportDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'UPDATE_UK_EXPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_UK_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doGetDeclaration: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DECLARATION_REQUEST' });

    try {
        const declaration = await getDeclaration(declarationId);
        const declarationTemplate = declaration.templateId ? await getTemplate(declaration.templateId) : undefined;
        dispatch({
            type: 'GET_DECLARATION_SUCCESS',
            declaration,
            declarationTemplate,
        });
        return declaration;
    } catch (e: any) {
        dispatch({ type: 'GET_DECLARATION_ERROR', error: e.response?.data });
    }
};

export const doGetDeclarationTemplate: Function = (templateId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_TEMPLATE_REQUEST' });

    try {
        const declarationTemplate = await getTemplate(templateId);
        dispatch({
            type: 'GET_TEMPLATE_SUCCESS',
            declarationTemplate,
        });
        return declarationTemplate;
    } catch (e: any) {
        dispatch({ type: 'GET_TEMPLATE_ERROR', error: e.response?.data });
    }
};

export const doSubmitDeclaration: Function =
    (declarationId: string, emails: NotifyEmails) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_DECLARATION_REQUEST' });

        try {
            const payload = await submitDeclaration(declarationId, emails);

            dispatch({
                type: 'SUBMIT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_DECLARATION_ERROR', error: e.response?.data });
        }
    };

export const doGetDeclarationDocuments: Function =
    (params: DeclarationDocumentsFilterFields) => async (dispatch: Function) => {
        dispatch({ type: 'GET_DECLARATION_DOCUMENTS' });

        try {
            const payload = await getDeclarationDocuments(params);
            dispatch({
                type: 'GET_DECLARATION_DOCUMENTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_DECLARATION_DOCUMENTS_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateCoreTemplateStatus: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_REQUEST' });
    try {
        const payload = await updateCoreTemplateStatus(declarationId);
        dispatch({
            type: 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_ERROR', error: e?.response?.data });
    }
};

export const doListDeclarationsFromJobs: Function = (jobId: string) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_DECLARATIONS_FROM_JOB_REQUEST' });
    try {
        const payload = await listDeclarations(jobId);
        dispatch({
            type: 'LIST_DECLARATIONS_FROM_JOB_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_DECLARATIONS_FROM_JOB_ERROR', error: e?.response?.data });
    }
};

export const doCreateTemporaryStorageDeclaration: Function =
    (customerId: string, declaration: IrelandTemporaryStorageDeclarationPayload) => async (dispatch: Function) => {
        dispatch({ type: 'DO_CREATE_TSD_DECLARATION' });
        try {
            const payload = await createIrelandTSDDeclaration(customerId, declaration);
            dispatch({
                type: 'DO_CREATE_TSD_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_CREATE_TSD_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateTemporaryStorageDeclaration: Function =
    (declarationId: string, declaration: IrelandTemporaryStorageDeclarationPayload) => async (dispatch: Function) => {
        dispatch({ type: 'DO_UPDATE_TSD_DECLARATION' });
        try {
            const payload = await updateIrelandTSDDeclaration(declarationId, declaration);
            dispatch({
                type: 'DO_UPDATE_TSD_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_UPDATE_TSD_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitTemporaryStorageDeclaration: Function =
    (declarationId: string, declaration: IrelandTemporaryStorageDeclarationPayload) => async (dispatch: Function) => {
        dispatch({ type: 'DO_SUBMIT_TSD_DECLARATION' });
        try {
            const payload = await submitIrelandTSDDeclaration(declarationId, declaration);
            dispatch({
                type: 'DO_SUBMIT_TSD_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_SUBMIT_TSD_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateEntrySummaryDeclaration: Function =
    (customerId: string, declaration: IrelandEntrySummaryDeclarationPayload, templateId?: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'DO_CREATE_ENS_DECLARATION' });
        try {
            const payload = await createIrelandENSDeclaration(customerId, declaration, templateId);
            dispatch({
                type: 'DO_CREATE_ENS_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_CREATE_ENS_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateEntrySummaryDeclaration: Function =
    (declarationId: string, declaration: IrelandEntrySummaryDeclarationPayload) => async (dispatch: Function) => {
        dispatch({ type: 'DO_UPDATE_ENS_DECLARATION' });
        try {
            const payload = await updateIrelandENSDeclaration(declarationId, declaration);
            dispatch({
                type: 'DO_UPDATE_ENS_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_UPDATE_ENS_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitEntrySummaryDeclaration: Function =
    (declarationId: string, declaration: IrelandEntrySummaryDeclarationPayload) => async (dispatch: Function) => {
        dispatch({ type: 'DO_SUBMIT_ENS_DECLARATION' });
        try {
            const payload = await submitIrelandENSDeclaration(declarationId, declaration);
            dispatch({
                type: 'DO_SUBMIT_ENS_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_SUBMIT_ENS_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doDuplicateDeclaration: Function =
    (declarationId: string, customerId: string, jobId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DUPLICATE_DECLARATION_REQUEST' });
        try {
            const payload = await duplicateDeclaration(declarationId, customerId, jobId);
            dispatch({
                type: 'DUPLICATE_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DUPLICATE_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doSetInputsFocused: Function = (focused: boolean) => (dispatch: Function) => {
    dispatch({
        type: 'SET_INPUT_FOCUSED',
        payload: focused,
    });
};

export const doInvalidateDeclaration: Function =
    (declarationId: string, reason: string) => async (dispatch: Function) => {
        dispatch({ type: 'INVALIDATE_DECLARATION_REQUEST' });
        try {
            const payload = await invalidateDeclaration(declarationId, reason);
            dispatch({
                type: 'INVALIDATE_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'INVALIDATE_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doInvalidateExportDeclaration: Function =
    (declarationId: string, reason: string) => async (dispatch: Function) => {
        dispatch({ type: 'INVALIDATE_EXPORT_DECLARATION_REQUEST' });
        try {
            const payload = await invalidateExportDeclaration(declarationId, reason);
            dispatch({
                type: 'INVALIDATE_EXPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'INVALIDATE_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitImportPresentationNotification: Function =
    (declarationId: string, type?: string, body?: {}) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_PRESENTATION_NOTIFICATION_REQUEST' });
        try {
            const payload = await submitImportPresentationNotification(declarationId, type, body);
            dispatch({
                type: 'SUBMIT_PRESENTATION_NOTIFICATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_PRESENTATION_NOTIFICATION_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitIrelandImportDocuments: Function =
    (declarationId: string, documentIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_REQUEST' });
        try {
            const payload = await submitIrelandImportDocuments(declarationId, documentIds);
            dispatch({
                type: 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_ERROR', error: e?.response?.data });
        }
    };

export const doClearDeclarationList: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'CLEAR_DECLARATION_LIST' });
};

export const doGetDeclarationHistory: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DECLARATION_HISTORY_REQUEST' });
    try {
        const payload = await getDeclarationHistory(declarationId);
        dispatch({
            type: 'GET_DECLARATION_HISTORY_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_DECLARATION_HISTORY_ERROR', error: e?.response?.data });
    }
};

export const doCreateAmendmentIrelandImportDeclaration: Function =
    (declarationId: string, detailsAmended: string) => async (dispatch: Function) => {
        dispatch({ type: 'AMENDMENT_IRELAND_IMPORT_DECLARATION_REQUEST' });
        try {
            const payload = await amendIrelandImportDeclaration(declarationId, detailsAmended);
            dispatch({
                type: 'AMENDMENT_IRELAND_IMPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'AMENDMENT_IRELAND_IMPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateAmendmentIrelandExportDeclaration: Function =
    (declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'AMENDMENT_IRELAND_EXPORT_DECLARATION_REQUEST' });
        try {
            const payload = await amendIrelandExportDeclaration(declarationId);
            dispatch({
                type: 'AMENDMENT_IRELAND_EXPORT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'AMENDMENT_IRELAND_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCancelAmendmentIrelandExportDeclaration: Function =
    (declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_EXPORT_DECLARATION_REQUEST' });

        try {
            const payload = await cancelAmendmentIrelandExportDeclaration(declarationId);
            dispatch({
                type: 'CREATE_IRELAND_EXPORT_DECLARATION_SUCCESS',
                payload,
            });

            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_EXPORT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateAmendmentIrelandEntrySummaryDeclaration: Function =
    (declarationId: string, detailsAmended: string) => async (dispatch: Function) => {
        dispatch({ type: 'AMENDMENT_IRELAND_ENS_DECLARATION_REQUEST' });
        try {
            const payload = await amendIrelandEntrySummaryDeclaration(declarationId, detailsAmended);
            dispatch({
                type: 'AMENDMENT_IRELAND_ENS_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'AMENDMENT_IRELAND_ENS_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateElectronicTransportDocument: Function =
    (customerId: string, declaration: ElectronicTransportDocument) => async (dispatch: Function) => {
        dispatch({ type: 'DO_CREATE_ETD_DECLARATION' });
        try {
            const payload = await createIrelandEtdDeclaration(customerId, declaration);
            dispatch({
                type: 'DO_CREATE_ETD_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_CREATE_ETD_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateElectronicTransportDocument: Function =
    (customerId: string, declarationId: string, declaration: ElectronicTransportDocumentPayload) =>
    async (dispatch: Function) => {
        dispatch({ type: 'DO_UPDATE_ETD_DECLARATION' });
        try {
            const payload = await updateIrelandEtdDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'DO_UPDATE_ETD_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_UPDATE_ETD_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCreateArrivalAtExitDeclaration: Function =
    (customerId: string, declaration: ArrivalAtExitDeclaration) => async (dispatch: Function) => {
        dispatch({ type: 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION' });
        try {
            const payload = await createIrelandArrivalAtExitDeclaration(customerId, declaration);
            dispatch({
                type: 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateArrivalAtExitDeclaration: Function =
    (customerId: string, declarationId: string, declaration: ArrivalAtExitDeclaration) =>
    async (dispatch: Function) => {
        dispatch({ type: 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION' });
        try {
            const payload = await updateIrelandArrivalAtExitDeclaration(customerId, declarationId, declaration);
            dispatch({
                type: 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION_ERROR', error: e?.response?.data });
        }
    };

export const doCancelCdsDeclaration: Function =
    (type: 'import' | 'export', declarationId: string, reason: string) => async (dispatch: Function) => {
        dispatch({ type: 'CANCEL_DECLARATION_REQUEST' });
        try {
            const payload = await cancelCdsDeclaration(type, declarationId, reason);
            dispatch({
                type: 'CANCEL_DECLARATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CANCEL_DECLARATION_ERROR', error: e?.response?.data });
        }
    };
