import { FC, useMemo } from 'react';
import { Alert } from './AlertInvalidationRequested.style';

interface Props {
    systemInvalidationRequested?: boolean;
    external: 'Revenue' | 'CDS' | 'CHIEF';
}

const ExternalSystemInvalidationRequested: FC<Props> = ({ systemInvalidationRequested = false, external }) => {
    const message = useMemo(() => {
        return (
            systemInvalidationRequested && (
                <Alert
                    message={`This declaration has been requested to be invalidated by ${external}`}
                    type="warning"
                    showIcon
                />
            )
        );
    }, [systemInvalidationRequested, external]);

    return <>{message}</>;
};
export default ExternalSystemInvalidationRequested;
