import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import AddressForm from 'views/declarations/common/AddressForm';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';

const CarrierCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const fields = props.getFields ? props.getFields() : [];

    return (
        <section
            id="arrival-at-exit-carrier-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('arrival-at-exit-carrier-form-card');
                }
            }}
        >
            <FormCard
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('carrier')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                state={getCardState(fields, props)}
            >
                <>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <FormInput
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`name`, props)}
                                refNumber="3.1"
                                label={t('name')}
                                tooltip={getH1TooltipsByRefNumberAndField('3.1', t('name'))}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                    </Row>
                    <AddressForm
                        declarationType={DeclarationName.ARRIVAL}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        viewOnly={props.viewOnly}
                        fieldProps={{
                            streetAndNumber: { ...getFormikProps(`streetAndNumber`, props).fieldProps },
                            country: { ...getFormikProps(`country`, props).fieldProps },
                            postCode: { ...getFormikProps(`postCode`, props).fieldProps },
                            city: { ...getFormikProps(`city`, props).fieldProps },
                        }}
                        fieldMeta={
                            props.getFieldMeta && {
                                streetAndNumber: { ...getFormikProps(`streetAndNumber`, props).fieldMeta! },
                                country: { ...getFormikProps(`country`, props).fieldMeta! },
                                postCode: { ...getFormikProps(`postCode`, props).fieldMeta! },
                                city: { ...getFormikProps(`city`, props).fieldMeta! },
                            }
                        }
                        fieldHelper={{
                            streetAndNumber: { ...getFormikProps(`streetAndNumber`, props).fieldHelper },
                            country: { ...getFormikProps(`country`, props).fieldHelper },
                            postCode: { ...getFormikProps(`postCode`, props).fieldHelper },
                            city: { ...getFormikProps(`city`, props).fieldHelper },
                        }}
                        refNumbers={{ streetAndNumber: '3.1', city: '3.1', country: '3.1', postcode: '3.1' }}
                    />
                </>
            </FormCard>
        </section>
    );
};
export default CarrierCard;
