import { H1, H3 } from 'components/ui/base/typography';
import Button from 'components/ui/base/button';
import styled, { StyledComponent } from 'styled-components';
import { colors } from 'theme';
import { BaseButtonType } from 'components/ui/base/button/Button';

interface WCOInitialComponents {
    Main: StyledComponent<'div', any>;
    TitleTextContainer: StyledComponent<'div', any>;
    Title: StyledComponent<'h1', any>;
    Text: StyledComponent<'h3', any>;
    Button: StyledComponent<BaseButtonType, any>;
}

const WCOInitialStyledComponents: WCOInitialComponents = {
    Main: styled.div`
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        align-items: center;
    `,
    TitleTextContainer: styled.div`
        display: flex;
        flex-direction: column;
        text-align: center;

        margin-top: 2rem;
        margin-bottom: 8rem;
    `,
    Title: styled(H1)`
        display: block;
        color: ${colors.darkPurple};
    `,
    Text: styled(H3)`
        margin-top: 8rem;
        color: ${colors.darkPurple};
    `,
    Button: styled(Button)`
        margin-top: 1px;
    `,
};

export default WCOInitialStyledComponents;
