import { Layout } from 'antd';
import LogoSmall from 'components/icons/LogoSmall';
import styled from 'styled-components';
import { colors } from 'theme';

export const StyledMobileHeader = styled(Layout.Header)`
    background-color: ${colors.white};
    filter: drop-shadow(0px 0px 24px rgba(9, 0, 255, 0.07));
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const UserDiv = styled.div`
    display: flex;
    align-items: center;
    font-size: 2.4rem;
`;

export const StyledLogoSmall = styled(LogoSmall)`
    svg {
        background-color: ${colors.darkPurple};
    }
`;
