import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Cargo, CargoParams } from './cargo';

export const listCargos = (params?: Partial<CargoParams>): Promise<ListPayload<Cargo>> =>
    axiosClient
        .get<ListResponse<Cargo>>(`${config.cargoUrl}/cargo`, { params })
        .then((response) => response.data.payload);

export const getCargo = (cargoID: string): Promise<Cargo> =>
    axiosClient
        .get<SuccessResponse<Cargo>>(`${config.cargoUrl}/cargo/${cargoID}`)
        .then((response) => response.data.payload);

export const postCargo = (cargo: Cargo): Promise<Cargo> =>
    axiosClient
        .post<SuccessResponse<Cargo>>(`${config.cargoUrl}/cargo`, cargo)
        .then((response) => response.data.payload);

export const putCargo = (cargo: Cargo): Promise<Cargo> =>
    axiosClient
        .put<SuccessResponse<Cargo>>(`${config.cargoUrl}/cargo/${cargo.id}`, cargo)
        .then((response) => response.data.payload);

export const deleteCargo = (cargoID: string): Promise<Cargo> =>
    axiosClient
        .delete<SuccessResponse<Cargo>>(`${config.cargoUrl}/cargo/${cargoID}`)
        .then((response) => response.data.payload);
