import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedField, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { IrelandEntrySummaryDeclaration } from 'store/declarations/ireland/entry-summary-declaration';
import { parseAnyValidationError } from 'utils/validationParserUtils';
import { addToParties } from '../../import/h1/validation/utils';

const parseValidationErrors = (validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const masterDetailsErrors: ParsedCard[] = [];
    parseMasterDetails(masterDetailsErrors, validation);
    parsePersonLodging(masterDetailsErrors, validation);
    parseEntryCarrier(masterDetailsErrors, validation);
    parseConsignor(masterDetailsErrors, validation);
    parseConsignee(masterDetailsErrors, validation);
    parseNotifyParty(masterDetailsErrors, validation);
    parseRepresentative(masterDetailsErrors, validation);
    parseGoodsShipment(masterDetailsErrors, validation);
    parseFirstEntry(masterDetailsErrors, validation);
    parseSubsequentCustomsOffice(masterDetailsErrors, validation);
    parseTransportInformation(masterDetailsErrors, validation);
    parseConsignment(masterDetailsErrors, validation);
    parseSeals(masterDetailsErrors, validation);

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationCustomerErrors = (
    customer: FormikErrors<DeclarationCustomer> | undefined,
    customerName: string
) => {
    if (!customer) return [];

    return parseAnyValidationError(
        [{ eori: 'tin' }, 'name', 'streetAndNumber', 'city', 'postCode', 'country'],
        customer,
        customerName
    );
};

const parseMasterDetails = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(
        ['totalGrossMass', 'numberOfItems', 'totalNumberOfPackages', 'issuing.place'],
        validation
    );
    if (fields.length > 0) {
        errors.push({ name: 'Master Details', fields });
    }
};

const parsePersonLodging = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(get(validation, 'agent') as any, 'Declarant');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseEntryCarrier = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(get(validation, 'carrier') as any, 'Entry Carrier');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseConsignor = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(
        get(validation, 'goodsShipment.consignor'),
        'Consignor'
    );
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseConsignee = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(
        get(validation, 'goodsShipment.consignee'),
        'Consignee'
    );
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseNotifyParty = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(
        get(validation, 'goodsShipment.notifyParty'),
        'Notify Party'
    );
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseRepresentative = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields: ParsedField[] = parseDeclarationCustomerErrors(
        get(validation, 'personLodgingTheSummaryDeclaration') as any,
        'Representative'
    );
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseGoodsShipment = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(['goodsShipment.regionDestination'], validation);
    if (fields.length > 0) {
        errors.push({ name: 'Goods shipment', fields });
    }
};

const parseFirstEntry = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(
        [
            'goodsShipment.firstEntryCustomsOffice.referenceNumber',
            { 'goodsShipment.firstEntryCustomsOffice.expectedDateTimeArrival': 'expectedTimeOfArrival' },
        ],
        validation
    );
    if (fields.length > 0) {
        errors.push({ name: 'CUSTOMS OFFICE of first Entry', fields });
    }
};

const parseSubsequentCustomsOffice = (
    errors: ParsedCard[],
    validation: FormikErrors<IrelandEntrySummaryDeclaration>
) => {
    get(validation, 'goodsShipment.entryCustomsOffice')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['referenceNumber'], val);

        if (fields.length > 0) {
            errors.push({ name: `CUSTOMS OFFICE of subsequent Entry ${index + 1}`, fields });
        }
    });
};

const parseTransportInformation = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(
        [
            'goodsShipment.consignment.borderTransportMeans.identityCrossingBorder',
            'goodsShipment.consignment.borderTransportMeans.nationalityCrossingBorder',
            'goodsShipment.consignment.borderTransportMeans.conveyanceReferenceNumber',
            'goodsShipment.consignment.borderTransportMeans.transportMode',
            { 'goodsShipment.transportMethodOfPaymentCode': 'Method Of Payment For Transport Charges' },
        ],
        validation
    );
    if (fields.length > 0) {
        errors.push({ name: 'Transport Information', fields });
    }
};

const parseConsignment = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(
        ['goodsShipment.consignment.loadingPlace', 'goodsShipment.consignment.unloadingPlace'],
        validation
    );
    if (fields.length > 0) {
        errors.push({ name: 'Consignment', fields });
    }
};

const parseSeals = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'goodsShipment.consignment.transportEquipment')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError([{ sealsIdentity: 'sealsIdentifier' }], val);
        if (fields.length > 0) {
            errors.push({ name: `Seals ${index + 1}`, fields });
        }
    });
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'goodsShipment.goodsItems')?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseTaricCode(item.errors, itemErrors);
        parseGoodsInformation(item.errors, itemErrors);
        parseConsignor(item.errors, itemErrors);
        parseConsignee(item.errors, itemErrors);
        parseNotifyParty(item.errors, itemErrors);
        parseContainers(item.errors, itemErrors);
        parsePackages(item.errors, itemErrors);
        parseIdentityOfMeansOfTransportAtBorder(item.errors, itemErrors);
        parseAdditionalDocument(item.errors, itemErrors);
        parseAdditionalInformation(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseEnsProductTemplate = (validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseTaricCode(productTemplateErrors, validation);
    parseGoodsInformation(productTemplateErrors, validation);
    parseConsignor(productTemplateErrors, validation);
    parseConsignee(productTemplateErrors, validation);
    parseNotifyParty(productTemplateErrors, validation);
    parseContainers(productTemplateErrors, validation);
    parsePackages(productTemplateErrors, validation);
    parseIdentityOfMeansOfTransportAtBorder(productTemplateErrors, validation);
    parseAdditionalDocument(productTemplateErrors, validation);
    parseAdditionalInformation(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseTaricCode = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(['commodity.combinedNomenclature'], validation);
    if (fields.length > 0) {
        errors.push({ name: 'Taric Code', fields });
    }
};

const parseGoodsInformation = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    const fields = parseAnyValidationError(
        [
            { ucr: 'UCR' },
            { 'commodity.dangerousCode': 'UN Dangerous Goods Code' },
            { 'commodity.description': 'Textual Description' },
            { transportChargesMethodOfPayment: 'Method Of Payment For Transport Charges' },
            'loadingPlace',
            'unloadingPlace',
            'grossMass',
        ],
        validation
    );
    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
};

const parseContainers = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'containers')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['containerNumber'], val);
        if (fields.length > 0) {
            errors.push({ name: `Containers ${index + 1}`, fields });
        }
    });
};

const parsePackages = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'goodsPackaging')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['marks', 'type', 'numberOfPackages', 'numberOfPieces'], val);
        if (fields.length > 0) {
            errors.push({ name: `Packages ${index + 1}`, fields });
        }
    });
};

const parseIdentityOfMeansOfTransportAtBorder = (
    errors: ParsedCard[],
    validation: FormikErrors<IrelandEntrySummaryDeclaration>
) => {
    get(validation, 'borderTransportMeans')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['nationalityCrossingBorder', 'identityCrossingBorder'], val);
        if (fields.length > 0) {
            errors.push({ name: `Identity Of Means Of Transport At Border ${index + 1}`, fields });
        }
    });
};

const parseAdditionalDocument = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'additionalDocument')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['code', 'identifier'], val);
        if (fields.length > 0) {
            errors.push({ name: `Additional Document ${index + 1}`, fields });
        }
    });
};

const parseAdditionalInformation = (errors: ParsedCard[], validation: FormikErrors<IrelandEntrySummaryDeclaration>) => {
    get(validation, 'additionalInformation')?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(['code', 'identifier'], val);
        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index + 1}`, fields });
        }
    });
};

export default parseValidationErrors;
