import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const GoodsLocationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('Goods location')}>
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`locationTypeCode`, props)}
                    refNumber="5.23"
                    label={t('firstPlaceOfUse.locationTypeCode')}
                    selectOptions={aisCodelists?.locationType}
                    tooltip={getH1TooltipsByRefNumberAndField('5.23', t('firstPlaceOfUse.locationTypeCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`qualifierIdentification`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.qualifierOfIdentification')}
                    selectOptions={aisCodelists?.locationIdentificationQualifier}
                    tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.qualifierOfIdentification'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`identificationOfLocation`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.identificationOfLocation')}
                    selectOptions={aisCodelists?.locationOfGoodsCode}
                    tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.identificationOfLocation'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.maxLength(35)]}
                />

                <FormInput
                    maxLength={3}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalIdentifier`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.additionalIdentifier')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.additionalIdentifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`address.country`, props)}
                    refNumber="5.23"
                    tooltip={getH1TooltipsByRefNumberAndField('5.23', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GoodsLocationCard;
