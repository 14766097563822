import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';
import useCodelists from '../../../../../hooks/useCodelists';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'otherChargeDeductionAmount' | 'otherChargeDeductionAmountCurrencyId' | 'chargesTypeCode';

export const additionsDeductionsBlockValidation: {
    childValidators: {
        'goodsShipment.customsValuation.chargeDeduction': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.customsValuation.chargeDeduction': {
            childValidators: {
                otherChargeDeductionAmount: [validators.number(), validators.float(16, 2)],
                otherChargeDeductionAmountCurrencyId: [validators.exact(3)],
                chargesTypeCode: [validators.exact(2)],
            },
        },
    },
};

export const additionsDeductionsItemBlockValidation: {
    childValidators: {
        'customsValuation.chargeDeduction': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'customsValuation.chargeDeduction': {
            childValidators: {
                otherChargeDeductionAmount: [validators.number(), validators.float(16, 2)],
                otherChargeDeductionAmountCurrencyId: [validators.exact(3)],
                chargesTypeCode: [validators.exact(2)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const AdditionsDeductionsBlock = ({
    path = 'goodsShipment.customsValuation.chargeDeduction',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/9', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="otherChargeDeductionAmount">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('value')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="otherChargeDeductionAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <CurrencySelect
                        required
                        refNumber={ucc}
                        label={t('currency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="chargesTypeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('type')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.headerValuationAdjustmentTypes}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionsDeductionsBlock;
