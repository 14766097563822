import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import DeliveryTermsBlock from '../blocks/DeliveryTermsBlock';

const DeliveryTermsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('deliveryTerms')} hidden={props.hidden}>
            <FormCardContainer>
                <DeliveryTermsBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default DeliveryTermsCard;
