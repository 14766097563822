import styled from 'styled-components';

export const NewDeclarationCard = styled.div`
    margin-top: 1rem;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 30px 31px;
    position: absolute;
    width: 318px;
    height: 355px;
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    box-shadow: 0px 0px 24px rgba(9, 0, 255, 0.07);
    border-radius: 4px;
    z-index: 2;
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3.5rem;
`;
