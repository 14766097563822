import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'countryCode' | 'typeCode';

export const countryOfOriginBlockValidation: {
    childValidators: {
        origin: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        origin: {
            childValidators: {
                countryCode: [validators.exact(2)],
                typeCode: [validators.number(), validators.exact(1)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const CountryOfOriginBlock = ({ path = 'origin', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => ['5/15', '5/16'], []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="countryCode">
                {({ form, field }: FieldProps<any>) => (
                    <CountrySelect
                        refNumber={ucc}
                        label={t('countryOfOriginCountryCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('countryOfOriginRole')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.countryRegionSubRoleTypes}
                        condensed
                        tooltip="Populate with 1 (Non-preferential origin) to indicate that D/E 5/15 has been declared.
                        Populate with 2 (Preferential origin) to indicate that D/E 5/16 has been declared."
                        codeValidation={[validators.number(), validators.exact(1)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default CountryOfOriginBlock;
