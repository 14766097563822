import Alert from 'components/ui/base/alert/Alert';
import Button from 'components/ui/base/button/Button';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';

interface Props {
    country: DeclarationCountry;
    showAlert: boolean;
}

const InformationAlert: FC<Props> = ({ country, showAlert }) => {
    const navigate = useNavigate();

    const info = useMemo(() => {
        const message =
            country === DeclarationCountry.IRELAND
                ? 'You need to upload digital certificates to submit the declaration. Check your personal settings to proceed.'
                : 'You need to configure HMRC access to submit the declaration. Check you personal settings to proceed.';

        const redirect = () => {
            const link = country === DeclarationCountry.IRELAND ? '/settings/digital-certificate' : '/settings/hmrc';
            navigate(link);
        };

        return (
            showAlert && (
                <Alert
                    message={
                        <>
                            {message}
                            <Button size="small" type="link" onClick={redirect}>
                                Take me there
                            </Button>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
        );
    }, [showAlert, country, navigate]);

    return <>{info}</>;
};
export default InformationAlert;
