import Button from 'components/ui/base/button';
import { CollapseDiv, StyledCollapse as StyledCollapseGeneral } from 'components/ui/base/collapse/collapse.styles';
import { TestProps } from 'core/utils/testTypes';
import { FC, ReactNode, useEffect, useState } from 'react';
import { ExpandedIconDiv, StyledMinusCircleOutlined, StyledPlusCircleOutlined } from '../../Form.styles';

interface Props extends TestProps {
    children: ReactNode;
    action?: {
        title: string;
        onClick: Function;
        icon?: ReactNode;
    };
    invalid?: boolean;
    viewOnly?: boolean;
    defaultActiveKey?: number | string;
    activeKey?: number | number;
    key?: number | string;
    keyCard?: number | string;
    expandAll?: boolean;
}
const StyledCollapse: FC<Props> = ({
    children,
    action,
    invalid,
    viewOnly,
    defaultActiveKey,
    expandAll = false,
    keyCard,
    testId,
}) => {
    const [expanded, setExpanded] = useState<string | number | undefined>(defaultActiveKey);
    const expandedIcon = (props?: any) => {
        return props?.isActive ? <StyledMinusCircleOutlined /> : <StyledPlusCircleOutlined />;
    };
    const handleExpandIcon = () => {
        if (expanded) {
            setExpanded(undefined);
        } else {
            setExpanded(keyCard);
        }
    };

    useEffect(() => {
        const expandedState = expandAll ? keyCard : undefined;
        if (expanded !== expandedState) {
            setExpanded(expandedState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandAll, keyCard]);

    const handleOnChangeCollaps = (key: string | string[]) => {
        if (expanded) {
            setExpanded(undefined);
        } else {
            setExpanded(keyCard);
        }
    };

    return (
        <CollapseDiv invalid={invalid} data-testid={testId}>
            <StyledCollapseGeneral
                ghost
                viewOnly={viewOnly}
                expandIconPosition="end"
                defaultActiveKey={defaultActiveKey}
                key={keyCard}
                onChange={handleOnChangeCollaps}
                activeKey={expanded}
                expandIcon={(props) => (
                    <ExpandedIconDiv onClick={handleExpandIcon}>
                        {action ? (
                            <Button
                                type="primary"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    action.onClick();
                                }}
                                style={{ marginRight: '1.45rem' }}
                            >
                                {action.icon}
                                {action.title}
                            </Button>
                        ) : (
                            <></>
                        )}
                        {expandedIcon(props)}
                    </ExpandedIconDiv>
                )}
            >
                {children}
            </StyledCollapseGeneral>
        </CollapseDiv>
    );
};
export default StyledCollapse;
