import WCWeighbridge from 'views/setup/weighbridge-creation/screens/WCWeighbridge';
import WCWeighbridgeLocationTrace from 'views/setup/weighbridge-creation/screens/WCWeighbridgeLocationTrace';
import WC from 'views/setup/weighbridge-creation/WC';
import { Route } from '../../routes';

const wcWeighbridgeRoute: Route = {
    name: 'weighbridge-creation-weighbridge',
    index: true,
    element: <WCWeighbridge />,
};

const wcWeighbridgeLocationTraceRoute: Route = {
    path: 'location',
    name: 'weighbridge-creation-weighbridge',
    element: <WCWeighbridgeLocationTrace />,
};

const wcRoutes: Route[] = [
    {
        path: 'weighbridges/create',
        name: 'weighbridge-creation',
        element: <WC />,
        children: [wcWeighbridgeRoute, wcWeighbridgeLocationTraceRoute],
    },
];

export default wcRoutes;
