export interface PaginatedParams {
    page: number;
    size: number;
    sortDirection: 'DESC' | 'ASC';
    sortParameter: string;
    query?: string;
}

export const defaultPagination: Readonly<PaginatedParams> = {
    page: 0,
    size: 10,
    sortDirection: 'DESC',
    sortParameter: 'ID',
};
