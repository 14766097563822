import { CheckCircleFilled } from '@ant-design/icons';
import AntFormItem from 'antd/lib/form/FormItem';
import { BoxNumber } from 'components/ui/base/box-number/box-number.styles';
import Input from 'components/ui/base/input/Input';
import NumberInput from 'components/ui/base/input/NumberInput';
import { Text, TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

interface ErrorProp {
    error: boolean;
    touched: boolean;
    condensed?: boolean;
}

export const LabelDiv = styled.div<{ condensed?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ condensed }) => (condensed ? 0 : '0.2rem')};
`;

export const RefDiv = styled(BoxNumber)`
    margin-left: 0.8rem;
    height: min-content;
    min-width: 4rem;
    background-color: rgba(56, 55, 82, 0.2);
`;

export const InputLabel = styled.label`
    color: ${colors.darkBlack};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: inline-block;
`;

export const InputLabelRequired = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-left: 0.8rem;
`;

export const ErrorLabel = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
`;

export const StyledInput = styled(Input).withConfig({
    shouldForwardProp: (prop) => !['showSuccess'].includes(prop),
})<{ showSuccess: boolean; condensed?: boolean; mirrored?: boolean }>`
    width: 100%;
    border-radius: ${({ condensed }) => (condensed ? '4px' : '2px')};
    border: ${({ condensed, mirrored }) =>
        condensed && !mirrored ? '1px solid #cecece' : mirrored ? '1.5px solid #00CCFF' : 'hsv(0, 0, 85%)'};
    & input:first-child {
        color: ${({ mirrored }) => mirrored && '#00CCFF'};
    }
`;

export const FormItem = styled(AntFormItem)`
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
`;

export const StyledNumberInput = styled(NumberInput)<{ condensed?: boolean; mirrored?: boolean }>`
    width: 100%;
    border-radius: ${({ condensed }) => (condensed ? '4px' : '2px')};
    border: ${({ condensed, mirrored }) =>
        condensed && !mirrored ? '1px solid #cecece' : mirrored ? '1.5px solid #00CCFF' : 'hsv(0, 0, 85%)'};
    & input:first-child {
        color: ${({ mirrored }) => mirrored && '#00CCFF'};
    }
`;

export const InputDiv = styled.div`
    max-width: 100%;
    width: 100%;
    display: flex;
    gap: 1rem;
`;

export const ErrorDiv = styled.div<ErrorProp>`
    width: 100%;
    margin-bottom: ${({ error, touched, condensed }) => (error && touched ? '0' : condensed ? '0' : '2.2rem')};
`;

export const FlexDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const ViewOnlyDiv = styled.div`
    display: flex;
    margin-bottom: 0.8rem;
`;

export const ViewOnlyLabel = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

export const SelectAccountNumbers = styled(Text)`
    cursor: pointer;
`;

export const SuccessDiv = styled.div`
    width: 100%;
    margin-bottom: 1.1rem;
`;

export const SuccessIcon = styled(CheckCircleFilled)`
    color: ${colors.success};
`;

export const FormatDiv = styled(BoxNumber)`
    margin-left: 0.8rem;
    white-space: nowrap;
    width: auto;
    padding: 2.5px 5px;
    background-color: rgba(56, 55, 82, 0.2);
`;
