import { FC } from 'react';
import Table from '../../../components/ui/base/table/Table';
import useDrivers from '../../../hooks/useDrivers';

const DriversTable: FC = () => {
    const { drivers } = useDrivers();
    const columns = [
        {
            title: 'Driver Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'ETA - Delivery on time / not on time',
            dataIndex: 'eta',
            key: 'eta',
            render: () => 'OldBawn Rd',
        },
        {
            title: 'Busy / Not Busy',
            dataIndex: 'busy',
            key: 'busy',
            render: () => 'Busy',
        },
        {
            title: 'Destination Name',
            dataIndex: 'destination',
            key: 'destination',
            render: () => '54, Kevin St, Dublin',
        },
        {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
            render: () => 'Company Name',
        },
        {
            title: 'Job Progress',
            dataIndex: 'job',
            key: 'job',
            render: () => 'Not on Time',
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            key: 'cargo',
            render: () => <a href="https://grama.io">View Cargo</a>,
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact',
            render: () => <a href="https://grama.io">Contact</a>,
        },
        {
            title: 'Book',
            dataIndex: 'book',
            key: 'book',
            render: () => <a href="https://grama.io">book</a>,
        },
    ];

    return <Table columns={columns} dataSource={drivers.list} />;
};
export default DriversTable;
