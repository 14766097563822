import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormCheckbox from 'components/ui/composed/formCheckbox/FormCheckbox';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';

const DepositMasterDetailsCard: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    return (
        <section
            id={'deposit-master-details-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('deposit-master-details-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('masterDetails')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`applicationReference`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.applicationReference')}
                />
                <DeclarationDatepicker
                    viewOnly={viewOnly}
                    {...getFormikProps(`date`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.date')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`applicant`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.applicant')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`procedureCode`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.procedureCode')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`mrn`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.mrn')}
                />
                <DeclarationDatepicker
                    viewOnly={viewOnly}
                    {...getFormikProps(`exportDate`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.exportDate')}
                />
                <FormCheckbox
                    viewOnly={viewOnly}
                    {...getFormikProps(`importedGoods`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.importedGoods')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`outstandingBalanceOnImportedGoods`, {
                        getFieldHelpers,
                        getFieldProps,
                        getFieldMeta,
                    })}
                    label={t('depositRefund.outstandingBalanceOnImportedGoods')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`amountOfDepositRefundClaim`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.amountOfDepositRefundClaim')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`payerEoriForRefund`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.payerEoriForRefund')}
                />
            </FormCard>
        </section>
    );
};
export default DepositMasterDetailsCard;
