import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Spin } from 'antd';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitLoading } from '../Form.styles';
import {
    BtSubmit,
    CollapsedDiv,
    CollapseFooter,
    CollapseIcon,
    ExpandIcon,
    FooterButton,
    FooterRow,
    SubmitDiv,
} from './Footer.styles';

interface Props {
    disabledSaveOrSubmit?: boolean;
    disabled?: boolean;
    saveDraft: {
        trigger: Function;
        loading: boolean;
    };
    submitDeclaration: {
        trigger: Function;
        loading: boolean;
    };
    isSaving?: boolean;
    isSubmiting?: boolean;
    iconDeclarationSaved?: boolean;
}

const RefundFooter: FC<Props> = ({
    disabledSaveOrSubmit = false,
    disabled = false,
    saveDraft,
    submitDeclaration,
    isSaving = false,
    isSubmiting = false,
    iconDeclarationSaved = false,
}) => {
    const { collapsed, expandDeclarationFooter, collapseDeclarationFooter } = useDeclarationFooter();
    const { t } = useTranslation('common');

    const iconSavedInfo = useMemo(() => {
        if (iconDeclarationSaved === undefined) {
            return <></>;
        }

        return iconDeclarationSaved ? <CheckCircleOutlined /> : <EditOutlined />;
    }, [iconDeclarationSaved]);

    return (
        <>
            <SubmitDiv>
                {!collapsed ? (
                    <FooterRow justify="space-between" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                        <Col>
                            <CollapseFooter onClick={() => collapseDeclarationFooter()}>
                                <CollapseIcon /> {t('footer.hideFooter')}
                            </CollapseFooter>
                        </Col>
                        <Col>
                            {!disabledSaveOrSubmit && !disabled && (
                                <FooterButton
                                    type="primary"
                                    disabled={disabledSaveOrSubmit}
                                    loading={saveDraft.loading}
                                    onClick={() => {
                                        saveDraft.trigger();
                                    }}
                                >
                                    {t('footer.saveDraft')} {iconSavedInfo}
                                </FooterButton>
                            )}
                            {!disabledSaveOrSubmit && !disabled && (
                                <BtSubmit
                                    type="primary"
                                    disabled={disabledSaveOrSubmit}
                                    loading={submitDeclaration.loading}
                                    onClick={() => {
                                        submitDeclaration.trigger();
                                    }}
                                >
                                    {t('footer.submit')} {isSubmiting && <Spin indicator={<SubmitLoading spin />} />}
                                </BtSubmit>
                            )}
                        </Col>
                    </FooterRow>
                ) : (
                    <FooterRow align="middle" padding="0.1rem 0">
                        <Col span={12}>
                            <CollapsedDiv>
                                <CollapseFooter onClick={() => expandDeclarationFooter()}>
                                    <ExpandIcon /> {t('footer.viewFooter')}
                                </CollapseFooter>
                            </CollapsedDiv>
                        </Col>
                    </FooterRow>
                )}
            </SubmitDiv>
        </>
    );
};
export default RefundFooter;
