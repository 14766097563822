import { FC } from 'react';
import { Individual } from 'store/individuals/individual';
import TeamMember from './TeamMember';

interface Props {
    teamMembers: Individual[];
    results?: (Individual | null)[];
    remove: (index: number) => void;
}
export const ListTeamMembers: FC<Props> = ({ teamMembers, remove, results }) => {
    return (
        <>
            {teamMembers?.map((member, index) => {
                return <TeamMember results={results} index={index} member={member} remove={() => remove(index)} />;
            })}
        </>
    );
};

export default ListTeamMembers;
