import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import useSession from 'hooks/useSession';
import { FC, useEffect, useState } from 'react';
import { Driver, DriverSortParameter } from 'store/drivers/driver';

interface Props {
    data?: ListPayload<Driver>;
    onDelete: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort: (paginator: Partial<PaginatedParams>) => void;
    loading: boolean;
}

const DriversTable: FC<Props> = ({ data, onDelete, onDetails, onEdit, handlePagination, handleSort, loading }) => {
    const [drivers, setDrivers] = useState(data?.list);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { userInfo } = useSession();

    useEffect(() => {
        setDrivers(data?.list);
    }, [data]);

    const rowSelection = {
        onChange: (selectedRowKeys: unknown, selectedRows: Driver[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: DriverSortParameter.NAME,
            key: DriverSortParameter.NAME,
            align: 'left',
            onCell: (record: Driver, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Driver, index: number) =>
                record.fullName ? <span>{record.fullName}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Licence Number',
            dataIndex: DriverSortParameter.DRIVER_LICENCE_NUMBER,
            key: DriverSortParameter.DRIVER_LICENCE_NUMBER,
            align: 'left',
            onCell: (record: Driver, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Driver, index: number) =>
                record.driverLicenceNumber ? <span>{record.driverLicenceNumber}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Insurance Certificate Number',
            dataIndex: DriverSortParameter.INSURANCE_CERTIFICATE_NUMBER,
            key: DriverSortParameter.INSURANCE_CERTIFICATE_NUMBER,
            align: 'left',
            onCell: (record: Driver, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Driver, index: number) =>
                record.insuranceCertificateNumber ? <span>{record.insuranceCertificateNumber}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: DriverSortParameter.STATUS,
            key: DriverSortParameter.STATUS,
            align: 'left',
            onCell: (record: Driver, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Driver, index: number) =>
                record.status ? <span>{record.status}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'Commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Driver, index: number) => {
                return (
                    <>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onEdit(record.id)}>
                            <EditOutlined />
                        </span>
                        <span
                            style={{ marginRight: '1.2rem', cursor: 'pointer' }}
                            onClick={() => onDelete([record.id])}
                        >
                            <DeleteOutlined />
                        </span>
                    </>
                );
            },
        },
    ];

    function onChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {
        if (!Array.isArray(sorter) && sorter.columnKey) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    }

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={drivers?.filter((d) => d.customerId === userInfo?.customerId)}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page: number, size: number | undefined) => {
                        const params = { page: page - 1, size };
                        handlePagination(params);
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default DriversTable;
