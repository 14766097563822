import { useDispatch } from 'react-redux';
import { useAppSelector } from '../config/hooks';
import { BreadcrumbItem } from '../store/breadcrumb/breadcrumbItem';
import { doSetBreadcrumb, doClearBreadcrumb } from '../store/breadcrumb/action';
import { useCallback } from 'react';

const useBreadcrumb = () => {
    const dispatch = useDispatch();
    const breadcrumb = useAppSelector((state) => state.breadcrumb);

    const setBreadcrumbRoutes = useCallback(
        (routes: BreadcrumbItem[]) => {
            dispatch(doSetBreadcrumb(routes));
        },
        [dispatch]
    );

    const clearBreadcrumbRoutes = useCallback(() => {
        dispatch(doClearBreadcrumb());
    }, [dispatch]);

    return {
        breadcrumb,
        setBreadcrumbRoutes,
        clearBreadcrumbRoutes,
    };
};

export default useBreadcrumb;
