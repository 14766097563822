import { ParsedForm } from './ParsedForm';

export const doSetDeclarationFormErrors: Function = (errors: ParsedForm) => (dispatch: Function) => {
    dispatch({
        type: 'SET_DECLARATION_FORM_ERRORS',
        errors,
    });
};

export const doClearDeclarationFormErrors: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_DECLARATION_FORM_ERRORS' });
};
