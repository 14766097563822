import Notification from 'components/ui/base/notification/Notification';

const useDeclarationNotifications = () => {
    const showErrorNotification = (title: string, description: string) => {
        Notification({
            type: 'error',
            messageTitle: title,
            description: description,
            duration: 5,
        });
    };

    const showSuccessNotification = (title: string, description: string) => {
        Notification({
            type: 'success',
            messageTitle: title,
            description: description,
        });
    };

    return { showErrorNotification, showSuccessNotification };
};

export default useDeclarationNotifications;
