import { Form } from 'antd';
import { H5 } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    brokerIndividualTypeLabel,
    driverIndividualTypeLabel,
    IndividualType,
    individualTypeLabel,
    traderIndividualTypeLabel,
} from 'store/individuals/individual';
import { User } from 'store/session/user';
import { AddNewTeamMemberForm, AddTeamMemberTitle } from 'views/authentication/invite/Invite.styles';

interface NewTeamMemberProps {
    index: number;
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    user: User;
}
const NewTeamMember: FC<NewTeamMemberProps> = ({ index, getFieldProps, getFieldHelpers, getFieldMeta, user }) => {
    const { t } = useTranslation('onboarding');

    const roleOptions = useMemo(() => {
        switch (user?.role) {
            case IndividualType.TRADER_ADMIN:
                return traderIndividualTypeLabel;
            case IndividualType.TRADER_USER:
                return traderIndividualTypeLabel;
            case IndividualType.HAULIER_ADMIN:
                return driverIndividualTypeLabel;
            case IndividualType.DRIVER:
                return driverIndividualTypeLabel;
            case IndividualType.BROKER_ADMIN:
                return brokerIndividualTypeLabel;
            case IndividualType.BROKER_CLERK:
                return brokerIndividualTypeLabel;
            default:
                return individualTypeLabel;
        }
    }, [user]);

    return (
        <AddNewTeamMemberForm>
            <AddTeamMemberTitle>
                <H5>{`${t('team_member')} ${index}`}</H5>
            </AddTeamMemberTitle>
            <Form layout="vertical">
                <FormSelect
                    label="Role"
                    hideKeys
                    fieldMeta={getFieldMeta('type')}
                    fieldProps={getFieldProps('type')}
                    fieldHelper={getFieldHelpers('type')}
                    selectOptions={roleOptions}
                />

                <FormInput
                    label="Email"
                    fieldMeta={getFieldMeta('email')}
                    fieldProps={getFieldProps('email')}
                    fieldHelper={getFieldHelpers('email')}
                />

                <FormPhoneNumber
                    fieldNumberMeta={getFieldMeta('phone')}
                    fieldNumberProps={getFieldProps('phone')}
                    fieldCodeProps={getFieldProps('phoneCode')}
                    fieldCodeMeta={getFieldMeta('phoneCode')}
                />
            </Form>
        </AddNewTeamMemberForm>
    );
};
export default NewTeamMember;
