import { Layout } from 'antd';
import CrossIcon from 'components/icons/CrossIcon';
import styled from 'styled-components';
import { colors } from 'theme';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
    background: ${colors.white};
    padding: 0;
    z-index: 1;
    position: sticky;
    width: 100%;
    top: 0;
    height: 100%;
`;

export const StyledLayout = styled(Layout)`
    background: ${colors.white};
    padding: 0;
`;

export const EmptyRenderRow = styled.div`
    text-align: center;
`;

export const EmptyRenderCol = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

export const EmptyRenderIcon = styled(CrossIcon)`
    font-size: 9;
`;
