export const aesDeclarationStatuses = {
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    REGISTERED: 'REGISTERED',
    UNDER_CONTROL: 'UNDER_CONTROL',
    DRAFT: 'DRAFT',
    GOODS_RELEASED_FOR_EXPORT: 'GOODS_RELEASED_FOR_EXPORT',
    DIVERSION_REJECTED: 'DIVERSION_REJECTED',
    REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS: 'REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS',
    GOODS_RELEASED_FOR_IMMEDIATE_LEAVE: 'GOODS_RELEASED_FOR_IMMEDIATE_LEAVE',
    EXPORTED: 'EXPORTED',
    EXPORTED_STOPPED_AT_EXIT: 'EXPORTED_STOPPED_AT_EXIT',
    CANCELLED: 'CANCELLED',
    INVALIDATED: 'INVALIDATED',
    GOODS_NOT_ALLOWED_TO_EXIT: 'GOODS_NOT_ALLOWED_TO_EXIT',
    NOT_RELEASED_FOR_EXPORT: 'NOT_RELEASED_FOR_EXPORT',
    GOODS_READY_TO_BE_RELEASED: 'GOODS_READY_TO_BE_RELEASED',
    EXITED: 'EXITED',
    EXPORT_STOPPED_DISCREPANCIES_AT_EXIT: 'EXPORT_STOPPED_DISCREPANCIES_AT_EXIT',
    GOODS_PRESENTED_AT_EXIT: 'GOODS_PRESENTED_AT_EXIT',
} as const;

export const baseDeclarationStatuses = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    AWAITING_TARIFF_CALCULATION: 'AWAITING_TARIFF_CALCULATION',
    ACCEPTED: 'ACCEPTED',
    AWAITING_RISK: 'AWAITING_RISK',
    UNDER_PAYMENT: 'UNDER_PAYMENT',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    UNDER_CONTROL: 'UNDER_CONTROL',
    RELEASED: 'RELEASED',
    REGISTERED: 'REGISTERED',
    NOT_RELEASED: 'NOT_RELEASED',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    INVALIDATE: 'INVALIDATE',
    RISKED: 'RISKED',
} as const;

export const DeclarationStatus = { ...baseDeclarationStatuses, ...aesDeclarationStatuses };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DeclarationStatus = typeof DeclarationStatus[keyof typeof DeclarationStatus];
