import { deleteVehicle, getVehicle, listVehicles, postVehicle, putVehicle } from './client';
import { Vehicle, VehiclesParams } from './vehicles';

export const doCreateVehicle: Function = (vehicle: Vehicle) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_VEHICLE_REQUEST' });
    try {
        const payload = await postVehicle(vehicle);

        dispatch({
            type: 'CREATE_VEHICLE_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_VEHICLE_ERROR', error: e?.response?.data });
        throw e;
    }
};


export const doListVehicles: Function = (params?: Partial<VehiclesParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_VEHICLES_REQUEST' });
    try {
        const payload = await listVehicles(params);
        dispatch({
            type: 'LIST_VEHICLES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_VEHICLES_ERROR', error: e?.response?.data });
    }
};

export const doGetVehicle: Function = (vehicleId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_VEHICLE_REQUEST' });
    try {
        const payload = await getVehicle(vehicleId);
        dispatch({
            type: 'GET_VEHICLE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_VEHICLE_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditVehicle: Function = (vehicle: Vehicle) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_VEHICLE_REQUEST' });
    try {
        const payload = await putVehicle(vehicle);
        dispatch({
            type: 'EDIT_VEHICLE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_VEHICLE_ERROR', error: e?.response?.data });
    }
};

export const doDeleteVehicle: Function = (vehicleId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_VEHICLE_REQUEST' });
    try {
        const payload = await deleteVehicle(vehicleId);
        dispatch({
            type: 'DELETE_VEHICLE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_VEHICLE_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
