import { Col, Row, Modal } from 'antd';
import Button from 'components/ui/base/button';
import SearchBar from 'components/ui/base/searchbar';
import { FC, useCallback, useEffect, useReducer } from 'react';
import ProductTemplatesTable from 'views/custom-declaration/products/components/ProductTemplatesTable';
import useProductTemplates from 'hooks/useProductsTemplates';
import debounce from 'lodash.debounce';
import { TemplateResponse } from '../../../store/template/template';
import { isEmpty } from 'lodash';

interface Props {
    addProductsToDeclaration?: Function;
    declarationTemplate: TemplateResponse | undefined;
}

export interface AddProductTemplatesToDeclaration {
    modalVisible: boolean;
    productTemplates: unknown[];
}

const DeclarationsProductsTemplates: FC<Props> = ({ addProductsToDeclaration, declarationTemplate }) => {
    const { productsTemplates, listProductTemplates } = useProductTemplates({});

    const [addProductTemplatesToDeclaration, setAddProductTemplatesToDeclaration] = useReducer(
        (initialState: AddProductTemplatesToDeclaration, newState: Partial<AddProductTemplatesToDeclaration>) => ({
            ...initialState,
            ...newState,
        }),
        { modalVisible: false, productTemplates: [] }
    );
    const closeModal = useCallback(() => setAddProductTemplatesToDeclaration({ modalVisible: false }), []);

    const listProductTemplatesRequest = useCallback(
        (params?: { query?: string }) =>
            listProductTemplates({ ...params, declarationTemplateId: declarationTemplate?.id ?? 'default' }),
        [listProductTemplates, declarationTemplate?.id]
    );

    useEffect(() => {
        listProductTemplatesRequest();
    }, [listProductTemplatesRequest]);

    const querySearch = (query: string) => {
        const params = query ? { query } : {};
        listProductTemplatesRequest(params);
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    return (
        <div style={{ paddingRight: '0.8rem' }}>
            <Row justify="end" style={{ marginBottom: '2.5rem' }} wrap={false}>
                <Button
                    disabled={isEmpty(addProductTemplatesToDeclaration.productTemplates)}
                    onClick={() => {
                        addProductsToDeclaration?.(addProductTemplatesToDeclaration.productTemplates);
                        setAddProductTemplatesToDeclaration({ productTemplates: [] });
                    }}
                >
                    Add to declaration
                </Button>
            </Row>

            <Row gutter={16} style={{ marginBottom: '2.5rem' }}>
                <Col span={24}>
                    <SearchBar
                        inputPlaceholder="Search products"
                        onClear={() => listProductTemplatesRequest()}
                        onSearch={(value) => debouncedSearch(value)}
                    />
                </Col>
            </Row>

            <ProductTemplatesTable
                source={productsTemplates}
                // declarationTemplatesList is used to evaluate the declaration template names in the table
                declarationTemplatesList={declarationTemplate ? [declarationTemplate] : []}
                onAddProductTemplatesToDeclaration={setAddProductTemplatesToDeclaration}
            />
            <Modal
                visible={addProductTemplatesToDeclaration.modalVisible}
                onCancel={closeModal}
                title="Add to declaration"
                footer={[
                    <Button onClick={closeModal}>Cancel</Button>,
                    <Button
                        onClick={() => {
                            addProductsToDeclaration?.(addProductTemplatesToDeclaration.productTemplates);
                            closeModal();
                        }}
                    >
                        Confirm
                    </Button>,
                ]}
            >
                Please confirm you want to add this Product to the Declaration.
            </Modal>
        </div>
    );
};
export default DeclarationsProductsTemplates;
