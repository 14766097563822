import righActionIcon from 'assets/right-action.svg';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HyperLink, ImgDiv, MainDiv, TextDiv } from './SignUpSplashScreen.style';

interface Props {
    onClear: Function;
}

const SignUpSplashScreen: FC<Props> = ({ onClear }) => {
    const navigate = useNavigate();

    const goToSignIn = useCallback(() => {
        onClear();
        navigate('/');
    }, [navigate, onClear]);

    return (
        <MainDiv>
            <ImgDiv>
                <img src={righActionIcon} alt="rightIcon" />
            </ImgDiv>
            <TextDiv>You have signed up successfully.</TextDiv>
            <TextDiv>We have sent a verification email to your inbox.</TextDiv>
            <HyperLink
                onClick={() => {
                    goToSignIn();
                }}
            >
                Go to Sign in
            </HyperLink>
        </MainDiv>
    );
};
export default SignUpSplashScreen;
