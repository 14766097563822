import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Journey, JourneyParams } from './journeys';

/*
const dummyJourney = (journeyId = faker.datatype.uuid()): Journey => ({
    id: journeyId,
    jobId: faker.datatype.uuid(),
    consigneeId: faker.datatype.uuid(),
    consignorId: faker.datatype.uuid(),
    description: faker.commerce.productDescription(),
    startDateTime: faker.date.soon().toISOString(),
    endDateTime: faker.date.soon().toISOString(),
    specialInstructions: faker.commerce.productMaterial(),
    legs: [dummyLeg()],
}); */

export const listJourneys = (params?: Partial<JourneyParams>): Promise<ListPayload<Journey>> => {
    return axiosClient
        .get<ListResponse<Journey>>(`${config.journeysUrl}/journeys`, { params })
        .then((response) => response.data.payload);
};
/* export const listJourneys = (params?: Partial<JourneyParams>): Promise<ListPayload<Journey>> => {
    return Promise.resolve({ list: [...Array(10)].map(() => dummyJourney()), pageNumber: 0, pageSize: 1, total: 2 });
}; */

export const getJourney = (journeyId: string): Promise<Journey> =>
    axiosClient
        .get<SuccessResponse<Journey>>(`${config.journeysUrl}/journeys/${journeyId}`)
        .then((response) => response.data.payload);
/* export const getJourney = (journeyId: string): Promise<Journey> => Promise.resolve(dummyJourney(journeyId)); */

export const postJourney = (journey: Journey): Promise<Journey> =>
    axiosClient
        .post<SuccessResponse<Journey>>(`${config.journeysUrl}/journeys`, journey)
        .then((response) => response.data.payload);

export const putJourney = (journey: Journey): Promise<Journey> =>
    axiosClient
        .put<SuccessResponse<Journey>>(`${config.journeysUrl}/journeys`, journey)
        .then((response) => response.data.payload);

export const deleteJourney = (journeyId: string): Promise<Journey> =>
    axiosClient
        .delete<SuccessResponse<Journey>>(`${config.journeysUrl}/journeys/${journeyId}`)
        .then((response) => response.data.payload);
