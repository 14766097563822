import SearchBar from 'components/ui/base/searchbar';
import { ListPayload } from 'core/http/response';
import useCustomers from 'hooks/useCustomers';
import debounce from 'lodash.debounce';
import { FC, useEffect, useState } from 'react';
import { Customer, CustomerType } from 'store/customers/customer';
import { listJobs } from 'store/jobs/client';
import { JobResponse } from 'store/jobs/job';
import CustomerTable from './CustomerTable';

interface Props {
    handleSelect?: (customer: Customer) => void;
    customerType?: CustomerType;
}

const SearchCustomer: FC<Props> = (props) => {
    const { listCustomers, customers, isLoading } = useCustomers();
    const [customersAndJobs, setCustomersAndJobs] = useState<ListPayload<Customer & { jobs: number }>>();
    const [jobs, setJobs] = useState<ListPayload<JobResponse>[]>();
    const { customerType } = props;

    const querySearch = async (query: string) => {
        if (query) {
            const params = customerType ? { type: customerType, query } : { query };
            await listCustomers(params);
        } else {
            if (customerType) {
                const params = { type: customerType };
                await listCustomers(params);
            } else {
                await listCustomers();
            }
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    // Set customers and jobs
    useEffect(() => {
        const data = { ...customers };
        if (jobs && isLoading !== true) {
            data.list = customers.list.map((c, i) => {
                if (jobs[i] && jobs[i].total) {
                    return { ...c, jobs: jobs[i].total };
                } else {
                    return { ...c, jobs: 0 };
                }
            });
            setCustomersAndJobs(data as ListPayload<Customer & { jobs: number }>);
        }
    }, [jobs, customers, isLoading]);

    // Initial list customers
    useEffect(() => {
        if (!customers.list && isLoading !== true) {
            if (customerType) {
                const params = { type: customerType };
                listCustomers(params);
            } else {
                listCustomers();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers, isLoading]);

    // Get jobs from customers
    useEffect(() => {
        if (!jobs?.length && customers && isLoading !== true) {
            getJobsFromCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, customers, isLoading]);

    const getJobsFromCustomer = async () => {
        if (customers.total > 0) {
            const jobs = await Promise.all(
                customers.list.map((c) => {
                    return listJobs({ customerId: c.id });
                })
            );
            setJobs(jobs);
        }
    };

    return (
        <>
            <SearchBar onSearch={(value) => debouncedSearch(value)} inputPlaceholder="Search by name, email, EORI" />
            <CustomerTable data={customersAndJobs} loading={isLoading} handleSelect={props.handleSelect} />
        </>
    );
};
export default SearchCustomer;
