import {
    addProductsTemplatesToIrelandENS,
    addProductsTemplatesToIrelandExport,
    addProductsTemplatesToIrelandH1,
    addProductsTemplatesToIrelandH7,
    addProductsTemplatesToUkExport,
    addProductsTemplatesToUkImport,
} from './client';

export const doAddProductTemplateToIrelandH1: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_REQUEST' });
        try {
            const payload = await addProductsTemplatesToIrelandH1(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_ERROR', error: e?.response?.data });
        }
    };

export const doAddProductTemplateToIrelandH7: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_REQUEST' });
        try {
            const payload = await addProductsTemplatesToIrelandH7(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H7_ERROR', error: e?.response?.data });
        }
    };

export const doAddProductTemplateToIrelandENS: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_REQUEST' });
        try {
            const payload = await addProductsTemplatesToIrelandENS(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_H1_ERROR', error: e?.response?.data });
        }
    };

export const doAddProductTemplateToIrelandExport: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_REQUEST' });
        try {
            const payload = await addProductsTemplatesToIrelandExport(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_IRELAND_EXPORT_ERROR', error: e?.response?.data });
        }
    };

export const doAddProductTemplateToUkExport: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_EXPORT_REQUEST' });
        try {
            const payload = await addProductsTemplatesToUkExport(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_EXPORT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_EXPORT_ERROR', error: e?.response?.data });
        }
    };

export const doAddProductTemplateToUkImport: Function =
    (declarationId: string, productTemplateIds: string[]) => async (dispatch: Function) => {
        dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_IMPORT_REQUEST' });
        try {
            const payload = await addProductsTemplatesToUkImport(declarationId, productTemplateIds);
            dispatch({
                type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_IMPORT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DO_ADD_PRODUCT_TEMPLATE_TO_UK_IMPORT_ERROR', error: e?.response?.data });
        }
    };
