import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';

const GoodsShipmentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumber } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };
    return (
        <NewFormCard title={t('goodsShipment.title')}>
            <FormCardContainer>
                <DeclarationInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    numbersAndLetters
                    {...getFormikProps(`goodsShipment.ucr`, props)}
                    tooltip={getIrelandExportTooltipsByRefNumber('5')}
                    refNumber="7"
                    label={t('ucr')}
                    refClicked={handleToggleHelp}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default GoodsShipmentCard;
