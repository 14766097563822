import { PaginatedParams } from "core/http/pagination";

export interface Driver{
    id: string;
    driverLicenceNumber: string;
    insuranceCertificateNumber: string;
    status: DriverStatus;
    customerId: string;
    email: string;
    fullName: string
    phone: string;
    address: {
        addressLine1: string;
        addressLine2: string;
        postCode: string;
        city: string;
        country: string;
    };
}

export enum DriverStatus{
    NEW = "NEW",
    ON_LEAVE = "ON_LEAVE",
    SUSPENDED = "SUSPENDED",
    AVAILABLE = "AVAILABLE"
}

export interface DriversQueryParams  extends PaginatedParams{
    customerId?: string;
    queryBy?:string[];
}

export enum DriverSortParameter {
    NAME = "NAME",
    STATUS = "STATUS",
    DRIVER_LICENCE_NUMBER = "DRIVER_LICENCE_NUMBER",
    INSURANCE_CERTIFICATE_NUMBER = "INSURANCE_CERTIFICATE_NUMBER"
}