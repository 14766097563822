import { FieldInputProps, FormikProps, useFormikContext } from 'formik';
import { ReactElement, useMemo, useState } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import { ValidationSchema, validators } from '../validations/validations';
import {
    CdsExportDeclarationPayload,
    CdsExportGovernmentAgencyGoodsItem,
} from 'store/declarations/uk/export-declaration';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import useCustomers from 'hooks/useCustomers';
import { get } from 'lodash';
import { Button, Modal, ModalProps, notification, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import CdsExportDeclarationUtils from '../utils';
import { CdsExportGovernmentAgencyGoodsItemPayload } from 'store/products/reducer';
import { Declaration } from 'store/declarations/declaration';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import styled from 'styled-components';
import { colors } from 'theme';
import { H5 } from 'components/ui/base/typography';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';

const { Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
    font-size: 1.6rem;
    color: ${colors.darkPurple};
`;

type Fields = 'transactionNatureCode';

export const natureOfTransactionBlockValidation: {
    childValidators: {
        goodsShipment: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        goodsShipment: {
            childValidators: {
                transactionNatureCode: [validators.number(), validators.maxLength(2)],
            },
        },
    },
};

export const natureOfTransactionBlockProductValidation: ValidationSchema<Fields> = {
    childValidators: {
        transactionNatureCode: [validators.number(), validators.maxLength(2)],
    },
};

interface Props extends BlockProps<Fields> {}

const NatureOfTransactionBlock = ({ path = 'goodsShipment', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const { declaration } = useDeclarations();
    const { template, templateFormik, form: templateForm } = useTemplateContext();
    const currentLevel = useMemo(() => (path === 'goodsShipment' ? 'header' : 'item'), [path]);

    const [value, setValue] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    const handleAnswer = (answer: 'header' | 'item', form: FormikProps<any>, field: FieldInputProps<any>) => {
        let _value = currentLevel === answer ? value : null;
        if (template) {
            templateFormik?.getFieldProps(`${form}.defaults.${path}.transactionNatureCode`).onChange({
                target: { value: _value, name: field.name },
            });
            return;
        }
        form.getFieldProps(field.name).onChange({
            target: { value: _value, name: field.name },
        });
    };

    const ucc = useMemo(() => '8/5', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="transactionNatureCode">
                {({ form, field }) => (
                    <>
                        <FormSelect
                            refNumber={ucc}
                            label={t('natureOfTransaction')}
                            {...getFormikProps(field.name, form)}
                            fieldEvents={{
                                onChange: (value, option) => {
                                    const formik = template ? templateFormik : form;
                                    const name = template ? `${templateForm}.defaults.${field.name}` : field.name;

                                    const hasOldValue =
                                        template && templateForm
                                            ? {
                                                  header: Boolean(
                                                      get(
                                                          formik?.values,
                                                          `master.defaults.goodsShipment.transactionNatureCode`
                                                      )
                                                  ),
                                                  item: Boolean(
                                                      get(formik?.values, `product.defaults.transactionNatureCode`)
                                                  ),
                                              }
                                            : {
                                                  header: Boolean(
                                                      declaration?.cdsExportDeclaration?.cdsExportDeclarationPayload
                                                          ?.goodsShipment?.transactionNatureCode
                                                  ),
                                                  item: declaration?.cdsExportDeclaration?.cdsExportGovernmentAgencyGoodsItems?.some(
                                                      (item) => item.governmentAgencyGoodsItem?.transactionNatureCode
                                                  ),
                                              };

                                    if (
                                        (currentLevel === 'item' && hasOldValue.header) ||
                                        (currentLevel === 'header' && hasOldValue.item)
                                    ) {
                                        setValue(value);
                                        setModalOpen(true);
                                        formik?.getFieldProps(name).onChange({
                                            target: { value: null, name },
                                        });
                                    } else {
                                        formik?.getFieldProps(name).onChange({
                                            target: { value, name },
                                        });
                                    }
                                },
                            }}
                            selectOptions={cdsCodelists?.transactionNatureTypes}
                            condensed
                            codeValidation={[validators.maxLength(2)]}
                        />
                        <NatureOfTransactionModal
                            path={path}
                            value={value}
                            visible={modalOpen}
                            onAnswer={(answer) => handleAnswer(answer, form, field)}
                            onCancel={() => setModalOpen(false)}
                        />
                    </>
                )}
            </DeclarationField>
        </>
    );
};

interface NatureOfTransactionModalProps extends ModalProps {
    path: string | null;
    value?: string | undefined | null;
    onAnswer?: (answer: 'header' | 'item') => void;
}

const NatureOfTransactionModal = ({
    path,
    value,
    onAnswer,
    ...modalProps
}: NatureOfTransactionModalProps): ReactElement => {
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { declaration, updateUkExportDeclaration } = useDeclarations();
    const { products, updateUkExportProduct } = useProducts();
    const { template, templateFormik } = useTemplateContext();
    const formik = useFormikContext();
    const { productId } = useParams();
    const {
        customer: { id: customerId },
    } = useCustomers();
    const currentLevel = useMemo(() => (path === 'goodsShipment' ? 'header' : 'item'), [path]);

    const setNatureOfTransaction = {
        header: (declaration: Declaration | undefined, transactionNatureCode: string | undefined | null) => {
            if (!declaration) return null;
            const _declaration: Declaration = {
                ...declaration,
                cdsExportDeclaration: {
                    ...declaration.cdsExportDeclaration,
                    cdsExportDeclarationPayload: {
                        ...CdsExportDeclarationUtils.transformForServer(
                            declaration.cdsExportDeclaration?.cdsExportDeclarationPayload as any
                        ),
                        goodsShipment: {
                            ...declaration.cdsExportDeclaration?.cdsExportDeclarationPayload?.goodsShipment,
                            transactionNatureCode: transactionNatureCode ?? undefined,
                        },
                    },
                },
            };
            return updateUkExportDeclaration(customerId, declaration!.id!, _declaration as any, false);
        },
        item: (products: CdsExportGovernmentAgencyGoodsItem[], transactionNatureCode: string | undefined | null) => {
            return products.map((product) => {
                const _product = {
                    ...product,
                    transactionNatureCode: transactionNatureCode ?? undefined,
                };
                return updateUkExportProduct(
                    {
                        id: _product.id,
                        governmentAgencyGoodsItem: _product,
                    },
                    declaration!.id!,
                    product.id!,
                    false
                );
            });
        },
    };

    const getPreparedData = () => {
        let _products = (products?.list as CdsExportGovernmentAgencyGoodsItemPayload[] | undefined)?.map((product) => ({
            id: product.id,
            ...product.governmentAgencyGoodsItem,
        })) as CdsExportGovernmentAgencyGoodsItem[];

        let _declaration = declaration;
        if (currentLevel === 'item') {
            _products = _products?.map((p) => {
                if (p.id === productId)
                    return {
                        id: p.id,
                        ...(CdsExportDeclarationUtils.transformProductForServer(
                            formik.values as any
                        ) as CdsExportGovernmentAgencyGoodsItem),
                    };
                return p;
            });
        } else {
            _declaration = declaration && {
                ...declaration,
                cdsExportDeclaration: CdsExportDeclarationUtils.transformForServer({
                    ...declaration.cdsExportDeclaration,
                    cdsExportDeclarationPayload: formik.values as CdsExportDeclarationPayload,
                }),
            };
        }
        return { _declaration, _products };
    };

    const handleClickMasterInformationTemplate = () => {
        templateFormik?.setFieldValue(`master.defaults.goodsShipment.transactionNatureCode`, value);
        templateFormik?.setFieldValue(`product.defaults.transactionNatureCode`, null);
        onAnswer?.('header');
        modalProps.onCancel?.(undefined as any);
    };
    const handleClickItemDetailsTemplate = () => {
        templateFormik?.setFieldValue(`master.defaults.goodsShipment.transactionNatureCode`, null);
        templateFormik?.setFieldValue(`product.defaults.transactionNatureCode`, value);
        onAnswer?.('item');
        modalProps.onCancel?.(undefined as any);
    };

    const handleClickMasterInformation = async () => {
        const { _declaration, _products } = getPreparedData();
        if (declaration && declaration.id && declaration.cdsExportDeclaration && products) {
            showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Updating Nature of Transaction' } });
            await Promise.all([
                setNatureOfTransaction.item(_products, null),
                setNatureOfTransaction.header(_declaration, value),
            ]);
            notification.success({ message: 'Nature of Transaction set in Master Information' });
            hideGlobalOverlay();
        }
        onAnswer?.('header');
        modalProps.onCancel?.(undefined as any);
    };
    const handleClickItemDetails = async () => {
        const { _declaration, _products } = getPreparedData();
        if (declaration && declaration.id && declaration.cdsExportDeclaration && products) {
            showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Updating Nature of Transaction' } });
            await Promise.all([
                setNatureOfTransaction.item(_products, value),
                setNatureOfTransaction.header(_declaration, null),
            ]);
            notification.success({ message: 'Nature of Transaction set in Item Details' });
            hideGlobalOverlay();
        }
        onAnswer?.('item');
        modalProps.onCancel?.(undefined as any);
    };

    return (
        <Modal footer={null} {...modalProps}>
            <div>
                <H5>Nature of Transaction can be filled either in the header or in the item level.</H5>
                <StyledParagraph style={{ marginTop: '1rem' }}>
                    On which level would you like to apply the value "{value}"?
                </StyledParagraph>
            </div>
            <div style={{ marginTop: '2rem', display: 'flex', gap: '1rem' }}>
                <Button
                    type="primary"
                    onClick={() => (template ? handleClickMasterInformationTemplate() : handleClickMasterInformation())}
                >
                    Master Information
                </Button>
                <Button
                    type="primary"
                    onClick={() => (template ? handleClickItemDetailsTemplate() : handleClickItemDetails())}
                >
                    Item Details
                </Button>
            </div>
        </Modal>
    );
};

export default NatureOfTransactionBlock;
