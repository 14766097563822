import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { doCreateDriver, doDeleteDriver, doEditDriver, doGetDriver, doListDrivers } from 'store/drivers/action';
import { Driver, DriversQueryParams } from 'store/drivers/driver';
import { useAppSelector } from '../config/hooks';

interface UseDriversProps {
    driverID?: string;
}

const useDrivers = ({driverID}: UseDriversProps = {}) => {
    const drivers = useAppSelector((state) => state.drivers.drivers);
    const driver = useAppSelector((state) => (driverID ? state.drivers.entities[driverID] : ({} as Driver)));
    const isLoading = useAppSelector((state) => state.drivers.isLoading);
    const error = useAppSelector((state) => state.drivers.error);
    const dispatch = useDispatch();

    const listDrivers = useCallback(( params?: Partial<DriversQueryParams>) => {
        return dispatch(doListDrivers(params));
    }, [dispatch]);

    const createDriver = useCallback((driver: Driver) => {
       return dispatch(doCreateDriver(driver));
    },[dispatch]);

    const editDriver = useCallback((driver: Driver) => {
        return dispatch(doEditDriver(driver))
    },[dispatch]);
    
    const getDriver = useCallback((driverId: string) => {
        return dispatch(doGetDriver(driverId));
    },[dispatch]);

    const deleteDriver = useCallback((driverId: string) => {
        return dispatch(doDeleteDriver(driverId))
    },[dispatch]);


    return {
        driver,
        drivers,
        isLoading,
        error,
        deleteDriver,
        getDriver,
        editDriver,
        createDriver,
        listDrivers
    };
};

export default useDrivers;
