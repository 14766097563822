import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import ItemPriceBlock, { itemPriceBlockValidation } from '../../blocks/ItemPriceBlock';

export const itemPriceCardValidation = {
    selfValidators: [itemPriceBlockValidation],
};

const ItemPriceCard = (props: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('itemAmount')} hidden={props.hidden}>
            <FormCardContainer>
                <ItemPriceBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ItemPriceCard;
