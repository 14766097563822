import { Typography } from 'antd';
import moment from 'moment';
import { EDocketT } from '../eDocketTypes';
import { EDocketInfoStyledComponents } from './EDocketForm.styles';

const { Text } = Typography;

type Props = {
    eDocketId: string;
    dateGenerated: string;
    t: EDocketT;
};

const EDocketInfo = ({ eDocketId, dateGenerated, t }: Props) => {
    return (
        <EDocketInfoStyledComponents.Main>
            <Text strong>{`${t('docketIdNumber')}: ${eDocketId}`}</Text>
            <Text strong>{`${t('dateGenerated')}: ${moment(dateGenerated).format('DD/MM/YYYY')}`}</Text>
        </EDocketInfoStyledComponents.Main>
    );
};

export default EDocketInfo;
