import { Reducer } from 'redux';
import { declarationFilterActions } from './actionType';
import { DeclarationFilter } from './DeclarationFilter';

export const initialState: DeclarationFilter = { declarationType: undefined, filter: [] };

const declarationFilter: Reducer<DeclarationFilter> = (state = initialState, action: declarationFilterActions) => {
    switch (action.type) {
        case 'SET_DECLARATION_FILTER':
            return action.declarationFilter;
        case 'CLEAR_DECLARATION_FILTER':
            return initialState;
        default:
            return state;
    }
};
export default declarationFilter;
