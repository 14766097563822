import useBreadcrumb from 'hooks/useBreadcrumb';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WCOInitialStyledComponents from '../styles/WCOInitial.styles';

const { Main, Title, Text, Button, TitleTextContainer } = WCOInitialStyledComponents;

const WCOInitial = () => {
    const { t } = useTranslation('weighbridge');
    const { setBreadcrumbRoutes } = useBreadcrumb();

    // Setting the route in the header
    useEffect(() => {
        setBreadcrumbRoutes([
            { breadcrumbName: t('breadcrumbs.setup'), path: '' },
            { breadcrumbName: t('breadcrumbs.companyRegistration'), path: '' },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Main>
            <TitleTextContainer>
                <Title>{t('initial.title')}</Title>
                <Text>{t('initial.subtitle')}</Text>
            </TitleTextContainer>
            <Link to="company-details">
                <Button type="primary">{t('start')}</Button>
            </Link>
        </Main>
    );
};

export default WCOInitial;
