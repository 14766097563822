import { useAppDispatch, useAppSelector } from "config/hooks";
import { useCallback } from "react";
import { doCreateVehicle, doDeleteVehicle, doEditVehicle, doGetVehicle, doListVehicles } from "store/vehicles/action";
import { Vehicle, VehiclesParams } from "store/vehicles/vehicles";

interface UseVehiclesProps {
    vehicleId?: string;
}

const useVehicles  = ({ vehicleId }: UseVehiclesProps = {}) => {
    const vehicles = useAppSelector((state) => state.vehicles.vehicles);
    const vehicle = useAppSelector((state) => (vehicleId ? state.vehicles.entities[vehicleId] : ({} as Vehicle)));
    const isLoading = useAppSelector((state) => state.vehicles.isLoading);
    const error = useAppSelector((state) => state.vehicles.error);

    const dispatch = useAppDispatch();

    const listVehicles = useCallback((params?: Partial<VehiclesParams>) => {
        return dispatch(doListVehicles(params));
    }, [dispatch]);

    const createVehicle = useCallback((vehicle: Vehicle) => {
       return dispatch(doCreateVehicle(vehicle));
    },[dispatch]);

    const editVehicle = useCallback((vehicle: Vehicle) => {
        return dispatch(doEditVehicle(vehicle))
    },[dispatch]);
    
    const getVehicle = useCallback((vehicleId: String) => {
        return dispatch(doGetVehicle(vehicleId));
    },[dispatch]);

    const deleteVehicle = useCallback((vehicleId: String) => {
        return dispatch(doDeleteVehicle(vehicleId))
    },[dispatch]);

    return{
        vehicles,
        vehicle,
        listVehicles,
        createVehicle,
        editVehicle,
        getVehicle,
        deleteVehicle,
        isLoading,
        error
    }

}
export default useVehicles;