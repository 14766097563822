const amendmentTypeCodeCodeList = [
    {
        value: '20',
        label: '20 - Amend commodity code',
    },
    {
        value: '21',
        label: '21 - Amend value',
    },
    {
        value: '22',
        label: '22 - Amend tax line(s)',
    },
    {
        value: '23',
        label: '23 - Amend preference',
    },
    {
        value: '24',
        label: '24 - Amend quota',
    },
    {
        value: '25',
        label: '25 - Amend license',
    },
    {
        value: '26',
        label: '26 - Amend currency',
    },
    {
        value: '27',
        label: '27 - Amend CPC',
    },
    {
        value: '28',
        label: '28 - Amend weight or quantity',
    },
    {
        value: '29',
        label: '29 - Amend consignee/consignor',
    },
    {
        value: '30',
        label: '30 - Delete item',
    },
    {
        value: '31',
        label: '31 - Nil amendment',
    },
    {
        value: '32',
        label: '32 - Other',
    },
    {
        value: '33',
        label: '33 - Amend security',
    },
];

export default amendmentTypeCodeCodeList;
