import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { H5 } from 'components/ui/base/typography';
import styled from 'styled-components';

export const EDocketFormStyledComponents = {
    Main: styled('div')`
        > * + * {
            margin-top: 3.5rem;
        }
    `,
    ButtonContainer: styled('div')`
        width: 100%;

        display: flex;
        justify-content: right;
        gap: 2rem;
    `,
    SectionTitle: styled(H5)`
        margin-bottom: 1.5rem;
    `,
    SectionMain: styled('div')`
        display: flex;
        flex-direction: column;
    `,
    Input: styled(Input)`
        padding-top: 8px;
        padding-bottom: 8px;
    `,
    TextArea: styled(TextArea)`
        resize: none;
    `,
};

export const EDocketInfoStyledComponents = {
    Main: styled('div')`
        display: flex;

        > * + * {
            margin-left: 10rem;
        }
    `,
};

export const EDocketFormWeightStyledComponents = {
    DraggerImageContainer: styled('div')`
        display: flex;
        gap: 10px;
    `,
    ChooseImageWrapper: styled('div')`
        display: flex;
        flex-direction: column;
        justify-content: center;

        border: 1px dashed rgb(217, 217, 217);
        border-radius: 8px;
        background-color: rgb(250, 250, 250);
        padding: 2rem;
        transition: background-color 100ms;

        max-height: 90px;

        cursor: pointer;

        svg {
            font-size: 2rem;
        }

        :hover {
            background-color: rgb(239, 239, 239);
        }
    `,
    ModalDraggerContainer: styled('div')`
        display: flex;
        gap: 5px;
        margin-top: 2rem;
    `,
    ModalFooter: styled('div')`
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    `,
};
