import { Col, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { TextSmall, TextSmallBold } from '../typography/Typography.styles';

interface Props {
    label: string;
    value?: string;
    marginBottom?: number;
}

const Label = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

const StyledRow = styled(Row)<{ marginBottom: number }>`
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}rem` : 0)};
`;

export const LabelValue: FC<Props> = ({ label, value, marginBottom }) => {
    return (
        <StyledRow marginBottom={marginBottom ?? 0} wrap={false}>
            <Col>
                <Label>{label}:</Label>
            </Col>
            <Col>
                <TextSmall>{value ?? 'N/A'}</TextSmall>
            </Col>
        </StyledRow>
    );
};

export default LabelValue;
