import { ListPayload } from '../core/http/response';
import { GeneralizedProductTemplate, ProductTemplate } from '../store/products-templates/products';

const transformGeneralizedProductTemplateForClient = ({
    template,
    ...rest
}: GeneralizedProductTemplate): ProductTemplate => ({
    ...template,
    ...rest,
});

const transformGeneralizedListOfProductTemplatesForClient = ({
    pageNumber,
    pageSize,
    total,
    list,
}: ListPayload<GeneralizedProductTemplate>) => ({
    list: list.map(transformGeneralizedProductTemplateForClient),
    pageNumber,
    pageSize,
    total,
});

const transformGeneralizedProductTemplateForServer = ({
    id,
    customerId,
    declarationTemplateId,
    tags,
    type,
    declarationName,
    ...payload
}: ProductTemplate): GeneralizedProductTemplate => ({
    template: payload,
    id,
    customerId,
    declarationTemplateId,
    tags,
    type,
    declarationName,
});

export {
    transformGeneralizedProductTemplateForClient,
    transformGeneralizedListOfProductTemplatesForClient,
    transformGeneralizedProductTemplateForServer,
};
