import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { WeighbridgesActions } from './actionType';
import { Weighbridge } from './weighbridge';

export interface WeighbridgesState {
    weighbridges: ListPayload<Weighbridge>;
    isLoading: boolean;
    error?: ErrorResponse;
}

export const weighbridgesInitialState: Readonly<WeighbridgesState> = {
    isLoading: false,
    error: undefined,
    weighbridges: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const weighbridges: Reducer<WeighbridgesState, WeighbridgesActions> = (
    state = weighbridgesInitialState,
    action
): WeighbridgesState => {
    switch (action.type) {
        case 'LIST_WEIGHBRIDGES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_WEIGHBRIDGES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                weighbridges: action.payload,
            };
        case 'LIST_WEIGHBRIDGES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default weighbridges;
