import { TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const InfoDiv = styled.div<{ paddingLeft?: number }>`
    display: flex;
    margin-bottom: 0.8rem;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}rem` : 0)};
`;

export const Label = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

export const Icon = styled.img`
    margin-right: 1rem;
`;

export const StyledBoldText = styled(TextSmallBold)`
    margin-right: 2.35rem;
`;

export const StyledLink = styled.a`
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: center;
    color: ${colors.blueLink};
`;

export const JobsDiv = styled.div`
    margin-top: 8.4rem;
`;

export const JobsDivList = styled.div`
    margin-top: 1.7rem;
`;

export const ReferenceIdDiv = styled.div`
    min-width: 13rem;
`;

export const DetailsDiv = styled.div`
    margin-top: 1.6rem;
`;
