import { Radio, RadioGroupProps, RadioProps as AntRadioProps } from 'antd';

export interface RadioProps extends AntRadioProps {
    disabled?: boolean;
    defaultChecked?: boolean;
    checked?: boolean;
    autoFocus?: boolean;
    onClick?: (action: any) => void;
}

export interface GroupProps extends RadioGroupProps {}

const { Group } = Radio;

export const ARadio = (props: RadioProps) => <Radio {...props}>{props.children}</Radio>;

export const AGroup = (props: GroupProps) => <Group {...props}>{props.children}</Group>;
