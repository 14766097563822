import { useAppSelector } from 'config/hooks';
import { GlobalOverlayStyledComponents } from './GlobalOverlay.style';
import LoadingOverlay from './overlays/LoadingOverlay';

const { Main } = GlobalOverlayStyledComponents;

const Overlays = {
    LoadingOverlay: <LoadingOverlay />,
};

const GlobalOverlay = () => {
    const show = useAppSelector((state) => state.globalOverlay.show);
    const type = useAppSelector((state) => state.globalOverlay.type);

    return <Main show={show}>{type && Overlays[type]}</Main>;
};

export default GlobalOverlay;
