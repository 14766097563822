import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import { H5 } from 'components/ui/base/typography';
import useCustomers from 'hooks/useCustomers';
import useJourneys from 'hooks/useJourneys';
import useLegs from 'hooks/useLegs';
import useRoutes from 'hooks/useRoutes';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import { DetailsRow, RowLegs, RowTitle, StatusLabel } from './CargoJourneys.style';
import LegsTable from './components/LegsTable';
import { NEW_LEG } from './LegView';

const CargoJourneysDetails: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const { journey, error, isLoading, getJourney } = useJourneys({ journeyId: id });
    const { getLeg, deleteLeg, listLegs, legs } = useLegs({ journeyId: id! }); // FIXME remove assertion
    const { deleteRoute } = useRoutes();
    const [exporter, setExporter] = useState<Customer | undefined>(undefined);
    const [importer, setImporter] = useState<Customer | undefined>(undefined);
    const navigate = useNavigate();

    const { getCustomer } = useCustomers();

    useEffect(() => {
        getJourney(id!); // FIXME remove assertion
        listLegs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchExporter = async (customerId: string) => {
            const customer: Customer = await getCustomer(customerId);
            setExporter(customer);
        };

        const fetchImporter = async (customerId: string) => {
            const customer: Customer = await getCustomer(customerId);
            setImporter(customer);
        };

        if (journey?.consignorId && exporter?.id !== journey?.consignorId && !error) {
            fetchExporter(journey.consignorId);
        }

        if (journey?.consigneeId && importer?.id !== journey?.consigneeId && !error) {
            fetchImporter(journey.consigneeId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journey, exporter, importer, error]);

    const startDate = useMemo(() => {
        if (journey?.startDateTime) {
            const date = new Date(journey?.startDateTime);
            return `${date.toLocaleDateString()}  ${date.toLocaleTimeString([], { timeStyle: 'short' })}`;
        }
        return '-';
    }, [journey?.startDateTime]);

    const endDate = useMemo(() => {
        if (journey?.endDateTime) {
            const date = new Date(journey?.endDateTime);
            return `${date.toLocaleDateString()}  ${date.toLocaleTimeString([], { timeStyle: 'short' })}`;
        }
        return '-';
    }, [journey?.endDateTime]);

    const handleAddNewLeg = () => {
        navigate(`/cargo-journeys/${id}/legs/${NEW_LEG}`);
    };

    return (
        <Container>
            <RowTitle>
                <Col span={18}>
                    <H5>Journey Details</H5>
                </Col>
            </RowTitle>
            <DetailsRow>
                <StatusLabel>Journey id: </StatusLabel>
                {journey?.id}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Job id: </StatusLabel>
                {journey?.jobId}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Importer: </StatusLabel>
                {importer?.name || '-'}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Exporter: </StatusLabel>
                {exporter?.name || '-'}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Start Date: </StatusLabel>
                {startDate}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>End Date: </StatusLabel>
                {endDate}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Description: </StatusLabel>
                {journey?.description}
            </DetailsRow>
            <DetailsRow>
                <StatusLabel>Special Instructions: </StatusLabel>
                {journey?.specialInstructions}
            </DetailsRow>
            <RowLegs>
                <Row>
                    <Col span={18}>
                        <H5>Legs</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={handleAddNewLeg}>
                            Add New Leg
                        </Button>
                    </ReverseCol>
                </Row>
                <LegsTable
                    loading={isLoading}
                    data={legs.list}
                    onDelete={async (id: string) => {
                        const leg = await getLeg(id);

                        if (leg?.origin) {
                            await deleteRoute(leg?.origin.id);
                        }

                        if (leg?.destination) {
                            await deleteRoute(leg?.destination.id);
                        }

                        deleteLeg(leg.id);
                        listLegs();
                    }}
                    onDetails={(id: string) => {}}
                    onEdit={(legId: string) => navigate(`/cargo-journeys/${id}/legs/${legId}`)}
                />
            </RowLegs>
        </Container>
    );
};
export default CargoJourneysDetails;
