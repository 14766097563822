import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWeighings from 'hooks/useWeighings';
import FormInput from 'components/ui/composed/formInput/FormInput';
import useBreadcrumb from 'hooks/useBreadcrumb';
import {
    StyledLabel,
    StyledMobileWeighingView,
    StyledValue,
    StyledWeighingDetails,
    StyledWeightImage,
    SyledWeighingActionButton,
    WeighingFooterActions,
} from './MobileWeighingView.styles';
import { useFormik } from 'formik';
import { EditWeighingRequest } from 'store/weighbridges/weighing';

const MobileWeighingView: FC = () => {
    const { weighingId } = useParams();
    const navigate = useNavigate();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const { isLoading, errorEditingWeighing, weighing, getWeighing, editWeighing, isEditingWeighing } = useWeighings();
    const { setBreadcrumbRoutes } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbRoutes([{ breadcrumbName: 'Weighing', path: '' }]);
    }, [setBreadcrumbRoutes]);

    useEffect(() => {
        if (weighingId) {
            getWeighing(weighingId);
        }
    }, [getWeighing, weighingId]);

    const formik = useFormik<Partial<EditWeighingRequest>>({
        initialValues: {
            ...weighing,
        },
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (weighingId) {
                await editWeighing(weighingId, values);

                if (!errorEditingWeighing) {
                    console.log('weight edited successfuly');
                    // TODO show success and go to leg
                    navigate(`/mobile/legs/${weighing?.legId}`);
                }
            }
        },
    });

    const nextStep = useCallback(async () => {
        navigate(`/mobile/legs/${weighing?.legId}`);
    }, [navigate, weighing?.legId]);

    return (
        <StyledMobileWeighingView>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div>
                        {/* TODO this is probably a base64 image from the server so this should be updated when the real request is made */}
                        <StyledWeightImage src={weighing?.capturedWeightImage} alt="Weight reading" />
                        <StyledWeighingDetails>
                            <StyledLabel>Location</StyledLabel>
                            <StyledValue>{weighing?.locationName}</StyledValue>
                            <StyledLabel>Weight read on bridge</StyledLabel>
                            <StyledValue>{weighing?.autoWeight}</StyledValue>
                            {isIncorrect && (
                                <FormInput
                                    label="Enter new weight"
                                    fieldProps={formik.getFieldProps('manualWeight')}
                                    fieldMeta={formik.getFieldMeta('manualWeight')}
                                    fieldHelper={formik.getFieldHelpers('manualWeight')}
                                />
                            )}
                        </StyledWeighingDetails>
                    </div>
                    <WeighingFooterActions>
                        {/* FOOTER with buttons */}
                        {isIncorrect ? (
                            <>
                                <SyledWeighingActionButton
                                    onClick={() => setIsIncorrect(false)}
                                    type="dashed"
                                    disabled={isEditingWeighing}
                                >
                                    Cancel
                                </SyledWeighingActionButton>
                                <SyledWeighingActionButton
                                    onClick={() => formik.handleSubmit()}
                                    type="primary"
                                    loading={isEditingWeighing}
                                >
                                    Confirm
                                </SyledWeighingActionButton>
                                {errorEditingWeighing && <p>Error saving weight</p>}
                            </>
                        ) : (
                            <>
                                <SyledWeighingActionButton onClick={() => setIsIncorrect(true)} type="dashed">
                                    Incorrect weight
                                </SyledWeighingActionButton>
                                <SyledWeighingActionButton onClick={() => nextStep()} type="primary">
                                    Confirm
                                </SyledWeighingActionButton>
                            </>
                        )}
                    </WeighingFooterActions>
                </>
            )}
        </StyledMobileWeighingView>
    );
};

export default MobileWeighingView;
