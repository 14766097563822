import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDeclarations from '../../../../../hooks/useDeclarations';
import useProducts from '../../../../../hooks/useProducts';
import { AesInvalidError, AesRejectedError } from '../../../../../store/declarations/ireland/export-declaration';
import { subtractOneFromEveryFoundNumber } from '../../../../../utils/validationParserUtils';
import irelandExportValidationErrorsParser from '../../../ireland/export/validation/IrelandExportValidationErrorsParser';
import { goToDeclarationField } from '../../../utils/form-utils';
import ListErrorsRows from '../declaration-errors/ListErrorsRows';
import { Title } from '../DeclarationErrorsDrawer.styles';
import { ErrorRow } from './RevenueImportErrorDetails';

const pathPrefixesToLowerCase = (path: string) =>
    path
        .split('.')
        .map((val) => val.charAt(0).toLowerCase() + val.substring(1))
        .join('.');

const RevenueExportErrorDetails: FC<{
    details?: AesRejectedError & AesInvalidError;
    onClose?: () => void;
}> = ({ details, onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);
    const { products } = useProducts();
    const { declaration } = useDeclarations();

    const fields: string[] = useMemo(
        () =>
            details?.errorPointer
                ? [pathPrefixesToLowerCase(subtractOneFromEveryFoundNumber(details?.errorPointer))]
                : [],
        [details?.errorPointer]
    );

    const handleOnClickField = async (field: string) => {
        if (field) {
            if (field.includes('goodsShipment.goodsItem')) {
                const productNumber = Number(field.split('.')[2]) - 1;
                const product = products?.list.at(productNumber);
                field = field.split('.').slice(3).join('.');

                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
            } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
            }
            onClose?.();
            goToDeclarationField(field, 100);
        }
    };

    return (
        <>
            <Title>Error details</Title>
            <ErrorRow label="Code:" value={details?.errorCode ?? '-'} />
            <ErrorRow label="Value:" value={details?.originalAttributeValue ?? '-'} />
            <ListErrorsRows
                section="Fields"
                fields={fields ?? []}
                handleOnClickField={handleOnClickField}
                parseDeclaration={irelandExportValidationErrorsParser}
                itemsPath={'goodsShipment.goodsItem'}
            />
            {details?.errorReason && <ErrorRow label="Error Reason:" value={details?.errorReason ?? '-'} />}
            {details?.errorColumnNumber && (
                <ErrorRow label="Error Column Number:" value={details?.errorColumnNumber ?? '-'} />
            )}
            {details?.errorLineNumber && (
                <ErrorRow label="Error Line Number:" value={details?.errorLineNumber ?? '-'} />
            )}
            {details?.errorText && <ErrorRow label="Error Text:" value={details?.errorText ?? '-'} />}
            <ErrorRow label="External Field:" value={details?.errorPointer ?? '-'} />
        </>
    );
};

export default RevenueExportErrorDetails;
