import { H6Style } from 'components/ui/base/typography/Typography.styles';
import useCountries from 'hooks/useCountries';
import { FC } from 'react';
import { Individual } from 'store/individuals/individual';
import { GreyText } from './IndividualDetails.styles';

interface Props {
    individual?: Individual;
}

const IndividualsDetails: FC<Props> = ({ individual }) => {
    const { getNameFromCountryCode } = useCountries();

    return (
        <>
            <H6Style>Basic Details</H6Style>

            <div style={{ marginTop: '1.6rem' }}>
                {individual?.fullName && <GreyText>Name: {individual?.fullName}</GreyText>}
                {individual?.address && <GreyText>Address:</GreyText>}
                {individual?.address?.addressLine1 && (
                    <GreyText style={{ paddingLeft: '2rem' }}>{individual?.address.addressLine1}</GreyText>
                )}
                {individual?.address?.addressLine2 && (
                    <GreyText style={{ paddingLeft: '2rem' }}>{individual?.address.addressLine2}</GreyText>
                )}
                {individual?.address?.postCode && <GreyText>PostCode: {individual?.address.postCode}</GreyText>}
                {individual?.address?.country && (
                    <GreyText>Country: {getNameFromCountryCode(individual?.address.country)}</GreyText>
                )}
                {individual?.address?.city && <GreyText>City: {individual?.address.city}</GreyText>}
                {individual?.email && <GreyText>Email: {individual?.email}</GreyText>}
                {individual?.phone && <GreyText>Phone number: {individual?.phone}</GreyText>}
            </div>
        </>
    );
};
export default IndividualsDetails;
