import { cloneDeep, merge } from 'lodash';
import { irelandExportB1ValidationHeader, irelandExportB1ValidationItem } from './IrelandExportB1Validation';
import DeepPartial from 'types/DeepPartial';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        statisticalValue: REMOVE,
        natureOfTransaction: REMOVE,
        commodity: {
            childValidators: {
                goodsMeasure: {
                    childValidators: {
                        supplementaryUnits: REMOVE,
                    },
                },
            },
        },
    },
};
export const irelandExportC1ValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        exportOperation: {
            childValidators: {
                invoiceCurrency: REMOVE,
                totalAmountInvoiced: REMOVE,
            },
        },
        goodsShipment: {
            childValidators: {
                natureOfTransaction: REMOVE,
                warehouse: REMOVE,
                deliveryTerms: REMOVE,
                consignment: {
                    childValidators: {
                        containerIndicator: REMOVE,
                        modeOfTransportAtTheBorder: REMOVE,
                        inlandModeOfTransport: REMOVE,
                        departureTransportMeans: REMOVE,
                        activeBorderTransportMeans: REMOVE,
                        transportEquipment: {
                            childValidators: {
                                goodsReference: REMOVE,
                            },
                        },
                    },
                },
                goodsItem: irelandExportC1ValidationItem,
            },
        },
    },
};
export const irelandExportC1ValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
