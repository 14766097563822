import { Col, Dropdown, Menu, Row, notification, message } from 'antd';
import Button from 'components/ui/base/button/Button';
import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import BulkUpload, { BulkUploadUploadFunc, FileType } from 'components/ui/composed/bulkUpload/BulkUpload';
import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import useCustomers from 'hooks/useCustomers';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import {
    downloadIrelandH1ImportDeclarationSubmissionDetails,
    listDeclarations as listDeclarationsReq,
} from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { IrelandExportMessageType } from 'store/declarations/enums/ireland/message-type';
import { IrelandDeclarationName } from 'store/declarations/ireland/ireland-declaration-name';
import { JobResponse } from 'store/jobs/job';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import { getDeclarationName } from 'views/declarations/utils/declaration-utils';
import { UkImportDeclarationName } from '../../store/declarations/uk/uk-declaration-name';
import {
    CloseIcon,
    ColButtonFillForm,
    Container,
    CustomRow,
    DownIcon,
    FileIcon,
    InvoiceButton,
    LeftRow,
    ReverseCol,
    SectionTitle,
    TitleRow,
} from './components/InvoiceUpload.styles';
import SelectCustomer from './components/SelectCustomer';
import SelectFormType from './components/SelectFormType';
import SelectJob from './components/SelectJob';
import { createDeclarations, createOrDuplicateDeclarations, getDeclarationNameLabels, getMessageType } from './utils';
import { getTemplates } from 'store/template/client';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import axiosClient from '../../config/axios';
import config from 'config/config';
import { SuccessResponse } from 'core/http/response';
import SelectInvoiceFile from './components/SelectInvoiceFile';
import { uploadFile as upload } from '../declarations/sections/customer-documents/components/UploadUtils';
import { confirmUploadFile as confirmUpload, documentsPreUpload as preUpload } from '../../store/documents/client';
import { RcFile } from 'antd/lib/upload';
import { createDocumentAis as attachInvoiceToDeclaration } from '../../store/file-upload/client';
import { getDeclarationsFromJob } from '../dashboard/components/utils';
import { union } from 'lodash';
import {
    AESMessageTypes,
    AISMessageTypes,
    CDSMessageTypes,
    ENSMessageTypes,
    MessageTypes,
} from '../../store/declarations/enums/common/template-types';

const BrokerInvoiceUpload: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();
    const { country, type, jobId } = useParams<{
        country: DeclarationCountry;
        type: DeclarationInternalType;
        jobId: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { customers, listCustomers } = useCustomers();
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);
    const [showCreateCustomer, setShowCreateCustomer] = useState(false);
    const [myDeclaration, setMyDeclaration] = useState<Declaration | undefined>(undefined);
    const {
        createIrelandImportDeclaration,
        createIrelandH1ImportDeclarationWithFile,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkExportDeclaration,
        createEntrySummaryDeclaration,
        createElectronicTransportDocument,
        createTemporaryStorageDeclaration,
        createArrivalAtExitDeclaration,
        createUkImportNewDeclaration,
        duplicateDeclaration,
        getDeclaration,
    } = useDeclarations();
    const { job: savedJob, createJob, listJobs, jobs, editJob } = useJobs({ jobId });
    const [selectedJob, setSelectedJob] = useState<JobResponse | undefined>(savedJob);
    const [selectedFormType, setSelectedFormType] = useState<string | undefined>(undefined);
    const [declarationFromJobs, setDeclarationFromJobs] = useState<{
        [key: string]: Declaration[];
    }>({});
    const [fillForm, setFillForm] = useState(false);
    const [duplicateDeclarationId, setDuplicateDeclarationId] = useState<string>();
    const [mirrorDeclarationId, setMirrorDeclarationId] = useState<string | null>(null);
    const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState<RcFile | null>(null);

    const locationState = useMemo(
        () => location.state as { declaration?: Declaration; job?: JobResponse; mirroring?: Boolean },
        [location.state]
    );

    const [h1SubmissionDetailsFile, setH1SubmissionDetailsFile] = useState<File>();

    const isEnsDeclaration = useMemo(
        () => (country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ENS ? true : false),
        [country, type]
    );

    const isTsdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.TSD,
        [country, type]
    );

    const isEtdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ETD,
        [country, type]
    );

    const isArrivalDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ARRIVAL,
        [country, type]
    );

    const disabledFillForm = useMemo(
        () => !selectedJob || !selectedCustomer || !selectedFormType,
        [selectedJob, selectedCustomer, selectedFormType]
    );

    const [formTypeDisabled, setFormTypeDisabled] = useState(false);

    const disableFormType = useMemo(
        () => !!duplicateDeclarationId || !!myDeclaration || formTypeDisabled,
        [duplicateDeclarationId, formTypeDisabled, myDeclaration]
    );

    const hasCreatedDeclaration = useMemo(() => !!myDeclaration, [myDeclaration]);

    const isH1FormTypeSelected = useMemo(() => selectedFormType === '(H1) Free Circulation', [selectedFormType]);

    const callbackGetDeclarationNameLabels = useCallback(() => {
        return getDeclarationNameLabels(country!, type!); // FIXME remove assertions
    }, [country, type]);

    const hasTemplatesImplementation = (
        country: DeclarationCountry,
        type: DeclarationInternalType,
        selectedFormType: any
    ) => {
        const hasAisTemplates =
            type === DeclarationInternalType.IMPORT && Object.values(AISMessageTypes).includes(selectedFormType);
        const hasAesTemplates =
            type === DeclarationInternalType.EXPORT && Object.values(AESMessageTypes).includes(selectedFormType);
        const hasEnsTemplate = type === DeclarationInternalType.ENS;
        const hasCdsB1Template =
            country === DeclarationCountry.UK &&
            type === DeclarationInternalType.EXPORT &&
            Object.values(CDSMessageTypes).includes(selectedFormType);

        return (
            (country === DeclarationCountry.IRELAND && (hasAisTemplates || hasEnsTemplate || hasAesTemplates)) ||
            hasCdsB1Template
        );
    };

    const [templates, setTemplates] = useState<TemplateResponse[] | undefined>(undefined);
    const [template, setTemplate] = useState<TemplateResponse | undefined>(undefined);
    useEffect(() => {
        if (!country || !type || !selectedFormType) {
            setTemplates(undefined);
            return;
        }
        const _formType =
            country === DeclarationCountry.IRELAND ? selectedFormType?.match(/\((.*)\)/)?.[1]! : selectedFormType;

        if (!hasTemplatesImplementation(country, type, _formType)) {
            setTemplates(undefined);
            return;
        }

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading template data...' } });
        getTemplates(country!, type!.toLowerCase() as 'import' | 'export', _formType as MessageTypes, {
            size: 9999,
        })
            ?.then((payload) => setTemplates(payload.list))
            .finally(() => {
                hideGlobalOverlay();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormType]);

    useEffect(() => {
        if (type === DeclarationInternalType.ENS) setSelectedFormType('ens');
    }, [type, setSelectedFormType]);

    const handleTemplateSelect = (templateId: string | undefined) => {
        if (templateId) {
            setTemplate(templates?.find(({ id }) => id === templateId));
        }
    };

    const templateInputDisabled = useMemo(() => {
        const previousInformation = locationState;
        return (
            !!previousInformation?.declaration?.templateId ||
            !union(
                Object.values(AISMessageTypes),
                Object.values(AESMessageTypes),
                Object.values(CDSMessageTypes),
                ENSMessageTypes.ENS
            ).some((at) => selectedFormType?.includes(at)) ||
            !selectedCustomer ||
            !selectedJob ||
            !templates?.length
        );
    }, [selectedFormType, locationState, selectedCustomer, selectedJob, templates?.length]);

    useEffect(() => {
        if (!customers.list.length) listCustomers({ size: 200 });
        if (!jobs.list.length) listJobs({ size: 200 });

        if (
            !jobs.list.length ||
            !customers.list.length ||
            !locationState?.declaration ||
            !locationState?.declaration?.customerId
        ) {
            return;
        }

        handleSelectCustomer(locationState.declaration.customerId);

        if (locationState.mirroring) {
            setMirrorDeclarationId(locationState.declaration.id ?? null);
            // ! Temporary until other mirroring options are available
            // TODO Mirroring - Remove when necessary
            setFormTypeDisabled(true);
            setSelectedFormType('H1');
        } else {
            handleTemplateSelect(locationState.declaration.templateId);
            const name = getDeclarationName(locationState.declaration);
            callbackGetDeclarationNameLabels().forEach((a: any) => {
                if (a.key === name) return setSelectedFormType(a.value);
            });
            setDuplicateDeclarationId(locationState.declaration.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState, jobs, customers, templates]);

    const getDeclarations = useCallback(() => {
        return getDeclarationsFromJob(jobs, listDeclarationsReq, setDeclarationFromJobs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs]);

    useEffect(() => {
        if (jobs.list) {
            getDeclarations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, getDeclarations]);

    useEffect(() => {
        if (!(jobId && jobs)) return;

        const selectedJobByParams = jobs.list.find((value) => value.id === jobId);
        if (!selectedJobByParams) return;

        setSelectedJob(selectedJobByParams);
    }, [jobId, jobs]);

    const handleSelectCustomer = useCallback(
        async (value: string) => {
            const element = customers.list.find((e) => e.id === value);
            const newJob = { ...selectedJob } as JobResponse;
            if (element?.id) {
                setSelectedCustomer(element);
                if (newJob?.id) {
                    newJob.customerId = element?.id;
                    editJob(newJob.id, newJob);
                    setSelectedJob(newJob);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customers, selectedJob, editJob]
    );

    const handleSelectJob = useCallback(
        async (id: string) => {
            const j = jobs.list.find((e) => e.id === id);
            if (j) {
                setSelectedJob(j);
                navigate(`/invoice-upload/${country}/${type}/job/${j.id}`, { state: { j } });
                if (selectedCustomer?.id && j?.id) {
                    const newJob = { ...j } as JobResponse;
                    newJob.customerId = selectedCustomer.id;
                    editJob(newJob.id, newJob);
                    setSelectedJob(newJob);
                }
            }
        },
        [jobs, country, editJob, navigate, selectedCustomer?.id, type]
    );

    const createNewJob = async () => {
        const job = (await createJob()) as JobResponse;
        setSelectedJob(job);
        navigate(`/invoice-upload/${country}/${type}/job/${job.id}`, { state: { job } });
    };

    const createDeclaration = async (
        country: DeclarationCountry,
        customerId: string,
        jobId: string,
        messageType?: IrelandDeclarationName | IrelandExportMessageType | UkImportDeclarationName
    ) => {
        return createDeclarations(
            country,
            customerId,
            jobId,
            type!, // FIXME remove assertion
            createIrelandH7ImportDeclaration,
            createIrelandImportDeclaration,
            createEntrySummaryDeclaration,
            createTemporaryStorageDeclaration,
            createIrelandExportDeclaration,
            createElectronicTransportDocument,
            createUkExportDeclaration,
            createUkImportNewDeclaration,
            createArrivalAtExitDeclaration,
            messageType,
            template
        );
    };

    const labelTitle = useMemo(() => {
        return t(`create${country}Declaration${type}`);
    }, [country, type, t]);

    useEffect(() => {
        if (myDeclaration && fillForm) {
            setFillForm(false);
            navigate(`/declarations/${myDeclaration.id}`, { state: { comingFromCreateDecPage: true } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillForm, myDeclaration]);

    const handleFillFormClick = () => {
        if (myDeclaration) return;

        if (isH1FormTypeSelected && h1SubmissionDetailsFile) {
            handleCreateIrelandH1ImportDeclarationWithFile();
        } else {
            handleCreateDeclaration().then(handleCreateInvoice);
        }

        setFillForm(true);
    };

    const handleCreateInvoice = async (createdDeclaration: Partial<Declaration> | undefined) => {
        if (!uploadedInvoiceFile || !createdDeclaration?.id || !createdDeclaration?.customerId) return;

        const preAndConfirmUploadPayload = {
            filename: uploadedInvoiceFile.name,
            fileSize: uploadedInvoiceFile.size,
            customerId: createdDeclaration.customerId,
            declarationId: createdDeclaration.id,
        };

        try {
            const { preSignedUrl } = await preUpload(preAndConfirmUploadPayload);
            await upload(uploadedInvoiceFile, preSignedUrl);
            const { payload: confirmUploadPayload } = (await confirmUpload(
                preAndConfirmUploadPayload
            )) as unknown as any;

            await attachInvoiceToDeclaration(
                {
                    fileId: confirmUploadPayload.id,
                    fileReference: confirmUploadPayload.link,
                    fileName: confirmUploadPayload.filename,
                    fileInfo: {
                        documentType: confirmUploadPayload.contentType,
                    },
                    invoice: true,
                },
                confirmUploadPayload.declarationId
            );
            await getDeclaration(createdDeclaration.id);
        } catch (e) {
            message.error(
                'Invoice uploading failed! Please create a new declaration in order to attach an invoice to it.',
                5
            );
        }
    };

    const handleCreateIrelandH1ImportDeclarationWithFile = async () => {
        const _jobId = jobId ?? selectedJob?.id;
        const _customerId = selectedCustomer?.id;
        if (!_jobId || !_customerId || !h1SubmissionDetailsFile) {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'Please select Job, Customer, and a Submission Details File',
            });

            return;
        }

        const response = await createIrelandH1ImportDeclarationWithFile(h1SubmissionDetailsFile, _jobId, _customerId);

        if (response) {
            Notification({
                type: 'success',
                messageTitle: 'Declaration created',
                description: 'Successfully created declaration',
            });
            setMyDeclaration(response);
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'An error occurred while creating a declaration',
            });
        }
    };

    const addNewCustomer = (newCustomer: Customer) => {
        const job = { ...selectedJob } as JobResponse;
        if (job && newCustomer.id) {
            job.customerId = newCustomer?.id;
            editJob(job.id, job);
            setSelectedJob(job);
        }
        setSelectedCustomer(newCustomer);
        setShowCreateCustomer(false);
    };

    const mirrorDeclaration = async () => {
        if (!mirrorDeclarationId) throw new Error('No declaration to be mirrored');

        /**
         * ! Currently working only for B1 -> H1.
         * * When other declaration types are needed,
         * * this request will have to be updated
         */
        // TODO Mirroring - Handle for other declaration types when necessary
        try {
            const response = await axiosClient.post<SuccessResponse<Declaration>>(
                `${config.declarationsUrl}/declarations/${mirrorDeclarationId}/mirror`
            );
            return setMyDeclaration(response.data.payload);
        } catch (error) {
            notification.error({ message: 'An error occurred while trying to mirror a declaration!' });
            console.error(error);
        }
    };

    const handleCreateDeclaration = async () => {
        if (myDeclaration) return;
        let messageType;

        if (!isEnsDeclaration && !isTsdDeclaration && !isArrivalDeclaration && selectedFormType) {
            messageType = getMessageType(country!, type!, selectedFormType); // FIXME remove assertions
        }

        const jId = jobId ?? selectedJob?.id;
        const customerId = selectedCustomer?.id;

        if (locationState.mirroring) {
            mirrorDeclaration();
            return;
        }

        return await createOrDuplicateDeclarations(
            duplicateDeclaration,
            createDeclaration,
            jId!, // FIXME remove assertion
            setMyDeclaration,
            country!, // FIXME remove assertion
            customerId,
            duplicateDeclarationId,
            messageType
        );
    };

    const querySearchJob = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs({ size: 200 });
        }
    };

    const debouncedSearchJob = debounce((query: string) => querySearchJob(query), 500);
    const querySearchCustomer = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
        } else {
            await listCustomers({ size: 200 });
        }
    };

    const debouncedSearchCustomers = debounce((query: string) => querySearchCustomer(query), 500);

    const handleUpload: BulkUploadUploadFunc = ({ file }) => {
        setH1SubmissionDetailsFile(file as File);
        return true;
    };

    const handleDownload = () => {
        return downloadIrelandH1ImportDeclarationSubmissionDetails();
    };

    const handleDelete = () => {
        setH1SubmissionDetailsFile(undefined);
    };

    return (
        <Container>
            <TitleRow>
                <Col span={18}>
                    <H5>{labelTitle}</H5>
                </Col>
                <ColButtonFillForm span={6}>
                    <Button size="large" disabled={disabledFillForm} type="primary" onClick={handleFillFormClick}>
                        {t('fillForm')}
                    </Button>
                </ColButtonFillForm>
            </TitleRow>

            <LeftRow gutter={32}>
                <Col span={12}>
                    <SelectJob
                        disabled={hasCreatedDeclaration}
                        job={selectedJob}
                        jobs={jobs?.list}
                        createNewJob={createNewJob}
                        handleSelectJob={handleSelectJob}
                        setJob={setSelectedJob}
                        setCustomer={setSelectedCustomer}
                        declarationFromJobs={declarationFromJobs}
                        search={(e) => debouncedSearchJob(e)}
                    />

                    <SelectCustomer
                        disabled={hasCreatedDeclaration}
                        customer={selectedCustomer}
                        customers={customers?.list}
                        job={selectedJob}
                        setShowCreateCustomer={setShowCreateCustomer}
                        handleSelectCustomer={handleSelectCustomer}
                        setCustomer={setSelectedCustomer}
                        search={(e) => debouncedSearchCustomers(e)}
                    />
                    {!isEnsDeclaration && !isTsdDeclaration && !isEtdDeclaration && !isArrivalDeclaration && (
                        <SelectFormType
                            customer={selectedCustomer}
                            job={selectedJob}
                            setFormType={setSelectedFormType}
                            declarationNameLabels={callbackGetDeclarationNameLabels()}
                            disableFormType={!selectedCustomer || !selectedJob || disableFormType}
                            formType={selectedFormType}
                        />
                    )}

                    {!isTsdDeclaration && !isEtdDeclaration && !isArrivalDeclaration && (
                        <CustomRow>
                            <Col span={16}>
                                <SectionTitle className={`${templateInputDisabled && 'disabled'}`}>
                                    {t('selectTemplate')}
                                </SectionTitle>
                            </Col>
                            <ReverseCol span={8}>
                                <Dropdown
                                    disabled={templateInputDisabled}
                                    overlayStyle={{
                                        overflow: 'auto',
                                        height: 250,
                                        filter: `drop-shadow(0 5px 10px rgba(9, 0, 255, 0.09))`,
                                    }}
                                    overlay={
                                        <Menu onClick={(item) => handleTemplateSelect(item.key)}>
                                            {templates?.map((template) => (
                                                <Menu.Item key={template.id}>{template.templateName}</Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                >
                                    <InvoiceButton size="large">
                                        <DownIcon /> {t('selectTemplate')}
                                    </InvoiceButton>
                                </Dropdown>
                            </ReverseCol>
                            {template && (
                                <Row align="middle">
                                    <CloseIcon
                                        className={`${templateInputDisabled && 'disabled'}`}
                                        onClick={() => {
                                            if (!templateInputDisabled) {
                                                setTemplate(undefined);
                                            }
                                        }}
                                        disabled={templateInputDisabled}
                                    />
                                    <FileIcon /> {template.templateName}
                                </Row>
                            )}
                        </CustomRow>
                    )}

                    <SelectInvoiceFile
                        disabled={disabledFillForm || !isH1FormTypeSelected}
                        onUploadedInvoiceFile={setUploadedInvoiceFile}
                        uploadedInvoiceFile={uploadedInvoiceFile}
                    />

                    <div>
                        <SectionTitle className={`${!isH1FormTypeSelected ? 'disabled' : ''}`}>
                            Bulk upload submission details (optional)
                        </SectionTitle>
                        <BulkUpload
                            showFile
                            onDownload={handleDownload}
                            onUpload={handleUpload}
                            onDelete={handleDelete}
                            fileType={[FileType.XLS, FileType.XLSX]}
                            disabled={!isH1FormTypeSelected}
                        />
                    </div>
                </Col>
            </LeftRow>
            <Drawer
                title="Add New Customer"
                width="627"
                visible={showCreateCustomer}
                onClose={() => setShowCreateCustomer((oldState) => !oldState)}
            >
                <CreateNewCustomer
                    refreshCustomers={listCustomers}
                    closeModal={() => setShowCreateCustomer(false)}
                    handleOk={addNewCustomer}
                />
            </Drawer>
        </Container>
    );
};
export default BrokerInvoiceUpload;
