import { DeclarationFilter } from './DeclarationFilter';

export const doSetDeclarationFilter: Function = (declarationFilter: DeclarationFilter) => (dispatch: Function) => {
    dispatch({
        type: 'SET_DECLARATION_FILTER',
        declarationFilter,
    });
};
export const doClearDeclarationFilter: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_DECLARATION_FILTER' });
};
