import LabelValue from 'components/ui/base/label-value/LabelValue';
import { TextExtraSmall } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Individual, individualTypeLabel } from 'store/individuals/individual';
import { colors } from 'theme';
import {
    CheckIcon,
    CloseIcon,
    DeleteDiv,
    TeamMemberInfoDiv,
    TeamMemberTitle,
} from 'views/authentication/invite/Invite.styles';

interface Props {
    index: number;
    member: Individual;
    remove: () => void;
    results?: (Individual | null)[];
}

const TeamMember: FC<Props> = ({ index, member, remove, results }) => {
    const { t } = useTranslation('onboarding');

    const handleMember = useMemo(() => {
        const user = (results ?? [])[index];
        return user === null || user === undefined ? (
            <>
                <DeleteDiv>
                    <CloseIcon onClick={() => remove()} />
                    <div>
                        <LabelValue label="Role" value={individualTypeLabel.find((e) => e.id === member.type)?.value} />
                        <LabelValue label="Email" value={member.email} />
                        {user === null && (
                            <TextExtraSmall style={{ color: colors.error }}>Email already exist!</TextExtraSmall>
                        )}
                        <LabelValue label="Phone" value={member.phone} />
                    </div>
                </DeleteDiv>
            </>
        ) : (
            <DeleteDiv>
                <CheckIcon />
                <div>
                    <LabelValue label="Role" value={individualTypeLabel.find((e) => e.id === member.type)?.value} />
                    <LabelValue label="Email" value={member.email} />
                    <LabelValue label="Phone" value={member.phone} />
                </div>
            </DeleteDiv>
        );
    }, [results, member, remove, index]);

    return (
        <TeamMemberInfoDiv>
            <TeamMemberTitle>{`${t('team_member')} ${index + 1}`}</TeamMemberTitle>
            {handleMember}
        </TeamMemberInfoDiv>
    );
};

export default TeamMember;
