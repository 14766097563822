import { Col, Spin } from 'antd';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import {
    BtSubmit,
    CollapsedDiv,
    CollapseFooter,
    CollapseIcon,
    ExpandIcon,
    FooterButton,
    FooterRow,
    SaveProductButton,
    Saving,
    SubmitDiv,
} from 'views/declarations/footer/Footer.styles';
import { SubmitLoading } from 'views/declarations/Form.styles';

interface Props {
    isSubmiting?: boolean;
    isSaving?: boolean;
    cancelSaveProduct: Function;
    saveProduct: Function;
    disabled?: boolean;
    saveDraft: Function;
    cancelAmendment: Function;
    submitAmendment: Function;
    declaration: Declaration;
}

const AmendmentFooter: FC<Props> = ({
    isSubmiting = false,
    isSaving = false,
    cancelSaveProduct,
    cancelAmendment,
    disabled = false,
    declaration,
    saveProduct,
    saveDraft,
    submitAmendment,
}) => {
    const { declarationFooter, collapsed, expandDeclarationFooter, collapseDeclarationFooter } = useDeclarationFooter();
    const { t } = useTranslation('common');
    const [renderSave, setRenderSave] = useState(isSaving);

    const savingMessage = useMemo(() => {
        if (isSubmiting) {
            return <Saving>{t('footer.submitting')}</Saving>;
        } else if (renderSave) {
            return <Saving>{t('footer.saving')}</Saving>;
        }

        return <></>;
    }, [renderSave, isSubmiting, t]);

    useEffect(() => {
        setRenderSave(isSaving);
    }, [isSaving]);

    const footerButtons = useMemo(() => {
        return declarationFooter === DeclarationFooterType.PRODUCTS ? (
            <Col>
                {renderSave && <Saving>{t('footer.saving')}</Saving>}
                <FooterButton onClick={() => cancelSaveProduct()}>{t('footer.backToProducts')}</FooterButton>
                {!disabled && (
                    <SaveProductButton
                        onClick={async () => {
                            await saveProduct();
                        }}
                    >
                        {t('footer.saveProduct')}
                    </SaveProductButton>
                )}
            </Col>
        ) : (
            <Col>
                <FooterButton onClick={() => cancelAmendment()}>Cancel Amendment</FooterButton>
                <FooterButton onClick={() => saveDraft()}>Save Draft</FooterButton>
                <BtSubmit type="primary" onClick={() => submitAmendment()}>
                    {t('footer.submit')} {isSubmiting && <Spin indicator={<SubmitLoading spin />} />}
                </BtSubmit>
            </Col>
        );
    }, [
        declarationFooter,
        cancelAmendment,
        saveDraft,
        submitAmendment,
        saveProduct,
        cancelSaveProduct,
        disabled,
        isSubmiting,
        renderSave,
        t,
    ]);

    return (
        <>
            <SubmitDiv>
                {!collapsed ? (
                    <FooterRow justify="space-between" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                        <CollapseFooter onClick={() => collapseDeclarationFooter()}>
                            <CollapseIcon /> {t('footer.hideFooter')}
                        </CollapseFooter>
                        {footerButtons}
                    </FooterRow>
                ) : (
                    <FooterRow align="middle" padding="0.1rem 0">
                        <Col span={12}>
                            <CollapsedDiv>
                                <CollapseFooter onClick={() => expandDeclarationFooter()}>
                                    <ExpandIcon /> {t('footer.viewFooter')}
                                </CollapseFooter>
                            </CollapsedDiv>
                        </Col>
                        {(isSubmiting || renderSave) && (
                            <Col span={12}>
                                <CollapsedDiv>{savingMessage}</CollapsedDiv>
                            </Col>
                        )}
                    </FooterRow>
                )}
            </SubmitDiv>
        </>
    );
};

export default AmendmentFooter;
