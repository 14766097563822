import { PaginatedParams } from 'core/http/pagination';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import { doCreateIndividual, doEditIndividual, doGetIndividual, doListIndividuals } from '../store/individuals/action';
import { Individual } from '../store/individuals/individual';

interface UseIndividualsProps {
    individualId?: string;
}

const useIndividuals = ({ individualId }: UseIndividualsProps = {}) => {
    const individuals = useAppSelector((state) => state.individuals.individuals);
    const individual = useAppSelector((state) => (individualId ? state.individuals.entities[individualId] : null));
    const createSuccess = useAppSelector((state) => state.individuals.createSuccess);
    const isLoading = useAppSelector((state) => state.individuals.isLoading);
    const error = useAppSelector((state) => state.individuals.error);

    const dispatch = useAppDispatch();

    const createIndividual = useCallback(
        (individual: Individual) => dispatch(doCreateIndividual(individual)),
        [dispatch]
    );

    const listIndividuals = useCallback(
        (params?: Partial<PaginatedParams>) => dispatch(doListIndividuals(params)),
        [dispatch]
    );

    const getIndividual = useCallback(
        (individualId: string) => {
            return dispatch(doGetIndividual(individualId));
        },
        [dispatch]
    );

    const editIndividual = useCallback(
        (individualId: string, individual: Individual) => dispatch(doEditIndividual(individualId, individual)),
        [dispatch]
    );

    useEffect(() => {
        if (individualId) {
            getIndividual(individualId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        individuals,
        individual,
        createSuccess,
        createIndividual,
        listIndividuals,
        getIndividual,
        editIndividual,
    };
};

export default useIndividuals;
