import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useHideEntities from '../../../../../hooks/useHideEntities';
import { FormCardContainer } from '../../../common/cards/NewFormCard';
import { DeclarationFormCardProps } from '../../../common/declaration.form.card';
import MultipleItemsCard from '../../../common/MultipleItemsCard';
import AdditionsDeductionsBlock from '../blocks/AdditionsDeductionsBlock';

const AdditionsDeductionsCard = ({
    viewOnly,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    toggleHelp,
    cardTotal,
    propsPathPrefix,
    keyCard,
}: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <MultipleItemsCard
            title={t('AdditionsAndDeductions')}
            keyCard={keyCard}
            viewOnly={viewOnly}
            path={propsPathPrefix!}
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            getFieldHelpers={getFieldHelpers}
            toggleHelp={toggleHelp}
            cardTotal={cardTotal}
            cardNumber={24}
            initialValues={{
                otherChargeDeductionAmount: '',
                otherChargeDeductionAmountCurrencyId: '',
                chargesTypeCode: '',
            }}
            list={(list) => [
                {
                    field: t('value'),
                    value: list.otherChargeDeductionAmount,
                },
                {
                    field: t('currency'),
                    value: list.otherChargeDeductionAmountCurrencyId,
                },
                {
                    field: t('type'),
                    value: list.chargesTypeCode,
                },
            ]}
            hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            condensed
            refNumber="4/9"
        >
            {(path) => (
                <FormCardContainer>
                    <AdditionsDeductionsBlock path={path} />
                </FormCardContainer>
            )}
        </MultipleItemsCard>
    );
};

export default AdditionsDeductionsCard;
