import { getCustomerValidation } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const goodsInformationEtdValidation = Yup.object().shape({
    grossMass: Yup.string().max(16).nullable(),
    totalPackageNumber: Yup.string().max(8).nullable(),
});

const etdGoodsInformationDeclarationCustomsOfficeValidation = Yup.object().shape({
    customsOfficeLodgement: Yup.string().max(8).min(8).nullable(),
    customsOfficeOfDestination: Yup.string().max(8).min(8).nullable(),
});

const etdGoodsInformationDeclarationPartiesValidation = Yup.object().shape({
    representative: getCustomerValidation().nullable(),
    holderOfTheTransitProcedure: getCustomerValidation().nullable(),
});

const declarationEtdValidation = Yup.object().shape({
    goodsInformation: goodsInformationEtdValidation,
    customsOffices: etdGoodsInformationDeclarationCustomsOfficeValidation,
    parties: etdGoodsInformationDeclarationPartiesValidation,
});

const goodsShipmentEtdDocumentsValidation = Yup.object().shape({
    ucr: Yup.string().max(35).nullable(),
    simplifiedDeclarationDocumentWritingOff: Yup.array(
        Yup.object().shape({
            previousDocumentType: Yup.string().max(3).min(1).nullable(),
            previousDocumentIdentifier: Yup.string().max(35).min(1).nullable(),
            previousDocumentLineId: Yup.string().max(35).min(1).nullable(),
        })
    ).nullable(),
    additionalInformation: Yup.array(
        Yup.object().shape({
            additionalInformationCode: Yup.string().max(5).min(5).nullable(),
            additionalInformationText: Yup.string().max(512).min(1).nullable(),
        })
    ).nullable(),
    producedDocumentsWritingOff: Yup.array(
        Yup.object().shape({
            documentType: Yup.string().max(4).min(4).nullable(),
            documentIdentifier: Yup.string().max(35).min(1).nullable(),
        })
    ).nullable(),
});

const supplyChainActor = Yup.object().shape({
    roleCode: Yup.string().max(3).nullable(),
    traderIdentification: Yup.string().max(17).min(1).nullable(),
});

const goodsShipmentEtdPartiesValidation = Yup.object().shape({
    consignee: getCustomerValidation().nullable(),
    supplyChainActor: Yup.array().of(supplyChainActor),
});

const goodsShipmentEtdDatesPlacesValidation = Yup.object().shape({
    countryDestination: Yup.string().max(2).min(2).nullable(),
    transportationPlace: Yup.object().shape({
        unlocode: Yup.string().max(17).min(1).nullable(),
        countryCode: Yup.string().max(2).min(2).nullable(),
        place: Yup.string().max(35).min(1).nullable(),
    }),
});

const goodsShipmentEtdTransportInformation = Yup.object().shape({
    departureTransportMeans: Yup.object().shape({
        identificationType: Yup.string().max(2).min(2).nullable(),
        identificationNumber: Yup.string().max(35).min(1).nullable(),
    }),
    nationality: Yup.string().max(2).min(2).nullable(),
    containerIdentificationNumber: Yup.array()
        .of(
            Yup.object().shape({
                containerIdentificationNumber: Yup.array()
                    .of(
                        Yup.object().shape({
                            containerIdentificationNumber: Yup.string().max(17).min(1).nullable(),
                        })
                    )
                    .nullable(),
            })
        )
        .nullable(),
    seal: Yup.object().shape({
        sealNumber: Yup.string().max(4).min(1).nullable(),
        sealIdentifier: Yup.array()
            .of(
                Yup.object().shape({
                    sealIdentifier: Yup.string().max(20).min(1).nullable(),
                })
            )
            .nullable(),
    }),
});

const packagingValidation = Yup.object().shape({
    typePackage: Yup.string().max(2).min(1).nullable(),
    packageNumber: Yup.string().max(8).min(1).nullable(),
    shippingMarks: Yup.string().max(512).min(1).nullable(),
});

export const etdItemValidation = Yup.object().shape({
    goodsItemNumber: Yup.string().max(5).min(1).nullable(),
    transitDeclaration: Yup.string().max(5).min(1).nullable(),
    documentsAuthorisations: goodsShipmentEtdDocumentsValidation,
    parties: Yup.object().shape({}).nullable(),
    datesPlaces: Yup.object()
        .shape({
            countryDestination: Yup.string().max(2).min(2).nullable(),
        })
        .nullable(),
    goodsInformation: Yup.object()
        .shape({
            grossMass: Yup.string().max(16).nullable(),
            goodsDescription: Yup.string().max(512).nullable(),
            packaging: Yup.array().of(packagingValidation).nullable(),
            cusCode: Yup.string().max(8).min(8).nullable(),
            commodityCode: Yup.object()
                .shape({
                    combinedNomenclatureCode: Yup.string().max(8).min(1).nullable(),
                })
                .nullable(),
        })
        .nullable(),
    transportInformation: Yup.object()
        .shape({
            departureTransportMeans: Yup.object().shape({}).nullable(),
            nationality: Yup.string().max(2).min(2).nullable(),
            containerIdentificationNumber: Yup.array()
                .of(
                    Yup.object().shape({
                        containerIdentificationNumber: Yup.string().max(17).min(1).nullable(),
                    })
                )
                .nullable(),
        })
        .nullable(),
});

const goodsShipmentEtdValidation = Yup.object().shape({
    documentsAuthorisations: goodsShipmentEtdDocumentsValidation,
    parties: goodsShipmentEtdPartiesValidation,
    datesPlaces: goodsShipmentEtdDatesPlacesValidation,
    transportInformation: goodsShipmentEtdTransportInformation,
    goodsShipmentItem: Yup.array().of(etdItemValidation).nullable(),
});

const irelandEtdSubmitValidation = Yup.object().shape({
    declaration: declarationEtdValidation,
    goodsShipmentEtdValidation: goodsShipmentEtdValidation,
});

export default irelandEtdSubmitValidation;
