import { DeleteOutlined } from '@ant-design/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import Button from 'components/ui/base/button';
import { FC, useMemo } from 'react';
import { DeleteButton } from './FormCard.styles';

interface Props {
    addCard?: () => void;
    removeCard?: () => void;
    isLast?: boolean;
    isFirst?: boolean;
}

const MultipleCardButtons: FC<Props> = ({ addCard, removeCard, isFirst, isLast }) => {
    const renderButtons = useMemo(() => {
        if (isLast) {
            return (
                <>
                    {!isFirst && (
                        <DeleteButton
                            onClick={(event) => {
                                if (removeCard) {
                                    removeCard();
                                }
                                event.stopPropagation();
                            }}
                        >
                            Delete Card <DeleteOutlined />
                        </DeleteButton>
                    )}
                    <Button
                        onClick={(event) => {
                            if (addCard) {
                                addCard();
                            }
                            event.stopPropagation();
                        }}
                    >
                        Add Card <PlusOutlined />
                    </Button>
                </>
            );
        } else {
            return (
                <Button
                    onClick={(event) => {
                        if (removeCard) {
                            removeCard();
                        }
                        event.stopPropagation();
                    }}
                >
                    Delete Card <DeleteOutlined />
                </Button>
            );
        }
    }, [isLast, isFirst, removeCard, addCard]);

    return <>{renderButtons}</>;
};
export default MultipleCardButtons;
