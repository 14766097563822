import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'typeCode';

export const declarationTypeBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        typeCode: [validators.required(), validators.exact(2)],
    },
};

interface Props extends BlockProps<Fields> {}

const DeclarationTypeBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '1/1', []);

    const declarationTypeCodelist = useMemo(() => {
        if (!formType.includes('IMPORT')) return cdsCodelists?.tradeMovementTypes;

        return cdsCodelists?.tradeMovementTypes.filter((c) => c.id !== 'EX');
    }, [cdsCodelists?.tradeMovementTypes, formType]);

    return (
        <DeclarationField name="typeCode">
            {({ form, field }) => (
                <FormSelect
                    required
                    {...getFormikProps(field.name, form)}
                    refNumber={ucc}
                    label={t('declarationType')}
                    selectOptions={declarationTypeCodelist}
                    condensed
                    codeValidation={[validators.exact(2)]}
                />
            )}
        </DeclarationField>
    );
};

export default DeclarationTypeBlock;
