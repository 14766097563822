import { InputLabel, LabelDiv } from 'components/ui/composed/formInput/FormInput.styles';

type Props = {
    label?: string;
};

const EDocketLabel = ({ label }: Props) => {
    if (!label) {
        return null;
    }

    return (
        <LabelDiv>
            <InputLabel>{label}</InputLabel>
        </LabelDiv>
    );
};

export default EDocketLabel;
