import { Col } from 'antd';
import Checkbox from 'components/ui/base/checkbox/Checkbox';
import Fuse from 'fuse.js';
import { FC, useEffect, useRef, useState } from 'react';
import ListTags from '../tag/ListTags';
import {
    BtTag,
    ButtonLabel,
    SuggestedTagsList,
    Divider,
    Input,
    PlusIcon,
    SaveIcon,
    SelectOptions,
} from './SelectTag.styles';

interface Props {
    suggestedTags: string[];
    usedTags: string[];
    onSuggestedTagsChange: (values: string[]) => void;
    onUsedTagsChange: (values: string[]) => void;
}

const SelectTag: FC<Props> = ({
    suggestedTags: suggestedTagsProps,
    onSuggestedTagsChange,
    onUsedTagsChange,
    usedTags,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);
    const [filteredSuggestedTags, setFilteredSuggestedTags] = useState<string[]>(suggestedTagsProps);

    const isTagUsed = (value: string): boolean => !!usedTags.find((e) => e === value);
    const inSuggestedTags = (input: string | undefined): boolean => !!suggestedTagsProps.find((e) => e === input);

    useEffect(() => {
        setFilteredSuggestedTags(suggestedTagsProps);
    }, [suggestedTagsProps]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        handleSearch(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !inSuggestedTags(inputValue) && inputValue) handleAddTag();
    };

    const handleCheckboxChange = (value: string) => {
        if (isTagUsed(value)) {
            const newUsedTags = usedTags.filter((e) => e !== value);
            onUsedTagsChange(newUsedTags);
        } else {
            const newUsedTags = [...usedTags, value];
            onUsedTagsChange(newUsedTags);
        }
    };

    const handleSearch = (value: string) => {
        if (value) {
            const optionsForSearch = { includeMatches: true };
            const fuse = new Fuse(suggestedTagsProps, optionsForSearch);
            const result = fuse.search(value);
            const filter = result.map((tag: any) => tag.matches[0].value);
            setFilteredSuggestedTags(filter);
        } else {
            setFilteredSuggestedTags(suggestedTagsProps);
        }
    };

    const handleAddTag = () => {
        const newUsedTags = [...usedTags, inputValue];
        const newSuggestedTags = [...suggestedTagsProps, inputValue];

        onUsedTagsChange(newUsedTags as string[]);
        onSuggestedTagsChange(newSuggestedTags as string[]);
        setInputValue(undefined);
    };

    useEffect(() => {
        const activeAndClickOutside = (e: any) =>
            dropdownRef.current !== null && !dropdownRef.current.contains(e.target) && setIsDropdownActive(false);

        if (isDropdownActive) window.addEventListener('click', activeAndClickOutside);

        return () => window.removeEventListener('click', activeAndClickOutside);
    }, [isDropdownActive]);

    return (
        <>
            <BtTag onClick={() => setIsDropdownActive((s) => !s)}>
                <ButtonLabel>
                    Add tag <PlusIcon />
                </ButtonLabel>
            </BtTag>
            {isDropdownActive && (
                <SelectOptions ref={dropdownRef}>
                    <Divider align="middle">
                        <Col span={21}>
                            <Input
                                autoFocus
                                placeholder="Search or add new tag"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                value={inputValue}
                            />
                        </Col>
                        <Col flex={'flex-end'} span={3}>
                            {!inSuggestedTags(inputValue) && inputValue && <SaveIcon onClick={() => handleAddTag()} />}
                        </Col>
                    </Divider>

                    <SuggestedTagsList>
                        {filteredSuggestedTags.map((tag) => (
                            <Checkbox
                                style={{ display: 'flex', margin: 0 }}
                                key={tag}
                                checked={isTagUsed(tag)}
                                onChange={() => handleCheckboxChange(tag)}
                            >
                                {tag}
                            </Checkbox>
                        ))}
                    </SuggestedTagsList>
                </SelectOptions>
            )}
            <ListTags tags={usedTags} />
        </>
    );
};
export default SelectTag;
