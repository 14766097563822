import addPathPrefix from 'utils/addPathPrefix';
import { DeclarationFieldValidation } from 'views/declarations/common/DeclarationField';
import {
    camelCaseToSentence,
    EORI_REGEX,
    getRequiredMessage,
    invalidEoriMessage,
    valueTypeForSentence,
} from 'views/declarations/utils/validation-utils';

type FieldValidator = (field: string) => (value: any) => Promise<string | undefined>;

const createFieldValidator = async (
    validator: (field: string, value: any) => Promise<string | undefined> | string | undefined
): Promise<FieldValidator> => {
    return (field: string) => async (value: any) => await validator(field, value);
};

const numberValidation = createFieldValidator((field, value) => {
    if (!value || !isNaN(value) || Number(value) >= 0) return undefined;

    return `${field} must be a number.`;
});

const required = createFieldValidator((field, value) => {
    if (value) return undefined;

    return getRequiredMessage(field);
});

const max = (max: number) =>
    createFieldValidator((field, value) => {
        if (!value || value.toString().length <= max) return undefined;

        return `${camelCaseToSentence(field)} cannot be longer than ${max} ${valueTypeForSentence(value)}.`;
    });

const float = (beforeComma: number, afterComma: number) =>
    createFieldValidator((field, value) => {
        const patternDigitsAfterComma = new RegExp(`^\\d{0,${beforeComma}}(\\.\\d{0,${afterComma}})?$`);

        if (!value || patternDigitsAfterComma.test(value.toString())) return undefined;

        return `${camelCaseToSentence(
            field
        )} cannot be longer than ${beforeComma} digits before comma and longer than ${afterComma} digits after comma.`;
    });

const exact = (exact: number) =>
    createFieldValidator((field, value) => {
        if (!value || value.toString().length === exact) return undefined;

        return `${camelCaseToSentence(field)} must be exactly ${exact} ${valueTypeForSentence(value)}.`;
    });

const eori = createFieldValidator(async (field, value) => {
    let message: string | undefined = invalidEoriMessage;

    if (!value) return undefined;

    if (value.toString().match(EORI_REGEX)) {
        try {
            const isValid = await window.EoriService.checkEori(field, value);
            if (isValid) return undefined;
        } catch (error: any) {
            if (error.code === 503 || error.code === 500) {
                return undefined;
            }

            message = error.reason;
        }
    }

    return message;
});

export const fieldValidator = {
    number: numberValidation,
    required,
    max,
    float,
    exact,
    eori,
};

export const createFieldValidatorFunction =
    (name: string, validators: (Promise<FieldValidator> | undefined | false)[]) => async (value: any) => {
        for (const validator of validators) {
            if (validator === undefined || validator === false) continue;

            const message = await (await validator)(name)(value);

            if (message) {
                return message;
            }
        }

        return undefined;
    };

export const getFieldValidationErrorMessage = (validation: DeclarationFieldValidation) => {
    if (!validation) return async () => undefined;

    return createFieldValidatorFunction(validation.name, [
        validation.number && fieldValidator.number,
        validation.required && fieldValidator.required,
        validation.exact !== undefined && fieldValidator.exact(validation.exact),
        validation.max !== undefined && fieldValidator.max(validation.max),
        validation.float !== undefined && fieldValidator.float(validation.float[0], validation.float[1]),
        validation.eori !== undefined && fieldValidator.eori,
    ]);
};

export interface FieldValidation {
    fieldPath: string;
    validation: (value: any) => Promise<string | undefined>;
}
export type BlockValidation = FieldValidation[];
export type CardValidation<T = []> = T extends (infer Item)[] ? Item : T;
export type FormValidation = CardValidation[];

export type ConstructCardValidationArgsPartial<TBlocks extends string, TFields extends string> = {
    [blocks in TBlocks]?: ConstructBlockValidationArgsPartial<TFields>;
};

export type ConstructBlockValidationArgs<TFields extends string> = {
    path: string;
    fields: {
        [fields in TFields]: DeclarationFieldValidation;
    };
};
export type ConstructBlockValidationArgsPartial<TFields extends string> = {
    path?: string;
    fields?: {
        [fields in TFields]?: Partial<DeclarationFieldValidation>;
    };
};
export type ConstructBlockValidationReturn = {
    fieldPath: string;
    validation: (value: any) => Promise<string | undefined>;
}[];
export const constructBlockValidation = (
    path: string | undefined,
    fieldValidations: { [fieldName: string]: DeclarationFieldValidation }
): ConstructBlockValidationReturn => {
    const blockValidation = [] as ConstructBlockValidationReturn;

    if (!path) return blockValidation;

    for (const [fieldName, fieldValidation] of Object.entries(fieldValidations)) {
        blockValidation.push({
            fieldPath: addPathPrefix(path, fieldName),
            validation: getFieldValidationErrorMessage(fieldValidation),
        });
    }

    return blockValidation;
};
