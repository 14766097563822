import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import AdditionalInformationCard from '../cards/AdditionalInformationCard';
import PackagingCard from '../cards/PackagingCard';
import ProducedDocumentsWritingOffCard from '../cards/ProducedDocumentsWritingOffCard';
import SimplifiedDeclarationDocumentWritingOffCard from '../cards/SimplifiedDeclarationDocumentWritingOffCard';
import SupplyChainActorCard from '../cards/SupplyChainActorCard';
import GoodShipmentItemCard from './cards/GoodShipmentItemCard';
import TransportInformationCard from './cards/TransportInformationCard';

export interface MasterDetailsIrelandEtdCards {
    cards: Card[];
}

const tsdProductsDeclarationSkeleton: MasterDetailsIrelandEtdCards = {
    cards: [
        {
            key: 'tsd-goods-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'goodsInformation',
            valid: false,
            component: GoodShipmentItemCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: '',
            getFields: () => [
                { name: `goodsItemNumber`, required: true },
                { name: `goodsInformation.grossMass`, required: false },
                { name: `goodsInformation.goodsDescription`, required: false },
                { name: `goodsInformation.cusCode`, required: false },
                { name: `goodsInformation.commodityCode.combinedNomenclatureCode`, required: false },
            ],
        },
        {
            key: 'tsd-simplified-document-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 2,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: SimplifiedDeclarationDocumentWritingOffCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `previousDocumentType`, required: false },
                { name: `previousDocumentLineId`, required: false },
                { name: `previousDocumentIdentifier`, required: false },
            ],
        },
        {
            key: 'tsd-produced-documents-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: ProducedDocumentsWritingOffCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `documentType`, required: false },
                { name: `documentIdentifier`, required: false },
            ],
        },
        {
            key: 'tsd-additional-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'additionalInformation',
            valid: false,
            component: AdditionalInformationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `additionalInformationCode`, required: false },
                { name: `additionalInformationText`, required: false },
            ],
        },
        {
            key: 'tsd-supply-chain-actor-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 5,
            defaultOpen: false,
            title: 'supplyChainActor',
            valid: false,
            component: SupplyChainActorCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'supplyChainActors',
            getFields: () => [
                { name: `roleCode`, required: false },
                { name: `traderIdentification`, required: false },
            ],
        },
        {
            key: 'tsd-packaging-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 6,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: PackagingCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'goodsInformation',
            getFields: () => [
                { name: `typePackage`, required: false },
                { name: `packageNumber`, required: false },
                { name: `shippingMarks`, required: false },
            ],
        },
        {
            key: 'tsd-transport-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 7,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: TransportInformationCard,
            declarationType: DeclarationName.TSD,
            propsPathPrefix: 'transportInformation',
            getFields: () => [
                { name: `departureTransportMeans.identificationType`, required: false },
                { name: `departureTransportMeans.identificationNumber`, required: false },
                { name: `nationality`, required: false },
            ],
        },
    ],
};
export default tsdProductsDeclarationSkeleton;
