import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { DocumentsActions } from './actionTypes';
import { Document } from './document';

export interface DocumentsState {
    isLoading: boolean;
    error?: ErrorResponse;
    documents: ListPayload<Document>;
}

export const initalDocumentsState: DocumentsState = {
    isLoading: false,
    error: undefined,
    documents: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const documents: Reducer<DocumentsState, DocumentsActions> = (state = initalDocumentsState, action) => {
    switch (action.type) {
        case 'LIST_DOCUMENTS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_DOCUMENTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                documents: action.payload,
            };
        case 'LIST_DOCUMENTS_ERROR':
            return {
                ...state,
                isLoading: false,
                documents: state.documents,
                error: action.error,
            };
        case 'DELETE_DOCUMENT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_DOCUMENT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_DOCUMENT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default: {
            return state;
        }
    }
};

export default documents;
