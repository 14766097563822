import { Collapse } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

interface InvalidProp {
    invalid?: boolean;
}

export const StyledCollapse = styled(Collapse)<{ viewOnly?: boolean }>`
    width: 100%;
    align-content: center;
    background: ${colors.white};
    border: none;
    border-radius: 0.8rem;

    .ant-collapse-header {
        padding: 1.6rem 4rem 1.6rem !important;
    }

    .ant-progress {
        margin-right: 2.4rem;
        vertical-align: text-bottom;
    }

    .ant-collapse-content {
        border-top: none;
        margin-left: 4rem;
        margin-right: 4rem;
        margin-bottom: ${({ viewOnly }) => (viewOnly ? `2.4rem` : `0.8rem`)};
    }

    .ant-collapse-content-box {
        padding: 0;
    }
`;

export const CollapseDiv = styled.div<InvalidProp>`
    background: ${colors.white};
    box-shadow: 0px 0px 2.4rem rgba(9, 0, 255, 0.07);
    border-radius: 4px;
    max-width: 100%;
    border: ${({ invalid }) => (invalid ? `1px solid ${colors.error}` : `1px solid ${colors.darkGrey2}`)};
`;
