import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { FC, ReactNode } from 'react';

export interface TooltipProps {
    title?: string;
    color?: string;
    placement?: TooltipPlacement;
    children?: ReactNode;
    handleTooltipClick?: (element: any) => void;
    overlay?: string | ReactNode;
    overlayStyle?: React.CSSProperties;
}

const AntTooltip: FC<TooltipProps> = ({
    overlay,
    title,
    placement,
    children,
    color,
    handleTooltipClick,
    overlayStyle,
}) => {
    return (
        <Tooltip title={title} placement={placement} color={color} overlay={overlay} overlayStyle={overlayStyle}>
            <span style={{ display: 'flex' }} onClick={handleTooltipClick}>
                {children}
            </span>
        </Tooltip>
    );
};

export default AntTooltip;
