import { cloneDeep, merge } from 'lodash';
import {
    getPartyValidator,
    irelandExportB1ValidationHeader,
    irelandExportB1ValidationItem,
} from './IrelandExportB1Validation';
import DeepPartial from 'types/DeepPartial';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        referenceNumberUCR: REMOVE,
        additionalReference: REMOVE,
        statisticalValue: REMOVE,
        natureOfTransaction: REMOVE,
        procedure: REMOVE,
        additionalInformation: REMOVE,
        supportingDocument: REMOVE,
        transportDocument: REMOVE,
        additionalSupplyChainActor: REMOVE,
        transportCharges: REMOVE,
        origin: {
            childValidators: {
                countryOfOrigin: REMOVE,
            },
        },
        commodity: {
            childValidators: {
                descriptionOfGoods: REMOVE,
                dangerousGoods: REMOVE,
                cusCode: REMOVE,
                commodityCode: REMOVE,
                goodsMeasure: {
                    childValidators: {
                        supplementaryUnits: REMOVE,
                        grossMass: REMOVE,
                        netMass: REMOVE,
                    },
                },
            },
        },
        packaging: REMOVE,
    },
};
export const irelandExportC2ValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        exportOperation: {
            childValidators: {
                declarationType: REMOVE,
                additionalDeclarationType: REMOVE,
                security: REMOVE,
                invoiceCurrency: REMOVE,
                totalAmountInvoiced: REMOVE,
                specificCircumstanceIndicator: REMOVE,
                presentationOfTheGoodsDateAndTime: REMOVE,
            },
        },
        deferredPayment: REMOVE,
        exporter: REMOVE,
        declarant: getPartyValidator({ eoriRequired: true, contactPerson: { present: false } }),
        representative: REMOVE,
        customsOfficeOfExitDeclared: REMOVE,
        customsOfficeOfExport: REMOVE,
        goodsShipment: {
            childValidators: {
                supportingDocument: REMOVE,
                additionalInformation: REMOVE,
                natureOfTransaction: REMOVE,
                warehouse: REMOVE,
                deliveryTerms: REMOVE,
                additionalReference: REMOVE,
                additionalSupplyChainActor: REMOVE,
                countryOfDestination: REMOVE,
                countryOfExport: REMOVE,
                consignment: {
                    childValidators: {
                        grossMass: REMOVE,
                        countryOfRoutingOfConsignment: REMOVE,
                        carrier: REMOVE,
                        consignee: REMOVE,
                        consignor: REMOVE,
                        referenceNumberUCR: REMOVE,
                        containerIndicator: REMOVE,
                        transportDocument: REMOVE,
                        modeOfTransportAtTheBorder: REMOVE,
                        inlandModeOfTransport: REMOVE,
                        departureTransportMeans: REMOVE,
                        activeBorderTransportMeans: REMOVE,
                        transportCharges: REMOVE,
                        transportEquipment: REMOVE,
                    },
                },
                goodsItem: irelandExportC2ValidationItem,
            },
        },
    },
};
export const irelandExportC2ValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
