import useDeclarations from 'hooks/useDeclarations';
import { useEffect } from 'react';
import { Declaration } from 'store/declarations/declaration';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';

interface Props {
    declarations: ReturnType<typeof useDeclarations>;
    declaration: Declaration | undefined;
    currentDeclaration: any;
    activeTabKey: DeclarationTabKeys;
}

const useRefetchDeclarationOnTabChangeIfAmendable = ({
    declarations,
    declaration,
    currentDeclaration,
    activeTabKey,
}: Props) => {
    useEffect(() => {
        if (
            !(
                currentDeclaration &&
                'amendmentAccepted' in currentDeclaration &&
                'userAmendmentSubmitted' in currentDeclaration
            )
        )
            return;

        const amendmentHasNoAnswer = !currentDeclaration?.amendmentAccepted && !currentDeclaration?.amendmentRejected;
        const amendmentRequestSubmitted = currentDeclaration?.userAmendmentSubmitted;

        if (declaration?.id && amendmentRequestSubmitted && amendmentHasNoAnswer) {
            declarations.getDeclaration(declaration.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey]);
};

export default useRefetchDeclarationOnTabChangeIfAmendable;
