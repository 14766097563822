import styled from 'styled-components';

export const StyledStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
`;

export const StyledStatusTag = styled.span<{ color: string | (() => string) }>`
    background-color: ${({ color }) => color};
    width: 1.4rem;
    height: 1.4rem;
    min-height: 1.4rem;
    min-width: 1.4rem;
    border-radius: 50%;
`;
