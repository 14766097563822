import { createJob, deleteJob, editJob, getJob, listJobs, listTags } from './client';
import { JobResponse } from './job';

export const doCreateJob: Function = (customerId?: string) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_JOB_REQUEST' });
    try {
        const payload = await createJob(customerId);

        dispatch({
            type: 'CREATE_JOB_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_JOB_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doListJobs: Function = (params: any) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_JOBS_REQUEST' });
    try {
        const payload = await listJobs(params);
        dispatch({
            type: 'LIST_JOBS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_JOBS_ERROR', error: e?.response?.data });
    }
};

export const doGetJob: Function = (jobId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_JOB_REQUEST' });
    try {
        const payload = await getJob(jobId);
        dispatch({
            type: 'GET_JOB_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'GET_JOB_ERROR', error: e?.response?.data });
    }
};

export const doEditJob: Function = (jobId: string, job: JobResponse) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_JOB_REQUEST' });
    try {
        const payload = await editJob(jobId, job);
        dispatch({
            type: 'EDIT_JOB_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'EDIT_JOB_ERROR', error: e?.response?.data });
    }
};

export const doListTags: Function = (params?: any) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_JOB_TAGS' });
    try {
        const payload = await listTags(params);
        dispatch({
            type: 'LIST_JOB_TAGS_SUCCESS',
            payload,
        });
    } catch (error: any) {
        dispatch({ type: 'LIST_JOB_TAGS_ERROR', error: error?.response?.data });
    }
};

export const doDeleteJob: Function = (jobId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_JOB_REQUEST' });
    try {
        const payload = await deleteJob(jobId);
        dispatch({
            type: 'DELETE_JOB_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'DELETE_JOB_ERROR', error: e?.response?.data });
    }
};
