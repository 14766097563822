import styled from 'styled-components';
import inviteImg from 'assets/invite-image.jpg';
import loginImg from 'assets/login-image.jpg';
import { device, fonts } from 'theme';

export const AuthenticationContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`;

export const AuthenticationFormDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 8rem 8rem 8rem;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${device.mobileL} {
        padding: 4rem;
    }
`;

export const AuthLogo = styled.h1`
    font-size: 3.8rem;
    line-height: 4.6rem;
    font-weight: ${fonts.weight.regular};
    margin-bottom: 2.8rem;
`;

type LoginPage = 'invite' | 'signIn' | 'signUp';

const getBackgroundImage = (pageName: LoginPage) => {
    switch (pageName) {
        case 'invite':
            return `url(${inviteImg})`;
        case 'signIn':
        case 'signUp':
        default:
            return `url(${loginImg})`;
    }
};

export const ImageDivBackground = styled.div<{ pageName: LoginPage }>`
    background-image: ${({ pageName }) => getBackgroundImage(pageName)};
    background-position: 0 50%;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;

    @media ${device.mobileL} {
        display: none;
    }
`;
