import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { PaginatedParams } from '../core/http/pagination';

export interface TableChangeParams {
    pagination: TablePaginationConfig;
    filters?: Record<string, FilterValue | null>;
    sorter?: SorterResult<any> | SorterResult<any>[];
}

export const getTableChangeParams = ({ pagination, sorter }: TableChangeParams) => {
    const params: Partial<PaginatedParams> = {};

    if (pagination && pagination.current) {
        params.page = pagination.current - 1;
        params.size = pagination.pageSize;
    }

    if (sorter && !Array.isArray(sorter)) {
        let sortDirection: 'ASC' | 'DESC' | undefined = undefined;
        if (sorter.order === 'ascend') sortDirection = 'ASC';
        else if (sorter.order === 'descend') sortDirection = 'DESC';

        params.sortDirection = sortDirection;
        params.sortParameter = sorter.columnKey?.toString();
    }

    return params;
};
