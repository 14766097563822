import TextArea from 'components/ui/base/text-area/TextArea';
import styled from 'styled-components';

export const StyledTextArea = styled(TextArea)`
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.2rem;
    width: 100%;
    resize: none;
`;
