import Notification from 'components/ui/base/notification/Notification';
import { FormikErrors, FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarations, { UpdateDeclarationFunction } from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration, DeclarationHistory } from 'store/declarations/declaration';
import { IrelandExportDeclaration, IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import {
    GoodsShipmentItem as H7GoodsShipmentItem,
    IrelandH7ImportDeclaration,
} from 'store/declarations/ireland/h7-import-declaration';
import {
    GoodsShipmentItem as H1GoodsShipmentItem,
    IrelandImportDeclaration,
} from 'store/declarations/ireland/import-declaration';
import { StyledHeader, StyledLayout } from 'views/declarations/Form.styles';
import { isDeclarationAmendable } from 'views/declarations/utils/declaration-utils';
import {
    removeEmptyObjectsFromDeclarationArrays,
    removeEmptyObjects,
    trimWhiteSpaces,
    FormAction,
} from 'views/declarations/utils/form-utils';
import AmendmentFooter from './AmendmentFooter';
import AmendmentReasonModal from './AmendmentReasonModal';
import MasterProductDeclarationNav from '../../common/MasterProductDeclarationNav';
import { Box44Context } from '../../common/box44/Box44';
import DeclarationTabKeys from '../../utils/DeclarationTabKeys';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import { FooterRow, SubmitDiv } from 'views/declarations/footer/Footer.styles';
import { Button, notification } from 'antd';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import config from 'config/config';
import styled from 'styled-components';
import CdsAmendmentModal, { AmendmentRecord } from './components/cds/CdsAmendmentModal';
import useRequest from 'hooks/useRequest';
import { prepareAesAmendment, prepareCdsExportAmendment, submitAmendmentRequest } from 'store/declarations/client';
import { isEmpty } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import CdsExportDeclarationUtils from 'views/declarations/uk/export/utils';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import ValidationErrorContainer from 'views/declarations/common/ValidationErrorContainer/ValidationErrorContainer';
import useHandleErrors from 'views/declarations/common/declaration-view/utils/useHandleErrors';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useHandleUnsavedChanges from 'views/declarations/common/declaration-view/utils/useHandleUnsavedChanges';
import useGetDeclarationViewMapValues from '../../../../hooks/useGetDeclarationViewMapValues';
import CancelDeclarationModal from 'views/declarations/common/CancelDeclarationModal';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { useTranslation } from 'react-i18next';
import PresentationNotificationModal from '../../common/PresentationNotificationModal';
import { box44PathAndFieldNames } from '../../common/box44/box-44-utils';
import useFormUtils from '../../../../hooks/useFormUtils';
import AesAmendmentModal from './components/aes/AesAmendmentModal';
import { FormSection } from '../../common/declaration-view/DeclarationView';

const FooterButton = styled(Button)`
    padding-inline: 3rem;
    height: 4.5rem;
`;

type SubmitDeclaration = IrelandImportDeclaration | IrelandExportDeclaration | IrelandH7ImportDeclaration;

const ViewOnlyTab: FC<{}> = () => {
    const { t } = useTranslation('common');
    const {
        declaration,
        productsFormik,
        formik,
        amendment = false,
        createAmendment,
        cancelAmendment,
        activeTabKey,
    } = useViewOnly() || {};
    const { declarationFormType } = useFormUtils();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { declarationId, productId } = useParams();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState(
        location.pathname === `/declarations/${declarationId}/view-only`
            ? FormSection.MASTER_DETAILS
            : FormSection.PRODUCTS
    );
    const [myProducts, setMyProducts] = useState<IrelandExportItem[] | H7GoodsShipmentItem[] | H1GoodsShipmentItem[]>(
        []
    );
    const navigate = useNavigate();
    const { setDeclarationFooterType } = useDeclarationFooter();
    const products = useProducts({
        productId,
    });
    const { setFormDeclarationErrors, clearFormDeclarationErrors } = useDeclarationFormErrors();
    const declarationViewMapValues = useGetDeclarationViewMapValues();
    const [triedToSubmit, setTriedToSubmit] = useState(false);

    const [isAmendmentReasonModalOpen, setIsAmendmentReasonModalOpen] = useState<boolean>(false);
    const [isCdsAmendmentModalOpen, setIsCdsAmendmentModalOpen] = useState<boolean>(false);
    const openCdsAmendmentModal = () => setIsCdsAmendmentModalOpen(true);
    const closeCdsAmendmentModal = () => setIsCdsAmendmentModalOpen(false);

    const [isAesAmendmentModalOpen, setIsAesAmendmentModalOpen] = useState<boolean>(false);
    const openAesAmendmentModal = () => setIsAesAmendmentModalOpen(true);
    const closeAesAmendmentModal = () => setIsAesAmendmentModalOpen(false);

    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const openErrorModal = () => setErrorModalOpen(true);
    const closeErrorModal = () => setErrorModalOpen(false);

    const declarations = useDeclarations();

    const [cancelDeclarationModalVisible, setCancelDeclarationModalVisible] = useState(false);
    const showCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(true), []);
    const hideCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(false), []);
    const showCancelButton = useMemo(
        () =>
            declarations.declaration?.status !== DeclarationStatus.DRAFT &&
            declarations.declaration?.declarationExternalEntity === 'CDS',
        [declarations.declaration?.declarationExternalEntity, declarations.declaration?.status]
    );

    const [presentationNotificationModalVisible, setPresentationNotificationModalVisible] = useState<boolean>(false);
    const showPresentationNotificationModal = useCallback(() => setPresentationNotificationModalVisible(true), []);
    const hidePresentationNotificationModal = useCallback(() => setPresentationNotificationModalVisible(false), []);

    const currentDeclaration = useMemo(
        () => declaration?.[declarationViewMapValues?.declarationType],
        [declaration, declarationViewMapValues.declarationType]
    );

    const showPresentationNotification = useMemo(
        () =>
            declaration?.status === DeclarationStatus.REGISTERED &&
            currentDeclaration &&
            'additionalDeclarationType' in currentDeclaration &&
            currentDeclaration.additionalDeclarationType === 'D',
        [currentDeclaration, declaration?.status]
    );

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}/view-only`
                ? FormSection.MASTER_DETAILS
                : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        if (products.products?.list) {
            setMyProducts(products.products?.list);
        } else {
            setMyProducts([]);
        }
    }, [products]);

    const declarationHasItems = useMemo(() => products.products?.total, [products]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const productFormVisible = useMemo(() => productId, [productId]);

    const productsNumber = useMemo(() => myProducts?.length, [myProducts]);

    const viewOnly = useMemo(() => {
        if (!productFormVisible && productsNumber && selectedBt !== 0) {
            return true;
        }
        return !amendment;
    }, [amendment, productFormVisible, productsNumber, selectedBt]);

    const handleSubmitForm = async (values: SubmitDeclaration) => {
        let form = removeEmptyObjects(
            removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces(values, {
                    emptyStringToNull: true,
                })
            )
        );
        if (declarationViewMapValues?.transformData?.declaration?.forServer !== undefined) {
            if (declarationViewMapValues?.payloadPath) {
                form = {
                    ...declaration?.[declarationViewMapValues?.declarationType],
                    [declarationViewMapValues?.payloadPath]: form,
                };
            }
            form = declarationViewMapValues.transformData.declaration.forServer(form);
        }
        const data = { ...declaration, [declarationViewMapValues?.declarationType as any]: form };
        if (!(declarations.declaration?.customerId! && declarationId)) return;
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Saving declaration...' } });
        return (declarations[declarationViewMapValues?.updateDeclarationFuncName] as UpdateDeclarationFunction)(
            declarations.declaration?.customerId,
            declarationId,
            data,
            false
        ).finally(() => {
            hideGlobalOverlay();
        });
    };

    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    const { data: amendments, doRequest: doPrepareCdsExportAmendment } = useRequest(prepareCdsExportAmendment);
    const { data: aesAmendmentData, doRequest: doPrepareAesAmendment } = useRequest(prepareAesAmendment);

    const cancelCdsDeclaration = useCallback(
        (data?: { reason?: string }) => {
            let message: string;
            if (!data?.reason) {
                message = 'Missing reason!';
                notification.error({ message });
                return Promise.reject({ message });
            }

            return declarations
                .cancelCdsDeclaration(data.reason)
                .then((data: Declaration) => {
                    message = 'Cancellation request sent successfully!';
                    notification.success({ message });
                    return { ...data, message };
                })
                .catch((error: any) => {
                    message = 'An error occurred while sending cancellation request!';
                    notification.error({ message });
                    return { ...error, message };
                })
                .finally(() => {
                    hideCancelDeclarationModal();
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [declarations.cancelCdsDeclaration]
    );

    const handlePresentationNotification = useCallback(
        (data?: { presentationCustomsOffice?: string }) => {
            if (!declarationId) return Promise.reject();

            let message: string;
            if (!data?.presentationCustomsOffice) {
                message = 'Missing Presentation Person!';
                notification.error({ message });
                return Promise.reject({ message });
            }

            const type = declaration?.irelandH7ImportDeclaration ? 'h7' : '';
            return declarations
                ?.importPresentationNotification(declarationId, type, data)
                .then((data: Declaration) => {
                    message = 'Presentation Notification sent successfully!';
                    notification.success({ message });
                    return { ...data, message };
                })
                .catch((error: any) => {
                    message = 'An error occurred while sending presentation notification!';
                    notification.error({ message });
                    return { ...error, message };
                })
                .finally(() => {
                    hidePresentationNotificationModal();
                });
        },
        [declaration?.irelandH7ImportDeclaration, declarations, declarationId, hidePresentationNotificationModal]
    );

    const footer = useMemo(() => {
        if (amendment) return null;

        const canSendArrivalNotification =
            declaration?.cdsExportDeclaration && ['REGISTERED', 'RISKED'].includes(declaration?.status);
        const sendArrivalNotification = () => {
            if (!canSendArrivalNotification) throw new Error('Not allowed to send arrival notification');

            axiosClient
                .post<SuccessResponse<any>>(
                    `${config.declarationsUrl}/uk/export/declarations/${declarationId}/arrivalNotification`
                )
                .then(() => {
                    notification.success({ message: 'Arrival Notification sent' });
                    declarations.getDeclaration(declarationId!);
                })
                .catch(() => {
                    notification.error({ message: 'Error sending Arrival Notification' });
                });
        };

        return (
            <SubmitDiv>
                <FooterRow justify="end" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                    <div style={{ display: 'grid', gridAutoFlow: 'column', gap: '1rem' }}>
                        {canSendArrivalNotification && (
                            <FooterButton onClick={sendArrivalNotification}>Send Arrival Notification</FooterButton>
                        )}
                        {showPresentationNotification && (
                            <FooterButton onClick={showPresentationNotificationModal}>
                                {t('footer.presentation_notification')}
                            </FooterButton>
                        )}
                        {declaration && isDeclarationAmendable(declaration) && (
                            <FooterButton onClick={() => createAmendment?.()}>Amend Declaration</FooterButton>
                        )}
                        {showCancelButton && (
                            <FooterButton onClick={showCancelDeclarationModal}>{t('footer.cancel')}</FooterButton>
                        )}
                        <CancelDeclarationModal
                            visible={cancelDeclarationModalVisible}
                            onClose={hideCancelDeclarationModal}
                            onDeclarationCancel={cancelCdsDeclaration}
                            reason
                        />
                        <PresentationNotificationModal
                            visible={presentationNotificationModalVisible}
                            onClose={hidePresentationNotificationModal}
                            handlePresentationNotification={handlePresentationNotification}
                        />
                    </div>
                </FooterRow>
            </SubmitDiv>
        );
    }, [
        amendment,
        cancelCdsDeclaration,
        cancelDeclarationModalVisible,
        createAmendment,
        declaration,
        declarationId,
        declarations,
        hideCancelDeclarationModal,
        showCancelButton,
        showCancelDeclarationModal,
        t,
        showPresentationNotification,
        hidePresentationNotificationModal,
        presentationNotificationModalVisible,
        showPresentationNotificationModal,
        handlePresentationNotification,
    ]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        } else {
            setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        }
    }, [selectedBt, setDeclarationFooterType]);

    const [declarationSaved, setDeclarationSaved] = useState(false);

    useHandleErrors({
        formik,
        productsFormik,
        validationErrorsParser: declarationViewMapValues?.parseForm,
        triedToSubmit,
    });
    useHandleUnsavedChanges({
        declarationSaved,
        setDeclarationSaved,
        formik,
        productsFormik,
    });
    const { setFormAction } = useDeclarationValidation({
        formAction: null,
    });

    return (
        <>
            <StyledHeader>
                {activeTabKey === DeclarationTabKeys.VIEW_ONLY && declarationFormType && (
                    <FormikProvider value={formikForProvider}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44PathAndFieldNames[declarationFormType]?.documentTypeName,
                                documentIdentifierName:
                                    box44PathAndFieldNames[declarationFormType]?.documentIdentifierName,
                                path: !location.pathname.includes('products')
                                    ? box44PathAndFieldNames[declarationFormType]?.masterPath
                                    : box44PathAndFieldNames[declarationFormType]?.productPath,
                            }}
                        >
                            <MasterProductDeclarationNav viewOnly selectedBt={selectedBt} amendment={amendment} />
                        </Box44Context.Provider>
                    </FormikProvider>
                )}
            </StyledHeader>
            <StyledLayout>
                <DeclarationFormTabContent>
                    <Outlet
                        context={{
                            hasAlert: true,
                            formik,
                            internalType: declaration?.declarationInternalType,
                            viewOnly,
                            amendment,
                            productsFormik,
                            cancelProducts: () => {},
                            clearCancel: async () => {
                                // await getDeclaration(declarationId);
                                //setCancelClicked(false);
                            },
                            saveProduct: () => {},
                            clearSaveProduct: () => {},
                            updateDeclaration: () => {
                                // getDeclaration(declarationId)
                            },
                            data: myProducts,
                            onEdit: (id: string) => {
                                navigate(`/declarations/${declarationId}/view-only/products/${id}`);
                                setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
                            },
                            itemsNumber: declarationHasItems ?? 0,
                            pageNumber: products.products?.pageNumber ?? 0,
                        }}
                    />
                </DeclarationFormTabContent>
            </StyledLayout>
            {footer}
            {amendment && declaration && isDeclarationAmendable(declaration) && (
                <AmendmentFooter
                    cancelSaveProduct={() => {
                        navigate(`/declarations/${declarationId}/view-only/products`);
                        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
                    }}
                    saveProduct={async () => {
                        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Saving product...' } });
                        setTriedToSubmit(false);
                        setFormAction(FormAction.PRODUCT);
                    }}
                    declaration={declaration}
                    cancelAmendment={() => {
                        cancelAmendment?.();

                        if (declaration?.ieExportDeclaration) {
                            declarations.cancelAmendmentIrelandExportDeclaration(declarationId!);
                        }
                    }}
                    saveDraft={async () => {
                        showGlobalOverlay({
                            type: 'LoadingOverlay',
                        });
                        setTriedToSubmit(false);
                        setFormAction(FormAction.DRAFT);
                    }}
                    submitAmendment={async () => {
                        setTriedToSubmit(true);
                        showGlobalOverlay({
                            type: 'LoadingOverlay',
                            payload: { message: 'Saving declaration...' },
                        });
                        let data = { ...formik.values };
                        const response = await handleSubmitForm(data);

                        const errors = await formik.validateForm();
                        if (!isEmpty(errors)) {
                            formik.setTouched(setNestedObjectValues(errors, true));
                            if (declarationViewMapValues?.parseForm)
                                setFormDeclarationErrors(declarationViewMapValues?.parseForm(errors));
                            openErrorModal();
                            hideGlobalOverlay();
                            return;
                        } else {
                            clearFormDeclarationErrors();
                        }

                        if (!response?.id) {
                            console.error('No response on form submission');
                            hideGlobalOverlay();
                            return;
                        }

                        if (declaration?.cdsExportDeclaration) {
                            showGlobalOverlay({
                                type: 'LoadingOverlay',
                                payload: { message: 'Preparing amendment...' },
                            });
                            const items = await doPrepareCdsExportAmendment(declarationId!);
                            hideGlobalOverlay();
                            if (isEmpty(items?.items)) {
                                notification.warning({ message: 'No changes have been made.' });
                                return;
                            }
                            openCdsAmendmentModal();
                            return;
                        }

                        hideGlobalOverlay();

                        if (declaration?.irelandImportDeclaration || declaration?.entrySummaryDeclaration) {
                            setIsAmendmentReasonModalOpen(true);

                            return;
                        }

                        if (declaration?.ieExportDeclaration) {
                            showGlobalOverlay({
                                type: 'LoadingOverlay',
                                payload: { message: 'Preparing amendment...' },
                            });
                            const response = await doPrepareAesAmendment(declarationId!);
                            hideGlobalOverlay();
                            if (isEmpty(response)) {
                                notification.warning({ message: 'No changes have been made.' });
                                return;
                            }

                            openAesAmendmentModal();
                            return;
                        }
                    }}
                />
            )}
            <AmendmentReasonModal
                open={isAmendmentReasonModalOpen}
                onCancel={() => {
                    setIsAmendmentReasonModalOpen(false);
                }}
                onSubmit={async ({ detailsAmended }) => {
                    if (!declarationId) return;
                    const createAmendment =
                        declaration?.irelandImportDeclaration || declaration?.irelandH7ImportDeclaration
                            ? declarations.createAmendmentIrelandImportDeclaration
                            : declaration?.entrySummaryDeclaration
                            ? declarations.createAmendmentIrelandEntrySummaryDeclaration
                            : undefined;

                    const response: DeclarationHistory | undefined = await createAmendment?.(
                        detailsAmended,
                        declarationId
                    );

                    if (response?.id) {
                        setIsAmendmentReasonModalOpen(false);

                        Notification({
                            type: 'success',
                            messageTitle: 'New Amendment',
                            description: 'A new amendment was successfully created.',
                        });
                    }
                }}
            />
            <CdsAmendmentModal
                amendments={amendments?.items}
                visible={isCdsAmendmentModalOpen}
                onCancel={closeCdsAmendmentModal}
                submitAmendmentRequest={async (value: AmendmentRecord[]) => {
                    if (!declarationId || !declaration?.mrn || !declaration?.cdsExportDeclaration?.lrn) {
                        notification.error({ message: 'Missing information for submitting amendment request' });
                        return Promise.reject();
                    }

                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting amendment...' } });
                    return submitAmendmentRequest(declarationId!, {
                        items: value,
                        declarationId: declarationId,
                        lrn: declaration.cdsExportDeclaration?.lrn,
                        mrn: declaration.mrn,
                    })
                        .then(() => {
                            cancelAmendment?.();
                            notification.success({ message: 'Amendment submitted' });
                        })
                        .catch(() => {
                            notification.error({ message: 'Could not submit amendment' });
                        });
                }}
                onChangesReverted={async () => {
                    if (!declarationId) return true;
                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Validating declaration...' } });
                    const declaration = await declarations.getDeclaration(declarationId);
                    const errors = (await formik.validateForm(
                        CdsExportDeclarationUtils.transformForClient(declaration.cdsExportDeclaration)
                            .cdsExportDeclarationPayload
                    )) as FormikErrors<any>;
                    if (!isEmpty(errors)) {
                        formik.setTouched(setNestedObjectValues(errors, true));
                        if (declarationViewMapValues?.parseForm)
                            setFormDeclarationErrors(declarationViewMapValues?.parseForm(errors));
                        openErrorModal();
                        closeCdsAmendmentModal();
                        return true;
                    }
                    clearFormDeclarationErrors();
                    return false;
                }}
            />
            <AesAmendmentModal
                amendments={aesAmendmentData}
                visible={isAesAmendmentModalOpen}
                onCancel={closeAesAmendmentModal}
                submitAmendmentRequest={async () => {
                    if (!declarationId) {
                        notification.error({ message: 'Missing information for submitting amendment request' });
                        return Promise.reject();
                    }

                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting amendment...' } });

                    await declarations
                        .createAmendmentIrelandExportDeclaration(declarationId)
                        .then(() => {
                            cancelAmendment?.();
                            notification.success({ message: 'Amendment submitted' });
                        })
                        .catch(() => {
                            notification.error({ message: 'Could not submit amendment' });
                        });
                }}
            />
            <ValidationErrorContainer isOpen={errorModalOpen} close={closeErrorModal} />
        </>
    );
};
export default ViewOnlyTab;

function useViewOnly() {
    return useOutletContext<{
        formik: ReturnType<typeof useFormik>;
        productsFormik: ReturnType<typeof useFormik>;
        declaration: Declaration | undefined;
        amendment?: boolean;
        createAmendment?: Function;
        cancelAmendment?: Function;
        activeTabKey: string;
    }>();
}
