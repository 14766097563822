import { PaginatedParams } from 'core/http/pagination';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { GoodsShipment as TsdGoodsShipmentItem } from 'store/declarations/ireland/temporary-storage-declaration';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import {
    createIrelandEnsProduct,
    createIrelandEtdProduct,
    createIrelandExportProduct,
    createIrelandH7ImportProduct,
    createIrelandImportProduct,
    createIrelandTsdProduct,
    createUkExportProduct,
    deleteIrelandEnsProduct,
    deleteIrelandExportProduct,
    deleteIrelandH7ImportProduct,
    deleteIrelandImportProduct,
    deleteIrelandTsdProduct,
    deleteUkExportProduct,
    deleteUkImportProduct,
    getIrelandEnsProduct,
    getIrelandEtdProduct,
    getIrelandExportProduct,
    getIrelandH1Product,
    getIrelandH7Product,
    getIrelandTsdProduct,
    getUkExportProduct,
    getUkImportProduct,
    listIrelandEnsProducts,
    listIrelandEtdProductsOfDeclaration,
    listIrelandExportProduct,
    listIrelandH1ImportProduct,
    listIrelandH7ImportProduct,
    listIrelandTsdProductsOfDeclaration,
    listUkExportProducts,
    updateIrelandEnsProduct,
    updateIrelandEtdProduct,
    updateIrelandExportProduct,
    updateIrelandH7ImportProduct,
    updateIrelandImportProduct,
    updateIrelandTsdProduct,
    updateUkExportProduct,
} from './client';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';

export const doCreateIrelandImportProduct: Function =
    (product: H1GoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandImportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandImportProduct: Function =
    (product: H1GoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandImportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandImportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandImportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandH7ImportProduct: Function =
    (product: H7GoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandH7ImportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandH7ImportProduct: Function =
    (product: H7GoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandH7ImportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandH7ImportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandH7ImportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandExportProduct: Function =
    (product: IrelandExportItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandExportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandExportProduct: Function =
    (product: IrelandExportItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandExportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandExportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandExportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteUkImportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_UK_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteUkImportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_UK_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_UK_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandEnsProduct: Function =
    (product: EnsGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandEnsProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandEnsProduct: Function =
    (product: EnsGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandEnsProduct(product, declarationId);
            dispatch({
                type: 'UPDATE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandEnsProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandEnsProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload || true;
        } catch (e: any) {
            console.log('here');
            dispatch({ type: 'DELETE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandEnsProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandEnsProduct(productId, declarationId);
            dispatch({
                type: 'GET_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandEnsProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_ENS_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandEnsProducts(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_ENS_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_ENS_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandH1Products: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandH1ImportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H1_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandExportProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandExportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_EXPORT_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandH1Product: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H1_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandH1Product(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H1_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H1_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetUkImportProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_UK_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await getUkImportProduct(declarationId, productId);
            dispatch({
                type: 'GET_UK_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_UK_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetUkExportProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await getUkExportProduct(declarationId, productId);
            dispatch({
                type: 'GET_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandH7Products: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H7_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandH7ImportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H7_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H7_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListUkExportProducts: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_UK_EXPORT_PRODUCTS_REQUEST' });

    try {
        const payload = await listUkExportProducts(declarationId);
        dispatch({
            type: 'LIST_UK_EXPORT_PRODUCTS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_UK_EXPORT_PRODUCTS_ERROR', error: e?.response?.data });
    }
};

export const doGetIrelandH7Product: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H7_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandH7Product(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H7_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H7_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandExportProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandExportProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doClearError: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'CLEAR_PRODUCTS_ERROR' });
};

export const doCreateIrelandTsdProduct: Function =
    (product: TsdGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_TSD_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandTsdProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_TSD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_TSD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandTsdProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_TSD_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandTsdProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_TSD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_TSD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandTsdProduct: Function =
    (product: TsdGoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_TSD_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandTsdProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_TSD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_TSD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandTsdProducts: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_IRELAND_TSD_PRODUCT_REQUEST' });

    try {
        const payload = await listIrelandTsdProductsOfDeclaration(declarationId);
        dispatch({
            type: 'LIST_IRELAND_TSD_PRODUCT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_IRELAND_TSD_PRODUCT_ERROR', error: e?.response?.data });
    }
};

export const doGetIrelandTsdProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_TSD_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandTsdProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_TSD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_TSD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandEtdProduct: Function =
    (product: EtdGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandEtdProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandEtdProduct: Function =
    (product: EtdGoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandEtdProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandEtdProducts: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_IRELAND_ETD_PRODUCT_REQUEST' });

    try {
        const payload = await listIrelandEtdProductsOfDeclaration(declarationId);
        dispatch({
            type: 'LIST_IRELAND_ETD_PRODUCT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
    }
};

export const doGetIrelandEtdProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandEtdProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateUkExportProduct: Function =
    (product: CdsExportGovernmentAgencyGoodsItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createUkExportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateUkExportProduct: Function =
    (product: CdsExportGovernmentAgencyGoodsItem, declarationId: string, productId: string) =>
    async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateUkExportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteUkExportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteUkExportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };
