import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Table from 'components/ui/base/table/Table';
import { dateStandardFormat } from 'core/utils/date';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { JobResponse } from 'store/jobs/job';
import useJobs from '../../../../hooks/useJobs';
import DeclarationsOfJobTable from './DeclarationsOfJobTable';
import { Ellipse, EllipseText } from './JobsTable.styles';
import { Spin } from 'antd';
import { getJobDeclarationType } from '../../../../components/ui/base/searchbar/search-options/jobs-search-options-utils';
import useJobDeclarationInformation from '../../../../hooks/useJobDeclarationInfromation';
import { getTableChangeParams } from '../../../../utils/tableHelpers';

// Delete functionality stopped temporarily in work item #4269

const JobsTable: FC = () => {
    const { jobs, listJobs } = useJobs();
    const navigate = useNavigate();
    // const [jobsDeleteModalVisible, setJobsDeleteModalVisible] = useState<boolean>(false);
    // const [deleteJobId, setDeleteJobId] = useState<string | undefined>(undefined);

    // const handleDeleteJob = () => {
    //     if (!deleteJobId) return notification.error({ message: 'Something went wrong, please try again later!' });

    //     deleteJob(deleteJobId)
    //         .then(() => {
    //             notification.success({ message: 'Job deleted successfully!' });
    //             listJobs();
    //         })
    //         .catch(() => {
    //             notification.error({ message: 'Something went wrong, please try again later!' });
    //         })
    //         .finally(() => {
    //             setJobsDeleteModalVisible(false);
    //             setDeleteJobId(undefined);
    //         });
    // };

    // const handleDeleteJobButtonClick = async (id: string) => {
    //     setDeleteJobId(id);
    //     setJobsDeleteModalVisible(true);
    // };

    const jobIdsPerPage = useMemo(() => jobs.list.map((job) => job.id), [jobs.list]);
    const { jobsDeclarationInformation, declarationInformationIsLoading } = useJobDeclarationInformation({
        jobIdsPerPage,
    });

    useEffect(() => {
        listJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            dataIndex: 'declarationLength',
            key: 'declarationLength',
            render: (text: string, record: JobResponse, index: number) => {
                if (declarationInformationIsLoading) return <Spin />;
                else
                    return (
                        <Ellipse>
                            <EllipseText>{jobsDeclarationInformation?.[index]?.declarationsSize}</EllipseText>
                        </Ellipse>
                    );
            },
        },
        {
            title: 'Job Id',
            dataIndex: 'referenceId',
            key: 'referenceId',
            render: (text: string, record: JobResponse) => record.referenceId,
        },
        {
            title: 'Decl. Type',
            dataIndex: 'referenceId',
            key: 'referenceId',

            render: (text: string, record: JobResponse, index: number) =>
                getJobDeclarationType(jobsDeclarationInformation?.[index]?.declarationType),
        },
        {
            title: 'Job. Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: JobResponse) => record?.status,
        },
        {
            title: 'Consignor',
            dataIndex: 'exporter',
            key: 'exporter',
            render: (text: string, record: JobResponse, index: number) => jobsDeclarationInformation?.[index]?.exporter,
        },
        {
            title: 'Consignee',
            dataIndex: 'importer',
            key: 'importer',
            render: (text: string, record: JobResponse, index: number) => jobsDeclarationInformation?.[index]?.importer,
        },
        {
            title: 'Declarant',
            dataIndex: 'declarant',
            key: 'declarant',

            render: (text: string, record: JobResponse, index: number) =>
                jobsDeclarationInformation?.[index]?.declarant,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text: string, record: JobResponse) => dateStandardFormat(new Date(record?.createdAt)),
        },
        // {
        //     title: 'Commands',
        //     dataIndex: 'commands',
        //     key: 'commands',
        //     render: (element: string, record: JobResponse) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        //                 <Tooltip title="Delete job">
        //                     <StyledButton
        //                         size="small"
        //                         onClick={(e) => {
        //                             e.stopPropagation();
        //                             handleDeleteJobButtonClick(record.id);
        //                         }}
        //                     >
        //                         <DeleteOutlined />
        //                     </StyledButton>
        //                 </Tooltip>
        //             </div>
        //         );
        //     },
        // },
    ];

    return (
        <>
            {/* <CustomModal
                title={<H5>Do you want to remove this Job?</H5>}
                centered
                visible={jobsDeleteModalVisible}
                onOk={handleDeleteJob}
                onCancel={() => setJobsDeleteModalVisible(false)}
                width={762}
                contentText={'If you remove this job, you will lose all the information associated with it.'}
            /> */}
            <Table
                rowKey="id"
                dataSource={jobs.list}
                columns={columns}
                style={{ overflow: 'hidden' }}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(`/jobs/${record.id}`),
                    };
                }}
                expandable={{
                    expandIconColumnIndex: 9,
                    expandedRowRender: (record: JobResponse) => (
                        <div>
                            <DeclarationsOfJobTable jobId={record.id} />
                        </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpOutlined
                                style={{ fontSize: '22px' }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    return onExpand(record, e);
                                }}
                            />
                        ) : (
                            <DownOutlined
                                style={{ fontSize: '22px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    return onExpand(record, e);
                                }}
                            />
                        ),
                }}
                onChange={(pagination) => listJobs(getTableChangeParams({ pagination }))}
                pagination={{
                    current: jobs.pageNumber + 1,
                    total: jobs.total,
                    pageSize: jobs.pageSize,
                    position: ['bottomCenter'],
                }}
            />
        </>
    );
};
export default JobsTable;
