import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { RefundsActions } from './actionType';
import { IrelandRefundDepositHistory } from './ireland-refund-deposit';

export interface RefundsDepositState {
    isLoading: boolean;
    error?: ErrorResponse;
    refund?: IrelandRefundDepositHistory;
}

export const initialRefundsDepositState: RefundsDepositState = {
    isLoading: false,
};

const depositRefunds: Reducer<RefundsDepositState, RefundsActions> = (
    state = initialRefundsDepositState,
    action
): RefundsDepositState => {
    switch (action.type) {
        case 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                refund: action.payload,
            };
        case 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR':
            return {
                ...state,
                isLoading: false,
                refund: state.refund,
                error: action.error,
            };
        case 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                refund: action.payload,
            };
        case 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR':
            return {
                ...state,
                isLoading: false,
                refund: state.refund,
                error: action.error,
            };
        case 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                refund: action.payload,
            };
        case 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR':
            return {
                ...state,
                isLoading: false,
                refund: state.refund,
                error: action.error,
            };
        default: {
            return state;
        }
    }
};

export default depositRefunds;
