import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import { Col, Row } from 'antd';
import logo from 'assets/logo-login.svg';
import { H5 } from 'components/ui/base/typography';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LegalInfoType } from './legal-info';
import {
    ActiveButton,
    BackButton,
    ButtonsRow,
    HeaderContent,
    HeaderRow,
    NotActiveButton,
    StyledHeader,
} from './LegalInfo.styles';

interface Props {
    isLogged?: boolean;
    type: LegalInfoType;
}

const Header: FC<Props> = ({ isLogged, type }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('termsAndConditions');

    const title = useMemo(() => {
        switch (type) {
            case LegalInfoType.TERMS_AND_CONDITIONS:
                return t('common.terms-and-condition');
            case LegalInfoType.PRIVACY_POLICY:
                return t('common.data-protection-policy');
            default:
                return '-';
        }
    }, [type, t]);

    const back = useCallback(() => {
        if (!isLogged) {
            navigate('/');
        } else {
            navigate(-1);
        }
    }, [isLogged, navigate]);

    const img = useMemo(() => <img src={logo} alt="ddispatch" />, []);

    const buttons = useMemo(() => {
        switch (type) {
            case LegalInfoType.TERMS_AND_CONDITIONS:
                return (
                    <>
                        <ActiveButton marginRight="1.6">{t('common.terms-and-condition')}</ActiveButton>
                        <NotActiveButton
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/privacy-policy', { replace: true });
                            }}
                        >
                            {t('common.privacy-policy')}
                        </NotActiveButton>
                    </>
                );
            case LegalInfoType.PRIVACY_POLICY:
                return (
                    <>
                        <NotActiveButton
                            marginRight="1.6"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/terms-and-conditions', { replace: true });
                            }}
                        >
                            {t('common.terms-and-condition')}
                        </NotActiveButton>
                        <ActiveButton>{t('common.privacy-policy')}</ActiveButton>
                    </>
                );
            default:
                return <></>;
        }
    }, [type, navigate, t]);

    return (
        <StyledHeader>
            <HeaderContent>
                <HeaderRow align="middle">
                    <Col span={8}>{img}</Col>
                    <Col span={16}>
                        <ButtonsRow justify="center">{buttons}</ButtonsRow>
                    </Col>
                </HeaderRow>
                <Row align="middle">
                    <Col span={8}>
                        <BackButton
                            onClick={(e) => {
                                e.stopPropagation();
                                back();
                            }}
                        >
                            <LeftOutlined /> {t('common.back')}
                        </BackButton>
                    </Col>

                    <Col span={16}>
                        <H5> {title}</H5>
                    </Col>
                </Row>
            </HeaderContent>
        </StyledHeader>
    );
};
export default Header;
