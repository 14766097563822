import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useEffect } from 'react';
import { ErrorDiv, ErrorLabel, FormItem, InputDiv, InputLabel, LabelDiv } from '../formInput/FormInput.styles';
import { StyledInput } from './FormPassword.styles';

export interface FormPasswordProps {
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    icon?: boolean;
}

const FormPassword: FC<FormPasswordProps> = ({ label, fieldProps, icon, fieldMeta, fieldHelper }) => {
    const { error, touched } = fieldMeta;

    useEffect(() => {
        if (fieldProps.value === '' && fieldHelper) {
            fieldHelper.setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.value]);

    const input = () => {
        return icon ? (
            <StyledInput.Password
                style={{ height: '4rem', fontSize: '1.6rem', lineHeight: '2.4rem' }}
                status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                {...fieldProps}
            />
        ) : (
            <StyledInput
                type="password"
                status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                style={{ height: '4rem', fontSize: '1.6rem', lineHeight: '2.4rem' }}
                {...fieldProps}
            />
        );
    };

    return (
        <>
            <LabelDiv>{label && <InputLabel>{label}</InputLabel>}</LabelDiv>
            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <InputDiv>{input()}</InputDiv>
                <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                    {!!error && !!touched && <ErrorLabel>{error}</ErrorLabel>}
                </ErrorDiv>
            </FormItem>
        </>
    );
};

export default FormPassword;
