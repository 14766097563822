import { WarningOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import styled from 'styled-components';
import { colors } from 'theme';

interface ButtonProps {
    invalid?: boolean;
    marginRight?: string;
}

export const InvalidIcon = styled(WarningOutlined)`
    font-size: 2.2rem;
    color: ${colors.error};
`;

export const ActionButton = styled(Button)<ButtonProps>`
    background: ${({ invalid }) => (invalid ? colors.invalid : colors.white)};
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
`;
