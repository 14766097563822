import { FormikProps } from 'formik';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useMemo, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import IeArrivalAtExitTabContent from 'views/declarations/ireland/arrival/IeArrivalAtExitTabContent';
import IrelandEnsTabContent from 'views/declarations/ireland/ens/IrelandEnsTabContent';
import IrelandEtdTabContent from 'views/declarations/ireland/etd/IrelandEtdTabContent';
import IrelandExportTabContent from 'views/declarations/ireland/export/IrelandExportTabContent';
import IrelandH1TabContent from 'views/declarations/ireland/import/h1/IrelandH1TabContent';
import IrelandH7TabContent from 'views/declarations/ireland/import/h7/IrelandH7TabContent';
import IrelandTsdTabContent from 'views/declarations/ireland/tsd/IrelandTsdTabContent';
import UkExportTabContent from 'views/declarations/uk/export/UkExportTabContent';
import UkImportFormTabContent from 'views/declarations/uk/import/UkImportFormTabContent';

export type ProductsErrors = { [index: number]: boolean };

const DeclarationForm: FC<{}> = () => {
    const { formik, productsFormik, cancelProducts, clearCancel } = useDeclarationForm() ?? {};

    const { declarationId } = useParams<{ declarationId: string }>();
    const { declaration } = useDeclarations();
    const {
        listIrelandH1Products,
        listIrelandH7Products,
        listIrelandExportProducts,
        listIrelandEnsProducts,
        listUkExportProducts,
        listIrelandEtdProducts,
    } = useProducts();

    const { declarationErrors: _declarationErrors } = useDeclarationFormErrors();

    const declarationErrors = useMemo(() => {
        const de: ProductsErrors = {};

        _declarationErrors.items.forEach(({ index }) => (de[index - 1] = true));

        return de;
    }, [_declarationErrors.items]);

    useEffect(() => {
        const listProducts = (declarationId: string, declaration: Declaration) => {
            if (declaration?.irelandImportDeclaration) {
                listIrelandH1Products(declarationId);
            }

            if (declaration?.irelandH7ImportDeclaration) {
                listIrelandH7Products(declarationId);
            }

            if (declaration?.ieExportDeclaration) {
                listIrelandExportProducts(declarationId);
            }

            if (declaration?.entrySummaryDeclaration) {
                listIrelandEnsProducts(declarationId);
            }

            if (declaration?.irelandETDDeclaration) {
                listIrelandEtdProducts(declarationId);
            }

            if (declaration?.cdsExportDeclaration) {
                listUkExportProducts(declarationId);
            }
        };

        if (declarationId && declaration?.id === declarationId) {
            listProducts(declarationId, declaration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration, declarationId]);

    const container = useMemo(() => {
        if (declaration && declaration.id === declarationId) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
                        if (declaration.irelandImportDeclaration) {
                            return (
                                <IrelandH1TabContent
                                    formik={formik}
                                    declaration={declaration}
                                    productsFormik={productsFormik!}
                                    cancelProducts={cancelProducts!}
                                    clearCancel={clearCancel}
                                    declarationErrors={declarationErrors}
                                />
                            );
                        } else {
                            return (
                                <IrelandH7TabContent
                                    declarationErrors={declarationErrors}
                                    formik={formik}
                                    productsFormik={productsFormik!}
                                    declaration={declaration}
                                    cancelProducts={cancelProducts!}
                                    clearCancel={clearCancel}
                                />
                            );
                        }
                    } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
                        return (
                            <IrelandExportTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
                        return (
                            <IrelandEnsTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
                        return (
                            <IrelandEtdTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ARRIVAL) {
                        return (
                            <IeArrivalAtExitTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                declaration={declaration}
                                clearCancel={clearCancel}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.TSD) {
                        return (
                            <IrelandTsdTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    } else {
                        return <></>;
                    }
                }
                case DeclarationExternalEntity.CHIEF:
                    return (
                        <UkExportTabContent
                            declarationErrors={declarationErrors}
                            formik={formik}
                            productsFormik={productsFormik!}
                            declaration={declaration}
                            cancelProducts={cancelProducts!}
                            clearCancel={clearCancel}
                        />
                    );
                case DeclarationExternalEntity.CDS:
                    if (declaration.cdsExportDeclaration?.messageType === 'H1') {
                        return (
                            <UkImportFormTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    } else {
                        return (
                            <UkExportTabContent
                                declarationErrors={declarationErrors}
                                formik={formik}
                                productsFormik={productsFormik!}
                                declaration={declaration}
                                cancelProducts={cancelProducts!}
                                clearCancel={clearCancel}
                            />
                        );
                    }
                default:
                    <></>;
            }
        }
        return <></>;
    }, [formik, declaration, productsFormik, cancelProducts, clearCancel, declarationErrors, declarationId]);

    return container;
};
export default DeclarationForm;

export function useDeclarationForm() {
    return useOutletContext<{
        formik: FormikProps<any>;
        productsFormik?: FormikProps<any>;
        cancelProducts?: boolean;
        clearCancel: Function;
    }>();
}
