import { useDispatch } from 'react-redux';
import { doSetInputsFocused } from 'store/declarations/actions';
import { useAppSelector } from '../config/hooks';

const useDeclarationInputFocused = () => {
    const dispatch = useDispatch();
    const focused = useAppSelector((state) => state.declarations.focused);

    const setFocused = (f: boolean) => {
        dispatch(doSetInputsFocused(f));
    };

    return {
        focused,
        setFocused,
    };
};

export default useDeclarationInputFocused;
