import { PaginatedParams } from "core/http/pagination";

export interface Vehicle{
    id: string;
    make: string;
    model: string;
    vin: string;
    number: string;
    status: VehiclesStatus;
    customerId: string;
}

export enum VehiclesStatus{
    NEW = "NEW"
}

export interface VehiclesParams  extends PaginatedParams {
    customerId: string;
    queryBy: string[];
}

export enum VehicleSortParameter {
    MAKE = 'MAKE',
    MODEL = 'MODEL',
    VIN = 'VIN',
    NUMBER = 'NUMBER',
    STATUS = 'STATUS',
}