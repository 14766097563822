import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { WCOUsersFormikFields } from '../WCOTypes';
import { wcoUsersValidationSchema, WCOUsersValidationSchemaProps } from './wcoValidationSchemas';

const initialValues: WCOUsersFormikFields = {
    firstName: '',
    lastName: '',
    email: '',
    phoneCode: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    role: 'admin',
    locations: [],
    image: null,
};

export interface FormikWCOUsersProps {
    initialValues?: WCOUsersFormikFields | null;
    validationSchemaProps?: WCOUsersValidationSchemaProps;
}

const useFormikWCOUsers = (props?: FormikWCOUsersProps) => {
    const formik = useFormik({
        initialValues: props?.initialValues ?? initialValues,
        validationSchema: wcoUsersValidationSchema(props?.validationSchemaProps),
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);

    return formik;
};

export default useFormikWCOUsers;
