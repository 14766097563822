import { Row } from 'antd';
import { JobResponse } from 'store/jobs/job';
import { enumToText } from '../../../../../core/utils/enum-to-text';
import { JobDeclarationInformation } from '../../../../../hooks/useJobDeclarationInfromation';
import { DeclarationExternalEntity } from '../../../../../store/declarations/enums/common/declaration-external-entity';
import { OptionsCol } from '../SearchBar.style';
import OptionTooltip from './OptionTooltip';

export const getJobDeclarationType = (jobDeclarationType: string | undefined) => {
    if (jobDeclarationType === undefined || jobDeclarationType === '-') return '-';
    if (jobDeclarationType === 'Multiple') return 'Multiple';

    const values = jobDeclarationType?.split(' ');

    let declarationExternalEntity = '';
    if (values?.[0]) {
        declarationExternalEntity = values[0] === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK';
    }

    let declarationInternalType = '';
    if (values?.[1]) {
        declarationInternalType = enumToText(values[1]);
        declarationInternalType = declarationInternalType.replace('New', '');
    }

    let declarationFormType = '';
    if (values?.[2]) declarationFormType = `(${values[2]})`;

    return `${declarationExternalEntity} \n${declarationInternalType} ${declarationFormType}`;
};

const getJobDeclarationsSize = (declarationsSize: number | undefined) => {
    if (!declarationsSize) return '-';
    else return `${declarationsSize} declarations`;
};

const getJobsSearchOptions = (
    jobsList: JobResponse[],
    jobsDeclarationInformation: JobDeclarationInformation[] | undefined
) => {
    const renderOptionsRow = (job: JobResponse, index: number) => ({
        value: `/jobs/${job.id}`,
        label: (
            <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <OptionsCol>
                    <OptionTooltip data={job.referenceId || '-'} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip
                        data={`${getJobDeclarationsSize(jobsDeclarationInformation?.[index]?.declarationsSize)}`}
                    />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip
                        data={`${getJobDeclarationType(jobsDeclarationInformation?.[index]?.declarationType)}`}
                    />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={`${jobsDeclarationInformation?.[index]?.exporter || '-'}`} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={`${jobsDeclarationInformation?.[index]?.importer || '-'}`} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={`${jobsDeclarationInformation?.[index]?.declarant || '-'}`} />
                </OptionsCol>
            </Row>
        ),
    });

    const jobsOptions = jobsList.map((job, index) => renderOptionsRow(job, index));
    const options = [
        {
            label: <span>Jobs</span>,
            options: jobsOptions,
        },
    ];
    return options;
};
export default getJobsSearchOptions;
