import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import IrelandImportH1DepositsRefunds from '../ireland/import/h1/refund/deposits/IrelandImportH1DepositsRefunds';

const DepositDeclaration: FC<{}> = () => {
    const { declaration } = useDepositDelcarationContext();

    const remissionDeclaration = useMemo(() => {
        if (declaration.irelandImportDeclaration) {
            return <IrelandImportH1DepositsRefunds declaration={declaration} />;
        }
        return <></>;
    }, [declaration]);

    return remissionDeclaration;
};
export default DepositDeclaration;
export function useDepositDelcarationContext() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
