import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import SupervisingCustomsOfficeBlock, {
    supervisingCustomsOfficeBlockRequiredValidation,
    supervisingCustomsOfficeBlockValidation,
} from '../blocks/SupervisingCustomsOfficeBlock';

export const supervisingCustomsOfficeCardValidation = {
    childValidators: {
        supervisingOffice: supervisingCustomsOfficeBlockValidation,
    },
};

export const supervisingCustomsOfficeCardRequiredValidation = {
    childValidators: {
        supervisingOffice: supervisingCustomsOfficeBlockRequiredValidation,
    },
};

interface Props extends DeclarationFormCardProps {}

const SupervisingCustomsOfficeCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('suppervisingCustomsOffice')} hidden={props.hidden}>
            <FormCardContainer>
                <SupervisingCustomsOfficeBlock
                    declarationOptions={{ UK_IMPORT_H2: { fieldOptions: { id: { required: true } } } }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default SupervisingCustomsOfficeCard;
