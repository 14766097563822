import { Row } from 'antd';
import { FC } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { getDescriptionOfGoods } from 'views/declarations/utils/declaration-utils';
import { SpanEllipsis, SpanEllipsisMR5 } from './DeclarationsTable.styled';
import PackageIcon from './icons/PackageIcon';
import WeightIcon from './icons/WeightIcon';

interface DescriptionOfGoodsProps {
    declaration: Declaration;
    fullDescription?: boolean;
}
const DescriptionOfGoods: FC<DescriptionOfGoodsProps> = ({ declaration, fullDescription }) => {
    const { numberOfItems, netMass, grossMass, numberOfPackages } = getDescriptionOfGoods(declaration);

    return (
        <>
            <SpanEllipsis>No. of Items: {numberOfItems}</SpanEllipsis>
            <Row align="middle" wrap={false}>
                <SpanEllipsisMR5>No. of</SpanEllipsisMR5>
                <PackageIcon />
                <SpanEllipsis>: {numberOfPackages}</SpanEllipsis>
            </Row>

            {fullDescription && (
                <>
                    <Row align="middle" wrap={false}>
                        <SpanEllipsisMR5>Gross</SpanEllipsisMR5>
                        <WeightIcon />
                        <SpanEllipsis>: {grossMass}</SpanEllipsis>
                    </Row>
                    <Row align="middle">
                        <SpanEllipsisMR5>Net</SpanEllipsisMR5>
                        <WeightIcon />
                        <SpanEllipsis>: {netMass}</SpanEllipsis>
                    </Row>
                </>
            )}
        </>
    );
};

export default DescriptionOfGoods;
