import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import AdditionalDeclarationTypeBlock, {
    additionalDeclarationTypeBlockValidation,
} from '../blocks/AdditionalDeclarationTypeBlock';
import DeclarationTypeBlock, { declarationTypeBlockValidation } from '../blocks/DeclarationTypeBlock';
import SpecificCircumstanceIndicatorBlock, {
    specificCircumstanceIndicatorBlockValidation,
} from '../blocks/SpecificCircumstanceIndicatorBlock';
import TotalNumberOfItemsBlock, { totalNumberOfItemsBlockValidation } from '../blocks/TotalNumberOfItemsBlock';
import TransportChargesMethodOfPaymentBlock, {
    transportChargesMethodOfPaymentBlockValidation,
} from '../blocks/TransportChargesMethodOfPaymentBlock';
import UcrBlock, { ucrBlockValidation } from '../blocks/UcrBlock';
import useHideEntities from '../../../../../hooks/useHideEntities';

export const masterDetailsCardValidation = {
    selfValidators: [
        declarationTypeBlockValidation,
        additionalDeclarationTypeBlockValidation,
        specificCircumstanceIndicatorBlockValidation,
        totalNumberOfItemsBlockValidation,
        ucrBlockValidation,
        transportChargesMethodOfPaymentBlockValidation,
    ],
};

export const cdsImportMasterDetailsCardValidation = {
    selfValidators: [
        declarationTypeBlockValidation,
        additionalDeclarationTypeBlockValidation,
        totalNumberOfItemsBlockValidation,
        ucrBlockValidation,
    ],
};

interface Props extends DeclarationFormCardProps {}

const MasterDetailsCard = (props: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <NewFormCard title={t('masterDetails')} hidden={props.hidden}>
            <FormCardContainer>
                <DeclarationTypeBlock />

                <AdditionalDeclarationTypeBlock />

                <SpecificCircumstanceIndicatorBlock hide={isFormType(['IMPORT_H1', 'IMPORT_H2'])} />

                <TotalNumberOfItemsBlock />

                <UcrBlock />

                <TransportChargesMethodOfPaymentBlock hide={isFormType(['IMPORT_H1', 'IMPORT_H2'])} />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default MasterDetailsCard;
