import { FieldHelperProps, FieldInputProps } from 'formik';
import { Eori } from 'store/eori/eori';

export const handleCustomerValues = (
    eori: Eori,
    getFieldHelpers: (name: string) => FieldHelperProps<any>,
    path: string
) => {
    getFieldHelpers(`${path}.name`).setValue(eori?.name);
    getFieldHelpers(`${path}.streetAndNumber`).setValue(eori?.street);
    getFieldHelpers(`${path}.postCode`).setValue(eori?.postCode);
    getFieldHelpers(`${path}.city`).setValue(eori?.city);
    getFieldHelpers(`${path}.eori`).setValue(eori?.eori);
    getFieldHelpers(`${path}.identifier`).setValue(eori?.eori);
};

export const customerHasValues = (getFieldProps: (name: string) => FieldInputProps<any>, path: string) => {
    return (
        !!getFieldProps(`${path}.name`).value ||
        !!getFieldProps(`${path}.streetAndNumber`).value ||
        !!getFieldProps(`${path}.postCode`).value ||
        !!getFieldProps(`${path}.city`).value ||
        !!getFieldProps(`${path}.identifier`).value
    );
};
