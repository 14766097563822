import { useDispatch } from 'react-redux';
import { doClearDeclarationFilter, doSetDeclarationFilter } from '../store/filter/action';
import { useAppSelector } from '../config/hooks';
import { DeclarationFilter } from '../store/filter/DeclarationFilter';

const useDeclarationFilter = () => {
    const dispatch = useDispatch();
    const declarationFilter = useAppSelector((state) => state.declarationFilter);

    const setDeclarationFilter = (declarationFilter: DeclarationFilter) => {
        dispatch(doSetDeclarationFilter(declarationFilter));
    };

    const clearDeclarationFilter = () => {
        dispatch(doClearDeclarationFilter());
    };

    return {
        declarationFilter,
        setDeclarationFilter,
        clearDeclarationFilter,
    };
};

export default useDeclarationFilter;
