import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from 'theme';

// TODO remove this from here
export const StyledInfoCircleFilled = styled(InfoCircleFilled)`
    margin-left: 0.8rem;
    font-size: 1.4rem;
    align-items: center;
    display: flex;
    color: ${colors.darkGrey2};
`;
