import useSession from 'hooks/useSession';
import { FC } from 'react';
import { IndividualType } from 'store/individuals/individual';
import BrokerInvoiceUpload from './BrokerInvoiceUpload';

const InvoiceUpload: FC<{}> = () => {
    const { userInfo } = useSession();

    if (!userInfo?.role) return <></>;

    if (Object.values(IndividualType).includes(userInfo?.role)) {
        return <BrokerInvoiceUpload />;
    }

    return <></>;
};

export default InvoiceUpload;
