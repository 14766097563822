import {
    cdsImportH1PartiesCardValidation,
    cdsImportItemH1PartiesCardBlockValidation,
} from '../../../../common/parties/cds/PartiesBlockValidations';
import { additionalDocumentBlockValidation } from '../../../export/blocks/AdditionalDocumentBlock';
import {
    additionalFiscalReferencesBlockProductValidation,
    cdsImportAdditionalFiscalReferencesBlockValidation,
} from '../../../export/blocks/AdditionalFiscalReferencesBlock';
import { cdsImportAdditionalInformationBlockValidation } from '../../../export/blocks/AdditionalInformationBlock';
import {
    cdsImportAdditionalSupplyChainActorBlockProductValidation,
    cdsImportAdditionalSupplyChainActorBlockValidation,
} from '../../../export/blocks/AdditionalSupplyChainActorBlock';
import { identityOfMeansOfTransportOnArrivalBlockValidation } from '../../../export/blocks/IdentityOfMeansOfTransportBlock';
import { previousDocumentsBlockOptionalValidation } from '../../../export/blocks/PreviousDocumentsBlock';
import { countryOfDestinationCardOptionalValidation } from '../../../export/cards/CountryOfDestinationCard';
import { cdsImportOfficesCardValidation } from '../../../export/cards/CustomsOfficeCard';
import { exchangeRateCardValidation } from '../../../export/cards/ExchangeRateCard';
import { cdsImportGrossMassCardValidation } from '../../../export/cards/GrossMassCard';
import { identificationOfWarehouseCardValidation } from '../../../export/cards/IdentificationOfWarehouseCard';
import { locationOfGoodsCardValidation } from '../../../export/cards/LocationOfGoodsCard';
import { cdsImportMasterDetailsCardValidation } from '../../../export/cards/MasterDetailsCard';
import { modeOfTransportCardValidation } from '../../../export/cards/ModeOfTransportCard';
import {
    natureOfTransactionCardProductValidation,
    natureOfTransactionCardValidation,
} from '../../../export/cards/NatureOfTransactionCard';
import { cdsImportCommodityCodesCardRequiredValidation } from '../../../export/cards/Products/CommodityCodesCard';
import { cdsImportRequiredNetMassItemFieldsCardValidation } from '../../../export/cards/Products/ItemFieldsCard';
import { cdsImportPackagingCardValidation } from '../../../export/cards/Products/PackagingCard';
import { procedureCardValidation } from '../../../export/cards/Products/ProcedureCard';
import { supervisingCustomsOfficeCardValidation } from '../../../export/cards/SupervisingCustomsOfficeCard';
import { totalAmountInvoicedCardValidation } from '../../../export/cards/TotalAmountInvoicedCard';
import {
    cdsImportTransportEquipmentCardValidation,
    transportEquipmentCardValidationProduct,
} from '../../../export/cards/TransportEquipmentCard';
import {
    guaranteeReferenceCardValidation,
    holderOfAuthorisationIdentificationNumberCardValidation,
} from '../../../export/UkExportForm';
import {
    countryOfOriginCardValidation,
    previousDocumentsCardValidation,
} from '../../../export/UkExportProductsSection';
import { ValidationSchema } from '../../../export/validations/validations';
import {
    additionsDeductionsBlockValidation,
    additionsDeductionsItemBlockValidation,
} from '../../blocks/AdditionsDeductionsBlock';
import { deferredPaymentBlockValidation } from '../../blocks/DeferredPaymentBlock';
import { valuationMethodBlockValidation } from '../../blocks/ValuationMethodBlock';
import { placeOfLoadingAirportCodeCardValidation } from '../../cards/PlaceOfLoadingAirportCodeCard';
import { dutyTaxFeeCardValidation } from '../../cards/Products/DutyTaxFeeCard';
import { itemPriceCardValidation } from '../../cards/Products/ItemPriceCard';
import { valuationIndicatorsCardValidation } from '../../cards/Products/ValuationIndicatorsCard';

export const cdsImportH1ProductValidation: ValidationSchema = {
    selfValidators: [
        cdsImportCommodityCodesCardRequiredValidation,
        cdsImportRequiredNetMassItemFieldsCardValidation,
        procedureCardValidation,
        cdsImportItemH1PartiesCardBlockValidation,
        cdsImportAdditionalInformationBlockValidation,
        additionalDocumentBlockValidation,
        natureOfTransactionCardProductValidation,
        cdsImportPackagingCardValidation,
        previousDocumentsCardValidation,
        cdsImportAdditionalSupplyChainActorBlockProductValidation,
        additionalFiscalReferencesBlockProductValidation,
        dutyTaxFeeCardValidation,
        additionsDeductionsItemBlockValidation,
        valuationIndicatorsCardValidation,
        itemPriceCardValidation,
        valuationMethodBlockValidation,
        countryOfOriginCardValidation,
        transportEquipmentCardValidationProduct,
    ],
};

export const cdsImportH1Validation: ValidationSchema = {
    childValidators: {
        'goodsShipment.governmentAgencyGoodsItem': {
            selfValidators: [cdsImportH1ProductValidation],
            stopAtNull: true,
        },
    },
    selfValidators: [
        cdsImportMasterDetailsCardValidation,
        cdsImportH1PartiesCardValidation,
        holderOfAuthorisationIdentificationNumberCardValidation,
        identificationOfWarehouseCardValidation,
        totalAmountInvoicedCardValidation,
        exchangeRateCardValidation,
        countryOfDestinationCardOptionalValidation,
        placeOfLoadingAirportCodeCardValidation,
        locationOfGoodsCardValidation,
        supervisingCustomsOfficeCardValidation,
        cdsImportOfficesCardValidation,
        cdsImportGrossMassCardValidation,
        modeOfTransportCardValidation,
        identityOfMeansOfTransportOnArrivalBlockValidation,
        guaranteeReferenceCardValidation,
        natureOfTransactionCardValidation,
        previousDocumentsBlockOptionalValidation,
        cdsImportAdditionalInformationBlockValidation,
        deferredPaymentBlockValidation,
        cdsImportAdditionalSupplyChainActorBlockValidation,
        cdsImportAdditionalFiscalReferencesBlockValidation,
        additionsDeductionsBlockValidation,
        cdsImportTransportEquipmentCardValidation,
    ],
};
