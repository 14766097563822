import { FC, useEffect } from 'react';
import useLegs from 'hooks/useLegs';
import { LegStatus } from 'store/journeys/legs/leg';
import useBreadcrumb from 'hooks/useBreadcrumb';
import LegRow from '../legs/MobileLegRow';

const DriverDashboard: FC = () => {
    const { legs, listLegs } = useLegs({ journeyId: 'dummy' });
    const { setBreadcrumbRoutes } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbRoutes([{ breadcrumbName: 'Legs', path: '' }]);
        listLegs();
    }, [listLegs, setBreadcrumbRoutes]);

    return (
        <>
            {legs.list
                .filter((leg) => leg.status !== LegStatus.COMPLETED)
                .map((leg) => (
                    <LegRow leg={leg} />
                ))}
        </>
    );
};

export default DriverDashboard;
