import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import { validators } from 'views/declarations/uk/export/validations/validations';

const AuthorisationHolderCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { aisCodelists } = useCodelists();

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path="authorisationHolder"
            initialValues={{ authorisationTypeCode: '', authorisationHolderIdentification: '' }}
            list={(list) => [
                {
                    field: t('authorisationHolder.typeCode'),
                    value: list.authorisationTypeCode,
                },
                {
                    field: t('authorisationHolder.identificationNumber'),
                    value: list.authorisationHolderIdentification,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('authorisationHolder.title')}
            condensed
            refNumber="3.39"
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'authorisationTypeCode'), formProps)}
                        refNumber="3.39"
                        label={t('authorisationHolder.typeCode')}
                        condensed
                        selectOptions={aisCodelists?.authorisationTypeCode}
                        tooltip={getH1TooltipsByRefNumberAndField('3.39', t('authorisationHolder.typeCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        codeValidation={[validators.maxLength(4)]}
                    />
                    <FormInput
                        maxLength={17}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'authorisationHolderIdentification'), formProps)}
                        refNumber="3.39"
                        label={t('authorisationHolder.identificationNumber')}
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '3.39',
                            t('authorisationHolder.identificationNumber')
                        )}
                        condensed
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                    />
                    <SearchCustomer.Drawer
                        onClose={triggerHideSearchCustomerDrawer}
                        show={showSearchCustomerDrawer}
                        onSelectCustomer={handleSelectCustomer}
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AuthorisationHolderCard;
