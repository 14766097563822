import {
    getCustomerValidation,
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    getFloatValidation,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import { documentsAuthorisations, valuationInformation } from './H7CommonValidations';

export const dutiesAndTaxes = Yup.array()
    .of(
        Yup.object({
            dutiesAndTaxesMethodOfPayment: getExactLengthValidation('dutiesAndTaxesMethodOfPayment', 1)
                .template()
                .nullable(),
            boxTaxType: getExactLengthValidation('boxTaxType', 3).template().nullable(),
            boxTaxBaseUnit: getMaxValidation('boxTaxBaseUnit', 6).template().nullable(),
            boxAmount: getFloatValidation('boxAmount', 16, 2).template().nullable(),
        })
    )
    .nullable();

const itemValuationInformation = Yup.object({
    valueAmount: getFloatValidation('valueAmount', 16, 2)
        .required(getRequiredMessage('itemAmountInvoicedIntrinsicValueAmount'))
        .template()
        .nullable(),
    valueCurrency: getExactLengthValidation('valueCurrency', 3)
        .required(getRequiredMessage('itemAmountInvoicedIntrinsicValueCurrency'))
        .template()
        .nullable(),
    transportAndInsuranceCostsToTheDestinationAmount: getFloatValidation(
        'transportAndInsuranceCostsToTheDestinationAmount',
        16,
        2
    )
        .template()
        .nullable(),
    transportAndInsuranceCostsToTheDestinationCurrency: getExactLengthValidation(
        'transportAndInsuranceCostsToTheDestinationCurrency',
        3
    )
        .template()
        .nullable(),
});

const additionalProcedure = Yup.array().of(
    Yup.object().shape({
        additionalProcedure: getExactLengthValidation('additionalProcedureCode', 3)
            .required(getRequiredMessage('additionalProcedureCode'))
            .template()
            .nullable(),
    })
);

export const governmentAgencyGoodsItem = Yup.object({
    additionalProcedure: additionalProcedure,
    documentsAuthorisations: documentsAuthorisations.nullable(),
    valuationInformation: valuationInformation.required('Valuation information is required').nullable(),
    exporter: getCustomerValidation({ postCode: { max: 17 } }).nullable(),
    itemAmountInvoicedIntrinsicValue: itemValuationInformation.required('Intrinsic value is required').nullable(),
    grossMass: getFloatValidation('grossMass', 16, 6).template().nullable().required(getRequiredMessage('grossMass')),
    supplementaryUnits: getFloatValidation('supplementaryUnits', 16, 6).template().nullable(),
    descriptionOfGoods: getMaxValidation('descriptionOfGoods', 512)
        .required(getRequiredMessage('descriptionOfGoods'))
        .template()
        .nullable(),
    packagingNumberOfPackages: getMaxValidation('numberOfPackages', 8)
        .required(getRequiredMessage('numberOfPackages'))
        .template()
        .nullable(),
    commodityCodeHarmonizedSystemSubHeadingCode: getExactLengthValidation('commodityCode', 6)
        .required(getRequiredMessage('commodityCode'))
        .template()
        .nullable(),
    dutiesAndTaxes: dutiesAndTaxes,
});

export default governmentAgencyGoodsItem;
