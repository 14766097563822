import { getDeclarationProductTaxes } from "./client";

export const doGetDeclarationProductTaxes: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_COMMODITY_TAXES' });
    try {
        const payload = await getDeclarationProductTaxes(declarationId);
        dispatch({
            type: 'GET_COMMODITY_TAXES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_COMMODITY_TAXES_ERROR', error: e?.response?.data });
    }
};