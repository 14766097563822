import { Drawer } from 'antd';
import SearchCustomer from 'components/ui/composed/searchCustomer/SearchCustomer';
import { ReactElement } from 'react';
import { Customer } from 'store/customers/customer';

interface Props {
    show: boolean;
    onClose: () => void;
    onSelectCustomer: (customer: Customer) => void;
}

const SearchCustomerDrawer = ({ show, onClose, onSelectCustomer }: Props): ReactElement => {
    return (
        <Drawer title="Search for customer" width="1098" visible={show} onClose={onClose}>
            <SearchCustomer handleSelect={onSelectCustomer} />
        </Drawer>
    );
};

export default SearchCustomerDrawer;
