import { Typography } from 'antd';
import { ModalStaticFunctions } from 'antd/lib/modal/confirm';

interface Interaction {
    vatRate: number;
    commodityNationalCode: string;
}

const { Paragraph } = Typography;

const interactions: Interaction[] = [
    { vatRate: 23, commodityNationalCode: 'VATS' },
    { vatRate: 0, commodityNationalCode: 'VATZ' },
];

const showConfirmModal = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    args: { title: string; content?: string[]; onOk: () => void; onCancel?: () => void }
) => {
    modal.confirm({
        title: args.title,
        cancelText: 'No',
        okText: 'Yes',
        content: (
            <>
                {args.content?.map((c) => (
                    <Paragraph>{c}</Paragraph>
                ))}
            </>
        ),
        onCancel: args.onCancel ? args.onCancel : () => {},
        onOk: () => {
            args.onOk();
        },
    });
};

export const handleVatRateChange = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    currentCommodityNationalCodes: (string | null)[],
    newVatRate: string | null,
    onUpdate: (newVatRate: (string | null)[] | null) => void
) => {
    if (newVatRate === null) return;
    const copyOfCommodityNationalCodes = [...currentCommodityNationalCodes];

    // Finds intersection between currentCommodityNationalCodes and interactions
    const codesToRemove = currentCommodityNationalCodes.filter((code) => {
        return interactions.some((interaction) => {
            return interaction.vatRate.toString() !== newVatRate && interaction.commodityNationalCode === code;
        });
    });

    const codeToAdd = interactions.find(
        (interaction) =>
            interaction.vatRate.toString() === newVatRate &&
            !currentCommodityNationalCodes.includes(interaction.commodityNationalCode)
    );

    // Calculates the number of modals to be shown. We will set the actual form value after all modals are closed.
    // This way we work around the fact the formik doesn't support concurrent set values.
    const totalNumberOfModals = codesToRemove.length + (codeToAdd ? 1 : 0);
    let numberOfModalsClosed = 0;
    const updateFormValue = () => {
        if (numberOfModalsClosed === totalNumberOfModals) {
            onUpdate(copyOfCommodityNationalCodes);
        }
    };

    for (let codeToRemove of codesToRemove) {
        showConfirmModal(modal, {
            title: `${codeToRemove} not needed`,
            content: [`${codeToRemove} is not necessary anymore.`, 'Do you want us to remove it for you?'],
            // eslint-disable-next-line no-loop-func
            onCancel: () => {
                numberOfModalsClosed++;
                updateFormValue();
            },
            // eslint-disable-next-line no-loop-func
            onOk: () => {
                numberOfModalsClosed++;
                copyOfCommodityNationalCodes.splice(copyOfCommodityNationalCodes.indexOf(codeToRemove), 1);
                updateFormValue();
            },
        });
    }

    if (codeToAdd) {
        showConfirmModal(modal, {
            title: `${codeToAdd.commodityNationalCode} required for this value`,
            content: [
                `It is required to have ${codeToAdd.commodityNationalCode} for a VAT Rate of ${codeToAdd.vatRate}.`,
                `Do you want us to add ${codeToAdd.commodityNationalCode} to Commodity National Code for you?`,
            ],
            onCancel: () => {
                numberOfModalsClosed++;
                updateFormValue();
            },
            onOk: () => {
                numberOfModalsClosed++;
                copyOfCommodityNationalCodes.push(codeToAdd.commodityNationalCode);
                updateFormValue();
            },
        });
    }
};

export const handleCommodityCodeChange = (
    modal: Omit<ModalStaticFunctions, 'warn'>,
    currentVatRate: number | null,
    newCommodityCode: string,
    onUpdate: (newVatRate: number | null) => void
) => {
    const interaction = interactions.find(({ commodityNationalCode }) => commodityNationalCode === newCommodityCode);
    if (interaction && currentVatRate !== interaction.vatRate) {
        showConfirmModal(modal, {
            title: 'Vat rate change',
            content: [
                `This commodity national code enforces vat rate of ${interaction.vatRate}.`,
                `Do you want us to update it for you?`,
            ],
            onCancel: () => {
                onUpdate(currentVatRate);
            },
            onOk: () => {
                onUpdate(interaction.vatRate);
            },
        });
    }
};

export const removeZeroDecimals = (value: string | null) => {
    if (!value) return null;

    const decimals = value.split('.');
    if (!decimals?.[1]) return value;

    const decimalIsZero = /^0+$/.test(decimals?.[1]);
    if (!decimalIsZero) return value;

    const removedZeroDecimals = Math.trunc(+value);
    return removedZeroDecimals.toString();
};
