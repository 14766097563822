import { PaginatedParams } from 'core/http/pagination';
import { listActiveWeighbridgeDevices, listWeighbridges } from './client';

export const doListActiveWeighbridgeDevices: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_ACTIVE_WEIGHBRIDGE_DEVICES_REQUEST' });
    try {
        const payload = await listActiveWeighbridgeDevices();
        dispatch({
            type: 'LIST_ACTIVE_WEIGHBRIDGE_DEVICES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_ACTIVE_WEIGHBRIDGE_DEVICES_ERROR', error: e?.response?.data });
    }
};

export const doListWeighbridges: Function = (params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_WEIGHBRIDGES_REQUEST' });
    try {
        const payload = await listWeighbridges(params);
        dispatch({
            type: 'LIST_WEIGHBRIDGES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_WEIGHBRIDGES_ERROR', error: e?.response?.data });
    }
};
