import { ButtonProps } from 'antd/lib/button/button';
import { TestProps } from 'core/utils/testTypes';
import { kebabCase } from 'lodash';
import { ReactElement, Children } from 'react';
import { DefaultButton, LinkButton, PrimaryButton, TextButton } from './Button.styles';

export interface CustomButtonProps extends ButtonProps, TestProps {}

export type BaseButtonType = (props: CustomButtonProps) => ReactElement;

const Button: BaseButtonType = ({ type, testId, ...props }) => {
    const buttonText = Children.toArray(props.children)
        .filter((child) => typeof child === 'string')
        .join('');

    const buttonTestId = `${kebabCase(buttonText || type || 'default')}-button`;

    switch (type) {
        case 'primary':
            return <PrimaryButton {...props} type={type} data-testid={testId || buttonTestId}></PrimaryButton>;
        case 'text':
            return <TextButton {...props} type={type} data-testid={testId || buttonTestId}></TextButton>;
        case 'link':
            return <LinkButton {...props} type={type} data-testid={testId || buttonTestId}></LinkButton>;
        default:
            return <DefaultButton {...props} type={type} data-testid={testId || buttonTestId}></DefaultButton>;
    }
};

export default Button;
