import { memo } from 'react';
import { InputProps } from 'antd';

import EDocketLabel from './EDocketLabel';
import SignatureInput from 'components/ui/composed/SignatureInput';

type Props = { label?: string } & InputProps;

const EDocketFormSignature = ({ label, ...inputProps }: Props) => {
    return (
        <>
            <EDocketLabel label={label} />
            <SignatureInput {...inputProps} />
        </>
    );
};

export default memo(EDocketFormSignature, (prev, next) => {
    return prev?.value === next?.value;
});
