import Notification from 'components/ui/base/notification/Notification';
import useDeclarations from 'hooks/useDeclarations';
import useSession from 'hooks/useSession';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import Archive from 'views/declarations/sections/form-archives';

const IrelandArchiveView: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { duplicateDeclaration, error, isLoading } = useDeclarations();
    const { userInfo } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        if (error && !isLoading) {
            Notification({
                type: 'error',
                messageTitle: t('archive.use-template-error-title'),
                description: t('archive.use-template-error-message'),
            });
        }
    }, [error, isLoading, t]);

    const handleSetAsTemplate = useCallback(
        async (declaration: Declaration) => {
            if (declaration?.id && userInfo?.customerId && declaration?.jobId) {
                const newDeclaration = await duplicateDeclaration(
                    declaration.id,
                    userInfo?.customerId,
                    declaration.jobId
                );
                navigate(`/declarations/${newDeclaration.id}`);
            }
        },
        [duplicateDeclaration, navigate, userInfo?.customerId]
    );

    return (
        <Archive
            declarationInternalType={[DeclarationInternalType.EXPORT, DeclarationInternalType.IMPORT]}
            declarationExternalEntity={[DeclarationExternalEntity.REVENUE]}
            setAsTemplate={handleSetAsTemplate}
        />
    );
};
export default IrelandArchiveView;
