import { StyledInfoCircleFilled } from 'App.styles';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
} from '../formInput/FormInput.styles';
import { StyledCheckbox } from './FormCheckbox.styles';

export interface FormInputProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    disabled?: boolean;
    required?: boolean;
    viewOnly?: boolean;
}

export interface IconTooltipProps {
    tooltip?: string;
    icon?: ReactNode;
}

const FormCheckbox: FC<FormInputProps> = ({ label, refNumber, tooltip, fieldMeta, fieldProps, viewOnly, required }) => {
    const { t } = useTranslation('common');

    return (
        <>
            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <InputDiv>
                    <LabelDiv>
                        {label && <InputLabel>{label}</InputLabel>}
                        {required && <InputLabelRequired>{t('mandatory')}</InputLabelRequired>}
                        {refNumber && <RefDiv>{refNumber}</RefDiv>}
                        {<IconTooltip tooltip={tooltip ?? t('defaultTooltip')} icon={<StyledInfoCircleFilled />} />}
                    </LabelDiv>
                    <StyledCheckbox
                        checked={fieldProps.value}
                        onChange={(e) => {
                            fieldProps.onChange({
                                target: {
                                    value: e.target.checked,
                                    name: fieldProps.name,
                                },
                            });
                        }}
                    />
                </InputDiv>
                <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                    {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                </ErrorDiv>
            </FormItem>
        </>
    );
};

export default FormCheckbox;
