import SendIcon from 'assets/sendIcon.png';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    EmailSentContent,
    ImageSendIcon,
    LabelCheckEmail,
    LabelEmailSent,
    Return,
    ReturnRow,
    ReturnText,
} from './ForgotPassword.styles';

const EmailSentSuccess = () => {
    const { t } = useTranslation('login');
    const navigate = useNavigate();

    const returnToLogin = useCallback(() => {
        navigate('/', { replace: true });
    }, [navigate]);

    return (
        <EmailSentContent>
            <ImageSendIcon src={SendIcon} alt="sendIcon" />
            <LabelEmailSent>{t('forgot_password.email_sent_sucessfully')}</LabelEmailSent>
            <LabelCheckEmail>{t('forgot_password.check_you_email_to_reset_your_password')}</LabelCheckEmail>
            <ReturnRow justify="center">
                <ReturnText> {t('returnTo')}</ReturnText>
                <Return type="link" onClick={() => returnToLogin()}>
                    {t('signIn')}
                </Return>
            </ReturnRow>
        </EmailSentContent>
    );
};

export default EmailSentSuccess;
