import { Form } from 'antd';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { useFormik } from 'formik';
import useCustomers from 'hooks/useCustomers';
import useVehicles from 'hooks/useVehicles';
import { FC, useEffect } from 'react';
import { Vehicle, VehiclesStatus } from 'store/vehicles/vehicles';
import * as Yup from 'yup';

interface Props {
    closeDrawer: () => void;
    handleOk: (newVehicle?: Vehicle) => void;
    handleEdit?: (journey?: Vehicle) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    vehicle?: Vehicle;
}

const vehicleSchema = () => {
    return Yup.object().shape({
        id: Yup.string(),
        make: Yup.string().required('Make is required.'),
        model: Yup.string().required('Model is required.'),
        vin: Yup.string().required('Vin is required.'),
        number: Yup.string().required('Number is required.'),
        status: Yup.string(),
    });
};

const CreateVehicle: FC<Props> = ({
    closeDrawer,
    handleOk,
    handleEdit,
    handleCreateError,
    handleEditError,
    vehicle,
}) => {
    const { getFieldProps, resetForm, getFieldMeta, handleSubmit, isValid, getFieldHelpers } = useFormik<
        Partial<Vehicle>
    >({
        initialValues: vehicle || ({} as Vehicle),
        validationSchema: vehicleSchema(),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createVehicleSubmit(values),
    });

    const { createVehicle, editVehicle } = useVehicles();
    const { listCustomers } = useCustomers();

    useEffect(() => {
        listCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createVehicleSubmit = async (vahicle: Partial<Vehicle>) => {
        if (vahicle && vahicle.id) {
            const response = await editVehicle(vahicle as Vehicle);
            if (response && response.id) {
                handleOk();
            } else {
                handleEditError && handleEditError();
            }
        } else {
            vahicle.status = VehiclesStatus.NEW;
            const response = await createVehicle(vahicle as Vehicle);
            if (response && response.id) {
                handleOk();
            } else {
                handleCreateError && handleCreateError();
            }
        }
    };

    const onCloseButton = () => {
        closeDrawer();
        resetForm();
    };

    return (
        <Form layout="vertical">
            <FormInput
                label="Make"
                fieldMeta={getFieldMeta('make')}
                fieldProps={getFieldProps('make')}
                fieldHelper={getFieldHelpers('make')}
            />

            <FormInput
                label="Model"
                fieldMeta={getFieldMeta('model')}
                fieldProps={getFieldProps('model')}
                fieldHelper={getFieldHelpers('model')}
            />

            <FormInput
                label="Vin"
                fieldMeta={getFieldMeta('vin')}
                fieldProps={getFieldProps('vin')}
                fieldHelper={getFieldHelpers('vin')}
            />

            <FormInput
                label="Number"
                fieldMeta={getFieldMeta('number')}
                fieldProps={getFieldProps('number')}
                fieldHelper={getFieldHelpers('number')}
            />

            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button disabled={!isValid} size="large" type="primary" onClick={() => handleSubmit()}>
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateVehicle;
