import { PaginatedParams } from 'core/http/pagination';
import {
    deleteDigitalCertificate,
    getDigitalCertificate,
    listDigitalCertificate,
    updateDigitalCertificate,
    uploadDigitalCertificate,
} from './client';
import { UpdateDigitalCertificate, UploadDigitalCertificateRequest } from './digital-certificates';

export const doListDigitalCertificate: Function = (params: Partial<PaginatedParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_DIGITAL_CERTIFICATES_REQUEST' });

    try {
        const payload = await listDigitalCertificate(params);
        dispatch({
            type: 'LIST_DIGITAL_CERTIFICATES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_DIGITAL_CERTIFICATES_ERROR', error: e?.response?.data });
    }
};

export const doUploadDigitalCertificate: Function =
    (request: UploadDigitalCertificateRequest) => async (dispatch: Function) => {
        dispatch({ type: 'UPLOAD_DIGITAL_CERTIFICATE_REQUEST' });

        try {
            const uploadPayload = await uploadDigitalCertificate(request);
            dispatch({
                type: 'UPLOAD_DIGITAL_CERTIFICATE_SUCCESS',
                payload: uploadPayload,
            });
        } catch (e: any) {
            dispatch({ type: 'UPLOAD_DIGITAL_CERTIFICATE_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateDigitalCertificate: Function =
    (id: string, body: UpdateDigitalCertificate) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_DIGITAL_CERTIFICATE_REQUEST' });

        try {
            const payload = await updateDigitalCertificate(id, body);
            dispatch({
                type: 'UPDATE_DIGITAL_CERTIFICATE_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_DIGITAL_CERTIFICATE_ERROR', error: e?.response?.data });
        }
    };

export const doGetDigitalCertificate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DIGITAL_CERTIFICATE_REQUEST' });

    try {
        const payload = await getDigitalCertificate(id);
        dispatch({
            type: 'GET_DIGITAL_CERTIFICATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_DIGITAL_CERTIFICATE_ERROR', error: e?.response?.data });
    }
};

export const doDeleteDigitalCertificate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_DIGITAL_CERTIFICATES_REQUEST' });

    try {
        const payload = await deleteDigitalCertificate(id);
        dispatch({
            type: 'DELETE_DIGITAL_CERTIFICATES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_DIGITAL_CERTIFICATES_ERROR', error: e?.response?.data });
    }
};
