import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachedDocuments } from 'store/declarations/ireland/refunds/remission/ireland-refund-remission';
import { CardContent } from 'views/declarations/common/box44/Box44Card.styles';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const RemissionAttachedDocuments: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');

    const formik = useFormik<AttachedDocuments>({
        initialValues: { documentType: '', documentIdentifier: '', documentDate: '' },
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const {
        setValues,
        getFieldProps: formGetFieldProps,
        getFieldHelpers: formGetFieldHelpers,
        getFieldMeta: formGetFieldMeta,
        resetForm,
    } = formik;

    const { modalVisible, handleEdit, handleCancel, onClickAddItem, handleDelete, addItem } = useModalMultipleCards({
        formikProps: {
            fieldProps: getFieldProps('attachedDocuments'),
            fieldHelper: getFieldHelpers('attachedDocuments'),
            fieldMeta: getFieldMeta ? getFieldMeta('attachedDocuments') : undefined,
        },
        setValues,
        resetForm,
    });

    const dataList = useMemo(() => {
        const list = getFieldProps('attachedDocuments').value;
        if (Array.isArray(list)) {
            return list.map((obj: AttachedDocuments, index) => {
                return [
                    {
                        field: t('documentType'),
                        value: obj.documentType,
                    },
                    {
                        field: t('documentIdentifier'),
                        value: obj.documentIdentifier,
                    },
                    {
                        field: t('documentDate'),
                        value: obj.documentDate,
                    },
                ];
            });
        }
        return [];
    }, [t, getFieldProps]);

    return (
        <section
            id={'remission-attached-documents-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('remission-attached-documents-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('remissionRefund.attachedDocuments')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <CardContent>
                    <CardList
                        data={dataList}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        viewOnly={viewOnly ?? false}
                    />
                    {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
                </CardContent>
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={676} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`documentType`, {
                                getFieldProps: formGetFieldProps,
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('documentType')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`documentIdentifier`, {
                                getFieldProps: formGetFieldProps,
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('documentIdentifier')}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <DeclarationDatepicker
                            viewOnly={viewOnly}
                            {...getFormikProps(`documentDate`, {
                                getFieldProps: formGetFieldProps,
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('documentDate')}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};
export default RemissionAttachedDocuments;
