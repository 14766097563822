import { Row } from 'antd';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { Declaration } from 'store/declarations/declaration';
import {
    getDeclarantName,
    getDeclarationCountry,
    getDeclarationName,
    getDeclarationPayload,
    getExporterName,
    getImporterName,
} from 'views/declarations/utils/declaration-utils';
import { OptionsCol } from '../SearchBar.style';
import OptionTooltip from './OptionTooltip';

const DeclarationsOptions = (list: Declaration[]) => {
    const getDeclarationType = (declaration: Declaration) => {
        const internalType = enumToText(declaration.declarationInternalType);
        const country = getDeclarationCountry(declaration);
        return `${country}/${internalType}`;
    };

    const getImporter = (declaration: Declaration) => {
        const payload = getDeclarationPayload(declaration);
        return getImporterName(payload);
    };

    const getExporter = (declaration: Declaration) => {
        const payload = getDeclarationPayload(declaration);
        return getExporterName(payload);
    };

    const getDeclarant = (declaration: Declaration) => {
        const payload = getDeclarationPayload(declaration);
        return getDeclarantName(payload);
    };

    const renderItem = (declaration: Declaration) => ({
        value: `/declarations/${declaration.id}`,
        label: (
            <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <OptionsCol>
                    <OptionTooltip data={declaration.referenceNumber} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={`${getDeclarationType(declaration)} - ${getDeclarationName(declaration)}`} />
                </OptionsCol>
                <OptionsCol>
                    <DeclarationStatusTag status={declaration.status} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getExporter(declaration)} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getImporter(declaration)} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getDeclarant(declaration)} />
                </OptionsCol>
            </Row>
        ),
    });

    const declarations = () => list.map((c) => renderItem(c));

    const options = [
        {
            label: <span>Declarations</span>,
            options: declarations(),
        },
    ];

    return options;
};
export default DeclarationsOptions;
