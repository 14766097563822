import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useJourneys from 'hooks/useJourneys';
import Button from 'components/ui/base/button';
import useWeighbridges from 'hooks/useWeighbridges';
import { WeighbridgeDeviceResponse } from 'store/weighbridges/weighbridge';
import useLegs from 'hooks/useLegs';
import useWeighings from 'hooks/useWeighings';
import { useFormik } from 'formik';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import LegRow from './MobileLegRow';
import { StyledMobileLegView, SyledLegActionButton } from './MobileLegView.styles';
import { useLeg } from './MobileLegView';

const MobileLegView: FC = () => {
    const navigate = useNavigate();
    const { leg } = useLeg();
    const {
        isLoading: isLoadingLeg,
        startLeg,
        completeLeg,
        isStarting,
        errorStarting,
        isLegAssigned,
        isLegInProgress,
    } = useLegs({
        journeyId: 'remove',
        legId: leg.id,
    });
    const { journey } = useJourneys({ journeyId: leg?.journeyId });
    const { listActiveWeighbridgeDevices } = useWeighbridges();
    const { isRequestingWeighing, errorRequestingWeighing, requestWeighing, weighing } = useWeighings();
    const [weighbridgeDevices, setWeighbridgeDevices] = useState<WeighbridgeDeviceResponse[]>([]);

    const formik = useFormik({
        initialValues: {
            weighbridgeDevice: '',
        },
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (leg?.id) {
                requestWeighing(values.weighbridgeDevice, {
                    jobId: journey!.jobId,
                    legId: leg.id,
                    isWeighOut: false, // TODO where to get this?
                });
            }
        },
    });

    useEffect(() => {
        if (weighing) {
            navigate(`/mobile/weighings/${weighing?.id}`);
        }
    }, [navigate, weighing]);

    useEffect(() => {
        const requestActiveWeighbridgeDevices = async () => {
            const weighbridgesResponses = await listActiveWeighbridgeDevices();
            setWeighbridgeDevices(weighbridgesResponses.list);
        };

        requestActiveWeighbridgeDevices();
    }, [listActiveWeighbridgeDevices]);

    const weighbridgesOptions = useMemo(() => {
        return weighbridgeDevices.map((w) => ({
            id: w.id,
            value: w.locationName,
        }));
    }, [weighbridgeDevices]);

    return (
        <StyledMobileLegView>
            {isLoadingLeg ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div>
                        {leg && <LegRow leg={leg} />}
                        {isLegInProgress && (
                            <div>
                                <FormSelect
                                    hideKeys
                                    label="Select weighbridge to read weight"
                                    selectOptions={weighbridgesOptions}
                                    fieldProps={formik.getFieldProps('weighbridgeDevice')}
                                    fieldMeta={formik.getFieldMeta('weighbridgeDevice')}
                                    fieldHelper={formik.getFieldHelpers('weighbridgeDevice')}
                                />
                                {formik.values.weighbridgeDevice && (
                                    <>
                                        <Button
                                            onClick={() => formik.handleSubmit()}
                                            type="primary"
                                            loading={isRequestingWeighing}
                                        >
                                            Request weighing
                                        </Button>
                                        {errorRequestingWeighing && <p>Error requesting weighing</p>}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        {/* FOOTER with buttons */}
                        {isLegAssigned && (
                            <div>
                                <SyledLegActionButton onClick={startLeg} type="primary" loading={isStarting}>
                                    Start leg
                                </SyledLegActionButton>
                                {errorStarting && <p>Error starting leg</p>}
                            </div>
                        )}
                        {isLegInProgress && (
                            <div>
                                <SyledLegActionButton onClick={completeLeg} type="primary" loading={isStarting}>
                                    Complete leg
                                </SyledLegActionButton>
                                {errorStarting && <p>Error completing leg</p>}
                            </div>
                        )}
                    </div>
                </>
            )}
        </StyledMobileLegView>
    );
};

export default MobileLegView;
