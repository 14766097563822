import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'traderAssignedReferenceID';

export const ucrBlockValidation: {
    childValidators: {
        'goodsShipment.ucr': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.ucr': {
            childValidators: {
                traderAssignedReferenceID: [validators.maxLength(35)],
            },
        },
    },
};

export const itemUcrBlockValidation: {
    childValidators: {
        ucr: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        ucr: {
            childValidators: {
                traderAssignedReferenceID: [validators.maxLength(35)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const UcrBlock = ({ path = 'goodsShipment.ucr', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '2/4', []);

    return (
        <DeclarationField pathPrefix={path} name="traderAssignedReferenceID">
            {({ form, field }) => (
                <FormInput {...getFormikProps(field.name, form)} refNumber={ucc} label={t('ucr')} condensed />
            )}
        </DeclarationField>
    );
};

export default UcrBlock;
