import './App.less';
import { FC, useEffect } from 'react';
import AppRouter from 'config/routes/AppRouter';
import notificationApi from './components/ui/base/notification/Notification';
import useNotification from './hooks/useNotification';
import useAxiosInterceptors from 'config/axios-interceptors';
import { BrowserRouter } from 'react-router-dom';
import GlobalOverlay from 'components/ui/base/global-overlay/GlobalOverlay';
import EoriService, { EventBus } from 'utils/EoriService';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';

declare global {
    interface Window {
        EoriService: EoriService;
        EventBus: EventBus;
    }
}

window.EoriService = new EoriService();
window.EventBus = new EventBus();

const App: FC = () => {
    const { notification } = useNotification();
    const ready = useAxiosInterceptors();

    useEffect(() => {
        if (notification.messageTitle !== '' && notification.description !== '') {
            notificationApi(notification);
        }
    }, [notification]);

    if (!ready) {
        return <span>Loading...</span>;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <GlobalOverlay />
                <AppRouter />
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default App;
