import MapboxDraw from '@mapbox/mapbox-gl-draw';
import useMap, { Coordinate } from 'hooks/useMap';
import { isEmpty } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapStyledComponents } from 'views/drivers/view-map/Map.styles';
import * as turf from '@turf/turf';

type DrawMode = 'creating' | 'editing' | 'selecting';

type Props = {
    selection?: Coordinate[];
    onDraw: (coordinates: Coordinate[]) => void;
};

const { HelperTextContainer, MapWrapper, MapContainer, TooltipIcon } = MapStyledComponents;

const MapWeighbridgeLocationTrace = ({ selection, onDraw }: Props) => {
    const { t } = useTranslation('weighbridge');

    const { map, mapContainer } = useMap();

    const [drawMode, setDrawMode] = useState<DrawMode>('selecting');

    const hasSelection: boolean = useMemo(() => !!selection && !isEmpty(selection), [selection]);

    const helperText: ReactElement = useMemo(() => {
        const helperTextMap: { [mode in DrawMode]: ReactElement } = {
            selecting: hasSelection ? (
                <>
                    <span>{t('weighbridgeLocation.mapTooltips.selecting.withSelection.1')}</span>
                    <span>{t('weighbridgeLocation.mapTooltips.selecting.withSelection.2')}</span>
                </>
            ) : (
                <span>{t('weighbridgeLocation.mapTooltips.selecting.withoutSelection')}</span>
            ),
            creating: (
                <>
                    <span>{t('weighbridgeLocation.mapTooltips.creating.1')}</span>
                    <span>{t('weighbridgeLocation.mapTooltips.creating.2')}</span>
                </>
            ),
            editing: <span>{t('weighbridgeLocation.mapTooltips.editing')}</span>,
        };

        return helperTextMap[drawMode];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawMode]);

    const updateDrawSelection = useCallback(
        (e: any): void => {
            if (e.type === 'draw.delete') {
                onDraw?.([]);
            } else {
                const coordinates = e.features[0].geometry.coordinates[0] as Coordinate[];
                onDraw?.(coordinates);
            }
        },
        [onDraw]
    );

    const onModeChange = useCallback((e: any): void => {
        const modeMap: { [mode: string]: DrawMode } = {
            simple_select: 'selecting',
            draw_polygon: 'creating',
            direct_select: 'editing',
        };

        setDrawMode(modeMap[e.mode]);
    }, []);

    useEffect(() => {
        if (!map) return;

        if (hasSelection) {
            map.setCenter(turf.center(turf.points(selection!)).geometry.coordinates as Coordinate);
            map.setZoom(15);
        }

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
            touchEnabled: true,
        });
        map.addControl(draw);

        if (hasSelection) {
            draw.add({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [...[selection!]],
                        },
                        properties: {},
                    },
                ],
            });
        }

        map.on('draw.create', updateDrawSelection);
        map.on('draw.update', updateDrawSelection);
        map.on('draw.delete', updateDrawSelection);
        map.on('draw.modechange', onModeChange);

        const cleanup = () => {
            map?.off('draw.create', updateDrawSelection);
            map?.off('draw.update', updateDrawSelection);
            map?.off('draw.delete', updateDrawSelection);
            map?.off('draw.modechange', onModeChange);
        };

        return cleanup;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    return (
        <MapWrapper>
            <MapContainer className="Map" ref={mapContainer} />
            <HelperTextContainer>
                <TooltipIcon />
                {helperText}
            </HelperTextContainer>
        </MapWrapper>
    );
};

export default MapWeighbridgeLocationTrace;
