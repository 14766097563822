import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import AdditionalInformationBlock, { additionalInformationBlockValidation } from './blocks/AdditionalInformationBlock';
import PreviousDocumentsBlock, { previousDocumentsBlockRequiredValidation } from './blocks/PreviousDocumentsBlock';
import GuaranteeReferenceBlock, { guaranteeReferenceBlockValidation } from './blocks/GuaranteeReferenceBlock';
import IdentificationOfWarehouseCard, {
    identificationOfWarehouseCardValidation,
} from './cards/IdentificationOfWarehouseCard';
import MasterDetailsCard, { masterDetailsCardValidation } from './cards/MasterDetailsCard';
import NatureOfTransactionCard, { natureOfTransactionCardValidation } from './cards/NatureOfTransactionCard';
import IdentityOfActiveMeansOfTransportCrossingTheBorderCard, {
    identityOfActiveMeansOfTransportCrossingTheBorderCardValidation,
} from './cards/IdentityOfActiveMeansOfTransportCrossingTheBorderCard';
import GrossMassCard, { grossMassCardValidation } from './cards/GrossMassCard';
import SupervisingCustomsOfficeCard, {
    supervisingCustomsOfficeCardValidation,
} from './cards/SupervisingCustomsOfficeCard';
import CustomsOfficesCard, { customsOfficesCardValidation } from './cards/CustomsOfficeCard';
import CountryOfDestinationCard, { countryOfDestinationCardValidation } from './cards/CountryOfDestinationCard';
import TotalAmountInvoicedCard, { totalAmountInvoicedCardValidation } from './cards/TotalAmountInvoicedCard';
import AdditionalFiscalReferencesBlock, {
    additionalFiscalReferencesBlockValidation,
} from './blocks/AdditionalFiscalReferencesBlock';
import HolderOfAuthorisationIdentificationNumberBlock, {
    holderOfAuthorisationIdentificationNumberBlockValidation,
} from './blocks/HolderOfAuthorisationIdentificationNumberBlock';
import AdditionalSupplyChainActorBlock, {
    additionalSupplyChainActorBlockValidation,
} from './blocks/AdditionalSupplyChainActorBlock';
import ModeOfTransportCard, { modeOfTransportCardValidation } from './cards/ModeOfTransportCard';
import LocationOfGoodsCard, { locationOfGoodsCardValidation } from './cards/LocationOfGoodsCard';
import CountriesOfRoutingCodesBlock, {
    countriesOfRoutingCodesBlockValidation,
} from './blocks/CountriesOfRoutingCodesBlock';
import TransportChargesMethodOfPaymentBlock, {
    consignmentItemsBlockValidation,
} from './blocks/TransportChargesMethodOfPaymentBlock';
import addPathPrefix from 'utils/addPathPrefix';
import { ValidationSchema } from './validations/validations';
import { cdsExportProductSectionValidation } from './UkExportProductsSection';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import PartiesAddressForm from 'views/declarations/common/parties/cds/PartiesAddressForm';
import PartiesEoriForm from 'views/declarations/common/parties/cds/PartiesEoriForm';
import { FormCardContainer } from '../../common/cards/NewFormCard';
import TransportEquipmentCard, { transportEquipmentCardValidation } from './cards/TransportEquipmentCard';
import DUCRMUCRCard, { ducrCardValidation } from './cards/DUCRMUCRCard';
import useHideEntities from '../../../../hooks/useHideEntities';
import { exportPartiesCardBlockValidation } from '../../common/parties/cds/PartiesBlockValidations';
import useCodelists from '../../../../hooks/useCodelists';
import IdentityOfMeansOfTransport, {
    identityOfMeansOfTransportAtDepartureCardValidation,
} from './cards/IdentityOfMeansOfTransport';
import DeferredPaymentCard from '../import/cards/DeferredPaymentCard';
import DeliveryTermsCard from '../import/cards/DeliveryTermsCard';
import AdditionsDeductionsCard from '../import/cards/AdditionsDeductionsCard';
import ExchangeRateCard, { exchangeRateCardValidation } from './cards/ExchangeRateCard';
import PlaceOfLoadingAirportCodeCard from '../import/cards/PlaceOfLoadingAirportCodeCard';
import useFormUtils from '../../../../hooks/useFormUtils';

// Multiple item cards
export const holderOfAuthorisationIdentificationNumberCardValidation = {
    selfValidators: [holderOfAuthorisationIdentificationNumberBlockValidation],
};

const countriesOfRoutingCodesCardValidation = {
    selfValidators: [countriesOfRoutingCodesBlockValidation],
};

export const guaranteeReferenceCardValidation = {
    selfValidators: [guaranteeReferenceBlockValidation],
};

const previousDocumentsCardValidation = {
    selfValidators: [previousDocumentsBlockRequiredValidation],
};

const additionalInformationCardValidation = {
    selfValidators: [additionalInformationBlockValidation],
};

const additionalSupplyChainActorCardValidation = {
    selfValidators: [additionalSupplyChainActorBlockValidation],
};

const additionalFiscalReferencesCardValidation = {
    selfValidators: [additionalFiscalReferencesBlockValidation],
};

const consignmentCodeCardValidation = {
    selfValidators: [consignmentItemsBlockValidation],
};

const partiesCardValidation = {
    selfValidators: [exportPartiesCardBlockValidation],
};

export const cdsExportFormValidation: ValidationSchema = {
    childValidators: {
        'goodsShipment.governmentAgencyGoodsItem': {
            selfValidators: [cdsExportProductSectionValidation],
            stopAtNull: true,
        },
    },
    selfValidators: [
        masterDetailsCardValidation,
        partiesCardValidation,
        holderOfAuthorisationIdentificationNumberCardValidation,
        identificationOfWarehouseCardValidation,
        totalAmountInvoicedCardValidation,
        exchangeRateCardValidation,
        countryOfDestinationCardValidation,
        locationOfGoodsCardValidation,
        supervisingCustomsOfficeCardValidation,
        customsOfficesCardValidation,
        grossMassCardValidation,
        modeOfTransportCardValidation,
        identityOfMeansOfTransportAtDepartureCardValidation,
        identityOfActiveMeansOfTransportCrossingTheBorderCardValidation,
        countriesOfRoutingCodesCardValidation,
        guaranteeReferenceCardValidation,
        natureOfTransactionCardValidation,
        previousDocumentsCardValidation,
        additionalInformationCardValidation,
        additionalSupplyChainActorCardValidation,
        additionalFiscalReferencesCardValidation,
        transportEquipmentCardValidation,
        consignmentCodeCardValidation,
        ducrCardValidation,
    ],
};
interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
}

const UkExportForm: FC<Props> = ({ formik, toggleHelp, viewOnly }) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();
    const { formTypeForCds: formType } = useFormUtils();
    const { cdsCodelists } = useCodelists();

    const totalNumberOfCards = useMemo(() => 27, []);

    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                keyCard={`master-details-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={1}
                hidden={isFormType([])}
            />
            <PartiesCard
                formik={formik}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'goodsShipment.importer',
                        header: 'Importer',
                        refNumber: '3/15',
                        hidden: isFormType(['EXPORT_B1']),
                    },
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3/1',
                        hidden: isFormType(['IMPORT_H2']),
                        eoriRequired: isFormType(['EXPORT_B1']),
                    },
                    {
                        path: 'goodsShipment.consignor',
                        header: 'Consignor',
                        refNumber: '3/7',
                        hidden: isFormType(['IMPORT_H1', 'IMPORT_H2']),
                    },
                    {
                        path: 'goodsShipment.consignee',
                        header: 'Consignee',
                        refNumber: '3/9',
                        hidden: isFormType(['IMPORT_H1', 'IMPORT_H2']),
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: '3/17',
                        eoriRequired: true,
                        hidden: isFormType([]),
                    },
                    {
                        path: 'agent',
                        header: 'Representative',
                        refNumber: '3/19',
                        hasRepresentativeStatus: true,
                        hidden: isFormType([]),
                    },
                    {
                        path: 'goodsShipment.seller',
                        header: 'Seller',
                        refNumber: '3/24',
                        hasPhoneNumber: true,
                        hidden: isFormType(['EXPORT_B1', 'IMPORT_H2']),
                    },
                    {
                        path: 'goodsShipment.buyer',
                        header: 'Buyer',
                        refNumber: '3/26',
                        hasPhoneNumber: true,
                        hidden: isFormType(['EXPORT_B1', 'IMPORT_H2']),
                    },
                    {
                        path: 'consignment.carrier',
                        header: 'Carrier',
                        refNumber: '3/31',
                        hidden: isFormType(['IMPORT_H1', 'IMPORT_H2']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.cityName',
                        country: 'address.countryCode',
                        name: 'name',
                        postCode: 'address.postcodeID',
                        streetAndNumber: 'address.line',
                    },
                    additional: {
                        representativeStatus: 'functionCode',
                        phoneNumber: 'phoneNumber',
                        phoneNumberCode: 'phoneNumberCode',
                    },
                    eori: 'id',
                }}
                PartiesAddressForm={PartiesAddressForm}
                PartiesEoriForm={PartiesEoriForm}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                condensed
            />
            <MultipleItemsCard
                title={t('Holder of the authorisation identification No.')}
                keyCard="uk-export-holder-of-authorisation"
                viewOnly={viewOnly}
                path="authorisationHolder"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
                initialValues={{ id: '', categoryCode: '' }}
                list={(list) => [
                    { field: t('authorisationHolder.identificationNumber'), value: list.id },
                    { field: t('authorisationHolder.authorisationType'), value: list.categoryCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                required={isFormType(['IMPORT_H2'])}
                refNumber="3/39"
            >
                {(path) => (
                    <FormCardContainer>
                        <HolderOfAuthorisationIdentificationNumberBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <IdentificationOfWarehouseCard
                keyCard="identification-of-warehouse-card"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
                hidden={isFormType([])}
            />

            <TotalAmountInvoicedCard
                keyCard="uk-export-total-amount-invoiced"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
                hidden={isFormType(['IMPORT_H2'])}
            />

            <ExchangeRateCard
                keyCard="exchange-rate"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
                hidden={isFormType(['IMPORT_H2'])}
            />

            <CountryOfDestinationCard
                keyCard="uk-export-country-of-destination"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
                hidden={isFormType([])}
            />

            <PlaceOfLoadingAirportCodeCard
                keyCard="transport-to-the-final-destination"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <LocationOfGoodsCard
                keyCard="uk-export-location-of-goods"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
                hidden={isFormType([])}
            />
            <SupervisingCustomsOfficeCard
                keyCard={`uk-export-supervising-customs-office`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
                hidden={isFormType([])}
            />
            <CustomsOfficesCard
                keyCard="uk-export-customs-office"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={14}
                hidden={isFormType([])}
            />
            <GrossMassCard
                keyCard="uk-export-grossmass"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
                hidden={isFormType([])}
            />
            <ModeOfTransportCard
                keyCard={`uk-export-mode-of-transport`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
                hidden={isFormType([])}
            />

            <IdentityOfMeansOfTransport
                keyCard={`uk-identity-of-means-of-transport`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
            />

            <IdentityOfActiveMeansOfTransportCrossingTheBorderCard
                keyCard={`uk-export-identity-of-active-means-of-transport-crossing-the-border`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={18}
                hidden={isFormType(['IMPORT_H1', 'IMPORT_H2'])}
            />
            <MultipleItemsCard
                title={t('countriesOfRoutingCodes')}
                keyCard="uk-export-countries-of-routing-codes"
                viewOnly={viewOnly}
                path="consignment.itinerary"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={19}
                initialValues={{ sequenceNumeric: '', routingCountryCode: '' }}
                list={(list) => [
                    {
                        field: t('sequenceNumeric'),
                        value: list.sequenceNumeric,
                    },
                    { field: t('routingCountryCode'), value: list.routingCountryCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType(['IMPORT_H1', 'IMPORT_H2'])}
                refNumber="5/18"
            >
                {(path) => (
                    <FormCardContainer>
                        <CountriesOfRoutingCodesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('guaranteeReference.title')}
                keyCard="uk-export-guaranteeReference"
                viewOnly={viewOnly}
                path="obligationGuarantee"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={21}
                initialValues={{
                    securityDetailsCode: '',
                    referenceId: '',
                    id: '',
                    accessCode: '',
                    amountAmount: '',
                    amountAmountCurrencyId: '',
                    guaranteeOffice: { id: '' },
                }}
                list={(list) => [
                    { field: t('guaranteeType'), value: list.securityDetailsCode },
                    { field: t('guaranteeReference.grn'), value: list.referenceId },
                    { field: t('guaranteeReference.otherGuaranteeReference'), value: list.id },
                    { field: t('guaranteeReference.accessCode'), value: list.accessCode },
                    { field: t('guaranteeReference.amountOfImportDuty'), value: list.amountAmount },
                    { field: t('guaranteeReference.amountOfImportDutyCurrency'), value: list.amountAmountCurrencyId },
                    { field: t('guaranteeReference.guaranteeOfficeID'), value: list.guaranteeOffice?.id },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber={['8/2', '8/3']}
            >
                {(path) => (
                    <FormCardContainer>
                        <GuaranteeReferenceBlock path={path} hide={isFormType(['EXPORT_B1'])} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <NatureOfTransactionCard
                propsPathPrefix="goodsShipment"
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={22}
                hidden={isFormType([])}
            />

            <DUCRMUCRCard
                previousDocumentPath="goodsShipment.previousDocument"
                hide={isFormType(['IMPORT_H1', 'IMPORT_H2'])}
            />

            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-export-previousDocuments"
                viewOnly={viewOnly}
                path="goodsShipment.previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={23}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list.categoryCode },
                    { field: t('previousDocumentType'), value: list.typeCode },
                    { field: t('previousDocumentReference'), value: list.id },
                    { field: t('previousDocumentCode'), value: list.lineNumeric },
                ]}
                formik={formik}
                required={formType?.includes('IMPORT') ? false : true}
                condensed
                hidden={isFormType([])}
                refNumber="2/1"
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-export-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={24}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list.statementCode },
                    { field: t('additionalInformationDescription'), value: list.statementDescription },
                ]}
                condensed
                hidden={isFormType([])}
                refNumber="2/2"
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <AdditionalInformationBlock
                            path={path}
                            fieldOptions={{
                                statementCode: {
                                    options: cdsCodelists?.gvmsaIcodes,
                                },
                            }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <DeferredPaymentCard
                propsPathPrefix="additionalDocument"
                viewOnly={viewOnly}
                keyCard={`uk-import-deferred-payment`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-export-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="goodsShipment.aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={25}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber="3/37"
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock
                            path={path}
                            declarationOptions={{
                                UK_EXPORT_B1: {
                                    fieldOptions: { id: { required: true }, roleCode: { required: true } },
                                },
                            }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-export-additional-references"
                viewOnly={viewOnly}
                path="goodsShipment.domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={26}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
                hidden={isFormType([])}
                refNumber="3/40"
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock
                            path={path}
                            declarationOptions={{
                                UK_EXPORT_B1: {
                                    fieldOptions: { roleCode: { required: true } },
                                },
                            }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <DeliveryTermsCard
                keyCard="delivery-terms"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
                hidden={isFormType(['EXPORT_B1', 'IMPORT_H2'])}
            />

            <AdditionsDeductionsCard
                propsPathPrefix="goodsShipment.customsValuation.chargeDeduction"
                viewOnly={viewOnly}
                keyCard={`uk-import-addition-deductions`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <TransportEquipmentCard
                propsPathPrefix="goodsShipment.consignment.transportEquipment"
                viewOnly={viewOnly}
                keyCard={`uk-export-container-identification-number`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={27}
            />
            <MultipleItemsCard
                title={t('consignmentItems')}
                keyCard="uk-export-container-identification-number"
                viewOnly={viewOnly}
                path="consignment.consignmentItem"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={27}
                initialValues={{ id: '' }}
                list={(list) => [{ field: t('consignmentItemNr'), value: list.id }]}
                formik={formik}
                condensed
                hidden={isFormType(['IMPORT_H1', 'IMPORT_H2'])}
                refNumber="4/2"
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <TransportChargesMethodOfPaymentBlock path={addPathPrefix(path, 'freight')} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};
export default UkExportForm;
