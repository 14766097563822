import { Tooltip, notification } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProps, useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { CdsExportPreviousDocument } from 'store/declarations/uk/export-declaration';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { ThunderboltOutlined } from '@ant-design/icons';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import config from 'config/config';
import useDeclarations from 'hooks/useDeclarations';
import { getFormikProps } from '../../../utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';
import { useOutletContext } from 'react-router-dom';
import { HollowButton } from 'views/declarations/common/box44/Box44';
import useGlobalOverlay from 'hooks/useGlobalOverlay';

const ducrValidation: ValidationSchema<'ducr'> = {
    childValidators: {
        ducr: [validators.required()],
    },
};

export const ducrCardValidation = {
    selfValidators: [ducrValidation],
};

interface Props {
    previousDocumentPath: string;
    hide: boolean;
}

const DUCRMUCRCard = ({ previousDocumentPath: previousDocumentPathProp, hide }: Props): ReactElement => {
    const mainFormik: FormikProps<any> = useFormikContext();
    const { declaration } = useDeclarations();
    const { template, form, templateFormik } = useTemplateContext();
    const { saveAsDraft } = useOutletContext<{ saveAsDraft: (withNotification: boolean) => Promise<any> }>() ?? {};

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    const [generatingDucrFromActionBtn, setGeneratingDucrFromActionBtn] = useState<boolean>(false);

    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : mainFormik),
        [mainFormik, template, templateFormik]
    );

    const previousDocumentPath = useMemo(
        () => (template ? `${form}.defaults.${previousDocumentPathProp}` : previousDocumentPathProp),
        [form, previousDocumentPathProp, template]
    );

    const previousDocument = useMemo(
        () => (formik.getFieldMeta(previousDocumentPath).value ?? []) as unknown as CdsExportPreviousDocument[],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.getFieldMeta(previousDocumentPath)]
    );

    const getIndexOfPrevDocByArg = useCallback(
        (arg: string | undefined) => previousDocument.findIndex((prevDoc) => prevDoc.typeCode === arg),
        [previousDocument]
    );

    const handlePreviousDocument = (typeCode: 'DCR' | 'MCR', code: string | undefined | null) => {
        const index = getIndexOfPrevDocByArg(typeCode);

        const create = () => {
            const newPreviousDocument = [...previousDocument, { categoryCode: 'Y', typeCode, id: code }];
            formik.setFieldValue(previousDocumentPath, newPreviousDocument);
        };

        const update = () => {
            formik.setFieldValue(`${previousDocumentPath}.${index}.id`, code);
        };

        const remove = () => {
            if (index === -1) throw new Error('Cannot remove code');
            const newPreviousDocument = [...previousDocument.filter((_, i) => i !== index)];
            formik.setFieldValue(`${previousDocumentPath}`, newPreviousDocument);
        };

        if (index !== -1) {
            if (code) {
                update();
            } else {
                remove();
            }
        } else {
            if (code) {
                create();
            }
        }
    };

    useEffect(() => {
        const dcrIndex = getIndexOfPrevDocByArg('DCR');
        const mcrIndex = getIndexOfPrevDocByArg('MCR');

        if (dcrIndex !== -1) formik.setFieldValue('ducr', previousDocument[dcrIndex]?.id);
        if (mcrIndex !== -1) formik.setFieldValue('mucr', previousDocument[mcrIndex]?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousDocument, getIndexOfPrevDocByArg]);

    const generateDucr = () => {
        if (!declaration || hide) return;
        axiosClient
            .get<SuccessResponse<string>>(
                `${config.declarationsUrl}/uk/export/declarations/${declaration.id}/generateDucr`
            )
            .then((data) => {
                formik.setFieldValue(`ducr`, data?.data.payload);
                handlePreviousDocument('DCR', data?.data.payload);
                if (generatingDucrFromActionBtn) notification.success({ message: 'DUCR generated and filled' });
            })
            .catch(() => {
                if (generatingDucrFromActionBtn)
                    notification.error({
                        message: '"Exporter - Identification Number" is required for DUCR generation',
                    });
            })
            .finally(() => {
                setGeneratingDucrFromActionBtn(false);
                if (generatingDucrFromActionBtn) hideGlobalOverlay();
            });
    };

    useEffect(() => {
        const subscriberId = window.EventBus.subscribe('afterDeclarationSave', () => generateDucr());

        return () => window.EventBus.unsubscribe(subscriberId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatingDucrFromActionBtn]);

    return (
        <NewFormCard title="DUCR/MUCR" hidden={hide}>
            <FormCardContainer>
                <DeclarationInput
                    label="DUCR"
                    {...getFormikProps(`ducr`, formik)}
                    onBlur={(e) => handlePreviousDocument('DCR', e.target.value)}
                    condensed
                    disabled
                    after={
                        <Tooltip
                            overlay={
                                template
                                    ? 'DUCR is unique for each declaration and should be generated while submission.'
                                    : 'Generate DUCR'
                            }
                        >
                            <HollowButton
                                shape="circle"
                                icon={<ThunderboltOutlined />}
                                disabled={template}
                                onClick={() => {
                                    showGlobalOverlay({
                                        type: 'LoadingOverlay',
                                        payload: { message: 'Generating DUCR...' },
                                    });
                                    setGeneratingDucrFromActionBtn(true);
                                    saveAsDraft(false);
                                }}
                            />
                        </Tooltip>
                    }
                    required
                />
                <DeclarationInput
                    label="MUCR"
                    {...getFormikProps(`mucr`, formik)}
                    onBlur={(e) => handlePreviousDocument('MCR', e.target.value)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default DUCRMUCRCard;
