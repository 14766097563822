import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import {
    LoginOutlined,
    LoadingOutlined,
    CheckCircleOutlined,
    MinusCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';
import SearchCustomerButton from '../../../../common/search-customer/SearchCustomerButton';
import { InfoTitle } from '../../../../common/DeclarationInformation';
import { Tooltip } from 'antd';
import ConditionalWrapper from '../../../../../../components/ConditionalWrapper';

interface InvoiceStatuses {
    loading: boolean;
    failed: boolean;
    invoiceSavedDisable: boolean;
}

const InvoiceFormHeader: FC<{ disabled: boolean }> = ({ disabled: initiallyDisabled }) => {
    const [handleOpenInvoiceModalCallback, setHandleOpenInvoiceModalCallback] = useState<() => void>(() => {});
    const [invoiceStatuses, setInvoiceStatuses] = useReducer(
        (state: InvoiceStatuses, newState: Partial<InvoiceStatuses>) => ({ ...state, ...newState }),
        {
            loading: true,
            failed: false,
            invoiceSavedDisable: false,
        }
    );

    useEffect(() => {
        const handleInvoiceModalSubscriberId = window.EventBus.subscribe(
            'handleInvoiceModal',
            (callback: () => void) => {
                setHandleOpenInvoiceModalCallback(() => callback);
                setInvoiceStatuses({ loading: false });
            }
        );

        const invoiceModalSavedSubscriberId = window.EventBus.subscribe('invoiceModalSaved', () =>
            setInvoiceStatuses({ invoiceSavedDisable: true })
        );

        const invoiceFailedInitiallySubscriberId = window.EventBus.subscribe('invoiceFailedInitially', () =>
            setInvoiceStatuses({ failed: true })
        );

        return () => {
            window.EventBus.unsubscribe(handleInvoiceModalSubscriberId);
            window.EventBus.unsubscribe(invoiceModalSavedSubscriberId);
            window.EventBus.unsubscribe(invoiceFailedInitiallySubscriberId);
        };
    }, []);

    const invoiceFormHeaderIcon = useMemo(() => {
        if (initiallyDisabled || invoiceStatuses.invoiceSavedDisable)
            return <MinusCircleOutlined style={{ color: 'grey', marginRight: 7 }} />;
        else if (invoiceStatuses.failed) return <CloseCircleOutlined style={{ color: 'red', marginRight: 7 }} />;
        else if (invoiceStatuses.loading) return <LoadingOutlined style={{ color: 'blue', marginRight: 7 }} />;

        return <CheckCircleOutlined style={{ color: 'green', marginRight: 7 }} />;
    }, [invoiceStatuses, initiallyDisabled]);

    const openExtractionButtonDisabled: boolean = useMemo(
        () => initiallyDisabled || Object.values(invoiceStatuses).some((status) => status),
        [initiallyDisabled, invoiceStatuses]
    );

    return (
        <ConditionalWrapper
            condition={initiallyDisabled}
            wrapper={(children) => (
                <Tooltip placement="left" title="The feature becomes available when an invoice is uploaded.">
                    {children}
                </Tooltip>
            )}
        >
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
                <InfoTitle
                    style={{
                        fontSize: 'large',
                        color: initiallyDisabled || invoiceStatuses.invoiceSavedDisable ? 'grey' : '',
                    }}
                >
                    {invoiceFormHeaderIcon}
                    Invoice Extraction
                </InfoTitle>
                <SearchCustomerButton
                    condensed
                    title="Open Extraction"
                    icon={<LoginOutlined />}
                    disabled={openExtractionButtonDisabled}
                    onClick={handleOpenInvoiceModalCallback}
                />
            </div>
        </ConditionalWrapper>
    );
};

export default InvoiceFormHeader;
