export const WeighbridgeStatus = {
    SETUP: 'SETUP',
    READY: 'READY',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WeighbridgeStatus = typeof WeighbridgeStatus[keyof typeof WeighbridgeStatus];

export interface WeighbridgeDeviceResponse {
    id: string;
    token?: string;
    name: string;
    dispatcher: string;
    status: WeighbridgeStatus;
    customerId: string;
    locationName: string;
    city: string;
    country: string;
    connected: boolean;
    geofenceCoordinates: GeofenceCoordinate[];
    calibrations?: Calibration[];
}

export interface Calibration {
    id: string;
    base64Image: string;
    topLeftX: number;
    topLeftY: number;
    bottomRightX: number;
    bottomRightY: number;
    traceId: string;
    createdAt: string;
    createdBy: string;
}

export interface GeofenceCoordinate {
    id: string;
    latitude: number;
    longitude: number;
    orderIndex: number;
}

export interface Weighbridge {
    id: string;
    label: string;
    status: string;
    // TODO Check location this type
    location: string;
    lastUsed: string;
    // TODO Check device this type
    device?: WeighbridgeDeviceResponse;
}

export enum WeighbridgeSortParameter {
    ID = 'ID',
    STATUS = 'STATUS',
    LABEL = 'LABEL',
    LOCATION = 'LOCATION',
    LAST_USED = 'LAST_USED',
    DEVICE = 'DEVICE',
}
