import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { CountriesActions } from './actionTypes';
import { Country } from './country';

export interface CountriesState {
    isLoading: boolean;
    countries: ListPayload<Country>;
    error?: ErrorResponse;
}

export const countriesInitialState: CountriesState = {
    isLoading: false,
    countries: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const countries: Reducer<CountriesState, CountriesActions> = (
    state = countriesInitialState,
    action: CountriesActions
): CountriesState => {
    switch (action.type) {
        case 'GET_COUNTRIES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_COUNTRIES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                countries: action.payload,
                error: undefined,
            };
        case 'GET_COUNTRIES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default countries;
