import { Col, Divider, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCargo from 'hooks/useCargo';
import { useEffect, useState } from 'react';
import { Cargo } from 'store/cargo/cargo';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import CargosTable from './components/CargosTable';
import CreateNewCargo from './components/CreateNewCargo';
const CargoManagement = () => {
    const { listCargos, cargos, isLoading, getCargo, deleteCargo, error } = useCargo();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const [cargo, setCargo] = useState<Cargo>();
    const [showCargoDrawer, setShowCargoDrawer] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Cargo Management',
                path: '',
            },
        ]);
        listCargos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddNewCargo = () => {
        setShowCargoDrawer(true);
        setCargo({} as Cargo);
    };

    const handleAddNewCargoSuccess = () => {
        setShowCargoDrawer(false);
        listCargos();
    };

    const handleUpdateRole = () => {};

    const handleSort = () => {};

    const handlePagination = () => {};

    const handleEdit = async (id: String) => {
        const response = await getCargo(id);
        if (response?.id) {
            setCargo(response);
            setShowCargoDrawer(true);
        }
    };

    const handleDetails = () => {
        //TODO details
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setShowDeleteDrawer(true);
    };

    const handleCreateError = () => {
        //TODO error
    };

    const handleEditError = () => {
        //TODO error
    };

    function debouncedSearch(value: string): void {
        throw new Error('Function not implemented.');
    }

    const clearCargoData = () => {
        setCargo(undefined);
        setShowCargoDrawer(false);
    };

    return (
        <>
            <Container>
                <Row>
                    <Col span={18}>
                        <H5>Cargo management</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={handleAddNewCargo}>
                            Add New Cargo
                        </Button>
                    </ReverseCol>
                </Row>
                <Divider />
                <SearchBar
                    onSearch={(value) => debouncedSearch(value)}
                    inputPlaceholder="Search by reference, status, type"
                    onClear={() => {
                        listCargos();
                    }}
                />
                <CargosTable
                    handleUpdateRole={handleUpdateRole}
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEdit}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    data={cargos}
                    loading={isLoading}
                />
            </Container>
            <Drawer
                title={!cargo || !cargo?.id ? 'Add New Cargo' : 'Edit Cargo'}
                width={750}
                visible={showCargoDrawer}
                onClose={clearCargoData}
            >
                <CreateNewCargo
                    closeDrawer={clearCargoData}
                    handleOk={handleAddNewCargoSuccess}
                    cargo={cargo}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                />
            </Drawer>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these cargo?</H5>
                    ) : (
                        <H5>Do you want to remove this cargo?</H5>
                    )
                }
                centered
                visible={showDeleteDrawer}
                onOk={async () => {
                    const toDelete: string[] = [...deleteIds];

                    for (let i = 0; i < toDelete.length; i++) {
                        await deleteCargo(toDelete[i]);
                    }

                    if (!error) {
                        Notification({
                            type: 'success',
                            messageTitle: 'Cargo Deleted',
                            description: 'Cargos has been successfully deleted!',
                        });
                    }

                    setShowDeleteDrawer(false);
                    listCargos();
                }}
                onCancel={() => setShowDeleteDrawer(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these cargo, you will lose all the information associated to them.'
                        : 'If you remove this cargo, you will lose all the associated information.'
                }
            />
        </>
    );
};
export default CargoManagement;
