import { FC } from 'react';
import { Body, Title } from './LegalInfo.styles';

interface Props {
    title: string;
    body: string;
}

const TextBlock: FC<Props> = ({ title, body }) => {
    return (
        <>
            <Title>{title}</Title>
            <Body>{body}</Body>
        </>
    );
};
export default TextBlock;
