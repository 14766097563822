import Notification from 'components/ui/base/notification/Notification';
import BulkUpload, { FileType } from 'components/ui/composed/bulkUpload/BulkUpload';
import useRequest from 'hooks/useRequest';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import {
    downloadProductTemplate,
    downloadUkProductTemplate,
    uploadProductTemplate,
    uploadUkProductTemplate,
} from 'store/products-templates/client';
import { Product } from '../components/ProductTemplatesTable';

interface Props {
    setUploadedProducts: (products: Product[]) => void;
}

const BulkUploadDrawer: FC<Props> = ({ setUploadedProducts }) => {
    const { country } = useParams<{ country: DeclarationCountry }>();
    const { t } = useTranslation('customs_declarations');
    const disabled = false;
    const [uploaded, setUploaded] = useState(false);
    const downloadRequest = country === DeclarationCountry.UK ? downloadUkProductTemplate : downloadProductTemplate;
    const uploadRequest = country === DeclarationCountry.UK ? uploadUkProductTemplate : uploadProductTemplate;
    const { doRequest: uploadProduct, error: uploadError, data: uploadedProducts } = useRequest(uploadRequest);
    const { doRequest: downloadTemplate } = useRequest(downloadRequest);

    useEffect(() => {
        if (uploaded) {
            if (uploadedProducts && !uploadError) {
                setUploadedProducts(uploadedProducts);
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.error-title'),
                    description: t(t('bulk-upload.error-message')),
                });
            }
            setUploaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedProducts, uploaded, uploadError]);

    const uploadFile = async (options: any) => {
        const { file } = options;
        const response = await uploadProduct(file);
        if (response) {
            setUploaded(true);
        }

        return response;
    };

    return (
        <BulkUpload
            disabled={disabled}
            onDownload={downloadTemplate}
            onUpload={uploadFile}
            hasDontOverrideFirstRowText
            fileType={FileType.CSV}
        />
    );
};
export default BulkUploadDrawer;
