import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { EtdNotification } from 'store/declarations/ireland/electronic-transport-document';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { IrelandNotification } from 'store/declarations/ireland/ireland-notification';
import { IrelandTemporaryStorageDeclarationNotification } from 'store/declarations/ireland/temporary-storage-declaration';
import IrelandEnsDeclarationNotificationRow from './IrelandEnsDeclarationNotificationsView';
import IrelandEtdDeclarationNotificationRow from './IrelandEtdNotificationsView';
import IrelandImportDeclarationNotificationRow from './IrelandImportDeclarationNotifications';
import IrelandTsdDeclarationNotificationRow from './IrelandTsdNotificationsView';
import { sortBy } from 'lodash';

const DeclarationsNotificationsView = () => {
    const { declaration } = { ...useDeclarationNotificationContext() };

    const view = useMemo(() => {
        const declarationType =
            declaration?.irelandH7ImportDeclaration ??
            declaration?.entrySummaryDeclaration ??
            declaration?.irelandImportDeclaration ??
            declaration?.irelandETDDeclaration ??
            declaration?.irelandImportTemporaryStorageDeclaration;

        const notifications = (('notificationDate' in (declarationType?.notifications ?? {})) as any)
            ? (sortBy(declarationType?.notifications, 'notificationDate') as
                  | IrelandNotification[]
                  | IrelandEnsNotification[]
                  | EtdNotification[]
                  | IrelandTemporaryStorageDeclarationNotification[]
                  | undefined)
            : declarationType?.notifications;

        return (
            <>
                {notifications?.map((notification) => {
                    switch (declarationType) {
                        case declaration.irelandImportDeclaration:
                        case declaration.irelandH7ImportDeclaration:
                            return (
                                <IrelandImportDeclarationNotificationRow
                                    notification={notification as IrelandNotification}
                                    key={notification.id}
                                />
                            );
                        case declaration.irelandETDDeclaration:
                            return (
                                <IrelandEtdDeclarationNotificationRow
                                    notification={notification as EtdNotification}
                                    key={notification.id}
                                />
                            );
                        case declaration.entrySummaryDeclaration:
                            return (
                                <IrelandEnsDeclarationNotificationRow
                                    notification={notification as IrelandEnsNotification}
                                    key={notification.id}
                                />
                            );
                        case declaration.irelandImportTemporaryStorageDeclaration:
                            return (
                                <IrelandTsdDeclarationNotificationRow
                                    notification={notification as IrelandTemporaryStorageDeclarationNotification}
                                    key={notification.id}
                                />
                            );

                        default:
                            return <></>;
                    }
                })}
            </>
        );
    }, [
        declaration?.irelandH7ImportDeclaration,
        declaration?.entrySummaryDeclaration,
        declaration?.irelandImportDeclaration,
        declaration?.irelandETDDeclaration,
        declaration?.irelandImportTemporaryStorageDeclaration,
    ]);

    return view;
};

export default DeclarationsNotificationsView;

function useDeclarationNotificationContext() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
