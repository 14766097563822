import { Modal, ModalProps, notification } from 'antd';
import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { MouseEvent, ReactElement, createContext, useMemo, useEffect } from 'react';
import CdsAmendmentListForm from './CdsAmendmentListForm';
import * as Yup from 'yup';
import { Shape } from 'views/declarations/utils/validation-utils';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { useParams } from 'react-router-dom';
import { AmendmentSummaryData } from 'store/declarations/common/amendment-summary-data';
import {
    CdsExportDeclarationPayload,
    CdsExportDeclarationPayloadPayload,
    CdsExportGovernmentAgencyGoodsItem,
} from 'store/declarations/uk/export-declaration';
import CdsExportDeclarationUtils from 'views/declarations/uk/export/utils';
import useDeclarations from 'hooks/useDeclarations';
import { fromExternalFieldNameToInternal } from 'utils/validationParserUtils';
import useProducts from 'hooks/useProducts';
import { CdsExportGovernmentAgencyGoodsItemPayload } from 'store/products/reducer';
import useGlobalOverlay from 'hooks/useGlobalOverlay';

const removeNonIncluded = (value: AmendmentRecordFE[]) => {
    return value.filter(({ include }) => include !== false);
};

const amendmentValidationSchema = Yup.array().of(
    Yup.object().shape<Shape<AmendmentRecordFE>>({
        reason: Yup.string().nullable(),
        amendmentReasonCode: Yup.string().when('include', {
            is: (include: boolean | undefined) => include !== false,
            then: Yup.string().required('Field required').nullable(),
            otherwise: Yup.string().nullable(),
        }),
    })
);

export interface AmendmentRecord {
    path: string;
    value: string;
    action: 'ADD' | 'EDIT' | 'DELETE';
    amendmentReasonCode?: string;
    reason?: string;
}
export interface AmendmentRecordFE extends AmendmentRecord {
    include?: boolean;
}

interface Props extends ModalProps {
    amendments: AmendmentRecord[] | undefined;
    submitAmendmentRequest: (value: AmendmentRecord[]) => Promise<void | AmendmentSummaryData>;
    onChangesReverted: () => Promise<boolean>;
}

export const AmendmentContext = createContext<{ previousValues: CdsExportDeclarationPayloadPayload } | null>(null);

const CdsAmendmentModal = ({
    amendments,
    submitAmendmentRequest: submitAmendmentRequestProp,
    onChangesReverted,
    ...modalProps
}: Props): ReactElement => {
    const { declarationId } = useParams();
    const { declarationHistory, declaration, updateUkExportDeclaration, getDeclarationHistory } = useDeclarations();
    const { updateUkExportProduct } = useProducts();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    useEffect(() => {
        if (declarationId) getDeclarationHistory(declarationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const previousValues = useMemo(() => {
        const previousDeclaration = declarationHistory?.at(-1)?.declaration.cdsExportDeclaration;
        if (!previousDeclaration) return {};
        return CdsExportDeclarationUtils.addProductToPayload(previousDeclaration).cdsExportDeclarationPayload;
    }, [declarationHistory]);

    const handleNotIncludedChanges = () => {
        const notIncludedChanges = formik.values.filter((v) => v.include === false);
        if (!notIncludedChanges.length) return;

        // Prepare data
        let declarationData = { ...declaration };
        let productsData = [...(declarationData.cdsExportDeclaration?.cdsExportGovernmentAgencyGoodsItems ?? [])];
        const undoneChangesProducts = new Set<number>();
        let undoneDeclarationChanges = false;
        for (const change of notIncludedChanges) {
            let path = fromExternalFieldNameToInternal(change.path);
            const previousValue = get(previousValues, path);

            if (path.includes('governmentAgencyGoodsItem')) {
                // Product change
                const productIndex = Number(path.split('.').at(2));
                const productData = productsData[productIndex];
                path = path.split('.').slice(3).join('.');
                const governmentAgencyGoodsItem = undoneChangesProducts.has(productIndex) // Clone only once per item
                    ? productData.governmentAgencyGoodsItem!
                    : cloneDeep(productData.governmentAgencyGoodsItem!);
                if (!productData) throw new Error('No such product');
                productsData[productIndex] = {
                    id: productData.id,
                    governmentAgencyGoodsItem: set(
                        governmentAgencyGoodsItem,
                        path,
                        previousValue
                    ) as CdsExportGovernmentAgencyGoodsItem,
                };
                undoneChangesProducts.add(productIndex);
            } else {
                // Declaration change
                if (!declarationData.cdsExportDeclaration?.cdsExportDeclarationPayload) continue;
                undoneDeclarationChanges = true;
                declarationData = {
                    ...declarationData,
                    cdsExportDeclaration: {
                        ...declarationData.cdsExportDeclaration,
                        cdsExportDeclarationPayload: set(
                            cloneDeep(declarationData.cdsExportDeclaration?.cdsExportDeclarationPayload),
                            path,
                            previousValue
                        ),
                    },
                };
            }
        }

        // Execute requests
        const promises = [];
        for (const changedProduct of undoneChangesProducts) {
            const product = productsData[changedProduct] as CdsExportGovernmentAgencyGoodsItemPayload;
            promises.push(updateUkExportProduct(product, declarationId!, product.id!, false));
        }
        if (declarationData.cdsExportDeclaration && undoneDeclarationChanges) {
            promises.push(
                updateUkExportDeclaration(
                    declaration?.customerId!,
                    declarationId!,
                    declarationData as CdsExportDeclarationPayload,
                    false
                )
            );
        }

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Reverting non-included changes...' } });
        return Promise.all(promises);
    };

    const submit = async (value: AmendmentRecord[]) => {
        if (!declarationId) return;

        let notIncludedChanges: any[] | undefined = undefined;
        try {
            notIncludedChanges = await handleNotIncludedChanges();
        } catch (err) {
            console.error(err);
        }
        const errorsOnRevert = notIncludedChanges?.length && (await onChangesReverted());
        const toSubmit = removeNonIncluded(value);
        if (!toSubmit.length && notIncludedChanges?.length) {
            notification.success({ message: 'Changes reverted' });
        }
        if (!errorsOnRevert && toSubmit.length) {
            await submitAmendmentRequestProp(toSubmit);
        }
        formik.resetForm();
        modalProps.onCancel?.(undefined as unknown as MouseEvent<HTMLElement>);
        hideGlobalOverlay();
    };

    const formik = useFormik<AmendmentRecordFE[]>({
        initialValues: amendments ?? [],
        onSubmit: submit,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: amendmentValidationSchema,
    });

    const handleOk = async () => {
        const errors = await formik.validateForm();
        formik.setTouched(setNestedObjectValues(errors, true));
        if (isEmpty(errors)) {
            formik.submitForm();
        }
    };

    return (
        <Modal
            title="Amendment Summary"
            width={1000}
            onOk={handleOk}
            {...modalProps}
            onCancel={(e) => {
                formik.resetForm();
                modalProps.onCancel?.(e);
            }}
        >
            <FormikProvider value={formik as any}>
                <AmendmentContext.Provider value={{ previousValues }}>
                    <CdsAmendmentListForm />
                </AmendmentContext.Provider>
            </FormikProvider>
        </Modal>
    );
};

export default CdsAmendmentModal;
