import { FormikProps, FormikProvider } from 'formik';
import { FC } from 'react';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { validators } from 'views/declarations/uk/export/validations/validations';
import useHideEntities from 'hooks/useHideEntities';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import CardSection from 'views/declarations/common/cards/CardSection';
import useCodelists from '../../../../hooks/useCodelists';
import useNumberOfItemsModal from '../../common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from '../../../../hooks/useProducts';
import { useOutletContext } from 'react-router-dom';

interface Props {
    formik: FormikProps<any>;
}

const IrelandExportForm: FC<Props> = ({ formik }) => {
    const { isFormType } = useHideEntities();
    const { aesCodelists } = useCodelists();
    const { createIrelandExportProduct, listIrelandExportProducts } = useProducts();
    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandExportProduct,
        listProducts: listIrelandExportProducts,
        saveAsDraft,
    });

    return (
        <FormikProvider value={formik}>
            {/* Group 11 - Message Information (including Procedure Codes) */}
            <NewFormCard title="Message Information">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Declaration Type"
                        {...getFormikProps('exportOperation.declarationType', formik)}
                        required
                        hidden={isFormType(['C2'])}
                        codeValidation={[validators.maxLength(5)]}
                        condensed
                        selectOptions={aesCodelists?.declarationType}
                    />
                    <DeclarationSelect
                        label="Additional Declaration Type"
                        {...getFormikProps('exportOperation.additionalDeclarationType', formik)}
                        required
                        hidden={isFormType(['C2'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.additionalDeclarationType}
                    />
                    <DeclarationSelect
                        label="Specific Circumstance Indicator"
                        {...getFormikProps('exportOperation.specificCircumstanceIndicator', formik)}
                        hidden={isFormType(['B3', 'B4', 'C2'])}
                        codeValidation={[validators.exact(3)]}
                        condensed
                        selectOptions={aesCodelists?.specificCircumstanceIndicator}
                    />
                    <DeclarationSelect
                        label="Security"
                        {...getFormikProps('exportOperation.security', formik)}
                        required
                        hidden={isFormType(['B3', 'B4', 'C2'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.exportOperationSecurity}
                    />
                    <DeclarationNumberInput
                        label="Number of Items"
                        {...getFormikProps(`numberOfItems`, formik)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 12 - References of Messages, Documents, Certificates, Authorisations */}
            <MultipleItemsCard
                title="Previous Document"
                path="goodsShipment.previousDocument"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Type', value: list.type },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.previousDocumentType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="goodsShipment.additionalInformation"
                hidden={isFormType(['C2'])}
                initialValues={{ code: '', text: '' }}
                list={(list) => [
                    { field: 'Code', value: list.code },
                    { field: 'Text', value: list.text },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Code"
                            {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                            required
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={aesCodelists?.additionalInformationCode}
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Supporting Document"
                path="goodsShipment.supportingDocument"
                hidden={isFormType(['C2'])}
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    issuingAuthorityName: '',
                    validityDate: '',
                    documentLineItemNumber: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Type', value: list.type },
                    { field: 'Issuing Authority Name', value: list.issuingAuthorityName },
                    { field: 'Date of Validity', value: list.validityDate },
                    { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.supportingDocumentType}
                        />
                        <DeclarationInput
                            label="Issuing Authority Name"
                            {...getFormikProps(addPathPrefix(path, 'issuingAuthorityName'), formik)}
                            condensed
                        />
                        <DeclarationDatepicker
                            label="Date of Validity"
                            {...getFormikProps(addPathPrefix(path, 'validityDate'), formik)}
                            condensed
                        />
                        <DeclarationNumberInput
                            label="Document Line Item Number"
                            {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Reference"
                path="goodsShipment.additionalReference"
                hidden={isFormType(['B3', 'B4', 'C2'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Type', value: list.type },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.additionalReferenceType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Document"
                path="goodsShipment.consignment.transportDocument"
                hidden={isFormType(['B3', 'C2'])}
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Type', value: list.type },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={aesCodelists?.transportDocumentType}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="References of Messages" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <DeclarationInput
                        label="Reference Number/UCR"
                        {...getFormikProps('goodsShipment.consignment.referenceNumberUCR', formik)}
                        hidden={isFormType(['B3', 'C2'])}
                        condensed
                    />
                    <DeclarationSelect
                        label="Warehouse - Type"
                        {...getFormikProps('goodsShipment.warehouse.type', formik)}
                        hidden={isFormType(['C1', 'C2'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.warehouseType}
                    />
                    <DeclarationInput
                        label="Warehouse - Identifier"
                        {...getFormikProps('goodsShipment.warehouse.identifier', formik)}
                        hidden={isFormType(['C1', 'C2'])}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Authorisation"
                path="authorisation"
                initialValues={{ type: '', referenceNumber: '', holderOfAuthorisation: '' }}
                list={(list) => [
                    { field: 'Type', value: list.type },
                    { field: 'Reference Number', value: list.referenceNumber },
                    { field: 'Holder of Authorisation', value: list.holderOfAuthorisation },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={aesCodelists?.authorisationType}
                        />
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                        />
                        <DeclarationInput
                            label="Holder of Authorisation"
                            {...getFormikProps(addPathPrefix(path, 'holderOfAuthorisation'), formik)}
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            {/* Group 13 - Parties */}
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: undefined,
                        eoriRequired: true,
                        hidden: isFormType(['C2']),
                    },
                    {
                        path: 'goodsShipment.consignment.consignor',
                        header: 'Consignor',
                        refNumber: undefined,
                        hidden: isFormType(['B2', 'B3', 'B4', 'C2']),
                    },
                    {
                        path: 'goodsShipment.consignment.consignee',
                        header: 'Consignee',
                        refNumber: undefined,
                        eoriRequired: isFormType(['B2', 'B3', 'B4']),
                        hidden: isFormType(['C2']),
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: undefined,
                        eoriRequired: true,
                        contactPerson: {
                            present: true,
                            hidden: isFormType(['C2']),
                        },
                    },
                    {
                        path: 'representative',
                        header: 'Representative',
                        refNumber: undefined,
                        hidden: isFormType(['C2']),
                        contactPerson: {
                            present: true,
                        },
                        hasRepresentativeStatus: true,
                        hasAddress: false,
                    },
                    {
                        path: 'goodsShipment.consignment.carrier',
                        header: 'Carrier',
                        refNumber: undefined,
                        hasAddress: false,
                        hidden: isFormType(['B3', 'B4', 'C2']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                    contactPerson: {
                        name: 'contactPerson.name',
                        phoneNumber: 'contactPerson.phoneNumber',
                        email: 'contactPerson.emailAddress',
                    },
                    additional: {
                        representativeStatus: 'status',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Additional Supply Chain Actor"
                path="goodsShipment.additionalSupplyChainActor"
                hidden={isFormType(['C2'])}
                initialValues={{ role: '', identificationNumber: '' }}
                list={(list) => [
                    { field: 'Role', value: list.role },
                    { field: 'Identification Number', value: list.identificationNumber },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role"
                            {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                            required
                            codeValidation={[validators.maxLength(3)]}
                            condensed
                            selectOptions={aesCodelists?.additionalSupplyChainActorRoleCode}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            required
                            condensed
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            {/* Group 14 - Valuation Information / Taxes */}
            <NewFormCard title="Valuation Information / Taxes">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Transport Charges - Method of Payment"
                        {...getFormikProps('goodsShipment.consignment.transportCharges.methodOfPayment', formik)}
                        hidden={isFormType(['B3', 'B4', 'C2'])}
                        codeValidation={[validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportChargesMethodOfPayment}
                    />
                    <CurrencySelect
                        label="Invoice Currency"
                        {...getFormikProps('exportOperation.invoiceCurrency', formik)}
                        hidden={isFormType(['B3', 'C1', 'C2'])}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Total Amount Invoiced"
                        {...getFormikProps('exportOperation.totalAmountInvoiced', formik)}
                        hidden={isFormType(['B3', 'C1', 'C2'])}
                        condensed
                    />
                    <CurrencySelect
                        selectOptions={aesCodelists?.internalCurrencyUnit}
                        label="Currency Exchange - Internal Currency Unit"
                        {...getFormikProps('currencyExchange.internalCurrencyUnit', formik)}
                        condensed
                    />
                    <DeclarationNumberInput
                        label="Currency Exchange - Exchange Rate"
                        {...getFormikProps('currencyExchange.exchangeRate', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Deferred Payment"
                        {...getFormikProps('deferredPayment.deferredPayment', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        label="Nature of Transaction"
                        {...getFormikProps('goodsShipment.natureOfTransaction', formik)}
                        codeValidation={[validators.number(), validators.maxLength(2)]}
                        condensed
                        selectOptions={aesCodelists?.natureOfTransactionCode}
                    />
                    <CardSection title="Delivery Terms">
                        <DeclarationSelect
                            label="Incoterm Code"
                            {...getFormikProps('goodsShipment.deliveryTerms.incotermCode', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2'])}
                            codeValidation={[validators.exact(3)]}
                            condensed
                            selectOptions={aesCodelists?.incotermCode}
                        />
                        <DeclarationSelect
                            label="UN/LOCODE"
                            {...getFormikProps('goodsShipment.deliveryTerms.unLocode', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2'])}
                            codeValidation={[validators.maxLength(17)]}
                            condensed
                            selectOptions={aesCodelists?.deliveryTermsUnLocode}
                        />
                        <CountrySelect
                            label="Country"
                            {...getFormikProps('goodsShipment.deliveryTerms.country', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2'])}
                            condensed
                            selectOptions={aesCodelists?.country}
                        />
                        <DeclarationInput
                            label="Location"
                            {...getFormikProps('goodsShipment.deliveryTerms.location', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2'])}
                            condensed
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps('goodsShipment.deliveryTerms.text', formik)}
                            hidden={isFormType(['B3', 'C1', 'C2'])}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            {/* Group 15 - Dates - Times - Periods */}
            <NewFormCard title="Dates - Times - Periods" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <DeclarationDatepicker
                        label="Date and time of presentation of the goods"
                        {...getFormikProps('exportOperation.presentationOfTheGoodsDateAndTime', formik)}
                        hidden={isFormType(['C2'])}
                        showTime
                        condensed
                        getPopupContainer={() => document.body}
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 16 - Places / Countries / Regions */}
            <NewFormCard title="Places / Countries / Regions" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <CountrySelect
                        label="Country of Destination"
                        {...getFormikProps('goodsShipment.countryOfDestination', formik)}
                        hidden={isFormType(['C2'])}
                        condensed
                        selectOptions={aesCodelists?.country}
                    />
                    <CountrySelect
                        label="Country of Export"
                        {...getFormikProps('goodsShipment.countryOfExport', formik)}
                        hidden={isFormType(['B4', 'C2'])}
                        condensed
                        selectOptions={aesCodelists?.countryCodesCommunity}
                    />
                    <MultipleDeclarationField
                        parent="goodsShipment.consignment.countryOfRoutingOfConsignment"
                        name="country"
                    >
                        {(fieldProps, controls) => (
                            <CountrySelect
                                label="Country of Routing of Consignment"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                hidden={isFormType(['B3', 'B4', 'C2'])}
                                multipleF={controls}
                                condensed
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Location of Goods">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Type of Location"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.typeOfLocation', formik)}
                        condensed
                        selectOptions={aesCodelists?.typeOfLocation}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationSelect
                        label="Qualifier of Identification"
                        {...getFormikProps(
                            'goodsShipment.consignment.locationOfGoods.qualifierOfIdentification',
                            formik
                        )}
                        condensed
                        selectOptions={aesCodelists?.locationOfGoodsQualifierOfIdentification}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationInput
                        label="UN/LOCODE"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.unLocode', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Authorisation Number"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.authorisationNumber', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Additional Identifier"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.additionalIdentifier', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        label="Customs Office - Reference Number"
                        {...getFormikProps(
                            'goodsShipment.consignment.locationOfGoods.customsOffice.referenceNumber',
                            formik
                        )}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        selectOptions={aesCodelists?.customsOfficeReferenceNumber}
                    />
                    <DeclarationInput
                        label="Latitude"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.gnss.latitude', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Longitude"
                        {...getFormikProps('goodsShipment.consignment.locationOfGoods.gnss.longitude', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Economic Operator - Identification Number"
                        {...getFormikProps(
                            'goodsShipment.consignment.locationOfGoods.economicOperator.identificationNumber',
                            formik
                        )}
                        condensed
                    />
                    <CardSection title="Address">
                        <DeclarationInput
                            label="Street and Number"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.address.streetAndNumber',
                                formik
                            )}
                            condensed
                        />
                        <DeclarationInput
                            label="Postcode"
                            {...getFormikProps('goodsShipment.consignment.locationOfGoods.address.postcode', formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="City"
                            {...getFormikProps('goodsShipment.consignment.locationOfGoods.address.city', formik)}
                            condensed
                        />
                        <CountrySelect
                            label="Country"
                            {...getFormikProps('goodsShipment.consignment.locationOfGoods.address.country', formik)}
                            condensed
                            selectOptions={aesCodelists?.countryCodesCommunity}
                        />
                    </CardSection>
                    <CardSection title="Postcode Address">
                        <DeclarationInput
                            label="House Number"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.postcodeAddress.houseNumber',
                                formik
                            )}
                            condensed
                        />
                        <DeclarationInput
                            label="Postcode"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.postcodeAddress.postcode',
                                formik
                            )}
                            condensed
                        />
                        <CountrySelect
                            label="Country"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.postcodeAddress.country',
                                formik
                            )}
                            condensed
                            selectOptions={aesCodelists?.countryCodesCommunity}
                        />
                    </CardSection>
                    <CardSection title="Contact Person">
                        <DeclarationInput
                            label="Name"
                            {...getFormikProps('goodsShipment.consignment.locationOfGoods.contactPerson.name', formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Phone Number"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.contactPerson.phoneNumber',
                                formik
                            )}
                            condensed
                        />
                        <DeclarationInput
                            label="E-mail Address"
                            {...getFormikProps(
                                'goodsShipment.consignment.locationOfGoods.contactPerson.emailAddress',
                                formik
                            )}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            {/* Group 17 - Customs Offices */}
            <NewFormCard title="Customs Offices">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Customs Office of Exit (Declared) - Reference Number"
                        {...getFormikProps('customsOfficeOfExitDeclared.referenceNumber', formik)}
                        required
                        hidden={isFormType(['C2'])}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        selectOptions={aesCodelists?.customsOfficeOfExitDeclaredReferenceNumber}
                    />
                    <DeclarationSelect
                        label="Customs Office of Export - Reference Number"
                        {...getFormikProps('customsOfficeOfExport.referenceNumber', formik)}
                        required
                        hidden={isFormType(['C2'])}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        selectOptions={aesCodelists?.customsOfficeOfExportReferenceNumber}
                    />
                    <DeclarationSelect
                        label="Customs Office of Presentation - Reference Number"
                        {...getFormikProps('customsOfficeOfPresentation.referenceNumber', formik)}
                        codeValidation={[validators.exact(8)]}
                        condensed
                        selectOptions={aesCodelists?.customsOfficeOfPresentation}
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 18 - Goods Identification */}
            <NewFormCard title="Goods Identification" hidden={isFormType(['C2'])}>
                <FormCardContainer>
                    <DeclarationNumberInput
                        label="Gross Mass"
                        {...getFormikProps('goodsShipment.consignment.grossMass', formik)}
                        hidden={isFormType(['C2'])}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            {/* Group 19 - Transport Information */}
            <NewFormCard title="Transport Information" hidden={isFormType(['C1', 'C2'])}>
                <FormCardContainer>
                    <DeclarationSelect
                        label="Container Indicator"
                        {...getFormikProps('goodsShipment.consignment.containerIndicator', formik)}
                        hidden={isFormType(['C1', 'C2'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.flag}
                    />
                    <DeclarationSelect
                        label="Mode of Transport at the Border"
                        {...getFormikProps('goodsShipment.consignment.modeOfTransportAtTheBorder', formik)}
                        hidden={isFormType(['C1', 'C2'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportMode}
                    />
                    <DeclarationSelect
                        label="Inland Mode of Transport"
                        {...getFormikProps('goodsShipment.consignment.inlandModeOfTransport', formik)}
                        hidden={isFormType(['B4', 'C1', 'C2'])}
                        codeValidation={[validators.number(), validators.exact(1)]}
                        condensed
                        selectOptions={aesCodelists?.transportMode}
                    />
                    <CardSection title="Active Border Transport Means">
                        <DeclarationSelect
                            label="Type of Identification"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.typeOfIdentification',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            codeValidation={[validators.number(), validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfIdentificationOfMeansOfTransportActive}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.identificationNumber',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            condensed
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(
                                'goodsShipment.consignment.activeBorderTransportMeans.nationality',
                                formik
                            )}
                            hidden={isFormType(['B2', 'B4', 'C1', 'C2'])}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.nationality}
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Departure Transport Means"
                path="goodsShipment.consignment.departureTransportMeans"
                hidden={isFormType(['B4', 'C1', 'C2'])}
                initialValues={{ typeOfIdentification: '', identificationNumber: '', nationality: '' }}
                list={(list) => [
                    { field: 'Type of Identification', value: list.typeOfIdentification },
                    { field: 'Identification Number', value: list.identificationNumber },
                    { field: 'Nationality', value: list.nationality },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type of Identification"
                            {...getFormikProps(addPathPrefix(path, 'typeOfIdentification'), formik)}
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            codeValidation={[validators.number(), validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.typeOfIdentificationOfMeansOfTransportActive}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            required={isFormType(['B1', 'B3'])} // TODO check required all?
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            condensed
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(addPathPrefix(path, 'nationality'), formik)}
                            hidden={isFormType(['B4', 'C1', 'C2'])}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={aesCodelists?.nationality}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Equipment"
                path="goodsShipment.consignment.transportEquipment"
                hidden={isFormType(['C1', 'C2'])}
                initialValues={{
                    containerIdentificationNumber: '',
                    numberOfSeals: '',
                    seal: [{ identifier: '' }],
                    goodsReference: [{ declarationGoodsItemNumber: '' }],
                }}
                list={(list) => [
                    { field: 'Container Identification Number', value: list.containerIdentificationNumber },
                    { field: 'Number of Seals', value: list.numberOfSeals },
                    {
                        field: 'Seals',
                        value: list.seal?.map((seal) => seal.identifier).join(', '),
                    },
                    {
                        field: 'Goods References',
                        value: list.goodsReference
                            ?.map((goodsReference) => goodsReference.declarationGoodsItemNumber)
                            .join(', '),
                    },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Container Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'containerIdentificationNumber'), formik)}
                            hidden={isFormType(['C1', 'C2'])}
                            condensed
                        />
                        <DeclarationNumberInput
                            required
                            label="Number of Seals"
                            {...getFormikProps(addPathPrefix(path, 'numberOfSeals'), formik)}
                            hidden={isFormType(['B3', 'B4', 'C2'])}
                            condensed
                        />
                        <MultipleDeclarationField parent={addPathPrefix(path, 'seal')} name="identifier">
                            {(fieldProps, controls) => (
                                <DeclarationInput
                                    required
                                    label="Seal Identifier"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    hidden={isFormType(['B3', 'B4', 'C2'])}
                                    multipleF={controls}
                                    condensed
                                />
                            )}
                        </MultipleDeclarationField>
                        <MultipleDeclarationField
                            parent={addPathPrefix(path, 'goodsReference')}
                            name="declarationGoodsItemNumber"
                        >
                            {(fieldProps, controls) => (
                                <DeclarationNumberInput
                                    required
                                    label="Goods Reference - Declaration Goods Item Number"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    hidden={isFormType(['C1', 'C2'])}
                                    multipleF={controls}
                                    condensed
                                />
                            )}
                        </MultipleDeclarationField>
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Test purposes">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Phase Id"
                        {...getFormikProps('phaseID', formik)}
                        codeValidation={[validators.maxLength(12)]}
                        condensed
                        selectOptions={[
                            { id: 'AES_1_0', value: 'Phase AES 1.1' },
                            { id: 'AES_1_1', value: 'Phase AES 1.0' },
                        ]}
                    />
                </FormCardContainer>
            </NewFormCard>
            {modalContextHolder}
        </FormikProvider>
    );
};
export default IrelandExportForm;
