import { createCustomer, deleteCustomer, editCustomer, getCustomer, listCustomers } from './client';
import { Customer, CustomerParams } from './customer';

export const doCreateCustomer: Function = (customer: Customer) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_CUSTOMER_REQUEST' });
    try {
        const payload = await createCustomer(customer);

        dispatch({
            type: 'CREATE_CUSTOMER_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_CUSTOMER_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doListCustomers: Function = (params?: Partial<CustomerParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_CUSTOMERS_REQUEST' });
    try {
        const payload = await listCustomers(params);
        dispatch({
            type: 'LIST_CUSTOMERS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_CUSTOMERS_ERROR', error: e?.response?.data });
    }
};

export const doGetCustomer: Function = (customerId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_CUSTOMER_REQUEST' });
    try {
        const payload = await getCustomer(customerId);
        dispatch({
            type: 'GET_CUSTOMER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_CUSTOMER_ERROR', error: e?.response?.data });
    }
};

export const doEditCustomer: Function = (customerId: string, customer: Customer) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_CUSTOMER_REQUEST' });
    try {
        const payload = await editCustomer(customerId, customer);
        dispatch({
            type: 'EDIT_CUSTOMER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_CUSTOMER_ERROR', error: e?.response?.data });
    }
};

export const doDeleteCustomer: Function = (customerId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_CUSTOMER_REQUEST' });
    try {
        const payload = await deleteCustomer(customerId);
        dispatch({
            type: 'DELETE_CUSTOMER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_CUSTOMER_ERROR', error: e?.response?.data });
    }
};
