import { DefaultOptionType } from 'antd/lib/select';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCountries from 'hooks/useCountries';
import { FC, FocusEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodelists from '../../../../hooks/useCodelists';
import useFormUtils from '../../../../hooks/useFormUtils';
import { normalizeCountriesToSelect } from '../selects/utils';
import DeclarationSelect, {
    FormSelectProps,
    GroupSelectOption,
    MultipleProps,
    SelectOption,
} from './formSelect/DeclarationSelect';
import { TestProps } from 'core/utils/testTypes';

export type CountryProps = TestProps &
    Omit<FormSelectProps, 'codeValidation'> & {
        refNumber?: string | string[];
        label?: string;
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
        icon?: boolean;
        required?: boolean;
        viewOnly?: boolean;
        multiple?: MultipleProps;
        tooltip?: string;
        refClicked?: (refNumber: string | number) => void;
        disabled?: boolean;
        condensed?: boolean;
        multipleF?: {
            onAdd: () => void;
            onRemove: () => void;
            canAdd?: boolean;
            canRemove?: boolean;
        };
        onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
        onBlur?: FocusEventHandler<HTMLInputElement>;
        selectOptions?: Readonly<SelectOption[]> | Readonly<GroupSelectOption>;
    };

/**
 * @argument selectOptions -  used only for AES
 */
const CountrySelect: FC<CountryProps> = ({
    refNumber,
    label,
    fieldProps,
    fieldMeta,
    required,
    fieldHelper,
    viewOnly,
    multiple,
    tooltip,
    refClicked,
    disabled,
    condensed,
    multipleF,
    onChange,
    onBlur,
    testId,
    selectOptions,
    ...selectProps
}) => {
    const { t } = useTranslation('common');
    const { countries } = useCountries();
    const { aisCodelists, cdsCodelists, aesCodelists } = useCodelists();
    const { isCds, isAis, isAes } = useFormUtils();

    const countriesCodelist = useMemo(() => {
        if (selectOptions) return selectOptions;
        else if (isAis) {
            return aisCodelists?.countryCodesFullList;
        } else if (isCds) {
            return cdsCodelists?.countryTypes;
        } else if (isAes) {
            return aesCodelists?.countryCodesForAddress;
        }
        return normalizeCountriesToSelect(countries.list);
    }, [
        aisCodelists?.countryCodesFullList,
        cdsCodelists?.countryTypes,
        countries.list,
        isAis,
        isCds,
        isAes,
        aesCodelists?.countryCodesForAddress,
        selectOptions,
    ]);

    const fieldEvents = useMemo(() => {
        if (onBlur) return { onBlur: (e: any) => onBlur(e) };
        return undefined;
    }, [onBlur]);

    return (
        <DeclarationSelect
            viewOnly={viewOnly}
            required={required}
            refNumber={refNumber}
            label={!label ? t('address.country') : label}
            tooltip={tooltip}
            multipleF={multipleF}
            selectOptions={countriesCodelist}
            fieldProps={fieldProps}
            fieldMeta={fieldMeta}
            fieldHelper={fieldHelper}
            multiple={multiple}
            refClicked={refClicked}
            disabled={disabled}
            condensed={condensed}
            onChange={onChange}
            fieldEvents={fieldEvents}
            testId={testId}
            hideCodelistMenu
            {...selectProps}
        />
    );
};

export default CountrySelect;
