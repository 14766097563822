import { Divider, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { get, isArray, isBoolean, isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import TemplateCheckbox from '../../../../../../components/ui/composed/template/TemplateCheckbox';
import {
    OmittedParsedField,
    ParsedCard,
    ParsedField,
    ParsedForm,
    ParsedItem,
} from '../../../../../../store/declaration-form-errors/ParsedForm';
import { IrelandImportDeclaration } from '../../../../../../store/declarations/ireland/import-declaration';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { ieH1PartiesData } from '../h1-skeleton-declaration';
import { LMap } from './InvoiceModal';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
    shouldDisableCustomer,
    shouldDisableProduct,
    shouldDisableCommodityCode,
    shouldDisableTaricCode,
    getSelectedSuggestedTaric,
    getProductFieldPath,
    getCustomerFieldPath,
} from './invoice-utils';
import {
    CardContent,
    CardTitle,
    Header,
    HeaderTitle,
    SuggestedTaricContainer,
    SuggestedTaricValueWrapper,
} from './InvoiceBody.styles';

interface Props {
    activeForm?: 'master' | 'product';
    formikValues?: IrelandImportDeclaration;
    selectedSuggestedValuesMap: LMap;
    setSelectedSuggestedValues?: Dispatch<SetStateAction<LMap>>;
    parsedInvoiceResponse: ParsedForm | undefined;
    setSelectedCustomers?: Dispatch<SetStateAction<Record<string, string[]>>>;
    selectedCustomers: Record<string, string[]>;
    setSelectedProductAction?: Dispatch<SetStateAction<Record<string, string | undefined>>>;
    selectedProductsActions: Record<string, string | undefined>;
    failedToGetSuggestedTarics: boolean;
}

const InvoiceBody = ({
    activeForm,
    formikValues,
    selectedSuggestedValuesMap,
    setSelectedSuggestedValues,
    parsedInvoiceResponse,
    setSelectedCustomers,
    selectedCustomers,
    setSelectedProductAction,
    selectedProductsActions,
    failedToGetSuggestedTarics,
}: Props) => {
    const handleCheckSuggestion = useCallback(
        (suggestedValuePath: string | undefined, suggestedValue: string | number | boolean | string[] | undefined) => {
            if (!suggestedValuePath) return;
            setSelectedSuggestedValues?.((oldMap: LMap) => new Map(oldMap.set(suggestedValuePath, suggestedValue)));
        },
        [setSelectedSuggestedValues]
    );

    const handleUnCheckSuggestion = useCallback(
        (suggestedValuePath: string | undefined) => {
            if (!suggestedValuePath) return;
            setSelectedSuggestedValues?.((oldMap: LMap) => {
                oldMap.delete(suggestedValuePath);
                return new Map(oldMap);
            });
        },
        [setSelectedSuggestedValues]
    );

    const isChecked = useCallback(
        (suggestedValuePath: string | undefined) =>
            !!suggestedValuePath && selectedSuggestedValuesMap?.has(suggestedValuePath),
        [selectedSuggestedValuesMap]
    );

    const getCurrentValueFieldPath = useCallback(
        (suggestedValuePath: string | undefined): string => {
            if (!suggestedValuePath) return '';
            else if (suggestedValuePath.includes('product'))
                return getProductFieldPath(suggestedValuePath, selectedProductsActions);
            else if (suggestedValuePath.includes('customer'))
                return getCustomerFieldPath(suggestedValuePath, selectedCustomers);
            else return suggestedValuePath;
        },
        [selectedProductsActions, selectedCustomers]
    );

    const getCurrentValue = useCallback(
        (suggestedValuePath: string | undefined) => {
            const currentValue = get(formikValues, getCurrentValueFieldPath(suggestedValuePath), '-');

            if (isBoolean(currentValue)) return <TemplateCheckbox size="small" checked={currentValue} />;
            else if (isArray(currentValue)) return currentValue.map((value) => <div key={`${value}`}>{value}</div>);
            else return currentValue;
        },
        [formikValues, getCurrentValueFieldPath]
    );

    const handleSuggestionCheckboxOnChange = useCallback(
        (
            newCheckedStatus: boolean,
            suggestedValuePath: string | undefined,
            suggestedValue: string | number | boolean | string[] | undefined
        ) => {
            if (newCheckedStatus) handleCheckSuggestion(suggestedValuePath, suggestedValue);
            else handleUnCheckSuggestion(suggestedValuePath);
        },
        [handleCheckSuggestion, handleUnCheckSuggestion]
    );

    const handleInvoiceNumberPublishEvent = useCallback(
        (newCheckedStatus: boolean, suggestedValuePath: string | undefined, suggestedValue: unknown) => {
            if (suggestedValuePath !== 'goodsShipment.producedDocumentsWritingOff.N935.[0]') return;
            let invoiceNumberChanged: boolean;

            if (newCheckedStatus) {
                const currentValue = get(formikValues, suggestedValuePath, '-');
                invoiceNumberChanged = currentValue !== suggestedValue;
            } else invoiceNumberChanged = false;

            window.EventBus.publish('invoiceNumberChanged', invoiceNumberChanged);
        },
        [formikValues]
    );

    const customerSelectOptions = useMemo(() => {
        const partiesSelectOptions = ieH1PartiesData.map((party) => ({ label: party.header, value: party.path }));

        if (!selectedCustomers) return partiesSelectOptions;

        return partiesSelectOptions.map((party) => ({
            ...party,
            className: `invoice-customer-option-disabled-${Object.values(selectedCustomers).some((customer) =>
                customer.includes(party.value)
            )}`,
        }));
    }, [selectedCustomers]);

    const handleChangeCustomerSelect = useCallback(
        (customerPaths: string[], customerName: string) => {
            if (!customerPaths) return;
            setSelectedCustomers?.((allValues: Record<string, any[]> | undefined) => ({
                ...allValues,
                [customerName.replace(' ', '').toLowerCase()]: customerPaths,
            }));
        },
        [setSelectedCustomers]
    );

    const productSelectOptions = useMemo(() => {
        const productSelectOptions = formikValues?.goodsShipment?.goodsShipmentItem?.map((product, index) => ({
            label: `Update declaration product ${index + 1}`,
            value: `goodsShipment.goodsShipmentItem.[${index}] ${product.id}`,
        }));

        productSelectOptions?.unshift({
            label: 'Create new declaration product',
            value: '',
        });

        if (isEmpty(selectedProductsActions)) return productSelectOptions;

        return productSelectOptions?.map((productSelectOption) => {
            if (productSelectOption.label === 'Create new declaration product') return productSelectOption;

            return {
                ...productSelectOption,
                className: `invoice-product-option-disabled-${Object.values(selectedProductsActions).some(
                    (selectedProductAction: string | undefined) => selectedProductAction === productSelectOption.value
                )}`,
            };
        });
    }, [formikValues?.goodsShipment?.goodsShipmentItem, selectedProductsActions]);

    /*
     *  selectedProductAction explanation
     *
     *  Update action - `productPath productId`
     *  Create action - ''
     *  Default action - undefined
     */

    const handleChangeProductAction = useCallback(
        (selectedProductAction: string | undefined, productPath: string) =>
            setSelectedProductAction?.((selectedProductsActions: Record<string, string | undefined>) => ({
                ...selectedProductsActions,
                [productPath]: selectedProductAction,
            })),
        [setSelectedProductAction]
    );

    const isDisabled = useCallback(
        (suggestedValuePath: string | undefined, isOcrTaric?: boolean) => {
            if (suggestedValuePath?.includes('product')) {
                if (shouldDisableProduct(suggestedValuePath, selectedProductsActions)) return true;

                if (isOcrTaric) return false;
                else if (suggestedValuePath?.includes('combinedNomenclatureCode')) {
                    return shouldDisableCommodityCode(suggestedValuePath, selectedSuggestedValuesMap);
                } else if (suggestedValuePath?.includes('taricCode'))
                    return shouldDisableTaricCode(suggestedValuePath, selectedSuggestedValuesMap);
            } else if (suggestedValuePath?.includes('customer'))
                return shouldDisableCustomer(suggestedValuePath, selectedCustomers);

            return false;
        },
        [selectedProductsActions, selectedCustomers, selectedSuggestedValuesMap]
    );

    const handleUnCheckPairOfSuggestedTarics = useCallback(
        (newCheckedStatus: boolean, suggestedValuePath: string | undefined) => {
            if (newCheckedStatus || !suggestedValuePath?.includes('combinedNomenclatureCode')) return;

            const pairTypeIndex = suggestedValuePath.slice(-1);
            const pairProductIndex = suggestedValuePath.split('.')[0];

            const selectedTaricCodePath = getSelectedSuggestedTaric(
                selectedSuggestedValuesMap,
                `${pairProductIndex}.goodsInformation.taricCode${pairTypeIndex}`
            );
            if (!selectedTaricCodePath) return;

            handleUnCheckSuggestion(selectedTaricCodePath);
        },
        [selectedSuggestedValuesMap, handleUnCheckSuggestion]
    );

    const isTarics = useCallback(
        (suggestedValuePath: string | undefined) =>
            suggestedValuePath?.includes('combinedNomenclatureCode') || suggestedValuePath?.includes('taricCode'),
        []
    );

    const isOcrTarics = useCallback(
        (suggestedValuePath: string | undefined, suggestedValue: string | number | boolean | string[] | undefined) =>
            isTarics(suggestedValuePath) && !isArray(suggestedValue),
        [isTarics]
    );

    const isSuggestedTarics = useCallback(
        (suggestedValuePath: string | undefined, suggestedValue: string | number | boolean | string[] | undefined) =>
            isTarics(suggestedValuePath) && isArray(suggestedValue),
        [isTarics]
    );

    const getFields = useCallback(
        (fields: ParsedField[] | undefined) =>
            fields?.map(({ name, message: suggestedValue, path: suggestedValuePath }: ParsedField) => {
                let suggestedValueColumnRender;
                let selectColumnRender;

                const defaultSuggestedValueColumn = (
                    <div>
                        {isBoolean(suggestedValue) ? (
                            <TemplateCheckbox size="small" checked={suggestedValue} />
                        ) : isArray(suggestedValue) ? (
                            suggestedValue.map((value) => <div key={`${value}`}>{value}</div>)
                        ) : (
                            suggestedValue
                        )}
                    </div>
                );

                const defaultSelectColumn = (
                    <TemplateCheckbox
                        size="medium"
                        onChange={({ target: { checked: newCheckedStatus } }: CheckboxChangeEvent) => {
                            handleSuggestionCheckboxOnChange(newCheckedStatus, suggestedValuePath, suggestedValue);
                            handleInvoiceNumberPublishEvent(newCheckedStatus, suggestedValuePath, suggestedValue);
                        }}
                        checked={isChecked(suggestedValuePath)}
                        disabled={isDisabled(suggestedValuePath, isOcrTarics(suggestedValuePath, suggestedValue))}
                    />
                );

                const taricsSuggestedValueColumn = (
                    <div>
                        {failedToGetSuggestedTarics && isEmpty(suggestedValue) && isArray(suggestedValue) ? (
                            <CloseCircleOutlined style={{ color: 'red' }} />
                        ) : !isEmpty(suggestedValue) && isArray(suggestedValue) ? (
                            <div style={{ display: 'flex' }}>
                                {suggestedValue?.map((taricSuggestedValue: string, index: number) => (
                                    <div style={{ display: 'flex' }} key={`${suggestedValuePath}${index}`}>
                                        {index === 1 && <Divider type="vertical" style={{ height: 52 }} />}
                                        <SuggestedTaricContainer>
                                            <SuggestedTaricValueWrapper>
                                                {taricSuggestedValue}
                                            </SuggestedTaricValueWrapper>
                                            <TemplateCheckbox
                                                size="medium"
                                                onChange={({
                                                    target: { checked: newCheckedStatus },
                                                }: CheckboxChangeEvent) => {
                                                    handleSuggestionCheckboxOnChange(
                                                        newCheckedStatus,
                                                        `${suggestedValuePath}${index}`,
                                                        taricSuggestedValue
                                                    );

                                                    handleUnCheckPairOfSuggestedTarics(
                                                        newCheckedStatus,
                                                        `${suggestedValuePath}${index}`
                                                    );
                                                }}
                                                checked={isChecked(`${suggestedValuePath}${index}`)}
                                                disabled={isDisabled(`${suggestedValuePath}${index}`)}
                                            />
                                        </SuggestedTaricContainer>
                                        {index === 1 && <Divider type="vertical" style={{ height: 52 }} />}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <LoadingOutlined style={{ color: 'blue' }} />
                        )}
                    </div>
                );

                if (isSuggestedTarics(suggestedValuePath, suggestedValue)) {
                    suggestedValueColumnRender = taricsSuggestedValueColumn;
                    selectColumnRender = <></>;
                } else {
                    suggestedValueColumnRender = defaultSuggestedValueColumn;
                    selectColumnRender = defaultSelectColumn;
                }

                return (
                    <FormCardContainer invoice key={`${name}_${suggestedValuePath}`}>
                        <div>{name}</div>
                        <div>{getCurrentValue(suggestedValuePath)}</div>
                        {suggestedValueColumnRender}
                        {selectColumnRender}
                    </FormCardContainer>
                );
            }),
        [
            getCurrentValue,
            handleSuggestionCheckboxOnChange,
            handleInvoiceNumberPublishEvent,
            isChecked,
            isDisabled,
            failedToGetSuggestedTarics,
            handleUnCheckPairOfSuggestedTarics,
            isSuggestedTarics,
            isOcrTarics,
        ]
    );

    const getMasterDetails = useCallback(
        (cards: ParsedCard[] | undefined) =>
            cards?.map(({ name, fields }) => (
                <div key={`${name}_fields_${fields?.length}`}>
                    <CardContent>
                        <Header>
                            <HeaderTitle>
                                <CardTitle>{name}</CardTitle>
                                {name.includes('Customer') && (
                                    <Select
                                        mode="multiple"
                                        placeholder="Select customers"
                                        style={{ width: 300, zIndex: 0 }}
                                        options={customerSelectOptions}
                                        onChange={(value: string[]) => handleChangeCustomerSelect(value, name)}
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        value={selectedCustomers[name.replace(' ', '').toLowerCase()]}
                                    />
                                )}
                            </HeaderTitle>

                            <TemplateCheckbox
                                onChange={({ target: { checked: newCheckedStatus } }: CheckboxChangeEvent) =>
                                    (fields as OmittedParsedField[])?.forEach(
                                        ({ path: suggestedValuePath, message: suggestedValue }) =>
                                            handleSuggestionCheckboxOnChange(
                                                newCheckedStatus,
                                                suggestedValuePath,
                                                suggestedValue
                                            )
                                    )
                                }
                                checked={fields?.some(({ path: suggestedValuePath }: ParsedField) =>
                                    isChecked(suggestedValuePath)
                                )}
                                disabled={fields?.some(({ path: suggestedValuePath }: ParsedField) =>
                                    isDisabled(suggestedValuePath)
                                )}
                            />
                        </Header>
                        {getFields(fields)}
                    </CardContent>
                    <Divider />
                </div>
            )) ?? null,
        [
            isChecked,
            handleSuggestionCheckboxOnChange,
            customerSelectOptions,
            handleChangeCustomerSelect,
            isDisabled,
            getFields,
            selectedCustomers,
        ]
    );

    const getItemDetails = useCallback(
        (items: ParsedItem[] | undefined) =>
            items?.map(({ index: productIndex, fields }) => (
                <div key={`${productIndex}_cards_${fields?.length}`}>
                    <Header>
                        <HeaderTitle>
                            <CardTitle>{`Invoice Product ${productIndex}`}</CardTitle>

                            <Select
                                allowClear
                                placeholder="Select action"
                                style={{ width: 250, zIndex: 0 }}
                                options={productSelectOptions}
                                onChange={(action: string | undefined) =>
                                    handleChangeProductAction(action, `product${productIndex - 1}`)
                                }
                                getPopupContainer={(trigger) => trigger.parentElement}
                                value={selectedProductsActions[`product${productIndex - 1}`]}
                            />
                        </HeaderTitle>

                        <TemplateCheckbox
                            onChange={({ target: { checked: newCheckedStatus } }: CheckboxChangeEvent) =>
                                fields?.forEach(
                                    ({ path: suggestedValuePath, message: suggestedValue }: ParsedField) => {
                                        let path = suggestedValuePath;
                                        let value = suggestedValue;

                                        if (isSuggestedTarics(suggestedValuePath, suggestedValue)) {
                                            for (let i = 0; i < (suggestedValue as []).length; i++) {
                                                const taricPath = `${suggestedValuePath}${i}`;
                                                const taricIsSelected = selectedSuggestedValuesMap.has(taricPath);

                                                if (taricIsSelected) {
                                                    path = taricPath;
                                                    value = (suggestedValue as [])[i];
                                                    break;
                                                } else {
                                                    path = `${suggestedValuePath}0`;
                                                    value = (suggestedValue as string[])[0];
                                                }
                                            }
                                        }

                                        handleSuggestionCheckboxOnChange(newCheckedStatus, path, value);
                                    }
                                )
                            }
                            checked={fields?.some(
                                ({ path: suggestedValuePath, message: suggestedValue }: ParsedField) => {
                                    if (isSuggestedTarics(suggestedValuePath, suggestedValue))
                                        return (suggestedValue as []).some((_, index) =>
                                            isChecked(`${suggestedValuePath}${index}`)
                                        );
                                    else return isChecked(suggestedValuePath);
                                }
                            )}
                            disabled={fields?.some(
                                ({ path: suggestedValuePath, message: suggestedValue }: ParsedField) => {
                                    const suggestedTaricsStatusLoadingOrFailed =
                                        isSuggestedTarics(suggestedValuePath, suggestedValue) &&
                                        isEmpty(suggestedValue);
                                    if (suggestedTaricsStatusLoadingOrFailed) return true;
                                    else if (isSuggestedTarics(suggestedValuePath, suggestedValue)) return false;
                                    else
                                        return isDisabled(
                                            suggestedValuePath,
                                            isOcrTarics(suggestedValuePath, suggestedValue)
                                        );
                                }
                            )}
                        />
                    </Header>
                    <CardContent>{getFields(fields)}</CardContent>
                    <Divider />
                </div>
            )) ?? null,
        [
            getFields,
            handleChangeProductAction,
            handleSuggestionCheckboxOnChange,
            productSelectOptions,
            selectedSuggestedValuesMap,
            isChecked,
            isDisabled,
            selectedProductsActions,
            isSuggestedTarics,
            isOcrTarics,
        ]
    );

    if (activeForm === 'master') return <>{getMasterDetails(parsedInvoiceResponse?.masterDetails)}</>;
    else return <>{getItemDetails(parsedInvoiceResponse?.items)}</>;
};

export default InvoiceBody;
