import { H6 } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const MainDiv = styled.div`
    background: ${colors.white};
    padding: 40px;
    border: 1px solid ${colors.darkGrey2};
    border-radius: 4px;
`;

export const SectionCoreTemplates = styled.div`
    margin-top: 4.8rem;
`;

export const Section = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.bold};
    font-size: 18px;
    line-height: 28px;
`;

export const SectionArchivedSubmittions = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
`;

export const TableDiv = styled.div`
    margin-top: 3.7rem;
`;

export const DocumentsTitle = styled(H6)`
    margin-bottom: 1.6rem;
`;
