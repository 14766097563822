import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { H5, TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';

export const Title = styled(H5)`
    margin-bottom: 3.8rem;
`;

export const Label = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

export const StyledForm = styled(Form)`
    width: 100%;
`;

export const StyledFormItem = styled(FormItem)`
    text-align: right;
`;
