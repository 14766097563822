import Notification from './notification';
import { DepositRefundRequestDecisionNotification } from './refunds/deposits/DepositRefundRequestDecisionNotification';
import { IeRefundDecisionPayload } from './refunds/remission/IeRefundDecisionPayload';

export interface IrelandNotification extends Notification<SubmissionErrors> {
    // GENERAL NOTIFICATION
    responseDateLimit?: string;
    remarks?: string;

    // DECLARATION REJECTED
    rejectionDate?: string;

    // PRESENTATION NOTIFICATION REJECTED
    notificationRejectionDate?: string;

    // AMENDMENT REJECTED
    amendmentRejectionDate?: string;

    // UNDER CONTROL
    timeLimitForControl?: string;
    controlNotificationDate?: string;
    controlType?: string;
    underControlGoodsShipmentItems?: UnderControlGoodsShipmentItems[];

    //DOCUMENT UPLOAD REQUEST
    documentsUploadRequestDate: string;
    documentsUploadRequestDateLimit: string;

    //DOCUMENT PRESENTATION REQUEST
    documentsPresentationRequestDate: string;
    documentsPresentationRequestDateLimit: string;

    //DECLARATION INVALIDATION REQUEST
    caseId: string;

    // INSUFFICIENT FUNDS
    insufficientFundsValue?: number;

    // REFUND REMISSION
    remissionRequestRejectionDate: string;
    remissionRefundApplicationDecisionPayload: IeRefundDecisionPayload;

    // REFUND DEPOSIT
    depositRequestRejectionDate: string;
    ieDepositRefundApplicationDecision: DepositRefundRequestDecisionNotification;
}

export interface SubmissionErrors {
    id: string;
    irelandTranslationTable: IrelandTranslationTable;
    errorReason: string;
    value: string;
    businessRuleCode?: string;
    remarks: string;
    date: string;
    patternRequired: string;
    fields: string[];
    message?: string;
    externalField: string;
    externalErrorMessage: string;
    missingDocuments?: string[];
}

export interface IrelandTranslationTable {
    code: string;
    description: string;
    message: string;
}

export enum IrelandNotificationType {
    GENERAL_NOTIFICATION = 'GENERAL_NOTIFICATION',
    INVALID = 'INVALID',
    REJECTED = 'REJECTED',
    UNDER_CONTROL = 'UNDER_CONTROL',
    AMENDMENT_REJECTED = 'AMENDMENT_REJECTED',
    NOTIFICATION_REJECTED = 'NOTIFICATION_REJECTED',
    ACCEPTED = 'ACCEPTED',
    REGISTERED = 'REGISTERED',
    RELEASED = 'RELEASED',
    RELEASE_REJECTED = 'RELEASE_REJECTED',
    DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED',
    AMENDMENT_ACCEPTED = 'AMENDMENT_ACCEPTED',
    DOCUMENTS_UPLOAD_REQUEST = 'DOCUMENTS_UPLOAD_REQUEST',
    DOCUMENTS_PRESENTATION_REQUEST = 'DOCUMENTS_PRESENTATION_REQUEST',
    INVALIDATION_REQUEST_DECISION = 'INVALIDATION_REQUEST_DECISION',
    DECLARATION_INVALIDATION_REQUEST = 'DECLARATION_INVALIDATION_REQUEST',
    AMENDMENT_REQUEST = 'AMENDMENT_REQUEST',
    AMENDMENT_REQUEST_CANCELLATION = 'AMENDMENT_REQUEST_CANCELLATION',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
    CONTROL_DECISION_NOTIFICATION = 'CONTROL_DECISION',
    EXIT_RELEASE_NOTIFICATION = 'EXIT_RELEASE',
    CUSTOMS_ERROR = 'CUSTOMS_ERROR',
    NOT_RELEASED = 'NOT_RELEASED',
    REMISSION_REFUND_REQUEST_REJECTION = 'REMISSION_REFUND_REQUEST_REJECTION',
    REMISSION_REFUND_REQUEST_ACCEPTANCE = 'REMISSION_REFUND_REQUEST_ACCEPTANCE',
    REFUND_APPLICATION_REQUIRED = 'REFUND_APPLICATION_REQUIRED',
    REMISSION_REFUND_APPLICATION_DECISION = 'REMISSION_REFUND_APPLICATION_DECISION',
    DEPOSIT_REFUND_REQUEST_REJECTION = 'DEPOSIT_REFUND_REQUEST_REJECTION',
    DEPOSIT_REFUND_REQUEST_APPLICATION_ACCEPTED = 'DEPOSIT_REFUND_REQUEST_APPLICATION_ACCEPTED',
    DEPOSIT_REFUND_REQUEST_APPLICATION_REJECTED = 'DEPOSIT_REFUND_REQUEST_APPLICATION_REJECTED',
}

export const irelandNotificationTypeLabel = [
    { id: IrelandNotificationType.GENERAL_NOTIFICATION, value: 'General Notification' },
    { id: IrelandNotificationType.INVALID, value: 'Invalid' },
    { id: IrelandNotificationType.REJECTED, value: 'Rejected' },
    { id: IrelandNotificationType.UNDER_CONTROL, value: 'Under Control' },
    { id: IrelandNotificationType.AMENDMENT_REJECTED, value: 'Amendment Rejected' },
    { id: IrelandNotificationType.NOTIFICATION_REJECTED, value: 'Notification Rejected' },
    { id: IrelandNotificationType.ACCEPTED, value: 'Accepted' },
    { id: IrelandNotificationType.REGISTERED, value: 'Registered' },
    { id: IrelandNotificationType.RELEASED, value: 'Released' },
    { id: IrelandNotificationType.RELEASE_REJECTED, value: 'Released Rejected' },
    { id: IrelandNotificationType.DOCUMENTS_REQUESTED, value: 'Documents Requested' },
    { id: IrelandNotificationType.AMENDMENT_ACCEPTED, value: 'Amendment Accepted' },
    { id: IrelandNotificationType.DOCUMENTS_UPLOAD_REQUEST, value: 'Documents Upload Request' },
    { id: IrelandNotificationType.DOCUMENTS_PRESENTATION_REQUEST, value: 'Documents Presentation Request' },
    { id: IrelandNotificationType.INVALIDATION_REQUEST_DECISION, value: 'Invalidation request decision' },
    { id: IrelandNotificationType.AMENDMENT_REQUEST_CANCELLATION, value: 'Amendment request cancellation' },
    { id: IrelandNotificationType.AMENDMENT_REQUEST, value: 'Amendment request' },
    { id: IrelandNotificationType.DECLARATION_INVALIDATION_REQUEST, value: 'Declaration invalidation request' },
    { id: IrelandNotificationType.INSUFFICIENT_FUNDS, value: 'Insufficient funds' },
    { id: IrelandNotificationType.CONTROL_DECISION_NOTIFICATION, value: 'Control Decision Notification' },
    { id: IrelandNotificationType.EXIT_RELEASE_NOTIFICATION, value: 'Exit Release Notification' },
    { id: IrelandNotificationType.CUSTOMS_ERROR, value: 'Rejected and customs technical error notification' },
    { id: IrelandNotificationType.NOT_RELEASED, value: 'Not Released' },
    { id: IrelandNotificationType.REMISSION_REFUND_REQUEST_REJECTION, value: 'Remission refund request rejected' },
    { id: IrelandNotificationType.REMISSION_REFUND_REQUEST_ACCEPTANCE, value: 'Remission refund request accepted' },
    { id: IrelandNotificationType.REFUND_APPLICATION_REQUIRED, value: 'Refund application required' },
    {
        id: IrelandNotificationType.REMISSION_REFUND_APPLICATION_DECISION,
        value: 'Remission refund application decision',
    },
    { id: IrelandNotificationType.DEPOSIT_REFUND_REQUEST_REJECTION, value: 'Deposit refund request rejected' },
    {
        id: IrelandNotificationType.DEPOSIT_REFUND_REQUEST_APPLICATION_ACCEPTED,
        value: 'Deposit refund request application accepted',
    },
    {
        id: IrelandNotificationType.DEPOSIT_REFUND_REQUEST_APPLICATION_REJECTED,
        value: 'Deposit refund request application rejected',
    },
] as const;

export interface UnderControlGoodsShipmentItems {
    goodsItemNumber?: number;
    controlTypes?: ControlTypes[];
}

export interface ControlTypes {
    controlTypeCoded: string;
    controlAgency: string;
}
