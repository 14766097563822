import Searchbar from 'components/ui/base/searchbar';
import { H4 } from 'components/ui/base/typography';
import Fuse from 'fuse.js';
import useAisBusinessRules from 'hooks/useAisBusinessRules';
import { FC, useMemo, useState } from 'react';
import { AisBusinessRule } from 'store/ais-business-rules/ais-business-rule';
import { BusinessRulesList } from '../BusinessRulesList';
import { DocumentationType } from '../Help';

const AisBusinessRules: FC<{ initFilter?: string }> = ({ initFilter = '' }) => {
    const { rules, error, isLoading } = useAisBusinessRules();
    const [filter, setFilter] = useState<string>(initFilter);

    const fuse = useMemo(() => {
        return new Fuse(rules, {
            keys: ['code', 'description', 'errorMessage'],
            threshold: 0.2,
        });
    }, [rules]);

    const filteredList = useMemo(() => {
        // fuse returns no results if filter is an empty string...
        if (filter === '') return rules;

        const res: AisBusinessRule[] = fuse.search(filter).map((r) => r.item);
        return res;
    }, [rules, fuse, filter]);

    return (
        <div>
            <H4>Revenue error codes</H4>
            <p>
                Submitting a delaration to Revenue can sometimes return an error. The error code comes from the Revenue
                system, not the Ddispatch platform. Below is a list of the ROS codes correct at the time of this
                publication.
            </p>
            <p>
                You can check the actual live Revenue error code list here{' '}
                <span>
                    <a
                        href="https://www.revenue.ie/en/online-services/support/software-developers/documents/ais/ais-business-rules-and-conditions.pdf"
                        rel="noreferrer"
                        target="_blank"
                    >
                        AIS Business Rules and Conditions
                    </a>
                </span>
            </p>
            <p>
                <strong>
                    If an error code was received on one of your declarations, you can search for the error code here.
                </strong>
            </p>
            <Searchbar
                value={filter}
                onSearch={(value) => setFilter(value)}
                inputPlaceholder="Search business rules..."
            />
            {isLoading ? (
                <span>Loading...</span>
            ) : error ? (
                <span>Error loading business rules</span>
            ) : filteredList ? (
                <BusinessRulesList rules={filteredList} type={DocumentationType.AIS} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default AisBusinessRules;
