import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddressForm from 'views/declarations/common/AddressForm';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import addPathPrefix from 'utils/addPathPrefix';

const OwnerOfTheGoodsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation(['form', 'common']);
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            title={t('authorisation.ownerOfTheGoods')}
            keyCard={props.keyCard}
            viewOnly={props.viewOnly}
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix ?? 'authorisation.ownerOfTheGoods'}
            initialValues={{ name: '', streetAndNumber: '', country: '', postCode: '', city: '' }}
            list={(list) => [
                {
                    field: t('name'),
                    value: list.name,
                },
                {
                    field: t('address.streetAndNumber', { ns: 'common' }),
                    value: list.streetAndNumber,
                },
                {
                    field: t('address.country', { ns: 'common' }),
                    value: list.country,
                },
                {
                    field: t('address.postcode', { ns: 'common' }),
                    value: list.postCode,
                },
                {
                    field: t('address.city', { ns: 'common' }),
                    value: list.city,
                },
            ]}
            defaultOpen={props.defaultOpen}
            condensed
            form={(form, path) => (
                <FormCardContainer>
                    <FormInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, `name`), form)}
                        refNumber="3.8"
                        label={t('name')}
                        tooltip={getH1TooltipsByRefNumberAndField('3.8', t('name'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <AddressForm
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        viewOnly={props.viewOnly}
                        fieldProps={{
                            streetAndNumber: form.getFieldProps(addPathPrefix(path, 'streetAndNumber')),
                            country: form.getFieldProps(addPathPrefix(path, 'country')),
                            postCode: form.getFieldProps(addPathPrefix(path, 'postCode')),
                            city: form.getFieldProps(addPathPrefix(path, 'city')),
                        }}
                        fieldMeta={
                            form.getFieldMeta && {
                                streetAndNumber: form.getFieldMeta(addPathPrefix(path, 'streetAndNumber')),
                                country: form.getFieldMeta(addPathPrefix(path, 'country')),
                                postCode: form.getFieldMeta(addPathPrefix(path, 'postCode')),
                                city: form.getFieldMeta(addPathPrefix(path, 'city')),
                            }
                        }
                        fieldHelper={
                            form.getFieldHelpers && {
                                streetAndNumber: form.getFieldHelpers(addPathPrefix(path, 'streetAndNumber')),
                                country: form.getFieldHelpers(addPathPrefix(path, 'country')),
                                postCode: form.getFieldHelpers(addPathPrefix(path, 'postCode')),
                                city: form.getFieldHelpers(addPathPrefix(path, 'city')),
                            }
                        }
                        refNumbers={{ streetAndNumber: '3.8', city: '3.8', country: '3.8', postcode: '3.8' }}
                        condensed
                    />
                </FormCardContainer>
            )}
            refNumber="3.8"
        />
    );
};
export default OwnerOfTheGoodsCard;
