// remove import and uncomment

const logger = (store: any) => (next: any) => (action: any) => {
    //console.group(action.type);
    //console.info('Logger', action);
    let result = next(action);
    //console.groupEnd();
    return result;
};

export default logger;
