import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';

const Refunds: FC<{}> = () => {
    const { declaration } = useRefunds();
    return (
        <Outlet
            context={{
                declaration: declaration,
            }}
        />
    );
};
export default Refunds;
export function useRefunds() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
