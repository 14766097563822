import { TFunction } from 'react-i18next';

export const submitDeclarationNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('formSubmitSuccessTitle'),
            description: t('formSubmitSuccessMessage'),
        },
        error: {
            title: t('error.formCreateErrorTitle'),
            description: t('error.formCreateErrorMessage'),
        },
    };
    return notifications;
};

export const updateDeclarationNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('formSaveSuccessTitle'),
            description: t('formSaveSuccessMessage'),
        },
        error: {
            title: t('error.declaration_invalid_title'),
            description: t('error.formCreateErrorMessage'),
        },
    };
    return notifications;
};

export const createProductNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('productCreateSuccessTitle'),
            description: t('productCreateSuccessMessage'),
        },
        error: {
            title: t('productCreateErrorTitle'),
            description: t('productCreateErrorMessage'),
        },
    };
    return notifications;
};

export const saveProductNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('productSaveSuccessTitle'),
            description: t('productSaveSuccessMessage'),
        },
        error: {
            title: t('error.formSaveProductErrorTitle'),
            description: t('error.formSaveProductErrorMessage'),
        },
    };
    return notifications;
};

export const createProductTemplatesNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('productTemplateSaveSuccessTitle'),
            description: t('productTemplateSaveSuccessMessage'),
        },
        error: {
            title: t('error.product_template_invalid_title'),
            description: t('error.product_template_invalid_message'),
        },
    };
    return notifications;
};

export const editProductTemplatesNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('productTemplateEditSuccessTitle'),
            description: t('productTemplateEditSuccessMessage'),
        },
        error: {
            title: t('error.product_template_invalid_title_edit'),
            description: t('error.product_template_invalid_message'),
        },
    };
    return notifications;
};

export const cancelAmendmentNotifs = (t: TFunction<'common', undefined>) => {
    const notifications = {
        success: {
            title: t('amendmentCancelledSuccessTitle'),
            description: t('amendmentCancelledSuccessMessage'),
        },
        error: {
            title: t('error.amendmentCancelledErrorTitle'),
            description: t('error.amendmentCancelledErrorMessage'),
        },
    };
    return notifications;
};
