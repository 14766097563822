import React from 'react';

export const handleNullValues = (value: string | number | React.ReactNode) => {
    return value ? <span>{value}</span> : <span>-</span>;
};

export const getStyledCol = (value: string | number | React.ReactNode, style: React.CSSProperties) => {
    return { props: style, children: handleNullValues(value) };
};

export const handleCurrency = (price?: number, currency?: string) => {
    if (price && currency) {
        return `${currency} ${price}`;
    } else if (price) {
        return `${price}`;
    } else {
        return '-';
    }
};
