import { Input } from 'antd';
import styled from 'styled-components';

const SignatureInput = styled(Input)`
    padding-top: 22px;
    padding-bottom: 22px;
    font-size: 24px;
    font-weight: 800;
`;

export default SignatureInput;
