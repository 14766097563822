import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from 'hooks/useProducts';
import { validators } from 'views/declarations/uk/export/validations/validations';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumberAndField } = useTooltips();
    const { createIrelandEnsProduct, listIrelandEnsProducts } = useProducts();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandEnsProduct,
        listProducts: listIrelandEnsProducts,
    });

    return (
        <NewFormCard title="Master Details">
            <FormCardContainer>
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfItems`, props)}
                    fieldEvents={{
                        onBlur(value) {
                            handleNumberOfItems(Number(value));
                        },
                    }}
                    refNumber="6.5"
                    label={t('numberOfItems')}
                    condensed
                />
                {modalContextHolder}

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalNumberOfPackages`, props)}
                    refNumber="6.5"
                    label={t('totalNumberOfPackages')}
                    refTooltip={t('defaultTooltip')}
                    condensed
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalGrossMass`, props)}
                    refNumber="6.5"
                    label={t('totalGrossMass')}
                    refTooltip={t('defaultTooltip')}
                    condensed
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`specificCircumstanceIndicator`, props)}
                    label={t('specificCircumstanceIndicator')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Specific Circumstance Indicator')}
                    refClicked={handleToggleHelp}
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Postal and express consignments',
                        },
                        {
                            id: 'C',
                            value: 'Road mode of transport',
                        },
                        {
                            id: 'D',
                            value: 'Rail mode of transport',
                        },
                        {
                            id: 'E',
                            value: 'Authorised economic operators',
                        },
                    ]}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />

                <FormInput
                    required
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(`issuing.place`, props)}
                    label={t('declarationIssuingPlace')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default MasterDetailsCard;
