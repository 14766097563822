import { InboxOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import useRequest from 'hooks/useRequest';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmUploadFile, documentsPreUpload } from 'store/documents/client';
import { H5Style } from '../../../../../components/ui/base/typography/Typography.styles';
import { beforeUpload, uploadFile } from './UploadUtils';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import { DeclarationDocument } from '../types';
import { createDocumentUk } from 'store/file-upload/client';
import useFormUtils from '../../../../../hooks/useFormUtils';

const { Dragger } = Upload;
interface Props {
    declarationId: string;
    customerId: string;
    listCmsFiles: () => void;
    setCmsFileAndFormAction: Function;
    addDeclarationDocument: (newDoc: DeclarationDocument) => void;
    disabled?: boolean;
}

const UploadDocuments: FC<Props> = ({
    listCmsFiles,
    customerId,
    declarationId,
    setCmsFileAndFormAction,
    addDeclarationDocument,
    disabled,
}) => {
    const { data: preUploadData, doRequest: preUpload } = useRequest(documentsPreUpload);
    const { t } = useTranslation('customs_declarations');

    const { countryLowerCase: country } = useFormUtils();

    const onChangeHandler = ({ file }: UploadChangeParam<UploadFile<any>>) => {
        uploadFile(file as unknown as File, preUploadData?.preSignedUrl)
            .then(async () => {
                const { payload: cmsFile } = (await confirmUploadFile({
                    filename: file.name,
                    fileSize: file.size,
                    customerId,
                    declarationId,
                })) as unknown as any;

                if (country === DeclarationCountry.IRELAND) {
                    setCmsFileAndFormAction?.({ file: cmsFile, action: 'upload' });
                } else if (country === DeclarationCountry.UK) {
                    addDeclarationDocument(
                        await createDocumentUk(
                            {
                                fileId: cmsFile.id,
                                fileReference: cmsFile.link,
                                fileName: cmsFile.filename,
                            },
                            declarationId
                        )
                    );
                }

                listCmsFiles?.();
                message.success(`${file.name} file uploaded successfully.`);
            })
            .catch(() => {
                message.error(`${file.name} file upload failed.`);
            });
    };

    return (
        <div style={{ marginBottom: '2rem' }}>
            <H5Style style={{ marginBottom: '1rem' }}>{t('uploadDocuments')}</H5Style>

            <Dragger
                id="file-upl"
                onChange={(uploadedFileData: UploadChangeParam<UploadFile<any>>) => onChangeHandler(uploadedFileData)}
                beforeUpload={(file: RcFile) => beforeUpload(file, customerId, declarationId, preUpload)}
                method="PUT"
                style={{ width: 'auto', maxHeight: '170px' }}
                showUploadList={false}
                disabled={disabled}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('ClickOrDragFile')}</p>
                <p className="ant-upload-hint">{t('supportForSingleBulkOrUpload')}</p>
            </Dragger>
        </div>
    );
};

export default UploadDocuments;
