import { getCurrencies } from './client';

export const doGetCurrencies: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_CURRENCIES_REQUEST' });
    try {
        const payload = await getCurrencies();

        dispatch({ type: 'GET_CURRENCIES_SUCCESS', payload: payload });
    } catch (e: any) {
        dispatch({ type: 'GET_CURRENCIES_ERROR', error: e?.response?.data });
    }
};
