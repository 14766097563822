import { useAppDispatch } from 'config/hooks';
import { doHideGlobalOverlay, doShowGlobalOverlay, DoShowGlobalOverlayParams } from 'store/global-overlay/action';

const useGlobalOverlay = () => {
    const dispatch = useAppDispatch();

    const showGlobalOverlay = async (params: DoShowGlobalOverlayParams) => {
        return await dispatch(doShowGlobalOverlay(params));
    };

    const hideGlobalOverlay = async () => {
        return await dispatch(doHideGlobalOverlay());
    };

    return { showGlobalOverlay, hideGlobalOverlay };
};

export default useGlobalOverlay;
