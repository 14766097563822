import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import Fuse from 'fuse.js';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Tooltip } from 'store/tooltips/tooltip';
import {
    Description,
    FormHelpContent,
    FormSection,
    FormSectionDescription,
    FormSectionHeader,
    StyledBoxNumber,
} from './FormHelp.styles';

export interface FormHelpProps {
    section?: string | number;
    declarationCountry: DeclarationCountry;
    declarationType?: DeclarationName;
    internalType?: DeclarationInternalType;
}

const FormHelp: FC<FormHelpProps> = ({ section, declarationType, internalType, declarationCountry }) => {
    const { h7Tooltips, h1Tooltips, cdsTooltips, revenueExportTooltips, ensTooltips } = useTooltips();
    const [filter, setFilter] = useState<string>('');
    const tooltips = useMemo(() => {
        if (declarationCountry === DeclarationCountry.IRELAND) {
            if (internalType === DeclarationInternalType.IMPORT) {
                if (declarationType === DeclarationName.H7) {
                    return h7Tooltips;
                } else if (declarationType === DeclarationName.H1) {
                    return h1Tooltips;
                }
            } else if (internalType === DeclarationInternalType.EXPORT) {
                return revenueExportTooltips; //TODO missing export tooltips
            } else if (internalType === DeclarationInternalType.ENS) {
                return revenueExportTooltips;
            }
        } else {
            if (internalType === DeclarationInternalType.IMPORT) {
                return cdsTooltips;
            } else {
                return [] as Tooltip[]; //TODO missing chief tooltips
            }
        }
        return [] as Tooltip[];
    }, [declarationCountry, internalType, declarationType, h7Tooltips, h1Tooltips, revenueExportTooltips, cdsTooltips]);

    const [tooltipsList, setTooltipsList] = useState(tooltips);

    const filterList = useCallback(() => {
        const fuse = new Fuse(tooltips ?? [], {
            keys: ['fieldNumber', 'field', 'description'],
            threshold: 0.2,
        });
        const res: unknown[] = fuse.search(filter).map((r) => r.item);
        setTooltipsList(res as Tooltip[]);
    }, [filter, tooltips]);

    useEffect(() => {
        if (!!filter) {
            filterList();
        } else {
            setTooltipsList(tooltips);
        }
    }, [filter, filterList, tooltips]);

    useEffect(() => {
        if (!!section) {
            setFilter(`${section}`);
        }
    }, [section]);

    return (
        <FormHelpContent>
            <SearchBar
                value={filter}
                inputPlaceholder="Search by field name, sad box number"
                onSearch={(value) => setFilter(value)}
            />
            <>
                {!!tooltipsList?.length &&
                    tooltipsList.map((helpSection, index) => (
                        <FormSection key={`help-section-${index}`}>
                            <FormSectionHeader>
                                <StyledBoxNumber>
                                    {!!helpSection.fieldNumber ? helpSection.fieldNumber : 'No DE'}
                                </StyledBoxNumber>
                                <H5>{helpSection.field}</H5>
                            </FormSectionHeader>
                            <FormSectionDescription>
                                <Description>{!!helpSection.description ? helpSection.description : 'N/A'}</Description>
                            </FormSectionDescription>
                        </FormSection>
                    ))}
            </>
        </FormHelpContent>
    );
};

export default FormHelp;
