import { notification } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Divider from 'components/ui/base/divider';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { ErrorResponse, ListPayload, SubError } from 'core/http/response';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useJobs from 'hooks/useJobs';
// import useRequest from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
// import { deleteCustomer } from 'store/customers/client';
import { Customer } from 'store/customers/customer';
import { JobResponse } from 'store/jobs/job';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import CustomerDetails from './components/CustomerDetails';
import CustomersTable from './components/CustomersTable';
import axiosClient from 'config/axios';
import config from 'config/config';
import { SpaceBetween } from '../../components/styles/layout.styles';
import StatusSegmented from '../declarations/common/dashboard/StatusSegmented';
import { useLocation } from 'react-router-dom';
import {
    archiveCustomer,
    listCustomers as listCustomersRequest,
    listArchivedCustomers as listArchivedCustomersRequest,
    unarchiveCustomer,
} from '../../store/customers/client';
import { useRequestPromise } from '../../hooks/useRequest';
import { getTableChangeParams, TableChangeParams } from '../../utils/tableHelpers';

/** Delete functionality is paused
 * Quoting Iskren: just comment the code. When we have more Roles - we will add permission management. for now - hiding the delete icon will be enough. - Task #3350
 */

const Customers: FC = () => {
    // const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    // const [deleteIds, setDeleteIds] = useState<string[]>([]);
    // const { doRequest: doDeleteCustomer, error: deleteError } = useRequest(deleteCustomer);

    const location = useLocation();
    const { listJobs } = useJobs();

    const [createCustomerDrawerOpen, setCreateCustomerDrawerOpen] = useState<boolean>(false);
    const [showCustomerDetails, setShowCustomerDetails] = useState<boolean>(false);
    const [customerDetails, setCustomerDetails] = useState<{ customer: Customer; jobs: ListPayload<JobResponse> }>();
    const [customer, setCustomer] = useState<Customer>();
    const [customersAndJobs, setCustomersAndJobs] = useState<ListPayload<Customer & { jobs: number }>>();
    const [jobs, setJobs] = useState<ListPayload<JobResponse>[]>();

    const atArchivedPath = useMemo(() => location.pathname.includes('archived'), [location.pathname]);

    const [archiveId, setArchiveId] = useState<string | undefined>(undefined);
    const [archiveModalVisible, setArchiveModalVisible] = useState<boolean>(false);
    const showArchiveModal = useCallback(() => setArchiveModalVisible(true), []);
    const hideArchiveModal = useCallback(() => {
        setArchiveModalVisible(false);
        setArchiveId(undefined);
    }, []);
    const [unarchiveModalVisible, setUnarchiveModalVisible] = useState<boolean>(false);
    const showUnarchiveModal = useCallback(() => setUnarchiveModalVisible(true), []);
    const hideUnarchiveModal = useCallback(() => {
        setUnarchiveModalVisible(false);
        setArchiveId(undefined);
    }, []);

    const {
        data: customers,
        isLoading,
        refetch: listCustomers,
    } = useRequestPromise(
        (params?: any) => {
            const { archived, ...rest } = params ?? {};
            return archived ? listArchivedCustomersRequest(rest) : listCustomersRequest(rest);
        },
        {
            args: [{ archived: atArchivedPath }],
        }
    );

    useEffect(() => {
        listCustomers({ archived: atArchivedPath });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
        } else {
            await listCustomers();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const { setBreadcrumbRoutes } = useBreadcrumb();
    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customers',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!jobs?.length && customers && isLoading !== true) {
            getJobsFromCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, customers, isLoading]);

    const refreshCustomers = () => {
        setJobs(undefined);
        setCustomer(undefined);
        listCustomers();
        getJobsFromCustomer();
    };

    useEffect(() => {
        const data = { ...customers };
        if (jobs && isLoading !== true) {
            data.list = customers?.list.map((c, i) => {
                if (jobs[i] && jobs[i].total) {
                    return { ...c, jobs: jobs[i].total };
                } else {
                    return { ...c, jobs: 0 };
                }
            });
            setCustomersAndJobs(data as ListPayload<Customer & { jobs: number }>);
        }
    }, [jobs, customers, isLoading]);

    const addedNewCustomer = () => {
        setJobs([]);
        setCreateCustomerDrawerOpen(false);
        Notification({
            type: 'success',
            messageTitle: 'Customer Created',
            description: 'Customer has been successfully created!',
        });
    };

    const editedCustomer = () => {
        setCreateCustomerDrawerOpen(false);
        Notification({
            type: 'success',
            messageTitle: 'Customer Edited',
            description: 'Customer has been successfully edited!',
        });
    };

    const getJobsFromCustomer = async () => {
        if (!customers) return;
        if (!(customers?.total > 0)) return;
        const jobs = await Promise.all(
            customers?.list.map((c) => {
                return listJobs({ customerId: c.id });
            })
        );
        setJobs(jobs);
    };

    const handleEdit = (id: string) => {
        const customer = customers?.list.find((element) => element.id === id);
        setCustomer(customer);
        setCreateCustomerDrawerOpen(true);
    };

    const handleDetails = (id: string) => {
        setShowCustomerDetails(true);
        const customer = customers?.list.find((element) => element.id === id);
        if (customer && jobs) {
            const index = customers?.list.indexOf(customer);
            if (!index) return;
            setCustomerDetails({ customer: customer, jobs: jobs[index] });
        }
    };

    const clearCustomerData = () => {
        setCustomer(undefined);
        setCreateCustomerDrawerOpen(false);
    };

    const createCustomer = () => {
        setCustomer(undefined);
        setCreateCustomerDrawerOpen(true);
    };

    const clearCustomerDetailsData = () => {
        setShowCustomerDetails(false);
        setCustomerDetails(undefined);
    };

    const handleEditError = (error: ErrorResponse) => {
        if (error?.subErrors) {
            createSubErrors(error.subErrors, 'edit');
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Edit Error',
                description: 'Failed to edit customer!',
            });
        }
    };

    const handleCreateError = (error: ErrorResponse) => {
        if (error?.subErrors) {
            createSubErrors(error.subErrors, 'create');
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Create Error',
                description: 'Failed to create customer!',
            });
        }
    };

    const createSubErrors = (subErrors: SubError[], action: String) => {
        if (subErrors.length === 1) {
            Notification({
                type: 'error',
                messageTitle: `Missing field ${subErrors[0].field}`,
                description: subErrors[0].message,
            });
        } else {
            Notification({
                type: 'error',
                messageTitle: `Multiple Errors`,
                description: `Multiple errors occurred when ${action} a customer`,
            });
        }
    };

    const exportCustomers = () =>
        axiosClient
            .get<Blob>(`${config.cmsUrl}/customers/export`, {
                headers: { Accept: 'text/csv', 'Content-Type': 'text/csv' },
                responseType: 'blob',
            })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement('a');
                link.setAttribute('href', href);
                link.setAttribute('download', 'customers.csv');
                document.body.appendChild(link);
                link.click();

                notification.success({ message: 'Customers exported successfully!' });
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(() => {
                notification.error({ message: 'Export unsuccessful. Please try again later.' });
            });

    const handleArchive = (id: string) => {
        setArchiveId(id);
        showArchiveModal();
    };

    const handleUnarchive = (id: string) => {
        setArchiveId(id);
        showUnarchiveModal();
    };

    const archiveCustomerFunc = () => {
        hideArchiveModal();
        if (!archiveId) return notification.error({ message: 'Could not archive customer!' });
        archiveCustomer(archiveId)
            .then(() => {
                listCustomers();
                notification.success({ message: 'Customer archived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not archive customer!' });
            })
            .finally(() => {
                setArchiveId(undefined);
            });
    };
    const unarchiveCustomerFunc = () => {
        hideUnarchiveModal();
        if (!archiveId) return notification.error({ message: 'Could not unarchive customer!' });
        unarchiveCustomer(archiveId)
            .then(() => {
                listCustomers({ archived: true });
                notification.success({ message: 'Customer unarchived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not unarchive customer!' });
            })
            .finally(() => {
                setArchiveId(undefined);
            });
    };

    // const handleDelete = (ids: string[]) => {
    //     setDeleteIds(ids);
    //     setModalVisible(true);
    // };

    // const deleteCustomers = () => {
    //     const toDelete: string[] = [...deleteIds];
    //     toDelete.map(async (id) => {
    //         await doDeleteCustomer(id);
    //         refreshCustomers();
    //     });
    //     if (!error) {
    //         setDeleteModalVisible(false);
    //         if (toDelete.length > 1) {
    //             Notification({
    //                 type: 'success',
    //                 messageTitle: 'Customers Deleted',
    //                 description: 'Customers has been successfully deleted!',
    //             });
    //         } else {
    //             Notification({
    //                 type: 'success',
    //                 messageTitle: 'Customer Deleted',
    //                 description: 'Customer has been successfully deleted!',
    //             });
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (deleteError) {
    //         if (deleteIds.length > 1) {
    //             Notification({
    //                 type: 'error',
    //                 messageTitle: 'Delete Error',
    //                 description: 'Failed to delete customers',
    //             });
    //         } else {
    //             Notification({
    //                 type: 'error',
    //                 messageTitle: 'Delete Error',
    //                 description: 'Failed to delete customer',
    //             });
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [deleteError]);

    const onCustomersTableChange = ({ pagination, sorter }: TableChangeParams) =>
        listCustomers({ ...getTableChangeParams({ pagination, sorter }), archived: atArchivedPath });

    return (
        <Container>
            <H5>Customers</H5>

            <SpaceBetween style={{ marginTop: '1.6rem' }}>
                <div style={{ display: 'flex', gap: '15px' }}>
                    <Button size="large" type="primary" onClick={exportCustomers}>
                        Export Customers
                    </Button>
                    <Button size="large" type="primary" onClick={createCustomer} disabled={atArchivedPath}>
                        Create New Customer
                    </Button>
                </div>
                <StatusSegmented value={atArchivedPath ? 'archived' : 'active'} />
            </SpaceBetween>

            <Divider />
            <SearchBar
                inputPlaceholder="Search by EORI, customer name, email and phone number"
                onClear={() => listCustomers()}
                onSearch={(value) => debouncedSearch(value)}
            />

            <CustomersTable
                onEdit={handleEdit}
                onDetails={handleDetails}
                data={customersAndJobs}
                loading={isLoading}
                onUnarchive={handleUnarchive}
                onArchive={handleArchive}
                atArchivedPath={atArchivedPath}
                onChange={onCustomersTableChange}
            />

            <Drawer
                title={!customer ? 'Add New Customer' : 'Edit Customer'}
                width="627"
                visible={createCustomerDrawerOpen}
                onClose={clearCustomerData}
                bodyPaddingRight={0}
            >
                <CreateNewCustomer
                    refreshCustomers={refreshCustomers}
                    closeModal={clearCustomerData}
                    handleOk={addedNewCustomer}
                    handleEdit={editedCustomer}
                    customer={customer}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                />
            </Drawer>

            <Drawer
                title="Customers Details"
                width="627"
                visible={showCustomerDetails}
                onClose={clearCustomerDetailsData}
            >
                <CustomerDetails customer={customerDetails?.customer} jobs={customerDetails?.jobs}></CustomerDetails>
            </Drawer>

            <CustomModal
                title={<H5>Do you want to archive this customer?</H5>}
                centered
                visible={archiveModalVisible}
                onOk={archiveCustomerFunc}
                onCancel={hideArchiveModal}
                width={762}
                confirmText="Archive"
                cancelText="Cancel"
                contentText={'Customer will be added to the archive.'}
            />
            <CustomModal
                title={<H5>Do you want to unarchive this customer?</H5>}
                centered
                visible={unarchiveModalVisible}
                onOk={unarchiveCustomerFunc}
                onCancel={hideUnarchiveModal}
                width={762}
                confirmText="Unarchive"
                cancelText="Cancel"
                contentText={'Customer will be unarchived and become active.'}
            />

            {/* <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these customers?</H5>
                    ) : (
                        <H5>Do you want to remove this customer?</H5>
                    )
                }
                centered
                visible={deleteModalVisible}
                onOk={deleteCustomers}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these customers, you will lose all the information associated to them.'
                        : 'If you remove this customer, you will lose all the information associated with him.'
                }
            /> */}
        </Container>
    );
};
export default Customers;
