import useProducts from 'hooks/useProducts';
import useModal from 'antd/lib/modal/useModal';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { useParams } from 'react-router-dom';
import { JSXElementConstructor, ReactElement } from 'react';
import useDeclarations from '../../../../../hooks/useDeclarations';
import useFormUtils from '../../../../../hooks/useFormUtils';

interface Props {
    createProduct: (product: any, declarationId: string) => Promise<any>;
    listProducts: (declarationId: string) => Promise<any>;
    saveAsDraft?: (withNotification: boolean, data?: any) => Promise<any>;
}

type ReturnType = [(numberOfItems: number) => void, ReactElement<any, string | JSXElementConstructor<any>>];

const useNumberOfItemsModal = ({ createProduct, listProducts, saveAsDraft }: Props): ReturnType => {
    const { declarationId } = useParams();
    const { products } = useProducts();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const [modal, modalContextHolder] = useModal();
    const { getDeclaration } = useDeclarations();
    const { isAis } = useFormUtils();

    const handleNumberOfItemsBlur = (value: number | null) => {
        if (!declarationId) return;

        if (!products?.total && value) {
            modal.confirm({
                content: <>Do you want to create {value} product(s)?</>,
                okText: 'Yes',
                onOk: () => createProducts(value),
                cancelText: 'No',
            });
        }
    };

    const createProducts = async (numberOfItems: number) => {
        if (!declarationId) throw new Error('CRITICAL: No declaration ID');
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Creating products...' } });
        await Promise.all(
            Array(numberOfItems)
                .fill(undefined)
                .map(() => createProduct({}, declarationId))
        );
        const saveAsDraftParams = isAis ? { numberOfItems } : { goodsItemQuantity: numberOfItems };
        await saveAsDraft?.(false, saveAsDraftParams);
        await (!!saveAsDraft && getDeclaration(declarationId));
        await listProducts(declarationId);
        hideGlobalOverlay();
    };

    return [handleNumberOfItemsBlur, modalContextHolder];
};

export default useNumberOfItemsModal;
