import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const FormHeaderH1 = styled.h1`
    font-weight: ${fonts.weight.medium};
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 1.9rem;
    color: ${colors.darkBlack2};
`;
