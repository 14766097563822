import { DownOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button/Button';
import { FC } from 'react';

interface Props {
    onClick: React.MouseEventHandler<HTMLElement>;
}

const FilterButton: FC<Props> = ({ onClick }) => {
    return (
        <Button size="large" onClick={onClick}>
            Filter <DownOutlined />
        </Button>
    );
};

export default FilterButton;
