import { Card, Col, Form, Row } from 'antd';
import Button from 'components/ui/base/button';
import styled from 'styled-components';
import { colors } from 'theme';

export const MainDiv = styled.div`
    margin-right: 15.9rem;
`;

export const RowActions = styled(Row)`
    margin-top: 4.1rem;
`;

export const ColActions = styled(Col)``;

export const ColActionsRight = styled(Col)`
    text-align: right;
`;

export const UploadBtDiv = styled.div`
    color: ${colors.blueLink};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.9rem;
    margin-bottom: 2rem;

    .disabled {
        color: ${colors.darkGrey};
    }
`;

export const BulkUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const UploadedFileCard = styled(Card)`
    margin-top: 2rem;

    max-width: 40rem;

    border-color: ${colors.darkPurple};
    span {
        color: ${colors.darkPurple};
    }
`;

export const UploadedFileCardInnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
`;

export const FormUpload = styled(Form)`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2rem;
`;

export const CustomRow = styled(Row)`
    &.disabled {
        cursor: not-allowed;
    }
`;

export const DivDownloadTemplate = styled.div<{ disabled?: boolean }>`
    color: ${({ disabled }) => (disabled ? colors.grey : colors.blueLink)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2.9rem;
    &:hover {
        text-decoration: ${({ disabled }) => (!disabled ? 'underline' : 'none')};
    }
`;

export const StyledDiv = styled.div`
    margin-top: 3.3rem;
    margin-right: 2.3rem;
`;

export const BackButton = styled(Button)`
    margin-bottom: 2.4rem;
`;

export const DivInformationTemplateFirstRow = styled.div`
    color: ${colors.blueLink};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.9rem;
    color: ${colors.red};
`;
