import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalInformation } from 'store/declarations/common/additional-information';
import { Package } from 'store/declarations/ireland/electronic-transport-document';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const AdditionalInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { viewOnly } = props;
    const fields = props.getFields ? props.getFields() : [];

    const formik = useFormik<Package>({
        initialValues: {},
        validationSchema: Yup.object().shape({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`${props.propsPathPrefix}.additionalInformation`),
            fieldMeta: props.getFieldMeta && props.getFieldMeta(`${props.propsPathPrefix}.additionalInformation`),
            fieldHelper: props.getFieldHelpers(`${props.propsPathPrefix}.additionalInformation`),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const additionalInformationList = useMemo(() => {
        const list = props.getFieldProps(`${props.propsPathPrefix}.additionalInformation`).value;
        if (Array.isArray(list)) {
            return list.map((obj: AdditionalInformation, index) => {
                return [
                    { field: 'Code', value: obj.additionalInformationCode },
                    { field: 'Text', value: obj.additionalInformationText },
                ];
            });
        }
        return [];
    }, [props]);

    return (
        <section
            id={'tsd-additional-information-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-additional-information-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('additionalInformation.title')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <CardList
                    data={additionalInformationList}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    viewOnly={viewOnly ?? false}
                />
                {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalInformationText`, {
                        getFieldHelpers: getFieldHelpers,
                        getFieldMeta: getFieldMeta,
                        getFieldProps: getFieldProps,
                    })}
                    label={t('additionalInformationText')}
                />
            </ModalEditCardListItem>
        </section>
    );
};

export default AdditionalInformationCard;
