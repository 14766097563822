import { Col, Drawer, Row } from 'antd';
import { SearchOutlined } from 'components/ui/base/searchbar/SearchBar.style';
import CustomerEoriAddressRadioButton from 'components/ui/composed/declarations/customerEoriAddressRadioButton/CustomerEoriAddressRadioButton';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormEori from 'components/ui/composed/formEori/FormEori';
import { customerHasValues } from 'components/ui/composed/formEori/utils';
import SearchCustomer from 'components/ui/composed/searchCustomer/SearchCustomer';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import AddressForm from 'views/declarations/common/AddressForm';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import {
    appendFieldPath,
    fillCustomerFromListOfCustomers,
    getCardState,
    getFormikProps,
    handleToggleHelp,
} from 'views/declarations/utils/form-utils';
import { invalidEoriMessage } from 'views/declarations/utils/validation-utils';

const HolderOfTheTransitProcedureCard: FC<DeclarationFormCardProps> = (props) => {
    const { getFieldProps, getFieldHelpers } = props;
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const [showCustomers, setShowCustomers] = useState(false);
    const fields = props.getFields ? props.getFields() : [];
    const fullFieldPath = appendFieldPath(props.propsPathPrefix);

    const handleSearchCustomer = () => {
        setShowCustomers(!showCustomers);
    };

    const handleSelectCustomer = (customer: Customer) => {
        const path = fullFieldPath('');
        fillCustomerFromListOfCustomers(customer, path, setShowCustomers, getFieldHelpers);
    };

    const onError = useCallback(() => {
        const path = fullFieldPath('eori');
        getFieldHelpers!(path).setError(invalidEoriMessage);
    }, [fullFieldPath, getFieldHelpers]);

    const onSuccess = useCallback(() => {
        const path = fullFieldPath('eori');
        getFieldHelpers!(path).setError(undefined);
    }, [fullFieldPath, getFieldHelpers]);

    const hasValues = useMemo(() => {
        const path = fullFieldPath('');
        return customerHasValues(getFieldProps, path);
    }, [fullFieldPath, getFieldProps]);

    return (
        <section
            id="holder-of-the-transit-procedure-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('holder-of-the-transit-procedure-form-card');
                }
            }}
        >
            <FormCard
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('etd.holderOfTheTransitProcedure')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                action={
                    !props.viewOnly
                        ? { title: 'Search Customer', onClick: handleSearchCustomer, icon: <SearchOutlined /> }
                        : undefined
                }
                state={getCardState(fields, props)}
            >
                <CustomerEoriAddressRadioButton>
                    <Row gutter={12}>
                        <Col sm={24}>
                            <FormEori
                                declaration
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`eori`, props)}
                                refNumber="3.2"
                                label={t('identificationNumber')}
                                hasValues={hasValues}
                                onError={onError}
                                onSuccess={onSuccess}
                                tooltip={getH1TooltipsByRefNumberAndField('3.2', t('identificationNumber'))}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                    </Row>
                    <>
                        <Row gutter={12}>
                            <Col sm={12}>
                                <FormInput
                                    viewOnly={props.viewOnly}
                                    {...getFormikProps(`name`, props)}
                                    refNumber="3.1"
                                    label={t('name')}
                                    tooltip={getH1TooltipsByRefNumberAndField('3.1', t('name'))}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                />
                            </Col>
                            <Col sm={12}>
                                <FormInput
                                    viewOnly={props.viewOnly}
                                    {...getFormikProps(`tin`, props)}
                                    refNumber="3.1"
                                    label={t('tin')}
                                    tooltip={getH1TooltipsByRefNumberAndField('3.1', t('tin'))}
                                    refClicked={(ref) => handleToggleHelp(ref, props)}
                                />
                            </Col>
                        </Row>
                        <AddressForm
                            declarationType={DeclarationName.ETD}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            viewOnly={props.viewOnly}
                            fieldProps={{
                                streetAndNumber: props.getFieldProps(fullFieldPath('streetAndNumber')),
                                country: props.getFieldProps(fullFieldPath('country')),
                                postCode: props.getFieldProps(fullFieldPath('postCode')),
                                city: props.getFieldProps(fullFieldPath('city')),
                            }}
                            fieldMeta={
                                props.getFieldMeta && {
                                    streetAndNumber: props.getFieldMeta(fullFieldPath('streetAndNumber')),
                                    country: props.getFieldMeta(fullFieldPath('country')),
                                    postCode: props.getFieldMeta(fullFieldPath('postCode')),
                                    city: props.getFieldMeta(fullFieldPath('city')),
                                }
                            }
                            fieldHelper={
                                props.getFieldHelpers && {
                                    streetAndNumber: props.getFieldHelpers(fullFieldPath('streetAndNumber')),
                                    country: props.getFieldHelpers(fullFieldPath('country')),
                                    postCode: props.getFieldHelpers(fullFieldPath('postCode')),
                                    city: props.getFieldHelpers(fullFieldPath('city')),
                                }
                            }
                            refNumbers={{ streetAndNumber: '3.1', city: '3.1', country: '3.1', postcode: '3.1' }}
                        />
                    </>
                </CustomerEoriAddressRadioButton>
            </FormCard>
            <Drawer
                title="Search for customer"
                width="1098"
                visible={showCustomers}
                onClose={() => {
                    setShowCustomers(false);
                }}
            >
                <SearchCustomer handleSelect={handleSelectCustomer} />
            </Drawer>
        </section>
    );
};
export default HolderOfTheTransitProcedureCard;
