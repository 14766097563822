import { Row } from 'antd';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const MainDiv = styled.div`
    background: ${colors.white};
    padding: 2.4rem;
    border: 1px solid ${colors.darkGrey2};
    box-sizing: border-box;
    border-radius: 0.4rem;
`;

export const CustomerInfoDiv = styled.div`
    width: 778px;
    min-height: 250px;
    left: 0px;
    top: 209px;
`;

export const FieldName = styled.span`
    font-style: normal;
    font-family: Open Sans;
    font-weight: ${fonts.weight.semibold};
    font-size: 14px;
    line-height: 22px;
    color: ${colors.darkPurple};
`;

export const FieldValue = styled.span`
    font-style: normal;
    font-family: Open Sans;
    font-weight: ${fonts.weight.regular};
    font-size: 14px;
    line-height: 22px;
    color: ${colors.darkPurple};
`;

export const FieldValueText = styled.div`
    margin-top: 1.5rem;
    font-style: normal;
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
    padding: 0.4rem 0.6rem;
    width: 400px;
    min-height: 120px;
    font-weight: ${fonts.weight.regular};
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #8d8d8d;
`;

export const RowValue = styled(Row)`
    margin-top: 0.8rem;
`;

export const ButtonRow = styled(Row)`
    margin-top: 4rem;
`;
