import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'statementCode' | 'statementDescription';

export const additionalInformationBlockValidation: {
    childValidators: {
        additionalInformation: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalInformation: {
            childValidators: {
                statementCode: [validators.required(), validators.exact(5)],
                statementDescription: [validators.maxLength(70)],
            },
            stopAtNull: true,
        },
    },
};

export const cdsImportAdditionalInformationBlockValidation: {
    childValidators: {
        additionalInformation: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalInformation: {
            childValidators: {
                statementCode: [validators.required(), validators.exact(5)],
                statementDescription: [validators.maxLength(512)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const AdditionalInformationBlock = ({ path = 'additionalInformation', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '2/2', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="statementCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('additionalInformationCode')}
                        refNumber={ucc}
                        selectOptions={
                            fieldOptions?.statementCode?.options ??
                            (formType?.includes('IMPORT')
                                ? cdsCodelists?.importSpecialMentions
                                : cdsCodelists?.exportSpecialMentions)
                        }
                        condensed
                        codeValidation={[validators.exact(5)]}
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="statementDescription">
                {({ form, field }) => (
                    <FormInput
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('additionalInformationDescription')}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionalInformationBlock;
