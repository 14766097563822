import { FC, useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';

const RefundsDeposits: FC<{}> = () => {
    const { declaration } = useRefundsDeposits();
    const declarationRemission = useMemo(() => {
        return (
            <Outlet
                context={{
                    declaration: declaration,
                }}
            />
        );
    }, [declaration]);

    return declarationRemission;
};
export default RefundsDeposits;
export function useRefundsDeposits() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
