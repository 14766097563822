import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { FormikProps } from 'formik';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

interface Props extends DeclarationFormCardProps {
    formik: FormikProps<any>;
}

const AdditionalProcedures: FC<Props> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('additionalProcedure')}>
            <FormCardContainer>
                <MultipleDeclarationField name="additionalProcedure" parent="additionalProcedure">
                    {(fieldProps, controls, index) => (
                        <FormSelect
                            required={index === 0}
                            refNumber="1.11"
                            viewOnly={props.viewOnly}
                            {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                            label={t('additionalProcedure')}
                            selectOptions={aisCodelists?.additionalProcedure}
                            tooltip={getH7TooltipsByRefNumberAndField('1.11', t('additionalProcedure'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                            multipleF={controls}
                            codeValidation={[validators.exact(3)]}
                        />
                    )}
                </MultipleDeclarationField>
            </FormCardContainer>
        </NewFormCard>
    );
};
export default AdditionalProcedures;
