import { InputNumberProps } from 'antd';
import { StyledNumberInput } from './Input.styles';

export interface NumberInputProps extends InputNumberProps {
    prefixCls?: string;
    size?: 'small' | 'middle' | 'large';
    onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
    addonBefore?: React.ReactNode;
    addonAfter?: React.ReactNode;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    allowClear?: boolean;
    bordered?: boolean;
    placeholder?: string;
    id?: string;
    value?: number;
    onChange?: any;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    min?: string;
    max?: string;
}

const AInput = (props: NumberInputProps) => {
    return (
        <StyledNumberInput
            controls={false}
            onWheel={(e) => {
                e.currentTarget.blur();
            }}
            {...props}
        />
    );
};
export default AInput;
