import { Progress } from 'antd';

export interface SuccessProps {
    percent?: number;
    strokeColor?: string;
}

export interface ProgressProps {
    format?: (percent?: number, successPercent?: number) => React.ReactNode;
    type?: 'circle' | 'line' | 'dashboard' | undefined;
    percent?: number;
    showInfo?: boolean;
    trailColor?: string;
    size?: 'default' | 'small';
    gapDegree?: undefined;
    strokeLinecap?: 'round' | 'butt' | 'square' | undefined;
    children?: any;
    onClick?: (action: any) => void;
    label?: string;
    strokeColor?: any;
    success?: SuccessProps;
}

const AProgress = (props: ProgressProps) => (
    <>
        <span>{props.label}</span>
        <Progress {...props}>{props.children}</Progress>
    </>
);
export default AProgress;
