import { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useMatch } from 'react-router-dom';
import { DeclarationStatus } from '../../../../../store/declarations/enums/common/declaration-status';
import { declarationHasBeenSubmitted } from '../../../utils/form-utils';

const useUpdateViewOfDeclaration = ({
    declarationStatus,
    declarationIsArchived,
}: {
    declarationStatus: DeclarationStatus | undefined;
    declarationIsArchived: boolean | undefined;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { productId, declarationId } = useParams<{
        productId: string | undefined;
        declarationId: string | undefined;
    }>();
    const inMasterInformation = useMatch('declarations/:declarationId');
    const inMasterDetailsViewOnly = useMatch('declarations/:declarationId/view-only');

    useEffect(() => {
        if (!declarationStatus) return;

        const declarationIsInViewOnlyStatus =
            declarationHasBeenSubmitted(declarationStatus) ||
            declarationStatus === DeclarationStatus.RELEASED ||
            declarationIsArchived;
        const declarationUrlIsInViewOnly = location.pathname.includes('view-only');

        if (declarationIsInViewOnlyStatus && !declarationUrlIsInViewOnly) {
            if (inMasterInformation) {
                navigate(`/declarations/${declarationId}/view-only`, { replace: true });
            } else if (productId) {
                navigate(`/declarations/${declarationId}/view-only/products/${productId}`);
            }
        } else if (!declarationIsInViewOnlyStatus && declarationUrlIsInViewOnly) {
            if (inMasterDetailsViewOnly) {
                navigate(`/declarations/${declarationId}`, { replace: true });
            } else if (productId) {
                navigate(`/declarations/${declarationId}/products/${productId}`);
            }
        }
    }, [
        declarationStatus,
        declarationId,
        productId,
        location.pathname,
        navigate,
        inMasterInformation,
        inMasterDetailsViewOnly,
        declarationIsArchived,
    ]);
};

export default useUpdateViewOfDeclaration;
