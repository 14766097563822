import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IndividualType } from 'store/individuals/individual';
import { SelectOption } from '../formSelect/FormSelect';
import TagButton from './TagButton';
import { SelectOptions, StyledGroup, StyledRadio, StyledSpace } from './TagsMenu.styles';

interface Props {
    value: IndividualType | string;
    selectOptions?: Readonly<SelectOption[]>;
    updateValue: (value: IndividualType & string) => void;
    disabled?: boolean;
}

const TagsMenu: FC<Props> = ({ selectOptions, value, updateValue, disabled = false }) => {
    const [selectedCheckbox, setSelectedCheckbox] = useState<IndividualType | string>(value);
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const activeAndClickOutside = (e: any) =>
            ref.current !== null && !ref.current.contains(e.target) && setOpen(false);

        if (open) window.addEventListener('click', activeAndClickOutside);

        return () => window.removeEventListener('click', activeAndClickOutside);
    }, [open]);

    const checkSelected = useCallback((selected: string) => selected === selectedCheckbox, [selectedCheckbox]);

    return (
        <>
            <TagButton setOpen={setOpen} value={value} />
            {open && (
                <SelectOptions ref={ref}>
                    <StyledGroup
                        disabled={disabled}
                        value={value}
                        onChange={(e) => {
                            setSelectedCheckbox(e.target.value);
                            updateValue(e.target.value);
                            setOpen(false);
                        }}
                    >
                        <StyledSpace direction="vertical">
                            {selectOptions?.map((item, i: number) => (
                                <StyledRadio
                                    key={`option-${i}`}
                                    value={item.id}
                                    selected={!disabled && checkSelected(item.id.toString())}
                                >
                                    {item.value}
                                </StyledRadio>
                            ))}
                        </StyledSpace>
                    </StyledGroup>
                </SelectOptions>
            )}
        </>
    );
};

export default TagsMenu;
