import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { IcsBusinessRulesActions } from './actionType';

import { IcsBusinessRule } from './ics-business-rule';

export interface IcsBusinessRulesState {
    isLoading: boolean;
    error?: ErrorResponse;
    rules: ListPayload<IcsBusinessRule>;
}

export const icsBusinessRulesInitialState: Readonly<IcsBusinessRulesState> = {
    isLoading: false,
    rules: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const icsBusinessRules: Reducer<IcsBusinessRulesState, IcsBusinessRulesActions> = (
    state = icsBusinessRulesInitialState,
    action
): IcsBusinessRulesState => {
    switch (action.type) {
        case 'LIST_ICS_BUSINESS_RULES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_ICS_BUSINESS_RULES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                rules: action.payload,
            };
        case 'LIST_ICS_BUSINESS_RULES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default icsBusinessRules;
