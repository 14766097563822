import { Card, Radio, Space } from 'antd';
import { Text } from 'components/ui/base/typography';
import { colors } from 'theme';
import styled from 'styled-components';
import { memo, useMemo } from 'react';
import { FormikProps } from 'formik';
import { map } from 'lodash';

const { RadioButton, RadioDescription, RadioContainer } = {
    RadioButton: styled(Radio)`
        font-weight: 600;
    `,
    RadioDescription: styled(Text)`
        color: ${colors.grey};
        font-size: 12px;
        font-weight: 500;
    `,
    RadioContainer: styled.div`
        display: flex;
        flex-direction: column;
    `,
};

type RadioButtons = Array<{
    value: any;
    title: string;
    description?: string;
}>;

type Props<TFormik extends FormikProps<TFormik['values']>> = {
    formik: TFormik;
    formikProperty: keyof TFormik['values'];
    radioButtons: RadioButtons;
};

const RadioCard = <TFormik extends FormikProps<TFormik['values']>>({
    formik,
    formikProperty,
    radioButtons,
}: Props<TFormik>) => {
    const values = useMemo(() => map(radioButtons, 'value'), [radioButtons]);

    return (
        <Card>
            <Radio.Group
                onChange={(e) => formik.setFieldValue(formikProperty as string, values[e.target.value])}
                value={values.indexOf(formik.values[formikProperty])}
            >
                <Space direction="vertical" size="large">
                    {radioButtons.map(({ title, description }, index) => (
                        <RadioContainer key={title}>
                            <RadioButton value={index}>{title}</RadioButton>
                            {description && <RadioDescription>{description}</RadioDescription>}
                        </RadioContainer>
                    ))}
                </Space>
            </Radio.Group>
        </Card>
    );
};

export default memo(RadioCard, (prev, curr) => {
    return prev.formik.values[prev.formikProperty] === curr.formik.values[curr.formikProperty];
});
