import StatusTag from 'components/ui/base/status/StatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';

interface Props {
    status: DeclarationStatus;
    multiple?: boolean;
    dataTestId?: string;
}

const aesSpecificDeclarationStatusColors = {
    [DeclarationStatus.DIVERSION_REJECTED]: colors.lightGreen3,
    [DeclarationStatus.REGISTERED_AND_WAITING_FOR_PRESENTATION_OF_GOODS]: colors.lightBlue,
    [DeclarationStatus.GOODS_RELEASED_FOR_IMMEDIATE_LEAVE]: colors.lightGreen,
    [DeclarationStatus.EXPORTED]: colors.green,
    [DeclarationStatus.EXPORTED_STOPPED_AT_EXIT]: colors.orange,
    [DeclarationStatus.CANCELLED]: colors.lightRed,
    [DeclarationStatus.INVALIDATED]: colors.red,
    [DeclarationStatus.GOODS_NOT_ALLOWED_TO_EXIT]: colors.purpleBlueA15,
    [DeclarationStatus.NOT_RELEASED_FOR_EXPORT]: colors.purpleBlue,
    [DeclarationStatus.GOODS_RELEASED_FOR_EXPORT]: colors.polarGreen,
    [DeclarationStatus.GOODS_READY_TO_BE_RELEASED]: colors.yellow,
    [DeclarationStatus.EXITED]: colors.lightBlue,
    [DeclarationStatus.EXPORT_STOPPED_DISCREPANCIES_AT_EXIT]: colors.lightRed,
    [DeclarationStatus.GOODS_PRESENTED_AT_EXIT]: colors.lightGreen,
};

export const declarationStatusColor = {
    [DeclarationStatus.DRAFT]: colors.grey,
    [DeclarationStatus.SUBMITTED]: colors.lightGreenA70,
    [DeclarationStatus.REJECTED]: colors.lightRed,
    [DeclarationStatus.INVALID]: colors.lightRed,
    [DeclarationStatus.AWAITING_TARIFF_CALCULATION]: colors.lightOrange,
    [DeclarationStatus.ACCEPTED]: colors.lightGreen2,
    [DeclarationStatus.AWAITING_RISK]: colors.lightOrange,
    [DeclarationStatus.UNDER_PAYMENT]: colors.lightOrange1,
    [DeclarationStatus.INSUFFICIENT_FUNDS]: colors.pink,
    [DeclarationStatus.UNDER_CONTROL]: colors.darkOrange,
    [DeclarationStatus.RELEASED]: colors.darkGreen,
    [DeclarationStatus.REGISTERED]: colors.lightGreenA70,
    [DeclarationStatus.NOT_RELEASED]: colors.lightPink,
    [DeclarationStatus.UPLOAD_DOCUMENTS]: colors.grey,
    [DeclarationStatus.INVALIDATE]: colors.black,
    [DeclarationStatus.RISKED]: colors.darkOrange,
    ...aesSpecificDeclarationStatusColors,
};

const DeclarationStatusTag: FC<Props> = ({ status, multiple, dataTestId }) => {
    if (multiple) {
        return <StatusTag color={colors.grey} status="Multiple" />;
    }
    return <StatusTag color={declarationStatusColor[status]} status={enumToText(status)} dataTestId={dataTestId} />;
};
export default DeclarationStatusTag;
