import { notification } from 'antd';

export type NotificationType = 'info' | 'error' | 'warn' | 'success';

export interface NotificationProps {
    type: NotificationType;
    messageTitle: string;
    description: string;
    key?: string;
    duration?: number;
}

const Notification = (props: NotificationProps) => {
    const duration = props.duration;
    notification[props.type]({
        key: props.key,
        message: props.messageTitle,
        description: props.description,
        duration,
    });
};

export default Notification;
