import Modal from 'components/ui/base/modal/Modal';
import { H5, TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import * as colors from 'theme/colors';

export const CustomModal = styled(Modal)`
    .ant-modal-body {
        max-height: 70.5rem;
        overflow-y: auto;
    }
`;

export const InfoDiv = styled.div<{ paddingLeft?: number }>`
    display: flex;
    margin-bottom: 0.8rem;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}rem` : 0)};
`;

export const Label = styled(TextSmallBold)`
    margin-right: 0.5rem;
`;

export const CardNameTitle = styled(H5)`
    margin-bottom: 1.2rem;
`;

export const ProductTitle = styled(H5)`
    margin-bottom: 1.2rem;
    color: ${colors.lightRed};
`;
