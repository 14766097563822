import { FileOutlined, TeamOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import HmrcIcon from 'components/icons/HmrcIcon';
import { H5 } from 'components/ui/base/typography';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ContentContainer, NavContainer, SettingsRow, StyledDiv, StyledSpan } from './Settings.styles';

export enum SettingsOptions {
    PERSONAL_INFORMATION = 'personal-information',
    COMPANY_DETAILS = 'company-details',
    DIGITAL_CERTIFICATE = 'digital-certificate',
    CHANGE_PASSWORD = 'change-password',
    HMRC = 'hmrc',
}

export const sectionsSettings = [
    { title: 'Personal Information', href: SettingsOptions.PERSONAL_INFORMATION },
    { title: 'Company Details', href: SettingsOptions.COMPANY_DETAILS },
    { title: 'Digital Certificate', href: SettingsOptions.DIGITAL_CERTIFICATE },
    { title: 'Change Password', href: SettingsOptions.CHANGE_PASSWORD },
    { title: 'HMRC', href: SettingsOptions.HMRC },
];

const Settings: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation('settings');
    const { setBreadcrumbRoutes } = useBreadcrumb();

    const isSectionActive = useCallback(
        (href: string) => {
            return location.pathname.includes(href);
        },
        [location]
    );

    const activeSection = useMemo(() => {
        return sectionsSettings.find((s) => isSectionActive(s.href));
    }, [isSectionActive]);

    useEffect(() => {
        const getBreadCrumbName = () => {
            const root = {
                breadcrumbName: `${t('settings.title')}`,
                path: '',
            };
            switch (activeSection?.href) {
                case SettingsOptions.PERSONAL_INFORMATION:
                    return [
                        root,
                        {
                            breadcrumbName: `${t('settings.personalInformation')}`,
                            path: '',
                        },
                    ];
                case SettingsOptions.CHANGE_PASSWORD:
                    return [
                        root,
                        {
                            breadcrumbName: `${t('settings.changePassword')}`,
                            path: '',
                        },
                    ];
                case SettingsOptions.COMPANY_DETAILS:
                    return [
                        root,
                        {
                            breadcrumbName: `${t('settings.companyDetails')}`,
                            path: '',
                        },
                    ];
                case SettingsOptions.HMRC:
                    return [
                        root,
                        {
                            breadcrumbName: `${t('settings.hmrc')}`,
                            path: '',
                        },
                    ];
                case SettingsOptions.DIGITAL_CERTIFICATE:
                    return [
                        root,
                        {
                            breadcrumbName: `${t('settings.digitalCertificate')}`,
                            path: '',
                        },
                    ];
                default:
                    return [];
            }
        };
        setBreadcrumbRoutes(getBreadCrumbName());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection]);

    const goToSection = useCallback(
        (option: SettingsOptions) => {
            navigate(`${option}`);
        },
        [navigate]
    );

    const handleIcon = useCallback((option: SettingsOptions) => {
        switch (option) {
            case SettingsOptions.PERSONAL_INFORMATION:
                return (
                    <StyledSpan>
                        <UserOutlined />
                    </StyledSpan>
                );
            case SettingsOptions.COMPANY_DETAILS:
                return (
                    <StyledSpan>
                        <TeamOutlined />
                    </StyledSpan>
                );
            case SettingsOptions.CHANGE_PASSWORD:
                return (
                    <StyledSpan>
                        <UnlockOutlined />
                    </StyledSpan>
                );
            case SettingsOptions.HMRC:
                return (
                    <StyledSpan>
                        <HmrcIcon />
                    </StyledSpan>
                );
            case SettingsOptions.DIGITAL_CERTIFICATE:
                return (
                    <StyledSpan>
                        <FileOutlined />
                    </StyledSpan>
                );
        }
    }, []);

    return (
        <SettingsRow>
            <NavContainer>
                <H5> {t('settings.title')}</H5>
                {sectionsSettings.map(({ title, href }, i) => (
                    <StyledDiv active={isSectionActive(href)} key={i} onClick={() => goToSection(href)}>
                        {handleIcon(href)}
                        {title}
                    </StyledDiv>
                ))}
            </NavContainer>
            <ContentContainer>
                <Outlet />
            </ContentContainer>
        </SettingsRow>
    );
};
export default Settings;
