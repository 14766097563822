import { Row } from 'antd';
import AForm from 'antd/lib/form/Form';
import Button from 'components/ui/base/button';
import { H6, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const ForgotPasswordDiv = styled.div`
    color: ${colors.darkPurple};
    text-align: center;
`;

export const ForgotPasswordSubdiv = styled.div`
    margin-top: 2.4rem;
    text-align: center;
`;

export const ImageSendIcon = styled.img`
    width: 5.4rem;
    height: 5.4rem;
`;

export const LabelEmailSent = styled(H6)`
    color: ${colors.lightBlue};
    display: block;
    margin-top: 2.4rem;
`;
export const LabelCheckEmail = styled(TextSmall)`
    color: ${colors.darkPurple};
    display: block;
    margin-top: 1.2rem;
`;

export const EmailSentContent = styled.div`
    text-align: center;
`;

export const Form = styled(AForm)`
    margin-top: 8rem;
    width: 100%;
`;

export const SendLinkBt = styled(Button)`
    margin-top: 3.2rem;
    width: 100%;
`;

export const ReturnLoginBt = styled(Button)`
    margin-top: 1.6rem;
    color: ${colors.black};
`;

export const Return = styled(Button)`
    padding: 0;
    margin-left: 0.5rem;
`;

export const ReturnRow = styled(Row)`
    margin-top: 2.4rem;
`;

export const ReturnText = styled(TextSmall)`
    font-style: normal;
    font-weight: normal;
    padding: 0;
`;
