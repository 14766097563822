import { DeclarationCountry } from '../store/declarations/enums/common/declaration-country';

const IRELAND_URL = 'https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp?Lang=en&SimDate=20230217#';
const UK_URL = 'https://www.trade-tariff.service.gov.uk/find_commodity';

const taricCodeLookUp = (country: DeclarationCountry) => {
    if (!country) return;

    const getTaricCodeLookUpUrl = (country: DeclarationCountry) => {
        if (country === DeclarationCountry.IRELAND) {
            return IRELAND_URL;
        } else if (country === DeclarationCountry.UK) {
            return UK_URL;
        }
    };

    window.open(getTaricCodeLookUpUrl(country));
};

export default taricCodeLookUp;
