import { useFormik } from 'formik';
import EDocketInfo from './components/EDocketInfo';
import { H4 } from 'components/ui/base/typography';
import EDocketFormGeneral from './components/EDocketFormGeneral';
import { EDocketFormStyledComponents } from './components/EDocketForm.styles';
import EDocketFormProduct from './components/EDocketFormProduct';
import EDocketFormDriver from './components/EDocketFormDriver';
import EDocketFormWeight from './components/EDocketFormWeight';
import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EDocket, EDocketFormType } from './eDocketTypes';
import eDocketFormValidationSchema from './eDocketFormValidationSchema';
import { debounce, isEmpty } from 'lodash';
import EDocketFormAfterWeight from './components/EDocketFormAfterWeight';
import EDocketFormSignatures from './components/EDocketFormSignatures';
import EDocketFormOperatorConfirmationModal from './components/EDocketFormOperatorConfirmationModal';
import {
    eDocketDataAfterAfterWeight,
    eDocketDataAfterDriverSignature,
    eDocketDataAfterFirstWeight,
    eDocketDataAfterOperatorConfirmation,
    eDocketDataAfterSecondWeight,
} from './eDocketTestData';
import { useTranslation } from 'react-i18next';

const { Main, ButtonContainer } = EDocketFormStyledComponents;

const weight = {
    goodsInOut: '',
    date: '',
    time: '',
    weight: '',
    units: '',
    image: null,
};

const eDocketInitialValues: EDocketFormType = {
    trader: '',
    receiver: '',
    haulier: '',
    product: {
        description: '',
        code: '',
        accountNumber: '',
        from: '',
    },
    driver: {
        vehicleRegisterNumber: '',
        trailerIdNumber: '',
    },
    firstWeight: { ...weight },
    secondWeight: null,
    afterWeight: null,
    signatures: null,
};

const EDocketForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('edocket');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eDocketId } = useParams<{ eDocketId?: string }>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [eDocket, setEDocket] = useState<EDocket | undefined | null>(null);

    const [isOperatorModalVisible, setIsOperatorConfirmationModalVisible] = useState<boolean>(false);

    const validationSchema = useMemo(() => eDocketFormValidationSchema(eDocket), [eDocket]);

    const formik = useFormik<EDocketFormType>({
        initialValues: eDocket ?? eDocketInitialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    // Validate form
    // Debouncing to avoid validation on each change, thus not hindering performance
    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);

    const handleClose = () => {
        navigate(-1);
    };

    // Will have operator signature parameter passed only if coming from operator confirmation modal
    const handleSubmit = (operatorSignature?: string) => {
        if (operatorSignature) {
            formik.values.signatures = { operator: operatorSignature, driver: null };
            formik.setFieldValue('signatures', { operator: operatorSignature, driver: null });
        }

        console.log('submitting', formik.values);

        // TODO Create/update eDocket and update eDocket state
        // TODO Remove this FE simulation when BE is ready
        const response = {
            status: 'success',
            data: { ...formik.values } as EDocket,
        };

        if (response.status !== 'success') {
            // TODO Notification - ERROR
            return;
        }

        setEDocket(response.data);

        /**
         * Entering  if:
         *  At the signature state
         *  AND- After weight is not saved/submitted
         *
         *  OR-
         *
         *  At the signature stage
         *  AND- There is no driver signature
         *  AND- Not coming from the operator confirmation modal
         */
        if (
            (formik.values.afterWeight && !eDocket?.afterWeight) ||
            (formik.values.afterWeight && !eDocket?.signatures?.driver && !operatorSignature)
        ) {
            setIsOperatorConfirmationModalVisible(true);
        } else {
            // TODO Notification - SUCCESS
        }
    };

    useEffect(() => {
        if (!eDocketId) {
            return;
        }

        // TODO Fetch eDocket and update eDocket state
    }, [eDocketId]);

    return (
        <Main>
            {/* TODO Remove this FE simulation after BE is ready */}
            <ButtonContainer style={{ overflowX: 'auto' }}>
                <Button onClick={() => setEDocket(null)}>Reset</Button>
                <Button onClick={() => setEDocket({ ...eDocketDataAfterFirstWeight })}>Test After First Weight</Button>
                <Button onClick={() => setEDocket({ ...eDocketDataAfterSecondWeight })}>
                    Test After Second Weight
                </Button>
                <Button onClick={() => setEDocket({ ...eDocketDataAfterAfterWeight })}>Test After After Weight</Button>
                <Button onClick={() => setEDocket({ ...eDocketDataAfterOperatorConfirmation })}>
                    Test After Operator Confirmation
                </Button>
                <Button onClick={() => setEDocket({ ...eDocketDataAfterDriverSignature })}>
                    Test After Driver Signature
                </Button>
            </ButtonContainer>
            <div>
                <H4>E-Docket</H4>
                {eDocket && <EDocketInfo eDocketId={eDocket.id} dateGenerated={eDocket.dateGenerated} t={t} />}
            </div>
            <EDocketFormGeneral formik={formik} t={t} />
            <EDocketFormProduct formik={formik} t={t} />
            <EDocketFormDriver formik={formik} t={t} />
            <EDocketFormWeight formik={formik} weight="first" t={t} />
            {eDocket && <EDocketFormWeight formik={formik} weight="second" t={t} />}
            {eDocket?.secondWeight && <EDocketFormAfterWeight formik={formik} t={t} />}
            {eDocket?.signatures?.operator && (
                <EDocketFormSignatures
                    formik={formik}
                    onResend={() => setIsOperatorConfirmationModalVisible(true)}
                    t={t}
                />
            )}
            <ButtonContainer>
                <Button onClick={handleClose}>{t('close')}</Button>
                <Button type="primary" onClick={() => handleSubmit()} disabled={!isEmpty(formik.errors)}>
                    {eDocket?.signatures?.operator ? t('submit') : t('saveAndContinue')}
                </Button>
            </ButtonContainer>
            <EDocketFormOperatorConfirmationModal
                visible={isOperatorModalVisible}
                onCancel={() => setIsOperatorConfirmationModalVisible(false)}
                onSubmit={handleSubmit}
                t={t}
            />
        </Main>
    );
};

export default EDocketForm;
