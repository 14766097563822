import React, { FC, ReactNode, useMemo, useState } from 'react';
import {
    IrelandNotification,
    IrelandNotificationType,
    irelandNotificationTypeLabel,
} from 'store/declarations/ireland/ireland-notification';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import {
    NotificationCode,
    NotificationDescription,
    NotificationDescriptionCode,
    NotificationMessage,
    NotificationText,
    StyledDownOutlined,
    StyledNotificationRow,
} from './DeclarationNotifications.style';

export const GeneralNotification: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.responseDateLimit) {
            const dateTime = new Date(notification.responseDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.responseDateLimit]);

    return (
        <div>
            {notification.responseDateLimit && (
                <NotificationDescriptionCode>
                    Response Date Limit:
                    <NotificationText>{date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.remarks && (
                <NotificationDescriptionCode>
                    Remarks:
                    <NotificationText>{notification.remarks}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const RejectedNotification: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.rejectionDate) {
            const dateTime = new Date(notification.rejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.rejectionDate]);

    return (
        <div>
            {notification.rejectionDate && (
                <NotificationDescriptionCode>
                    Rejection Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const PresentationNotificationRejected: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.notificationRejectionDate) {
            const dateTime = new Date(notification.notificationRejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.notificationRejectionDate]);

    return (
        <div>
            {notification.notificationRejectionDate && (
                <NotificationDescriptionCode>
                    Rejection Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const AmendmentRejectionRejected: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.amendmentRejectionDate) {
            const dateTime = new Date(notification.amendmentRejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.amendmentRejectionDate]);

    return (
        <div>
            {notification.amendmentRejectionDate && (
                <NotificationDescriptionCode>
                    Amendment Rejection Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const DeclarationAmendmentAccepted: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return <div>{children}</div>;
};

export const DeclarationAmendmentRequested: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return <div>{children}</div>;
};

export const DeclarationAmendmentRequestCancellation: FC<{
    notification: IrelandNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    return <div>{children}</div>;
};

export const DeclarationAmendmentRejected: FC<{
    notification: IrelandNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    const notificationsMessages = useMemo(() => {
        const messages = notification.submissionErrors || [];

        if (messages?.length === 0) {
            return <></>;
        }

        return messages.map((message) => {
            return (
                <React.Fragment key={message.id}>
                    {message.businessRuleCode && (
                        <NotificationDescriptionCode>
                            Business Rule Code:
                            <NotificationText> {message.businessRuleCode}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.fields && (
                        <NotificationDescriptionCode>
                            Field:
                            <NotificationText> {message.fields}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.value && (
                        <NotificationDescriptionCode>
                            Value:
                            <NotificationText> {message.value}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.irelandTranslationTable?.description && (
                        <NotificationDescriptionCode>
                            Translation Description:
                            <NotificationText> {message?.irelandTranslationTable?.description}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.irelandTranslationTable?.message && (
                        <NotificationDescriptionCode>
                            Translation Message:
                            <NotificationText> {message?.irelandTranslationTable?.message}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                </React.Fragment>
            );
        });
    }, [notification]);
    return (
        <div>
            {children}
            {notificationsMessages}
        </div>
    );
};

export const UnderControlNotification: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const timeLimitForControl = useMemo(() => {
        if (notification.timeLimitForControl) {
            const dateTime = new Date(notification.timeLimitForControl);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.timeLimitForControl]);

    const controlNotificationDate = useMemo(() => {
        if (notification.controlNotificationDate) {
            const dateTime = new Date(notification.controlNotificationDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.controlNotificationDate]);

    return (
        <div>
            {notification.timeLimitForControl && (
                <NotificationDescriptionCode>
                    Time Limit For Control:
                    <NotificationText> {timeLimitForControl}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlNotificationDate && (
                <NotificationDescriptionCode>
                    Cotrol Notification Date:
                    <NotificationText> {controlNotificationDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlType && (
                <NotificationDescriptionCode>
                    Control Type:
                    <NotificationText>{notification.controlType}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const InvalidationRequestDecisionNotification: FC<{
    notification: IrelandNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    const notificationsMessages = useMemo(() => {
        const messages = notification.submissionErrors || [];

        if (messages?.length === 0) {
            return <></>;
        }

        return messages.map((message) => {
            return (
                <React.Fragment key={message.id}>
                    {message.businessRuleCode && (
                        <NotificationDescriptionCode>
                            Business Rule Code:
                            <NotificationText> {message.businessRuleCode}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.fields && (
                        <NotificationDescriptionCode>
                            Field:
                            <NotificationText> {message.fields}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.value && (
                        <NotificationDescriptionCode>
                            Value:
                            <NotificationText> {message.value}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.irelandTranslationTable?.description && (
                        <NotificationDescriptionCode>
                            Translation Description:
                            <NotificationText> {message?.irelandTranslationTable?.description}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.irelandTranslationTable?.message && (
                        <NotificationDescriptionCode>
                            Translation Message:
                            <NotificationText> {message?.irelandTranslationTable?.message}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                </React.Fragment>
            );
        });
    }, [notification]);
    return (
        <div>
            {children}
            {notificationsMessages}
        </div>
    );
};

export const DeclarationInvalidationRequest: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return (
        <div>
            {children}
            {notification.caseId && (
                <NotificationDescriptionCode>
                    Case ID:
                    <NotificationText> {notification?.caseId}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const DeclarationInsufficientFunds: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.responseDateLimit) {
            const dateTime = new Date(notification.responseDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.responseDateLimit]);

    return (
        <div>
            {children}
            {notification.remarks && (
                <NotificationDescriptionCode>
                    Remarks:
                    <NotificationText> {notification?.remarks}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.responseDateLimit && (
                <NotificationDescriptionCode>
                    Response Date Limit:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.insufficientFundsValue && (
                <NotificationDescriptionCode>
                    Insufficient Funds:
                    <NotificationText> {notification?.insufficientFundsValue}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const RefundApplicationRequired: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.responseDateLimit) {
            const dateTime = new Date(notification.responseDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.responseDateLimit]);

    return (
        <div>
            {children}
            {notification.remarks && (
                <NotificationDescriptionCode>
                    Remarks:
                    <NotificationText> {notification?.remarks}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.responseDateLimit && (
                <NotificationDescriptionCode>
                    Response Date Limit:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const RemissionRefundRequestAcceptance: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification?.remissionRefundApplicationDecisionPayload?.timeLimit) {
            const dateTime = new Date(notification.remissionRefundApplicationDecisionPayload.timeLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.remissionRefundApplicationDecisionPayload?.timeLimit]);

    // TODO add other fields as needed, decision payload seems too big to put all in notification
    return (
        <div>
            {children}
            {notification?.remissionRefundApplicationDecisionPayload?.generalRemarks &&
                notification?.remissionRefundApplicationDecisionPayload?.generalRemarks.map((generalRemark, index) => (
                    <NotificationDescriptionCode key={index}>
                        General remarks {index + 1}:<NotificationText> {generalRemark}</NotificationText>
                    </NotificationDescriptionCode>
                ))}
            {notification?.remissionRefundApplicationDecisionPayload?.statementOfTheDecision && (
                <NotificationDescriptionCode>
                    Statement of the decision:
                    <NotificationText>
                        {' '}
                        {notification.remissionRefundApplicationDecisionPayload.statementOfTheDecision}
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.remissionRefundApplicationDecisionPayload?.timeLimit && (
                <NotificationDescriptionCode>
                    Time limit:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const DepositRefundRequestApplicationAccepted: FC<{
    notification: IrelandNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    return (
        <div>
            {children}
            {notification?.ieDepositRefundApplicationDecision?.header.statementOfTheDecisionTakingCustomsAuthority && (
                <NotificationDescriptionCode>
                    Statement of the decision taking customs authority:
                    <NotificationText>
                        {
                            notification.ieDepositRefundApplicationDecision.header
                                .statementOfTheDecisionTakingCustomsAuthority
                        }
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.ieDepositRefundApplicationDecision?.depositRefundDetails.amountOfDepositRefund && (
                <NotificationDescriptionCode>
                    Amount of deposit refund:
                    <NotificationText>
                        {' '}
                        {notification.ieDepositRefundApplicationDecision.depositRefundDetails.amountOfDepositRefund}
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.ieDepositRefundApplicationDecision?.depositRefundDetails.payerEORIForRefund && (
                <NotificationDescriptionCode>
                    Payer eori for refund:
                    <NotificationText>
                        {' '}
                        {notification.ieDepositRefundApplicationDecision.depositRefundDetails.payerEORIForRefund}
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const RemissionRefundRequestRejection: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification?.remissionRequestRejectionDate) {
            const dateTime = new Date(notification.remissionRequestRejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.remissionRequestRejectionDate]);

    return (
        <div>
            {children}
            {notification?.remissionRequestRejectionDate && (
                <NotificationDescriptionCode>
                    Remission request rejection date:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const DepositRefundRequestApplicationRejected: FC<{
    notification: IrelandNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    const date = useMemo(() => {
        if (notification?.depositRequestRejectionDate) {
            const dateTime = new Date(notification.depositRequestRejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.depositRequestRejectionDate]);

    return (
        <div>
            {children}
            {notification?.depositRequestRejectionDate && (
                <NotificationDescriptionCode>
                    Deposit request rejection date:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.ieDepositRefundApplicationDecision?.header.statementOfTheDecisionTakingCustomsAuthority && (
                <NotificationDescriptionCode>
                    Statement of the decision taking customs authority:
                    <NotificationText>
                        {
                            notification.ieDepositRefundApplicationDecision.header
                                .statementOfTheDecisionTakingCustomsAuthority
                        }
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.ieDepositRefundApplicationDecision?.header.reasonNotApproved && (
                <NotificationDescriptionCode>
                    Reason not approved:
                    <NotificationText>
                        {notification.ieDepositRefundApplicationDecision.header.reasonNotApproved}
                    </NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const DepositRefundRequestRejection: FC<{ notification: IrelandNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification?.depositRequestRejectionDate) {
            const dateTime = new Date(notification.depositRequestRejectionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.depositRequestRejectionDate]);

    return (
        <div>
            {children}
            {notification?.depositRequestRejectionDate && (
                <NotificationDescriptionCode>
                    Deposit request rejection date:
                    <NotificationText> {date}</NotificationText>
                </NotificationDescriptionCode>
            )}
        </div>
    );
};

export const NotificationRow: FC<{ notification: IrelandNotification }> = ({ notification }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const date = useMemo(() => {
        if (notification.notificationDate) {
            const dateTime = new Date(notification.notificationDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.notificationDate]);

    const remarks = useMemo(() => {
        return notification?.submissionErrors?.find((e) => !!e.remarks)?.remarks;
    }, [notification]);

    const basicNotification = useMemo(() => {
        return (
            <>
                {notification.message && (
                    <NotificationDescriptionCode>
                        Message:
                        <NotificationText>{notification.message}</NotificationText>
                    </NotificationDescriptionCode>
                )}
                {remarks && (
                    <NotificationDescriptionCode>
                        Remarks:
                        <NotificationText>{remarks}</NotificationText>
                    </NotificationDescriptionCode>
                )}
                {notification.underControlGoodsShipmentItems && (
                    <NotificationDescriptionCode>
                        Items:
                        {notification.underControlGoodsShipmentItems.map((element, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <NotificationDescriptionCode key={index}>
                                        Item Number:
                                        <NotificationText>{element.goodsItemNumber}</NotificationText>
                                    </NotificationDescriptionCode>
                                    {element.controlTypes && (
                                        <NotificationDescriptionCode key={index}>
                                            Control Type:
                                            {element.controlTypes.map((obj, index) => (
                                                <>
                                                    <NotificationDescriptionCode>
                                                        Control Type Code:
                                                        <NotificationText>{obj.controlTypeCoded}</NotificationText>
                                                    </NotificationDescriptionCode>
                                                    <NotificationDescriptionCode>
                                                        Control Agency:
                                                        <NotificationText>{obj.controlAgency}</NotificationText>
                                                    </NotificationDescriptionCode>
                                                </>
                                            ))}
                                        </NotificationDescriptionCode>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </NotificationDescriptionCode>
                )}
            </>
        );
    }, [notification, remarks]);

    const notificationDescription = useMemo(() => {
        switch (notification.type) {
            case IrelandNotificationType.GENERAL_NOTIFICATION:
                return <GeneralNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.REJECTED:
                return <RejectedNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_REJECTED:
                return <DeclarationAmendmentRejected notification={notification} children={basicNotification} />;
            case IrelandNotificationType.UNDER_CONTROL:
                return <UnderControlNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.INVALIDATION_REQUEST_DECISION:
                return (
                    <InvalidationRequestDecisionNotification notification={notification} children={basicNotification} />
                );
            case IrelandNotificationType.DECLARATION_INVALIDATION_REQUEST:
                return <DeclarationInvalidationRequest notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_ACCEPTED:
                return <DeclarationAmendmentAccepted notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_REQUEST:
                return <DeclarationAmendmentRequested notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_REQUEST_CANCELLATION:
                return (
                    <DeclarationAmendmentRequestCancellation notification={notification} children={basicNotification} />
                );
            case IrelandNotificationType.INSUFFICIENT_FUNDS:
                return <DeclarationInsufficientFunds notification={notification} children={basicNotification} />;
            case IrelandNotificationType.REFUND_APPLICATION_REQUIRED:
                return <RefundApplicationRequired notification={notification} children={basicNotification} />;
            case IrelandNotificationType.REMISSION_REFUND_REQUEST_ACCEPTANCE:
                return <RemissionRefundRequestAcceptance notification={notification} children={basicNotification} />;
            case IrelandNotificationType.REMISSION_REFUND_REQUEST_REJECTION:
                return <RemissionRefundRequestRejection notification={notification} children={basicNotification} />;
            case IrelandNotificationType.DEPOSIT_REFUND_REQUEST_APPLICATION_ACCEPTED:
                return (
                    <DepositRefundRequestApplicationAccepted notification={notification} children={basicNotification} />
                );
            case IrelandNotificationType.DEPOSIT_REFUND_REQUEST_APPLICATION_REJECTED:
                return (
                    <DepositRefundRequestApplicationRejected notification={notification} children={basicNotification} />
                );
            case IrelandNotificationType.DEPOSIT_REFUND_REQUEST_REJECTION:
                return <DepositRefundRequestRejection notification={notification} children={basicNotification} />;
            default:
                return basicNotification;
        }
    }, [notification, basicNotification]);

    return (
        <StyledNotificationRow>
            <NotificationMessage onClick={() => setShowDescription(!showDescription)}>
                <StyledDownOutlined expanded={showDescription} />
                <NotificationCode>
                    Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationCode>
                <NotificationCode>
                    Type:
                    <NotificationText>
                        {toTitleCase(
                            irelandNotificationTypeLabel.find((e) => e.id === notification.type)?.value ?? '-'
                        )}
                    </NotificationText>
                </NotificationCode>
            </NotificationMessage>
            {showDescription ? <NotificationDescription>{notificationDescription}</NotificationDescription> : <></>}
        </StyledNotificationRow>
    );
};

export default NotificationRow;
