import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import { ValidationSchema, validators } from '../validations/validations';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';

type Fields = 'invoiceAmount' | 'invoiceAmountCurrencyId';

export const totalAmountInvoicedBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        invoiceAmount: [validators.number(), validators.float(16, 2)],
        invoiceAmountCurrencyId: [validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields> {}

const TotalAmountInvoicedBlock = ({ fieldOptions }: Props): ReactElement => {
    const ucc = useMemo(() => '4/11', []);
    const { t } = useTranslation('form');

    return (
        <>
            <DeclarationField name="invoiceAmount">
                {({ form, field }) => (
                    <DeclarationNumberInput
                        refNumber={ucc}
                        maxLength={16}
                        label={t('totalAmountInvoicedExport.amount')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField name="invoiceAmountCurrencyId">
                {({ form, field }) => (
                    <CurrencySelect
                        refNumber={ucc}
                        label={t('totalAmountInvoicedExport.currency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default TotalAmountInvoicedBlock;
