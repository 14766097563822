import { FC, useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';

const RefundsRemissions: FC<{}> = () => {
    const { declaration } = useRefundsRemissions();

    const declarationRemission = useMemo(() => {
        return (
            <Outlet
                context={{
                    declaration: declaration,
                }}
            />
        );
    }, [declaration]);

    return declarationRemission;
};
export default RefundsRemissions;
export function useRefundsRemissions() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
