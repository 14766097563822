import WCOCompanyDetails from 'views/setup/weighbridge-client-onboarding/screens/WCOCompanyDetails';
import WCOInitial from 'views/setup/weighbridge-client-onboarding/screens/WCOInitial';
import WCOLocations from 'views/setup/weighbridge-client-onboarding/screens/WCOLocations';
import WCOUsers from 'views/setup/weighbridge-client-onboarding/screens/WCOUsers';
import WCO from 'views/setup/weighbridge-client-onboarding/WCO';
import { Route } from '../../routes';

const wcoInitial: Route = {
    name: 'WCOInitial',
    index: true,
    element: <WCOInitial />,
};

const wcoCompanyDetails: Route = {
    path: 'company-details',
    name: 'WCOCompanyDetails',
    element: <WCOCompanyDetails />,
};

const wcoLocations: Route = {
    path: 'locations',
    name: 'WCOLocations',
    element: <WCOLocations />,
};

const wcoUsers: Route = {
    path: 'first-admin',
    name: 'WCOUsers',
    element: <WCOUsers isFirstAdmin />,
};

const wcoRoutes: Route[] = [
    {
        path: 'company-registration',
        name: 'WCO',
        element: <WCO />,
        children: [wcoInitial, wcoCompanyDetails, wcoLocations, wcoUsers],
    },
];

export default wcoRoutes;
