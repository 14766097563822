import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import FormTextArea from 'components/ui/composed/formTextArea/FormTextArea';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type AmendmentReasonModalProps = {
    open?: boolean;
    onCancel?: () => void;
    onSubmit: (values: { detailsAmended: string }) => void;
};

const AmendmentReasonModal = ({ open = false, onCancel = () => {}, onSubmit }: AmendmentReasonModalProps) => {
    const formik = useFormik({
        initialValues: { detailsAmended: '' },
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            detailsAmended: Yup.string().nullable().max(512),
        }),
        onSubmit: async (values: { detailsAmended: string }) => onSubmit?.(values),
    });

    return (
        <CustomModal
            title={<H5>Amendment Reason</H5>}
            centered
            cancelText="Cancel"
            confirmText="Continue"
            testId="amendment-reason-modal"
            visible={open}
            onOk={() => {
                formik.submitForm();
            }}
            onCancel={() => {
                formik.resetForm();
                onCancel?.();
            }}
            width={676}
        >
            <FormTextArea fieldProps={formik.getFieldProps('detailsAmended')} />
        </CustomModal>
    );
};

export default AmendmentReasonModal;
