import { CdsMessageType } from './export-declaration';

export enum UkImportDeclarationName {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
}

export const ukDeclarationNameLabels = [
    {
        key: UkImportDeclarationName.H1,
        value: '(H1) Free Circulation',
    },
    {
        key: UkImportDeclarationName.H2,
        value: '(H2) Customs Warehousing',
    },
    {
        key: UkImportDeclarationName.H3,
        value: '(H3) Temporary Admission',
    },
    {
        key: UkImportDeclarationName.H4,
        value: '(H4) Inward Processing ',
    },
    {
        key: UkImportDeclarationName.H5,
        value: '(H5) Introductions of Goods (SFT)',
    },
] as const;

export const ukExportDeclarationNameLabels = [
    {
        key: CdsMessageType.B1,
        value: 'B1',
    },
    {
        key: CdsMessageType.C1,
        value: 'C1',
    },
    {
        key: CdsMessageType.B2,
        value: 'B2',
    },
    {
        key: CdsMessageType.B4,
        value: 'B4',
    },
    {
        key: CdsMessageType.C21E,
        value: 'C21E',
    },
] as const;
