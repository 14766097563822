import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id';

export const combinedNomenclatureCodesBlockValidation: {
    childValidators: {
        'commodity.classification.tsp': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.classification.tsp': {
            childValidators: {
                id: [validators.required(), validators.exact(8)],
            },
        },
    },
};

export const combinedNomenclatureCodesFields =
    combinedNomenclatureCodesBlockValidation.childValidators['commodity.classification.tsp'];

interface Props extends BlockProps<Fields> {}

const CombinedNomenclatureCodeBlock = ({
    path = 'commodity.classification.tsp',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/14', []);
    return (
        <DeclarationField pathPrefix={path} name="id">
            {({ form, field }: FieldProps<any>) => (
                <FormInput
                    required={fieldOptions?.id?.required ?? true}
                    refNumber={ucc}
                    label={t('combinedNomenclatureCodeIdentifier')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default CombinedNomenclatureCodeBlock;
