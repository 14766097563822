interface DeclarationBlockProps {
    hide: boolean | undefined;
    children: JSX.Element[] | JSX.Element | null;
}

const HideComponent = ({ hide, children }: DeclarationBlockProps): any => {
    if (!hide) {
        return children;
    }

    return null;
};

export default HideComponent;
