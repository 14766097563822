import { useAppDispatch, useAppSelector } from '../config/hooks';
import { useCallback, useEffect } from 'react';
import { doGetCurrencies } from '../store/currencies/actions';

const useCurrencies = () => {
    const currencies = useAppSelector((state) => state.currencies.currencies);
    const isLoading = useAppSelector((state) => state.currencies.isLoading);
    const error = useAppSelector((state) => state.currencies.error);

    const dispatch = useAppDispatch();

    const getCurrencies = useCallback(() => dispatch(doGetCurrencies()), [dispatch]);

    useEffect(() => {
        if (!currencies.list.length && !isLoading && !error) {
            getCurrencies();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        currencies,
        isLoading,
        error,
    };
};

export default useCurrencies;
