import styled from 'styled-components';
import { colors, device } from 'theme';
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export const StyledHeader = styled(Layout.Header)`
    background-color: ${colors.white};
    filter: drop-shadow(0px 0px 24px rgba(9, 0, 255, 0.07));
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const UncollapseIcon = styled(MenuUnfoldOutlined)`
    margin-left: 2rem;

    @media ${device.tablet} {
        display: none;
    }
`;

export const CollapseIcon = styled(MenuFoldOutlined)`
    margin-left: 2rem;

    @media ${device.tablet} {
        display: none;
    }
`;

export const UserDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4rem;
`;

export const InfoSpan = styled.span`
    margin-left: 1rem;
`;
