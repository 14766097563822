import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';

const AmountsHeldOnDepositCard: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    return (
        <section
            id={'deposit-amount-held-on-deposit-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('deposit-amount-held-on-deposit-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('depositRefund.amounhHeldOnDeposit')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`customDutyHeldOnDeposit`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.customDutyHeldOnDeposit')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`vatHeldOnDeposit`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.vatHeldOnDeposit')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`otherDutiesHeldOnDeposit`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('depositRefund.otherDutiesHeldOnDeposit')}
                />
            </FormCard>
        </section>
    );
};
export default AmountsHeldOnDepositCard;
