import { useFormik } from 'formik';
import useDebouncedValidate from 'hooks/useDebouncedValidate';
import { WCWeighbridgeLocationTraceFormikFields } from '../WCTypes';
import { wcWeighbridgeLocationTraceValidationSchema } from './wcValidationSchemas';

const initialValues: WCWeighbridgeLocationTraceFormikFields = {
    coordinates: [],
};

const useFormikWeighbridgeLocationTrace = () => {
    const formik = useFormik({
        initialValues,
        validationSchema: wcWeighbridgeLocationTraceValidationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    useDebouncedValidate(formik);

    return formik;
};

export default useFormikWeighbridgeLocationTrace;
