import { Col, Form, Row } from 'antd';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormNumberInput from 'components/ui/composed/formInput/FormNumberInput';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useCargo from 'hooks/useCargo';
import useCurrencies from 'hooks/useCurrencies';
import { FC } from 'react';
import { Cargo, CargoType, DimensionsUnit, WeightUnit } from 'store/cargo/cargo';
import * as Yup from 'yup';

interface Props {
    closeDrawer: () => void;
    handleOk: (newCargo?: any) => void;
    handleEdit?: (cargo?: any) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    cargo?: Cargo;
}

const cargoSchema = () => {
    return Yup.object().shape({
        type: Yup.string().required('Type name is required.'),
        description: Yup.string().required('Description is required.'),
        weight: Yup.string().required('Weight is required.'),
        weightUnit: Yup.string().required('Weight unit is required.'),
        width: Yup.string().required('Width is required.').nullable(),
        length: Yup.string().required('Length is required.').nullable(),
        height: Yup.string().required('Height is required.').nullable(),
        dimensionsUnit: Yup.string().required('Dimensions unit is required.'),
        constraints: Yup.string().nullable(),
        currency: Yup.string().required('Currency is required.').nullable(),
        value: Yup.string().required('Value is required.').nullable(),
    });
};

const CreateNewCargo: FC<Props> = ({ closeDrawer, handleOk, cargo, handleCreateError, handleEditError }) => {
    const { getFieldProps, resetForm, getFieldMeta, handleSubmit, isValid, getFieldHelpers } = useFormik<
        Partial<Cargo>
    >({
        initialValues: cargo || ({} as Cargo),
        validationSchema: cargoSchema(),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createCargoSubmit(values),
    });
    const { createCargo, editCargo } = useCargo();
    const { currencies } = useCurrencies();

    const createCargoSubmit = async (values: Partial<Cargo>) => {
        if (cargo && cargo.id) {
            const response = await editCargo(values as Cargo);
            if (response && response.id) {
                handleOk();
            }
        } else {
            const response = await createCargo(values as Cargo);
            if (response && response.id) {
                handleOk();
            }
        }
    };

    const onCloseButton = () => {
        closeDrawer();
        resetForm();
    };

    return (
        <Form layout="vertical">
            <FormSelect
                required
                label="Type"
                hideKeys
                fieldMeta={getFieldMeta('type')}
                fieldProps={getFieldProps('type')}
                fieldHelper={getFieldHelpers('type')}
                selectOptions={Object.entries(CargoType).map(([key, value]) => ({
                    id: key,
                    value: value.replace('_', ' '),
                }))}
            />
            <FormInput
                required
                label="Description"
                fieldMeta={getFieldMeta('description')}
                fieldProps={getFieldProps('description')}
                fieldHelper={getFieldHelpers('description')}
            />
            <FormNumberInput
                required
                label="Weight"
                fieldMeta={getFieldMeta('weight')}
                fieldProps={getFieldProps('weight')}
                fieldHelper={getFieldHelpers('weight')}
            />
            <FormSelect
                required
                label="Weight unit"
                hideKeys
                fieldMeta={getFieldMeta('weightUnit')}
                fieldProps={getFieldProps('weightUnit')}
                fieldHelper={getFieldHelpers('weightUnit')}
                selectOptions={Object.entries(WeightUnit).map(([key, value]) => ({
                    id: key,
                    value: value.replace('_', ' '),
                }))}
            />
            <Row gutter={18}>
                <Col span={8}>
                    <FormNumberInput
                        required
                        label="Length"
                        fieldMeta={getFieldMeta('length')}
                        fieldProps={getFieldProps('length')}
                        fieldHelper={getFieldHelpers('length')}
                    />
                </Col>
                <Col span={8}>
                    <FormNumberInput
                        required
                        label="Width"
                        fieldMeta={getFieldMeta('width')}
                        fieldProps={getFieldProps('width')}
                        fieldHelper={getFieldHelpers('width')}
                    />
                </Col>
                <Col span={8}>
                    <FormNumberInput
                        required
                        label="Height"
                        fieldMeta={getFieldMeta('height')}
                        fieldProps={getFieldProps('height')}
                        fieldHelper={getFieldHelpers('height')}
                    />
                </Col>
            </Row>
            <FormSelect
                required
                label="Dimension unit"
                hideKeys
                fieldMeta={getFieldMeta('dimensionsUnit')}
                fieldProps={getFieldProps('dimensionsUnit')}
                fieldHelper={getFieldHelpers('dimensionsUnit')}
                selectOptions={Object.entries(DimensionsUnit).map(([key, value]) => ({
                    id: key,
                    value: value.replace('_', ' '),
                }))}
            />
            <FormInput
                label="Constraints"
                fieldMeta={getFieldMeta('constraints')}
                fieldProps={getFieldProps('constraints')}
                fieldHelper={getFieldHelpers('constraints')}
            />

            <FormNumberInput
                label="Value"
                required
                fieldMeta={getFieldMeta('value')}
                fieldProps={getFieldProps('value')}
                fieldHelper={getFieldHelpers('value')}
            />

            <FormSelect
                label="Currency"
                required
                fieldMeta={getFieldMeta('currency')}
                fieldProps={getFieldProps('currency')}
                fieldHelper={getFieldHelpers('currency')}
                selectOptions={currencies.list.map((c) => {
                    return {
                        id: c.code,
                        value: c.name,
                    };
                })}
            />

            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button disabled={!isValid} size="large" type="primary" onClick={() => handleSubmit()}>
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateNewCargo;
