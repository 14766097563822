import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ExportGoodsInformation,
    OtherDocuments,
} from 'store/declarations/ireland/refunds/deposits/ireland-refund-deposit';
import { CardContent } from 'views/declarations/common/box44/Box44Card.styles';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';
import { ModalExportGoodsInformation } from './ExportGoodsInformationCard';

const OtherDocumentsCard: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    const formik = useFormik<OtherDocuments>({
        initialValues: {},
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });
    const {
        setValues,
        getFieldProps: formGetFieldProps,
        getFieldHelpers: formGetFieldHelpers,
        getFieldMeta: formGetFieldMeta,
        resetForm,
    } = formik;

    const formikExportGoodsInformation = useFormik<ExportGoodsInformation>({
        initialValues: {},
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addGoodsItem(values);
        },
    });

    const {
        setValues: setValuesGoodsInformation,
        getFieldProps: formGoodsInformationGetFieldProps,
        getFieldHelpers: formGoodsInformationGetFieldHelpers,
        getFieldMeta: formGoodsInformationGetFieldMeta,
        resetForm: resetFormGoodsInformation,
    } = formikExportGoodsInformation;

    const { modalVisible, handleEdit, handleCancel, onClickAddItem, handleDelete, addItem } = useModalMultipleCards({
        formikProps: {
            fieldProps: getFieldProps('otherDocuments'),
            fieldHelper: getFieldHelpers('otherDocuments'),
            fieldMeta: getFieldMeta ? getFieldMeta('otherDocuments') : undefined,
        },
        setValues,
        resetForm,
    });

    const {
        modalVisible: modalGoodsItemVisible,
        handleEdit: handleEditGoodsItem,
        handleCancel: handleCancelGoodsItem,
        onClickAddItem: onClickAddGoodsItem,
        handleDelete: handleDeleteGoodsItem,
        addItem: addGoodsItem,
    } = useModalMultipleCards({
        formikProps: {
            fieldProps: formGetFieldProps('exportGoodsInformation'),
            fieldHelper: formGetFieldHelpers('exportGoodsInformation'),
            fieldMeta: formGetFieldMeta('exportGoodsInformation'),
        },
        setValues: setValuesGoodsInformation,
        resetForm: resetFormGoodsInformation,
    });

    const otherDocumentsDataList = useMemo(() => {
        const list = getFieldProps('otherDocuments').value;
        if (Array.isArray(list)) {
            return list.map((obj: OtherDocuments, index) => {
                return [
                    {
                        field: t('depositRefund.otherDocuments.methodOfDischarge'),
                        value: obj.methodOfDischarge,
                    },
                    {
                        field: t('depositRefund.otherDocuments.numberOfExportGoodsItems'),
                        value: obj.exportGoodsInformation?.length.toString() ?? '0',
                    },
                ];
            });
        }
        return [];
    }, [getFieldProps, t]);

    const exportGoodsInformationList = useMemo(() => {
        const list = formGetFieldProps('exportGoodsInformation').value;
        if (Array.isArray(list)) {
            return list.map((obj: ExportGoodsInformation, index) => {
                return [
                    {
                        field: t('remissionRefund.combinedNomenclatureCode'),
                        value: obj.commodityCode?.combinedNomenclatureCode,
                    },
                    {
                        field: t('depositRefund.taricCode'),
                        value: obj.commodityCode?.taricCode,
                    },
                    {
                        field: t('depositRefund.goodsDescription'),
                        value: obj.goodsDescription,
                    },
                    {
                        field: t('goodsItems.netMass'),
                        value: obj.netMass?.toString(),
                    },
                    {
                        field: t('goodsItems.supplementaryUnits'),
                        value: obj.supplementaryUnits?.toString(),
                    },
                ];
            });
        }
        return [];
    }, [formGetFieldProps, t]);

    return (
        <section
            id={'other-documents-list-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('other-documents-list-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('depositRefund.otherDocuments.title')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <CardContent>
                    <CardList
                        data={otherDocumentsDataList}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        viewOnly={viewOnly ?? false}
                    />
                    {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
                </CardContent>
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24}>
                        <FormInput
                            viewOnly={viewOnly}
                            {...getFormikProps(`methodOfDischarge`, {
                                getFieldHelpers: formGetFieldHelpers,
                                getFieldProps: formGetFieldProps,
                                getFieldMeta: formGetFieldMeta,
                            })}
                            label={t('depositRefund.otherDocuments.methodOfDischarge')}
                        />
                    </Col>
                    <Col xs={24}>
                        <CardContent>
                            <CardList
                                data={exportGoodsInformationList}
                                onDelete={handleDeleteGoodsItem}
                                onEdit={handleEditGoodsItem}
                                viewOnly={viewOnly ?? false}
                            />
                            {!viewOnly && <Button onClick={onClickAddGoodsItem}>Add item</Button>}
                        </CardContent>
                        <ModalExportGoodsInformation
                            getFieldHelpers={formGoodsInformationGetFieldHelpers}
                            getFieldMeta={formGoodsInformationGetFieldMeta}
                            getFieldProps={formGoodsInformationGetFieldProps}
                            modalVisible={modalGoodsItemVisible}
                            handleCancel={handleCancelGoodsItem}
                            viewOnly={viewOnly}
                            formik={formikExportGoodsInformation}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};
export default OtherDocumentsCard;
