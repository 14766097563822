import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { ParsedCard, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { CdsExportDeclaration, CdsExportDeclarationPayloadPayload } from 'store/declarations/uk/export-declaration';
import {
    parseFieldErrorsForAesCustomerCard,
    parseFieldErrorsForCard,
    parseFieldErrorsForMultipleCard,
} from 'utils/validationParserUtils';

export const aesFieldsWithHardCodedIndexesInParser = {
    countryOfRoutingOfConsignment: 'goodsShipment.consignment.countryOfRoutingOfConsignment.0.country',
    taricAdditionalCode: 'commodity.commodityCode.taricAdditionalCode.0.taricAdditionalCode',
    nationalAdditionalCode: 'commodity.commodityCode.nationalAdditionalCode.0.nationalAdditionalCode',
    additionalProcedure: 'procedure.additionalProcedure.0.additionalProcedure',
    unNumber: 'commodity.dangerousGoods.0.unNumber',
};

const irelandExportValidationErrorsParser = (validation: FormikErrors<CdsExportDeclaration>) => {
    const masterDetailsErrors: ParsedCard[] = [];
    parseFieldErrorsForCard(
        'Message Information',
        [
            { 'exportOperation.declarationType': 'Declaration Type' },
            { 'exportOperation.additionalDeclarationType': 'Additional Declaration Type' },
            { 'exportOperation.specificCircumstanceIndicator': 'Specific Circumstance Indicator' },
            { 'exportOperation.security': 'Security' },
            { numberOfItems: 'Number of Items' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'goodsShipment.previousDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'goodsShipment.additionalInformation',
        [{ code: 'Code' }, { text: 'Text' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Supporting Documents',
        'goodsShipment.supportingDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { issuingAuthorityName: 'Issuing Authority Name' },
            { validityDate: 'Date of Validity' },
            { documentLineItemNumber: 'Document Line Item Number' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Reference',
        'goodsShipment.additionalReference',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Transport Document',
        'goodsShipment.consignment.transportDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'References of Messages',
        [
            { 'goodsShipment.consignment.referenceNumberUCR': 'Reference Number/UCR' },
            { 'goodsShipment.warehouse.type': 'Warehouse - Type' },
            { 'goodsShipment.warehouse.identifier': 'Warehouse - Identifier' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Authorisation',
        'authorisation',
        [
            { type: 'Type' },
            { referenceNumber: 'Reference Number' },
            { holderOfAuthorisation: 'Holder of Authorisation' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForAesCustomerCard('Exporter', 'exporter', masterDetailsErrors, validation);
    parseFieldErrorsForAesCustomerCard(
        'Consignor',
        'goodsShipment.consignment.consignor',
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForAesCustomerCard(
        'Consignee',
        'goodsShipment.consignment.consignee',
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForAesCustomerCard('Declarant', 'declarant', masterDetailsErrors, validation);
    parseFieldErrorsForAesCustomerCard('Representative', 'representative', masterDetailsErrors, validation);
    parseFieldErrorsForAesCustomerCard('Carrier', 'goodsShipment.consignment.carrier', masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Additional Supply Chain Actor',
        'goodsShipment.additionalSupplyChainActor',
        [{ role: 'Role' }, { identificationNumber: 'Identification Number' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Valuation Information / Taxes',
        [
            { 'goodsShipment.consignment.transportCharges.methodOfPayment': 'Transport Charges - Method of Payment' },
            { 'exportOperation.invoiceCurrency': 'Invoice Currency' },
            { 'exportOperation.totalAmountInvoiced': 'Total Amount Invoiced' },
            {
                'currencyExchange.internalCurrencyUnit': 'Currency Exchange - Internal Currency Unit',
            },
            {
                'currencyExchange.exchangeRate': 'Currency Exchange - Exchange Rate',
            },
            {
                'deferredPayment.deferredPayment': 'Deferred Payment',
            },
            {
                'goodsShipment.natureOfTransaction': 'Nature of Transaction',
            },
            {
                'goodsShipment.deliveryTerms.incotermCode': 'Delivery Terms - Incoterm Code',
            },
            {
                'goodsShipment.deliveryTerms.unLocode': 'Delivery Terms - UN/LOCODE',
            },
            {
                'goodsShipment.deliveryTerms.country': 'Delivery Terms - Country',
            },
            {
                'goodsShipment.deliveryTerms.location': 'Delivery Terms - Location',
            },
            {
                'goodsShipment.deliveryTerms.text': 'Delivery Terms - Text',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Dates - Times - Periods',
        [
            {
                'exportOperation.presentationOfTheGoodsDateAndTime': 'Date and time of presentation of the goods',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Places / Countries / Regions',
        [
            {
                'goodsShipment.countryOfDestination': 'Country of Destination',
            },
            {
                'goodsShipment.countryOfExport': 'Country of Export',
            },
            {
                [aesFieldsWithHardCodedIndexesInParser.countryOfRoutingOfConsignment]:
                    'Country of Routing of Consignment',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Location of Goods',
        [
            {
                'goodsShipment.consignment.locationOfGoods.typeOfLocation': 'Type of Location',
            },
            {
                'goodsShipment.consignment.locationOfGoods.qualifierOfIdentification': 'Qualifier of Identification',
            },
            {
                'goodsShipment.consignment.locationOfGoods.unLocode': 'UN/LOCODE',
            },
            {
                'goodsShipment.consignment.locationOfGoods.authorisationNumber': 'Authorisation Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.additionalIdentifier': 'Additional Identifier',
            },
            {
                'goodsShipment.consignment.locationOfGoods.customsOffice.referenceNumber':
                    'Customs Office - Reference Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.gnss.latitude': 'Latitude',
            },
            {
                'goodsShipment.consignment.locationOfGoods.gnss.longitude': 'Longitude',
            },
            {
                'goodsShipment.consignment.locationOfGoods.economicOperator.identificationNumber':
                    'Economic Operator - Identification Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.address.streetAndNumber': 'Address - Street and Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.address.postcode': 'Address - Postcode',
            },
            {
                'goodsShipment.consignment.locationOfGoods.address.city': 'Address - City',
            },
            {
                'goodsShipment.consignment.locationOfGoods.address.country': 'Address - Country',
            },
            {
                'goodsShipment.consignment.locationOfGoods.postcodeAddress.houseNumber':
                    'Postcode Address - House Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.postcodeAddress.postcode': 'Postcode Address - Postcode',
            },
            {
                'goodsShipment.consignment.locationOfGoods.postcodeAddress.country': 'Postcode Address - Country',
            },
            {
                'goodsShipment.consignment.locationOfGoods.contactPerson.name': 'Contact Person - Name',
            },
            {
                'goodsShipment.consignment.locationOfGoods.contactPerson.phoneNumber': 'Contact Person - Phone Number',
            },
            {
                'goodsShipment.consignment.locationOfGoods.contactPerson.emailAddress':
                    'Contact Person - E-mail Address',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Offices',
        [
            {
                'customsOfficeOfExitDeclared.referenceNumber': 'Customs Office of Exit (Declared) - Reference Number',
            },
            {
                'customsOfficeOfExport.referenceNumber': 'Customs Office of Export - Reference Number',
            },
            {
                'customsOfficeOfPresentation.referenceNumber': 'Customs Office of Presentation - Reference Number',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Goods Identification',
        [
            {
                'goodsShipment.consignment.grossMass': 'Gross Mass',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Transport Information',
        [
            {
                'goodsShipment.consignment.containerIndicator': 'Container Indicator',
            },
            {
                'goodsShipment.consignment.modeOfTransportAtTheBorder': 'Mode of Transport at the Border',
            },
            {
                'goodsShipment.consignment.activeBorderTransportMeans.typeOfIdentification':
                    'Active Border Transport Means - Type of Identification',
            },
            {
                'goodsShipment.consignment.activeBorderTransportMeans.identificationNumber':
                    'Active Border Transport Means - Identification Number',
            },
            {
                'goodsShipment.consignment.activeBorderTransportMeans.nationality':
                    'Active Border Transport Means - Nationality',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Transport Equipment',
        'goodsShipment.consignment.transportEquipment',
        [
            { containerIdentificationNumber: 'Container Identification Number' },
            { numberOfSeals: 'Number of Seals' },
            { 'seal.identifier': 'Seals' },
            { 'goodsReference.declarationGoodsItemNumber': 'Goods References' },
        ],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ParsedItem[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (errors: ParsedItem[], validation: FormikErrors<CdsExportDeclarationPayloadPayload>) => {
    const products = get(validation, 'goodsShipment.goodsItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseExportProductTemplate = (validation: FormikErrors<CdsExportDeclarationPayloadPayload>) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseDeclarationItem(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseDeclarationItem = (parsed: ParsedCard[], errors: FormikErrors<CdsExportDeclarationPayloadPayload>) => {
    parseFieldErrorsForCard(
        'Commodity Codes',
        [
            { 'commodity.commodityCode.harmonizedSystemSubHeadingCode': 'Harmonized System Sub-Heading Code' },
            { 'commodity.commodityCode.combinedNomenclatureCode': 'Combined Nomenclature Code' },
            { [aesFieldsWithHardCodedIndexesInParser.taricAdditionalCode]: 'TARIC Additional Code' },
            { [aesFieldsWithHardCodedIndexesInParser.nationalAdditionalCode]: 'National Additional Code' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Goods Information',
        [
            { statisticalValue: 'Statistical Value' },
            { natureOfTransaction: 'Nature of Transaction' },
            { referenceNumberUCR: 'Reference Number/UCR' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Procedure',
        [
            { 'procedure.requestedProcedure': 'Requested Procedure' },
            { 'procedure.previousProcedure': 'Previous Procedure' },
            { [aesFieldsWithHardCodedIndexesInParser.additionalProcedure]: 'Additional Procedure' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Previous Document',
        'previousDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { typeOfPackages: 'Type of Packages' },
            { numberOfPackages: 'Number of Packages' },
            { measurementUnitAndQualifier: 'Measurement Unit and Qualifier' },
            { quantity: 'Quantity' },
            { goodsItemNumber: 'Goods Item Number' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [{ code: 'Code' }, { text: 'Text' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Supporting Documents',
        'supportingDocument',
        [
            { referenceNumber: 'Reference Number' },
            { type: 'Type' },
            { issuingAuthorityName: 'Issuing Authority Name' },
            { measurementUnitAndQualifier: 'Measurement Unit & Qualifier' },
            { quantity: 'Quantity' },
            { validityDate: 'Date of Validity' },
            { currency: 'Currency' },
            { amount: 'Document Line Item Number' },
            { documentLineItemNumber: 'Amount' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Additional Reference',
        'additionalReference',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Transport Document',
        'transportDocument',
        [{ referenceNumber: 'Reference Number' }, { type: 'Type' }],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Authorisation',
        'authorisation',
        [
            { type: 'Type' },
            { referenceNumber: 'Reference Number' },
            { holderOfAuthorisation: 'Holder of Authorisation' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForAesCustomerCard('Consignor', 'consignor', parsed, errors);
    parseFieldErrorsForAesCustomerCard('Consignee', 'consignee', parsed, errors);

    parseFieldErrorsForMultipleCard(
        'Additional Supply Chain Actor',
        'additionalSupplyChainActor',
        [{ role: 'Role' }, { identificationNumber: 'Identification Number' }],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Valuation Information / Taxes',
        [
            { 'transportCharges.methodOfPayment': 'Transport Charges - Method of Payment' },
            { 'commodity.calculationOfTaxes.totalDutiesAndTaxesAmount': 'Total Duties and Taxes Amount' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Duties and Taxes',
        'commodity.calculationOfTaxes.dutiesAndTaxes',
        [
            { taxType: 'Tax Type' },
            { payableTaxAmount: 'Payable Tax' },
            { methodOfPayment: 'Method of Payment' },
            { taxBase: 'Tax Bases' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Places / Countries / Regions',
        [
            { countryOfDestination: 'Country of Destination' },
            { countryOfExport: 'Country of Export' },
            { 'origin.countryOfOrigin': 'Country of Origin' },
            { 'origin.regionOfDispatch': 'Region of Dispatch' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Goods Identification',
        [
            { 'commodity.goodsMeasure.netMass': 'Net Mass' },
            { 'commodity.goodsMeasure.supplementaryUnits': 'Supplementary Units' },
            { 'commodity.goodsMeasure.grossMass': 'Gross Mass' },
            { 'commodity.descriptionOfGoods': 'Description of Goods' },
            { [aesFieldsWithHardCodedIndexesInParser.unNumber]: 'Dangerous Goods - UN Number' },
            { 'commodity.cusCode': 'CUS Code' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForMultipleCard(
        'Packaging',
        'packaging',
        [
            { typeOfPackages: 'Type of Packages' },
            { numberOfPackages: 'Number of Packages' },
            { shippingMarks: 'Shipping Marks' },
        ],
        parsed,
        errors
    );
};

export default irelandExportValidationErrorsParser;
