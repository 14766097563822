export enum TaxPaymentMethod {
    A = 'A', // A - Cash payment
    E = 'E', // E - Deferred or postponed payment
    J = 'J', // J - Payment through post office administration (postal consignments) or other public sector
    M = 'M', // M - Securities
}

export const taxPaymentMethodLabels = [
    { id: TaxPaymentMethod.A, value: 'Cash payment' },
    { id: TaxPaymentMethod.E, value: 'Deferred or postponed payment' },
    {
        id: TaxPaymentMethod.J,
        value: 'Payment through post office administration (postal consignments) or other public sector',
    },
    { id: TaxPaymentMethod.M, value: 'Securities' },
] as const;
