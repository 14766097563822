import config from 'config';
import { ListResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Tooltip } from './tooltip';

export const getH7Tooltips = (): Promise<Tooltip[]> =>
    axiosClient
        .get<ListResponse<Tooltip>>(`${config.cmsUrl}/tooltips/ireland/h7`)
        .then((response) => response.data.payload.list);

export const getH1Tooltips = (): Promise<Tooltip[]> =>
    axiosClient
        .get<ListResponse<Tooltip>>(`${config.cmsUrl}/tooltips/ireland/h1`)
        .then((response) => response.data.payload.list);

export const getCdsTooltips = (): Promise<Tooltip[]> =>
    axiosClient
        .get<ListResponse<Tooltip>>(`${config.cmsUrl}/tooltips/uk/cds`)
        .then((response) => response.data.payload.list);

export const getRevenueExportTooltips = (): Promise<Tooltip[]> =>
    axiosClient
        .get<ListResponse<Tooltip>>(`${config.cmsUrl}/tooltips/ireland/export`)
        .then((response) => response.data.payload.list);
