import { Form } from 'antd';
import styled from 'styled-components';
import { fonts } from 'theme';

const { Item } = Form;

export const FormItem = styled(Item)`
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: ${fonts.weight.semibold};
    font-family: Open Sans;
    font-style: normal;
    margin-bottom: 0px;
`;
