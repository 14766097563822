import { FormType } from 'components/ui/composed/template/TemplateContext';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface IDeclarationContext {
    form?: FormType;
    setForm?: Dispatch<SetStateAction<FormType>>;
    cards?: CardData[];
    registerCard?: (cardData: CardData) => void;
}

interface CardData {
    title: string;
    form: FormType;
    visible?: boolean;
    section?: boolean;
}

interface Props {
    value?: IDeclarationContext;
    children: ReactNode;
}

export const DeclarationContext = createContext<IDeclarationContext>({});
export const useDeclarationContext = () => useContext(DeclarationContext);

export const DeclarationContextProvider = (props: Props) => {
    const [form, setForm] = useState<FormType>('master');
    const [cards, setCards] = useState<CardData[]>([]);

    const registerCard = (cardData: CardData) => {
        setCards((prev) => {
            const index = prev.findIndex((card) => card.title === cardData.title && card.form === cardData.form);
            if (index === -1) prev.push(cardData);
            else prev[index] = cardData;
            return [...prev];
        });
    };

    return (
        <DeclarationContext.Provider value={{ form, setForm, cards, registerCard, ...props.value }}>
            {props.children}
        </DeclarationContext.Provider>
    );
};
