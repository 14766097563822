import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import TotalAmountInvoicedBlock, { totalAmountInvoicedBlockValidation } from '../blocks/TotalAmountInvoicedBlock';

export const totalAmountInvoicedCardValidation = {
    selfValidators: [totalAmountInvoicedBlockValidation],
};

const TotalAmountInvoicedCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('totalAmountInvoiced')} hidden={props.hidden}>
            <FormCardContainer>
                <TotalAmountInvoicedBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default TotalAmountInvoicedCard;
