import { Col, Row } from 'antd';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import MultiDeclarationInput from 'components/ui/composed/declarations/formInput/MultiDeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { appendFieldPath, getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const TransportInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const fields = props.getFields ? props.getFields() : [];
    const fullFieldPath = appendFieldPath(props.propsPathPrefix);

    return (
        <section
            id={'etd-transport-information-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('etd-transprot-information-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('transportInformation')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`departureTransportMeans.identificationType`, props)}
                            label={t('etd.departureTransportMeans.identificationType')}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`departureTransportMeans.identificationNumber`, props)}
                            label={t('etd.departureTransportMeans.identificationNumber')}
                        />
                    </Col>
                    <Col md={12}>
                        <CountrySelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`nationality`, props)}
                            label={t('nationality')}
                        />
                    </Col>
                    <Col md={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`seal.sealNumber`, props)}
                            label={t('etd.seal.sealNumber')}
                        />
                    </Col>
                    <Col md={24}>
                        <MultiDeclarationInput
                            viewOnly={props.viewOnly}
                            objectInput={'sealIdentifier'}
                            maxLength={17}
                            path={fullFieldPath(`seal.sealIdentifier`)}
                            refNumber="7.10"
                            label={t('etd.seal.sealIdentifier')}
                            getFieldProps={(value: string) => props.getFieldProps(value)}
                            getFieldMeta={props.getFieldMeta}
                            fieldHelpers={props.getFieldHelpers(fullFieldPath(`seal.sealIdentifier`))}
                            getFieldHelpers={props.getFieldHelpers}
                        />
                    </Col>
                    <Col md={24}>
                        <MultiDeclarationInput
                            viewOnly={props.viewOnly}
                            objectInput={'containerIdentificationNumber'}
                            maxLength={17}
                            path={fullFieldPath(`containerIdentificationNumber`)}
                            refNumber="7.10"
                            label={t('containerIdentificationNumbers')}
                            getFieldProps={(value: string) => props.getFieldProps(value)}
                            getFieldMeta={props.getFieldMeta}
                            fieldHelpers={props.getFieldHelpers(fullFieldPath(`containerIdentificationNumber`))}
                            getFieldHelpers={props.getFieldHelpers}
                        />
                    </Col>
                </Row>
            </FormCard>
        </section>
    );
};

export default TransportInformationCard;
