import { Menu } from 'antd';
import logo from 'assets/logo-name.svg';
import logoSmall from 'assets/logo-small.svg';
import useSession from 'hooks/useSession';
import { FC, useMemo, useState } from 'react';
import { LogoH1, LogoSmall, SidebarLogoDiv, StyledSider } from './Sidebar.styles';
import { sidebarMenuItems } from './SidebarMenuItems';

interface Props {
    collapsed: boolean;
}

const Sidebar: FC<Props> = ({ collapsed }) => {
    const { isAdminUser, isHaulierUser, isBrokerUser, isDriverUser } = useSession();
    const [activeKeys] = useState<string[]>();

    const menuItems = useMemo(
        () => sidebarMenuItems(isAdminUser, isDriverUser, isHaulierUser, isBrokerUser, activeKeys ?? []),
        [isAdminUser, isDriverUser, isHaulierUser, isBrokerUser, activeKeys]
    );

    return (
        <StyledSider collapsed={collapsed} width="265">
            <SidebarLogoDiv>
                {collapsed ? (
                    <LogoSmall>
                        <img src={logoSmall} alt="ddispatch" style={{ width: '24px', marginTop: 7 }} />
                    </LogoSmall>
                ) : (
                    <LogoH1>
                        <img src={logo} alt="ddispatch" style={{ width: '132px', marginTop: 7 }} />
                    </LogoH1>
                )}
            </SidebarLogoDiv>
            <Menu
                theme="dark"
                defaultSelectedKeys={['sidebar-home']}
                selectedKeys={activeKeys}
                mode="inline"
                items={menuItems}
            ></Menu>
        </StyledSider>
    );
};
export default Sidebar;
