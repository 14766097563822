import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import ValuationMethodBlock from '../../blocks/ValuationMethodBlock';

const ValuationMethodCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('valuationMethod')} hidden={props.hidden}>
            <FormCardContainer>
                <ValuationMethodBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ValuationMethodCard;
