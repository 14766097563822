import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import HideComponent from '../../../../../utils/HideComponent';
import useCodelists from '../../../../../hooks/useCodelists';

export type Fields = 'undgid';

export const unDangerousGoodsCodeBlockValidation: {
    childValidators: {
        'commodity.dangerousGoods': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.dangerousGoods': {
            childValidators: {
                undgid: [validators.number(), validators.maxLength(4)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const UnDangerousGoodsCodeBlock = ({ path = 'commodity.dangerousGoods', hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '6/12', []);
    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="undgid">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('dangerousGoodsCode')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.dangerousGoodsTypes}
                        condensed
                        codeValidation={[validators.number(), validators.maxLength(4)]}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default UnDangerousGoodsCodeBlock;
