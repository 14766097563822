import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { kebabCase } from 'lodash';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    aesDeclarationStatuses,
    baseDeclarationStatuses,
    DeclarationStatus,
} from 'store/declarations/enums/common/declaration-status';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from '../../../../../store/declarations/enums/common/declaration-internal-type';
import { FilterCheckbox, FilterGroup, FilterOption } from './Filter.styles';

interface DeclarationStatusFilterProps {
    value: CheckboxValueType[];
    selectedStatus: (status: string[]) => void;
}
export const DeclarationStatusFilter: FC<DeclarationStatusFilterProps> = ({ selectedStatus, value }) => {
    const { country, type: internalType } = useParams();

    const declarationStatuses = useMemo(() => {
        let statuses;

        if (country === DeclarationCountry.IRELAND && internalType === DeclarationInternalType.EXPORT)
            statuses = aesDeclarationStatuses;
        else statuses = baseDeclarationStatuses;

        return Object.keys(statuses).map((status) => status as DeclarationStatus);
    }, [country, internalType]);

    const onChange = (checkedValues: CheckboxValueType[]) => {
        selectedStatus(checkedValues as string[]);
    };

    return (
        <FilterGroup onChange={onChange} value={value || ''}>
            {declarationStatuses.map((s, i) => (
                <FilterOption key={`${s}-${i}`} data-testid={`${kebabCase(s)}-declaration-status-option`}>
                    <FilterCheckbox value={s} data-testid={`${kebabCase(s)}-declaration-status-checkbox`}>
                        <DeclarationStatusTag status={s} />
                    </FilterCheckbox>
                </FilterOption>
            ))}
        </FilterGroup>
    );
};

export default DeclarationStatusFilter;
