import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import CarrierCard from './cards/CarrierCard';
import ConsignmentCard from './cards/ConsignmentCard';
import MasterDetailsCard from './cards/MasterDetailsCard';

export interface MasterDetailsIrelandArrivalAtExitCards {
    cards: Card[];
}

const arrivalAtExitSkeletonDeclaration: MasterDetailsIrelandArrivalAtExitCards = {
    cards: [
        {
            key: 'arrival-at-exit-master-details-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'Master Details',
            valid: false,
            component: MasterDetailsCard,
            declarationType: DeclarationName.ARRIVAL,
            propsPathPrefix: '',
            getFields: () => [],
        },
        {
            key: 'arrival-at-exit-consignment-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 2,
            defaultOpen: true,
            title: 'Consignment',
            valid: false,
            component: ConsignmentCard,
            declarationType: DeclarationName.ARRIVAL,
            propsPathPrefix: 'goodsShipment.consignment',
            getFields: () => [],
        },
        {
            key: 'arrival-at-exit-carrier-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'carrier',
            valid: false,
            component: CarrierCard,
            declarationType: DeclarationName.ARRIVAL,
            propsPathPrefix: 'goodsShipment.consignment.carrier',
            getFields: () => [
                { name: `name`, required: false },
                { name: `streetAndNumber`, required: false },
                { name: `country`, required: false },
                { name: `postCode`, required: false },
                { name: `city`, required: false },
            ],
        },
    ],
};
export default arrivalAtExitSkeletonDeclaration;
