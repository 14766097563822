import styled, { css } from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { fonts } from 'theme';

export const StyledHelpView = styled.div`
    padding-left: 20rem;
    padding-right: 20rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const StyledBusinessRuleRow = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const BusinessRuleMessage = styled.div`
    cursor: pointer;
`;

export const BusinessRuleCode = styled.span`
    margin-left: 0.5rem;
    font-weight: ${fonts.weight.bold};
    padding-right: 0.3rem;
`;

export const BusinessRuleDescription = styled.p`
    padding-top: 0.3rem;
`;

export const BackButton = styled.div`
    cursor: pointer;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
`;

export const StyledDownOutlined = styled(DownOutlined).withConfig({
    shouldForwardProp: (prop) => !['expanded'].includes(prop),
})<{ expanded: boolean }>`
    transform: rotate(0deg);
    transition: transform 0.3s ease-out;

    ${(props) =>
        props.expanded &&
        css`
            transform: rotate(-180deg);
        `};
`;
