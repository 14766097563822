export enum DeclarationFooterType {
    MASTER_DETAILS = 'MASTER_DETAILS',
    PRODUCTS = 'PRODUCTS',
    SUMMARY = 'SUMMARY',
}

export interface DeclarationFooterState {
    type?: DeclarationFooterType;
    collapsed?: boolean;
}
