import Button from 'components/ui/base/button/Button';
import styled from 'styled-components';
import { fonts } from 'theme';

export const StyledMobileWeighingView = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledWeightImage = styled.img`
    width: 100%;
    max-width: 800px;
`;

export const StyledWeighingDetails = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const StyledLabel = styled.div`
    font-weight: ${fonts.weight.bold};
    font-size: 1.8rem;
    margin: 1rem;
`;

export const StyledValue = styled.div`
    font-size: 1.6rem;
`;

export const WeighingFooterActions = styled.div`
    display: flex;
    gap: 1rem;
`;

export const SyledWeighingActionButton = styled(Button)`
    width: 100%;
`;
