import { useCallback, useState } from 'react';
import {
    requestWeighing as requestWeighingClient,
    getWeighing as getWeighingClient,
    editWeighing as editWeighingClient,
} from 'store/weighbridges/client';
import { EditWeighingRequest, WeighingRequest, WeighingResponse } from 'store/weighbridges/weighing';

const useWeighings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [isRequestingWeighing, setIsRequestingWeighing] = useState(false);
    const [errorRequestingWeighing, setErrorRequestingWeighing] = useState(false);
    const [isEditingWeighing, setIsEditingWeighing] = useState(false);
    const [errorEditingWeighing, setErrorEditingWeighing] = useState(false);
    const [weighing, setWeighing] = useState<WeighingResponse | null>();

    const getWeighing = useCallback(async (weighingId: string) => {
        try {
            setIsLoading(true);
            setErrorLoading(false);

            const weighingResponse = await getWeighingClient(weighingId);
            setWeighing(weighingResponse);
        } catch {
            setErrorLoading(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const requestWeighing = useCallback(async (deviceId: string, weighingRequest: WeighingRequest) => {
        try {
            setIsRequestingWeighing(true);
            setErrorRequestingWeighing(false);

            const weighingResponse = await requestWeighingClient(deviceId, weighingRequest);
            setWeighing(weighingResponse);
        } catch {
            setErrorRequestingWeighing(true);
            setWeighing(null);
        } finally {
            setIsRequestingWeighing(false);
        }
    }, []);

    const editWeighing = useCallback(async (weighingId: string, weighing: Partial<EditWeighingRequest>) => {
        try {
            setIsEditingWeighing(true);
            setErrorEditingWeighing(false);

            await editWeighingClient(weighingId, weighing);
        } catch {
            setErrorEditingWeighing(true);
        } finally {
            setIsEditingWeighing(false);
        }
    }, []);

    return {
        isLoading,
        errorLoading,
        isRequestingWeighing,
        errorRequestingWeighing,
        requestWeighing,
        getWeighing,
        editWeighing,
        weighing,
        isEditingWeighing,
        errorEditingWeighing,
    };
};
export default useWeighings;
