import { useState } from 'react';

const useModalDeclarationSubmit = () => {
    const [modalPreviewSadAndClearanceSlip, setModalPreviewSadAndClearanceSlip] = useState(false);
    const [digitalCertificateModal, setDigitalCertificateModal] = useState(false);

    const onCloseModalPreviewSadAndClearanceSlip = () => {
        setModalPreviewSadAndClearanceSlip(false);
    };

    const onCloseDigitalCertificateModal = () => {
        setDigitalCertificateModal(false);
    };

    const displayModalSubmit = async (
        hasCertificate: boolean,
        getDeclarationProductTaxes: Function,
        validateNumberOfItems?: Function,
        validateForm?: Function,
        getTaxes?: boolean
    ) => {
        if (hasCertificate) {
            if (getTaxes) {
                getDeclarationProductTaxes();
            }
            setModalPreviewSadAndClearanceSlip(true);
        } else {
            setDigitalCertificateModal(true);
        }
    };

    return {
        modalPreviewSadAndClearanceSlip,
        digitalCertificateModal,
        displayModalSubmit,
        onCloseModalPreviewSadAndClearanceSlip,
        onCloseDigitalCertificateModal,
    };
};
export default useModalDeclarationSubmit;
