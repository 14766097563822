import { getIrelandCodeLists, getUkCodeLists } from './client';
import { Codelists, CodelistsPayload, IrelandCodelistTypes, UkCodelistTypes } from './code';
import { camelCase } from 'lodash';

const AES_CODE_SUBSTRING = /cl\d{3}(Cl|NCl)/i;

const transformCodelistsForClient = (response: CodelistsPayload[]): IrelandCodelistTypes & UkCodelistTypes => {
    const codelistsObject: any = {};

    for (let i = 0; i < response.length; i++) {
        const codelistType = camelCase(response[i].type) as keyof IrelandCodelistTypes & UkCodelistTypes;
        let codelistName = camelCase(response[i].name) as keyof Codelists;

        if (AES_CODE_SUBSTRING.test(codelistName)) {
            const withRemovedSubstring = codelistName.replace(AES_CODE_SUBSTRING, '');
            codelistName = (withRemovedSubstring[0].toLowerCase() + withRemovedSubstring.slice(1)) as keyof Codelists;
        }

        if (codelistType && !codelistsObject[codelistType]) {
            codelistsObject[codelistType] = {};
        }

        if (codelistName && !codelistsObject[codelistType][codelistName]) {
            codelistsObject[codelistType][codelistName] = [];
        }

        codelistsObject[codelistType][codelistName].push({
            id: response[i].code,
            value: response[i].description,
            type: response[i].type,
            name: codelistName,
            idServer: response[i].id,
            creatorName: response[i].creatorName,
            ...JSON.parse(response[i].data),
        });
    }

    return codelistsObject;
};

export const doGetIrelandCodelists: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_IRELAND_CODELISTS_REQUEST' });

    try {
        const response = transformCodelistsForClient(await getIrelandCodeLists());

        dispatch({ type: 'GET_IRELAND_CODELISTS_SUCCESS', payload: response });
    } catch (e: any) {
        dispatch({ type: 'GET_IRELAND_CODELISTS_ERROR', payload: {} });
    }
};

export const doGetUkCodelists: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_UK_CODELISTS_REQUEST' });

    try {
        const response = transformCodelistsForClient(await getUkCodeLists());

        dispatch({ type: 'GET_UK_CODELISTS_SUCCESS', payload: response });
    } catch (e: any) {
        dispatch({ type: 'GET_UK_CODELISTS_ERROR', payload: {} });
    }
};
