import Button from 'components/ui/base/button';
import { BaseButtonType } from 'components/ui/base/button/Button';
import styled, { StyledComponent } from 'styled-components';
import { colors } from 'theme';

interface WCOUsersComponents {
    DraggerContainer: StyledComponent<'div', any>;
    DeleteButton: StyledComponent<BaseButtonType, any>;
}

const WCOUsersStyledComponents: WCOUsersComponents = {
    DraggerContainer: styled.div`
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        padding: 2rem;
    `,
    DeleteButton: styled(Button)`
        background-color: ${colors.red};
        color: ${colors.white};
        border: 1px solid ${colors.red};
        margin-left: auto;

        :hover {
            color: ${colors.red};
            border: 1px solid ${colors.red};
            background-color: transparent;
        }
    `,
};

export default WCOUsersStyledComponents;
