import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import {
    IrelandExportMessageType,
    irelandExportMessageTypeLabels,
} from 'store/declarations/enums/ireland/message-type';
import {
    IrelandDeclarationName,
    irelandDeclarationNameLabels,
} from 'store/declarations/ireland/ireland-declaration-name';
import { CdsExportMessageType } from 'store/declarations/uk/export-declaration';
import {
    UkImportDeclarationName,
    ukDeclarationNameLabels,
    ukExportDeclarationNameLabels,
} from 'store/declarations/uk/uk-declaration-name';
export const getDeclarationNameLabels = (country: DeclarationCountry, type: DeclarationInternalType) => {
    if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return [...ukDeclarationNameLabels];
        } else {
            return [...ukExportDeclarationNameLabels];
        }
    } else {
        if (type === DeclarationInternalType.IMPORT) {
            return [...irelandDeclarationNameLabels];
        } else if (type === DeclarationInternalType.EXPORT) {
            return [...irelandExportMessageTypeLabels];
        } else {
            return [];
        }
    }
};

export const getMessageType = (country: DeclarationCountry, type: DeclarationInternalType, value: string) => {
    if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return ukDeclarationNameLabels.find((e) => e.value === value)?.key;
        } else {
            return ukExportDeclarationNameLabels.find((e) => e.value === value)?.key;
        }
    } else {
        if (type === DeclarationInternalType.IMPORT) {
            return irelandDeclarationNameLabels.find((e) => e.value === value)?.key;
        } else {
            return irelandExportMessageTypeLabels.find((e) => e.value === value)?.key;
        }
    }
};

export const createOrDuplicateDeclarations = async (
    updateDeclaration: Function,
    createDeclaration: Function,
    jobId: string,
    setMyDeclaration: Function,
    country: string,
    customerId?: string,
    duplicateDeclarationId?: string,
    messageType?: UkImportDeclarationName | IrelandDeclarationName | IrelandExportMessageType | CdsExportMessageType
) => {
    if (!customerId) return;

    if (duplicateDeclarationId) {
        const response = (await updateDeclaration(duplicateDeclarationId, customerId, jobId)) as Declaration;
        if (response) {
            setMyDeclaration(response);
            return response;
        }
    } else {
        let response = {};
        if (messageType) {
            response = (await createDeclaration(country, customerId, jobId, messageType)) as Declaration;
        } else {
            response = (await createDeclaration(country, customerId, jobId)) as Declaration;
        }
        if (response) {
            setMyDeclaration(response);
            return response;
        }
    }
};

export const createDeclarations = async (
    country: DeclarationCountry,
    customerId: string,
    jobId: string,
    type: DeclarationInternalType,
    createIrelandH7ImportDeclaration: Function,
    createIrelandImportDeclaration: Function,
    createEntrySummaryDeclaration: Function,
    createTemporaryStorageDeclaration: Function,
    createIrelandExportDeclaration: Function,
    createElectronicTransportDocument: Function,
    createUkExportDeclaration: Function,
    createUkImportNewDeclaration: Function,
    createArrivalAtExitDeclaration: Function,
    messageType?: IrelandDeclarationName | IrelandExportMessageType | UkImportDeclarationName | CdsExportMessageType,
    template?: TemplateResponse
) => {
    if (country === DeclarationCountry.IRELAND) {
        if (type === DeclarationInternalType.IMPORT) {
            if ((messageType as IrelandDeclarationName) === IrelandDeclarationName.H7) {
                return await createIrelandH7ImportDeclaration(
                    customerId,
                    template?.template?.master.defaults,
                    jobId,
                    template?.id
                );
            } else {
                return await createIrelandImportDeclaration(
                    customerId,
                    { ...template?.template?.master.defaults, messageType: messageType as IrelandDeclarationName },
                    jobId,
                    template?.id
                );
            }
        } else if (type === DeclarationInternalType.ENS) {
            return await createEntrySummaryDeclaration(
                customerId,
                template?.template?.master.defaults,
                jobId,
                template?.id
            );
        } else if (type === DeclarationInternalType.ETD) {
            return await createElectronicTransportDocument(customerId, undefined, jobId);
        } else if (type === DeclarationInternalType.TEMPORARY) {
            return await createTemporaryStorageDeclaration(customerId, undefined, jobId);
        } else if (type === DeclarationInternalType.ARRIVAL) {
            return await createArrivalAtExitDeclaration(customerId, undefined, jobId);
        } else if (type === DeclarationInternalType.EXPORT) {
            return await createIrelandExportDeclaration(
                customerId,
                { ...template?.template?.master.defaults, messageType: messageType as IrelandExportMessageType },
                jobId,
                template?.id
            );
        }
    } else if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return await createUkImportNewDeclaration(customerId, { messageType }, jobId);
        } else {
            return await createUkExportDeclaration(
                customerId,
                { cdsExportDeclarationPayload: template?.template?.master.defaults, messageType },
                jobId,
                template?.id,
                template?.template
            );
        }
    }
};
