import { ProductTemplateFormErrors } from './ProductTemplateFormErrors';

export const doSetProductTemplateFormErrors: Function = (errors: ProductTemplateFormErrors) => (dispatch: Function) => {
    dispatch({
        type: 'SET_PRODUCT_TEMPLATE_FORM_ERRORS',
        errors,
    });
};

export const doClearProductTemplateFormErrors: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_PRODUCT_TEMPLATE_FORM_ERRORS' });
};
