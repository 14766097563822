import styled from 'styled-components';

interface ListTagLayoutProps {
    gap?: string | number;
    vertical?: boolean;
}

export const ListTagLayout = styled.div<ListTagLayoutProps>`
    display: grid;
    grid-template-columns: ${({ vertical }) => (vertical ? 'repeat(2, min-content)' : 'unset')};
    grid-auto-flow: ${({ vertical }) => (vertical ? 'row dense' : 'column dense')};
    gap: ${({ gap }) => gap || 0};
`;
