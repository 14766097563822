import { message } from 'antd';
import { curry, set } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SModal } from '../../../../../../components/ui/composed/template/TemplateModal';
import { ParsedForm } from '../../../../../../store/declaration-form-errors/ParsedForm';
import InvoiceBody from './InvoiceBody';
import InvoiceHeader from './InvoiceHeader';
import axiosClient from 'config/axios';
import { SuccessResponse } from '../../../../../../core/http/response';
import config from '../../../../../../config';
import { IrelandImportDeclaration } from '../../../../../../store/declarations/ireland/import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import useGlobalOverlay from '../../../../../../hooks/useGlobalOverlay';
import useGetDeclarationViewMapValues from '../../../../../../hooks/useGetDeclarationViewMapValues';
import {
    removeEmptyObjects,
    removeEmptyObjectsFromDeclarationArrays,
    trimWhiteSpaces,
} from '../../../../utils/form-utils';
import useProducts, { CreateProductFunction, UpdateProductFunction } from '../../../../../../hooks/useProducts';
import { parseInvoiceResponse } from './parseInvoiceResponse';
import IrelandImportDeclarationUtils, { makeTransformation } from '../../utils';
import { h1Box44KeyNames, h1PathBox44 } from '../../../../common/box44/box-44-utils';
import { SUGGESTED_TARICS_NUMERATION_REGEX } from './invoice-utils';
import { ieH1PartiesData } from '../h1-skeleton-declaration';

const REFRESH_DELAY_TIME = 10000;

export enum TaricEvalStatus {
    BASIC_INFO_RECEIVED = 'BASIC_INFO_RECEIVED',
    TARIC_SUGGESTIONS_RECEIVED = 'TARIC_SUGGESTIONS_RECEIVED',
    TEE_UPDATED_WITH_SUBMITTED_DATA = 'TEE_UPDATED_WITH_SUBMITTED_DATA',
    FAILED = 'FAILED',
}

interface Props {
    formikValues?: IrelandImportDeclaration;
    declarationId?: string;
    saveAsDraft?: Function;
}

type TaricEvaluationResponse = { irelandImportDeclaration: IrelandImportDeclaration; taricEvalStatus: string };

export type CustomerLevelValidationData = {
    eoriLevelPaths: Record<string, any>;
    addressLevelPaths: Record<string, any>;
    allCustomerValues: Record<string, any>;
    customerHeader: string;
    customerPath: string;
    disabled: boolean;
};

const EORI_LEVEL_FIELD_PATHS = ['eori', 'identificationNumber', 'id', 'statusCode', 'functionCode'];

export type LMap = Map<string, string | number | boolean | string[] | undefined>;

const InvoiceModal = ({ formikValues, declarationId, saveAsDraft }: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [activeForm, setActiveForm] = useState<'master' | 'product'>('master');
    const [invoiceResponse, setInvoiceResponse] = useState<TaricEvaluationResponse | undefined>(undefined);
    const [parsedInvoiceResponse, setParsedInvoiceResponse] = useState<ParsedForm | undefined>(undefined);
    const [selectedSuggestedValuesMap, setSelectedSuggestedValuesMap] = useState<LMap>(new Map());
    const [selectedCustomers, setSelectedCustomers] = useState<Record<string, string[]>>({});
    const [selectedProductsActions, setSelectedProductsActions] = useState<Record<string, string | undefined>>({});

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { ...products } = useProducts();
    const { parseForm, updateProductFuncName, createProductFuncName, transformData } = useGetDeclarationViewMapValues();

    const handleMirroredProductsSubmit = useCallback(
        async (item: H1GoodsShipmentItem) => {
            let form = removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces({ ...item }, { emptyStringToNull: true })
            );

            if (!(declarationId && updateProductFuncName && createProductFuncName)) return;

            if (transformData?.product?.forServer) {
                form = transformData.product.forServer(form);
            }

            if (item.id)
                await (products[updateProductFuncName] as UpdateProductFunction)(
                    removeEmptyObjects(form),
                    declarationId,
                    item.id
                );
            else await (products[createProductFuncName] as CreateProductFunction)(form, declarationId);
        },
        [declarationId, createProductFuncName, updateProductFuncName, products, transformData]
    );

    const getInvoiceData = useCallback(() => {
        if (!declarationId) return;

        axiosClient
            .get<SuccessResponse<TaricEvaluationResponse>>(
                `${config.declarationsUrl}/declarations/${declarationId}/teeInvoiceData`
            )
            .then((response) => {
                setInvoiceResponse({
                    taricEvalStatus: response.data.payload.taricEvalStatus,
                    irelandImportDeclaration: makeTransformation(
                        response.data.payload.irelandImportDeclaration,
                        curry(IrelandImportDeclarationUtils.box44Transform)(
                            IrelandImportDeclarationUtils.box44ToObject,
                            h1PathBox44,
                            h1Box44KeyNames
                        )
                    ),
                });
            })
            .catch(() => {
                message.error(
                    'Error generating the invoice! Please create a new declaration and attach an invoice to it.',
                    5
                );
            });
    }, [declarationId]);

    useEffect(() => {
        if (
            invoiceResponse?.taricEvalStatus === TaricEvalStatus.TARIC_SUGGESTIONS_RECEIVED ||
            invoiceResponse?.taricEvalStatus === TaricEvalStatus.TEE_UPDATED_WITH_SUBMITTED_DATA ||
            invoiceResponse?.taricEvalStatus === TaricEvalStatus.FAILED
        )
            return;

        const interval = setInterval(() => {
            getInvoiceData();
        }, REFRESH_DELAY_TIME);

        return () => clearInterval(interval);
    }, [getInvoiceData, invoiceResponse?.taricEvalStatus]);

    useEffect(() => {
        if (!invoiceResponse?.irelandImportDeclaration) return;

        const defaultParse = parseForm?.(invoiceResponse?.irelandImportDeclaration as any, {
            withoutCustomers: true,
        }) as ParsedForm;

        const invoiceParse = parseInvoiceResponse(defaultParse, invoiceResponse?.irelandImportDeclaration as any);

        setParsedInvoiceResponse(invoiceParse);
        window.EventBus.publish('handleInvoiceModal', () => setModalOpen(true));
    }, [invoiceResponse?.irelandImportDeclaration, parseForm]);

    useEffect(() => {
        if (
            invoiceResponse?.taricEvalStatus === TaricEvalStatus.FAILED &&
            invoiceResponse?.irelandImportDeclaration === undefined
        )
            window.EventBus.publish('invoiceFailedInitially');
    }, [invoiceResponse]);

    const handleCloseModal = useCallback(() => setModalOpen(false), [setModalOpen]);

    const handleChangeForm = useCallback((form: 'master' | 'product') => setActiveForm(form), []);

    const getTaricEvalLineItemId = useCallback(
        (productPath: `product${number}`) => {
            const indexOfInvoiceProduct = +productPath.slice(7);
            const taricEvalLineItemId =
                invoiceResponse?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem?.[indexOfInvoiceProduct]
                    ?.taricEvalLineItemId;

            return taricEvalLineItemId;
        },
        [invoiceResponse?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem]
    );

    const setInvoiceProductsInFormikStructure = useCallback(
        (suggestedValuesFormikStructure: any) => {
            let indexOfProductForCreation = 0;
            let pathOfProduct: string;
            let bodyOfProduct: Record<string, string> = {};
            Object.entries(selectedProductsActions).forEach(([productPath, productAction]) => {
                const productActionIsNotSelected = !(productPath in selectedProductsActions);
                const productActionIsUndefined = selectedProductsActions[productPath] === undefined;

                if (productActionIsNotSelected || productActionIsUndefined) return;

                if (productAction === '') {
                    const declarationProductsLength = formikValues?.goodsShipment?.goodsShipmentItem?.length;

                    const indexToCreateProductOn = declarationProductsLength
                        ? declarationProductsLength + indexOfProductForCreation
                        : indexOfProductForCreation;

                    pathOfProduct = `goodsShipment.goodsShipmentItem.[${indexToCreateProductOn}]`;

                    indexOfProductForCreation++;
                } else if (productAction !== '' && productAction) {
                    const productActionValues = productAction.split(' ');
                    pathOfProduct = productActionValues[0];
                    const idOfProductForUpdate = productActionValues[1];

                    bodyOfProduct = {
                        id: idOfProductForUpdate,
                    };
                }

                bodyOfProduct = {
                    ...bodyOfProduct,
                    ...suggestedValuesFormikStructure[productPath],
                    taricEvalLineItemId: getTaricEvalLineItemId(productPath as `product${number}`),
                };
                set(suggestedValuesFormikStructure, pathOfProduct, bodyOfProduct);
                delete suggestedValuesFormikStructure[productPath];
            });
        },
        [formikValues?.goodsShipment?.goodsShipmentItem?.length, selectedProductsActions, getTaricEvalLineItemId]
    );

    const setCustomerInFormikStructureAndGetLevelValidation = useCallback(
        (suggestedValuesFormikStructure: any): CustomerLevelValidationData[] => {
            const customersLevelValidationData: CustomerLevelValidationData[] = [];

            Object.entries(selectedCustomers).forEach(([invoiceCustomer, selectedCustomerPaths]) => {
                const selectedCustomerValues = suggestedValuesFormikStructure[invoiceCustomer];

                let customerLevelValidationData = {
                    eoriLevelPaths: {},
                    addressLevelPaths: {},
                    allCustomerValues: selectedCustomerValues,
                } as CustomerLevelValidationData;

                Object.keys(selectedCustomerValues).forEach((fieldPath, i) => {
                    if (EORI_LEVEL_FIELD_PATHS.includes(fieldPath))
                        customerLevelValidationData.eoriLevelPaths[i] = fieldPath;
                    else customerLevelValidationData.addressLevelPaths[i] = fieldPath;
                });

                selectedCustomerPaths.forEach((customerPath: string) => {
                    customerLevelValidationData = { ...customerLevelValidationData };
                    customerLevelValidationData.customerPath = customerPath;
                    customerLevelValidationData.customerHeader =
                        ieH1PartiesData.find((partyData) => partyData.path === customerPath)?.header ?? 'Customer';
                    customersLevelValidationData.push(customerLevelValidationData);

                    set(suggestedValuesFormikStructure, customerPath, selectedCustomerValues);
                });
            });

            Object.keys(selectedCustomers).forEach((key) => delete suggestedValuesFormikStructure[key]);

            return customersLevelValidationData;
        },
        [selectedCustomers]
    );

    const handleSave = useCallback(async () => {
        if (!selectedSuggestedValuesMap || selectedSuggestedValuesMap.size === 0)
            return message.error('Please select suggested values in order to save.');

        let suggestedValuesFormikStructure: any = {};
        for (let [key, value] of selectedSuggestedValuesMap) {
            if (key.includes('combinedNomenclatureCode') || key.includes('taricCode'))
                key = key.replace(SUGGESTED_TARICS_NUMERATION_REGEX, '');
            else if (key.includes('containerIdentificationNumber'))
                value = (value as string[])?.map((containerIdentificationNumber) =>
                    containerIdentificationNumber.slice(0, 16)
                );

            suggestedValuesFormikStructure = set(suggestedValuesFormikStructure, key, value);
        }

        setInvoiceProductsInFormikStructure(suggestedValuesFormikStructure);
        const customerLevelValidation =
            setCustomerInFormikStructureAndGetLevelValidation(suggestedValuesFormikStructure);

        const productPromises: Promise<unknown>[] = [];
        suggestedValuesFormikStructure?.goodsShipment?.goodsShipmentItem?.forEach((item: H1GoodsShipmentItem) =>
            productPromises.push(handleMirroredProductsSubmit(item))
        );

        try {
            showGlobalOverlay({
                type: 'LoadingOverlay',
            });

            await Promise.all(productPromises);
            await saveAsDraft?.(true, suggestedValuesFormikStructure);
            window.EventBus.publish('invoiceModalSaved', {
                customerLevelValidation,
            });
        } finally {
            hideGlobalOverlay();
        }

        handleCloseModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSuggestedValuesMap,
        selectedCustomers,
        handleMirroredProductsSubmit,
        setInvoiceProductsInFormikStructure,
    ]);

    const itemDetailsDisabled = useMemo(
        () => invoiceResponse?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem?.length === 0,
        [invoiceResponse?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem]
    );

    return (
        <SModal
            visible={modalOpen}
            onCancel={handleCloseModal}
            width={'100rem'}
            footer={false}
            closable={false}
            destroyOnClose
            maskClosable={false}
        >
            <InvoiceHeader
                handleClose={handleCloseModal}
                handleChangeForm={handleChangeForm}
                activeForm={activeForm}
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    backgroundColor: 'white',
                    paddingTop: '1rem',
                }}
                onSave={handleSave}
                itemDetailsDisabled={itemDetailsDisabled}
            />
            <InvoiceBody
                activeForm={activeForm}
                parsedInvoiceResponse={parsedInvoiceResponse}
                formikValues={formikValues}
                selectedSuggestedValuesMap={selectedSuggestedValuesMap}
                setSelectedSuggestedValues={setSelectedSuggestedValuesMap}
                setSelectedCustomers={setSelectedCustomers}
                selectedCustomers={selectedCustomers}
                setSelectedProductAction={setSelectedProductsActions}
                selectedProductsActions={selectedProductsActions}
                failedToGetSuggestedTarics={invoiceResponse?.taricEvalStatus === TaricEvalStatus.FAILED}
            />
        </SModal>
    );
};

export default InvoiceModal;
