import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormCheckbox from 'components/ui/composed/formCheckbox/FormCheckbox';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
const RemissionMasterDetailsCard: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    return (
        <section
            id={'master-details-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('master-details-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('masterDetails')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`applicationReference`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.applicationReference')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`applicationDecisionCode`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.applicationDecisionCode')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`signature`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.signature')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`totalNumberOfDocuments`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.totalNumberOfDocuments')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`applicant`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.applicant')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`representativeIdentification`, {
                        getFieldHelpers,
                        getFieldProps,
                        getFieldMeta,
                    })}
                    label={t('remissionRefund.representativeIdentification')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`contactName`, {
                        getFieldHelpers,
                        getFieldProps,
                        getFieldMeta,
                    })}
                    label={t('remissionRefund.contactName')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`contactPhone`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.contactPhone')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`contactFax`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.contactFax')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`contactEmail`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.contactEmail')}
                />
                <DeclarationDatepicker
                    viewOnly={viewOnly}
                    {...getFormikProps(`date`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.date')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`officeOfNotifiedDebt`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.officeOfNotifiedDebt')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`officeOfResponsibility`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.officeOfResponsibility')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`mrn`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.mrn')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`legalBasisCode`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.legalBasisCode')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`descriptionOfGrounds`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.descriptionOfGrounds')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`bankDetails`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.bankDetails')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`procedureCode`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.procedureCode')}
                />
                <FormCheckbox
                    viewOnly={viewOnly}
                    {...getFormikProps(`tickBox`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.tickBox')}
                />

                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`dutyToRepayCurrency`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.dutyToRepayCurrency')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`dutyToRepayAmount`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.dutyToRepayAmount')}
                />
                <FormInput
                    viewOnly={viewOnly}
                    {...getFormikProps(`additionalInformation`, { getFieldHelpers, getFieldProps, getFieldMeta })}
                    label={t('remissionRefund.additionalInformation')}
                />
            </FormCard>
        </section>
    );
};
export default RemissionMasterDetailsCard;
