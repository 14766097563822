import { PaginatedParams } from "core/http/pagination";

export enum CargoStatus{
    NEW = "NEW",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED"
}

export enum CargoType{
    RORO = 'RORO', 
    BREAK_BULK = 'BREAK_BULK', 
    DRY_BULK = 'DRY_BULK', 
    LIQUID_BULK = 'LIQUID_BULK', 
    CONTAINER_CARGO = 'CONTAINER_CARGO'
}

export enum WeightUnit{
    MILLIGRAM = 'MILLIGRAM', 
    GRAM = 'GRAM',
    KILOGRAM = 'KILOGRAM', 
    TON = 'TON', 
    US_TON = 'US_TON',
    UK_TON = 'UK_TON', 
    POUND = 'POUND', 
    OUNCE = 'OUNCE'
}

export enum DimensionsUnit{
    CENTIMETER = 'CENTIMETER', 
    METER = 'METER', 
    KILOMETER = 'KILOMETER', 
    SQUARED_CENTIMETER = 'SQUARED_CENTIMETER', 
    SQUARED_METER = 'SQUARED_METER', 
    SQUARED_KILOMETER = 'SQUARED_KILOMETER', 
    CUBIC_CENTIMETER = 'CUBIC_CENTIMETER', 
    CUBIC_METER = 'CUBIC_METER', 
    CUBIC_KILOMETER = 'CUBIC_KILOMETER'
}

export interface Cargo{  
    id: string;
    referenceNumber: string,
    status: CargoStatus,
    type: CargoType,
    description: string,
    weight: number,
    weightUnit: WeightUnit,
    height: number,
    length: number;
    width: number;
    dimensionsUnit: DimensionsUnit,
    constraints: string,
    value: number,
    currency: string
}

export enum CargoSortParameter {
    REFERENCE_NUMBER = 'REFERENCE_NUMBER',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
    WEIGHT = 'WEIGHT',
    WEIGHT_UNIT = 'WEIGHT_UNIT',
    DIMENSIONS_UNIT = 'DIMENSIONS_UNIT',
    DIMENSIONS = 'DIMENSIONS',
    VALUE = 'VALUE'
}

export interface CargoParams extends PaginatedParams{
    customerId?: string;
    referenceNumber?: string;
    status?: CargoStatus;
    type?: CargoType;
    weightUnit?: WeightUnit;
    dimensionsUnit?: DimensionsUnit;
    createdBy?: string;
    queryBy?:string[]
}