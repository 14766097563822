import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { MapStyledComponents, markerElement } from './Map.styles';
import useMap from 'hooks/useMap';

const { MapWrapper, MapContainer } = MapStyledComponents;

export type Coordinate = [number, number];

const Map = () => {
    const { map, mapContainer } = useMap();

    useEffect(() => {
        if (!map) return;

        const marker = new mapboxgl.Marker({ element: markerElement });
        marker.setLngLat([-6.272250963000388, 53.348783340728005]);
        marker.addTo(map);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    return (
        <MapWrapper>
            <MapContainer className="Map" ref={mapContainer} />
        </MapWrapper>
    );
};
export default Map;
