import { FormModel, Validator, validators } from 'views/declarations/uk/export/validations/validations';
import DeepPartial from '../../../../../types/DeepPartial';

type PartyValidator = {
    childValidators: {
        identificationNumber: Validator[];
        status?: Validator[];
        name: Validator[];
        address: {
            childValidators: {
                streetAndNumber: Validator[];
                postcode: Validator[];
                city: Validator[];
                country: Validator[];
            };
        };
        contactPerson?: {
            childValidators: {
                name: Validator[];
                phoneNumber: Validator[];
                emailAddress: Validator[];
            };
        };
    };
};
export const getPartyValidator = (options?: {
    eoriRequired?: boolean;
    contactPerson?: { present?: boolean; required?: boolean };
    status?: { present?: boolean; required?: boolean };
}) => {
    const validator: PartyValidator = {
        childValidators: {
            identificationNumber: [validators.eori(true)], // Identification Number
            name: [validators.maxLength(70)], // Name
            address: {
                // Address
                childValidators: {
                    streetAndNumber: [validators.maxLength(70)], // Street and Number
                    postcode: [validators.maxLength(17)], // Postcode
                    city: [validators.maxLength(35)], // City
                    country: [validators.exact(2)], // Country
                },
            },
        },
    };

    if (options?.eoriRequired) {
        validator.childValidators.identificationNumber.unshift(validators.required());
    }

    if (options?.contactPerson?.present) {
        const contactPerson = {
            // Contact Person
            childValidators: {
                name: [validators.maxLength(70)], // Name
                phoneNumber: [validators.maxLength(35)], // Phone Number
                emailAddress: [validators.email()], // E-mail Address
            },
        };

        if (options.contactPerson.required) {
            contactPerson.childValidators.name.unshift(validators.required());
            contactPerson.childValidators.phoneNumber.unshift(validators.required());
        }

        validator.childValidators.contactPerson = contactPerson;
    }

    if (options?.status) {
        validator.childValidators.status = [validators.number(), validators.exact(1)];
        if (options?.status.required) {
            validator.childValidators.status.unshift(validators.required());
        }
    }

    return validator;
};

export const irelandExportB1ValidationItem = {
    childValidators: {
        // Goods Item {M}
        statisticalValue: [validators.float(16, 2)], // Statistical Value
        natureOfTransaction: [validators.number(), validators.maxLength(2)], // Nature of Transaction
        countryOfExport: [validators.exact(2)], // Country of Export
        countryOfDestination: [validators.exact(2)], // Country of Export
        referenceNumberUCR: [validators.maxLength(35)], // Reference Number UCR
        authorisation: {
            // Authorisation {M}
            childValidators: {
                type: [validators.required(), validators.maxLength(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(35)], // Reference Number
                holderOfAuthorisation: [validators.required(), validators.eori(true)], // Holder of Authorisation
            },
            stopAtNull: true,
        },
        procedure: {
            // Procedure (R)
            childValidators: {
                requestedProcedure: [validators.required(), validators.number(), validators.exact(2)], // Requested Procedure
                previousProcedure: [validators.required(), validators.number(), validators.exact(2)], // Previous Procedure
                additionalProcedure: {
                    // Additional Procedure {M}
                    childValidators: {
                        additionalProcedure: [validators.exact(3)], // Additional Procedure
                    },
                    stopAtNull: true,
                },
            },
        },
        consignor: getPartyValidator(), // Consignor
        consignee: getPartyValidator(), // Consignee
        additionalSupplyChainActor: {
            // Additional Supply Chain Actor {M}
            childValidators: {
                role: [validators.required(), validators.maxLength(3)], // Role
                identificationNumber: [validators.required(), validators.eori(true)], // Identification Number
            },
            stopAtNull: true,
        },
        origin: {
            // Origin
            childValidators: {
                countryOfOrigin: [validators.exact(2)], // Country of Origin
                regionOfDispatch: [validators.maxLength(9)], // Region of Dispatch
            },
        },
        commodity: {
            // Commodity (R)
            childValidators: {
                descriptionOfGoods: [validators.required(), validators.maxLength(512)], // Description of Goods
                cusCode: [validators.exact(9)], // CUS Code
                commodityCode: {
                    // Commodity Code (R)
                    childValidators: {
                        harmonizedSystemSubHeadingCode: [validators.required(), validators.exact(6)], // Harmonized System Sub-Heading Code
                        combinedNomenclatureCode: [validators.required(), validators.exact(2)], // Combined Nomenclature Code
                        taricAdditionalCode: {
                            // TARIC Additional Code {M}
                            childValidators: {
                                taricAdditionalCode: [validators.exact(4)], // TARIC Additional Code
                            },
                            stopAtNull: true,
                        },
                        nationalAdditionalCode: {
                            // National Additional Code {M}
                            childValidators: {
                                nationalAdditionalCode: [validators.maxLength(4)], // National Additional Code
                            },
                            stopAtNull: true,
                        },
                    },
                }, // Commodity Code
                dangerousGoods: {
                    // Dangerous Goods {M}
                    childValidators: {
                        unNumber: [validators.exact(4)], // UN Number
                    },
                    stopAtNull: true,
                },
                goodsMeasure: {
                    // Goods Measure (R)
                    childValidators: {
                        grossMass: [validators.float(16, 6)], // Gross Mass
                        netMass: [validators.required(), validators.float(16, 6)], // Net Mass
                        supplementaryUnits: [validators.float(16, 6)], // Supplementary Units
                    },
                },
                calculationOfTaxes: {
                    // Calculation of Taxes
                    childValidators: {
                        totalDutiesAndTaxesAmount: [validators.float(16, 2)], // Total Duties and Taxes Amount
                        dutiesAndTaxes: {
                            // Duties and Taxes (R) {M}
                            childValidators: {
                                taxType: [validators.required(), validators.exact(3)], // Tax Type
                                payableTaxAmount: [validators.required(), validators.float(16, 2)], // Payable Tax
                                methodOfPayment: [validators.required(), validators.exact(1)], // Method of Payment
                                taxBase: {
                                    // Tax Base (R) {M}
                                    childValidators: {
                                        taxRate: [validators.required(), validators.float(17, 3)], // Tax Rate
                                        measurementUnitAndQualifier: [validators.maxLength(4)], // Measurement Unit and Qualifier
                                        quantity: [validators.float(16, 6)], // Quantity
                                        amount: [validators.float(16, 2)], // Amount
                                        taxAmount: [validators.required(), validators.float(16, 2)], // Tax Amount
                                    },
                                    selfValidators: [validators.minLength(1)],
                                    stopAtNull: true,
                                },
                            },
                            selfValidators: [validators.minLength(1)],
                            stopAtNull: true,
                        }, // Duties and Taxes
                    },
                }, // Calculation of Taxes
            },
        }, // Commodity
        packaging: {
            // Packaging (R) {M}
            childValidators: {
                typeOfPackages: [validators.required(), validators.maxLength(5)], // Type of Packages
                numberOfPackages: [validators.number(), validators.maxLength(8)], // Number of Packages
                shippingMarks: [validators.maxLength(512)], // Shipping Marks
            },
            selfValidators: [validators.minLength(1)],
            stopAtNull: true,
        },
        previousDocument: {
            // Previous Document {M}
            childValidators: {
                type: [validators.required(), validators.exact(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
                goodsItemNumber: [validators.number(), validators.maxLength(5)], // Goods Item Number
                typeOfPackages: [validators.exact(2)], // Type of Packages
                numberOfPackages: [validators.number(), validators.maxLength(8)], // Type of Packages
                measurementUnitAndQualifier: [validators.maxLength(4)], // Measurement Unit and Qualifier
                quantity: [validators.float(16, 6)], // Quantity
            },
            stopAtNull: true,
        },
        supportingDocument: {
            // Supporting Document {M}
            childValidators: {
                type: [validators.required(), validators.exact(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
                documentLineItemNumber: [validators.number(), validators.maxLength(5)], // Document Line Item number
                issuingAuthorityName: [validators.maxLength(70)], // Issuing Authority Name
                validityDate: [], // Validity Date
                measurementUnitAndQualifier: [validators.maxLength(4)], // Measurement Unit And Qualifier
                quantity: [validators.float(16, 6)], // Quantity
                currency: [validators.exact(3)], // Currency
                amount: [validators.float(16, 2)], // Amount
            },
            stopAtNull: true,
        },
        transportDocument: {
            // Transport Document {M}
            childValidators: {
                type: [validators.required(), validators.exact(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
            },
            stopAtNull: true,
        },
        additionalReference: {
            // Additional Reference {M}
            childValidators: {
                type: [validators.required(), validators.exact(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
            },
            stopAtNull: true,
        },
        additionalInformation: {
            // Additional Information {M}
            childValidators: {
                code: [validators.required(), validators.exact(5)], // Code
                text: [validators.maxLength(512)], // Text
            },
            stopAtNull: true,
        },
        transportCharges: {
            // Transport Charges
            childValidators: {
                methodOfPayment: [validators.exact(1)], // Method of Payment
            },
        },
    },
    stopAtNull: true,
}; // (level 2) Goods Item

export const irelandExportB1ValidationHeader = {
    childValidators: {
        numberOfItems: [
            validators.required(),
            validators.number(),
            validators.min(1),
            validators.maxLength(3),
            async (_: string, b: FormModel<any>) => {
                const itemsLength = b.getValues()?.goodsShipment?.goodsItem?.length;
                const numberOfItemsField = b.getValues()?.numberOfItems;
                if (itemsLength === undefined) return undefined;
                if (Number(itemsLength) !== Number(numberOfItemsField)) {
                    return 'Number of items from the input does not match the number of items in the declaration.';
                }
                return undefined;
            },
        ],
        exportOperation: {
            // Export Operation
            childValidators: {
                declarationType: [validators.required(), validators.maxLength(5)], // Declaration Type
                additionalDeclarationType: [validators.required(), validators.exact(1)], // Additional Declaration Type
                presentationOfTheGoodsDateAndTime: [], // Presentation of the Goods Date and Time
                security: [validators.required(), validators.number(), validators.exact(1)], // Security
                specificCircumstanceIndicator: [validators.exact(3)], // Specific Circumstance Indicator
                totalAmountInvoiced: [validators.number(), validators.float(16, 2)], // Total Amount Invoiced
                invoiceCurrency: [validators.exact(3)], // Invoice Currency
            },
        },
        authorisation: {
            // Authorisation {M}
            childValidators: {
                type: [validators.required(), validators.maxLength(4)], // Type
                referenceNumber: [validators.required(), validators.maxLength(35)], // Reference Number
                holderOfAuthorisation: [validators.eori(true)], // Holder of Authorisation
            },
            stopAtNull: true,
        },
        customsOfficeOfPresentation: {
            // Customs Office of Presentation
            childValidators: {
                referenceNumber: [validators.exact(8)], // Reference Number
            },
        },
        customsOfficeOfExport: {
            // Customs Office of Export (R)
            childValidators: {
                referenceNumber: [validators.required(), validators.exact(8)], // Reference Number
            },
        },
        customsOfficeOfExitDeclared: {
            // Customs Office of Exit (Declared) (R)
            childValidators: {
                referenceNumber: [validators.required(), validators.exact(8)], // Reference Number
            },
        },
        exporter: getPartyValidator({ eoriRequired: true }), // Exporter (R)
        declarant: getPartyValidator({ eoriRequired: true, contactPerson: { present: true } }), // Declarant (R)
        representative: getPartyValidator({ contactPerson: { present: true }, status: { present: true } }), // Representative
        currencyExchange: {
            // Currency Exchange
            childValidators: {
                internalCurrencyUnit: [validators.exact(3)], // Internal Currency Unit
                exchangeRate: [validators.float(12, 5)], // Exchange Rate
            },
        },
        deferredPayment: {
            // Deferred Payment
            childValidators: {
                deferredPayment: [validators.maxLength(35)], // Deferred Payment
            },
        },
        goodsShipment: {
            // Goods Shipment (R)
            childValidators: {
                natureOfTransaction: [validators.number(), validators.maxLength(2)], // Nature of Transaction
                countryOfExport: [validators.exact(2)], // Country of Export
                countryOfDestination: [validators.exact(2)], // Country of Destination
                additionalSupplyChainActor: {
                    // Additional Supply Chain Actor {M}
                    childValidators: {
                        role: [validators.required(), validators.maxLength(3)], // Role
                        identificationNumber: [validators.required(), validators.eori(true)],
                    },
                    stopAtNull: true,
                },
                deliveryTerms: {
                    // Delivery Terms
                    childValidators: {
                        incotermCode: [validators.exact(3)], // INCOTERM Code
                        unLocode: [validators.maxLength(17)], // UN LOCODE
                        location: [validators.maxLength(35)], // Location
                        country: [validators.exact(2)], // Country
                        text: [validators.maxLength(512)], // Text
                    },
                },
                warehouse: {
                    // Warehouse
                    childValidators: {
                        type: [validators.exact(1)], // Type
                        identifier: [validators.maxLength(35)], // Identifier
                    },
                },
                previousDocument: {
                    // Previous Document {M}
                    childValidators: {
                        type: [validators.required(), validators.exact(4)], // Type
                        referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
                    },
                    stopAtNull: true,
                },
                supportingDocument: {
                    // Supporting Document {M}
                    childValidators: {
                        type: [validators.required(), validators.exact(4)], // Type
                        referenceNumber: [validators.required(), validators.maxLength(70)], // Reference Number
                        documentLineItemNumber: [validators.number(), validators.maxLength(5)], // Document Line Item Number
                        issuingAuthorityName: [validators.maxLength(70)], // Issuing Authority Name
                        validityDate: [], // Validity Date
                    },
                    stopAtNull: true,
                },
                additionalReference: {
                    // Additional Reference {M}
                    childValidators: {
                        type: [validators.required(), validators.exact(4)], // Type
                        referenceNumber: [validators.maxLength(70)], // Reference Number
                    },
                    stopAtNull: true,
                },
                additionalInformation: {
                    // Additional Information {M}
                    childValidators: {
                        code: [validators.required(), validators.exact(5)], // Type
                        text: [validators.maxLength(512)], // Text
                    },
                    stopAtNull: true,
                },
                consignment: {
                    // Consignment (R)
                    childValidators: {
                        containerIndicator: [validators.number(), validators.exact(1)], // Container Indicator
                        inlandModeOfTransport: [validators.number(), validators.exact(1)], // Inland Mode of Transport
                        modeOfTransportAtTheBorder: [validators.number(), validators.exact(1)], // Mode of Transport at the Border
                        grossMass: [validators.float(16, 6)], // Gross mass
                        referenceNumberUCR: [validators.maxLength(35)], // Reference Number UCR
                        carrier: {
                            // Carrier
                            childValidators: {
                                identificationNumber: [validators.eori(true)], // Identification Number
                            },
                        },
                        consignor: getPartyValidator(), // Consignor
                        consignee: getPartyValidator(), // Consignee
                        transportEquipment: {
                            // Transport Equipment {M}
                            childValidators: {
                                containerIdentificationNumber: [validators.maxLength(17)],
                                numberOfSeals: [validators.required(), validators.number(), validators.maxLength(4)],
                                seal: {
                                    // Seal {M}
                                    childValidators: {
                                        identifier: [validators.required(), validators.maxLength(20)], // Identifier
                                    },
                                    stopAtNull: true,
                                },
                                goodsReference: {
                                    // Goods Reference {M}
                                    childValidators: {
                                        declarationGoodsItemNumber: [
                                            validators.required(),
                                            validators.number(),
                                            validators.maxLength(5),
                                        ], // Declaration Goods Item Number
                                    },
                                    stopAtNull: true,
                                },
                            },
                            stopAtNull: true,
                        }, // Transport Equipment
                        locationOfGoods: {
                            // Location of Goods
                            childValidators: {
                                typeOfLocation: [validators.exact(1)], // Type of Location
                                qualifierOfIdentification: [validators.exact(1)], // Qualifier of Identification
                                authorisationNumber: [validators.maxLength(35)], // Authorisation Number
                                additionalIdentifier: [validators.maxLength(4)], // Additional Identifier
                                unLocode: [validators.maxLength(17)], // UN LOCODE
                                customsOffice: {
                                    // Customs Office
                                    childValidators: {
                                        referenceNumber: [validators.exact(8)], // Reference Number
                                    },
                                },
                                gnss: {
                                    // GNSS
                                    childValidators: {
                                        latitude: [validators.maxLength(17)], // Latitude
                                        longitude: [validators.maxLength(17)], // Longitude
                                    },
                                },
                                economicOperator: {
                                    // Economic Operator
                                    childValidators: {
                                        identificationNumber: [validators.maxLength(17)], // Identification Number
                                    },
                                },
                                address: {
                                    // Address
                                    childValidators: {
                                        streetAndNumber: [validators.maxLength(70)], // Street And Number
                                        postcode: [validators.maxLength(17)], // Postcode
                                        city: [validators.maxLength(35)], // City
                                        country: [validators.exact(2)], // Country
                                    },
                                },
                                postcodeAddress: {
                                    // Postcode Address
                                    childValidators: {
                                        houseNumber: [validators.maxLength(17)], // House Number
                                        postcode: [validators.maxLength(17)], // Postcode
                                        country: [validators.exact(2)], // Country
                                    },
                                },
                                contactPerson: {
                                    // Contact Person
                                    childValidators: {
                                        name: [validators.maxLength(70)], // Name
                                        phoneNumber: [validators.maxLength(35)], // Phone Number
                                        emailAddress: [validators.maxLength(70)], // E-mail Address
                                    },
                                },
                            },
                        }, // Location of Goods
                        departureTransportMeans: {
                            // Departure Transport Means {M}
                            childValidators: {
                                typeOfIdentification: [validators.number(), validators.exact(2)], // Type of Identification
                                identificationNumber: [validators.required(), validators.maxLength(35)], // Identification Number
                                nationality: [validators.exact(2)], // Nationality
                            },
                            stopAtNull: true,
                        },
                        countryOfRoutingOfConsignment: {
                            // Country of Routing of Consignment {M}
                            childValidators: {
                                country: [validators.required(), validators.exact(2)], // Country
                            },
                            stopAtNull: true,
                        },
                        activeBorderTransportMeans: {
                            // Active Border Transport Means
                            childValidators: {
                                typeOfIdentification: [validators.number(), validators.exact(2)], // Type of Identification
                                identificationNumber: [validators.maxLength(35)], // Identification Number
                                nationality: [validators.exact(2)], // Nationality
                            },
                        },
                        transportDocument: {
                            // Transport Document {M}
                            childValidators: {
                                type: [validators.exact(4)], // Type
                                referenceNumber: [validators.maxLength(70)], // Reference Number
                            },
                            stopAtNull: true,
                        },
                        transportCharges: {
                            // Transport Charges
                            childValidators: {
                                methodOfPayment: [validators.exact(1)], // Method of Payment
                            },
                        },
                    },
                }, // Consignment
                goodsItem: irelandExportB1ValidationItem,
            },
        }, // Goods Shipment
    },
};

export const irelandExportRepresentativeEoriLevelRequired: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        representative: getPartyValidator({
            contactPerson: { present: true },
            eoriRequired: true,
            status: { present: true, required: true },
        }), // Representative
    },
};
export const irelandExportRepresentativeContactPersonLevelRequired: DeepPartial<
    typeof irelandExportB1ValidationHeader
> = {
    childValidators: {
        representative: getPartyValidator({
            contactPerson: { present: true, required: true },
            eoriRequired: true,
            status: { present: true, required: true },
        }), // Representative
    },
};
