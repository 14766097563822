import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { DigitalCertificate, UpdateDigitalCertificate, UploadDigitalCertificateRequest } from './digital-certificates';

export const uploadDigitalCertificate = (request: UploadDigitalCertificateRequest): Promise<DigitalCertificate> => {
    return axiosClient
        .post<SuccessResponse<DigitalCertificate>>(`${config.cmsUrl}/digital-certificates/upload`, request.file, {
            params: { type: request.type, filename: request.file.name, password: request.password },
            headers: { 'content-type': request.file.type },
        })
        .then((response) => response.data.payload);
};

export const updateDigitalCertificate = (id: string, body: UpdateDigitalCertificate): Promise<DigitalCertificate> => {
    return axiosClient
        .put<SuccessResponse<DigitalCertificate>>(`${config.cmsUrl}/digital-certificates/${id}`, body)
        .then((response) => response.data.payload);
};

export const listDigitalCertificate = (params: Partial<PaginatedParams>): Promise<ListPayload<DigitalCertificate>> => {
    return axiosClient
        .get<ListResponse<DigitalCertificate>>(`${config.cmsUrl}/digital-certificates`, { params })
        .then((response) => response.data.payload);
};

export const getDigitalCertificate = (id: string): Promise<DigitalCertificate> => {
    return axiosClient
        .get<DigitalCertificate>(`${config.cmsUrl}/digital-certificates/${id}`)
        .then((response) => response.data);
};

export const deleteDigitalCertificate = (id: string): Promise<SuccessResponse<void>> => {
    return axiosClient.delete<SuccessResponse<void>>(`${config.cmsUrl}/digital-certificates/${id}`).then((response) => {
        return response.data;
    });
};
