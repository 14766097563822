import { FieldInputProps } from 'formik';

export const handleBlur = (
    setFocused: Function,
    fieldProps?: FieldInputProps<any>,
    event?: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLElement>
) => {
    setFocused(false);
    if (event && fieldProps) {
        fieldProps.onBlur(event);
    }
};

export const handleFocus = (setFocused: Function) => setFocused(true);
