import styled from 'styled-components';

export const GlobalOverlayStyledComponents = {
    Main: styled.div<{ show: boolean }>`
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100vw;
        height: 100vh;

        background-color: rgba(255, 255, 255, 0.5);
        background: radial-gradient(circle, rgba(230, 230, 230, 0.6570027669270833) 0%, rgba(0, 0, 0, 0) 100%);

        transition: opacity 300ms;

        pointer-events: ${({ show }) => (show ? 'initial' : 'none')};
        opacity: ${({ show }) => (show ? 1 : 0)};

        z-index: 9999;
    `,
};
