import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCountries from 'hooks/useCountries';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';
import { CompanyDetailsOutletContext, useWCOOutletContext } from '../WCO';

const { ScreenTitle, ButtonContainer, ScreenTitleSubtitleContainer } = SetupStyledComponents;

const WCOCompanyDetails = () => {
    const { t } = useTranslation('weighbridge');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const navigate = useNavigate();
    const { countries } = useCountries();

    const { formik } = useWCOOutletContext<CompanyDetailsOutletContext>();

    // Setting the route in the header
    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: t('breadcrumbs.setup'),
                path: '',
            },
            {
                breadcrumbName: t('breadcrumbs.companyRegistration'),
                path: '',
            },
            {
                breadcrumbName: t('breadcrumbs.companyDetails'),
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <ScreenTitleSubtitleContainer>
                <ScreenTitle>{t('companyDetails.title')}</ScreenTitle>
            </ScreenTitleSubtitleContainer>
            <Row gutter={[30, 15]}>
                {/* Row 1 */}
                <Col xl={18}>
                    <FormInput
                        label={t('companyDetails.companyName.label')}
                        required
                        fieldProps={formik.getFieldProps('companyName')}
                        fieldMeta={formik.getFieldMeta('companyName')}
                    />
                </Col>
                {/* Row 2 */}
                <Col xl={12}>
                    <FormInput
                        label={t('companyDetails.addressLine1.label')}
                        required
                        fieldProps={formik.getFieldProps('addressLine1')}
                        fieldMeta={formik.getFieldMeta('addressLine1')}
                    />
                </Col>
                <Col xl={12}>
                    <FormPhoneNumber
                        required
                        fieldNumberProps={formik.getFieldProps('phoneNumber')}
                        fieldCodeProps={formik.getFieldProps('phoneCode')}
                        fieldCodeMeta={formik.getFieldMeta('phoneCode')}
                        fieldNumberMeta={formik.getFieldMeta('phoneNumber')}
                    />
                </Col>
                {/* Row 3 */}
                <Col xl={12}>
                    <FormInput
                        label={t('companyDetails.addressLine2.label')}
                        fieldProps={formik.getFieldProps('addressLine2')}
                        fieldMeta={formik.getFieldMeta('addressLine2')}
                    />
                </Col>
                <Col xl={12}>
                    <FormInput
                        label={t('companyDetails.email.label')}
                        required
                        fieldProps={formik.getFieldProps('email')}
                        fieldMeta={formik.getFieldMeta('email')}
                    />
                </Col>
                {/* Row 4 */}
                <Col xl={12}>
                    <FormInput
                        label={t('companyDetails.city.label')}
                        required
                        fieldProps={formik.getFieldProps('city')}
                        fieldMeta={formik.getFieldMeta('city')}
                    />
                </Col>
                {/* Row 5 */}
                <Col xl={{ span: 12, offset: 1, pull: 1 }}>
                    <FormSelect
                        label={t('companyDetails.country.label')}
                        required
                        fieldProps={formik.getFieldProps('country')}
                        fieldMeta={formik.getFieldMeta('country')}
                        selectOptions={countries.list.map((obj) => {
                            return {
                                id: obj.countryCode,
                                value: obj.name,
                            };
                        })}
                    />
                </Col>
            </Row>
            <ButtonContainer>
                <Button onClick={() => navigate(-1)}>{t('back')}</Button>
                <Button disabled={!isEmpty(formik.errors)} type="primary" onClick={() => navigate('../locations')}>
                    {t('next')}
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default WCOCompanyDetails;
