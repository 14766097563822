import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import DeclarationCheckbox from '../../../../../../components/ui/composed/declarations/formCheckbox/DeclarationCheckbox';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const ContainerCheckboxCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('container')}>
            <FormCardContainer>
                <DeclarationCheckbox
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsShipment.container', props)}
                    onChange={(e) => {
                        props.getFieldHelpers('goodsShipment.container').setValue(e.target.checked ?? e.target.value);
                    }}
                    checked={
                        props.getFieldProps('goodsShipment.container').checked ??
                        props.getFieldProps('goodsShipment.container').value
                    }
                    refNumber="7.2"
                    label={t('container')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ContainerCheckboxCard;
