import useCodelists from 'hooks/useCodelists';
import useSession from 'hooks/useSession';
import useTooltips from 'hooks/useTooltips';
import { FC, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { StyledContent, StyledLayout } from './Skeleton.styles';

const Skeleton: FC = () => {
    const { isLogged } = useSession();
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);

    // load all codelists at application start
    useCodelists(true);
    useTooltips(true);

    if (!isLogged) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <>
            <StyledLayout>
                <Sidebar collapsed={collapsed} />
                <StyledLayout>
                    <Header collapsed={collapsed} setCollapsed={setCollapsed} />
                    <StyledContent>
                        <Outlet />
                    </StyledContent>
                </StyledLayout>
            </StyledLayout>
        </>
    );
};
export default Skeleton;
