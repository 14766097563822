import faker from '@faker-js/faker';
import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Weighbridge, WeighbridgeDeviceResponse, WeighbridgeStatus } from './weighbridge';
import { EditWeighingRequest, WeighingRequest, WeighingResponse } from './weighing';

export const dummyWeighing = (weighingId = faker.datatype.uuid()): WeighingResponse => ({
    id: weighingId,
    createdAt: faker.date.recent().toISOString(),
    createdBy: faker.datatype.uuid(),
    jobId: faker.datatype.uuid(),
    legId: faker.datatype.uuid(),
    autoWeight: faker.datatype.number({ precision: 0.01 }).toString(),
    capturedWeightImage: faker.image.business(),
    customerId: faker.datatype.uuid(),
    dispatcher: faker.name.firstName(),
    docketId: faker.datatype.uuid(),
    driverId: faker.datatype.uuid(),
    locationName: faker.address.cityName(),
    manualOverride: faker.datatype.boolean(),
    manualWeight: faker.datatype.number({ precision: 0.01 }),
    requestType: faker.lorem.word(),
    traceId: faker.datatype.uuid(),
    updatedAt: faker.date.recent().toISOString(),
    updatedBy: faker.datatype.uuid(),
    weighOut: faker.datatype.boolean(),
});

export const dummyWeighbridgeDeviceResponse = (): WeighbridgeDeviceResponse => ({
    id: faker.datatype.uuid(),
    city: faker.address.cityName(),
    country: faker.address.countryCode(),
    connected: true,
    customerId: faker.datatype.uuid(),
    dispatcher: faker.name.firstName(),
    geofenceCoordinates: [
        {
            id: faker.datatype.uuid(),
            latitude: +faker.address.latitude(),
            longitude: +faker.address.longitude(),
            orderIndex: 1,
        },
        {
            id: faker.datatype.uuid(),
            latitude: +faker.address.latitude(),
            longitude: +faker.address.longitude(),
            orderIndex: 2,
        },
        {
            id: faker.datatype.uuid(),
            latitude: +faker.address.latitude(),
            longitude: +faker.address.longitude(),
            orderIndex: 3,
        },
    ],
    locationName: faker.address.streetAddress(),
    name: faker.company.companyName(),
    status: WeighbridgeStatus.READY,
});

export const requestWeighing = (weighbridgeId: string, weighingRequest: WeighingRequest): Promise<WeighingResponse> =>
    axiosClient
        .post<SuccessResponse<WeighingResponse>>(
            `${config.weighbridgeUrl}/weighbridges/${weighbridgeId}/weigh`,
            weighingRequest
        )
        .then((response) => {
            return response.data.payload;
        });

/* export const requestWeighing = (weighbridgeId: string, weighingRequest: WeighingRequest): Promise<WeighingResponse> =>
    Promise.resolve(dummyWeighing()); */

export const getWeighing = (weighingId: string): Promise<WeighingResponse> =>
    axiosClient
        .get<SuccessResponse<WeighingResponse>>(`${config.weighbridgeUrl}/weighings/${weighingId}`)
        .then((response) => {
            return response.data.payload;
        });
/* export const getWeighing = (weighingId: string): Promise<WeighingResponse> =>
    Promise.resolve(dummyWeighing(weighingId)); */

export const editWeighing = (weighingId: string, weighing: Partial<EditWeighingRequest>): Promise<WeighingResponse> =>
    axiosClient
        .put<SuccessResponse<WeighingResponse>>(`${config.weighbridgeUrl}/weighings/${weighingId}`, weighing)
        .then((response) => {
            return response.data.payload;
        });

export const listActiveWeighbridgeDevices = (): Promise<ListPayload<WeighbridgeDeviceResponse>> =>
    axiosClient
        .get<ListResponse<WeighbridgeDeviceResponse>>(`${config.weighbridgeUrl}/weighbridges/active`)
        .then((response) => {
            return response.data.payload;
        });

export const listWeighbridges = (params?: Partial<PaginatedParams>): Promise<ListPayload<Weighbridge>> =>
    axiosClient
        .get<ListResponse<Weighbridge>>(`${config.weighbridgeUrl}/weighbridges`, { params })
        .then((response) => response.data.payload);

/* export const listActiveWeighbridgeDevices = (): Promise<ListPayload<WeighbridgeDeviceResponse>> =>
    Promise.resolve({
        list: [...Array(10)].map(() => dummyWeighbridgeDeviceResponse()),
        pageNumber: 0,
        pageSize: 1,
        total: 2,
    }); */
