import Notification from 'components/ui/base/notification/Notification';

type MessageOptions = {
    success: {
        title: string;
        description: string;
    };
    error: {
        title: string;
        description: string;
    };
};

const useResponseNotification = () => {
    const showErrorNotification = (title: string, description: string) => {
        Notification({
            type: 'error',
            messageTitle: title,
            description: description,
            duration: 5,
        });
    };

    const showSuccessNotification = (title: string, description: string) => {
        Notification({
            type: 'success',
            messageTitle: title,
            description: description,
        });
    };

    const withResponseNotifications = <TResponseType>(response: TResponseType, msg: MessageOptions) => {
        if (response) {
            showSuccessNotification(msg.success.title, msg.success.description);
        } else {
            showErrorNotification(msg.error.title, msg.error.description);
        }

        return response;
    };

    return { withResponseNotifications };
};

export default useResponseNotification;
