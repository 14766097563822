import { Reducer } from 'redux';
import { TestActions } from './actionType';
import { BreadcrumbItem } from './breadcrumbItem';

export const initialState: BreadcrumbItem[] = [];

const breadcrumb: Reducer<BreadcrumbItem[]> = (state = initialState, action: TestActions) => {
    switch (action.type) {
        case 'SET_BREADCRUMB':
            return action.payload;
        case 'CLEAR_BREADCRUMB':
            return initialState;
        default:
            return state;
    }
};

export default breadcrumb;
