import { FC } from 'react';
import { CustomDivider } from './Divider.styles';

interface Props {
    type?: 'horizontal' | 'vertical';
    style?: React.CSSProperties;
}

const Divider: FC<Props> = (props) => {
    return <CustomDivider {...props} />;
};
export default Divider;
