import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';

const FirstEntryCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('customOfficeFirstEntry')}>
            <FormCardContainer>
                <FormInput
                    viewOnly={props.viewOnly}
                    required
                    maxLength={35}
                    {...getFormikProps(`goodsShipment.firstEntryCustomsOffice.referenceNumber`, props)}
                    label={t('referenceNumber')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />
                <DeclarationDatepicker
                    viewOnly={props.viewOnly}
                    required
                    {...getFormikProps(`goodsShipment.firstEntryCustomsOffice.expectedDateTimeArrival`, props)}
                    refNumber="2.3"
                    label={t('expectedDateTimeArrival')}
                    tooltip={t('defaultTooltip')}
                    showTime={true}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default FirstEntryCard;
