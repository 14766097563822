import { Col, Row, Space } from 'antd';
import Button from 'components/ui/base/button';
import { Text } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Coordinate } from 'views/drivers/view-map/Map';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';
import MapWeighbridgeLocationTrace from '../components/MapWeighbridgeLocationTrace';
import { LocationOutletContext, useWCOutletContext } from '../WC';

const { ScreenTitleSubtitleContainer, ScreenTitle, ButtonContainer } = SetupStyledComponents;

const WCWeighbridgeLocationTrace = () => {
    const { t } = useTranslation('weighbridge');

    const { formik, weighbridgeCreationFormik } = useWCOutletContext<LocationOutletContext>();

    const navigate = useNavigate();

    const handleCoordinates = useCallback(
        (coordinates: Coordinate[]) => {
            formik.setFieldValue('coordinates', coordinates);
        },
        [formik]
    );

    const handleBack = () => {
        navigate(-1);
    };

    const handleSaveAndClose = () => {
        // TODO Save
        // TODO Navigate somewhere (for the close)
    };

    const handleNext = () => {
        // TODO Save
        navigate('/weighbridges');
    };

    return (
        <div>
            <ScreenTitleSubtitleContainer>
                <ScreenTitle>{t('weighbridgeLocation.title')}</ScreenTitle>
            </ScreenTitleSubtitleContainer>
            <Space size={10} direction="vertical">
                <Row gutter={[30, 15]}>
                    <Col xl={12}>
                        <FormInput
                            disabled
                            label={t('weighbridge.label.label')}
                            fieldProps={weighbridgeCreationFormik.getFieldProps('label')}
                            fieldMeta={weighbridgeCreationFormik.getFieldMeta('label')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            disabled
                            label={t('weighbridge.location.label')}
                            fieldProps={weighbridgeCreationFormik.getFieldProps('location')}
                            fieldMeta={weighbridgeCreationFormik.getFieldMeta('location')}
                        />
                    </Col>
                    <Col xl={6}>
                        <Space size={15} direction="vertical">
                            <Text>{t('weighbridgeLocation.text1')}</Text>
                            <Text>{t('weighbridgeLocation.text2')}</Text>
                        </Space>
                    </Col>
                    <Col xl={18}>
                        <MapWeighbridgeLocationTrace onDraw={handleCoordinates} selection={formik.values.coordinates} />
                    </Col>
                </Row>
            </Space>

            <ButtonContainer>
                <Button onClick={handleBack}>{t('back')}</Button>
                <Button onClick={handleSaveAndClose}>{t('saveAndClose')}</Button>
                <Button type="primary" onClick={handleNext} disabled={!isEmpty(formik.errors)}>
                    {t('next')}
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default WCWeighbridgeLocationTrace;
