import { FC, useEffect, useState } from 'react';
import { FilterResult } from './filter-result';
import FilterButton from './components/FilterButton';
import FilterCard from './components/FilterCard';
import { useLocation } from 'react-router-dom';

interface Props {
    filterState: FilterResult[];
    onFilterChange: (filters: FilterResult[]) => void;
}

export const Filter: FC<Props> = ({ filterState, onFilterChange }) => {
    const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);

    const location = useLocation();
    useEffect(() => {
        setShowFilterDropdown(false);
    }, [location.pathname]);

    return (
        <>
            <FilterButton onClick={() => setShowFilterDropdown((s: boolean) => !s)} />
            {showFilterDropdown && (
                <FilterCard
                    filterState={filterState}
                    handleClose={() => setShowFilterDropdown(false)}
                    onFilter={(filterChangeParams) => {
                        const filterChangeParamsTypeIndex = filterState.findIndex(
                            (f) => f.type === filterChangeParams.type
                        );

                        if (filterChangeParamsTypeIndex === -1) {
                            // If the filter doesn't exist in the state, add it.
                            onFilterChange([...filterState, filterChangeParams]);
                        } else {
                            // If the filter exists, update it.
                            const newFilters = [...filterState];
                            newFilters[filterChangeParamsTypeIndex] = { ...filterChangeParams };
                            onFilterChange(newFilters);
                        }
                    }}
                />
            )}
        </>
    );
};
export default Filter;
