import { Modal, ModalProps, Tag } from 'antd';
import styled from 'styled-components';
import { AesAmendmentData } from '../../../../../../store/declarations/common/amendment-summary-data';
import { Typography } from 'antd';
import { useCallback, Fragment } from 'react';
import { fromExternalFieldNameToInternal, getFieldName } from '../../../../../../utils/validationParserUtils';
import irelandExportValidationErrorsParser, {
    aesFieldsWithHardCodedIndexesInParser,
} from '../../../../ireland/export/validation/IrelandExportValidationErrorsParser';
import useGlobalOverlay from '../../../../../../hooks/useGlobalOverlay';

const { Text } = Typography;

const Wrapper = styled.div`
    display: grid;
    place-items: center;
`;

const AmendmentList = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
`;

const Header = () => {
    return (
        <>
            <Text strong>Path</Text>
            <Text strong>Previous Value</Text>
            <Text strong>New Value</Text>
            <Text strong>Action</Text>
        </>
    );
};

interface Props extends ModalProps {
    amendments: AesAmendmentData | null;
    submitAmendmentRequest: () => Promise<void>;
}

const AesAmendmentModal = ({ amendments, submitAmendmentRequest, ...modalProps }: Props) => {
    const { hideGlobalOverlay } = useGlobalOverlay();

    const replacePathIndexWithHardCodedIndexInParser = (path: string) => {
        if (Object.keys(aesFieldsWithHardCodedIndexesInParser).some((field) => path.includes(field))) {
            return path.replace(/\d(?![\d\D]*\d)/, '0');
        }

        return path;
    };

    const getAmendedFieldName = useCallback((path: string) => {
        const _path = replacePathIndexWithHardCodedIndexInParser(path);

        const fieldName = getFieldName(irelandExportValidationErrorsParser, _path, 'goodsShipment.goodsItem');
        if (fieldName === 'Unknown card - Unknown field' || !fieldName) return fromExternalFieldNameToInternal(path);
        return fieldName;
    }, []);

    return (
        <Modal
            title="Amendment Summary"
            width={750}
            onOk={async () => {
                await submitAmendmentRequest();
                hideGlobalOverlay();
            }}
            {...modalProps}
        >
            <Wrapper>
                <AmendmentList>
                    <Header />
                    {amendments &&
                        Object.entries(amendments).map(([path, values]) => (
                            <Fragment key={path}>
                                <div>
                                    <Text italic>{getAmendedFieldName(path)}</Text>
                                </div>
                                <div>
                                    <Text type={'secondary'}>{values.oldValue}</Text>
                                </div>
                                <div>
                                    <Text
                                        type={
                                            values.action === 'ADD'
                                                ? 'success'
                                                : values.action === 'EDIT'
                                                ? 'warning'
                                                : 'danger'
                                        }
                                    >
                                        {values.action === 'DELETE' ? '-' : values.newValue}
                                    </Text>
                                </div>
                                <div>
                                    <Tag
                                        color={
                                            values.action === 'ADD'
                                                ? 'green'
                                                : values.action === 'EDIT'
                                                ? 'gold'
                                                : 'red'
                                        }
                                        style={{ display: 'flex', justifyContent: 'center', margin: 0 }}
                                    >
                                        {values.action}
                                    </Tag>
                                </div>
                            </Fragment>
                        ))}
                </AmendmentList>
            </Wrapper>
        </Modal>
    );
};

export default AesAmendmentModal;
