import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import { ValidationSchema, Validator, validators } from '../validations/validations';

type Fields = 'categoryCode' | 'typeCode' | 'id' | 'lineNumeric';

export const previousDocumentsBlockRequiredValidation: {
    childValidators: {
        'goodsShipment.previousDocument': ValidationSchema<Fields>;
    };
    selfValidators?: Validator[];
} = {
    childValidators: {
        'goodsShipment.previousDocument': {
            childValidators: {
                categoryCode: [validators.required(), validators.exact(1)],
                typeCode: [validators.required(), validators.maxLength(3)],
                id: [validators.required(), validators.maxLength(35)],
                lineNumeric: [validators.maxLength(3)],
            },
            selfValidators: [validators.minLength(1)],
            stopAtNull: true,
        },
    },
};

export const previousDocumentsBlockOptionalValidation: {
    childValidators: {
        'goodsShipment.previousDocument': ValidationSchema<Fields>;
    };
    selfValidators?: Validator[];
} = {
    childValidators: {
        'goodsShipment.previousDocument': {
            childValidators: {
                categoryCode: [validators.required(), validators.exact(1)],
                typeCode: [validators.required(), validators.maxLength(3)],
                id: [validators.required(), validators.maxLength(35)],
                lineNumeric: [validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

export const previousDocumentsBlockProductValidation: {
    childValidators: {
        previousDocument: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        previousDocument: {
            childValidators: {
                categoryCode: [validators.required(), validators.exact(1)],
                typeCode: [validators.required(), validators.maxLength(3)],
                id: [validators.required(), validators.maxLength(35)],
                lineNumeric: [validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const PreviousDocumentsBlock = ({ path = 'goodsShipment.previousDocument', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '2/1', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="categoryCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('previousDocumentCategory')}
                        refNumber={ucc}
                        selectOptions={
                            formType?.includes('IMPORT')
                                ? cdsCodelists?.documentCategories
                                : cdsCodelists?.exportDocumentCategories
                        }
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('previousDocumentType')}
                        refNumber={ucc}
                        selectOptions={
                            formType?.includes('IMPORT')
                                ? cdsCodelists?.importPreviousDocuments
                                : cdsCodelists?.exportPreviousDocuments
                        }
                        condensed
                        codeValidation={[validators.maxLength(3)]}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormInput
                        required
                        maxLength={35}
                        {...getFormikProps(field.name, form)}
                        refNumber={ucc}
                        label={t('previousDocumentReference')}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="lineNumeric">
                {({ form, field }) => (
                    <DeclarationNumberInput
                        maxLength={3}
                        min="1"
                        {...getFormikProps(field.name, form)}
                        refNumber={ucc}
                        label={t('goodsItemIdentifier')}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default PreviousDocumentsBlock;
