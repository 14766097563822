import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { H5 } from 'components/ui/base/typography';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useSession from 'hooks/useSession';
import { FC, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StyledLogoSmall, StyledMobileHeader, UserDiv } from './MobileHeader.styles';

const MobileHeader: FC = () => {
    const { logout, userInfo } = useSession();
    const { breadcrumb } = useBreadcrumb();
    const navigate = useNavigate();

    const headerMenuItems = useMemo((): ItemType[] => {
        return [
            {
                label: userInfo?.email,
                key: 'email',
            },
            {
                label: <Link to={{ pathname: '/terms-and-conditions' }}>Terms & Conditions</Link>,
                key: 'terms-and-conditions',
            },
            {
                label: <Link to={{ pathname: '/privacy-policy' }}>Privacy Policy</Link>,
                key: 'privacy-policy',
            },
            {
                label: 'Logout',
                key: 'terms-and-conditions',
                onClick: logout,
            },
        ];
    }, [logout, userInfo?.email]);

    const menu = useMemo(() => {
        return <Menu items={headerMenuItems} />;
    }, [headerMenuItems]);

    return (
        <StyledMobileHeader>
            <StyledLogoSmall onClick={() => navigate('/mobile/home')} />
            <H5>{breadcrumb[0]?.breadcrumbName}</H5>
            <UserDiv>
                <Dropdown overlay={menu} placement="bottom">
                    <UserOutlined />
                </Dropdown>
            </UserDiv>
        </StyledMobileHeader>
    );
};
export default MobileHeader;
