import { H4, H6 } from 'components/ui/base/typography';
import styled, { StyledComponent } from 'styled-components';
import { colors } from 'theme';

interface SetupComponents {
    Main: StyledComponent<'div', any>;
    Container: StyledComponent<'div', any>;
    ScreenTitle: StyledComponent<'h4', any>;
    ScreenSubtitle: StyledComponent<'h6', any>;
    ScreenTitleSubtitleContainer: StyledComponent<'div', any>;
    ButtonContainer: StyledComponent<'div', any, { noMarginTop?: boolean }>;
}

const SetupStyledComponents: SetupComponents = {
    Main: styled.div`
        display: flex;
        justify-content: center;
    `,
    Container: styled.div`
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 1000px;
        margin: 6rem 0;
        padding: 0 4rem;
    `,
    ScreenTitle: styled(H4)`
        color: ${colors.darkPurple};
        font-size: 3.5rem;
        margin-bottom: 0;
    `,
    ScreenSubtitle: styled(H6)`
        color: ${colors.darkGrey};
        font-size: 1.75rem;
        line-height: 1.2;
        margin-top: 1.5rem;
        margin-bottom: 0;
        max-width: 50ch;
    `,
    ScreenTitleSubtitleContainer: styled.div`
        margin-bottom: 5rem;
    `,
    ButtonContainer: styled.div<{ noMarginTop?: boolean }>`
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        width: 100%;

        margin-top: ${({ noMarginTop = false }) => (noMarginTop ? '0' : '5rem')};
    `,
};

export default SetupStyledComponents;
