import { FormikProps, useFormik } from 'formik';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { MessageTypes } from '../../../../store/declarations/enums/common/template-types';
import { TemplateDashboardState } from '../../../../views/templates/TemplatesDashboard';

export interface TemplateResponse {
    id: string;
    templateName?: string;
    template?: Template;
    declarationInternalType?: string;
    declarationExternalType?: string;
    declarationName?: MessageTypes;
    customerId?: string;
}

export type FormType = 'master' | 'product';

export interface TemplateOptions {
    country?: 'IRELAND' | 'UK' | 'CDS' | 'REVENUE';
    declarationType?: 'IMPORT' | 'EXPORT' | 'ENS';
    formType?: MessageTypes;
    name?: string;
}
interface OpenModalArgs extends TemplateOptions {
    templateId?: string;
    viewOnly?: boolean;
}

export type TemplateContextType = {
    template?: boolean;
    setTemplate?: Dispatch<SetStateAction<boolean>>;
    templateFormik?: TemplateFormik;
    openModal?: (args?: OpenModalArgs | undefined) => void;
    closeModal?: () => void;
    open?: boolean;
    templateId?: string;
    options?: TemplateOptions;
    form?: 'master' | 'product';
    setForm?: Dispatch<SetStateAction<FormType>>;
    isViewOnly?: boolean | undefined;
    onCreateTemplateListing?: (obj: TemplateDashboardState) => void;
};
export const TemplateContext = createContext<TemplateContextType>({} as TemplateContextType);
export const useTemplateContext = () => useContext<TemplateContextType>(TemplateContext);

export interface MetaData {
    isViewable?: boolean;
    isEditable?: boolean;
}

export interface Template {
    master: TemplateData;
    product: TemplateData;
}
export interface TemplateData {
    defaults: Record<string, any>;
    meta: Record<string, MetaData>;
}
type TemplateFormik = FormikProps<Template>;

interface Props {
    children: ReactNode;
    onCreateTemplateListing?: (obj: TemplateDashboardState) => void;
}

export const TemplateContextProvider = ({ children, onCreateTemplateListing }: Props) => {
    const [template, setTemplate] = useState(false);
    const [templateId, setTemplateId] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<OpenModalArgs | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState<FormType>('master');
    const [isViewOnly, setIsViewOnly] = useState<boolean | undefined>(false);
    const templateMockData = {
        master: {
            defaults: {},
            meta: {},
        },
        product: {
            defaults: {},
            meta: {},
        },
    };
    const templateFormik = useFormik<Template>({
        initialValues: templateMockData,
        onSubmit: () => {},
    });

    const openModal = (args: OpenModalArgs | undefined) => {
        const { templateId, viewOnly, ...options } = args ?? {};
        setOpen(true);
        setTemplateId(templateId);
        setOptions(options);
        setIsViewOnly(viewOnly);
    };
    const closeModal = () => {
        setOpen(false);
        setTemplateId(undefined);
    };

    return (
        <TemplateContext.Provider
            value={{
                template,
                setTemplate,
                templateFormik,
                openModal,
                closeModal,
                open,
                templateId,
                options,
                form,
                setForm,
                isViewOnly,
                onCreateTemplateListing,
            }}
        >
            {children}
        </TemplateContext.Provider>
    );
};
