import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';

interface Props {
    setActiveTabKey: (tabKey: DeclarationTabKeys) => void;
}

export const updateActiveTabKey = (location: any) => {
    const activeTabKeyFromLocationState = (location.state as { activeTabKey?: DeclarationTabKeys })?.activeTabKey;

    if (activeTabKeyFromLocationState) {
        return activeTabKeyFromLocationState;
    }

    const paths = location.pathname.split('/');
    const activeTabKeyFromLocationPathname = paths[3];

    if (activeTabKeyFromLocationPathname === 'products' || !activeTabKeyFromLocationPathname) {
        return DeclarationTabKeys.FORM;
    }

    return activeTabKeyFromLocationPathname;
};

const useHandleActiveTabKeyBasedOnLocation = ({ setActiveTabKey }: Props) => {
    const location = useLocation();

    useEffect(() => setActiveTabKey(updateActiveTabKey(location)), [location, setActiveTabKey]);
};

export default useHandleActiveTabKeyBasedOnLocation;
