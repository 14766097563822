import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import EDocketFormInput from './inputs/EDocketFormInput';

const { SectionTitle, SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik>;

const EDocketFormDriver = <TFormik extends FormikProps<EDocketFormType>>({ formik, t }: Props<TFormik>) => {
    return (
        <SectionMain>
            <SectionTitle>{t('driverInformation')}</SectionTitle>

            <Row gutter={[8, 25]}>
                <Col xl={6}>
                    <EDocketFormInput
                        label={t('driver.vehicleRegisterNumber.label')}
                        name="driver.vehicleRegisterNumber"
                        value={formik.values.driver.vehicleRegisterNumber}
                        placeholder={t('driver.vehicleRegisterNumber.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={6}>
                    <EDocketFormInput
                        label={t('driver.trailerIdNumber.label')}
                        name="driver.trailerIdNumber"
                        value={formik.values.driver.trailerIdNumber}
                        placeholder={t('driver.trailerIdNumber.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
            </Row>
        </SectionMain>
    );
};

export default EDocketFormDriver;
