import { Reducer } from 'redux';
import { TestActions } from './actionType';
import { DeclarationValidation } from './declaration-validation';

export const initialState: DeclarationValidation = {
    formAction: null,
    pressedSubmit: false,
    hasUnsavedChanges: false,
};

const declarationValidation: Reducer<DeclarationValidation, TestActions> = (
    state = initialState,
    action: TestActions
) => {
    switch (action.type) {
        case 'SET_DECLARATION_VALIDATION':
            return { ...state, formAction: action.value };
        case 'RESET_DECLARATION_VALIDATION':
            return { ...state, formAction: initialState.formAction };
        case 'CHANGE_PRESS_SUMBIT':
            return { ...state, pressedSubmit: action.value };
        case 'SET_HAS_UNSAVED_CHANGES':
            return { ...state, hasUnsavedChanges: action.value };
        default:
            return state;
    }
};
export default declarationValidation;
