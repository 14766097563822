import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { validators } from '../../export/validations/validations';
import HideComponent from '../../../../../utils/HideComponent';

export const quotaOrderNumberBlockValidation = {
    childValidators: {
        quotaOrderID: [validators.exact(6)],
    },
};

interface Props extends BlockProps<'quotaOrderId'> {}

const QuotaOrderNumberBlock = ({ fieldOptions, path = 'commodity.dutyTaxFee', hide }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '8/1', []);
    return (
        <HideComponent hide={hide}>
            <DeclarationField name="quotaOrderID" pathPrefix={path}>
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('quotaOrderNumber')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default QuotaOrderNumberBlock;
