import { FilePdfOutlined } from '@ant-design/icons/lib/icons';
import { Text } from 'components/ui/base/typography';
import styled from 'styled-components';

export const Filename = styled(Text)`
    margin-right: 1.2rem;
`;

export const StyledFilePdfOutlined = styled(FilePdfOutlined)`
    margin-right: 1.2rem;
`;

export const UploadDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;
