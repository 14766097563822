import { ErrorResponse } from "core/http/response";
import { Reducer } from "redux";
import { ExchangeActions } from './actionType';
import { Exchange } from "./exchange";

export interface ExchangesState {
    isLoading: boolean;
    error?: ErrorResponse;
    exchanges: Exchange[];
}

export const exchangesInitialState: Readonly<ExchangesState> = {
    isLoading: false,
    exchanges: [],
};

const exchanges: Reducer<ExchangesState, ExchangeActions> = (
    state = exchangesInitialState,
    action
): ExchangesState => {
    switch (action.type) {
        case 'LIST_EXCHANGE':
            return {
                ...state,
                isLoading: true,
            }
        case 'LIST_EXCHANGE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                exchanges: action.payload.list,
            }
        case 'LIST_EXCHANGE_ERROR':
            return {
                ...state,
                isLoading: false,
                exchanges: state.exchanges,
                error: action.error,
            }
        default: return state;
    }
}
export default exchanges;