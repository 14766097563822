import { Navigate, RouteObject } from 'react-router-dom';
import { IndividualType } from 'store/individuals/individual';
import { User } from 'store/session/user';
import AboutPage from 'views/about';
import Authentication from 'views/authentication/Auth';
import ForgotPassword from 'views/authentication/forgot-password/ForgotPassword';
import Invite from 'views/authentication/invite/Invite';
import SignIn from 'views/authentication/sign-in/SignIn';
import SignUp from 'views/authentication/sign-up/SignUp';
import CargoJourneysDetails from 'views/cargo-journeys/CargoJourneysDetails';
import LegView from 'views/cargo-journeys/LegView';
import CargoManagement from 'views/cargo-management/CargoManagement';
import IrelandArchiveView from 'views/custom-declaration/archive/ireland/IrelandArchiveView';
import UkArchiveView from 'views/custom-declaration/archive/uk/UkArchiveView';
import ProductTemplatesDashboard from 'views/custom-declaration/products/ProductTemplatesDashboard';
import Customers from 'views/customers/Customers';
import DeclarationProductView from 'views/declarations/DeclarationProductView';
import MasterDetails from 'views/declarations/MasterDetails';
import DepositDeclaration from 'views/declarations/refunds/DepositDeclaration';
import Refunds from 'views/declarations/refunds/Refunds';
import RefundsDeposits from 'views/declarations/refunds/RefundsDeposits';
import RefundsList from 'views/declarations/refunds/RefundsList';
import RefundsRemissions from 'views/declarations/refunds/RefundsRemissions';
import RemissionDeclaration from 'views/declarations/refunds/RemissionDeclaration';
import CustomerDetails from 'views/declarations/sections/customer-details';
import CustomerDocuments from 'views/declarations/sections/customer-documents';
import DeclarationForm from 'views/declarations/sections/declaration-form/DeclarationForm';
import DeclarationHistory from 'views/declarations/sections/history/DeclarationHistory';
import DeclarationHistoryDetails from 'views/declarations/sections/history/DeclarationHistoryDetails';
import DeclarationHistoryTable from 'views/declarations/sections/history/DeclarationHistoryTable';
import DeclarationsNotificationsView from 'views/declarations/sections/notifications/DeclarationNotificationsView';
import DeclarationProductsTable from 'views/declarations/sections/products/DeclarationProductsTable';
import ViewOnlyTab from 'views/declarations/sections/view-only/ViewOnlyTab';
import HelpView, { DocumentationType } from 'views/documentation/Help';
import DriverManagement from 'views/drivers/management';
import ViewMap from 'views/drivers/view-map';
import InvoiceUpload from 'views/invoice-upload/InvoiceUpload';
import JobsDetails from 'views/jobs/components/JobsDetails';
import Jobs from 'views/jobs/Jobs';
import PrivacyPolicy from 'views/legal-info/PrivacyPolicy';
import TermsAndConditions from 'views/legal-info/TermsAndConditions';
import MobileLegDetailsView from 'views/mobile/legs/MobileLegDetailsView';
import MobileLegMapView from 'views/mobile/legs/MobileLegMapView';
import MobileLegView from 'views/mobile/legs/MobileLegView';
import MobileWeighingView from 'views/mobile/weighings/MobileWeighingView';
import ChangePassword from 'views/settings/components/ChangePassword';
import CompanyDetails from 'views/settings/components/company-details/CompanyDetails';
import DigitalCertificate from 'views/settings/components/digital-certificates/DigitalCertificate';
import Hmrc from 'views/settings/components/Hmrc';
import HmrcConfirmation from 'views/settings/components/HmrcConfirmation';
import PersonalInformation from 'views/settings/components/PersonalInformation';
import Settings from 'views/settings/Settings';
import UserManagement from 'views/user-management/UserManagement';
import VehiclesManagement from 'views/vehicles/VehiclesManagement';
import MobileSkeleton from '../../views/mobile/skeleton/MobileSkeleton';
import setupRoutes from './setup/setup.routes';
import eDocketRoutes from './edocket/edocket.routes';
import Skeleton from './skeleton/Skeleton';
import { ReactElement } from 'react';
import AddEditProductTemplate from '../../views/custom-declaration/products/AddEditProductTemplate';
import TemplateDashboard from 'views/templates/TemplatesDashboard';
import SummaryView from '../../views/declarations/common/summary-view/SummaryView';
import DashboardView from '../../views/dashboard/DashboardView';
import DeclarationDashboard from '../../views/custom-declaration/DeclarationDashboard';
import DeclarationView from '../../views/declarations/common/declaration-view/DeclarationView';

export interface Route extends RouteObject {
    name: string;
    props?: any;
    adminRoute?: boolean;
    children?: Route[];
    restrictRoute?: IndividualType[];
    state?: any;
}

interface ProtectedRouteProps {
    user?: User;
    children: ReactElement;
    allowedUsers?: IndividualType[];
}
export const ProtectedRoute = ({ user, children, allowedUsers }: ProtectedRouteProps) => {
    if (!user) {
        return <Navigate to="/" replace />;
    }

    if (allowedUsers && !allowedUsers.includes(user.role as IndividualType)) {
        return <Navigate to="/home" replace />;
    }

    return children;
};

const routesConfig = (user: User | undefined): Route[] => {
    return [
        {
            path: '/',
            name: 'Authentication',
            element: <Authentication />,
            children: [
                {
                    path: '',
                    name: 'SignIn',
                    element: <SignIn />,
                    index: true,
                },
                {
                    path: 'signUp',
                    name: 'SignUp',
                    element: <SignUp />,
                },
                {
                    path: 'reset',
                    name: 'ResetPassword',
                    children: [
                        {
                            path: ':token',
                            name: 'ResetPasswordToken',
                            element: <ForgotPassword />,
                        },
                        {
                            path: '',
                            name: 'ResetPassword',
                            element: <ForgotPassword />,
                            index: true,
                        },
                    ],
                },
            ],
        },
        {
            path: '/invite/:token',
            name: 'Invite',
            element: <Invite />,
        },
        {
            path: '/terms-and-conditions',
            name: 'TermsAndConditions',
            element: <TermsAndConditions />,
        },
        {
            path: '/privacy-policy',
            name: 'Invite',
            element: <PrivacyPolicy />,
        },
        {
            name: 'Skeleton',
            element: (
                <ProtectedRoute user={user}>
                    <Skeleton />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: 'home',
                    name: 'HomePage',
                    element: <DashboardView />,
                    index: true,
                },
                {
                    path: 'customs-declarations/:country/:internalType/products/:type',
                    name: 'Products',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <ProductTemplatesDashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/:country/:internalType/products/:type/new',
                    name: 'Add Product',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <AddEditProductTemplate />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/:country/:internalType/products/:type/:id',
                    name: 'Edit Product',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <AddEditProductTemplate />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/:country/:type/archived',
                    name: 'Customs declaration',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <DeclarationDashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/:country/:type',
                    name: 'Customs declaration',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <DeclarationDashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/uk/archive/view',
                    name: 'Uk archive view',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <UkArchiveView />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'customs-declarations/ireland/archive/view',
                    name: 'Ireland archive view',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <IrelandArchiveView />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'invoice-upload/:country/:type/job/:jobId',
                    name: 'Customs declaration',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <InvoiceUpload />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'invoice-upload/:country/:type/',
                    name: 'Customs declaration',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <InvoiceUpload />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'drivers/view-map',
                    name: 'View Map',
                    element: <ViewMap />,
                },
                {
                    path: 'drivers/management',
                    name: 'Drivers Management',
                    element: <DriverManagement />,
                },
                {
                    path: 'login',
                    name: 'LoginPage',
                    element: <SignIn />,
                },
                {
                    path: 'about',
                    name: 'AboutPage',
                    element: <AboutPage />,
                },
                {
                    path: 'declarations/:declarationId',
                    name: 'declarationView',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <DeclarationView />
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: '',
                            name: 'Form',
                            element: <DeclarationForm />,
                            children: [
                                {
                                    path: '',
                                    name: 'Master Details',
                                    element: <MasterDetails />,
                                    index: true,
                                },
                                {
                                    path: 'products',
                                    name: 'Products',
                                    element: <DeclarationProductsTable />,
                                },
                                {
                                    path: 'products/:productId',
                                    name: 'Product Detail',
                                    element: <DeclarationProductView />,
                                },
                            ],
                        },
                        {
                            path: 'view-only',
                            name: 'Form Info',
                            element: <ViewOnlyTab />,
                            children: [
                                {
                                    path: '',
                                    name: 'Master Details',
                                    element: <MasterDetails />,
                                    index: true,
                                },
                                {
                                    path: 'products',
                                    name: 'Products',
                                    element: <DeclarationProductsTable />,
                                },
                                {
                                    path: 'products/:productId',
                                    name: 'Product Detail',
                                    element: <DeclarationProductView />,
                                },
                            ],
                        },
                        {
                            path: 'summary',
                            name: 'Summary',
                            element: <SummaryView />,
                            index: true,
                        },
                        {
                            path: 'documentation',
                            name: 'Documentation',
                            element: <CustomerDocuments />,
                            index: true,
                        },
                        {
                            path: 'customer-details',
                            name: 'Documentation',
                            element: <CustomerDetails />,
                            index: true,
                        },
                        {
                            path: 'notifications',
                            name: 'Notifications',
                            element: <DeclarationsNotificationsView />,
                            index: true,
                        },
                        {
                            path: 'history',
                            name: 'History',
                            element: <DeclarationHistory />,
                            children: [
                                {
                                    path: '',
                                    name: 'History Table',
                                    element: <DeclarationHistoryTable />,
                                    index: true,
                                },
                                {
                                    path: ':historyId',
                                    name: 'History Details',
                                    element: <DeclarationHistoryDetails />,
                                    children: [
                                        {
                                            path: '',
                                            name: 'Master Details',
                                            element: <MasterDetails />,
                                            index: true,
                                        },
                                        {
                                            path: 'products',
                                            name: 'Products',
                                            element: <DeclarationProductsTable />,
                                        },
                                        {
                                            path: 'products/:productId',
                                            name: 'Product Detail',
                                            element: <DeclarationProductView />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'refunds',
                            name: 'Refunds',
                            element: <Refunds />,
                            children: [
                                {
                                    path: 'remissions',
                                    name: 'Remissions',
                                    element: <RefundsRemissions />,
                                    children: [
                                        {
                                            path: '',
                                            name: 'Remissions List',
                                            element: <RefundsList />,
                                            index: true,
                                        },
                                        {
                                            path: ':remissionId',
                                            name: 'Remission',
                                            element: <RemissionDeclaration />,
                                            index: true,
                                        },
                                    ],
                                },
                                {
                                    path: 'deposits',
                                    name: 'Deposits',
                                    element: <RefundsDeposits />,
                                    children: [
                                        {
                                            path: '',
                                            name: 'Remissions List',
                                            element: <RefundsList />,
                                            index: true,
                                        },
                                        {
                                            path: ':remissionId',
                                            name: 'Remission',
                                            element: <DepositDeclaration />,
                                            index: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'declarations/:declarationId/:section',
                    name: 'declarationView',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <DeclarationView />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'settings/hmrc/confirmation',
                    name: 'Settings',
                    element: <HmrcConfirmation />,
                },
                {
                    path: 'settings',
                    name: 'Settings',
                    element: <Settings />,
                    children: [
                        {
                            path: 'personal-information',
                            name: 'PersonalInformation',
                            element: <PersonalInformation />,
                            index: true,
                        },
                        {
                            path: 'company-details',
                            name: 'CompanyDetails',
                            element: <CompanyDetails />,
                        },
                        {
                            path: 'digital-certificate',
                            name: 'DigitalCertificate',
                            element: <DigitalCertificate />,
                        },
                        {
                            path: 'change-password',
                            name: 'ChangePassword',
                            element: <ChangePassword />,
                        },
                        {
                            path: 'hmrc',
                            name: 'HMRC',
                            element: <Hmrc />,
                        },
                    ],
                },
                {
                    path: 'customers',
                    name: 'Customers',
                    element: <Customers />,
                },
                {
                    path: 'customers/archived',
                    name: 'Customers',
                    element: <Customers />,
                },
                // Commented out as part of work item #4269 | UI General | hide sections that are not working
                // ...weighbridgesRoutes(user),
                // {
                //     path: 'weighbridges/create',
                //     name: 'Weighbridges-create',
                //     element: (
                //         <ProtectedRoute
                //             user={user}
                //             allowedUsers={[
                //                 IndividualType.SUPER_ADMIN,
                //                 IndividualType.BROKER_ADMIN,
                //                 IndividualType.HAULIER_ADMIN,
                //                 IndividualType.SYSTEM_ADMIN,
                //                 IndividualType.TRADER_ADMIN,
                //             ]}
                //         >
                //             <WeighbridgesDashboard />
                //         </ProtectedRoute>
                //     ),
                // },
                {
                    path: 'templates',
                    name: 'templates',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                            ]}
                        >
                            <TemplateDashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'templates/archived',
                    name: 'templates',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                            ]}
                        >
                            <TemplateDashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'user-management',
                    name: 'UserManagement',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                            ]}
                        >
                            <UserManagement />
                        </ProtectedRoute>
                    ),
                },
                // Commented out as part of work item #4269 | UI General | hide sections that are not working
                // {
                //     path: 'user-management/new',
                //     name: 'WCOUsersCreate',
                //     element: <WCOUsers />,
                // },
                // {
                //     path: 'user-management/:userId/edit',
                //     name: 'WCOUsersEdit',
                //     element: <WCOUsers />,
                // },
                {
                    path: 'jobs',
                    name: 'Jobs',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                                IndividualType.HAULIER_ADMIN,
                            ]}
                        >
                            <Jobs />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'jobs/:jobId',
                    name: 'JobsDetails',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                                IndividualType.HAULIER_ADMIN,
                            ]}
                        >
                            <JobsDetails />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'cargo-management',
                    name: 'CargoManagement',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <CargoManagement />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'vehicles/management',
                    name: 'Vehicles',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.DRIVER,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <VehiclesManagement />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'cargo-journeys/:id',
                    name: 'Cargo Joruney',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.DRIVER,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <CargoJourneysDetails />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'cargo-journeys/:journeyId/legs/:legId',
                    name: 'Leg',
                    element: (
                        <ProtectedRoute
                            user={user}
                            allowedUsers={[
                                IndividualType.SUPER_ADMIN,
                                IndividualType.BROKER_ADMIN,
                                IndividualType.BROKER_CLERK,
                                IndividualType.SYSTEM_ADMIN,
                                IndividualType.TRADER_ADMIN,
                                IndividualType.DRIVER,
                                IndividualType.HAULIER_ADMIN,
                                IndividualType.TRADER_USER,
                            ]}
                        >
                            <LegView />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: 'help',
                    name: 'Help',
                    element: <HelpView type={DocumentationType.AIS} />,
                },
                ...eDocketRoutes,
                ...setupRoutes,
            ],
        },
        {
            path: '/mobile',
            name: 'MobileSkeleton',
            element: (
                <ProtectedRoute user={user}>
                    <MobileSkeleton />
                </ProtectedRoute>
            ),
            children: [
                {
                    name: 'MobileHomePageIndex',
                    element: <DashboardView />,
                    index: true,
                },
                {
                    path: 'home',
                    name: 'MobileHomePage',
                    element: <DashboardView />,
                },
                {
                    path: 'legs',
                    name: 'MobileLegs',
                    children: [
                        {
                            path: ':legId',
                            name: 'MobileLegView',
                            element: <MobileLegView />,
                            children: [
                                {
                                    name: 'MobileLegDetailsViewIndex',
                                    element: <MobileLegDetailsView />,
                                    index: true,
                                },
                                {
                                    path: 'details',
                                    name: 'MobileLegDetailsView',
                                    element: <MobileLegDetailsView />,
                                },
                                {
                                    path: 'map',
                                    name: 'MobileLegMapView',
                                    element: <MobileLegMapView />,
                                },
                            ],
                        },
                        // TODO remove this?
                        {
                            path: '',
                            name: 'MobileLegsView',
                            element: <Navigate to={'/mobile/home'} />,
                            index: true,
                        },
                    ],
                },
                {
                    path: 'weighings',
                    name: 'MobileWeighings',
                    children: [
                        {
                            path: ':weighingId',
                            name: 'MobileWeighingView',
                            element: <MobileWeighingView />,
                        },
                        // TODO remove this?
                        {
                            path: '',
                            name: 'MobileWeighingsView',
                            element: <Navigate to={'/mobile/home'} />,
                            index: true,
                        },
                    ],
                },
            ],
        },
    ];
};
export default routesConfig;
