import * as Yup from 'yup';

const irelandRefundTypeOfDutiesValidation = Yup.object().shape({
    unionCode: Yup.string().max(3).min(3).nullable(),
    nationalCode: Yup.string().max(3).min(3).nullable(),
});

const goodsInformationValidation = Yup.object().shape({
    combinedNomenclatureCode: Yup.string().max(8).nullable(),
    taricCode: Yup.string().max(2).min(2).nullable(),
    taricAdditionalCode: Yup.string().max(4).min(4).nullable(),
    nationalAdditionalCommodityCode: Yup.string().max(4).nullable(),
    goodsDescription: Yup.string().max(5128).nullable(),
    measurementUnit: Yup.string().max(4).nullable(),
    quantity: Yup.string().max(16).nullable(),
    currency: Yup.string().max(3).min(3).nullable(),
    amount: Yup.string().max(16).nullable(),
    typeOfDuties: Yup.array().of(irelandRefundTypeOfDutiesValidation),
});

const GoodsLocationTypeValidation = Yup.object().shape({
    identification: Yup.string().max(35).nullable(),
    additionalIdentifier: Yup.string().max(3).min(3).nullable(),
    locationTypeCode: Yup.string().max(1).min(1).nullable(),
    qualifierIdentification: Yup.string().max(1).min(1).nullable(),
    city: Yup.string().max(35).nullable(),
    countryCode: Yup.string().max(2).min(2).nullable(),
    streetAndNumber: Yup.string().max(70).nullable(),
    postcode: Yup.string().max(9).nullable(),
});

const AttachedDocumentsValidation = Yup.object().shape({
    documentType: Yup.string().max(4).min(4).nullable(),
    documentIdentifier: Yup.string().max(35).nullable(),
    documentDate: Yup.string().nullable(),
});

const validation = Yup.object().shape({
    applicationReference: Yup.string().max(22).min(22).nullable(),
    applicationDecisionCode: Yup.string().max(4).nullable(),
    signature: Yup.string().max(256).nullable(),
    totalNumberOfDocuments: Yup.string().max(3).nullable(),
    attachedDocuments: Yup.array().of(AttachedDocumentsValidation).nullable(),
    applicant: Yup.string().max(17).nullable(),
    representativeIdentification: Yup.string().max(17).nullable(),
    contactName: Yup.string().max(70).nullable(),
    contactPhone: Yup.string().max(35).nullable(),
    contactFax: Yup.string().max(35).nullable(),
    contactEmail: Yup.string().max(70).nullable(),
    date: Yup.date().nullable(),
    officeOfNotifiedDebt: Yup.string().max(8).nullable(),
    officeOfResponsibility: Yup.string().max(8).nullable(),
    goodsLocationType: GoodsLocationTypeValidation,
    mrn: Yup.string().min(18).max(18).nullable(),
    legalBasisCode: Yup.string().max(2).nullable(),
    descriptionOfGrounds: Yup.string().max(512).nullable(),
    bankDetails: Yup.string().max(512).nullable(),
    goodsInformation: Yup.array().of(goodsInformationValidation).nullable(),
    procedureCode: Yup.string().max(2).min(2).nullable(),
    tickBox: Yup.boolean().nullable(),
    dutyToRepayCurrency: Yup.string().max(3).min(3).nullable(),
    dutyToRepayAmount: Yup.string().max(18),
    additionalInformation: Yup.string().max(512).nullable(),
});

export default validation;
