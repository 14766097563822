import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import { FormModel, ValidationSchema, validators } from '../validations/validations';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import addPathPrefix from '../../../../../utils/addPathPrefix';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import useFormUtils from '../../../../../hooks/useFormUtils';

const validateNetMass = async (path: string, formModel: FormModel<any>) => {
    const netMassPath = addPathPrefix(path, 'netNetWeightMeasure');

    const netMassValue = formModel.get(netMassPath) as string;
    const grossMassValue = formModel.get(addPathPrefix(path, 'grossMassMeasure')) as string;

    if (!netMassValue || !grossMassValue) return undefined;

    if (parseFloat(netMassValue) > parseFloat(grossMassValue))
        return { [netMassPath]: 'Net mass cannot be larger than gross mass.' };
};

export type Fields = 'netNetWeightMeasure';

export const netMassBlockValidation: {
    childValidators: {
        'commodity.goodsMeasure': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.goodsMeasure': {
            childValidators: {
                netNetWeightMeasure: [validators.required(), validators.number(), validators.float(11, 3)],
            },
            selfValidators: [(path, formModel) => validateNetMass(path, formModel)],
        },
    },
};

export const netMassBlockOptionalValidation: {
    childValidators: {
        'commodity.goodsMeasure': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.goodsMeasure': {
            childValidators: {
                netNetWeightMeasure: [validators.number(), validators.float(16, 6)],
            },
            selfValidators: [(path, formModel) => validateNetMass(path, formModel)],
        },
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const NetMassBlock = ({ path = 'commodity.goodsMeasure', declarationOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '6/1', []);

    return (
        <DeclarationField pathPrefix={path} name="netNetWeightMeasure">
            {({ form, field }: FieldProps<any>) => (
                <DeclarationNumberInput
                    refNumber={ucc}
                    required={declarationOptions?.[formType]?.fieldOptions?.netNetWeightMeasure?.required}
                    label={t('goodsItems.netMass')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default NetMassBlock;
