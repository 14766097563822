import { Form, Input, Modal, ModalProps } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { ReactElement } from 'react';
import { InfoText } from './DeclarationInformation';
import { HollowButton } from './box44/Box44';

interface Props extends ModalProps {
    reason?: boolean;
    onDeclarationCancel: (data?: { reason?: string }) => Promise<void>;
    onClose: () => void;
    cancelText?: string;
}

const CancelDeclarationModal = ({
    onDeclarationCancel,
    onClose,
    cancelText,
    reason,
    ...modalProps
}: Props): ReactElement => {
    return (
        <Modal
            title={<H5>Cancel declaration</H5>}
            destroyOnClose
            onCancel={onClose}
            footer={[
                <HollowButton onClick={onClose} size="large">
                    Close
                </HollowButton>,
                <HollowButton form="cancellationForm" htmlType="submit" inverted size="large">
                    {cancelText ?? 'Send Cancellation Request'}
                </HollowButton>,
            ]}
            {...modalProps}
        >
            <InfoText>Please enter the reason for the declaration cancellation.</InfoText>
            <Form
                style={{ marginTop: '1rem', height: 180 }}
                id="cancellationForm"
                layout="vertical"
                onFinish={onDeclarationCancel}
            >
                <Form.Item name="reason" label="Reason:" rules={[{ required: true, message: 'Field is required' }]}>
                    <Input.TextArea style={{ resize: 'none' }} rows={5} placeholder="Enter reason" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CancelDeclarationModal;
