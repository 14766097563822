import { Modal, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { TextExtraSmall } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { FieldMetaProps, useFormikContext, FormikProps } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { TaxBox } from 'store/declarations/ireland/import-declaration';
import {
    getBox44FieldPath,
    h1Box44KeyNames,
    h1PathBox44Products,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import {
    handleCommodityCodeChange,
    handleVatRateChange,
    removeZeroDecimals,
} from './useHandleVatRateCommodityNationalCodeInteraction';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import taricCodeLookUp from '../../../../../../../utils/taricCodeLookUp';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import addPathPrefix from 'utils/addPathPrefix';
import { validators } from 'views/declarations/uk/export/validations/validations';

export interface GoodsInformationCardProps extends DeclarationFormCardProps {
    box44Items: ProducedDocumentsWritingOffHeader[];
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    updateTaricCode: (taricCode: string) => void;
    country: DeclarationCountry;
    detailsType: TaricDetailsType;
}

const GoodsInformationCard: FC<GoodsInformationCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByField, getH1TooltipsByRefNumber } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const mainFormik: FormikProps<any> = useFormikContext();

    const _h1PathBox44Products = useMemo(() => {
        if (template) {
            return `product.defaults.${h1PathBox44Products}`;
        } else {
            return h1PathBox44Products;
        }
    }, [template]);

    const [modal, contextHolder] = Modal.useModal();

    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : mainFormik),
        [template, templateFormik, mainFormik]
    );

    const taxBoxPath = useMemo(() => {
        const base = 'taxBox43Bis';
        if (template) return `${form}.defaults.${base}`;
        return base;
    }, [form, template]);

    const vatRate = useMemo(() => {
        const taxBox = (formik.getFieldMeta(taxBoxPath).value as TaxBox[]) || [];
        return taxBox.find((element) => element.boxTaxType === 'B00')?.boxTaxRate;
    }, [formik, taxBoxPath]);

    const dutyRate = useMemo(() => {
        const taxBox = (formik.getFieldMeta(taxBoxPath).value as TaxBox[]) || [];
        return taxBox.find((element) => element.boxTaxType === 'A00')?.boxTaxRate;
    }, [formik, taxBoxPath]);

    const box44Items = useMemo(() => {
        const box44Items = (formik.getFieldMeta(_h1PathBox44Products).value ?? {}) as Record<string, string[]>;
        return box44Items;
    }, [_h1PathBox44Products, formik]);
    const commodityConditions = useMemo(() => {
        const commodityConditions = Object.entries(box44Items)
            .filter(([_, documentIdentifiers]) => documentIdentifiers.indexOf('NAI') !== -1)
            .map(([documentType]) => documentType);
        return commodityConditions;
    }, [box44Items]);
    const handleCommodityConditionChange = (value: (string | number)[] | null) => {
        const box44ItemsCopy = { ...box44Items };
        commodityConditions.forEach((cc) => {
            delete box44ItemsCopy[cc];
        });
        value?.forEach((v) => {
            box44ItemsCopy[v] = ['NAI'];
        });
        formik.getFieldHelpers(_h1PathBox44Products).setValue(box44ItemsCopy);
    };

    const findIndexOnTaxBox = (getFieldMeta: ((name: string) => FieldMetaProps<any>) | undefined, taxType: string) => {
        if (getFieldMeta) {
            const taxBox = (getFieldMeta(taxBoxPath).value as TaxBox[]) || [];
            const index = taxBox.findIndex((element) => element.boxTaxType === taxType);
            return index !== -1 ? index : taxBox.length;
        }
    };

    const handleOnChangeFieldOnTaxBox = (value: number | null, taxType: string) => {
        const index = findIndexOnTaxBox(formik.getFieldMeta, taxType);
        const entityValue = formik.getFieldMeta(`${taxBoxPath}.${index}`).value as {};

        if (value !== null) {
            formik.getFieldHelpers(`${taxBoxPath}.${index}`).setValue({
                ...entityValue,
                boxTaxRate: value,
                boxTaxType: taxType,
            });
        } else {
            const fieldHelper = formik.getFieldHelpers(taxBoxPath);
            const fieldMeta = formik.getFieldMeta(taxBoxPath);
            const boxTaxes = (fieldMeta?.value as TaxBox[]) ?? [];
            const newBoxTaxes = boxTaxes.filter((e) => e.boxTaxType !== taxType);
            fieldHelper?.setValue(newBoxTaxes);
        }
    };

    if (formik.getFieldHelpers === undefined) {
        return <></>;
    }

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <FormInput
                    required
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.combinedNomenclatureCode`, props)}
                    refNumber="6.14"
                    label={t('commodityCode')}
                    tooltip={getH1TooltipsByRefNumberAndField('6.14', t('commodityCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    onSearchTaricCode={() => taricCodeLookUp(props.country)}
                    condensed
                />

                <FormInput
                    required
                    maxLength={2}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.taricCode`, props)}
                    refNumber="6.15"
                    label={t('goodsItems.taricCode')}
                    tooltip={getH1TooltipsByRefNumberAndField('6.15', t('goodsItems.taricCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <MultipleDeclarationField name="goodsInformation.taricAdditionalCode" max={2}>
                    {(fieldProps, controls) => {
                        return (
                            <FormInput
                                maxLength={4}
                                viewOnly={props.viewOnly}
                                refNumber="6.16"
                                label={t('comodityCodeTaricCode')}
                                tooltip={getH1TooltipsByRefNumberAndField('6.14', t('comodityCodeTaricCode'))}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                condensed
                                multipleF={controls}
                            />
                        );
                    }}
                </MultipleDeclarationField>

                <MultipleDeclarationField name="goodsInformation.nationalAdditionalCommodityCode">
                    {(fieldProps, controls) => (
                        <FormSelect
                            viewOnly={props.viewOnly}
                            refNumber="6.17"
                            label={t('nationalAdditionalCommodityCode')}
                            {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                            selectOptions={aisCodelists?.taricNationalAdditionalCode}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            tooltip={getH1TooltipsByRefNumberAndField('6.17', t('nationalAdditionalCommodityCode'))}
                            fieldEvents={{
                                onChange: (value) => {
                                    const _fieldProps = template
                                        ? {
                                              form: templateFormik,
                                              field: { name: `${form}.defaults.${fieldProps.field.name}` },
                                          }
                                        : fieldProps;

                                    _fieldProps.form
                                        ?.getFieldHelpers(
                                            addPathPrefix(template ?? `${form}.defaults`, _fieldProps.field.name)
                                        )
                                        .setValue(value);
                                    const boxRateTaxBoxRecord = `${taxBoxPath}.${findIndexOnTaxBox(
                                        formik.getFieldMeta,
                                        'B00'
                                    )}`;
                                    const boxRateFieldName = `${boxRateTaxBoxRecord}.boxTaxRate`;
                                    handleCommodityCodeChange(
                                        modal,
                                        (formik.getFieldMeta(boxRateFieldName).value as number) ?? null,
                                        value?.toString(),
                                        (newVatRate) => {
                                            const _record = formik.getFieldProps(boxRateTaxBoxRecord).value as
                                                | Record<any, any>
                                                | undefined;
                                            const record = _record ? _record : { boxTaxType: 'B00' };
                                            formik
                                                .getFieldHelpers(boxRateTaxBoxRecord)
                                                .setValue({ ...record, boxTaxRate: newVatRate });
                                        }
                                    );
                                },
                            }}
                            condensed
                            multipleF={controls}
                            codeValidation={[validators.maxLength(4)]}
                        />
                    )}
                </MultipleDeclarationField>

                <DeclarationNumberInput
                    integerNumbers
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.supplementaryUnits`, props)}
                    refNumber="6.2"
                    label={t('goodsItems.supplementaryUnits')}
                    refTooltip={getH1TooltipsByRefNumberAndField('6.2', t('goodsItems.supplementaryUnits'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    required
                    maxLength={512}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsInformation.goodsDescription`, props)}
                    refNumber="6.8"
                    label={t('goodsItems.goodsDescription')}
                    tooltip={getH1TooltipsByRefNumberAndField('6.8', t('goodsItems.goodsDescription'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationSelect
                    mode="multiple"
                    onlyKeys
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`${_h1PathBox44Products}.${commodityConditions}`, props)}
                    value={commodityConditions}
                    fieldEvents={{
                        onChange: (value: (string | number)[], option: DefaultOptionType | DefaultOptionType[]) => {
                            handleCommodityConditionChange(value);
                        },
                    }}
                    refNumber="Box 44"
                    label={t(`Commodity condition`)}
                    tooltip={getH1TooltipsByRefNumberAndField('44', t('Commodity condition 1'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={aisCodelists?.commonDocumentsType}
                    condensed
                    codeValidation={[]}
                />

                <Tooltip
                    overlay={
                        vatRate && (
                            <TextExtraSmall>
                                Please input the base amount in the respective additional taxes entry, under the card
                                Additional Conditional Information
                            </TextExtraSmall>
                        )
                    }
                >
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        specialName="B00"
                        {...getFormikProps(
                            `taxBox43Bis.${findIndexOnTaxBox(formik.getFieldMeta, 'B00')}.boxTaxRate`,
                            props
                        )}
                        label={t('goodsItems.vatRate')}
                        refTooltip={getH1TooltipsByField(t('goodsItems.vatRate'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        fieldEvents={{
                            onChange: (value) => handleOnChangeFieldOnTaxBox(value, 'B00'),
                        }}
                        condensed
                        onBlur={(event) =>
                            handleVatRateChange(
                                modal,
                                (formik.getFieldMeta(
                                    addPathPrefix(
                                        template && `${form}.defaults`,
                                        'goodsInformation.nationalAdditionalCommodityCode'
                                    )
                                ).value as string[]) ?? [],
                                removeZeroDecimals(event?.target?.value),
                                (newCommodityNationalCodes) => {
                                    formik
                                        .getFieldHelpers(
                                            addPathPrefix(
                                                template && `${form}.defaults`,
                                                'goodsInformation.nationalAdditionalCommodityCode'
                                            )
                                        )
                                        .setValue(newCommodityNationalCodes);
                                }
                            )
                        }
                    />
                </Tooltip>

                <Tooltip
                    overlay={
                        dutyRate && (
                            <TextExtraSmall>
                                Please input the base amount in the respective additional taxes entry, under the card
                                Additional Conditional Information
                            </TextExtraSmall>
                        )
                    }
                >
                    <DeclarationNumberInput
                        specialName="A00"
                        viewOnly={props.viewOnly}
                        {...getFormikProps(
                            `taxBox43Bis.${findIndexOnTaxBox(formik.getFieldMeta, 'A00')}.boxTaxRate`,
                            props
                        )}
                        fieldEvents={{
                            onChange: (value) => handleOnChangeFieldOnTaxBox(value, 'A00'),
                        }}
                        label={t('goodsItems.dutyRate')}
                        refTooltip={getH1TooltipsByField(t('goodsItems.dutyRate'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </Tooltip>

                <FormInput
                    maxLength={512}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        getBox44FieldPath({
                            path: h1PathBox44Products,
                            keyNames: h1Box44KeyNames,
                            type: 'N935',
                        }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44Products,
                                keyNames: h1Box44KeyNames,
                                type: 'N935',
                            },
                            { template, form }
                        )
                    }
                    label={t('Invoice Number')}
                    tooltip={getH1TooltipsByRefNumber('N935')}
                    refNumber="N935"
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`preference`, props)}
                    refNumber="4.17"
                    label={t('preference')}
                    selectOptions={aisCodelists?.preference}
                    tooltip={getH1TooltipsByRefNumberAndField('4.17', t('preference'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                    codeValidation={[validators.exact(3)]}
                />
            </FormCardContainer>
            {contextHolder}
        </NewFormCard>
    );
};
export default GoodsInformationCard;
