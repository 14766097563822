import { Alert as AAlert } from 'antd';
import { ReactNode } from 'react';

export interface IAlertProps {
    type?: 'success' | 'info' | 'warning' | 'error';
    closable?: boolean;
    closeText?: ReactNode;
    message: ReactNode;
    description?: ReactNode;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
    afterClose?: () => void;
    showIcon?: boolean;
    role?: string;
    style?: React.CSSProperties;
    prefixCls?: string;
    className?: string;
    banner?: boolean;
    icon?: ReactNode;
    action?: ReactNode;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    children?: any;
    onClick?: (action: any) => void;
}

const Alert = (props: IAlertProps) => (
    <AAlert {...props}>{props.children}</AAlert>
);
export default Alert;
