import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput';

type Fields = 'containerCode';

export const containerBlockValidation: {
    childValidators: {
        'goodsShipment.consignment': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment': {
            childValidators: {
                containerCode: [validators.required(), validators.number(), validators.exact(1)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ContainerBlock = ({ path = 'goodsShipment.consignment', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds } = useFormUtils();

    const ucc = useMemo(() => '7/2', []);

    if (formTypeForCds.includes('IMPORT')) {
        return (
            <DeclarationField pathPrefix={path} name="containerCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('container')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        );
    }

    return (
        <DeclarationField pathPrefix={path} name="containerCode">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    required
                    refNumber={ucc}
                    label={t('container')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={cdsCodelists?.containerCategories}
                    condensed
                    codeValidation={[validators.number(), validators.exact(1)]}
                />
            )}
        </DeclarationField>
    );
};

export default ContainerBlock;
