import { getEoriValidation } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const goodsShipmentTsdDocumentsValidation = Yup.object().shape({
    ucr: Yup.string().max(35).nullable(),
    simplifiedDeclarationDocumentWritingOff: Yup.array(
        Yup.object().shape({
            previousDocumentType: Yup.string().max(3).min(1).nullable(),
            previousDocumentIdentifier: Yup.string().max(35).min(1).nullable(),
            previousDocumentLineId: Yup.string().max(35).min(1).nullable(),
        })
    ).nullable(),
    additionalInformation: Yup.array(
        Yup.object().shape({
            additionalInformationCode: Yup.string().max(5).min(5).nullable(),
            additionalInformationText: Yup.string().max(512).min(1).nullable(),
        })
    ).nullable(),
    producedDocumentsWritingOff: Yup.array(
        Yup.object().shape({
            documentType: Yup.string().max(4).min(4).nullable(),
            documentIdentifier: Yup.string().max(35).min(1).nullable(),
        })
    ).nullable(),
});

const supplyChainActor = Yup.object().shape({
    roleCode: Yup.string().max(3).nullable(),
    traderIdentification: Yup.string().max(17).min(1).nullable(),
});

const goodsShipmentTsdPartiesValidation = Yup.object().shape({
    declarant: Yup.object().shape({
        identifier: getEoriValidation('identifier'),
    }),
    representative: Yup.object().shape({
        identifier: getEoriValidation('identifier'),
        status: Yup.string().max(1).min(1).nullable(),
    }),
});

const goodsShipmentTsdDatesPlacesValidation = Yup.object().shape({
    countryDestination: Yup.string().max(2).min(2).nullable(),
});

const goodsShipmentTsdTransportInformation = Yup.object().shape({
    departureTransportMeans: Yup.object().shape({
        identificationType: Yup.string().max(2).min(2).nullable(),
        identificationNumber: Yup.string().max(35).min(1).nullable(),
    }),
    nationality: Yup.string().max(2).min(2).nullable(),
    containerIdentificationNumber: Yup.array()
        .of(
            Yup.object()
                .shape({
                    containerIdentificationNumber: Yup.string().max(17).min(1).nullable(),
                })
                .nullable()
        )
        .nullable(),
    seal: Yup.object().shape({
        sealNumber: Yup.string().max(4).min(1).nullable(),
        sealIdentifier: Yup.array()
            .of(
                Yup.object().shape({
                    sealIdentifier: Yup.string().max(20).min(1).nullable(),
                })
            )
            .nullable(),
    }),
});

const goodsShipmentTsdValidation = Yup.object().shape({
    totalGrossMass: Yup.number().nullable(),
    presentation: Yup.object()
        .shape({
            presentationTrader: Yup.string().max(17).nullable(),
            firstEntryCustomsOffice: Yup.string().max(8).min(8).nullable(),
            activeBorderTransportMeans: Yup.object().shape({
                type: Yup.string().max(2).min(2).nullable(),
                identification: Yup.string().max(35).nullable(),
            }),
        })
        .nullable(),
    documentsAuthorisations: goodsShipmentTsdDocumentsValidation,
    datesPlaces: goodsShipmentTsdDatesPlacesValidation,
    transportInformation: goodsShipmentTsdTransportInformation,
    supplyChainActors: Yup.array().of(supplyChainActor),
});

const irelandTsdValidation = Yup.object().shape({
    customsOffices: Yup.object().shape({
        supervisingCustomsOffices: Yup.string().max(8).min(8).nullable(),
        customsOfficeOfLodgement: Yup.string().max(8).min(8).nullable(),
    }),
    parties: goodsShipmentTsdPartiesValidation,
    goodsShipment: goodsShipmentTsdValidation,
});

export default irelandTsdValidation;
