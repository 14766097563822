import Table from 'components/ui/base/table/Table';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useMemo, useReducer } from 'react';
import { HistoryUtils } from './historyUtils';
import { HistoryViewContainer, HistoryStatusContainer } from './components/styledComponents';
import { H5 } from 'components/ui/base/typography';
import { orderBy, set } from 'lodash';
import { Declaration } from 'store/declarations/declaration';
import DeclarationErrorsDrawer from 'views/declarations/common/declaration-errors-drawer/DeclarationErrorsDrawer';
import { AesNotification } from '../../../../store/declarations/ireland/export-declaration';

export type Column = {
    key?: string;
    date: string;
    user: string;
    action: string;
    description?: string;
    notificationType?: string;
    errors?: Object;
    rawMessage?: string;
};
interface ErrorsDrawer {
    drawerOpen: boolean;
    errors: Object | undefined;
    showRawData: boolean;
}

const DeclarationHistoryTable: FC<{}> = () => {
    const { declarationHistory, declaration } = useDeclarations();

    const [errorsDrawer, setErrorsDrawer] = useReducer(
        (initialState: ErrorsDrawer, newState: Partial<ErrorsDrawer>) => ({
            ...initialState,
            ...newState,
        }),
        { drawerOpen: false, errors: undefined, showRawData: false }
    );

    const getAesDescription = useCallback(
        (notification: AesNotification) => {
            if (!declaration?.ieExportDeclaration) return '';

            if (!notification?.additionalProperties?.messageName) return notification.messageType;
            // If external system has problems, additionalProperties will be null

            return `${notification.messageType} - ${notification.additionalProperties.messageName}`;
        },
        [declaration?.ieExportDeclaration]
    );

    const currentKeys = useMemo(() => {
        let keys: Column = {} as Column;

        if (
            declaration?.irelandImportDeclaration ||
            declaration?.irelandH7ImportDeclaration ||
            declaration?.entrySummaryDeclaration
        ) {
            keys = {
                date: 'notificationDate',
                action: 'type',
                description: 'message',
                user: 'user',
                errors: 'errors',
                rawMessage: 'rawMessage',
            };
        } else if (declaration?.cdsExportDeclaration) {
            keys = {
                date: 'issueDateTime',
                action: 'statusMessage',
                notificationType: 'functionCode',
                description: 'message',
                user: 'user',
                errors: 'errors',
            };
        } else if (declaration?.ieExportDeclaration) {
            keys = {
                date: 'createdAt',
                action: 'type',
                description: 'messageType',
                user: 'user',
                errors: 'errors',
                rawMessage: 'rawMessage',
            };
        }

        return keys;
    }, [
        declaration?.cdsExportDeclaration,
        declaration?.entrySummaryDeclaration,
        declaration?.irelandH7ImportDeclaration,
        declaration?.irelandImportDeclaration,
        declaration?.ieExportDeclaration,
    ]);

    const history = useMemo(() => {
        const statusHistory =
            declaration?.irelandImportDeclaration?.notifications ??
            declaration?.irelandH7ImportDeclaration?.notifications ??
            declaration?.entrySummaryDeclaration?.notifications ??
            declaration?.cdsExportDeclaration?.notifications ??
            declaration?.ieExportDeclaration?.notifications ??
            [];

        const _formHistory = declarationHistory ?? [];
        let formHistory: any[] = [];
        formHistory = _formHistory.map((h, i) => {
            return {
                user: h.creatorName,
                key: `form-history-${i}`,
                [currentKeys.action]: h.type,
                [currentKeys.date]: h.createdAt,
            };
        }) as Column[];

        const history = [
            ...statusHistory.map((h, i) => ({
                ...h,
                key: `status-history-${i}`,
                user: 'Customs',
                errors: hasErrors(h)
                    ? set({}, getCurrentDeclarationNotificationsPath(declaration) ?? '', [h])
                    : undefined,
                ...(declaration?.ieExportDeclaration && { messageType: getAesDescription(h as AesNotification) }),
            })),
            ...formHistory,
        ];

        return orderBy(history, currentKeys.date, 'desc');
    }, [currentKeys.action, currentKeys.date, declaration, declarationHistory, getAesDescription]);

    const openErrorDrawer = ({ errors, showRawData }: { errors: Object; showRawData: boolean }) => {
        setErrorsDrawer({ errors, drawerOpen: true, showRawData });
    };
    const closeErrorDrawer = () => {
        setErrorsDrawer({ errors: undefined, drawerOpen: false });
    };

    const historyColumns = useMemo(
        () => HistoryUtils.columns.statusHistory(currentKeys, openErrorDrawer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentKeys]
    );

    return (
        <HistoryViewContainer>
            <HistoryStatusContainer>
                <H5>Status History</H5>
                <Table
                    pagination={false}
                    columns={historyColumns}
                    dataSource={history}
                    onRow={() => {
                        return {
                            'data-testid': `status-history-table-row`,
                            onClick: () => {},
                        };
                    }}
                />
            </HistoryStatusContainer>
            <DeclarationErrorsDrawer
                visible={errorsDrawer.drawerOpen}
                onClose={closeErrorDrawer}
                declaration={errorsDrawer.errors as any}
                showRawData={errorsDrawer.showRawData}
            />
        </HistoryViewContainer>
    );
};

const getCurrentDeclarationNotificationsPath = (declaration: Declaration | undefined): string | undefined => {
    if (!declaration) return undefined;
    if (declaration.irelandImportDeclaration) {
        return 'irelandImportDeclaration.notifications';
    } else if (declaration.irelandH7ImportDeclaration) {
        return 'irelandH7ImportDeclaration.notifications';
    } else if (declaration.entrySummaryDeclaration) {
        return 'entrySummaryDeclaration.notifications';
    } else if (declaration.cdsExportDeclaration) {
        return 'cdsExportDeclaration.notifications';
    } else if (declaration.ieExportDeclaration) {
        return 'ieExportDeclaration.notifications';
    } else {
        throw new Error('Can"t get declaration type');
    }
};
const hasErrors = (notification: any | undefined) => {
    return (
        !!notification?.submissionErrors?.length ||
        !!notification?.validationMessages?.length ||
        !!notification?.notification?.functionalError?.length ||
        !!notification?.notification?.xmlError?.length
    );
};

export default DeclarationHistoryTable;
