import CheckCircleIcon from 'assets/check-circle.svg';
import { FC } from 'react';
import { InvitePageProps, OnboardingSection } from 'views/authentication/invite/Invite';
import {
    ButtonDiv,
    ContentDiv,
    ContinueButton,
    DivDDFeatures,
    DivRtl,
    Image,
    RowDiv,
    Subtitle,
    Text,
} from 'views/authentication/invite/sections/common/CheckList.styles';

interface TraderFeaturesProps extends InvitePageProps {}

const TraderFeatures: FC<TraderFeaturesProps> = ({ onChangePage }) => {
    return (
        <>
            <DivDDFeatures>
                <Subtitle>Ddispatch features...</Subtitle>
            </DivDDFeatures>
            <ContentDiv>
                <RowDiv>
                    <Text>Manage Inventory</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Request Cost Estimates</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Request Cost Estimates</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Manage Customers</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
            </ContentDiv>
            <ButtonDiv>
                <ContinueButton
                    type="primary"
                    onClick={() => {
                        onChangePage(OnboardingSection.INTRO);
                    }}
                    size="large"
                >
                    Continue
                </ContinueButton>
            </ButtonDiv>
        </>
    );
};
export default TraderFeatures;
