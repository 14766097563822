import styled from 'styled-components';
import { Layout } from 'antd';
import { colors } from 'theme';

export const StyledLayout = styled(Layout)`
    height: 100vh;
    background: ${colors.lightGrey};
`;

export const StyledContent = styled(Layout.Content)`
    padding: 1.9rem 2.8rem 1.9rem;
    overflow: auto;
    background-color: ${colors.white};
`;
