import { useDispatch } from 'react-redux';
import { doListExchanges } from 'store/exchanges/action';
import { useAppSelector } from '../config/hooks';

const useExchanges = () => {
    const exchanges = useAppSelector((state) => state.exchanges);
    const dispatch = useDispatch();
    
    const listExchanges = async () => {
        return await dispatch(doListExchanges());
    }

    return {
        ...exchanges,
        listExchanges
    };
}

export default useExchanges;