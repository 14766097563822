import { FC } from 'react';
import { StyledStatus, StyledStatusTag } from './StatusTag.styles';

const StatusTag: FC<{ color: string; status: string; dataTestId?: string }> = ({ color, status, dataTestId }) => {
    return (
        <StyledStatus data-testid={dataTestId}>
            <StyledStatusTag color={color} />
            <span style={{ whiteSpace: 'normal', overflowWrap: 'break-word', wordWrap: 'break-word' }}>{status}</span>
        </StyledStatus>
    );
};

export default StatusTag;
