import { EyeInvisibleOutlined, EyeOutlined, FieldTimeOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { TextSmallBold } from 'components/ui/base/typography';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { dateStandardFormat } from 'core/utils/date';
import { format, formatDistanceToNowStrict, isAfter, parseISO } from 'date-fns';
import useCustomers from 'hooks/useCustomers';
import useDeclarationHeader from 'hooks/useDeclarationHeader';
import useIndividuals from 'hooks/useIndividuals';
import { FC, useMemo } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { IrelandNotificationType } from 'store/declarations/ireland/ireland-notification';
import { JobResponse } from 'store/jobs/job';
import { DeclarationExternalEntity } from '../../../store/declarations/enums/common/declaration-external-entity';
import AmendmentInfo from './AmendmentInfo';
import {
    Col,
    CollapseButton,
    DivHideButtom,
    ErrorButton,
    FieldGroupDiv,
    FieldGroupRemarks,
    FieldsLabels,
    FieldsText,
    HeaderTitle,
    HideButton,
    MainDiv,
    ReverseCol,
    RoutingStatus,
    SectionButton,
    StatusFieldsLabels,
    StyledInfoButton,
    TimeDiv,
    Title,
} from './FormHeader.styles';

export interface HeaderParams {
    title: string;
    declaration: Declaration;
    job?: JobResponse;
    activeCustomer: () => void;
    showErrors?: Function;
    remarks?: string;
    internalEntity?: string;
}

const FormHeader: FC<HeaderParams> = ({
    title,
    declaration,
    job,
    activeCustomer,
    showErrors,
    remarks,
    internalEntity,
}) => {
    const { customer } = useCustomers({ customerId: declaration?.customerId });
    const { individual } = useIndividuals({ individualId: declaration?.individualId });
    const { declarationHeaderCollapsed, collapseDeclarationHeader, expandDeclarationHeader } = useDeclarationHeader();

    const countryText = useMemo(
        () => (declaration?.declarationExternalEntity === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK'),
        [declaration]
    );

    const referenceNumber = useMemo(() => declaration?.referenceNumber, [declaration]);

    const createdBy = useMemo(() => {
        return individual?.fullName || '-';
    }, [individual]);

    const routingStatusField = useMemo(() => {
        if (!declaration?.routing) {
            return <></>;
        }

        return (
            <Col>
                <FieldGroupDiv>
                    <StatusFieldsLabels>Routing Status:</StatusFieldsLabels>
                    <RoutingStatus $status={declaration.routing}></RoutingStatus>
                </FieldGroupDiv>
            </Col>
        );
    }, [declaration]);

    // TODO improve this
    const releasedInfo = useMemo(() => {
        const getStandardFormatDate = (date: string): string => {
            const acceptedDate = parseISO(date);
            return dateStandardFormat(acceptedDate);
        };

        if (declaration?.mrn || declaration?.acceptanceDate) {
            return (
                <Col>
                    {declaration?.mrn && (
                        <FieldGroupDiv>
                            <FieldsLabels>MRN: </FieldsLabels>
                            <FieldsText>{declaration?.mrn}</FieldsText>
                        </FieldGroupDiv>
                    )}
                    {declaration?.acceptanceDate && (
                        <FieldGroupDiv>
                            <FieldsLabels>Acceptance date: </FieldsLabels>
                            <FieldsText>{getStandardFormatDate(declaration.acceptanceDate)}</FieldsText>
                        </FieldGroupDiv>
                    )}
                </Col>
            );
        }
    }, [declaration]);

    // TODO improve this
    const estimatedTimeOfArrival = useMemo(() => {
        const estimatedTimeOfArrival =
            declaration?.irelandImportDeclaration?.goodsShipment?.estimatedTimeOfArrival ||
            declaration?.irelandH7ImportDeclaration?.goodsShipment?.estimatedTimeOfArrival;

        const isAfterToday = () => {
            if (estimatedTimeOfArrival) {
                const today = new Date();
                const date = parseISO(estimatedTimeOfArrival);
                return isAfter(today, date);
            }
            return false;
        };

        if (estimatedTimeOfArrival) {
            if (isAfterToday()) {
                return <></>;
            } else {
                const date = parseISO(estimatedTimeOfArrival);
                const dateFormatted = format(date, "dd MMMM 'of' yyyy");
                const distance = formatDistanceToNowStrict(date);
                return (
                    <>
                        <TextSmallBold>Deadline: {dateFormatted}</TextSmallBold>
                        <TimeDiv>
                            <FieldTimeOutlined />
                            <TextSmallBold>{distance}</TextSmallBold>
                        </TimeDiv>
                    </>
                );
            }
        }
        return <></>;
    }, [declaration]);

    const declarationErrors = useMemo(() => {
        if (declaration?.status === DeclarationStatus.REJECTED || declaration?.status === DeclarationStatus.INVALID) {
            return (
                <ErrorButton onClick={() => showErrors && showErrors()}>
                    View error messages <WarningOutlined />
                </ErrorButton>
            );
        } else {
            return <></>;
        }
    }, [declaration, showErrors]);

    const invalidationJustification = useMemo(() => {
        return declaration?.irelandImportDeclaration?.invalidationJustification;
    }, [declaration]);

    const remarksInfo = useMemo(() => {
        if (remarks) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Remarks: </FieldsLabels>
                        <FieldsText title={remarks}>{remarks}</FieldsText>
                    </FieldGroupRemarks>
                    {invalidationJustification && (
                        <FieldGroupDiv>
                            <FieldsLabels>Invalidation Justification: </FieldsLabels>
                            <FieldsText>{invalidationJustification}</FieldsText>
                        </FieldGroupDiv>
                    )}
                </Col>
            );
        }
        return <></>;
    }, [remarks, invalidationJustification]);

    const irelandImportH1Declaration = useMemo(() => {
        return declaration?.irelandImportDeclaration;
    }, [declaration]);

    const invalidationInfo = useMemo(() => {
        if (irelandImportH1Declaration) {
            const invalidationRequestDate = irelandImportH1Declaration?.invalidationRequestDate
                ? new Date(irelandImportH1Declaration?.invalidationRequestDate)
                : undefined;
            return (
                <Col>
                    {invalidationRequestDate && (
                        <FieldGroupRemarks>
                            <FieldsLabels>Invalidation date: </FieldsLabels>
                            <FieldsText>{`${invalidationRequestDate.toLocaleDateString()} ${invalidationRequestDate.toLocaleTimeString()}`}</FieldsText>
                        </FieldGroupRemarks>
                    )}
                    {irelandImportH1Declaration?.invalidationReasonText && (
                        <FieldGroupDiv>
                            <FieldsLabels>Invalidation Reason: </FieldsLabels>
                            <FieldsText>{irelandImportH1Declaration?.invalidationReasonText}</FieldsText>
                        </FieldGroupDiv>
                    )}
                </Col>
            );
        }
        return <></>;
    }, [irelandImportH1Declaration]);

    const insufficientFundsInfo = useMemo(() => {
        if (irelandImportH1Declaration) {
            const notifications = irelandImportH1Declaration.notifications ?? [];
            const insuficcientFundsNotification = notifications.find(
                (notification) => notification.type === IrelandNotificationType.INSUFFICIENT_FUNDS
            );
            if (insuficcientFundsNotification?.insufficientFundsValue) {
                return (
                    <Col>
                        <FieldGroupRemarks>
                            <FieldsLabels>Insuficcient Funds: </FieldsLabels>
                            <FieldsText>{insuficcientFundsNotification.insufficientFundsValue}</FieldsText>
                        </FieldGroupRemarks>
                    </Col>
                );
            }
        }
        return <></>;
    }, [irelandImportH1Declaration]);

    const releaseRejectionReason = useMemo(() => {
        if (irelandImportH1Declaration?.releaseRejectionReason) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Release rejection reason: </FieldsLabels>
                        <FieldsText>{irelandImportH1Declaration.releaseRejectionReason}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }
        return <></>;
    }, [irelandImportH1Declaration]);

    const remissionRefundApplicationRejected = useMemo(() => {
        const declarationType = declaration?.irelandImportDeclaration ?? declaration?.irelandH7ImportDeclaration;

        if (
            declarationType?.remissionRefundApplicationRejected &&
            declarationType?.remissionRefundApplicationRejectionReason
        ) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Remission refund application rejected: </FieldsLabels>
                    </FieldGroupRemarks>
                    <FieldGroupRemarks>
                        <FieldsLabels>Remission refund application rejection reason: </FieldsLabels>
                        <FieldsText>{declarationType.remissionRefundApplicationRejectionReason}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }
        return <></>;
    }, [declaration?.irelandH7ImportDeclaration, declaration?.irelandImportDeclaration]);

    const depositRefundApplicationRejected = useMemo(() => {
        if (
            irelandImportH1Declaration?.invalidDepositRefundRejected &&
            irelandImportH1Declaration?.depositRefundRequestReasonNotApproved
        ) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Invalid deposit refund rejected: </FieldsLabels>
                    </FieldGroupRemarks>
                    <FieldGroupRemarks>
                        <FieldsLabels>Deposit refund request reason not approved: </FieldsLabels>
                        <FieldsText>{irelandImportH1Declaration.depositRefundRequestReasonNotApproved}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }
        return <></>;
    }, [irelandImportH1Declaration]);

    const amendmentInfo = useMemo(() => {
        const declarationType =
            declaration.irelandImportDeclaration ??
            declaration.irelandH7ImportDeclaration ??
            declaration.entrySummaryDeclaration;

        return <AmendmentInfo amendableDeclaration={declarationType} />;
    }, [declaration]);

    return (
        <>
            <MainDiv $collapsed={declarationHeaderCollapsed}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 16 }}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={3} style={{ height: '100%' }}>
                        <HeaderTitle>
                            <Title>{title}</Title>
                        </HeaderTitle>
                    </Col>
                    <ReverseCol xs={24} sm={24} md={24} lg={14} xl={14} xxl={21} style={{ height: '100%' }}>
                        <HeaderTitle>
                            <SectionButton>
                                {declarationErrors}
                                {estimatedTimeOfArrival}
                            </SectionButton>
                            {declarationHeaderCollapsed && (
                                <>
                                    <CollapseButton size="small" onClick={() => expandDeclarationHeader()}>
                                        <EyeOutlined /> Show Info
                                    </CollapseButton>
                                </>
                            )}
                        </HeaderTitle>
                    </ReverseCol>
                </Row>
                {!declarationHeaderCollapsed && (
                    <Row>
                        <Col span={22}>
                            <Row gutter={{ xs: 4, sm: 4, md: 1, lg: 1 }}>
                                <Col>
                                    <FieldGroupDiv>
                                        <FieldsLabels>Ref:</FieldsLabels>
                                        <FieldsText>{referenceNumber}</FieldsText>
                                    </FieldGroupDiv>
                                    <FieldGroupDiv title={`Job Id: ${job?.referenceId}`}>
                                        <FieldsLabels>Job Id:</FieldsLabels>
                                        <FieldsText>{job?.referenceId}</FieldsText>
                                    </FieldGroupDiv>
                                </Col>
                                <Col>
                                    <FieldGroupDiv>
                                        <FieldsLabels>
                                            {internalEntity} / {countryText}
                                        </FieldsLabels>
                                    </FieldGroupDiv>
                                    <FieldGroupDiv>
                                        <StyledInfoButton
                                            size="small"
                                            type="link"
                                            icon={<UserOutlined />}
                                            onClick={() => activeCustomer && activeCustomer()}
                                        >
                                            {customer?.name}
                                        </StyledInfoButton>
                                    </FieldGroupDiv>
                                </Col>

                                <Col>
                                    <FieldGroupDiv>
                                        <StatusFieldsLabels>General Status:</StatusFieldsLabels>
                                        <DeclarationStatusTag status={declaration.status} />
                                    </FieldGroupDiv>
                                    <FieldGroupDiv>
                                        <FieldsLabels>Created by: </FieldsLabels>
                                        <FieldsText>{createdBy}</FieldsText>
                                    </FieldGroupDiv>
                                </Col>
                                {releasedInfo}
                                {routingStatusField}
                                {remarksInfo}
                                {invalidationInfo}
                                {releaseRejectionReason}
                                {amendmentInfo}
                                {insufficientFundsInfo}
                                {remissionRefundApplicationRejected}
                                {depositRefundApplicationRejected}
                            </Row>
                        </Col>

                        <ReverseCol span={2}>
                            <DivHideButtom>
                                <HideButton size="small" onClick={() => collapseDeclarationHeader()}>
                                    <EyeInvisibleOutlined /> Hide Info
                                </HideButton>
                            </DivHideButtom>
                        </ReverseCol>
                    </Row>
                )}
            </MainDiv>
        </>
    );
};

export default FormHeader;
