import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';

const OtherItemDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { template } = useTemplateContext();

    return (
        <NewFormCard title={t('Other Details')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`statisticalValue`, props)}
                    refNumber="8.6"
                    disabled={template}
                    tooltip={template && 'Statistical value will be calculated separately for each declaration.'}
                    label={t('statisticalValue')}
                    refTooltip={getH1TooltipsByRefNumberAndField('8.6', t('statisticalValue'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default OtherItemDetailsCard;
