import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    doClearProductTemplateFormErrors,
    doSetProductTemplateFormErrors,
} from '../store/product-template-form-errors/action';
import { useAppSelector } from '../config/hooks';
import { ProductTemplateFormErrors } from '../store/product-template-form-errors/ProductTemplateFormErrors';

const useProductTemplateFormErrors = () => {
    const dispatch = useDispatch();
    const productTemplateErrors = useAppSelector((state) => state.productTemplateFormErrors);

    const hasFormProductTemplateErrors = useMemo(
        () => !isEmpty(productTemplateErrors.template),
        [productTemplateErrors.template]
    );

    const setFormProductTemplateErrors = (errors: ProductTemplateFormErrors) => {
        dispatch(doSetProductTemplateFormErrors(errors));
    };

    const clearFormProductTemplateErrors = () => {
        dispatch(doClearProductTemplateFormErrors());
    };

    return {
        productTemplateErrors,
        setFormProductTemplateErrors,
        clearFormProductTemplateErrors,
        hasFormProductTemplateErrors,
    };
};

export default useProductTemplateFormErrors;
