import { FormikProps, FormikProvider } from 'formik';
import useProducts from 'hooks/useProducts';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import TaricCodesCard, { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { getIrelandEnsTaricCode, updateIrelandEnsTaricCode } from 'views/declarations/common/taric-codes/utils';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import ProductInfoCard from './cards/products/ProductInfoCard';

import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { useTranslation } from 'react-i18next';
import {
    additionalInformation,
    borderTransport,
    containers,
    productPackages,
} from 'views/declarations/ireland/ens/validation/irelandEnsValidation';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import addPathPrefix from 'utils/addPathPrefix';
import { ProductContext } from '../../common/declaration-view/ProductContext';

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
}

const EnsProductSection: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll }) => {
    const { t } = useTranslation('form');

    const { productId, declarationId } = useParams<string>();
    const { getIrelandEnsProduct } = useProducts();
    const { setProductId } = useContext(ProductContext);

    useEffect(() => {
        const fetchProduct = async (declarationId: string, productId: string) => {
            const product = await getIrelandEnsProduct(productId, declarationId);
            setProductId?.(productId);
            formik.setValues(product);
        };

        if (declarationId && productId && productId !== 'new') {
            const { values } = formik;

            if (values.id !== productId) {
                fetchProduct(declarationId, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId]);

    const totalCards = useMemo(() => 10, []);

    return (
        <FormikProvider value={formik}>
            <TaricCodesCard
                expandAll={expandAll}
                keyCard={`taric-codes-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalCards}
                label="Combined Nomenclature"
                path="commodity.combinedNomenclature"
                cardNumber={1}
                updateTaricCode={(code) => updateIrelandEnsTaricCode(formik, code)}
                country={DeclarationCountry.IRELAND}
                value={getIrelandEnsTaricCode(formik)}
                detailsType={TaricDetailsType.IMPORT}
                required
            />

            <ProductInfoCard
                expandAll={expandAll}
                keyCard={`product-info-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                cardNumber={2}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                condensed
                parties={[
                    {
                        path: 'consignor',
                        header: 'Consignor',
                        refNumber: '2.0',
                    },
                    {
                        path: 'consignee',
                        header: 'Consignee',
                        refNumber: '8.0',
                    },
                    {
                        path: 'notifyParty',
                        header: 'Notify party',
                        refNumber: undefined,
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                labels={{
                    eori: 'tin',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                expandAll={expandAll}
            />

            <MultipleItemsCard
                title={t('containers')}
                keyCard={`enspackage-card`}
                viewOnly={viewOnly}
                path="transportEquipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={6}
                expandAll={expandAll}
                initialValues={{ equipmentIdentification: '' }}
                validationSchema={containers}
                list={(container) => [
                    { field: t('equipmentIdentification'), value: container.equipmentIdentification },
                ]}
                condensed
                form={(props, path) => (
                    <Row gutter={12}>
                        <Col xs={24}>
                            <DeclarationNumberInput
                                required
                                maxLength={11}
                                integerNumbers
                                {...getFormikProps(addPathPrefix(path, `equipmentIdentification`), props)}
                                label={t('containerNumber')}
                                refTooltip={t('defaultTooltip')}
                            />
                        </Col>
                    </Row>
                )}
            />

            <MultipleItemsCard
                title={t('Identity Of Means Of Transport At Border')}
                keyCard={`transport-boarder-card`}
                viewOnly={viewOnly}
                path="borderTransportMeans"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={7}
                expandAll={expandAll}
                initialValues={{ nationalityCrossingBorder: '', identityCrossingBorder: '' }}
                validationSchema={borderTransport}
                list={(_package) => [
                    { field: t('nationalityCrossingBorder'), value: _package.nationalityCrossingBorder },
                    { field: t('identityCrossingBorder'), value: _package.identityCrossingBorder },
                ]}
                condensed
                form={(props, path) => (
                    <Row gutter={16}>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <CountrySelect
                                {...getFormikProps(addPathPrefix(path, `nationalityCrossingBorder`), props)}
                                label={t('nationalityCrossingBorder')}
                                tooltip={t('defaultTooltip')}
                            />
                        </Col>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <FormInput
                                required
                                maxLength={31}
                                {...getFormikProps(addPathPrefix(path, `identityCrossingBorder`), props)}
                                label={t('identityCrossingBorder')}
                                refNumber="21.1"
                                refClicked={(ref) => toggleHelp?.(ref)}
                                tooltip="Click for more information"
                            />
                        </Col>
                    </Row>
                )}
            />

            <MultipleItemsCard
                title={t('Packages')}
                keyCard={`enspackage-card`}
                viewOnly={viewOnly}
                path="goodsPackaging"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={8}
                expandAll={expandAll}
                initialValues={{ marks: '', type: '', numberOfPackages: '', numberOfPieces: '' }}
                validationSchema={productPackages}
                list={(_package) => [
                    { field: t('marksNumbersOfPackages'), value: _package.marks },
                    { field: t('kindOfPackages'), value: _package.type },
                    { field: t('numberOfPackages'), value: _package.numberOfPackages },
                    { field: t('numberOfPieces'), value: _package.numberOfPieces },
                ]}
                condensed
                form={(props, path) => (
                    <Row gutter={16}>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <FormInput
                                maxLength={140}
                                {...getFormikProps(addPathPrefix(path, `marks`), props)}
                                label={t('marksNumbersOfPackages')}
                                tooltip={t('defaultTooltip')}
                            />
                        </Col>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <FormInput
                                required
                                maxLength={2}
                                {...getFormikProps(addPathPrefix(path, `type`), props)}
                                label={t('kindOfPackages')}
                                tooltip={t('defaultTooltip')}
                            />
                        </Col>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <DeclarationNumberInput
                                integerNumbers
                                {...getFormikProps(addPathPrefix(path, `numberOfPackages`), props)}
                                refNumber="6.5"
                                label={t('numberOfPackages')}
                                refTooltip={t('defaultTooltip')}
                            />
                        </Col>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <DeclarationNumberInput
                                integerNumbers
                                {...getFormikProps(addPathPrefix(path, `numberOfPieces`), props)}
                                refNumber="6.5"
                                label={t('numberOfPieces')}
                                refTooltip={t('defaultTooltip')}
                            />
                        </Col>
                    </Row>
                )}
            />

            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard={`additional-information-card`}
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={10}
                expandAll={expandAll}
                initialValues={{ code: '', identifier: '' }}
                validationSchema={additionalInformation}
                list={(additionalInformation) => [
                    { field: t('identifier'), value: additionalInformation.code },
                    { field: t('text'), value: additionalInformation.identifier },
                ]}
                condensed
                form={(props, path) => (
                    <Row gutter={16}>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <FormInput
                                required
                                maxLength={17}
                                {...getFormikProps(addPathPrefix(path, `code`), props)}
                                label={t('additionalInformationIdentifier')}
                                tooltip={t('defaultTooltip')}
                            />
                        </Col>
                        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                            <FormInput
                                maxLength={255}
                                {...getFormikProps(addPathPrefix(path, `identifier`), props)}
                                label={t('additionalInformationText')}
                                tooltip={t('defaultTooltip')}
                            />
                        </Col>
                    </Row>
                )}
            />
        </FormikProvider>
    );
};
export default EnsProductSection;
