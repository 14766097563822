import { ReactElement } from 'react';
import EoriBlock from 'views/declarations/uk/export/blocks/customer/EoriBlock';
import { FormCardContainer } from '../../cards/NewFormCard';
import PartiesEoriFormProps from '../PartiesEoriFormProps';

const PartiesEoriForm = ({ party, refNumber, eoriRequired, condensed, onBlur }: PartiesEoriFormProps): ReactElement => {
    return (
        <FormCardContainer>
            <EoriBlock
                path={party.path}
                fieldOptions={{
                    id: { required: eoriRequired, columnSpan: 24 },
                    functionCode: { show: party.hasRepresentativeStatus },
                }}
                options={{ ucc: refNumber ?? '' }}
                condensed={condensed}
                onBlur={onBlur}
            />
        </FormCardContainer>
    );
};

export default PartiesEoriForm;
