import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'typeCode';

export const typeOfPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        typeCode: [validators.required(), validators.maxLength(2)],
    },
};

export const cdsImportTypeOfPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        typeCode: [validators.required(), validators.exact(2)],
    },
};

interface Props extends BlockProps<Fields> {}

const TypeOfPackagesBlock = ({ path = 'packaging' }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '6/9', []);

    return (
        <DeclarationField pathPrefix={path} name="typeCode">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    required
                    label={t('packages.typeOfPackages')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={cdsCodelists?.packageTypes}
                    condensed
                    codeValidation={[validators.exact(2)]}
                />
            )}
        </DeclarationField>
    );
};

export default TypeOfPackagesBlock;
