import { FC, useMemo, useState } from 'react';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { irelandNotificationTypeLabel } from 'store/declarations/ireland/ireland-notification';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import {
    NotificationCode,
    NotificationDescription,
    NotificationDescriptionCode,
    NotificationMessage,
    NotificationText,
    StyledDownOutlined,
    StyledNotificationRow,
} from './DeclarationNotifications.style';

export const NotificationRow: FC<{ notification: IrelandEnsNotification }> = ({ notification }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const date = useMemo(() => {
        if (notification.notificationDate) {
            const dateTime = new Date(notification.notificationDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.notificationDate]);

    const basicNotification = useMemo(() => {
        return (
            <>
                {notification.message && (
                    <NotificationDescriptionCode>
                        Message:
                        <NotificationText>{notification.message}</NotificationText>
                    </NotificationDescriptionCode>
                )}
            </>
        );
    }, [notification]);

    const notificationDescription = useMemo(() => {
        switch (notification.type) {
            default:
                return basicNotification;
        }
    }, [notification, basicNotification]);

    return (
        <StyledNotificationRow>
            <NotificationMessage onClick={() => setShowDescription(!showDescription)}>
                <StyledDownOutlined expanded={showDescription} />
                <NotificationCode>
                    Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationCode>
                <NotificationCode>
                    Type:
                    <NotificationText>
                        {toTitleCase(
                            irelandNotificationTypeLabel.find((e) => e.id === notification.type)?.value ?? '-'
                        )}
                    </NotificationText>
                </NotificationCode>
            </NotificationMessage>
            {showDescription ? <NotificationDescription>{notificationDescription}</NotificationDescription> : <></>}
        </StyledNotificationRow>
    );
};

export default NotificationRow;
