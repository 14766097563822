import { createContext, useContext, useEffect } from 'react';

export interface FieldData {
    path: string;
    required?: boolean;
    hidden?: boolean;
}

export interface CardContextProps {
    registerField?: (fieldData: FieldData) => void;
    registerSection?: (fieldsData: FieldData[]) => void;
}
export const CardContext = createContext<CardContextProps>({});
export const useCardContext = () => useContext(CardContext);

export const useRegisterField = (fieldData: Partial<FieldData>) => {
    const { registerField } = useCardContext();

    useEffect(() => {
        if (fieldData.path) registerField?.(fieldData as FieldData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useRegisterSection = (fieldsData: FieldData[]) => {
    const { registerSection } = useCardContext();

    useEffect(() => {
        registerSection?.(fieldsData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
