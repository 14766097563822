import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationsOutletContext, useWCOOutletContext } from '../WCO';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';

const { ScreenTitle, ScreenSubtitle, ButtonContainer, ScreenTitleSubtitleContainer } = SetupStyledComponents;

const WCOLocations = () => {
    const { t } = useTranslation('weighbridge');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const navigate = useNavigate();

    const { formik } = useWCOOutletContext<LocationsOutletContext>();

    const handleAddLocation = () => {
        formik.setFieldValue('locations', [...formik.values.locations, { locationName: '', address: '' }]);
    };

    const handleRemoveLocation = (index: number) => {
        formik.values.locations.splice(index, 1);
        formik.setFieldValue('locations', formik.values.locations);
    };

    // Setting the route in the header
    useEffect(() => {
        setBreadcrumbRoutes([
            { breadcrumbName: t('breadcrumbs.setup'), path: '' },
            { breadcrumbName: t('breadcrumbs.companyRegistration'), path: '' },
            { breadcrumbName: t('breadcrumbs.locations'), path: '' },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <ScreenTitleSubtitleContainer>
                <ScreenTitle>{t('locations.title')}</ScreenTitle>
                <ScreenSubtitle>{t('locations.subtitle')}</ScreenSubtitle>
            </ScreenTitleSubtitleContainer>
            <Row gutter={[30, 15]}>
                {/* Row 1 */}
                <Col xl={24}>
                    <FormInput
                        label={t('locations.companyName.label')}
                        required
                        fieldProps={formik.getFieldProps('companyName')}
                        fieldMeta={formik.getFieldMeta('companyName')}
                    />
                </Col>
                {/* Row 2 */}
                {formik.values.locations.map((_, index: number) => (
                    <>
                        <Col xl={index === 0 ? 12 : 11}>
                            <FormInput
                                label={t('locations.locationName.label')}
                                required
                                fieldProps={formik.getFieldProps(`locations[${index}].locationName`)}
                                fieldMeta={formik.getFieldMeta(`locations[${index}].locationName`)}
                            />
                        </Col>
                        <Col xl={index === 0 ? 12 : 11}>
                            <FormInput
                                label={t('locations.locationAddress.label')}
                                required
                                fieldProps={formik.getFieldProps(`locations[${index}].address`)}
                                fieldMeta={formik.getFieldMeta(`locations[${index}].address`)}
                            />
                        </Col>

                        {index !== 0 && (
                            <Col xl={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    type="primary"
                                    shape="circle"
                                    onClick={() => handleRemoveLocation(index)}
                                    icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                                />
                            </Col>
                        )}
                    </>
                ))}
            </Row>

            <Button style={{ marginTop: '2rem' }} type="primary" onClick={handleAddLocation}>
                {t('locations.addLocation')}
            </Button>

            <ButtonContainer>
                <Button onClick={() => navigate(-1)}>{t('back')}</Button>
                <Button disabled={!isEmpty(formik.errors)} type="primary" onClick={() => navigate('../first-admin')}>
                    {t('next')}
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default WCOLocations;
