import { FC } from 'react';
import Icon from '@ant-design/icons';

const SearchCrossSvg = () => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M40.6172 0C25.2426 0 12.7343 12.5082 12.7343 27.8828C12.7343 34.6158 15.1332 40.7994 19.1212 45.6232L0.5 64.2444L4.25558 68L22.8768 49.3782C27.7006 53.3668 33.8842 55.7657 40.6172 55.7657C55.9918 55.7657 68.5 43.2574 68.5 27.8828C68.5 12.5082 55.9918 0 40.6172 0ZM40.6172 50.4547C28.1712 50.4547 18.0453 40.3288 18.0453 27.8828C18.0453 15.4369 28.1712 5.31094 40.6172 5.31094C53.0631 5.31094 63.1891 15.4369 63.1891 27.8828C63.1891 40.3288 53.0631 50.4547 40.6172 50.4547ZM53.2919 18.9637L44.3727 27.8828L53.2919 36.802L49.5363 40.5576L40.6172 31.6384L31.698 40.5576L27.9424 36.802L36.8616 27.8828L27.9424 18.9637L31.698 15.2081L40.6172 24.1273L49.5363 15.2081L53.2919 18.9637Z"
            fill="#969696"
        />
    </svg>
);

const SearchCrossIcon: FC<any> = (props) => <Icon component={SearchCrossSvg} {...props} />;

export default SearchCrossIcon;
