import { useCallback } from 'react';

const RecaptchaV2 = () => {
    const setRef = useCallback(
        (el: HTMLDivElement) =>
            window.grecaptcha.ready(() =>
                window.grecaptcha.render(el, {
                    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2,
                })
            ),
        []
    );

    return <div ref={setRef}></div>;
};

export default RecaptchaV2;
