export enum IrelandDeclarationName {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    H7 = 'H7',
}

export const irelandDeclarationNameLabels = [
    {
        key: IrelandDeclarationName.H1,
        value: '(H1) Free Circulation',
    },
    {
        key: IrelandDeclarationName.H2,
        value: '(H2) Customs Warehousing',
    },
    {
        key: IrelandDeclarationName.H3,
        value: '(H3) Temporary Admission',
    },
    {
        key: IrelandDeclarationName.H4,
        value: '(H4) Inward Processing ',
    },
    {
        key: IrelandDeclarationName.H5,
        value: '(H5) Introductions of Goods (SFT)',
    },
    {
        key: IrelandDeclarationName.H6,
        value: '(H6) Free Circulation Postal',
    },
    {
        key: IrelandDeclarationName.H7,
        value: '(H7) Form',
    },
] as const;
