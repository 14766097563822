import { H5, Text } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const CurrencyText = styled(H5)`
    color: ${colors.purpleBlue};
    display: block;
`;

export const TitleText = styled(H5)`
    color: ${colors.darkPurple};
    display: block;
`;

export const DoApplyText = styled(Text)`
    color: ${colors.darkPurple};
    display: block;
`;

export const ContainerH5Text = styled.div`
    text-align: center;
`;

export const ContainerText = styled.div`
    text-align: center;
    margin-top: 2.4rem;
`;
export const ContainerButtonDiv = styled.div`
    text-align: center;
`;
