import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { ProductsActions } from './actionType';
import { Eori } from './eori';

interface EoriEntities {
    [key: string]: Eori;
}

export interface EoriState {
    isLoading: boolean;
    error?: ErrorResponse;
    eori?: Eori;
    entities: EoriEntities;
}

export const eoriInitialState: Readonly<EoriState> = {
    isLoading: false,
    error: undefined,
    eori: undefined,
    entities: {},
};

const eori: Reducer<EoriState, ProductsActions> = (state = eoriInitialState, action): EoriState => {
    switch (action.type) {
        case 'CHECK_EORI_REQUEST':
            return {
                ...state,
                eori: undefined,
                isLoading: true,
            };
        case 'CHECK_EORI_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                eori: action.payload[0],
                entities: { ...state.entities, [action.payload[0].eori]: action.payload[0] },
            };
        case 'CHECK_EORI_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default eori;
