import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { IndividualActions } from './actionType';
import { Individual } from './individual';

interface IndividualEntities {
    [key: string]: Individual;
}
export interface IndividualsState {
    isLoading: boolean;
    error?: ErrorResponse;
    individuals: ListPayload<Individual>;
    entities: IndividualEntities;
    createSuccess: boolean;
}

export const individualsInitialState: Readonly<IndividualsState> = {
    isLoading: false,
    createSuccess: false,
    individuals: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const individuals: Reducer<IndividualsState, IndividualActions> = (
    state = individualsInitialState,
    action
): IndividualsState => {
    switch (action.type) {
        case 'LIST_INDIVIDUALS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_INDIVIDUALS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                individuals: action.payload,
            };
        case 'LIST_INDIVIDUALS_ERROR':
            return {
                ...state,
                isLoading: false,
                individuals: state.individuals,
                error: action.error,
            };
        case 'CREATE_INDIVIDUAL_REQUEST':
            return {
                ...state,
                createSuccess: false,
                isLoading: true,
            };
        case 'CREATE_INDIVIDUAL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                createSuccess: true,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_INDIVIDUAL_ERROR':
            return {
                ...state,
                isLoading: false,
                createSuccess: false,
                error: action.error,
            };
        case 'GET_INDIVIDUAL_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_INDIVIDUAL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_INDIVIDUAL_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_INDIVIDUAL_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'EDIT_INDIVIDUAL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_INDIVIDUAL_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_INDIVIDUAL_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_INDIVIDUAL_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_INDIVIDUAL_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default individuals;
