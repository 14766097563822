import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';

import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import IdentificationOfWarehouseBlock, {
    identificationOfWarehouseBlockRequiredValidation,
    identificationOfWarehouseBlockValidation,
} from '../blocks/IdentificationOfWarehouseBlock';

export const identificationOfWarehouseCardValidation = {
    selfValidators: [identificationOfWarehouseBlockValidation],
};

export const identificationOfWarehouseCardRequiredValidation = {
    selfValidators: [identificationOfWarehouseBlockRequiredValidation],
};
interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'id' | 'typeCode'>;
}

const IdentificationOfWarehouseCard = (props: Props): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('identificationOfWarehouse.title')} hidden={props.hidden}>
            <FormCardContainer>
                <IdentificationOfWarehouseBlock
                    declarationOptions={{
                        UK_IMPORT_H2: { fieldOptions: { id: { required: true }, typeCode: { required: true } } },
                    }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default IdentificationOfWarehouseCard;
