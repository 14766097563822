import { notification } from 'antd';
import ExpandedTable from 'components/ui/base/table/ExpandedTable';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import dashboardColumns from 'views/custom-declaration/declaration-table/DeclarationColumns';
import { H5 } from '../../../../components/ui/base/typography';
import { deleteDeclaration } from '../../../../store/declarations/client';
import CustomModal from 'components/ui/base/modal/Modal';
import axiosClient from 'config/axios';
import { SuccessResponse } from '../../../../core/http/response';
import config from '../../../../config';
import { Spin } from 'antd';

export interface Props {
    jobId: string | undefined;
}

const DeclarationsOfJobTable: FC<Props> = ({ jobId }) => {
    const navigate = useNavigate();
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [deleteDeclarationId, setDeleteDeclarationId] = useState<string | undefined>(undefined);
    const [declarationsForJobId, setDeclarationsForJobId] = useState<Declaration[] | undefined>(undefined);

    const getDeclarationsForJobId = useCallback(() => {
        if (!jobId) return;

        axiosClient
            .get<SuccessResponse<Declaration[]>>(`${config.declarationsUrl}/declarations/job/${jobId}`, {
                params: { size: 10 },
            })
            .then((response) => setDeclarationsForJobId(response.data.payload));
    }, [jobId]);

    useEffect(() => {
        getDeclarationsForJobId();
    }, [getDeclarationsForJobId]);

    const handleDeleteAction = (deleteId: string | undefined) => {
        setDeleteDeclarationId(deleteId);
        setDeleteModalVisible(true);
    };

    const handleDeleteDeclaration = () => {
        if (!deleteDeclarationId)
            return notification.error({ message: 'Something went wrong, please try again later!' });

        deleteDeclaration(deleteDeclarationId)
            .then(() => {
                notification.success({ message: 'Declaration deleted successfully!' });
                getDeclarationsForJobId();
            })
            .catch(() => {
                notification.error({ message: 'Something went wrong, please try again later!' });
            })
            .finally(() => {
                setDeleteModalVisible(false);
                setDeleteDeclarationId(undefined);
            });
    };

    const handleDuplicate = (declaration: Declaration) => {
        const country = declaration.cdsExportDeclaration ? DeclarationCountry.UK : DeclarationCountry.IRELAND;
        navigate(`/invoice-upload/${country}/${declaration.declarationInternalType}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    return (
        <>
            {!declarationsForJobId ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spin size="large" tip="Loading declarations..." />
                </div>
            ) : (
                <ExpandedTable
                    dataSource={declarationsForJobId}
                    columns={dashboardColumns({ onDuplicate: handleDuplicate, onDelete: handleDeleteAction })}
                    onRow={(record) => {
                        return {
                            onClick: () => navigate(`/declarations/${record.id}`),
                        };
                    }}
                    showHeader={false}
                    pagination={false}
                />
            )}

            <CustomModal
                title={<H5>Do you want to delete this declaration?</H5>}
                centered
                visible={deleteModalVisible}
                onOk={handleDeleteDeclaration}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                confirmText="Delete"
                cancelText="Cancel"
                contentText={'The declaration will be deleted and all information associated with it will be lost.'}
            />
        </>
    );
};
export default DeclarationsOfJobTable;
