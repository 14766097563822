import { ToFormikSchemaObject } from 'utils/ToFormikSchemaObject';
import * as Yup from 'yup';
import { WCWeighbridgeFormikFields, WCWeighbridgeLocationTraceFormikFields } from '../WCTypes';

// TODO Add correct validations
export const wcWeighbridgeValidationSchema = Yup.object().shape<ToFormikSchemaObject<WCWeighbridgeFormikFields>>({
    label: Yup.string().required(),
    location: Yup.string(),
    type: Yup.string().required(),
});

// TODO Add correct validations
export const wcWeighbridgeLocationTraceValidationSchema = Yup.object().shape<
    ToFormikSchemaObject<WCWeighbridgeLocationTraceFormikFields>
>({
    coordinates: Yup.array().min(3).of(Yup.array().of(Yup.number())),
});
