import EDocketForm from 'views/edocket/EDocketForm';
import { Route } from '../routes';

const eDocketRoutes: Route[] = [
    {
        path: 'edocket/create',
        name: 'CreateEDocket',
        element: <EDocketForm />,
    },
];

export default eDocketRoutes;
