import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { DigitalCertificateActions } from './actionTypes';
import { DigitalCertificate } from './digital-certificates';

interface DigitalCertificateEntities {
    [key: string]: DigitalCertificate;
}

export interface DigitalCertificateState {
    isLoading: boolean;
    error?: ErrorResponse;
    digitalCertificates: ListPayload<DigitalCertificate>;
    digitalCertificate: DigitalCertificateEntities;
}

export const initialDigitalCertificateState: DigitalCertificateState = {
    isLoading: false,
    error: undefined,
    digitalCertificates: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    digitalCertificate: {},
};

const digitalCertificates: Reducer<DigitalCertificateState, DigitalCertificateActions> = (
    state = initialDigitalCertificateState,
    action
) => {
    switch (action.type) {
        case 'LIST_DIGITAL_CERTIFICATES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_DIGITAL_CERTIFICATES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                digitalCertificates: action.payload,
            };
        case 'LIST_DIGITAL_CERTIFICATES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'UPLOAD_DIGITAL_CERTIFICATE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'UPLOAD_DIGITAL_CERTIFICATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                digitalCertificate: { ...state.digitalCertificate, [action.payload.id]: action.payload },
            };
        case 'UPLOAD_DIGITAL_CERTIFICATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'UPDATE_DIGITAL_CERTIFICATE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'UPDATE_DIGITAL_CERTIFICATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                digitalCertificate: { ...state.digitalCertificate, [action.payload.id]: action.payload },
            };
        case 'UPDATE_DIGITAL_CERTIFICATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_DIGITAL_CERTIFICATE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_DIGITAL_CERTIFICATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                digitalCertificate: { ...state.digitalCertificate, [action.payload.id]: action.payload },
            };
        case 'GET_DIGITAL_CERTIFICATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_DIGITAL_CERTIFICATE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_DIGITAL_CERTIFICATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_DIGITAL_CERTIFICATE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default: {
            return state;
        }
    }
};

export default digitalCertificates;
