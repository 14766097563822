import { Col, Divider, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import { PaginatedParams } from 'core/http/pagination';
import useDrivers from 'hooks/useDrivers';
import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import { Driver } from 'store/drivers/driver';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import CreateDriver from './CreateDriver';
import DriversTable from './DriversTable';

const DriverManagement = () => {
    const { drivers, isLoading, listDrivers, deleteDriver, error } = useDrivers();
    const [driver, setDriver] = useState<Driver>();
    const [showDriverDrawer, setShowDriverDrawer] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
    const { userInfo } = useSession();
    useEffect(() => {
        listDrivers({ customerId: userInfo?.customerId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listDrivers]);

    const handleAddNewDriver = () => {
        setDriver({} as Driver);
        setShowDriverDrawer(true);
    };
    const handleSort = (paginator: Partial<PaginatedParams>) => {};
    const handlePagination = (paginator: Partial<PaginatedParams>) => {};
    const handleEdit = (driverID: string) => {
        const driver = drivers.list.find((e) => e.id === driverID);
        if (driver?.id) {
            setDriver(driver);
            setShowDriverDrawer(true);
        }
    };
    const handleDetails = (driverID: string) => {};
    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setShowDeleteDrawer(true);
    };

    return (
        <>
            <Drawer
                title={!driver || !driver?.id ? 'Add New Driver' : 'Edit Driver'}
                width={627}
                visible={showDriverDrawer}
                onClose={() => {
                    setDriver({} as Driver);
                    setShowDriverDrawer(false);
                }}
            >
                <CreateDriver
                    closeDrawer={() => {
                        setDriver({} as Driver);
                        setShowDriverDrawer(false);
                    }}
                    handleOk={() => {
                        setShowDriverDrawer(false);
                        listDrivers({ customerId: userInfo?.customerId });
                    }}
                    driver={driver}
                    handleCreateError={() => {}}
                    handleEditError={() => {}}
                />
            </Drawer>
            <Container>
                <Row>
                    <Col span={18}>
                        <H5>Drivers management</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={handleAddNewDriver}>
                            Add New Driver
                        </Button>
                    </ReverseCol>
                </Row>
                <Divider />
                <DriversTable
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEdit}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    data={drivers}
                    loading={isLoading}
                />
            </Container>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these cargo?</H5>
                    ) : (
                        <H5>Do you want to remove this cargo?</H5>
                    )
                }
                centered
                visible={showDeleteDrawer}
                onOk={async () => {
                    const toDelete: string[] = [...deleteIds];

                    for (let i = 0; i < toDelete.length; i++) {
                        await deleteDriver(toDelete[i]);
                    }

                    if (!error) {
                        Notification({
                            type: 'success',
                            messageTitle: 'Cargo Deleted',
                            description: 'Cargos has been successfully deleted!',
                        });
                    }

                    setShowDeleteDrawer(false);
                    listDrivers({ customerId: userInfo?.customerId });
                }}
                onCancel={() => setShowDeleteDrawer(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these cargo, you will lose all the information associated to them.'
                        : 'If you remove this cargo, you will lose all the associated information.'
                }
            />
        </>
    );
};

export default DriverManagement;
