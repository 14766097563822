import { TextExtraSmallBold } from 'components/ui/base/typography';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { FC, useCallback, useMemo } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { FlexDiv, TooltipDiv } from './DeclarationsTable.styled';

interface Props {
    declaration: Declaration;
    createdDate?: boolean;
}
export const DeclarationDate: FC<Props> = ({ declaration, createdDate }) => {
    const getDeclarationDate = useCallback((declarationDate?: string) => {
        if (declarationDate) {
            const date = new Date(declarationDate);
            const hour = getHour(date);
            const formattedDate = dateStandardFormat(date);
            return { date: formattedDate, hour };
        }
        return {};
    }, []);

    const date = useMemo(
        () =>
            declaration?.updatedAt
                ? getDeclarationDate(declaration?.updatedAt)
                : getDeclarationDate(declaration?.createdAt),
        [declaration, getDeclarationDate]
    );

    return (
        <>
            {date?.hour && date?.date ? (
                <>
                    {createdDate ? (
                        <TooltipDiv>
                            <TextExtraSmallBold>Created date:</TextExtraSmallBold>
                            <span>{date?.hour}</span>
                            <span>{date?.date}</span>
                        </TooltipDiv>
                    ) : (
                        <FlexDiv>
                            <span>{date?.hour}</span>
                            <span>{date?.date}</span>
                        </FlexDiv>
                    )}
                </>
            ) : (
                <span>N/A</span>
            )}
        </>
    );
};

export default DeclarationDate;
