import { Reducer } from 'redux';
import { NotificationActions } from './actionTypes';
import { NotificationProps, NotificationType } from '../../components/ui/base/notification/Notification';

export const initialState = {
    type: 'info' as NotificationType,
    messageTitle: '',
    description: '',
};

const notification: Reducer<NotificationProps> = (state = initialState, action: NotificationActions) => {
    switch (action.type) {
        case 'SET_NOTIFICATION':
            return action.payload;
        case 'CLEAR_NOTIFICATION':
            return initialState;
        default:
            return state;
    }
};

export default notification;
