import { FieldMetaProps, FormikProps } from 'formik';
import moment from 'moment';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { SupportingDocuments } from 'store/declarations/ireland/h7-import-declaration';
import { ProducedDocumentsWritingOffItem } from 'store/declarations/ireland/import-declaration';
import { CardState } from '../cards/card-state';

type box44H7Type = SupportingDocuments & { createdByInput: boolean };

export const h1PathBox44 = 'goodsShipment.producedDocumentsWritingOff';
export const h1PathBox44Products = 'producedDocumentsWritingOff';
export const h1Box44KeyNames: Box44FieldNames = {
    documentType: 'documentType',
    documentIdentifier: 'documentIdentifier',
};
export const h7PathBox44 = 'goodsShipment.documentsAuthorisations.supportingDocument';
export const h7PathBox44Products = 'documentsAuthorisations.supportingDocument';
export const h7Box44KeyNames: Box44FieldNames = {
    documentType: 'supportingDocumentType',
    documentIdentifier: 'supportingDocumentReferenceNumber',
};

export const ensPathBox44Products = 'additionalDocument';
export const ensBox44KeyNames: Box44FieldNames = {
    documentType: 'code',
    documentIdentifier: 'identifier',
};

export const box44PathAndFieldNames: Record<string, any> = {
    H1: {
        masterPath: h1PathBox44,
        productPath: h1PathBox44Products,
        documentTypeName: h1Box44KeyNames.documentType,
        documentIdentifierName: h1Box44KeyNames.documentIdentifier,
    },
    H7: {
        masterPath: h7PathBox44,
        productPath: h7PathBox44Products,
        documentTypeName: h7Box44KeyNames.documentType,
        documentIdentifierName: h7Box44KeyNames.documentIdentifier,
    },
    ENS: {
        masterPath: 'stringSoItDoesntGiveAnError',
        productPath: ensPathBox44Products,
        documentTypeName: ensBox44KeyNames.documentType,
        documentIdentifierName: ensBox44KeyNames.documentIdentifier,
    },
};

export const getDateFormatForBox44 = (value: moment.Moment | null) =>
    value ? moment(value).format('yyyyMMDDHHmm') : null;

const handleNullSupportingDocuments = (box44Items: SupportingDocuments[]) => {
    if (box44Items?.length) {
        return [...box44Items] as box44H7Type[];
    } else {
        return [] as box44H7Type[];
    }
};

export const updatedH7Item = (item: SupportingDocuments, box44Items: SupportingDocuments[]) => {
    const arr = handleNullSupportingDocuments(box44Items);
    const index = arr.findIndex((i) => i.supportingDocumentType === item.supportingDocumentType);
    if (Number.isFinite(index) && index >= 0) {
        if (!!item.supportingDocumentReferenceNumber) {
            arr.splice(index, 1, item as box44H7Type);
        } else {
            arr.splice(index, 1);
        }
        return arr;
    } else {
        if (!!item.supportingDocumentReferenceNumber) {
            return [...arr, item];
        }
        return arr;
    }
};

export const updateBox44 = (
    item: Required<Omit<ProducedDocumentsWritingOffHeader, 'id'>>,
    box44Items: Record<string, string[]>
) => {
    const box44 = { ...box44Items };
    const fieldInBox44 = box44[item.documentType];
    if (fieldInBox44) {
        if (item.documentIdentifier) {
            box44[item.documentType].splice(0, 1, item.documentIdentifier);
        } else {
            box44[item.documentType].splice(0, 1);
        }
    } else {
        box44[item.documentType] = [];
        box44[item.documentType].push(item.documentIdentifier);
    }

    return box44;
};

export const getBox44Path = (declarationType: DeclarationName) => {
    return declarationType === DeclarationName.H7
        ? 'goodsShipment.documentsAuthorisations.supportingDocument'
        : 'goodsShipment.producedDocumentsWritingOff';
};

export const getBox44State = (items: ProducedDocumentsWritingOffHeader[] | SupportingDocuments[]) => {
    if (items?.length) {
        return CardState.VALID;
    } else {
        return CardState.EMPTY;
    }
};

export const getPathOfBox44FieldInInput = (field: string) => {
    switch (field) {
        case 'N740':
            return 'airwayBill';
        case 'N741':
            return 'masterAirwayBill';
        case 'N703':
            return 'houseWayBill';
        case 'N705':
            return 'billOfLading';
        case 'N730':
            return 'roadConsignmentNote';
        case 'N704':
            return 'masterBillOfLading';
        case 'N714':
            return 'houseBillOfLading';
        case '1D24':
            return 'goodsShipment.estimatedTimeOfArrival';
        case '1D94':
            return 'roroShipId';
        case '1D95':
            return 'roroUnaccompaniedTrailerReg';
        case '1Q35':
            return 'textilesCertOfOrigin';
        case 'N934':
            return 'dv1Declaration';
        case '1A01':
            return 'vatFreeAuthorisation';
        default:
            return '';
    }
};

export const getDeclarationPath = (declarationType: DeclarationName, products?: boolean) => {
    if (declarationType === DeclarationName.H1) {
        return !products ? h1PathBox44 : h1PathBox44Products;
    } else {
        return !products ? h7PathBox44 : h7PathBox44Products;
    }
};

export const findIndexOnProducedWritingOffItem = (
    getFieldMeta: ((name: string) => FieldMetaProps<any>) | undefined,
    document: string,
    declarationType: DeclarationName,
    products?: boolean
) => {
    if (getFieldMeta) {
        if (declarationType === DeclarationName.H1) {
            const producedDocumentsWritingOff =
                (getFieldMeta(getDeclarationPath(declarationType, products))
                    .value as ProducedDocumentsWritingOffItem[]) || [];
            const index = producedDocumentsWritingOff.findIndex((element) => element.documentType === document);
            return index !== -1 ? index : producedDocumentsWritingOff.length;
        } else {
            const supportingDocuments =
                (getFieldMeta(getDeclarationPath(declarationType, products)).value as SupportingDocuments[]) || [];
            const index = supportingDocuments.findIndex((element) => element.supportingDocumentType === document);
            return index !== -1 ? index : supportingDocuments.length;
        }
    }
};

export type Box44FieldNames = { documentType: string; documentIdentifier: string };

export type Box44FieldSettings = {
    path: string;
    keyNames: Box44FieldNames;
    type: string;
};
export type FormikControls = Pick<FormikProps<any>, 'getFieldHelpers' | 'getFieldProps'>;

export const getBox44FieldPath = (box44: Box44FieldSettings) => {
    return `${box44.path}.${box44.type}.0`;
};

export const handleBox44Field = (
    value: string | number | null,
    controls: FormikControls,
    box44: Box44FieldSettings,
    template: {
        template?: boolean | undefined;
        form?: string | undefined;
    }
) => {
    const pathToBox44 = template?.template ? `${template.form}.defaults.${box44.path}` : box44.path;
    const box44Value = (controls.getFieldProps(pathToBox44).value ?? {}) as Record<string, string[]>;
    const fieldInBox44 = () => box44Value[box44.type];

    const createBox44Field = () => {
        controls
            .getFieldHelpers(`${pathToBox44}.${box44.type}.${box44Value[box44.type]?.length ?? '0'}`)
            .setValue(value);
    };
    const updateBox44Field = () => {
        controls.getFieldHelpers(`${pathToBox44}.${box44.type}.0`).setValue(value);
    };
    const deleteBox44Field = () => {
        const box44ValueCopy = { ...box44Value };
        box44ValueCopy[box44.type].splice(0, 1);
        if (!box44ValueCopy[box44.type].length) delete box44ValueCopy[box44.type];
        controls.getFieldHelpers(pathToBox44).setValue(box44ValueCopy);
    };

    if (value) {
        if (fieldInBox44()) {
            updateBox44Field();
        } else {
            createBox44Field();
        }
    } else {
        if (fieldInBox44()) {
            deleteBox44Field();
        }
    }
};
