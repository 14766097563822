const config = {
    isProduction: process.env.NODE_ENV === 'production',
    baseUrl: process.env.REACT_APP_BASE_URL,
    appVersion: process.env.REACT_APP_VERSION,
    host: process.env.REACT_APP_HOST,
    recaptchav3: process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3,
    mapboxglAccessToken:
        'pk.eyJ1Ijoiam1hZ2FsaGFlcyIsImEiOiJja3Fnb3R6NjMwbnE2Mm9qb2dwNm5zdnU4In0.0Vl0fkF9NQTrF1pxCxXfqw',
    authUrl: '/auth',
    cmsUrl: '/cms',
    resourcesUrl: '/cms/resources',
    documentsUrl: '/cms/documents',
    jobsUrl: '/jobs-service',
    declarationsUrl: '/declarations-service',
    declarationTemplatesUrl: '/declarations-service/declaration-templates',
    productTemplateUrl: '/product-templates',
    cargoUrl: '/cargo-manager',
    weighbridgeUrl: '/weighbridge-service',
    journeysUrl: '/journeys-service',
    fleestUrl: '/fleet-and-drivers',
    spoonUrl: '/spoon-service',
};

export default config;
