import { Layout } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const MobileStyledLayout = styled(Layout)`
    height: 100vh;
    background: ${colors.lightGrey};
`;

export const MobileStyledContent = styled(Layout.Content)`
    padding: 1rem 1rem 1.6rem;
    overflow: auto;
    background-color: ${colors.white};
`;
