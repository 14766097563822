import useSession from 'hooks/useSession';
import { FC, useMemo } from 'react';
import { IndividualType } from 'store/individuals/individual';
import Jobs from 'views/jobs/Jobs';
import DriverDashboard from 'views/mobile/dashboard/DriverDashboard';
import Dashboard from './components/Dashboard';

const DashboardView: FC = () => {
    const { userInfo } = useSession();

    const dashboardView = useMemo(() => {
        switch (userInfo?.role) {
            case IndividualType.BROKER_ADMIN:
            case IndividualType.SUPER_ADMIN:
            case IndividualType.BROKER_CLERK:
            case IndividualType.TRADER_ADMIN:
            case IndividualType.TRADER_USER:
                return <Dashboard />;
            case IndividualType.HAULIER_ADMIN:
                return <Jobs />;
            case IndividualType.DRIVER:
                return <DriverDashboard />;
            default:
                return <></>;
        }
    }, [userInfo]);
    return <>{dashboardView}</>;
};
export default DashboardView;
