import { Col, Row } from 'antd';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const TransportInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const fields = props.getFields ? props.getFields() : [];

    return (
        <section
            id={'tsd-transport-information-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-transprot-information-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('transportInformation')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={2}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`departureTransportMeans.identificationType`, props)}
                            label={t('identificationType')}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`departureTransportMeans.identificationNumber`, props)}
                            label={t('identificationNumber')}
                        />
                    </Col>
                    <Col sm={24} xxl={12}>
                        <CountrySelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`nationality`, props)}
                            refNumber="BG"
                            label={t('consignment.borderTransportMeans.nationality')}
                        />
                    </Col>
                </Row>
            </FormCard>
        </section>
    );
};

export default TransportInformationCard;
