import videoImg from 'assets/video-image.jpg';
import Button from 'components/ui/base/button/Button';
import { H5 } from 'components/ui/base/typography';
import useSession from 'hooks/useSession';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InvitePageProps } from 'views/authentication/invite/Invite';
import { IntroContinueBt, TitleSection, VideoDiv } from 'views/authentication/invite/Invite.styles';

interface IntroProps extends InvitePageProps {}

const VideoIntro: FC<IntroProps> = () => {
    const { t } = useTranslation('onboarding');
    const { userInfo, isLogged } = useSession();
    const navigate = useNavigate();

    const handleContinue = () => {
        if (isLogged && userInfo) {
            navigate('/');
        }
    };

    return (
        <>
            <TitleSection>
                <H5>{t('intro_to_ddispatch')}</H5>
            </TitleSection>
            <VideoDiv>
                <img alt="video" src={videoImg} />
            </VideoDiv>
            <IntroContinueBt>
                <Button size="large" style={{ width: '10.0rem' }} type="primary" onClick={handleContinue}>
                    {t('continue')}
                </Button>
            </IntroContinueBt>
        </>
    );
};
export default VideoIntro;
