import { BreadcrumbItem } from './breadcrumbItem';

export const doSetBreadcrumb: Function = (routes: BreadcrumbItem[]) => (dispatch: Function) => {
    dispatch({
        type: 'SET_BREADCRUMB',
        payload: routes,
    });
};

export const doClearBreadcrumb: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_BREADCRUMB' });
};
