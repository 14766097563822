import { Col } from 'antd';
import { TextSmall, TextSmallBold } from 'components/ui/base/typography';
import useSession from 'hooks/useSession';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import { LegalInfoType } from './components/legal-info';
import { ContentDiv, DefinitionRow, StyledContent, StyledLayout } from './components/LegalInfo.styles';
import TextBlock from './components/TextBlock';

const PrivacyPolicy: FC = () => {
    const { t } = useTranslation('termsAndConditions');
    const { isLogged } = useSession();

    const textBlockLabels = useMemo(
        () => [
            'introduction',
            'information-collection',
            'information-use',
            'information-sharing',
            'rationale',
            'scope',
            'we-as-a-data-controller',
            'subject-access-requests',
            'third-party-processors',
            'third-party-processors-payment',
            'ip-addresses-and-cookies',
            'data-security',
            'the-data-protection-principles',
            'your-legal-rights',
            'data-subject-access-requests',
            'implementation',
            'privacy-policy-changes',
            'definitions',
        ],
        []
    );
    const definitionsLabels = useMemo(
        () => [
            'data',
            'data',
            'personal-data',
            'sensitive-personal-data',
            'data-controller',
            'data-subject',
            'data-processor',
            'data-protection-officer',
            'relevant-filing-system',
        ],
        []
    );

    return (
        <StyledLayout>
            <Header isLogged={isLogged} type={LegalInfoType.PRIVACY_POLICY} />
            <StyledContent>
                <ContentDiv>
                    {textBlockLabels.map((label, index) => (
                        <TextBlock
                            key={`${label}-${index}`}
                            title={t(`privacy-policy.labels.${label}`)}
                            body={t(`privacy-policy.${label}`)}
                        />
                    ))}

                    {definitionsLabels.map((label, index) => (
                        <DefinitionRow key={`${label}-${index}`} gutter={16}>
                            <Col span={4}>
                                <TextSmallBold>{t(`privacy-policy.labels.${label}`)}</TextSmallBold>
                            </Col>
                            <Col span={20}>
                                <TextSmall>{t(`privacy-policy.${label}`)}</TextSmall>
                            </Col>
                        </DefinitionRow>
                    ))}
                </ContentDiv>
            </StyledContent>
        </StyledLayout>
    );
};
export default PrivacyPolicy;
