import useBreadcrumb from 'hooks/useBreadcrumb';
import useSetup from 'hooks/useSetup';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { BreadcrumbItem } from 'store/breadcrumb/breadcrumbItem';
import useFormikWeighbridge from './formik/useFormikWCWeighbridge';
import useFormikWeighbridgeLocationTrace from './formik/useFormikWCWeighbridgeLocationTrace';
import { WCWeighbridgeFormikType, WCWeighbridgeLocationTraceFormikType } from './WCTypes';

export interface CreateOutletContext {
    formik: WCWeighbridgeFormikType;
}

export interface LocationOutletContext {
    formik: WCWeighbridgeLocationTraceFormikType;
    weighbridgeCreationFormik: WCWeighbridgeFormikType;
}

export interface WCOutletContext {
    create: CreateOutletContext;
    location: LocationOutletContext;
}

const WC = () => {
    const { t } = useTranslation('weighbridge');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const location = useLocation();

    const { isInSetupScreen } = useSetup();

    const weighbridgeFormik = useFormikWeighbridge();
    const weighbridgeLocationTraceFormik = useFormikWeighbridgeLocationTrace();

    const outletContext = useMemo(() => {
        const context: WCOutletContext = {
            create: { formik: weighbridgeFormik },
            location: {
                formik: weighbridgeLocationTraceFormik,
                weighbridgeCreationFormik: weighbridgeFormik,
            },
        };

        // String after route's last slash
        const key = location.pathname.split('/').at(-1) as keyof typeof context;

        return context[key];
    }, [location.pathname, weighbridgeFormik, weighbridgeLocationTraceFormik]);

    // Setting the route in the header
    useEffect(() => {
        const breadcrumbs: BreadcrumbItem[] = [
            {
                breadcrumbName: t('breadcrumbs.locationRegistration'),
                path: '',
            },
            {
                breadcrumbName: t('breadcrumbs.weighbridge'),
                path: '',
            },
        ];

        if (isInSetupScreen) {
            breadcrumbs.unshift({
                breadcrumbName: t('breadcrumbs.setup'),
                path: '',
            });
        }

        setBreadcrumbRoutes(breadcrumbs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Outlet context={outletContext} />;
};

export const useWCOutletContext = <TOutletContext extends WCOutletContext[keyof WCOutletContext]>() => {
    return useOutletContext<TOutletContext>();
};

export default WC;
