import FormPassword from 'components/ui/composed/formPassword';
import { useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, SendLinkBt } from './ForgotPassword.styles';

const formValidation = Yup.object().shape({
    password: Yup.string().required('Password is required.').nullable(),
    confirmPassword: Yup.string()
        .required('Confirm password is required.')
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
        })
        .nullable(),
});

const ResetPassword: FC<{ token: string }> = ({ token }) => {
    const { isLoading, resetPassword, error } = useSession();
    const [resetSent, setResetSents] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('login');

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema: formValidation,
        onSubmit: (values: { password: string; confirmPassword: string }) => handleSubmit(values),
    });

    useEffect(() => {
        if (isLoading === false && resetSent && error === undefined) {
            navigate('/', { replace: true });
        }
    }, [isLoading, resetSent, error, navigate]);

    useEffect(() => {
        if (error !== undefined && resetSent) {
            //TODO error
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, resetSent]);

    const handleSubmit = (form: { password: string; confirmPassword: string }) => {
        resetPassword(form.password, token);
        setResetSents(true);
    };

    return (
        <Form layout="vertical">
            <FormPassword
                icon
                label={t('forgot_password.password')}
                fieldMeta={formik.getFieldMeta('password')}
                fieldProps={formik.getFieldProps('password')}
                fieldHelper={formik.getFieldHelpers('password')}
            />
            <FormPassword
                icon
                label={t('forgot_password.confirmPassword')}
                fieldMeta={formik.getFieldMeta('confirmPassword')}
                fieldProps={formik.getFieldProps('confirmPassword')}
                fieldHelper={formik.getFieldHelpers('confirmPassword')}
            />
            <SendLinkBt
                type="primary"
                size="large"
                onClick={() => {
                    formik.handleSubmit();
                }}
            >
                {t('forgot_password.confirmBt')}
            </SendLinkBt>
        </Form>
    );
};

export default ResetPassword;
