import Button from 'components/ui/base/button/Button';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import { useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createIndividual } from 'store/individuals/client';
import { Individual, IndividualType } from 'store/individuals/individual';
import * as Yup from 'yup';
import { InvitePageProps, OnboardingSection } from '../../Invite';
import { AddTeamMemberButtons, NextButton, TeamMembersList, TitleSection } from '../../Invite.styles';
import ListTeamMembers from './ListTeamMembers';
import NewTeamMember from './NewTeamMember';

const formValidation = Yup.object().shape({
    type: Yup.string().required('Role is missing.').nullable(),
    email: Yup.string().required('Email missing.').email('Email is wrong').nullable(),
    phoneCode: Yup.string().required('Field Phone code is required.'),
    phone: Yup.string().required('Field Phone number is required.'),
});

interface AddTeamMembersProps extends InvitePageProps {}

const AddTeamMembers: FC<AddTeamMembersProps> = ({ onChangePage }) => {
    const { t } = useTranslation('onboarding');
    const { isLogged, userInfo } = useSession();
    const [teamMembers, setTeamMembers] = useState<Individual[]>([]);
    const [additionMode, setAdditionMode] = useState(false);
    const [createIndividualClicked, setCreateIndividualClicked] = useState(false);
    const [addMembersResults, setAddMembersResults] = useState<(Individual | null)[]>();

    useEffect(() => {
        if (!teamMembers.length) {
            setAdditionMode(true);
        }
    }, [teamMembers]);

    useEffect(() => {
        if (createIndividualClicked) {
            if (isLogged && userInfo) {
                onChangePage(OnboardingSection.INTRO);
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('addErrorTitle'),
                    description: t('addErrorMessage'),
                });
            }
            setCreateIndividualClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createIndividualClicked]);

    const handleContinue = async () => {
        const err: unknown[] = [];
        const result: (Individual | null)[] = [];
        let newMembers = [...teamMembers];
        if (addMembersResults?.length) {
            const members = [...teamMembers];
            const alreadyAdded = [...addMembersResults.filter((a) => a !== null)];
            newMembers = members.filter((m) => {
                const exist = alreadyAdded.find((a: Individual | null) => a && a.email === m.email);
                return exist ? false : true;
            });
        }
        await Promise.allSettled(
            newMembers.map(async (individual, i) => {
                err[i] = null;
                try {
                    const payload = await createIndividual(individual);
                    result[i] = payload;
                } catch (error) {
                    result[i] = null;
                    err[i] = error;
                }
            })
        );
        setAddMembersResults(result);

        if (err.every((e) => e === null)) {
            setCreateIndividualClicked(true);
        } else {
            Notification({
                type: 'error',
                messageTitle: t('addErrorTitle'),
                description: t('addErrorMessage'),
            });
        }
    };

    const handleSubmit = (values: {
        email: string;
        type: string;
        phoneCode: string;
        phone: string;
        fullName: string;
    }) => {
        const newIndividual = {
            email: values.email,
            type: values.type as IndividualType,
            isInvited: true,
            phone: `${values.phoneCode}${values.phone}`,
            customerId: userInfo?.customerId,
        } as Individual;

        const list = [...teamMembers, newIndividual];
        setTeamMembers(list);
        setAdditionMode(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            type: null,
            email: null,
        },
        validateOnMount: false,
        validationSchema: formValidation,
        enableReinitialize: true,
        onSubmit: (values: any) => handleSubmit(values),
    });

    const addMore = () => {
        formik.resetForm();
        setAdditionMode(true);
    };

    const confirmNewTeamMember = () => {
        formik.submitForm();
    };

    const removeTeamMember = useCallback(
        (index: number) => {
            const list = [...teamMembers];
            const newMembers = list.filter((o, i) => index !== i);
            setTeamMembers(newMembers);
        },
        [teamMembers]
    );
    return (
        <>
            <TitleSection>
                <H5>{t('add_team_members')}</H5>
            </TitleSection>
            <TeamMembersList>
                <ListTeamMembers results={addMembersResults} teamMembers={teamMembers} remove={removeTeamMember} />
                {additionMode && userInfo && (
                    <NewTeamMember
                        index={teamMembers.length + 1}
                        getFieldProps={formik.getFieldProps}
                        getFieldHelpers={formik.getFieldHelpers}
                        getFieldMeta={formik.getFieldMeta}
                        user={userInfo}
                    />
                )}

                <AddTeamMemberButtons>
                    <Button size="large" onClick={addMore} disabled={additionMode}>
                        Add more
                    </Button>
                    <Button
                        style={{ marginLeft: '1.6rem' }}
                        type="primary"
                        size="large"
                        onClick={confirmNewTeamMember}
                        disabled={!additionMode}
                    >
                        Confirm
                    </Button>
                </AddTeamMemberButtons>
            </TeamMembersList>
            <div>
                <NextButton>
                    <Button
                        size="large"
                        style={{ width: '10.0rem' }}
                        onClick={handleContinue}
                        type="primary"
                        testId="add-team-member"
                    >
                        Continue
                    </Button>
                    <Button style={{ marginLeft: '1.6rem', width: '10.0rem' }} size="large" onClick={handleContinue}>
                        Skip
                    </Button>
                </NextButton>
            </div>
        </>
    );
};
export default AddTeamMembers;
