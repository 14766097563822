import { TextAreaProps } from 'antd/lib/input/TextArea';
import { memo } from 'react';
import { EDocketFormStyledComponents } from '../EDocketForm.styles';
import EDocketLabel from './EDocketLabel';

type Props = { label?: string } & TextAreaProps;

const { TextArea } = EDocketFormStyledComponents;

const EDocketFormTextArea = ({ label, ...textAreaProps }: Props) => {
    return (
        <>
            <EDocketLabel label={label} />
            <TextArea {...textAreaProps} />
        </>
    );
};

export default memo(EDocketFormTextArea, (prev, next) => {
    return prev?.value === next?.value;
});
