import { FC, useState } from 'react';
import { AepBusinessRule } from 'store/aep-business-rules/aep-business-rule';
import {
    BusinessRuleCode,
    BusinessRuleDescription,
    BusinessRuleMessage,
    StyledBusinessRuleRow,
    StyledDownOutlined,
} from '../BusinessRules.styles';

export const AepBusinessRuleRow: FC<{ rule: AepBusinessRule }> = ({ rule }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    return (
        <StyledBusinessRuleRow>
            <BusinessRuleMessage onClick={() => setShowDescription(!showDescription)}>
                <StyledDownOutlined expanded={showDescription} />
                <BusinessRuleCode>{rule.code}:</BusinessRuleCode>
            </BusinessRuleMessage>
            {showDescription ? <BusinessRuleDescription>{rule.description}</BusinessRuleDescription> : <></>}
        </StyledBusinessRuleRow>
    );
};

export default AepBusinessRuleRow;
