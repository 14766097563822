import { DownOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Drawer from 'components/ui/base/drawer/Drawer';
import SearchBar from 'components/ui/base/searchbar';
import { H4, H6 } from 'components/ui/base/typography';
import useDeclarations from 'hooks/useDeclarations';
import useRequest, { useRequestPromise } from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { listDeclarations } from 'store/declarations/client';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import DeclarationTable, { DeclarationTableType } from 'views/custom-declaration/declaration-table/DeclarationsTable';
import { FilterCustomButtom } from '../../Form.styles';
import DocumentsTable from '../customer-documents/components/DocumentsTable';
import { MainDiv, Section, SectionArchivedSubmittions, SectionCoreTemplates, TableDiv } from './Archives.styles';
import { ListPayload } from 'core/http/response';

interface Props {
    setAsTemplate: (declaration: Declaration) => void;
    declarationExternalEntity: DeclarationExternalEntity[];
    declarationInternalType: DeclarationInternalType[];
}

const Archive: FC<Props> = ({ setAsTemplate, declarationExternalEntity, declarationInternalType }) => {
    const { t } = useTranslation('customs_declarations');
    const [updateLists, setUpdateLists] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const {
        data: coreTemplateDeclarations,
        doRequest: listCoreTemplateDeclarations,
        isLoading: isLoadingCoreTemplateDeclaration,
    } = useRequest(listDeclarations);
    const {
        data: releasedDeclarations,
        refetch: listReleasedDeclarations,
        isLoading: isLoadingReleasedDeclaration,
    } = useRequestPromise(listDeclarations);
    const { updateCoreTemplateStatus, documents, listDeclarationDocuments } = useDeclarations();

    useEffect(() => {
        listReleasedDeclarations({
            declarationStatus: [DeclarationStatus.RELEASED],
            declarationExternalEntity,
            declarationInternalType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        listCoreTemplateDeclarations({ isCoreTemplate: true, declarationExternalEntity, declarationInternalType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateList = () => {
        listCoreTemplateDeclarations({ isCoreTemplate: true, declarationExternalEntity, declarationInternalType });
        listReleasedDeclarations({
            declarationStatus: [DeclarationStatus.RELEASED],
            declarationExternalEntity,
            declarationInternalType,
        });
    };

    useEffect(() => {
        if (updateLists) {
            updateList();
            setUpdateLists(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateLists]);

    const querySearch = async (query: string) => {
        if (query) {
            listCoreTemplateDeclarations({
                isCoreTemplate: true,
                declarationExternalEntity,
                declarationInternalType,
                query,
            });
            listReleasedDeclarations({
                declarationStatus: [DeclarationStatus.RELEASED],
                declarationExternalEntity,
                declarationInternalType,
                query,
            });
        } else {
            listCoreTemplateDeclarations({ isCoreTemplate: true, declarationExternalEntity, declarationInternalType });
            listReleasedDeclarations({
                declarationStatus: [DeclarationStatus.RELEASED],
                declarationExternalEntity,
                declarationInternalType,
            });
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const handleCoreTemplate = useCallback(
        async (declarationId: string) => {
            await updateCoreTemplateStatus(declarationId);
            setUpdateLists(true);
        },
        [updateCoreTemplateStatus]
    );

    const handleViewDocumentation = useCallback(
        (id: string) => {
            listDeclarationDocuments(id);
            setOpenDrawer(true);
        },
        [listDeclarationDocuments]
    );

    const handleSetAsTemplate = useCallback(
        (id: string, type: DeclarationTableType) => {
            switch (type) {
                case DeclarationTableType.ARCHIVED:
                    const releasedDeclaration = releasedDeclarations?.list?.find((d: Declaration) => d.id === id);
                    if (releasedDeclaration) {
                        setAsTemplate(releasedDeclaration);
                    }
                    break;
                case DeclarationTableType.CORE_TEMPLATE:
                    const coreTemplateDeclaration = coreTemplateDeclarations?.list?.find(
                        (d: Declaration) => d.id === id
                    );
                    if (coreTemplateDeclaration) {
                        setAsTemplate(coreTemplateDeclaration);
                    }
                    break;
            }
        },
        [releasedDeclarations, coreTemplateDeclarations, setAsTemplate]
    );

    return (
        <MainDiv>
            <H4>{t('archive.title')}</H4>
            <Row gutter={16} wrap={false}>
                <Col flex="auto">
                    <SearchBar
                        inputPlaceholder={t('archive.searchBy')}
                        onSearch={(value) => debouncedSearch(value)}
                        onClear={() => updateList()}
                    />
                </Col>
                <Col>
                    <FilterCustomButtom>
                        {t('archive.filter')} <DownOutlined />
                    </FilterCustomButtom>
                </Col>
            </Row>
            <SectionCoreTemplates>
                <Section>{t('archive.coreTemplates')}</Section>
                <TableDiv>
                    {coreTemplateDeclarations && (
                        <DeclarationTable
                            data={coreTemplateDeclarations as ListPayload<DeclarationAndAgent>}
                            loading={isLoadingReleasedDeclaration}
                            type={DeclarationTableType.CORE_TEMPLATE}
                            viewDocuments={handleViewDocumentation}
                            handleCoreTemplate={handleCoreTemplate}
                            setAsTemplate={handleSetAsTemplate}
                        />
                    )}
                </TableDiv>
            </SectionCoreTemplates>
            <SectionArchivedSubmittions>
                <Section>{t('archive.archivedSubmissions')}</Section>
                <TableDiv>
                    {releasedDeclarations && (
                        <DeclarationTable
                            data={releasedDeclarations as ListPayload<DeclarationAndAgent>}
                            loading={isLoadingCoreTemplateDeclaration}
                            type={DeclarationTableType.ARCHIVED}
                            viewDocuments={handleViewDocumentation}
                            handleCoreTemplate={handleCoreTemplate}
                            setAsTemplate={handleSetAsTemplate}
                        />
                    )}
                </TableDiv>
            </SectionArchivedSubmittions>
            <Drawer title="Associated Documents" width="950" visible={openDrawer} onClose={() => setOpenDrawer(false)}>
                <H6>Associated Documents</H6>
                {documents && <DocumentsTable archivesViewOnly={'archiveView'} cmsFilesList={documents.list} />}
            </Drawer>
        </MainDiv>
    );
};
export default Archive;
