import { AppDispatch } from 'config/store';
import { GlobalOverlayPayload, GlobalOverlayType } from './globalOverlay';

export type DoShowGlobalOverlayParams = {
    type: GlobalOverlayType;
    payload?: GlobalOverlayPayload;
};
export const doShowGlobalOverlay: Function = (params: DoShowGlobalOverlayParams) => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'SHOW_GLOBAL_OVERLAY',
        overlayType: params.type,
        payload: params.payload,
    });
};

export const doHideGlobalOverlay: Function = () => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'HIDE_GLOBAL_OVERLAY',
    });
};
