import logo from 'assets/logo-login.svg';
import { FC, useEffect, useMemo, useState } from 'react';
import { AuthenticationFormDiv, AuthLogo } from '../Auth.styles';
import SignUpForm from './SignUpForm';
import SignUpSplashScreen from './SignUpSplashScreen';

const SignUp: FC = () => {
    const [userIsCreated, setUserIsCreated] = useState(false);

    useEffect(() => {
        setUserIsCreated(false);
    }, []);

    const displayTitle = useMemo(() => {
        if (userIsCreated) {
            return (
                <SignUpSplashScreen
                    onClear={() => {
                        setUserIsCreated(false);
                    }}
                />
            );
        }

        return (
            <>
                <AuthLogo>
                    <img src={logo} alt="DDispatch" />
                </AuthLogo>
                <SignUpForm
                    handleUserIsCreated={() => {
                        setUserIsCreated(true);
                    }}
                />
            </>
        );
    }, [userIsCreated]);

    return <AuthenticationFormDiv>{displayTitle}</AuthenticationFormDiv>;
};
export default SignUp;
