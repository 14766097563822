import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    doPressedSubmitOff,
    doPressedSubmitOn,
    doResetDeclarationValidation,
    doSetDeclarationValidation,
    doSetHasUnsavedChanges,
} from 'store/declaration-validation/action';
import { FormAction } from 'views/declarations/utils/form-utils';
import { useAppSelector } from '../config/hooks';

interface Props {
    formAction?: FormAction | null;
}
const useDeclarationValidation = (props?: Props) => {
    const dispatch = useDispatch();
    const declarationValidation = useAppSelector((state) => state.declarationValidation);

    const isSavingAsDraft = useMemo(
        () => declarationValidation.formAction === FormAction.DRAFT,
        [declarationValidation.formAction]
    );

    const setFormAction = (validation: FormAction | null) => {
        dispatch(doSetDeclarationValidation(validation));
    };

    const setPressedSubmit = () => {
        dispatch(doPressedSubmitOn());
    };

    const clearValidation = () => {
        dispatch(doResetDeclarationValidation());
    };

    const clearPressedSubmit = () => {
        dispatch(doPressedSubmitOff());
    };

    const setHasUnsavedChanges = (value: boolean) => {
        dispatch(doSetHasUnsavedChanges(value));
    };

    useEffect(() => {
        if (props?.formAction && props.formAction !== declarationValidation.formAction) {
            clearValidation();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return {
        declarationValidation,
        setFormAction,
        clearValidation,
        setPressedSubmit,
        clearPressedSubmit,
        setHasUnsavedChanges,
        isSavingAsDraft,
    };
};

export default useDeclarationValidation;
