import useSession from 'hooks/useSession';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import { MobileStyledLayout, MobileStyledContent } from './MobileSkeleton.styles';

const MobileSkeleton: FC = () => {
    const { isLogged } = useSession();
    const location = useLocation();

    if (!isLogged) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <MobileStyledLayout>
            <MobileHeader />
            <MobileStyledContent>
                <Outlet />
            </MobileStyledContent>
        </MobileStyledLayout>
    );
};
export default MobileSkeleton;
