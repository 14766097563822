import { Action } from 'redux';
import { ErrorResponse } from 'core/http/response';

type ActionArgument = Action & { payload?: any; error?: ErrorResponse };

type HandleRequestAction<TState> = {
    actionType: string;
    payloadProperty: keyof TState;
};

/**
 *
 * Handles **single** request action.
 * There are 3 cases that are handled per `actionType`.
 * - `actionType_REQUEST`
 * - `actionType_SUCCESS`
 * - `actionType_ERROR`
 *
 * It handles all of the aspects that you would expect: isLoading, error, and the data (`payloadProperty`)
 *
 * @param action
 * @param state
 * @param requestAction
 * @returns
 */
export const handleRequestAction = <TAction extends ActionArgument, TState>(
    action: TAction,
    state: TState,
    { actionType, payloadProperty }: HandleRequestAction<TState>
) => {
    switch (action.type) {
        case `${actionType}_REQUEST`:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case `${actionType}_SUCCESS`:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                [payloadProperty]: action.payload,
            };
        case `${actionType}_ERROR`:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return undefined;
    }
};

/**
 *
 * Handles **multiple** request actions.
 * There are 3 cases that are handled per `actionType`.
 * - `actionType_REQUEST`
 * - `actionType_SUCCESS`
 * - `actionType_ERROR`
 *
 * It handles all of the aspects that you would expect: isLoading, error, and the data (`payloadProperty`)
 *
 * @param action
 * @param state
 * @param requestActions
 * @returns
 */
export const handleRequestActions = <TAction extends ActionArgument, TState>(
    action: TAction,
    state: TState,
    requestActions: HandleRequestAction<TState>[]
): TState | undefined => {
    for (const requestAction of requestActions) {
        const storeRequestReducerState = handleRequestAction(action, state, requestAction);

        if (storeRequestReducerState !== undefined) {
            return storeRequestReducerState;
        }
    }

    return undefined;
};

/**
 *
 * Handles **multiple** request actions with a single payload property.
 * There are 3 cases that are handled per `actionType`.
 * - `actionType_REQUEST`
 * - `actionType_SUCCESS`
 * - `actionType_ERROR`
 *
 * It handles all of the aspects that you would expect: isLoading, error, and the data (`payloadProperty`)
 *
 * @param action
 * @param state
 * @param payloadProperty
 * @param requestActions
 * @returns
 */
export const handleRequestActionsSinglePayloadProperty = <TAction extends ActionArgument, TState>(
    action: TAction,
    state: TState,
    payloadProperty: keyof TState,
    requestActions: string[]
): TState | undefined => {
    for (const requestAction of requestActions) {
        const storeRequestReducerState = handleRequestAction(action, state, {
            payloadProperty,
            actionType: requestAction,
        });

        if (storeRequestReducerState !== undefined) {
            return storeRequestReducerState;
        }
    }

    return undefined;
};
