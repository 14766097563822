import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import EDocketFormInput from './inputs/EDocketFormInput';
import EDocketFormTextArea from './inputs/EDocketFormTextArea';

const { SectionTitle, SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik>;

const EDocketFormProduct = <TFormik extends FormikProps<EDocketFormType>>({ formik, t }: Props<TFormik>) => {
    return (
        <SectionMain>
            <SectionTitle>{t('productInformation')}</SectionTitle>

            <Row gutter={[8, 25]}>
                <Col xl={6}>
                    <EDocketFormInput
                        label={t('product.description.label')}
                        name="product.description"
                        value={formik.values.product.description}
                        placeholder={t('product.description.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={6}>
                    <EDocketFormInput
                        label={t('product.code.label')}
                        name="product.code"
                        value={formik.values.product.code}
                        placeholder={t('product.code.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={6}>
                    <EDocketFormInput
                        label={t('product.accountNumber.label')}
                        name="product.accountNumber"
                        value={formik.values.product.accountNumber}
                        placeholder={t('product.accountNumber.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={6}>
                    <EDocketFormTextArea
                        label={t('product.form.label')}
                        name="product.from"
                        value={formik.values.product.from}
                        placeholder={t('product.form.placeholder')}
                        onChange={formik.handleChange}
                        rows={2}
                    />
                </Col>
            </Row>
        </SectionMain>
    );
};

export default EDocketFormProduct;
