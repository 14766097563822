import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import { validators } from '../../export/validations/validations';
import HideComponent from '../../../../../utils/HideComponent';

type Fields = 'payment.methodCode';

export const calculationOfTaxesMethodOfPaymentBlockValidation = {
    childValidators: {
        'payment.methodCode': [validators.exact(1)],
    },
};

interface Props extends BlockProps<Fields> {}

const CalculationOfTaxesMethodOfPaymentBlock = ({
    path = 'commodity.dutyTaxFee',
    fieldOptions,
    hide,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/8', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="payment.methodCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('calculationOfTaxesMethodOfPayment')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.paymentMethodTypes}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CalculationOfTaxesMethodOfPaymentBlock;
