import { FC, useState } from 'react';
import { AisBusinessRule } from 'store/ais-business-rules/ais-business-rule';
import {
    BusinessRuleCode,
    BusinessRuleDescription,
    BusinessRuleMessage,
    StyledBusinessRuleRow,
    StyledDownOutlined,
} from '../BusinessRules.styles';

export const AisBusinessRuleRow: FC<{ rule: AisBusinessRule }> = ({ rule }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    return (
        <StyledBusinessRuleRow>
            <BusinessRuleMessage onClick={() => setShowDescription(!showDescription)}>
                <StyledDownOutlined expanded={showDescription} />
                <BusinessRuleCode>{rule.code}:</BusinessRuleCode>
                <span>{rule.message}</span>
            </BusinessRuleMessage>
            {showDescription ? <BusinessRuleDescription>{rule.description}</BusinessRuleDescription> : <></>}
        </StyledBusinessRuleRow>
    );
};

export default AisBusinessRuleRow;
