import { Form } from 'antd';
import { H5, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';
import Button from '../../../components/ui/base/button/Button';

const { Item } = Form;

export const StyledDiv = styled.div`
    margin-top: 2.3rem;
    width: 100%;
    height: auto;
    padding-bottom: 4rem;
    margin-top: 2.3rem;
`;

export const ForgetRemDiv = styled.div`
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-between;
`;

export const StyledItem = styled(Item)`
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: ${fonts.weight.semibold};
    font-family: Open Sans;
    font-style: normal;
`;

export const ForgotPassword = styled(Button)`
    padding: 0;
`;

export const SignUpButton = styled(Button)`
    padding: 0;
    margin-left: 0.5rem;
`;

export const Title = styled(H5)`
    justify-content: center;
    margin-top: 2.8rem;
`;

export const LegalInfoDiv = styled.div`
    display: flex;
    margin-top: 2.4rem;
`;

export const TermsAndConditionsLink = styled(TextSmall)`
    color: ${colors.purpleBlue};
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
`;

export const PrivacyPolicyLink = styled(TextSmall)`
    color: ${colors.purpleBlue};
    margin-left: 0.5rem;
    cursor: pointer;
`;

export const FormItemButtons = styled(Form.Item)`
    margin-top: 22%;
    align-content: center;
`;

export const SignUpDiv = styled.div`
    text-align: center;
`;
