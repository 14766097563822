import { cloneDeep, merge } from 'lodash';
import { irelandExportB1ValidationHeader, irelandExportB1ValidationItem } from './IrelandExportB1Validation';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeepPartial from 'types/DeepPartial';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        additionalReference: REMOVE,
        transportCharges: REMOVE,
        statisticalValue: REMOVE,
        countryOfExport: REMOVE,
        commodity: {
            childValidators: {
                dangerousGoods: REMOVE,
                commodityCode: {
                    childValidators: {
                        taricAdditionalCode: REMOVE,
                        nationalAdditionalCode: REMOVE,
                    },
                },
            },
        },
        packaging: {
            childValidators: {
                numberOfPackages: [REMOVE],
            },
        },
    },
};
export const irelandExportB4ValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        exportOperation: {
            childValidators: {
                specificCircumstanceIndicator: REMOVE,
                security: REMOVE,
            },
        },
        deferredPayment: REMOVE,
        goodsShipment: {
            childValidators: {
                countryOfExport: REMOVE,
                additionalReference: REMOVE,
                consignment: {
                    childValidators: {
                        countryOfRoutingOfConsignment: REMOVE,
                        modeOfTransportAtTheBorder: [REMOVE],
                        inlandModeOfTransport: REMOVE,
                        departureTransportMeans: REMOVE,
                        consignor: REMOVE,
                        consignee: {
                            childValidators: {
                                identificationNumber: [
                                    validators.required(),
                                    ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators
                                        .consignment.childValidators.consignee.childValidators.identificationNumber,
                                ],
                            },
                        },
                        activeBorderTransportMeans: REMOVE,
                        carrier: REMOVE,
                        transportCharges: REMOVE,
                        transportEquipment: {
                            childValidators: {
                                seal: REMOVE,
                            },
                        },
                    },
                },
                goodsItem: irelandExportB4ValidationItem,
            },
        },
    },
};
export const irelandExportB4ValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
