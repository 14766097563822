import { Row } from 'antd';
import { Customer } from 'store/customers/customer';
import { OptionsCol } from '../SearchBar.style';
import OptionTooltip from './OptionTooltip';

const CustomerOptions = (list: Customer[]) => {
    const renderItem = (customer: Customer) => ({
        value: `/customers/${customer.id}`,
        label: (
            <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <OptionsCol>
                    <OptionTooltip data={customer.eori} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={customer.name} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={customer.referenceCode} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={customer.contact?.email} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={customer.contact?.phoneNumber} />
                </OptionsCol>
                <OptionsCol></OptionsCol>
            </Row>
        ),
    });

    const customers = () => list.map((c) => renderItem(c));

    const options = [
        {
            label: <span>Customers</span>,
            options: customers(),
        },
    ];

    return options;
};
export default CustomerOptions;
