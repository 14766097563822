import DDatePicker from 'components/ui/base/datepicker/Datepicker';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext } from 'react-router-dom';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { TemplateCheckboxes } from '../../template/TemplateCheckbox';
import { useRegisterField } from '../../template/CardContext';
import { useTemplateContext } from '../../template/TemplateContext';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import {
    ErrorDiv,
    ErrorLabel,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    ViewOnlyLabel,
    FormatDiv,
    FormItem,
} from '../formInput/DeclarationInput.styles';
import { handleBlur, handleFocus } from '../utils';
import { kebabCase } from 'lodash';
import useDeclarations from 'hooks/useDeclarations';
import { TestProps } from 'core/utils/testTypes';
import { useMirrorMetaContext } from '../../mirroring/MirrorMetaContext';
import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';

export interface FormDatepickerProps extends TestProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    showTime?: boolean;
    viewOnly?: boolean;
    refClicked?: (refNumber: string | number) => void;
    required?: boolean;
    condensed?: boolean;
    onChange?: (value: moment.Moment | null) => void;
    disabled?: boolean;
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
    hidden?: boolean;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const DeclarationDatepicker: FC<FormDatepickerProps> = ({
    label,
    refNumber,
    tooltip,
    fieldProps: fieldPropsProp,
    fieldMeta: fieldMetaProp,
    fieldHelper: fieldHelpersProp,
    showTime,
    viewOnly: viewOnlyProp,
    refClicked,
    required = false,
    condensed,
    onChange,
    disabled,
    specialName,
    hidden,
    testId,
    getPopupContainer,
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { declaration } = useDeclarations();

    const { meta, handleOpenModal } = useMirrorMetaContext();

    const isFieldMirrored = useMemo(() => {
        if (!(fieldPropsProp?.name && meta)) return undefined;

        return meta?.[fieldPropsProp?.name]?.isMirrored;
    }, [fieldPropsProp?.name, meta]);

    useRegisterField({ path: fieldPropsProp?.name, required, hidden });
    const { isViewable, isInvisible } = useFieldTemplateMetaData(fieldPropsProp?.name);

    const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return viewOnlyProp || isViewable || inViewOnly || declaration?.archived;
    }, [
        template,
        isTemplateViewOnly,
        outletContext?.amendment,
        isViewable,
        viewOnlyProp,
        inViewOnly,
        declaration?.archived,
    ]);

    const handleOpenMirrorSeveringModal = useCallback(() => {
        if (!isFieldMirrored) return;

        const revertValueCallback = () => {
            fieldHelpers?.setValue(fieldProps.value);
        };

        handleOpenModal?.(revertValueCallback);
    }, [isFieldMirrored, fieldProps?.value, handleOpenModal, fieldHelpers]);

    const render = useMemo(
        () => (
            <>
                {viewOnly ? (
                    <>
                        <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                        {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                        <TextSmall data-testid={testId ?? kebabCase(label ?? fieldPropsProp?.name)}>
                            {fieldProps.value
                                ? (() => {
                                      const parsedDate = moment(fieldProps.value, 'YYYYMMDDHHmm', true);
                                      return parsedDate.isValid()
                                          ? parsedDate.format('DD-MMM-YYYY HH:mm')
                                          : moment(fieldProps.value).format('DD-MMM-YYYY HH:mm');
                                  })()
                                : '-'}
                        </TextSmall>
                    </>
                ) : (
                    <>
                        <LabelDiv condensed={condensed}>
                            {label && (
                                <InputLabel>
                                    {label}
                                    {required && (
                                        <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                    )}
                                </InputLabel>
                            )}

                            {refNumber && <RefDiv>{refNumber}</RefDiv>}
                            <FormatDiv>{showTime ? 'DD-MM-YYYY HH:MM' : 'DD-MM-YYYY'}</FormatDiv>
                            {showTooltip && (
                                <IconTooltip
                                    title={label}
                                    tooltip={tooltip ?? t('defaultTooltip')}
                                    icon={<StyledInfoCircleFilled />}
                                    tooltipClicked={handleRefClick}
                                />
                            )}
                            {isFieldMirrored && (
                                <Tooltip title={'Mirrored field'}>
                                    <LinkOutlined
                                        style={{ marginLeft: '0.8rem', color: '#00CCFF', cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            )}
                        </LabelDiv>
                        {template && (
                            <TemplateCheckboxes
                                required={required}
                                fieldPath={fieldPropsProp?.name}
                                specialName={specialName}
                                onChange={onChange}
                            />
                        )}
                        <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                            <DDatePicker
                                mirrored={isFieldMirrored}
                                id={testId ?? kebabCase(label ?? fieldPropsProp?.name)}
                                fieldProps={fieldProps}
                                showTime={showTime}
                                disabled={disabled || isInvisible}
                                onFocus={() => handleFocus(setFocused)}
                                onBlur={(e) => {
                                    fieldHelpers?.setTouched(true);
                                    handleBlur(setFocused, fieldProps, e);
                                }}
                                onChange={(date) => {
                                    if (onChange) onChange(date);
                                    else
                                        fieldProps.onChange({
                                            target: {
                                                value: date === null ? date : date?.toISOString(),
                                                name: fieldProps.name,
                                            },
                                        });

                                    handleOpenMirrorSeveringModal();
                                }}
                                status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                condensed={condensed}
                                getPopupContainer={getPopupContainer}
                            />
                            <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                                {!!fieldMeta?.error && !!fieldMeta?.touched && (
                                    <ErrorLabel>{fieldMeta?.error}</ErrorLabel>
                                )}
                            </ErrorDiv>
                        </FormItem>
                    </>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldMeta?.error, fieldMeta?.touched, fieldMeta?.value, onChange, disabled, viewOnly]
    );

    if (hidden || (isInvisible && !template)) return null;

    return render;
};

export default DeclarationDatepicker;
