import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';

const ItineraryCard: FC = () => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('Itinerary')}>
            <FormCardContainer>
                <MultipleDeclarationField name="goodsShipment.consignment.borderTransportMeans.itinerary.countryOfRoutingCode">
                    {(fieldProps, controls) => (
                        <CountrySelect
                            required
                            {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                            label={t('countryOfRoutingCode')}
                            tooltip={t('defaultTooltip')}
                            condensed
                            multipleF={controls}
                        />
                    )}
                </MultipleDeclarationField>
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ItineraryCard;
