import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import ExchangeRateBlock, { exchangeRateBlockValidation } from '../blocks/ExchangeRateBlock';

export const exchangeRateCardValidation = {
    selfValidators: [exchangeRateBlockValidation],
};

const ExchangeRateCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('exchangeRate')} hidden={props.hidden}>
            <FormCardContainer>
                <ExchangeRateBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ExchangeRateCard;
