import { WarningTwoTone } from '@ant-design/icons';
import { Col } from 'antd';
import { FC, useMemo } from 'react';
import { Amendable } from 'store/declarations/declaration';
import { colors } from 'theme';
import { FieldGroupRemarks, FieldsLabels, FieldsText } from './FormHeader.styles';

interface Props {
    amendableDeclaration: Amendable | undefined;
}

const AmendmentInfo: FC<Props> = ({ amendableDeclaration }) => {
    const info = useMemo(() => {
        if (amendableDeclaration?.amendmentAccepted) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Amendment: </FieldsLabels>
                        <FieldsText>{`Accepted`}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }

        if (amendableDeclaration?.amendmentRejected) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Amendment: </FieldsLabels>
                        <FieldsText>{`Rejected`}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }

        if (amendableDeclaration?.userAmendmentSubmitted) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Amendment: </FieldsLabels>
                        <FieldsText>{`Requested`}</FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }

        if (amendableDeclaration?.revenueAmendmentRequested) {
            return (
                <Col>
                    <FieldGroupRemarks>
                        <FieldsLabels>Amendment: </FieldsLabels>
                        <FieldsText>
                            {`Requested by Revenue`} <WarningTwoTone twoToneColor={colors.orange} />
                        </FieldsText>
                    </FieldGroupRemarks>
                </Col>
            );
        }
        return <></>;
    }, [amendableDeclaration]);

    return <>{info}</>;
};
export default AmendmentInfo;
