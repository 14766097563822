import { Col, Modal, Row, notification, TablePaginationConfig } from 'antd';
import Container from 'components/ui/base/container';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import Filter from '../../components/ui/composed/filter/Filter';
import { FilterResult } from 'components/ui/composed/filter/filter-result';
import { getFiltersForDeclaration } from 'components/ui/composed/filter/filter-utils';
import ListFilter from 'components/ui/composed/filter/ListFilters';
import { enumToText } from 'core/utils/enum-to-text';
import { capitalize } from 'core/utils/strings';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { useRequestPromise } from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    getIrelandSadDraft,
    getIrelandClearanceDraft,
    generateIrelandSadDraft,
    generateIrelandClearanceDraft,
    listDeclarations as listDeclarationsRequest,
    archiveDeclaration,
    unarchiveDeclaration,
    listArchivedDeclarations,
    deleteDeclaration,
} from 'store/declarations/client';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { CreateButton, StyledDivider, TableDiv } from './DeclarationDashboard.styles';
import DeclarationTable, { DeclarationTableType } from './declaration-table/DeclarationsTable';
import useDeclarationFilter from '../../hooks/useDeclarationFilter';
import { DocumentsDiv, SadDocTitle } from '../declarations/common/summary-view/SummaryView.styles';
import DocumentTable from '../declarations/common/summary-view/DocumentTable';
import StatusSegmented from 'views/declarations/common/dashboard/StatusSegmented';
import { ListPayload } from 'core/http/response';
import MirrorDeclarationModal from './declaration-table/MirrorDeclarationModal';
import { getTableChangeParams } from '../../utils/tableHelpers';
import { DeclarationExternalEntity } from '../../store/declarations/enums/common/declaration-external-entity';

interface PrintModalValues {
    visible: boolean;
    declarationId: string | undefined;
}

const DeclarationDashboard: FC = () => {
    const { country, type } = useParams<{ country: DeclarationCountry; type: DeclarationInternalType }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('customs_declarations');
    const { setBreadcrumbRoutes } = useBreadcrumb();

    const [paginator, setPaginator] = useState<TablePaginationConfig>({});
    const [searchQuery, setSearchQuery] = useState<string>('');

    const { declarationFilter, setDeclarationFilter } = useDeclarationFilter();
    const [filterResult, setFilterResult] = useState<FilterResult[]>(
        declarationFilter.declarationType === type ? declarationFilter.filter : []
    );

    const [printModalState, setPrintModalState] = useReducer(
        (initialState: PrintModalValues, newState: Partial<PrintModalValues>) => ({ ...initialState, ...newState }),
        { visible: false, declarationId: undefined }
    );

    const [archiveIds, setArchiveIds] = useState<string[]>([]);
    const [archiveModalVisible, setArchiveModalVisible] = useState<boolean>(false);
    const showArchiveModal = useCallback(() => setArchiveModalVisible(true), []);
    const hideArchiveModal = useCallback(() => {
        setArchiveModalVisible(false);
        setArchiveIds([]);
    }, []);
    const [unarchiveModalVisible, setUnarchiveModalVisible] = useState<boolean>(false);
    const showUnarchiveModal = useCallback(() => setUnarchiveModalVisible(true), []);
    const hideUnarchiveModal = useCallback(() => {
        setUnarchiveModalVisible(false);
        setArchiveIds([]);
    }, []);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [deleteDeclarationId, setDeleteDeclarationId] = useState<string | undefined>(undefined);

    const atArchivedPath = useMemo(() => location.pathname.includes('archived'), [location.pathname]);

    useEffect(() => {
        if (!(type && filterResult)) return;
        setDeclarationFilter({ declarationType: type, filter: filterResult });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, filterResult]);

    const declarationExternalEntityParams = useMemo(() => {
        const declarationParams: {
            declarationExternalEntity: DeclarationExternalEntity[];
            declarationInternalType: DeclarationInternalType[];
        } = {
            declarationInternalType: type ? [type] : [],
            declarationExternalEntity: [],
        };

        if (country === DeclarationCountry.IRELAND) {
            declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.REVENUE);
        } else if (country === DeclarationCountry.UK) {
            if (type === DeclarationInternalType.IMPORT) {
                declarationParams.declarationInternalType.push(DeclarationInternalType.IMPORT_NEW);
            }
            declarationParams.declarationExternalEntity.push(DeclarationExternalEntity.CDS);
        }

        return declarationParams;
    }, [type, country]);

    const getDeclarationParameters = useCallback(
        ({
            searchQueryParam,
            filters,
            pagination,
        }: {
            searchQueryParam?: string;
            filters?: FilterResult[];
            pagination?: TablePaginationConfig;
        }) => ({
            ...declarationExternalEntityParams,
            ...getFiltersForDeclaration(filters ?? filterResult),
            ...getTableChangeParams({ pagination: pagination ?? paginator }),
            query: searchQueryParam ?? searchQuery,
            archived: atArchivedPath,
        }),
        [atArchivedPath, declarationExternalEntityParams, filterResult, paginator, searchQuery]
    );

    const {
        data: declarations,
        isLoading,
        refetch: listDeclarations,
    } = useRequestPromise(
        (params?: any) => {
            const { archived, ...rest } = params ?? {};
            return archived ? listArchivedDeclarations(rest) : listDeclarationsRequest(rest);
        },
        {
            args: [{ ...declarationExternalEntityParams, archived: atArchivedPath }],
            withSignal: true,
        }
    );

    const debouncedSearch = debounce((query: string) => {
        listDeclarations(getDeclarationParameters({ searchQueryParam: query }));
    }, 700);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customs Declarations',
                path: '',
            },
            {
                breadcrumbName: t(`custom${country}`),
                path: '',
            },
            {
                breadcrumbName: t(`${type}${country}`),
                path: '',
            },
        ]);

        return () => {
            setFilterResult([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type]);

    const onFilterChange = useCallback(
        (filters: FilterResult[]) => {
            setFilterResult(filters);
            listDeclarations(getDeclarationParameters({ filters }));
        },
        [listDeclarations, getDeclarationParameters]
    );

    const handlePrintButtonClick = (declarationId: string | undefined) => {
        if (!declarationId) return;

        Promise.all([generateIrelandSadDraft(declarationId), generateIrelandClearanceDraft(declarationId)]).then(() =>
            setPrintModalState({ visible: true, declarationId })
        );
    };

    const handleDuplicate = async (declaration: Declaration) => {
        navigate(`/invoice-upload/${country}/${type}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    const handleDeleteButtonClick = (deleteId: string | undefined) => {
        setDeleteDeclarationId(deleteId);
        setDeleteModalVisible(true);
    };

    const handleDeleteDeclaration = () => {
        if (!deleteDeclarationId)
            return notification.error({ message: 'Something went wrong, please try again later!' });

        deleteDeclaration(deleteDeclarationId)
            .then(() => {
                notification.success({ message: 'Declaration deleted successfully!' });
                listDeclarations(getDeclarationParameters({}));
            })
            .catch(() => {
                notification.error({ message: 'Something went wrong, please try again later!' });
            })
            .finally(() => {
                setDeleteModalVisible(false);
                setDeleteDeclarationId(undefined);
            });
    };

    const handleArchive = (ids: string[]) => {
        setArchiveIds(ids);
        showArchiveModal();
    };

    const handleUnarchive = (ids: string[]) => {
        setArchiveIds(ids);
        showUnarchiveModal();
    };

    const onDeclarationTableChange = (pagination: TablePaginationConfig) => {
        setPaginator(pagination);
        listDeclarations(getDeclarationParameters({ pagination }));
    };

    const createDeclarationButtonText = useMemo(() => {
        return type !== DeclarationInternalType.ARRIVAL
            ? `Create ${enumToText(type)} ${capitalize(country!)} Declaration`
            : `Create Arrival at Exit Declaration`;
    }, [type, country]);

    useEffect(() => {
        listDeclarations({
            ...declarationExternalEntityParams,
            ...(declarationFilter.declarationType === type && getFiltersForDeclaration(declarationFilter.filter)),
            archived: atArchivedPath,
        }).catch((err) => {
            if (err.message === 'canceled') Promise.resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const archiveDeclarations = async () => {
        hideArchiveModal();
        Promise.all(archiveIds.map((id) => archiveDeclaration(id)))
            .then((res) => {
                listDeclarations(getDeclarationParameters({}));
                notification.success({ message: 'Declaration archived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not archive the declaration!' });
            })
            .finally(() => {
                setArchiveIds([]);
            });
    };
    const unarchiveDeclarations = async () => {
        hideUnarchiveModal();
        Promise.all(archiveIds.map((id) => unarchiveDeclaration(id)))
            .then((res) => {
                listDeclarations(getDeclarationParameters({}));
                notification.success({ message: 'Declaration unarchived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not unarchive the declaration!' });
            })
            .finally(() => {
                setArchiveIds([]);
            });
    };

    const [declarationToBeMirrored, setDeclarationToBeMirrored] = useState<Declaration | null>(null);
    const [mirrorDeclarationModalVisible, setMirrorDeclarationModalVisible] = useState(false);
    const openMirrorDeclarationModal = useCallback((declaration: Declaration) => {
        setDeclarationToBeMirrored(declaration);
        setMirrorDeclarationModalVisible(true);
    }, []);
    const closeMirrorDeclarationModal = useCallback(() => {
        setDeclarationToBeMirrored(null);
        setMirrorDeclarationModalVisible(false);
    }, []);

    const initiateMirroringFlow = (data: { country: string; declarationType: string }) => {
        if (!declarationToBeMirrored) throw new Error('No declaration was selected to be mirrored');
        navigate(`/invoice-upload/${data.country}/${data.declarationType}/job/${declarationToBeMirrored.jobId}`, {
            state: { declaration: declarationToBeMirrored, mirroring: true },
        });
        setDeclarationToBeMirrored(null);
    };

    return (
        <>
            <CustomModal
                title={<H5>Do you want to delete this declaration?</H5>}
                centered
                visible={deleteModalVisible}
                onOk={handleDeleteDeclaration}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                confirmText="Delete"
                cancelText="Cancel"
                contentText={'The declaration will be deleted and all information associated with it will be lost.'}
            />
            <CustomModal
                title={
                    archiveIds.length > 1 ? (
                        <H5>Do you want to archive these declarations?</H5>
                    ) : (
                        <H5>Do you want to archive this declaration?</H5>
                    )
                }
                centered
                visible={archiveModalVisible}
                onOk={archiveDeclarations}
                onCancel={hideArchiveModal}
                width={762}
                confirmText="Archive"
                cancelText="Cancel"
                contentText={
                    archiveIds.length > 1
                        ? 'The declarations will be added to the archive.'
                        : 'The declaration will be added to the archive.'
                }
            />
            <CustomModal
                title={
                    archiveIds.length > 1 ? (
                        <H5>Do you want to unarchive these declarations?</H5>
                    ) : (
                        <H5>Do you want to unarchive this declaration?</H5>
                    )
                }
                centered
                visible={unarchiveModalVisible}
                onOk={unarchiveDeclarations}
                onCancel={hideUnarchiveModal}
                width={762}
                confirmText="Unarchive"
                cancelText="Cancel"
                contentText={
                    archiveIds.length > 1
                        ? 'The declarations will be unarchived and become active.'
                        : 'The declaration will be unarchived and become active.'
                }
            />
            <Modal
                centered
                width={762}
                visible={printModalState.visible}
                onCancel={() => setPrintModalState({ visible: false })}
                footer={null}
                bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '3.2rem',
                    minHeight: '236px',
                }}
            >
                <>
                    <DocumentsDiv>
                        <SadDocTitle>SAD Document</SadDocTitle>
                        <DocumentTable
                            id={printModalState.declarationId}
                            request={getIrelandSadDraft}
                            fileName="sad-document"
                        />
                    </DocumentsDiv>
                    <DocumentsDiv>
                        <SadDocTitle>Clearance Slip</SadDocTitle>
                        <DocumentTable
                            id={printModalState.declarationId}
                            request={getIrelandClearanceDraft}
                            fileName="clearanceslip-document"
                        />
                    </DocumentsDiv>
                </>
            </Modal>
            <Container>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <H5>{`${t(type!)} ${t('declarations')}`}</H5>

                        <CreateButton
                            type="primary"
                            size="large"
                            onClick={() => navigate(`/invoice-upload/${country}/${type}`)}
                            disabled={atArchivedPath}
                        >
                            {createDeclarationButtonText}
                        </CreateButton>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <StatusSegmented value={atArchivedPath ? 'archived' : 'active'} />
                    </div>
                </div>
                <StyledDivider />
                <Row gutter={16} wrap={false}>
                    <Col flex="auto">
                        <SearchBar
                            inputPlaceholder={t('SearchByDeclarationTable')}
                            onSearch={(value) => {
                                setSearchQuery(value);
                                debouncedSearch(value);
                            }}
                            onClear={() => {
                                setSearchQuery('');
                                listDeclarations({ ...declarationExternalEntityParams });
                            }}
                        />
                    </Col>
                    <Col>
                        <Filter filterState={filterResult} onFilterChange={onFilterChange} />
                    </Col>
                </Row>

                <TableDiv>
                    <ListFilter filters={filterResult} onFilter={onFilterChange} />
                    {declarations && (
                        <DeclarationTable
                            data={declarations as ListPayload<DeclarationAndAgent>}
                            handleDuplicate={handleDuplicate}
                            onMirror={openMirrorDeclarationModal}
                            onUnarchive={handleUnarchive}
                            onArchive={handleArchive}
                            onDelete={handleDeleteButtonClick}
                            loading={isLoading}
                            type={DeclarationTableType.DECLARATIONS}
                            onPrint={(declarationId: string) => handlePrintButtonClick(declarationId)}
                            onChange={onDeclarationTableChange}
                        />
                    )}
                </TableDiv>
            </Container>
            <MirrorDeclarationModal
                visible={mirrorDeclarationModalVisible}
                onCancel={closeMirrorDeclarationModal}
                onContinue={initiateMirroringFlow}
            />
        </>
    );
};

export default DeclarationDashboard;
