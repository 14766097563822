import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import useCodelists from '../../../../../hooks/useCodelists';

type Fields = 'id' | 'categoryCode';

export const holderOfAuthorisationIdentificationNumberBlockValidation: {
    childValidators: {
        authorisationHolder: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        authorisationHolder: {
            childValidators: {
                id: [validators.required(), validators.eori()],
                categoryCode: [validators.required(), validators.maxLength(4)],
            },
            stopAtNull: true,
        },
    },
};

export const holderOfAuthorisationIdentificationNumberRequiredBlockValidation: {
    childValidators: {
        authorisationHolder: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        authorisationHolder: {
            selfValidators: [validators.minLength(1)],
            childValidators: {
                id: [validators.required(), validators.eori()],
                categoryCode: [validators.required(), validators.maxLength(4)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const HolderOfAuthorisationIdentificationNumberBlock = ({
    path = 'authorisationHolder',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '3/39', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        required
                        label={t('authorisationHolder.identificationNumber')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="categoryCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        required
                        label={t('authorisationHolder.authorisationType')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.partyRoleAuthorizationTypes}
                        condensed
                        codeValidation={[validators.maxLength(4)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default HolderOfAuthorisationIdentificationNumberBlock;
