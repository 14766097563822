import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { Declaration, DeclarationDocuments, DeclarationHistory } from 'store/declarations/declaration';
import { DeclarationsActions } from './actionTypes';

export interface DeclarationsState {
    isLoading: boolean;
    error?: ErrorResponse;
    declaration?: Declaration;
    declarations: ListPayload<Declaration> | null;
    documents?: ListPayload<DeclarationDocuments> | null;
    focused: boolean;
    declarationHistory?: DeclarationHistory[];
    declarationTemplate?: TemplateResponse;
}

export const initialDeclarationsState: DeclarationsState = {
    isLoading: false,
    declarations: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    documents: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    focused: false,
    declarationHistory: [],
};

const declarations: Reducer<DeclarationsState, DeclarationsActions> = (
    state = initialDeclarationsState,
    action
): DeclarationsState => {
    switch (action.type) {
        case 'LIST_DECLARATIONS_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'LIST_DECLARATIONS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declarations: action.payload,
            };
        case 'LIST_DECLARATIONS_ERROR':
            return {
                ...state,
                isLoading: false,
                declarations: state.declarations,
                error: action.error,
            };
        case 'CREATE_IRELAND_IMPORT_DECLARATION_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'CREATE_IRELAND_IMPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'CREATE_IRELAND_IMPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'CREATE_IRELAND_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'CREATE_IRELAND_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'CREATE_IRELAND_EXPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'CREATE_UK_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_UK_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'CREATE_UK_EXPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'UPDATE_IRELAND_IMPORT_DECLARATION_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'UPDATE_IRELAND_IMPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'UPDATE_IRELAND_IMPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'UPDATE_IRELAND_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'UPDATE_IRELAND_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'UPDATE_IRELAND_EXPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'UPDATE_UK_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'UPDATE_UK_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'UPDATE_UK_EXPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'GET_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.declaration,
                declarationTemplate: action.declarationTemplate,
            };
        case 'GET_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'SUBMIT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'SUBMIT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                declaration: action.payload,
            };
        case 'SUBMIT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                declaration: state.declaration,
            };
        case 'GET_DECLARATION_DOCUMENTS':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_DECLARATION_DOCUMENTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                documents: action.payload,
            };
        case 'GET_DECLARATION_DOCUMENTS_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'UPDATE_DECLARATION_CORE_TEMPLATE_STATUS_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_UPDATE_ENS_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_UPDATE_ENS_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_UPDATE_ENS_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_CREATE_ENS_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_CREATE_ENS_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_CREATE_ENS_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DUPLICATE_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DUPLICATE_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DUPLICATE_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'SET_INPUT_FOCUSED':
            return { ...state, focused: action.payload };
        case 'INVALIDATE_DECLARATION_REQUEST':
        case 'INVALIDATE_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'INVALIDATE_DECLARATION_SUCCESS':
        case 'INVALIDATE_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declarationHistory: state.declarationHistory
                    ? [...state.declarationHistory, action.payload]
                    : [action.payload],
            };
        case 'INVALIDATE_DECLARATION_ERROR':
        case 'INVALIDATE_EXPORT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'SUBMIT_PRESENTATION_NOTIFICATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'SUBMIT_PRESENTATION_NOTIFICATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'SUBMIT_PRESENTATION_NOTIFICATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload.declaration,
            };
        case 'SUBMIT_IRELAND_IMPORT_DOCUMENTS_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'CLEAR_DECLARATION_LIST': {
            return {
                ...state,
                declarations: initialDeclarationsState.declarations,
            };
        }
        case 'AMENDMENT_IRELAND_IMPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'AMENDMENT_IRELAND_IMPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload.declaration,
            };
        case 'AMENDMENT_IRELAND_IMPORT_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'GET_DECLARATION_HISTORY_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_DECLARATION_HISTORY_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declarationHistory: action.payload,
            };
        case 'GET_DECLARATION_HISTORY_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'AMENDMENT_IRELAND_EXPORT_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'AMENDMENT_IRELAND_EXPORT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload.declaration,
            };
        case 'AMENDMENT_IRELAND_EXPORT_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_CREATE_ETD_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_CREATE_ETD_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_CREATE_ETD_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_UPDATE_ETD_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_UPDATE_ETD_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_UPDATE_ETD_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_CREATE_TSD_DECLARATION':
        case 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_CREATE_TSD_DECLARATION_SUCCESS':
        case 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_CREATE_TSD_DECLARATION_ERROR':
        case 'DO_CREATE_ARRIVAL_AT_EXIT_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'DO_UPDATE_TSD_DECLARATION':
        case 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DO_UPDATE_TSD_DECLARATION_SUCCESS':
        case 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'DO_UPDATE_TSD_DECLARATION_ERROR':
        case 'DO_UPDATE_ARRIVAL_AT_EXIT_DECLARATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'AMENDMENT_IRELAND_ENS_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'AMENDMENT_IRELAND_ENS_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'AMENDMENT_IRELAND_ENS_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'CANCEL_DECLARATION_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CANCEL_DECLARATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                declaration: action.payload,
            };
        case 'CANCEL_DECLARATION_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};

export default declarations;
