import Drawer from 'components/ui/base/drawer/Drawer';
import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo } from 'react';
import { Declaration } from 'store/declarations/declaration';
import IrelandEnsDeclarationErrors from './declaration-errors/IrelandEnsDeclarationErrors';
import AisAesDeclarationErrors from './declaration-errors/AisAesDeclarationErrors';
import UkExportDeclarationErrors from './declaration-errors/UkExportDeclarationErrors';
import { IrelandNotification } from 'store/declarations/ireland/ireland-notification';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { CdsExportNotification } from 'store/declarations/uk/export-declaration';
import { sortBy } from 'lodash';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';
import {
    AesInvalidNotification,
    AesNotification,
    AesRejectedNotification,
} from '../../../../store/declarations/ireland/export-declaration';
import ConditionalWrapper from '../../../../components/ConditionalWrapper';

interface Props {
    declaration?: Declaration;
    visible: boolean;
    onClose: () => void;
    formik?: FormikProps<any>;
    showRawData?: boolean;
}

const DeclarationErrorsDrawer: FC<Props> = ({ declaration, visible, onClose, formik, showRawData }) => {
    const declarationErrors = useMemo(() => {
        const declarationType =
            declaration?.irelandImportDeclaration ??
            declaration?.irelandH7ImportDeclaration ??
            declaration?.ieExportDeclaration ??
            declaration?.entrySummaryDeclaration ??
            declaration?.cdsExportDeclaration;

        if (!declarationType?.notifications) {
            return <></>;
        }

        if (declaration?.status === DeclarationStatus.SUBMITTED || declaration?.status === DeclarationStatus.ACCEPTED) {
            return <></>;
        }

        const lastNotificationIndex = declarationType.notifications.length - 1;

        const sortedNotifications = declarationType.notifications.some(
            (declarationNotification) =>
                'notificationDate' in declarationNotification || 'createdAt' in declarationNotification
        )
            ? sortBy(declarationType.notifications as IrelandNotification[], ['notificationDate', 'createdAt'])
            : declarationType.notifications;

        const latestNotification = sortedNotifications?.[lastNotificationIndex];

        const errors =
            (latestNotification as IrelandNotification | IrelandEnsNotification | undefined)?.submissionErrors ?? // Ireland
            (latestNotification as CdsExportNotification | undefined)?.validationMessages ?? // CDS Export
            (((latestNotification as AesNotification)?.notification as AesRejectedNotification)?.functionalError ||
                ((latestNotification as AesNotification)?.notification as AesInvalidNotification)?.xmlError); // Ireland Export

        const additionalInformation = (latestNotification as CdsExportNotification | undefined)
            ?.additionalInformationMessages; // Cds Export

        let DeclarationErrorsComponent;
        let specificProps;
        switch (declarationType) {
            case declaration?.irelandImportDeclaration:
            case declaration?.irelandH7ImportDeclaration:
            case declaration?.ieExportDeclaration:
                DeclarationErrorsComponent = AisAesDeclarationErrors;
                specificProps = { latestNotification, showRawData };
                break;

            case declaration?.entrySummaryDeclaration:
                DeclarationErrorsComponent = IrelandEnsDeclarationErrors;
                specificProps = { notifications: sortedNotifications };
                break;

            case declaration?.cdsExportDeclaration:
                DeclarationErrorsComponent = UkExportDeclarationErrors;
                specificProps = { additionalInformation, notifications: sortedNotifications };
                break;

            default:
                return <></>;
        }

        return <DeclarationErrorsComponent errors={errors} onClose={onClose} {...(specificProps || {})} />;
    }, [onClose, declaration, showRawData]);

    return (
        <Drawer title="Declaration errors" visible={visible} onClose={onClose} width={820} warning={true}>
            <ConditionalWrapper
                condition={Boolean(formik)}
                wrapper={(children) => <FormikProvider value={formik as FormikProps<any>}> {children}</FormikProvider>}
            >
                {declarationErrors}
            </ConditionalWrapper>
        </Drawer>
    );
};
export default DeclarationErrorsDrawer;
