import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportGoodsInformation } from 'store/declarations/ireland/refunds/deposits/ireland-refund-deposit';
import { CardContent } from 'views/declarations/common/box44/Box44Card.styles';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const ExportGoodsInformationCard: FC<DeclarationFormCardProps> = ({
    selectCard,
    keyCard,
    defaultOpen = false,
    viewOnly = false,
    expandAll = false,
    cardTotal,
    cardNumber,
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
}) => {
    const { t } = useTranslation('form');
    const formik = useFormik<ExportGoodsInformation>({
        initialValues: {},
        validationSchema: Yup.object({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });
    const {
        setValues,
        getFieldProps: formGetFieldProps,
        getFieldHelpers: formGetFieldHelpers,
        getFieldMeta: formGetFieldMeta,
        resetForm,
    } = formik;

    const { modalVisible, handleEdit, handleCancel, onClickAddItem, handleDelete, addItem } = useModalMultipleCards({
        formikProps: {
            fieldProps: getFieldProps('exportGoodsInformation'),
            fieldHelper: getFieldHelpers('exportGoodsInformation'),
            fieldMeta: getFieldMeta ? getFieldMeta('exportGoodsInformation') : undefined,
        },
        setValues,
        resetForm,
    });

    const goodsInformationDataList = useMemo(() => {
        const list = getFieldProps('exportGoodsInformation').value;
        if (Array.isArray(list)) {
            return list.map((obj: ExportGoodsInformation, index) => {
                return [
                    {
                        field: t('depositRefund.goodsDescription'),
                        value: obj.goodsDescription,
                    },
                    {
                        field: t('remissionRefund.combinedNomenclatureCode'),
                        value: obj.commodityCode?.combinedNomenclatureCode ?? '-',
                    },
                    {
                        field: t('depositRefund.taricCode'),
                        value: obj.commodityCode?.taricCode ?? '-',
                    },
                    {
                        field: t('goodsItems.netMass'),
                        value: obj.netMass?.toString(),
                    },
                    {
                        field: t('goodsItems.supplementaryUnits'),
                        value: obj.supplementaryUnits?.toString(),
                    },
                ];
            });
        }
        return [];
    }, [getFieldProps, t]);

    return (
        <section
            id={'deposit-export-goods-information-form-card'}
            onClick={() => {
                if (selectCard) {
                    selectCard('deposit-export-goods-information-form-card');
                }
            }}
        >
            <FormCard
                key={keyCard}
                keyCard={keyCard}
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                title={t('depositRefund.exportGoodsItems.title')}
                expandAll={expandAll}
                cardNumber={cardNumber}
                total={cardTotal}
                showRightSideCardState={false}
            >
                <CardContent>
                    <CardList
                        data={goodsInformationDataList}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        viewOnly={viewOnly ?? false}
                    />
                    {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
                </CardContent>
            </FormCard>
            <ModalExportGoodsInformation
                getFieldHelpers={formGetFieldHelpers}
                getFieldMeta={formGetFieldMeta}
                getFieldProps={formGetFieldProps}
                modalVisible={modalVisible}
                handleCancel={handleCancel}
                viewOnly={viewOnly}
                formik={formik}
            />
        </section>
    );
};
export default ExportGoodsInformationCard;

interface PropsModalGoodsInformation {
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    modalVisible: boolean;
    handleCancel: () => void;
    formik: ReturnType<typeof useFormik>;
    viewOnly: boolean;
}

export const ModalExportGoodsInformation: FC<PropsModalGoodsInformation> = ({
    getFieldHelpers,
    getFieldProps,
    getFieldMeta,
    modalVisible,
    handleCancel,
    formik,
    viewOnly,
}) => {
    const { t } = useTranslation('form');
    return (
        <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
            <Row gutter={12}>
                <Col xs={24}>
                    <FormInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`commodityCode.combinedNomenclatureCode`, {
                            getFieldHelpers: getFieldHelpers,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                        })}
                        label={t('remissionRefund.combinedNomenclatureCode')}
                    />
                    <FormInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`commodityCode.taricCode`, {
                            getFieldHelpers: getFieldHelpers,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                        })}
                        label={t('depositRefund.taricCode')}
                    />
                    <FormInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsDescription`, {
                            getFieldHelpers: getFieldHelpers,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                        })}
                        label={t('depositRefund.goodsDescription')}
                    />
                    <FormInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`netMass`, {
                            getFieldHelpers: getFieldHelpers,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                        })}
                        label={t('goodsItems.netMass')}
                    />
                    <FormInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`supplementaryUnits`, {
                            getFieldHelpers: getFieldHelpers,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                        })}
                        label={t('goodsItems.supplementaryUnits')}
                    />
                </Col>
            </Row>
        </ModalEditCardListItem>
    );
};
