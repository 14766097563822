import { useCallback, useEffect } from 'react';
import { doGetAisBusinessRules } from 'store/ais-business-rules/action';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useAisBusinessRules = () => {
    const rules = useAppSelector((state) => state.aisBusinessRules.rules.list);
    const error = useAppSelector((state) => state.aisBusinessRules.error);
    const isLoading = useAppSelector((state) => state.aisBusinessRules.isLoading);

    const dispatch = useAppDispatch();

    const getAisBusinessRules = useCallback(() => dispatch(doGetAisBusinessRules()), [dispatch]);

    useEffect(() => {
        if (!rules.length) {
            getAisBusinessRules();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        rules,
    };
};

export default useAisBusinessRules;
