import * as Yup from 'yup';
import { getRequiredMessage } from 'views/declarations/utils/validation-utils';

const companyValidateSchema = Yup.object().shape({
    deferredAccountNumber: Yup.string().required(getRequiredMessage('deferredAccountNumber')).nullable(),
    name: Yup.string().required(getRequiredMessage('name')).nullable(),
    contact: Yup.object().shape({
        email: Yup.string().email('Not a valid email format!'),
    }),
    eori: Yup.string().nullable(),
    address: Yup.object().shape({
        addressLine1: Yup.string().required(getRequiredMessage('addressLine1')).nullable(),
        postCode: Yup.string().required(getRequiredMessage('postCode')).nullable(),
        city: Yup.string().required(getRequiredMessage('city')).nullable(),
        country: Yup.string().required(getRequiredMessage('country')).nullable(),
    }),
});

export default companyValidateSchema;
