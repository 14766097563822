export enum SupplyChainActorRoleCode {
    CS = 'CS', //Consolidator
    FW = 'FW', //Freight Forwarder
    MF = 'MF', //Manufacturer
    WH = 'WH', //Warehouse Keeper
}

export const supplyChainActorRoleCodeLabels = [
    { id: SupplyChainActorRoleCode.CS, value: 'Consolidator' },
    { id: SupplyChainActorRoleCode.FW, value: 'Freight Forwarder' },
    { id: SupplyChainActorRoleCode.MF, value: 'Manufacturer' },
    { id: SupplyChainActorRoleCode.WH, value: 'Warehouse Keeper' },
] as const;
