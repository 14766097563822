import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'additionCode';

export const valuationIndicatorsBlockValidation: {
    childValidators: {
        valuationAdjustment: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        valuationAdjustment: {
            childValidators: {
                additionCode: [validators.exact(4)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ValuationIndicatorsBlock = ({ path = 'valuationAdjustment', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/13', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="additionCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('valuationIndicators')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.valuationIndicatorTypes}
                        condensed
                        codeValidation={[validators.exact(4)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default ValuationIndicatorsBlock;
