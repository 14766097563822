import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { AepBusinessRulesActions } from './actionType';
import { AepBusinessRule } from './aep-business-rule';

export interface AepBusinessRulesState {
    isLoading: boolean;
    error?: ErrorResponse;
    rules: ListPayload<AepBusinessRule>;
}

export const aepBusinessRulesInitialState: Readonly<AepBusinessRulesState> = {
    isLoading: false,
    rules: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const aepBusinessRules: Reducer<AepBusinessRulesState, AepBusinessRulesActions> = (
    state = aepBusinessRulesInitialState,
    action
): AepBusinessRulesState => {
    switch (action.type) {
        case 'LIST_AEP_BUSINESS_RULES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_AEP_BUSINESS_RULES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                rules: action.payload,
            };
        case 'LIST_AEP_BUSINESS_RULES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default aepBusinessRules;
