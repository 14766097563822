import styled from 'styled-components';
import { colors } from 'theme';

export const CustomDiv = styled.div`
    width: 100%;
    background: ${colors.white};
    padding: 3.9rem;
    box-shadow: 0px 0px 24px rgba(9, 0, 255, 0.07);
    border-radius: 4px;
    border: 1px solid ${colors.darkGrey2};
`;
