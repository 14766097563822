import { Col, Row } from 'antd';
import { H5, Text } from 'components/ui/base/typography';
import config from 'config';
import useIndividuals from 'hooks/useIndividuals';
import useSession from 'hooks/useSession';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectToHmrc, disconnectToHmrc } from 'store/customers/client';
import {
    Break,
    ConnectedIcons,
    ConnectHmrc,
    DisconnectHmrc,
    HmrcConnectedContainer,
    HmrcIntro,
    RowConnectToHmrc,
} from '../Settings.styles';

const Hmrc: FC = () => {
    const { userInfo } = useSession();
    const { individual, getIndividual } = useIndividuals({ individualId: userInfo?.individualId });
    const [isConnected, setConnected] = useState<boolean>(() => individual?.hasHmrcToken || false);
    const { t } = useTranslation('settings');

    useEffect(() => {
        setConnected(individual?.hasHmrcToken || false);
    }, [individual]);

    const renderState = useMemo(() => {
        if (!isConnected) {
            return (
                <>
                    <Row>
                        <HmrcIntro>
                            <Text>{t('hmrc.intro')}</Text>
                        </HmrcIntro>
                    </Row>
                    <RowConnectToHmrc>
                        <Col>
                            <ConnectHmrc
                                onClick={async () => {
                                    if (!individual?.customerId) {
                                        return;
                                    }

                                    const response = await connectToHmrc(individual.customerId);

                                    if (response && response.data) {
                                        const url = `${response.data}&redirect_uri=${config.host}/settings/hmrc/confirmation?id=${individual.customerId}`;
                                        window.location = url as any;
                                    }
                                }}
                            >
                                {t('hmrc.connectToHmrc')}
                            </ConnectHmrc>
                        </Col>
                    </RowConnectToHmrc>
                </>
            );
        } else {
            return (
                <HmrcConnectedContainer>
                    <ConnectedIcons />
                    <Break />
                    <H5>{t('hmrc.connectedHmrc')}</H5>
                    <Break />
                    <DisconnectHmrc
                        onClick={async () => {
                            if (!individual?.customerId) {
                                return;
                            }

                            const response = await disconnectToHmrc(individual.customerId);

                            if (response.status === 200) {
                                getIndividual(individual.id);
                            }
                        }}
                    >
                        {t('hmrc.disconnectFromHmrc')}
                    </DisconnectHmrc>
                </HmrcConnectedContainer>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, t, individual]);

    return (
        <div>
            <Row>
                <Col>
                    <H5>{t('settings.hmrc')}</H5>
                </Col>
            </Row>
            {renderState}
        </div>
    );
};

export default Hmrc;
