import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import HideComponent from '../../../../../utils/HideComponent';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';

export type Fields =
    | 'code'
    | 'id'
    | 'lpcoExemptionCode'
    | 'name'
    | 'effectiveDateTime'
    | 'submitter.name'
    | 'writeOff.amountAmount'
    | 'writeOff.quantityQuantity';

export const additionalDocumentBlockValidation: {
    childValidators: {
        additionalDocument: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalDocument: {
            childValidators: {
                code: [validators.required(), validators.exact(4)],
                id: [validators.maxLength(35)],
                lpcoExemptionCode: [validators.exact(2)],
                name: [validators.maxLength(35)],
                effectiveDateTime: [],
                'submitter.name': [validators.maxLength(70)],
                'writeOff.amountAmount': [validators.maxLength(5)],
                'writeOff.quantityQuantity': [validators.number(), validators.float(16, 6)],
            },
            stopAtNull: true,
        },
    },
};

export const smallerAdditionalDocumentBlockValidation: {
    childValidators: {
        additionalDocument: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalDocument: {
            childValidators: {
                code: [validators.required(), validators.exact(4)],
                id: [validators.maxLength(35)],
                lpcoExemptionCode: [validators.exact(2)],
                name: [validators.maxLength(35)],
            },
            selfValidators: [validators.minLength(1)],
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const AdditionalDocumentBlock = ({ path = 'additionalDocument', hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const ucc = useMemo(() => '8/7', []);
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    return (
        <>
            <DeclarationField pathPrefix={path} name="code">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.code')}
                        refNumber={ucc}
                        selectOptions={
                            formType?.includes('IMPORT')
                                ? cdsCodelists?.importCertificates
                                : cdsCodelists?.exportCertificates
                        }
                        condensed
                        codeValidation={[validators.exact(4)]}
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormInput
                        maxLength={35}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.identifier')}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="lpcoExemptionCode">
                {({ form, field }) => (
                    <FormSelect
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.status')}
                        refNumber={ucc}
                        selectOptions={cdsCodelists?.documentExemptionTypes}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="name">
                {({ form, field }) => (
                    <FormInput
                        maxLength={35}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.reason')}
                        condensed
                    />
                )}
            </DeclarationField>

            <HideComponent hide={hide}>
                <>
                    <DeclarationField pathPrefix={path} name="effectiveDateTime">
                        {({ form, field }) => (
                            <DeclarationDatepicker
                                refNumber={ucc}
                                {...getFormikProps(field.name, form)}
                                label={t('writingOff.dateOfValidity')}
                                condensed
                            />
                        )}
                    </DeclarationField>
                    <DeclarationField pathPrefix={path} name="submitter.name">
                        {({ form, field }) => (
                            <FormInput
                                maxLength={70}
                                refNumber={ucc}
                                {...getFormikProps(field.name, form)}
                                label={t('writingOff.issuingAuthority')}
                                condensed
                            />
                        )}
                    </DeclarationField>
                    <DeclarationField pathPrefix={path} name="writeOff.amountAmount">
                        {({ form, field }) => (
                            <FormInput
                                maxLength={5}
                                refNumber={ucc}
                                {...getFormikProps(field.name, form)}
                                label={t('writingOff.measurementUnitAndQualifier')}
                                condensed
                            />
                        )}
                    </DeclarationField>
                    <DeclarationField pathPrefix={path} name="writeOff.quantityQuantity">
                        {({ form, field }) => (
                            <DeclarationNumberInput
                                maxLength={22}
                                refNumber={ucc}
                                {...getFormikProps(field.name, form)}
                                label={t('writingOff.quantity')}
                                condensed
                            />
                        )}
                    </DeclarationField>
                </>
            </HideComponent>
        </>
    );
};

export default AdditionalDocumentBlock;
