import { Cargo, CargoParams } from './cargo';
import { deleteCargo, getCargo, listCargos, postCargo, putCargo } from './client';

export const doCreateCargo: Function = (cargo: Cargo) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_CARGO_REQUEST' });
    try {
        const payload = await postCargo(cargo);

        dispatch({
            type: 'CREATE_CARGO_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_CARGO_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doListCargos: Function = (params?: Partial<CargoParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_CARGOS_REQUEST' });
    try {
        const payload = await listCargos(params);

        dispatch({
            type: 'LIST_CARGOS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_CARGOS_ERROR', error: e?.response?.data });
    }
};

export const doGetCargo: Function = (cargoId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_CARGO_REQUEST' });
    try {
        const payload = await getCargo(cargoId);
        dispatch({
            type: 'GET_CARGO_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_CARGO_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditCargo: Function = (cargo: Cargo) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_CARGO_REQUEST' });
    try {
        const payload = await putCargo(cargo);
        dispatch({
            type: 'EDIT_CARGO_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_CARGO_ERROR', error: e?.response?.data });
    }
};

export const doDeleteCargo: Function = (cargoId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_CARGO_REQUEST' });
    try {
        const payload = await deleteCargo(cargoId);
        dispatch({
            type: 'DELETE_CARGO_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_CARGO_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
