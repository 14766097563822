import { Button as AButton } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const DarkButton = styled(AButton)`
    background-color: ${colors.darkPurple};
    height: 4rem;
    color: ${colors.white};
    .ant-btn-primary {
        background-color: ${colors.darkPurple};
    }
`;

export const DefaultButton = styled(AButton)`
    color: ${colors.purpleBlue};
    background: ${colors.white};
    border: 1px solid ${colors.purpleBlue};
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    &:hover {
        border: 1px solid ${colors.purpleBlue};
        background: ${colors.purpleBlue};
        color: ${colors.white};
    }
    .ant-btn-lg {
        height: 4rem;
    }

    .ant-btn {
        height: 3.2rem;
    }
`;

export const PrimaryButton = styled(AButton)`
    color: ${colors.white};
    background: ${colors.darkPurple};
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-color: ${colors.darkPurple};

    &:hover {
        background: rgba(56, 55, 82, 0.9);
        border-color: rgba(56, 55, 82, 0.9);
    }

    .ant-btn-lg {
        height: 4rem;
    }

    .ant-btn {
        height: 3.2rem;
    }
`;

export const TextButton = styled(AButton)``;

export const LinkButton = styled(AButton)`
    .ant-btn-link {
        color: ${colors.blueLink};
        background: transparent;
        border-color: transparent;
        box-shadow: none;

        &:hover {
            color: ${colors.blueLink};
        }
    }
`;

export const NewButton = styled(AButton)<{ hollow?: boolean }>`
    background-color: ${({ hollow }) => (hollow ? 'transparent' : '#0040E4')};
    border-color: #0040e4;
    border-radius: 4px;
    color: ${({ hollow }) => (hollow ? '#0040E4' : 'white')};
    font-weight: bold;

    :focus,
    :hover {
        border-color: #0040e4;
        background-color: ${({ hollow }) => (hollow ? '#0040E4' : 'transparent')};
        color: ${({ hollow }) => (hollow ? 'white' : '#0040E4')};
    }
`;
