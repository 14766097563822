import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'totalPackageQuantity';

export const totalPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        totalPackageQuantity: [validators.required(), validators.number(), validators.maxLength(7)],
    },
};

export const cdsImportTotalPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        totalPackageQuantity: [validators.required(), validators.number(), validators.maxLength(8)],
    },
};

interface Props extends BlockProps<Fields> {}

const TotalPackagesBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/18', []);
    return (
        <DeclarationField name="totalPackageQuantity">
            {({ form, field }: FieldProps<any>) => (
                <FormInput
                    refNumber={ucc}
                    required
                    label={t('totalPakages')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default TotalPackagesBlock;
