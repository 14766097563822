import { FormikProps } from 'formik';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { ChildrenComponent } from './children-component';
import { ComponentTypeForm } from './component-type-form';

export enum MasterDetailsIrelandCardsType {
    SINGLE,
    NESTED_AUTHORIZATION,
    PARTIES,
}

export interface Card {
    key: string;
    type: MasterDetailsIrelandCardsType;
    cardNumber: number;
    defaultOpen: boolean;
    title?: string;
    valid: boolean;
    component: ComponentTypeForm;
    fields?: any[];
    getFields?: (index?: number) => any[];
    multiple?: boolean;
    propsPathPrefix?: string;
    listPath?: string;
    declarationType?: DeclarationName;
    pathOwnerOfGoods?: string;
    pathPlacesOfUse?: string;
    pathSupplyChainActor?: string;
    pathAdditionsDeductions?: string;
    children?: {
        propsPathPrefix: string;
        key: string;
        multiple?: boolean;
        getFields?: (index?: number, mainPath?: string) => any[];
        component: ChildrenComponent;
        listPath?: string;
    }[];
    other?: any;
    formik?: FormikProps<any>;
    condensed?: boolean;
}
