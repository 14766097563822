import { ReactElement } from 'react';
import AddressBlock from 'views/declarations/uk/export/blocks/customer/AddressBlock';
import { FormCardContainer } from '../../cards/NewFormCard';
import PartiesAddressFormProps from '../PartiesAddressFormProps';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';

const PartiesAddressForm = ({ party, refNumber, condensed, onBlur }: PartiesAddressFormProps): ReactElement => {
    const { template } = useTemplateContext();

    return (
        <FormCardContainer>
            <AddressBlock
                path={party.path}
                options={{ ucc: refNumber ?? '' }}
                fieldOptions={{
                    functionCode: { show: party.hasRepresentativeStatus && !template },
                    phoneNumber: { show: party.hasPhoneNumber },
                }}
                condensed={condensed}
                onBlur={onBlur}
            />
        </FormCardContainer>
    );
};

export default PartiesAddressForm;
