import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useLegs from 'hooks/useLegs';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { StyledLegDetailsTabs, StyledMobileLegView } from './MobileLegView.styles';
import { Leg } from 'store/journeys/legs/leg';
import Button from 'components/ui/base/button/Button';

type ContextType = { leg: Leg };

const MobileLegView: FC = () => {
    const { legId } = useParams();
    const navigate = useNavigate();
    const { leg, getLeg } = useLegs({
        journeyId: 'remove',
        legId,
    });
    const { setBreadcrumbRoutes } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbRoutes([{ breadcrumbName: 'Leg details', path: '' }]);
    }, [setBreadcrumbRoutes]);

    useEffect(() => {
        if (legId) {
            getLeg(legId);
        }
    }, [getLeg, legId]);

    return (
        <StyledMobileLegView>
            <StyledLegDetailsTabs>
                <Button type="link" onClick={() => navigate('details')}>
                    Details
                </Button>
                <Button type="link" onClick={() => navigate('map')}>
                    Map
                </Button>
            </StyledLegDetailsTabs>
            <Outlet context={{ leg }} />
        </StyledMobileLegView>
    );
};

export function useLeg() {
    return useOutletContext<ContextType>();
}

export default MobileLegView;
