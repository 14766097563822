import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const GoodShipmentItemCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const fields = props.getFields ? props.getFields() : [];

    return (
        <section
            id={'tsd-goods-shipment-item-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-goods-shipment-item-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('goodsInformation')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsItemNumber`, props)}
                            label={t('itemNumber')}
                        />
                    </Col>
                    <Col md={12}>
                        <FormInput
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsInformation.goodsDescription`, props)}
                            label={t('goodsDescription')}
                        />
                    </Col>
                    <Col md={12}>
                        <FormInput
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsInformation.grossMass`, props)}
                            label={t('grossMass')}
                        />
                    </Col>
                    <Col md={12}>
                        <FormInput
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsInformation.cusCode`, props)}
                            label={t('goodsItems.cusCode')}
                        />
                    </Col>
                    <Col md={12}>
                        <FormInput
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsInformation.commodityCode.combinedNomenclatureCode`, props)}
                            label={t('commodityCode')}
                        />
                    </Col>
                </Row>
            </FormCard>
        </section>
    );
};

export default GoodShipmentItemCard;
