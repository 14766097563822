import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import FormInput from '../../../../../components/ui/composed/declarations/formInput';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'conditionCode' | 'locationId' | 'locationName';

export const cdsImportAdditionalFiscalReferencesBlockValidation: {
    childValidators: {
        'goodsShipment.tradeTerms': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.tradeTerms': {
            childValidators: {
                conditionCode: [validators.exact(3)],
                locationId: [validators.maxLength(17)],
                locationName: [validators.maxLength(37)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const DeliveryTermsBlock = ({ path = 'goodsShipment.tradeTerms', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/1', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="conditionCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('conditionCode')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.incoTermCodes}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="locationID">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('locationID')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="locationName">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('countryCodeLocationName')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default DeliveryTermsBlock;
