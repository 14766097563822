import { Reducer } from 'redux';
import { Actions } from './actionTypes';
import { declarationHeaderInitialState, DeclarationHeaderState } from './declaration-header';

const declarationHeader: Reducer<DeclarationHeaderState> = (state = declarationHeaderInitialState, action: Actions) => {
    switch (action.type) {
        case 'COLLAPSE_DECLARATION_HEADER':
            return { ...state, collapsed: true };
        case 'EXPAND_DECLARATION_HEADER':
            return { ...state, collapsed: false };

        default:
            return state;
    }
};
export default declarationHeader;
