import {
    isabelLine,
    lavenderWeb,
    linen,
    magnolia,
    mimiPink,
    mintCream,
    palePink,
    periwinkleCrayola,
    powderBlue,
} from 'theme/colors';

const tagsColors = [
    magnolia,
    linen,
    palePink,
    mimiPink,
    mintCream,
    powderBlue,
    isabelLine,
    lavenderWeb,
    periwinkleCrayola,
];

export const getTagColor = (name: string) => {
    const nameSize = name.length;
    const numberOfColors = tagsColors.length;
    const result = nameSize % numberOfColors;
    return result < numberOfColors ? tagsColors[result] : tagsColors[numberOfColors - 1];
};
