import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { CargosActions } from './actionType';
import { Cargo } from './cargo';


interface CargoEntities {
    [key: string]: Cargo;
}
export interface CargosState {
    isLoading: boolean;
    error?: ErrorResponse;
    cargos: ListPayload<Cargo>;
    entities: CargoEntities;
}

export const cargosInitialState: Readonly<CargosState> = {
    isLoading: false,
    cargos: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const cargos: Reducer<CargosState, CargosActions> = (state = cargosInitialState, action): CargosState => {
    switch (action.type) {
        case 'LIST_CARGOS_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_CARGOS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                cargos: action.payload,
            };
        case 'LIST_CARGOS_ERROR':
            return {
                ...state,
                isLoading: false,
                cargos: state.cargos,
                error: action.error,
            };
        case 'CREATE_CARGO_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_CARGO_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_CARGO_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_CARGO_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_CARGO_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_CARGO_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_CARGO_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_CARGO_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_CARGO_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_CARGO_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_CARGO_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_CARGO_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default cargos;
