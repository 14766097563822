import { FC, useMemo } from 'react';
import { AepBusinessRule } from 'store/aep-business-rules/aep-business-rule';
import { AisBusinessRule } from 'store/ais-business-rules/ais-business-rule';
import AepBusinessRuleRow from './aep-documentation/AepBusinessRuleRow';
import { AisBusinessRuleRow } from './ais-documentation/AisBusinessRuleRow';
import { BusinessRuleCode, StyledBusinessRuleRow } from './BusinessRules.styles';
import { DocumentationType } from './Help';

type BusinessRule = AisBusinessRule | AepBusinessRule;

export const BusinessRulesList: FC<{ type: DocumentationType; rules: BusinessRule[] }> = ({ type, rules }) => {
    const rows = useMemo(() => {
        switch (type) {
            case DocumentationType.AIS:
                return rules.map((businessRule) => {
                    return <AisBusinessRuleRow key={businessRule.code} rule={businessRule as AisBusinessRule} />;
                });
            case DocumentationType.AEP:
                return rules.map((businessRule) => {
                    return <AepBusinessRuleRow key={businessRule.code} rule={businessRule as AepBusinessRule} />;
                });
            default:
                return <></>;
        }
    }, [type, rules]);
    return (
        <>
            <StyledBusinessRuleRow>
                <BusinessRuleCode>Rule code:</BusinessRuleCode>
                <span>Error message</span>
            </StyledBusinessRuleRow>
            {rows}
        </>
    );
};
