import { Tooltip } from 'antd';
import { declarationStatusColor } from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import { ReactElement, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import styled from 'styled-components';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import useFormUtils from '../../../hooks/useFormUtils';
import Box44, { SBox44Button } from './box44/Box44';

export const InfoTitle = styled.span`
    font-weight: bold;
    white-space: nowrap;
`;

export const InfoText = styled.span`
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Status = styled.span<{ status: DeclarationStatus | undefined }>`
    background-color: ${({ status }) => status && declarationStatusColor[status]};
    padding: 0.2rem 0.8rem;
    font-weight: bold;
    border-radius: 2px;
    color: white;
`;

const Container = styled.div`
    display: flex;
    gap: 3rem;
    flex: 1;
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: center;
`;

interface Props {
    hasBox44?: boolean | undefined;
    handleViewAllProductsBtn?: () => void;
    handleAddNewProductBtn?: () => void;
    viewOnly?: boolean;
    options?: {
        visible?: {
            template?: boolean;
            declaration?: boolean;
            jobId?: boolean;
            ref?: boolean;
            status?: boolean;
        };
    };
}

const DeclarationInformation = ({
    hasBox44,
    handleViewAllProductsBtn,
    handleAddNewProductBtn,
    viewOnly,
    options = {
        visible: {
            declaration: true,
            jobId: true,
            ref: true,
            status: true,
            template: true,
        },
    },
}: Props): ReactElement => {
    const { declaration, declarationTemplate } = useDeclarations();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { productId } = useParams();
    const location = useLocation();
    const { job } = useJobs({ jobId: declaration?.jobId });
    const navigate = useNavigate();

    const { declarationFormType } = useFormUtils();
    const jobId = job?.referenceId;
    const ref = declaration?.referenceNumber;
    const status = declaration?.status;

    const [show, setShow] = useState(false);

    const inProductTemplateAdd = useMatch('customs-declarations/:country/:internalType/products/:type/new');
    const inProductTemplateEdit = useMatch('customs-declarations/:country/:internalType/products/:type/:id');
    const inProductTemplate = useMemo(
        () => inProductTemplateAdd || inProductTemplateEdit,
        [inProductTemplateAdd, inProductTemplateEdit]
    );

    const showButton = useMemo(
        () => !location.pathname.includes('products') || productId || inProductTemplate,
        [inProductTemplate, location.pathname, productId]
    );

    const templateName = useMemo(() => {
        if (productTemplateDeclarationTemplate) return productTemplateDeclarationTemplate.templateName;
        if (declarationTemplate) return declarationTemplate.templateName;
        return 'DEFAULT';
    }, [declarationTemplate, productTemplateDeclarationTemplate]);

    return (
        <Container>
            {options?.visible?.template && (
                <InfoWrapper>
                    <InfoTitle>Template: </InfoTitle>
                    <Tooltip overlay={templateName}>
                        <InfoText>{templateName}</InfoText>
                    </Tooltip>
                </InfoWrapper>
            )}
            {options?.visible?.declaration && (
                <InfoWrapper>
                    <InfoTitle>Declaration: </InfoTitle>
                    <InfoText>{declarationFormType}</InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.jobId && (
                <InfoWrapper>
                    <InfoTitle>Job ID: </InfoTitle>
                    <InfoText>{jobId}</InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.ref && (
                <InfoWrapper>
                    <InfoTitle>Ref: </InfoTitle>
                    <InfoText>{ref}</InfoText>
                </InfoWrapper>
            )}
            {options?.visible?.status && (
                <InfoWrapper>
                    <InfoTitle>Status: </InfoTitle>
                    <div>
                        <Status status={status}>{status}</Status>
                    </div>
                </InfoWrapper>
            )}
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '10px' }}>
                {handleViewAllProductsBtn && !viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() => handleViewAllProductsBtn?.()}
                            data-testid={'view-all-product-templates-button'}
                        >
                            View all product templates
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {handleAddNewProductBtn && !viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() => handleAddNewProductBtn?.()}
                            data-testid={'add-new-product-button'}
                        >
                            Add new product
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {productId && viewOnly && (
                    <InfoWrapper>
                        <SBox44Button
                            size="small"
                            onClick={() =>
                                navigate(`/declarations/${declaration?.id}/view-only/products`, {
                                    replace: true,
                                    state: { comingBackFromProductView: true },
                                })
                            }
                            data-testid={'back-to-products-list-button'}
                        >
                            Back to products list
                        </SBox44Button>
                    </InfoWrapper>
                )}

                {hasBox44 && (
                    <InfoWrapper>
                        {showButton && <Box44.Button onClick={() => setShow((prev) => !prev)} />}
                        <Box44.Modal viewOnly={viewOnly} visible={show} onCancel={() => setShow(false)} />
                    </InfoWrapper>
                )}
            </div>
        </Container>
    );
};

export default DeclarationInformation;
