import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { TemplatesActions } from './actionType';
import { TemplateResponse } from './template';

export interface TemplatesState {
    templates: ListPayload<TemplateResponse>;
    isLoading: boolean;
    error?: ErrorResponse;
}

export const templatesInitialState: Readonly<TemplatesState> = {
    isLoading: false,
    error: undefined,
    templates: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const templates: Reducer<TemplatesState, TemplatesActions> = (
    state = templatesInitialState,
    action
): TemplatesState => {
    switch (action.type) {
        case 'LIST_TEMPLATES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_TEMPLATES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                templates: action.payload,
            };
        case 'LIST_TEMPLATES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default templates;
