import SearchBar from 'components/ui/base/searchbar';
import CustomerOptions from 'components/ui/base/searchbar/search-options/CustomersOptions';
import DeclarationsOptions from 'components/ui/base/searchbar/search-options/DeclarationsOptions';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getJobsSearchOptions from '../../../components/ui/base/searchbar/search-options/jobs-search-options-utils';
import useJobs from '../../../hooks/useJobs';
import { useRequestPromise } from '../../../hooks/useRequest';
import { listDeclarations as listDeclarationsRequest } from 'store/declarations/client';
import useCustomers from '../../../hooks/useCustomers';
import { PaginatedParams } from '../../../core/http/pagination';
import useJobDeclarationInformation from '../../../hooks/useJobDeclarationInfromation';

const DashboardSearchBar: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('dashboard');
    const { jobs, listJobs } = useJobs();
    const { customers, listCustomers } = useCustomers();
    const { data: declarations, refetch: listDeclarations } = useRequestPromise(listDeclarationsRequest);

    const listRequests = async (params?: PaginatedParams) =>
        await Promise.all([listCustomers(params), listDeclarations(params), listJobs(params)]);

    const debouncedSearch = debounce((query: string) => {
        const params = { query } as PaginatedParams;
        query ? listRequests(params) : listRequests();
    }, 500);

    const jobIdsPerPage = useMemo(() => jobs.list.map((job) => job.id), [jobs.list]);
    const { jobsDeclarationInformation } = useJobDeclarationInformation({ jobIdsPerPage });

    useEffect(() => {
        listRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSearchOptions = useMemo(() => {
        let jobOptions: any[] = [];
        let customerOptions: any[] = [];
        let declarationOptions: any[] = [];
        if (declarations?.list) {
            declarationOptions = [...DeclarationsOptions(declarations.list)];
        }
        if (jobs?.list) {
            jobOptions = [...getJobsSearchOptions(jobs.list, jobsDeclarationInformation)];
        }
        if (customers?.list) {
            customerOptions = [...CustomerOptions(customers.list)];
        }

        return [...customerOptions, ...jobOptions, ...declarationOptions];
    }, [declarations?.list, customers?.list, jobs?.list, jobsDeclarationInformation]);

    return (
        <SearchBar
            onSearch={(value: string) => {
                debouncedSearch(value);
            }}
            onSelect={(value: string) => {
                navigate(value);
            }}
            options={getSearchOptions}
            inputPlaceholder={t('placeholder')}
            onClear={() => listRequests()}
        />
    );
};
export default DashboardSearchBar;
