import { Table } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const StyledTable = styled(Table)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${colors.darkPurple};
        border-color: ${colors.darkPurple};
    }
    .ant-checkbox-indeterminate {
        background-color: ${colors.darkPurple};
    }

    .ant-checkbox-inner::after {
        background-color: ${colors.darkPurple};
        border-spacing: 0px !important;
    }

    table {
        border-spacing: 0 16px;
        & th {
            padding: 6px !important;
        }
        & tr {
            &:hover {
                cursor: ${({ onRow }) => (onRow ? 'pointer' : 'normal')};
            }
        }
        & td {
            padding: 6px !important;
            height: 56px;
            border-bottom: 1px solid ${colors.lightGrey4};
            border-top: 1px solid ${colors.lightGrey4};
            &:first-child {
                border-left: 1px solid ${colors.lightGrey4};
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-right: 1px solid ${colors.lightGrey4};
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .ant-table-thead > tr > th {
        background: ${colors.white};
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: ${colors.darkPurple};
        border-bottom: none;
        &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            position: relative;
        }
    }

    .ant-table-column-sorter {
        color: ${colors.darkPurple};
    }

    tr.ant-table-expanded-row > td {
        padding: 0 !important;
        border: none;
        background: ${colors.white} !important;
    }
`;

export const StyledExpandadedTable = styled(Table)`
    margin: -4.8rem 0 -4.8rem 0;
    table {
        & td {
            border-bottom: none !important;
            border-top: none !important;
            background-color: ${colors.lightBlue2};
            &:first-child {
                border-left: none !important;
            }
            &:last-child {
                border-right: none !important;
            }
        }
    }
`;
