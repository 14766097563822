import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const GoodsShipmentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('goodsShipment.title')}>
            <FormCardContainer>
                <FormInput
                    maxLength={9}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`regionDestination`, props)}
                    refNumber="5.9"
                    label={t('regionDestination')}
                    tooltip={getH1TooltipsByRefNumberAndField('5.9', t('regionDestination'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GoodsShipmentCard;
