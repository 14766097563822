import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import addPathPrefix from 'utils/addPathPrefix';
import { FormikProvider } from 'formik';
import { useTemplateContext } from '../../../../../../components/ui/composed/template/TemplateContext';

const ContainerIdentificationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { templateFormik } = useTemplateContext();

    if (props.getFieldHelpers === undefined) {
        return <></>;
    }

    return (
        <FormikProvider value={props.formik! ?? templateFormik}>
            <NewFormCard title={t('containerIdentificationNumbers')}>
                <FormCardContainer>
                    <MultipleDeclarationField
                        name={addPathPrefix(props?.propsPathPrefix, 'containerIdentificationNumber')}
                    >
                        {(fieldProps, controls) => (
                            <DeclarationInput
                                viewOnly={props.viewOnly}
                                maxLength={17}
                                refNumber="7.10"
                                label={t('containerIdentificationNumbers')}
                                tooltip={getH1TooltipsByRefNumberAndField('7.10', t('containerIdentificationNumbers'))}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                condensed
                                multipleF={controls}
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>
        </FormikProvider>
    );
};
export default ContainerIdentificationCard;
