import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { FC, useState } from 'react';
import { Cargo, CargoSortParameter } from 'store/cargo/cargo';
import { Individual, IndividualType } from 'store/individuals/individual';

interface Props {
    data?: ListPayload<Cargo>;
    onDelete: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort: (paginator: Partial<PaginatedParams>) => void;
    loading: boolean;
    handleUpdateRole: (role: IndividualType, id: string) => void;
}

const CargosTable: FC<Props> = ({
    data,
    onDelete,
    onEdit,
    onDetails,
    handlePagination,
    handleSort,
    loading,
    handleUpdateRole,
}) => {
    const [deleteIds, setDeleteIds] = useState<string[]>([]);

    const rowSelection = {
        onChange: (selectedRowKeys: unknown, selectedRows: Individual[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Reference number',
            dataIndex: CargoSortParameter.REFERENCE_NUMBER,
            key: CargoSortParameter.REFERENCE_NUMBER,
            align: 'left',
            onCell: (record: Cargo, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.referenceNumber ? <span>{record.referenceNumber}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: CargoSortParameter.TYPE,
            key: CargoSortParameter.TYPE,
            align: 'left',
            render: (text, record: Cargo) => (record.status ? <span>{record.status}</span> : <span>-</span>),
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: CargoSortParameter.TYPE,
            key: CargoSortParameter.TYPE,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.type ? <span>{record.type}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Weight',
            dataIndex: CargoSortParameter.WEIGHT,
            key: CargoSortParameter.WEIGHT,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.weight ? <span>{record.weight}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Weight Unit',
            dataIndex: CargoSortParameter.WEIGHT_UNIT,
            key: CargoSortParameter.WEIGHT_UNIT,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.weightUnit ? <span>{record.weightUnit}</span> : <span>-</span>,

            sorter: true,
        },
        {
            title: 'Dimensions (L x W x H)',
            dataIndex: CargoSortParameter.DIMENSIONS,
            key: CargoSortParameter.DIMENSIONS,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.length && record.width && record.height ? (
                    <span>{`${record.length} x ${record.width} x ${record.height}`}</span>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Dimensions Unit',
            dataIndex: CargoSortParameter.DIMENSIONS_UNIT,
            key: CargoSortParameter.DIMENSIONS_UNIT,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) =>
                record.dimensionsUnit ? <span>{record.dimensionsUnit}</span> : <span>-</span>,

            sorter: true,
        },
        {
            title: 'Value',
            dataIndex: CargoSortParameter.VALUE,
            key: CargoSortParameter.VALUE,
            align: 'left',
            onCell: (record: Cargo, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Cargo, index: number) => {
                if (record.value) {
                    return (
                        <span>
                            {record.value} {record.currency}
                        </span>
                    );
                }
                return <span>-</span>;
            },
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'Commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Cargo, index: number) => {
                return (
                    <>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onEdit(record.id)}>
                            <EditOutlined />
                        </span>
                        <span
                            style={{ marginRight: '1.2rem', cursor: 'pointer' }}
                            onClick={() => onDelete([record.id])}
                        >
                            <DeleteOutlined />
                        </span>
                    </>
                );
            },
        },
    ];

    function onChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {
        if (!Array.isArray(sorter) && sorter.columnKey) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    }

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page: number, size: number | undefined) => {
                        const params = { page: page - 1, size };
                        handlePagination(params);
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default CargosTable;
