import { Field, FieldAttributes, FieldProps } from 'formik';
import { ReactElement, ReactNode, useMemo } from 'react';
import addPathPrefix from 'utils/addPathPrefix';
import { createFieldValidatorFunction, fieldValidator } from 'utils/validations/fieldValidators';

export interface DeclarationFieldValidation {
    name: string;
    number?: boolean;
    required?: boolean;
    max?: number;
    exact?: number;
    float?: [number, number];
    eori?: boolean;
}

export interface DeclarationFieldProps extends FieldAttributes<any> {
    name: string;
    pathPrefix?: string | null;
    validation?: DeclarationFieldValidation;
    children: (fieldProps: FieldProps<any>, ...rest: any) => ReactNode;
}

const DeclarationField = ({
    children,
    pathPrefix,
    name,
    validation,
    controls,
    ...formikFieldProps
}: DeclarationFieldProps): ReactElement => {
    const validate = useMemo(() => {
        if (!validation) return undefined;

        return createFieldValidatorFunction(validation?.name, [
            validation.number && fieldValidator.number,
            validation.required && fieldValidator.required,
            validation.exact !== undefined && fieldValidator.exact(validation.exact),
            validation.max !== undefined && fieldValidator.max(validation.max),
            validation.float !== undefined && fieldValidator.float(validation.float[0], validation.float[1]),
            validation.eori !== undefined && fieldValidator.eori,
        ]);
    }, [validation]);

    return (
        <Field {...formikFieldProps} name={addPathPrefix(pathPrefix, name)} validate={validate}>
            {children}
        </Field>
    );
};

export default DeclarationField;
