import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Vehicle, VehiclesParams } from './vehicles';

export const listVehicles = (params?: Partial<VehiclesParams>): Promise<ListPayload<Vehicle>> =>
    axiosClient
        .get<ListResponse<Vehicle>>(`${config.fleestUrl}/vehicles`, { params })
        .then((response) => response.data.payload);

export const getVehicle = (vehicleID: string): Promise<Vehicle> =>
    axiosClient
        .get<SuccessResponse<Vehicle>>(`${config.fleestUrl}/vehicles/${vehicleID}`)
        .then((response) => response.data.payload);

export const postVehicle = (vehicle: Vehicle): Promise<Vehicle> =>
    axiosClient
        .post<SuccessResponse<Vehicle>>(`${config.fleestUrl}/vehicles`, vehicle)
        .then((response) => response.data.payload);

export const putVehicle = (vehicle: Vehicle): Promise<Vehicle> =>
    axiosClient
        .put<SuccessResponse<Vehicle>>(`${config.fleestUrl}/vehicles/${vehicle.id}`, vehicle)
        .then((response) => response.data.payload);

export const deleteVehicle = (vehicleID: string): Promise<Vehicle> =>
    axiosClient
        .delete<SuccessResponse<Vehicle>>(`${config.fleestUrl}/vehicles/${vehicleID}`)
        .then((response) => response.data.payload);
