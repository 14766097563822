import { Row } from 'antd';
import { FC, useMemo } from 'react';
import { CardState } from './card-state';
import { CardTextSmall, DivRightHand, EmptyIcon, ErrorIcon, FilledIcon, VerticalLine } from './FormCard.styles';

interface Props {
    title: string;
    total?: number;
    cardNumber?: number;
    state?: CardState;
}

const RightSideCardState: FC<Props> = ({ title, total, cardNumber, state }) => {
    const renderRightSide = useMemo(() => {
        const handleIcon = () => {
            switch (state) {
                case CardState.INVALID:
                    return <ErrorIcon />;
                case CardState.VALID:
                    return <FilledIcon />;
                default:
                    return <EmptyIcon />;
            }
        };

        return (
            <>
                <DivRightHand>
                    <Row justify="end">
                        {handleIcon()}
                        <div>
                            {cardNumber && total && (
                                <CardTextSmall state={state}>{`${cardNumber} of ${total}`}</CardTextSmall>
                            )}
                        </div>
                    </Row>
                </DivRightHand>
                <VerticalLine state={state} />
            </>
        );
    }, [cardNumber, total, state]);

    return <>{renderRightSide}</>;
};
export default RightSideCardState;
