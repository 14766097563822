import { createContext, useContext } from 'react';
import { TemplateResponse } from 'store/template/template';

interface IProductTemplateContext {
    declarationTemplate?: TemplateResponse | undefined | null;
    inProductTemplate?: boolean;
}

export const ProductTemplateContext = createContext<IProductTemplateContext>({});
export const useProductTemplateContext = () => useContext(ProductTemplateContext);
