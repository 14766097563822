import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { H5 } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const GettingStartDiv = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 2.4rem;
    line-height: 4rem;
    color: ${colors.purpleBlue};
    text-align: center;
    margin-top: 10%;
`;

export const TitleSection = styled.div`
    text-align: center;
    color: ${colors.darkPurple};
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
    margin-bottom: 2rem;
`;

export const FormDiv = styled.div`
    width: 100%;
`;

export const CheckItemDiv = styled.div`
    background: ${colors.white};
    border: 1px solid ${colors.lightGrey2};
    box-shadow: 0 0 2.4rem rgba(9, 0, 255, 0.07);
    border-radius: 5px;
    width: 39.5rem;
    height: 5.612rem;
    display: flex;
    align-items: flex-start;
    padding: 1.6rem 2.4rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 0.8rem;
`;

export const AddNewTeamMemberForm = styled.div`
    margin-top: 4rem;
`;

export const AddTeamMemberTitle = styled.div`
    margin-bottom: 1.6rem;
`;

export const TeamMembersList = styled.div`
    text-align: left;
    height: 55vh;
    overflow-y: auto;
    padding-right: 0.5rem;
`;

export const AddTeamMemberButtons = styled.div`
    margin-top: 3.2rem;
    text-align: right;
`;

export const NextButton = styled.div`
    margin-top: 5rem;
`;

export const ChangePwdNextBt = styled.div`
    margin-top: 23rem;
`;

export const CheckListDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const CheckListNextBt = styled.div`
    margin-top: 14.151rem;
`;

export const VideoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
    }
`;

export const IntroContinueBt = styled.div`
    margin-top: 5.187rem;
`;

export const DeleteDiv = styled.div`
    display: flex;
`;

export const TeamMemberInfoDiv = styled.div`
    margin-top: 4rem;
    margin-bottom: 1.6rem;
`;

export const TeamMemberTitle = styled(H5)`
    margin-bottom: 1.6rem;
`;

export const CloseIcon = styled(CloseOutlined)`
    font-size: 1.8rem;
    margin-right: 2rem;
    cursor: pointer;
`;

export const CheckIcon = styled(CheckOutlined)`
    font-size: 1.8rem;
    margin-right: 2rem;
    color: ${colors.success};
`;
