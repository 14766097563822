import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'id';

export const placeOfLoadingAirportCodeBlockValidation: {
    childValidators: {
        'goodsShipment.consignment.loadingLocation': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment.loadingLocation': {
            childValidators: {
                id: [validators.maxLength(17)],
            },
        },
    },
};

interface Props extends BlockProps<'id'> {}

const PlaceOfLoadingAirportCodeBlock = ({
    fieldOptions,
    path = 'goodsShipment.consignment.loadingLocation',
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '5/21', []);
    return (
        <DeclarationField name="id" pathPrefix={path}>
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    label={t('placeOfLoadingAirportCode')}
                    {...getFormikProps(field.name, form)}
                    condensed
                    selectOptions={cdsCodelists?.airportCodes}
                    codeValidation={[validators.maxLength(17)]}
                />
            )}
        </DeclarationField>
    );
};

export default PlaceOfLoadingAirportCodeBlock;
