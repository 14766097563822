import { ReactElement } from 'react';
import { Spin, Tabs } from 'antd';
import { PaginatedParams } from 'core/http/pagination';
import { FormikErrors, setNestedObjectValues, useFormik } from 'formik';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCustomers from 'hooks/useCustomers';
import useDeclarationAutoRefresh from 'hooks/useDeclarationAutoRefresh';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import useDeclarationProductTaxes from 'hooks/useDeclarationProductTaxes';
import useDeclarations, { UpdateDeclarationFunction } from 'hooks/useDeclarations';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useExchanges from 'hooks/useExchanges';
import useModalDeclarationSubmit from 'hooks/useModalDeclarationSubmit';
import useProducts, { CreateProductFunction, ListProductFunction, UpdateProductFunction } from 'hooks/useProducts';
import useSession from 'hooks/useSession';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Declaration, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { GoodsShipmentItem, IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import InformationAlert from 'views/declarations/common/InformationAlert';
import ModalPreviewSadAndClearanceSlip from 'views/declarations/common/ModalPreviewSadAndClearanceSlip';
import ValidationErrorContainer from 'views/declarations/common/ValidationErrorContainer/ValidationErrorContainer';
import WarningSubmitModal from 'views/declarations/common/WarningSubmitModal';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import FormFooter from 'views/declarations/footer/Footer';
import { StyledHeader, StyledLayout, StyledTabs } from 'views/declarations/Form.styles';
import FormHeader from 'views/declarations/header/FormHeader';
import {
    isDeclarationRefundDepositRequested,
    isDeclarationRefundRemissionRequested,
} from 'views/declarations/utils/declaration-utils';
import {
    declarationHasBeenSubmitted,
    FormAction,
    removeEmptyObjects,
    removeEmptyObjectsFromDeclarationArrays,
    trimWhiteSpaces,
} from 'views/declarations/utils/form-utils';
import { get, isEmpty, merge, set, sortBy } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { AnyObjectSchema } from 'yup';
import AlertRefundRemissionRequested from '../../ireland/common/AlertRefundRemissionRequested';
import AlertSubmitDocuments from '../../ireland/common/AlertSubmitDocuments';
import AlertInvalidationRequested from '../../ireland/common/AlertInvalidationRequested';
import AlertInsufficientFunds from '../../ireland/common/AlertInsufficientFunds';
import AlertRefundDepositRequested from '../../ireland/common/AlertRefundDepositRequested';
import SubmissionErrorsDrawer, { SubmissionError } from '../submissionErrors/SubmissionErrorsDrawer';
import useIndividuals from 'hooks/useIndividuals';
import ExternalSystemInvalidationRequested from 'views/declarations/ireland/common/ExternalSystemInvalidationRequested';
import useHandleFormAction from './utils/useHandleFormAction';
import useHandleActiveTabKeyBasedOnLocation, { updateActiveTabKey } from './utils/useHandleActiveTabKeyBasedOnLocation';
import useRefetchDeclarationOnTabChangeIfAmendable from './utils/useRefetchDeclarationOnTabChangeIfAmendable';
import useAutoSaveProduct from './utils/useAutoSaveProduct';
import useHandleErrors from './utils/useHandleErrors';
import useHandleUnsavedChanges from './utils/useHandleUnsavedChanges';
import validate, { FormModel, transformErrorsForFormik } from 'views/declarations/uk/export/validations/validations';
import { ErrorButton } from 'views/declarations/header/FormHeader.styles';
import { WarningOutlined } from '@ant-design/icons';
import TemplateModal from 'components/ui/composed/template/TemplateModal';
import { TemplateContextProvider } from 'components/ui/composed/template/TemplateContext';
import { DeclarationType } from '../declarationType';
import { IrelandNotification } from 'store/declarations/ireland/ireland-notification';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { CdsExportNotification } from 'store/declarations/uk/export-declaration';
import useUpdateViewOfDeclaration from './utils/useUpdateViewOfDeclaration';
import MirrorMetaContextProvider from '../../../../components/ui/composed/mirroring/MirrorMetaContext';
import InvoiceFormHeader from '../../ireland/import/h1/invoice/InvoiceFormHeader';
import InvoiceModal from '../../ireland/import/h1/invoice/InvoiceModal';
import ProductContextProvider from './ProductContext';
import DeclarationContent from './DeclarationContent';
import {
    AesInvalidNotification,
    AesNotification,
    AesRejectedNotification,
    IrelandExportDeclaration,
} from '../../../../store/declarations/ireland/export-declaration';
import useFormUtils from '../../../../hooks/useFormUtils';
import {
    irelandExportRepresentativeContactPersonLevelRequired,
    irelandExportRepresentativeEoriLevelRequired,
} from '../../ireland/export/validation/IrelandExportB1Validation';
import { deepObjHasTruthyNonObjValue } from '../../../../core/utils/objects';
import useGetDeclarationViewMapValues from '../../../../hooks/useGetDeclarationViewMapValues';
import { DeclarationContextProvider } from '../../../../utils/DeclarationContext';
import useJobs from '../../../../hooks/useJobs';

const { TabPane } = Tabs;

export enum FormSection {
    MASTER_DETAILS = 0,
    PRODUCTS = 1,
}

export type Declarations = NonNullable<Declaration[DeclarationType]>;

interface Transform<TReturn = any> {
    forClient?: (data: any, straight?: boolean) => TReturn;
    forServer?: (data: any, straight?: boolean) => TReturn;
}

export interface TransformData {
    product?: Transform;
    declaration?: Transform;
}

const DeclarationView = <TDeclaration extends Declarations>(): ReactElement => {
    // * UseState
    const location = useLocation();
    const { declarationId, productId: urlParamsProductId } = useParams<{ declarationId: string; productId: string }>();
    const [declaration, setDeclaration] = useState<Declaration | undefined>(undefined);
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState<DeclarationTabKeys>(() => updateActiveTabKey(location));
    const [cancelClicked, setCancelClicked] = useState<boolean>(false);
    const [emails, setEmails] = useState<NotifyEmails>();
    const [productId, setProductId] = useState<string | undefined>(urlParamsProductId);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [declarationSaved, setDeclarationSaved] = useState(true);
    const [validationErrorContainer, setValidationErrorContainer] = useState(false);
    const [showErrorDrawer, setShowErrorDrawer] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [openSubmissionErrorsDrawer, setOpenSubmissionErrorsDrawer] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState<SubmissionError[] | null | undefined>(null);
    const [declarationIsFetching, setDeclarationIsFetching] = useState<boolean>(false);

    // * Hooks
    const declarations = useDeclarations();
    const { job } = useJobs({ jobId: declarations?.declaration?.jobId });
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { userInfo } = useSession();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { individual } = useIndividuals({ individualId: userInfo?.individualId });
    const products = useProducts({ productId });
    const { setFormDeclarationErrors, clearFormDeclarationErrors } = useDeclarationFormErrors();
    const { customer } = useCustomers({ customerId: userInfo?.customerId });
    const {
        modalPreviewSadAndClearanceSlip,
        digitalCertificateModal,
        onCloseModalPreviewSadAndClearanceSlip,
        onCloseDigitalCertificateModal,
        displayModalSubmit,
    } = useModalDeclarationSubmit();
    const { getDeclarationProductTaxes } = useDeclarationProductTaxes(declaration?.id);
    const { listExchanges } = useExchanges();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { showErrorNotification } = useDeclarationNotifications();
    const { declarationValidation, setFormAction, setPressedSubmit, setHasUnsavedChanges, isSavingAsDraft } =
        useDeclarationValidation({
            formAction: null,
        });

    const { isAes, isAis } = useFormUtils();
    const { ...mapper } = useGetDeclarationViewMapValues();

    const currentDeclaration = useMemo(
        () => declaration?.[mapper.declarationType],
        [declaration, mapper.declarationType]
    );

    const getValidationSchema = (): AnyObjectSchema | undefined =>
        mapper.declarationValidationSchema instanceof Function
            ? mapper.declarationValidationSchema(products.products?.total)
            : mapper.declarationValidationSchema;

    const getDeclarationFormValidations = useCallback(
        (formikValues: TDeclaration) => {
            if (!isAes) return mapper.declarationFormValidations;

            if (deepObjHasTruthyNonObjValue((formikValues as IrelandExportDeclaration)?.representative?.contactPerson))
                return merge(
                    {},
                    mapper.declarationFormValidations,
                    irelandExportRepresentativeContactPersonLevelRequired
                );
            else if (deepObjHasTruthyNonObjValue((formikValues as IrelandExportDeclaration)?.representative))
                return merge({}, mapper.declarationFormValidations, irelandExportRepresentativeEoriLevelRequired);
            else return mapper.declarationFormValidations;
        },
        [mapper.declarationFormValidations, isAes]
    );

    const formik = useFormik<TDeclaration>({
        initialValues:
            (mapper.payloadPath ? get(currentDeclaration, mapper.payloadPath) : (currentDeclaration as any)) ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: getValidationSchema(),
        validate: mapper.declarationFormValidations
            ? async (values) => {
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  return transformErrorsForFormik(
                      await validate(new FormModel(values), getDeclarationFormValidations(values))
                  );
              }
            : () => {},
        onSubmit: () => {},
    });

    const productInitialValues = useMemo(() => {
        if (mapper.transformData?.product?.forClient) {
            return mapper.transformData?.product?.forClient(
                isEmpty(products.product)
                    ? declarations.declarationTemplate?.template?.product.defaults
                    : products.product
            );
        }
        if (isEmpty(products.product)) {
            return declarations.declarationTemplate?.template?.product.defaults;
        }
        return products.product;
    }, [declarations.declarationTemplate?.template?.product.defaults, products.product, mapper.transformData?.product]);

    const productsFormik = useFormik({
        initialValues: productInitialValues ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: mapper.productValidationSchema,
        validate: mapper.productFormValidations
            ? async (values) =>
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  transformErrorsForFormik(await validate(new FormModel(values), mapper.productFormValidations!))
            : () => {},
        onSubmit: () => {},
    });

    // * UseMemo

    const formTabName = useMemo(() => {
        if (declaration?.status === DeclarationStatus.RELEASED) return 'Summary';
        return `${mapper.declarationName} Form`;
    }, [declaration?.status, mapper.declarationName]);

    const hasRightCertificate: boolean = useMemo(() => {
        if (declaration?.cdsExportDeclaration) return individual?.hasHmrcToken ?? false;
        else if (declaration?.ieExportDeclaration) return customer?.hasAesCertificate ?? false;
        else return customer?.hasAisCertificate ?? false;
    }, [customer, individual, declaration?.ieExportDeclaration, declaration?.cdsExportDeclaration]);

    const declarationCountry = useMemo(() => {
        return declaration?.cdsExportDeclaration ? DeclarationCountry.UK : DeclarationCountry.IRELAND;
    }, [declaration?.cdsExportDeclaration]);

    const remarks = useMemo(() => {
        if (currentDeclaration && !('notifications' in currentDeclaration)) return;

        const notifications = currentDeclaration?.notifications?.sort((elementA: any, elementB: any) => {
            const dateA = new Date(elementA.notificationDate);
            const dateB = new Date(elementB.notificationDate);
            return dateB.getTime() - dateA.getTime();
        });

        if (!notifications || notifications.length === 0 || !('submissionErrors' in notifications[0])) return;

        return (notifications[0].submissionErrors as any[]).find((e: any) => !!e.remarks)?.remarks;
    }, [currentDeclaration]);

    // * Functions
    const fetchDeclaration = async (declarationId: string) => {
        setDeclarationIsFetching(true);
        try {
            await declarations.getDeclaration(declarationId);
        } finally {
            setDeclarationIsFetching(false);
        }
    };

    const touchFieldsToShowErrorsMasterDetails = useCallback(
        (validations: FormikErrors<TDeclaration>) => {
            formik.setTouched(setNestedObjectValues(validations, true));
        },
        [formik]
    );

    const validateForm = useCallback(async () => {
        const masterDetailsValidation = await formik.validateForm();

        touchFieldsToShowErrorsMasterDetails(masterDetailsValidation);

        if (isEmpty(masterDetailsValidation)) {
            // Reset errors
            clearFormDeclarationErrors();

            return true;
        }

        if (isSavingAsDraft) return true;

        if (mapper.parseForm) {
            setFormDeclarationErrors(mapper.parseForm(masterDetailsValidation));
        }

        return false;
    }, [
        mapper,
        formik,
        touchFieldsToShowErrorsMasterDetails,
        isSavingAsDraft,
        clearFormDeclarationErrors,
        setFormDeclarationErrors,
    ]);

    const handleSubmitForm = async () => {
        if (!declaration || declarationValidation.formAction !== FormAction.SUBMIT) return;

        setIsSubmitting(true);
        await declarations.submitDeclaration(declaration.id!, { ...emails } as NotifyEmails);
        setIsSubmitting(false);
    };

    const updateProduct = async (form: any, declarationId: string) => {
        if (!products.product?.id || !mapper.updateProductFuncName) return;

        return (products[mapper.updateProductFuncName] as UpdateProductFunction)(
            removeEmptyObjects(form),
            declarationId,
            products.product?.id
        ).then((res) => {
            if (!res) return;
            setHasUnsavedChanges(false);
            return res;
        });
    };
    const createProduct = async (form: any, declarationId: string) => {
        if (products.product?.id || !mapper.createProductFuncName) return;

        await (products[mapper.createProductFuncName] as CreateProductFunction)(form, declarationId).then((res) => {
            if (!res?.id) return;

            let data;

            if (mapper.transformProductCreateResponse) {
                const transformed = mapper.transformProductCreateResponse(res);
                data = mapper.transformData?.product?.forClient?.(transformed) ?? transformed ?? {};
            } else {
                data = mapper.transformData?.product?.forClient?.(res) ?? res ?? {};
            }

            productsFormik.setValues(data);
            setProductId(res.id);
            navigate(`/declarations/${declarationId}/products/${res.id}`);
            return data;
        });
    };
    const handleProductsFormSubmit = async (item: GoodsShipmentItem) => {
        if (!declaration) return;

        let form = removeEmptyObjectsFromDeclarationArrays(trimWhiteSpaces({ ...item }, { emptyStringToNull: true }));

        const isProductCreated = products.product?.id;

        if (mapper.transformData?.product?.forServer) {
            form = mapper.transformData.product.forServer(form);
        }

        let data;
        if (isProductCreated) {
            data = await updateProduct(form, declaration.id!);
        } else {
            data = await createProduct(form, declaration.id!);
        }

        window.EventBus.publish('afterDeclarationSave', data);

        await declarations.getDeclaration(declaration.id!);
    };

    const saveDeclaration = async (data: any, declarationId: string, withNotification: boolean) => {
        return (declarations[mapper.updateDeclarationFuncName] as UpdateDeclarationFunction)(
            job?.customerId,
            declarationId,
            data,
            withNotification
        ).then((res) => {
            if (!res) return;

            window.EventBus.publish('afterDeclarationSave');
            setHasUnsavedChanges(false);
            setDeclarationSaved(true);
        });
    };

    const saveAsDraft = async (
        withNotification = true,
        paramData?: Partial<TDeclaration>,
        declarationWithNewItem?: TDeclaration
    ) => {
        if (!declaration) return;
        let form = removeEmptyObjects(
            removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces(declarationWithNewItem ?? merge(formik.values, paramData), {
                    emptyStringToNull: true,
                })
            )
        );

        if (mapper.transformData?.declaration?.forServer !== undefined) {
            if (mapper.payloadPath) {
                form = {
                    ...declaration?.[mapper.declarationType],
                    [mapper.payloadPath]: form,
                };
            }

            form = mapper.transformData.declaration.forServer(form);
        }

        const data = { ...declaration, [mapper.declarationType]: form };

        window.EventBus.publish('beforeDeclarationSave', true);
        return saveDeclaration(data, declaration.id!, withNotification);
    };

    const handleSubmit = async () => {
        const valid = await validateForm();
        setPressedSubmit();

        if (!valid) return;

        onCloseModalPreviewSadAndClearanceSlip();
        setShowSubmitModal(true);
    };

    const handleSubmitButton = () => {
        setFormAction(FormAction.SUBMIT);
        setShowSubmitModal(false);
    };

    const refetchDeclaration = useCallback(() => {
        return declarations.getDeclaration(declaration?.id!);
    }, [declaration?.id, declarations]);

    const handleFormErrorDetails = () => {
        setValidationErrorContainer(true);
    };

    // * useEffects
    useEffect(() => {
        if (declarationId) {
            fetchDeclaration(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationId]);
    useDeclarationAutoRefresh();
    useUpdateViewOfDeclaration({
        declarationStatus: declaration?.status,
        declarationIsArchived: declaration?.archived,
    });
    useEffect(() => {
        setBreadcrumbRoutes([
            { breadcrumbName: 'Home', path: '' },
            { breadcrumbName: 'Forms', path: '' },
            { breadcrumbName: 'In Progress', path: '' },
        ]);

        clearFormDeclarationErrors();
        listExchanges();
        return () => {
            clearFormDeclarationErrors();
            products.clearError();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Submission validation errors drawer
    useEffect(() => {
        if (!declarations.error || declarations.error.category !== 'VALIDATION') {
            return;
        }

        // Disable submission error drawer for all declarations other than ENS
        // TODO Remove when necessary
        if (declarations.declaration?.entrySummaryDeclaration == null) return;

        setOpenSubmissionErrorsDrawer(true);

        setSubmissionErrors(
            declarations.error?.subErrors
                ?.filter((error) => error.category === 'VALIDATION')
                .map((error) => ({ message: error.message }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarations.error]);

    useEffect(() => {
        formik.setValues(
            (mapper.payloadPath ? get(currentDeclaration, mapper.payloadPath) : (currentDeclaration as any)) ?? {}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDeclaration]);
    useEffect(() => {
        productsFormik.setValues(productInitialValues ?? {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productInitialValues]);

    // Trigger transform
    useEffect(() => {
        if (mapper.transformData?.declaration?.forClient) {
            setDeclaration(mapper.transformData.declaration.forClient(declarations.declaration));
        } else {
            setDeclaration(declarations.declaration);
        }
    }, [mapper.transformData?.declaration?.forClient, declarations.declaration, mapper.transformData]);

    useHandleFormAction({
        productsFormik,
        saveDeclaration: saveAsDraft,
        saveProduct: handleProductsFormSubmit,
        submitDeclaration: handleSubmitForm,
        setIsSaving,
        validateForm,
        refetchDeclaration,
    });
    useRefetchDeclarationOnTabChangeIfAmendable({ declarations, declaration, currentDeclaration, activeTabKey });
    useHandleActiveTabKeyBasedOnLocation({ setActiveTabKey });
    useAutoSaveProduct({
        setFormAction,
        declarationStatus: declaration?.status,
        productsFormik,
        fieldsToAutosaveOn: mapper.productFieldsToAutosaveOn,
    });
    useHandleErrors({
        formik,
        productsFormik,
        validationErrorsParser: mapper.parseForm,
        triedToSubmit,
    });
    useHandleUnsavedChanges({
        declarationSaved,
        formik,
        productsFormik,
        setDeclarationSaved,
    });

    const headerRender = useMemo(
        () =>
            declaration &&
            activeTabKey !== DeclarationTabKeys.VIEW_ONLY &&
            activeTabKey !== DeclarationTabKeys.HISTORY ? (
                <FormHeader
                    title={`${mapper.declarationName} declaration`}
                    declaration={declaration}
                    job={job}
                    activeCustomer={() => {
                        navigate(`/declarations/${declaration.id}/${DeclarationTabKeys.CUSTOMER_DETAILS}`, {
                            state: { activeTabKey: DeclarationTabKeys.CUSTOMER_DETAILS },
                        });
                        setActiveTabKey(DeclarationTabKeys.CUSTOMER_DETAILS);
                    }}
                    showErrors={() => setShowErrorDrawer(true)}
                    remarks={remarks}
                />
            ) : null,
        [declaration, activeTabKey, mapper.declarationName, job, remarks, navigate]
    );

    const declarationErrorsWarning = useMemo(() => {
        if (declaration?.status === DeclarationStatus.SUBMITTED || declaration?.status === DeclarationStatus.ACCEPTED) {
            return <></>;
        }

        const declarationType =
            declaration?.irelandImportDeclaration ??
            declaration?.irelandH7ImportDeclaration ??
            declaration?.entrySummaryDeclaration ??
            declaration?.cdsExportDeclaration ??
            declaration?.ieExportDeclaration;

        if (!declarationType?.notifications) return <></>;

        const lastNotificationIndex = declarationType.notifications.length - 1;

        const latestNotification = (
            declarationType.notifications.some(
                (declarationNotification) =>
                    'notificationDate' in declarationNotification || 'createdAt' in declarationNotification
            )
                ? sortBy(declarationType.notifications as IrelandNotification[], ['notificationDate', 'createdAt'])
                : declarationType.notifications
        )?.[lastNotificationIndex];

        const declarationNotificationErrors =
            (latestNotification as IrelandNotification | undefined)?.submissionErrors ?? // Ireland Import
            (latestNotification as IrelandEnsNotification | undefined)?.rejectedNotification?.errors ?? // ENS
            (latestNotification as CdsExportNotification | undefined)?.validationMessages ?? // CDS Export
            (((latestNotification as AesNotification)?.notification as AesRejectedNotification)?.functionalError ||
                ((latestNotification as AesNotification)?.notification as AesInvalidNotification)?.xmlError); // Ireland Export

        if (!declarationNotificationErrors?.length) return <></>;

        return (
            <ErrorButton onClick={() => setShowErrorDrawer(true)}>
                View error messages <WarningOutlined />
            </ErrorButton>
        );
    }, [declaration]);

    const showCertificateAlert = useMemo(() => {
        if (declaration?.cdsExportDeclaration) {
            if (!individual) return false;
            return !individual?.hasHmrcToken;
        } else {
            if (!customer) return false;
            return !(customer?.hasAisCertificate || customer?.hasAesCertificate);
        }
    }, [customer, individual, declaration?.cdsExportDeclaration]);

    const handleDeclarationFormTabs = useMemo(() => {
        if (!declaration?.status) return;

        if (declarationHasBeenSubmitted(declaration?.status)) {
            return <TabPane tab="Form info" key={DeclarationTabKeys.VIEW_ONLY} />;
        } else if (declaration.status === DeclarationStatus.RELEASED) {
            return (
                <>
                    <TabPane tab={formTabName} key={DeclarationTabKeys.SUMMARY} />
                    <TabPane tab="Form info" key={DeclarationTabKeys.VIEW_ONLY} />
                </>
            );
        } else {
            return <TabPane tab={formTabName} key={DeclarationTabKeys.FORM} />;
        }
    }, [declaration?.status, formTabName]);

    const comingFromCreateDecPage = (location.state as { comingFromCreateDecPage: boolean })?.comingFromCreateDecPage;
    const [showInvoiceModal, setShowInvoiceModal] = useState<boolean | undefined>(comingFromCreateDecPage);

    useEffect(() => {
        if (declaration?.status !== DeclarationStatus.DRAFT) setShowInvoiceModal(false);
    }, [declaration?.status]);

    useEffect(() => {
        window.history.replaceState({}, document.title);
        return () => setShowInvoiceModal(false);
    }, []);

    const declarationHasInvoice = useMemo(
        () => declaration?.uploadedFiles?.some((uploadedFile) => uploadedFile.invoice),
        [declaration]
    );

    if (declarationIsFetching)
        return (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spin size="large" tip="Loading declaration..." />
            </div>
        );

    return (
        <StyledLayout>
            <DeclarationContextProvider>
                <TemplateContextProvider>
                    <MirrorMetaContextProvider>
                        <ProductContextProvider productId={productId} setProductId={(value) => setProductId(value)}>
                            <StyledHeader>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <StyledTabs
                                        onChange={(k) => {
                                            const tab = k as DeclarationTabKeys;
                                            if (tab === DeclarationTabKeys.FORM) {
                                                navigate(`/declarations/${declaration?.id}`, {
                                                    state: { activeTabKey: tab },
                                                });
                                            } else {
                                                navigate(`/declarations/${declaration?.id}/${tab}`, {
                                                    state: { activeTabKey: tab },
                                                });
                                            }
                                            setActiveTabKey(tab);
                                        }}
                                        activeKey={activeTabKey}
                                    >
                                        {handleDeclarationFormTabs}
                                        <TabPane tab="Documentation" key={DeclarationTabKeys.DOCUMENTATION} />
                                        <TabPane tab="Customer Details" key={DeclarationTabKeys.CUSTOMER_DETAILS} />
                                        <TabPane tab="History" key={DeclarationTabKeys.HISTORY} />
                                        {isAis && declaration?.status !== 'DRAFT' && (
                                            <TabPane tab="Refunds" key={DeclarationTabKeys.REFUNDS} />
                                        )}
                                    </StyledTabs>

                                    {declaration?.irelandImportDeclaration && (
                                        <InvoiceFormHeader disabled={!(showInvoiceModal && declarationHasInvoice)} />
                                    )}

                                    {showInvoiceModal && declarationHasInvoice && (
                                        <InvoiceModal
                                            formikValues={formik?.values as IrelandImportDeclaration}
                                            declarationId={declaration?.id}
                                            saveAsDraft={saveAsDraft}
                                        />
                                    )}

                                    {(declaration?.irelandH7ImportDeclaration ||
                                        declaration?.entrySummaryDeclaration ||
                                        declaration?.ieExportDeclaration ||
                                        declaration?.irelandImportDeclaration ||
                                        declaration?.cdsExportDeclaration) &&
                                        declarationErrorsWarning}
                                </div>
                                {!(
                                    declaration?.irelandH7ImportDeclaration ||
                                    declaration?.entrySummaryDeclaration ||
                                    declaration?.ieExportDeclaration ||
                                    declaration?.irelandImportDeclaration ||
                                    declaration?.cdsExportDeclaration
                                ) && headerRender}
                                <InformationAlert showAlert={showCertificateAlert} country={declarationCountry} />
                                <AlertRefundRemissionRequested
                                    show={isDeclarationRefundRemissionRequested(declaration)}
                                />
                                {isDeclarationRefundDepositRequested(declaration) && <AlertRefundDepositRequested />}
                                <AlertInsufficientFunds
                                    insufficientFunds={declaration?.status === DeclarationStatus.INSUFFICIENT_FUNDS}
                                />
                                <AlertSubmitDocuments
                                    documentsUploadRequested={
                                        declaration?.irelandImportDeclaration?.documentsUploadRequested
                                    }
                                    documentsPresentationRequested={
                                        declaration?.irelandImportDeclaration?.documentsPresentationRequested
                                    }
                                />
                                <AlertInvalidationRequested
                                    userInvalidationRequested={
                                        declaration?.irelandImportDeclaration?.userInvalidationSubmitted
                                    }
                                />
                                <ExternalSystemInvalidationRequested
                                    systemInvalidationRequested={
                                        declaration?.irelandImportDeclaration?.revenueInvalidationRequested
                                    }
                                    external="Revenue"
                                />
                            </StyledHeader>

                            <StyledLayout>
                                <DeclarationContent
                                    activeTabKey={activeTabKey}
                                    setActiveTabKey={setActiveTabKey}
                                    declaration={declaration}
                                    formik={formik}
                                    productsFormik={productsFormik}
                                    setEmails={setEmails}
                                    setShowSubmitModal={setShowSubmitModal}
                                    showSubmitModal={showSubmitModal}
                                    handleSubmitButton={handleSubmitButton}
                                    cancelClicked={cancelClicked}
                                    setCancelClicked={setCancelClicked}
                                    showErrorDrawer={showErrorDrawer}
                                    setShowErrorDrawer={setShowErrorDrawer}
                                    saveAsDraft={saveAsDraft}
                                />
                            </StyledLayout>
                            {activeTabKey === DeclarationTabKeys.FORM && (
                                <FormFooter
                                    declartionInternalType={mapper.declarationInternalType}
                                    declarationId={declaration?.id}
                                    declarationStatus={declaration?.status}
                                    isSaving={isSaving}
                                    iconDeclarationSaved={declarationSaved}
                                    isSubmiting={isSubmitting}
                                    disabled={
                                        currentDeclaration &&
                                        typeof currentDeclaration === 'object' &&
                                        'userInvalidationSubmitted' in currentDeclaration &&
                                        !!currentDeclaration.userInvalidationSubmitted
                                    }
                                    externalInvalidationRequested={
                                        currentDeclaration &&
                                        typeof currentDeclaration === 'object' &&
                                        'revenueInvalidationRequested' in currentDeclaration &&
                                        currentDeclaration.revenueInvalidationRequested
                                    }
                                    cancelSaveProduct={() => {
                                        setCancelClicked(true);
                                        setFormAction(null);
                                        if (declarationValidation.hasUnsavedChanges) {
                                            setFormAction(FormAction.PRODUCT);
                                        }
                                        navigate(`/declarations/${declaration?.id}/products`, {
                                            replace: true,
                                            state: { comingBackFromProductView: true },
                                        });
                                    }}
                                    saveProduct={{
                                        trigger: () => {
                                            showGlobalOverlay({
                                                type: 'LoadingOverlay',
                                            });
                                            setTriedToSubmit(false);
                                            setFormAction(FormAction.PRODUCT);
                                        },
                                        loading: isSaving,
                                    }}
                                    saveDraft={{
                                        trigger: async () => {
                                            showGlobalOverlay({
                                                type: 'LoadingOverlay',
                                            });
                                            setTriedToSubmit(false);
                                            setFormAction(FormAction.DRAFT);
                                        },
                                        loading: isSaving,
                                    }}
                                    submitDeclaration={{
                                        trigger: async (saveProduct?: boolean) => {
                                            showGlobalOverlay({
                                                type: 'LoadingOverlay',
                                            });

                                            if (!declaration?.id) return;

                                            /* FIXME: this setVALUE it's just for the declaration's product list to have the same ordering as the product table */
                                            const params: Partial<PaginatedParams> = { size: 1000 };

                                            let declarationWitNewItem: TDeclaration | undefined = undefined;
                                            if (saveProduct && mapper.listProductsFuncName) {
                                                await handleProductsFormSubmit(productsFormik.values);
                                                await refetchDeclaration();
                                                await validateForm();

                                                const productsList = (
                                                    await (
                                                        products[mapper.listProductsFuncName] as ListProductFunction
                                                    )(declaration.id, params)
                                                ).list;

                                                declarationWitNewItem = set(
                                                    formik.values,
                                                    mapper.itemPath,
                                                    productsList
                                                );
                                            }

                                            await saveAsDraft(true, {}, declarationWitNewItem);
                                            const isValid = await validateForm();

                                            if (!isValid) {
                                                showErrorNotification(
                                                    t('error.declaration_invalid_title'),
                                                    t('error.formRequired')
                                                );
                                            }

                                            displayModalSubmit(
                                                hasRightCertificate,
                                                getDeclarationProductTaxes,
                                                undefined,
                                                undefined,
                                                declaration.irelandImportDeclaration !== undefined
                                            );

                                            setTriedToSubmit(true);

                                            hideGlobalOverlay();
                                        },
                                        loading: isSubmitting,
                                    }}
                                />
                            )}
                            <WarningSubmitModal
                                country={declarationCountry}
                                isOpen={digitalCertificateModal}
                                close={() => {
                                    onCloseDigitalCertificateModal();
                                }}
                            />
                            {declaration?.id && (
                                <ModalPreviewSadAndClearanceSlip
                                    declarationId={declaration.id}
                                    isOpen={modalPreviewSadAndClearanceSlip}
                                    onClickErrorDetails={handleFormErrorDetails}
                                    close={() => {
                                        onCloseModalPreviewSadAndClearanceSlip();
                                    }}
                                    confirm={() => {
                                        handleSubmit();
                                    }}
                                />
                            )}
                            <ValidationErrorContainer
                                isOpen={validationErrorContainer}
                                close={() => {
                                    setValidationErrorContainer(false);
                                }}
                            />
                            <SubmissionErrorsDrawer
                                errors={submissionErrors}
                                open={openSubmissionErrorsDrawer}
                                onClose={() => setOpenSubmissionErrorsDrawer(false)}
                            />
                            <TemplateModal />
                        </ProductContextProvider>
                    </MirrorMetaContextProvider>
                </TemplateContextProvider>
            </DeclarationContextProvider>
        </StyledLayout>
    );
};

export default DeclarationView;
