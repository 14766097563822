import { Form, Modal, ModalProps, Select } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { ReactElement } from 'react';
import { HollowButton } from 'views/declarations/common/box44/Box44';

type Props = ModalProps & {
    onContinue: (data: { country: string; declarationType: string }) => void;
};

const MirrorDeclarationModal = ({ onContinue, ...modalProps }: Props): ReactElement => {
    return (
        <Modal
            title={<H5>Mirror Declaration</H5>}
            destroyOnClose
            footer={[
                <HollowButton onClick={modalProps.onCancel} size="large">
                    Cancel
                </HollowButton>,
                <HollowButton form="mirrorForm" htmlType="submit" inverted size="large">
                    Continue
                </HollowButton>,
            ]}
            {...modalProps}
        >
            <Form style={{ marginTop: '1rem', height: 180 }} id="mirrorForm" layout="vertical" onFinish={onContinue}>
                <Form.Item name="country" label="Country:" rules={[{ required: true, message: 'Field is required' }]}>
                    <Select
                        showSearch
                        allowClear
                        options={[
                            { value: 'ireland', label: 'Ireland' },
                            // TODO Mirroring - Implement others when necessary
                        ]}
                        placeholder="Enter Country"
                    />
                </Form.Item>
                <Form.Item
                    name="declarationType"
                    label="Declaration Type:"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Select
                        options={[
                            { value: 'IMPORT', label: 'Import' },
                            // TODO Mirroring - Implement others when necessary
                        ]}
                        placeholder="Enter Declaration Type"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default MirrorDeclarationModal;
