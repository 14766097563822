import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplyChainActor } from 'store/declarations/common/supply-chain-actor';
import { supplyChainActorRoleCodeLabels } from 'store/declarations/enums/ireland/supply-chain-actors-role-code';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const SupplyChainActorCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const fields = props.getFields ? props.getFields() : [];
    const { viewOnly } = props;

    const formik = useFormik<SupplyChainActor>({
        initialValues: {},
        validationSchema: Yup.object().shape({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`${props.propsPathPrefix}`),
            fieldMeta: props.getFieldMeta && props.getFieldMeta(`${props.propsPathPrefix}`),
            fieldHelper: props.getFieldHelpers(`${props.propsPathPrefix}`),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const supplyChainActors = useMemo(() => {
        const list = props.getFieldProps(`${props.propsPathPrefix}`).value;
        if (Array.isArray(list)) {
            return list.map((obj: SupplyChainActor, index) => {
                return [
                    { field: 'Code', value: obj.roleCode },
                    { field: 'Identification', value: obj.traderIdentification },
                ];
            });
        }
        return [];
    }, [props]);

    return (
        <section
            id={'tsd-supply-chain-actor-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-supply-chain-actor-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('tsd.supplyChainActorCard')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <CardList
                    data={supplyChainActors}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    viewOnly={viewOnly ?? false}
                />
                {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
            </FormCard>

            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`roleCode`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="3.37"
                            label={t('supplyChainActor.roleCode')}
                            tooltip={getH1TooltipsByRefNumberAndField('3.37', t('supplyChainActor.roleCode'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={supplyChainActorRoleCodeLabels}
                            hideCodelistMenu
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={18}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`traderIdentification`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="3.37"
                            label={t('supplyChainActor.traderIdentification')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '3.37',
                                t('supplyChainActor.traderIdentification')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};

export default SupplyChainActorCard;
