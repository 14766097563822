import { getCdsTooltips, getH1Tooltips, getH7Tooltips, getRevenueExportTooltips } from './client';

export const doGetH7Tooltips: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_H7_TOOLTIPS_REQUEST' });

    try {
        const payload = await getH7Tooltips();
        dispatch({
            type: 'GET_H7_TOOLTIPS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_H7_TOOLTIPS_ERROR', error: e?.response?.data });
    }
};

export const doGetH1Tooltips: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_H1_TOOLTIPS_REQUEST' });

    try {
        const payload = await getH1Tooltips();
        dispatch({
            type: 'GET_H1_TOOLTIPS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_H1_TOOLTIPS_ERROR', error: e?.response?.data });
    }
};

export const doGetCdsTooltips: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_UK_CDS_TOOLTIPS_REQUEST' });

    try {
        const payload = await getCdsTooltips();
        dispatch({
            type: 'GET_UK_CDS_TOOLTIPS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_UK_CDS_TOOLTIPS_ERROR', error: e?.response?.data });
    }
};

export const doGetIrelandExportTooltips: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_IRELAND_EXPORT_TOOLTIPS_REQUEST' });

    try {
        const payload = await getRevenueExportTooltips();
        dispatch({
            type: 'GET_IRELAND_EXPORT_TOOLTIPS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_IRELAND_EXPORT_TOOLTIPS_ERROR', error: e?.response?.data });
    }
};
