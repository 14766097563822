import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { ColumnsType } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { dateStandardFormat } from 'core/utils/date';
import { isValid, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDigitalCertificates from '../../../../hooks/useDigitalCertificates';
import {
    DigitalCertificate,
    DigitalCertificateSortParameter,
    digitalCertificateType,
} from '../../../../store/digital-certificates/digital-certificates';
import { getTableChangeParams } from '../../../../utils/tableHelpers';

interface Props {
    data?: ListPayload<DigitalCertificate>;
    onDelete?: (ids: string[]) => void;
    loading?: boolean;
}

const DigitalCertificateTable: FC<Props> = ({ data, onDelete, loading }) => {
    const { t } = useTranslation('settings');
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { listDigitalCertificates } = useDigitalCertificates();

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: DigitalCertificate[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const handleNullValues = (value?: string) => (value ? <span>{value}</span> : <span>-</span>);
    const handleDate = (value: string) => {
        const date = parseISO(value);
        return isValid(date) ? <span>{dateStandardFormat(date)}</span> : <span>-</span>;
    };

    const columns: ColumnsType<DigitalCertificate> = [
        {
            title: t('digitalCertificate.labels.type'),
            dataIndex: DigitalCertificateSortParameter.TYPE,
            key: DigitalCertificateSortParameter.TYPE,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) =>
                handleNullValues(digitalCertificateType.find((t) => t.id === record.digitalCertificateType)?.value),
        },
        {
            title: t('digitalCertificate.labels.title'),
            dataIndex: DigitalCertificateSortParameter.TITLE,
            key: DigitalCertificateSortParameter.TITLE,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleNullValues(record.title),
        },
        {
            title: t('digitalCertificate.labels.filename'),
            dataIndex: DigitalCertificateSortParameter.FILENAME,
            key: DigitalCertificateSortParameter.FILENAME,
            align: 'left',
            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleNullValues(record.filename),
        },
        {
            title: t('digitalCertificate.labels.date'),
            dataIndex: DigitalCertificateSortParameter.CREATED_AT,
            key: DigitalCertificateSortParameter.CREATED_AT,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleDate(record.createdAt),
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text: string, record: DigitalCertificate, index: number) => {
                return (
                    <Button size="small" onClick={() => onDelete && onDelete([record.id!])}>
                        Delete <DeleteOutlined />
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                }}
                onChange={(pagination, _, sorter) =>
                    listDigitalCertificates(getTableChangeParams({ pagination, sorter }))
                }
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete && onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};

export default DigitalCertificateTable;
