import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { CustomerActions } from './actionType';
import { Customer } from './customer';

interface CustomerEntities {
    [key: string]: Customer;
}
export interface CustomersState {
    isLoading: boolean;
    error?: ErrorResponse;
    customers: ListPayload<Customer>;
    entities: CustomerEntities;
}

export const customersInitialState: Readonly<CustomersState> = {
    isLoading: false,
    customers: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const customers: Reducer<CustomersState, CustomerActions> = (state = customersInitialState, action): CustomersState => {
    switch (action.type) {
        case 'LIST_CUSTOMERS_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'LIST_CUSTOMERS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                customers: action.payload,
            };
        case 'LIST_CUSTOMERS_ERROR':
            return {
                ...state,
                isLoading: false,
                customers: state.customers,
                error: action.error,
            };
        case 'CREATE_CUSTOMER_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'CREATE_CUSTOMER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_CUSTOMER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_CUSTOMER_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'GET_CUSTOMER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_CUSTOMER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_CUSTOMER_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'EDIT_CUSTOMER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_CUSTOMER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_CUSTOMER_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_CUSTOMER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_CUSTOMER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default customers;
