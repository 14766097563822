import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';

const RefundsList: FC<{}> = () => {
    return <>Refunds list</>;
};
export default RefundsList;
export function useRefundsList() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
