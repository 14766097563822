import config from 'config';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import { IrelandRefundDeposit, IrelandRefundDepositHistory } from './deposits/ireland-refund-deposit';
import { IrelandRefundRemission, IrelandRefundRemissionHistory } from './remission/ireland-refund-remission';

// Import (H1 - H6)

export const createRefundRemissionDeclaration = (
    declarationId: string,
    body: IrelandRefundRemission
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/remissions`,
            body
        )
        .then((response) => response.data.payload);
};

export const updateRefundRemissionDeclaration = (
    declarationId: string,
    body: IrelandRefundRemission,
    refundId: string
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .put<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/remissions/${refundId}`,
            body
        )
        .then((response) => response.data.payload);
};

export const submitRefundRemissionDeclaration = (
    declarationId: string,
    refundId: string
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/remissions/${refundId}`
        )
        .then((response) => response.data.payload);
};

export const createRefundDepositDeclaration = (
    declarationId: string,
    body: IrelandRefundDeposit
): Promise<IrelandRefundDepositHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundDepositHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/deposits`,
            body
        )
        .then((response) => response.data.payload);
};

export const updateRefundDepositDeclaration = (
    declarationId: string,
    body: IrelandRefundDeposit,
    refundId: string
): Promise<IrelandRefundDepositHistory> => {
    return axiosClient
        .put<SuccessResponse<IrelandRefundDepositHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/deposits/${refundId}`,
            body
        )
        .then((response) => response.data.payload);
};

export const submitRefundDepositDeclaration = (
    declarationId: string,
    refundId: string
): Promise<IrelandRefundDepositHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundDepositHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/refund/deposits/${refundId}`
        )
        .then((response) => response.data.payload);
};

// Import (H7)

export const createImportH7RefundRemissionDeclaration = (
    declarationId: string,
    body: IrelandRefundRemission
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/import/declarations/h7/${declarationId}/refund/remissions`,
            body
        )
        .then((response) => response.data.payload);
};

export const updateImportH7RefundRemissionDeclaration = (
    declarationId: string,
    body: IrelandRefundRemission,
    refundId: string
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .put<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/declarations/h7/${declarationId}/refund/remissions/${refundId}`,
            body
        )
        .then((response) => response.data.payload);
};

export const submitImportH7RefundRemissionDeclaration = (
    declarationId: string,
    refundId: string
): Promise<IrelandRefundRemissionHistory> => {
    return axiosClient
        .post<SuccessResponse<IrelandRefundRemissionHistory>>(
            `${config.declarationsUrl}/ireland/declarations/h7/${declarationId}/refund/remissions/${refundId}`
        )
        .then((response) => response.data.payload);
};
