import CloseSquareOutlined from '@ant-design/icons/lib/icons/CloseSquareOutlined';
import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Drawer from 'components/ui/base/drawer/Drawer';
import { H4 } from 'components/ui/base/typography';
import useCustomers from 'hooks/useCustomers';
import useJobs from 'hooks/useJobs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import CustomerInfo from 'views/customers/components/CustomerInfo';
import { ButtonRow, CustomerInfoDiv, FieldName, FieldValue, MainDiv, RowValue } from './CustomerDetails.styles';

interface Props {
    customerId?: string;
}

const CustomerDetails: FC<Props> = () => {
    const { customerId } = useCustomerDocuments();
    const [customer, setCustomer] = useState<Customer>();
    const { t } = useTranslation('form');
    const { customer: customerLoad, getCustomer } = useCustomers({ customerId });
    const { listJobs, jobs } = useJobs();
    const [showCustomerDetails, setShowCustomerDetails] = useState(false);

    useEffect(() => {
        getCustomer(customerId);
        listJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        setCustomer(customerLoad);
    }, [customerLoad]);

    const activeJobs = useMemo(
        () =>
            jobs && jobs?.total > 0 ? (
                <FieldValue>{jobs.total}</FieldValue>
            ) : (
                <FieldValue>
                    No active jobs <CloseSquareOutlined style={{ color: 'red' }} />
                </FieldValue>
            ),

        [jobs]
    );

    return (
        <>
            <MainDiv>
                <Row>
                    <Col span={18}>
                        <H4>{t('customerDetails.title')}</H4>
                    </Col>
                </Row>
                <CustomerInfoDiv>
                    <Row>
                        <Col span={24}>
                            <CustomerInfo customer={customer} />
                            <RowValue>
                                <Col span={24}>
                                    <FieldName>{t('customerDetails.activeJob')} </FieldName>
                                    {activeJobs}
                                </Col>
                            </RowValue>
                        </Col>
                    </Row>
                    <ButtonRow>
                        <Col span={6}>
                            <Button
                                size="large"
                                style={{ width: '10.0rem' }}
                                onClick={() => {
                                    setShowCustomerDetails(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Col>
                    </ButtonRow>
                </CustomerInfoDiv>
            </MainDiv>
            <Drawer
                title="Edit Customer"
                width="627"
                visible={showCustomerDetails}
                onClose={() => {
                    setShowCustomerDetails(false);
                }}
            >
                <CreateNewCustomer
                    refreshCustomers={() => {
                        getCustomer(customerId);
                    }}
                    closeModal={() => {
                        setShowCustomerDetails(false);
                    }}
                    handleOk={() => {}}
                    handleEdit={() => {
                        setShowCustomerDetails(false);
                    }}
                    customer={customer}
                    handleCreateError={() => {}}
                    handleEditError={() => {}}
                />
            </Drawer>
        </>
    );
};
export default CustomerDetails;

function useCustomerDocuments() {
    return useOutletContext<{
        customerId: string;
    }>();
}
