import { FC, useMemo } from 'react';
import { format } from 'date-fns';
import { AimOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
    StyledLegRow,
    StyledInProgressLegDate,
    StyledDayAndMonth,
    StyledLegDetails,
    StyledAssignedLegDate,
    StyledAddressRow,
    StyledInfo,
    StyledTitle,
    StyledInfoRow,
} from './LegRow.styles';
import { Leg, LegStatus } from 'store/journeys/legs/leg';
import LegStatusTag from './LegStatusTag';

const LegRow: FC<{ leg: Leg }> = ({ leg }) => {
    const navigate = useNavigate();

    const legDate = useMemo(() => {
        return (
            <>
                <p>{format(new Date(leg.startDateTime), 'hh:mm')}</p>
                <StyledDayAndMonth>
                    <p>{format(new Date(leg.startDateTime), 'dd')}</p>
                    <p>{format(new Date(leg.startDateTime), 'MMM')}</p>
                </StyledDayAndMonth>
            </>
        );
    }, [leg]);

    return (
        <StyledLegRow onClick={() => navigate(`/mobile/legs/${leg.id}`)}>
            {leg.status === LegStatus.IN_PROGRESS ? (
                <StyledInProgressLegDate>{legDate}</StyledInProgressLegDate>
            ) : (
                <StyledAssignedLegDate>{legDate}</StyledAssignedLegDate>
            )}
            <StyledLegDetails>
                <StyledAddressRow>
                    <UploadOutlined />
                    {leg.origin?.address.addressLine1}
                </StyledAddressRow>
                <StyledAddressRow>
                    <AimOutlined />
                    {leg.destination?.address.addressLine1}
                </StyledAddressRow>
                <StyledInfoRow>
                    <StyledInfo>
                        <StyledTitle>Status</StyledTitle>
                        <LegStatusTag status={leg.status} />
                    </StyledInfo>
                    <StyledInfo>
                        <StyledTitle>Goods</StyledTitle>
                        <p>Wheat TBC</p>
                    </StyledInfo>
                </StyledInfoRow>
            </StyledLegDetails>
        </StyledLegRow>
    );
};

export default LegRow;
