import CheckCircleIcon from 'assets/check-circle.svg';
import useSession from 'hooks/useSession';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IndividualType } from 'store/individuals/individual';
import { InvitePageProps, OnboardingSection } from 'views/authentication/invite/Invite';
import {
    ButtonDiv,
    ContentDiv,
    ContinueButton,
    DivDDFeatures,
    DivRtl,
    Image,
    RowDiv,
    Subtitle,
    Text,
} from 'views/authentication/invite/sections/common/CheckList.styles';
interface BrokerFeaturesProps extends InvitePageProps {}

const checkItems = [
    {
        title: 'Accept Declarations',
    },
    {
        title: 'Complete Declarations',
    },
    {
        title: 'Manage Documents',
    },
];

const BrokerFeatures: FC<BrokerFeaturesProps> = ({ onChangePage }) => {
    const { t } = useTranslation('onboarding');
    const { userInfo, isLogged } = useSession();

    const handleContinue = () => {
        if (isLogged && userInfo) {
            if (
                userInfo.role === IndividualType.BROKER_ADMIN ||
                userInfo.role === 'SYSTEM_ADMIN' //TODO remove this condition after change in server
            ) {
                onChangePage(OnboardingSection.ADD_TEAM_MEMBERS);
            } else {
                onChangePage(OnboardingSection.INTRO);
            }
        }
    };

    return (
        <>
            <DivDDFeatures>
                <Subtitle>{t('features_title')}</Subtitle>
            </DivDDFeatures>
            <ContentDiv>
                {checkItems.map((obj, index) => {
                    return (
                        <RowDiv key={index}>
                            <Text>{obj.title} </Text>
                            <DivRtl>
                                <Image src={CheckCircleIcon} alt="check" />
                            </DivRtl>
                        </RowDiv>
                    );
                })}
            </ContentDiv>
            <ButtonDiv>
                <ContinueButton
                    data-testid="continue-button"
                    type="primary"
                    onClick={() => {
                        handleContinue();
                    }}
                    size="large"
                >
                    {t('continue')}
                </ContinueButton>
            </ButtonDiv>
        </>
    );
};
export default BrokerFeatures;
