import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from '../../../../views/declarations/Form.styles';
import IconTooltip from '../../base/tooltip/IconTooltip';
import { FlexDiv, RefDiv } from '../declarations/formInput/DeclarationInput.styles';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
} from '../formInput/FormInput.styles';
import { StyledTextArea } from './FormTextArea.styles';

export interface FormTextAreaProps {
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
    rows?: number;
    placeholder?: string;
    refNumber?: string;
    tooltip?: string;
    condensed?: boolean;
}

const FormTextArea: FC<FormTextAreaProps> = ({
    label,
    fieldMeta,
    fieldProps,
    fieldHelper,
    disabled,
    required,
    maxLength,
    rows = 4,
    placeholder,
    refNumber,
    tooltip,
}) => {
    const { t } = useTranslation('common');

    useEffect(() => {
        if (fieldProps.value === '' && fieldHelper) {
            fieldHelper.setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.value]);

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    return (
        <>
            <FlexDiv>
                <LabelDiv style={{ alignItems: 'start' }}>
                    {label && <InputLabel>{label}</InputLabel>}
                    {required && <InputLabelRequired>{t('*')}</InputLabelRequired>}
                    {refNumber && <RefDiv>{refNumber}</RefDiv>}
                    {showTooltip && (
                        <IconTooltip
                            title={label}
                            tooltip={tooltip ?? t('defaultTooltip')}
                            icon={<StyledInfoCircleFilled />}
                        />
                    )}
                </LabelDiv>
            </FlexDiv>

            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <InputDiv>
                    <StyledTextArea
                        placeholder={placeholder}
                        rows={rows}
                        disabled={disabled}
                        maxLength={maxLength}
                        {...fieldProps}
                    />
                </InputDiv>
                <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                    {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                </ErrorDiv>
            </FormItem>
        </>
    );
};

export default FormTextArea;
