import { DatePicker } from 'antd';
import styled from 'styled-components';

export const AStyledDatePicker = styled(DatePicker)<{ condensed?: boolean; mirrored?: boolean }>`
    width: 100%;
    height: 3.2rem;
    border-radius: ${({ condensed }) => (condensed ? '4px' : '2px')};
    border: ${({ condensed, mirrored }) =>
        condensed && !mirrored ? '1px solid #cecece' : mirrored ? '1.5px solid #00CCFF' : 'hsv(0, 0, 85%)'};
    & input:first-child {
        color: ${({ mirrored }) => mirrored && '#00CCFF'};
    }
`;

export const DatePickerDiv = styled.div`
    width: 100%;
    display: inline-flex;
`;
