import {
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    getFloatValidation,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

export const transportDocument = Yup.object().shape({
    transportDocumentReferenceNumber: getMaxValidation('transportDocumentReferenceNumber', 70)
        .required(getRequiredMessage('transportDocumentReferenceNumber'))
        .template()
        .nullable(),
    transportDocumentType: getMaxValidation('transportDocumentType', 4)
        .required(getRequiredMessage('transportDocumentType'))
        .template()
        .nullable(),
    transportAndInsuranceCostsToTheDestinationAmount: getFloatValidation(
        'transportAndInsuranceCostsToTheDestinationAmount',
        16,
        2
    )
        .template()
        .nullable(),
    transportAndInsuranceCostsToTheDestinationCurrency: getExactLengthValidation(
        'transportAndInsuranceCostsToTheDestinationCurrency',
        3
    )
        .template()
        .nullable(),
});

export const valuationInformation = Yup.object().shape({
    transportDocument: Yup.array().of(transportDocument).nullable(),
    transportAndInsuranceCostsToTheDestinationAmount: Yup.string().template().nullable(),
    transportAndInsuranceCostsToTheDestinationCurrency: Yup.string().template().nullable(),
});

export const documentsAuthorisations = Yup.object({
    referenceNumberUcr: getMaxValidation('referenceNumberUcr', 35).template().nullable(),
    previousDocument: Yup.array()
        .of(
            Yup.object().shape({
                previousDocumentReferenceNumber: getMaxValidation('previousDocumentReferenceNumber', 70).nullable(),
                previousDocumentType: getMaxValidation('previousDocumentType', 4).nullable(),
            })
        )
        .nullable(),
    supportingDocument: Yup.array()
        .of(
            Yup.object().shape({
                supportingDocumentReferenceNumber: getMaxValidation('documentIdentifier', 70).nullable(),
                supportingDocumentType: getMaxValidation('documentType', 4).nullable(),
            })
        )
        .nullable(),
    additionalInformation: Yup.array()
        .of(
            Yup.object().shape({
                additionalInformationCode: getExactLengthValidation('additionalInformationCode', 5).nullable(),
                additionalInformationText: getMaxValidation('additionalInformationText', 512).nullable(),
            })
        )
        .nullable(),
});
