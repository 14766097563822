import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import AdditionalInformationCard from '../cards/AdditionalInformationCard';
import ConsigneeCard from '../cards/ConsigneeCard';
import PackagingCard from '../cards/PackagingCard';
import ProducedDocumentsWritingOffCard from '../cards/ProducedDocumentsWritingOffCard';
import SimplifiedDeclarationDocumentWritingOffCard from '../cards/SimplifiedDeclarationDocumentWritingOffCard';
import SupplyChainActorCard from '../cards/SupplyChainActorCard';
import GoodShipmentItemCard from './cards/GoodShipmentItemCard';
import TransportInformationCard from './cards/TransportInformationCard';

export interface MasterDetailsIrelandEtdCards {
    cards: Card[];
}

const etdProductsDeclarationSkeleton: MasterDetailsIrelandEtdCards = {
    cards: [
        {
            key: 'etd-goods-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'goodsInformation',
            valid: false,
            component: GoodShipmentItemCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: '',
            getFields: () => [
                { name: `goodsItemNumber`, required: true },
                { name: `transitDeclaration`, required: true },
            ],
        },
        {
            key: 'etd-simplified-document-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: SimplifiedDeclarationDocumentWritingOffCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `previousDocumentType`, required: false },
                { name: `previousDocumentLineId`, required: false },
                { name: `previousDocumentIdentifier`, required: false },
            ],
        },
        {
            key: 'etd-produced-documents-writing-off-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: ProducedDocumentsWritingOffCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `documentType`, required: false },
                { name: `documentIdentifier`, required: false },
            ],
        },
        {
            key: 'etd-additional-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'additionalInformation',
            valid: false,
            component: AdditionalInformationCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'documentsAuthorisations',
            getFields: () => [
                { name: `additionalInformationCode`, required: false },
                { name: `additionalInformationText`, required: false },
            ],
        },
        {
            key: 'etd-consignee-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 3,
            defaultOpen: false,
            title: 'consignee',
            valid: false,
            component: ConsigneeCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'parties.consignee',
            getFields: () => [
                { name: `eori`, required: false },
                { name: `name`, required: false },
                { name: `streetAndNumber`, required: false },
                { name: `country`, required: false },
                { name: `postCode`, required: false },
                { name: `city`, required: false },
                { name: `tin`, required: false },
            ],
        },
        {
            key: 'etd-supply-chain-actor-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'supplyChainActor',
            valid: false,
            component: SupplyChainActorCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'parties',
            getFields: () => [
                { name: `roleCode`, required: false },
                { name: `traderIdentification`, required: false },
            ],
        },
        {
            key: 'etd-packaging-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'packaging',
            valid: false,
            component: PackagingCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'goodsInformation',
            getFields: () => [
                { name: `roleCode`, required: false },
                { name: `traderIdentification`, required: false },
            ],
        },
        {
            key: 'etd-transport-information-form-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 4,
            defaultOpen: false,
            title: 'transportInformation',
            valid: false,
            component: TransportInformationCard,
            declarationType: DeclarationName.ETD,
            propsPathPrefix: 'transportInformation',
            getFields: () => [
                { name: `departureTransportMeans.identificationType`, required: false },
                { name: `departureTransportMeans.identificationNumber`, required: false },
                { name: `nationality`, required: false },
            ],
        },
    ],
};
export default etdProductsDeclarationSkeleton;
