import { Button, Checkbox, Col, Modal, Row, Space } from 'antd';
import { H4, Text } from 'components/ui/base/typography';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import { useFormik } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { eDocketFormOperatorConfirmationValidationSchema } from '../eDocketFormValidationSchema';
import { EDocketT } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import EDocketFormInput from './inputs/EDocketFormInput';
import EDocketFormSignature from './inputs/EDocketFormSignature';

const { ButtonContainer } = EDocketFormStyledComponents;

export type OperatorConfirmationFormDriverType = {
    name: string;
    email: string;
    phoneCode: string;
    phoneNumber: string;
};

export type OperatorConfirmationForm = {
    driver: OperatorConfirmationFormDriverType;
    hasConfirmedDriverInformation: boolean;
    operatorSignature: string;
    netWeight: string;
};

const operatorConfirmationFormInitialValues: OperatorConfirmationForm = {
    driver: {
        name: '',
        email: '',
        phoneCode: '',
        phoneNumber: '',
    },
    hasConfirmedDriverInformation: false,
    operatorSignature: '',
    netWeight: '',
};

type Props = {
    visible: boolean;
    onSubmit: (operatorSignature: OperatorConfirmationForm['operatorSignature']) => void;
    onCancel: () => void;
    t: EDocketT;
};

const EDocketFormOperatorConfirmationModal = ({
    visible,
    onCancel: handleCancel,
    onSubmit: handleSubmit,
    t,
}: Props) => {
    const formik = useFormik<OperatorConfirmationForm>({
        initialValues: operatorConfirmationFormInitialValues,
        validationSchema: eDocketFormOperatorConfirmationValidationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    // Validate form
    // Debouncing to avoid validation on each change, thus not hindering performance
    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);

    return (
        <Modal visible={visible} onCancel={handleCancel} width={800} footer={false}>
            <Space size={24} direction="vertical" style={{ width: '100%' }}>
                <H4>Weighbridge Operator Confirmation</H4>
                <Text>Enter the Driver's details associated with the cargo collection</Text>
                <Row gutter={[8, 8]}>
                    <Col xs={12}>
                        <EDocketFormInput
                            label={t('operatorConfirmation.driver.name.label')}
                            name="driver.name"
                            onChange={formik.handleChange}
                            value={formik.values.driver.name}
                            placeholder={t('operatorConfirmation.driver.name.placeholder')}
                        />
                    </Col>
                    <Col xs={12}>
                        <EDocketFormInput
                            label={t('operatorConfirmation.driver.email.label')}
                            name="driver.email"
                            onChange={formik.handleChange}
                            value={formik.values.driver.email}
                            placeholder={t('operatorConfirmation.driver.email.placeholder')}
                        />
                    </Col>
                    <Col xs={24}>
                        <FormPhoneNumber
                            fieldCodeProps={formik.getFieldProps('driver.phoneCode')}
                            fieldNumberProps={formik.getFieldProps('driver.phoneNumber')}
                            fieldCodeMeta={formik.getFieldMeta('driver.phoneCode')}
                            fieldNumberMeta={formik.getFieldMeta('driver.phoneNumber')}
                        />
                    </Col>
                </Row>
                <ButtonContainer>
                    <Checkbox
                        name="hasConfirmedDriverInformation"
                        checked={formik.values.hasConfirmedDriverInformation}
                        onChange={formik.handleChange}
                    >
                        {t('operatorConfirmation.confirmInformationAboveIsCorrect')}
                    </Checkbox>
                </ButtonContainer>
                <Row>
                    <Col xs={24}>
                        <EDocketFormSignature
                            label={t('operatorConfirmation.operatorSignature.label')}
                            name="operatorSignature"
                            onChange={formik.handleChange}
                            value={formik.values.operatorSignature}
                            placeholder={t('operatorConfirmation.operatorSignature.placeholder')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <EDocketFormInput
                            label={t('operatorConfirmation.netWeight.label')}
                            name="netWeight"
                            onChange={formik.handleChange}
                            value={formik.values.netWeight}
                            placeholder={t('operatorConfirmation.netWeight.placeholder')}
                        />
                    </Col>
                </Row>
                <ButtonContainer>
                    <Button onClick={handleCancel}>Close</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            // TODO Send confirmation to driver
                            handleSubmit(formik.values.operatorSignature);
                            handleCancel();
                        }}
                        disabled={!isEmpty(formik.errors)}
                    >
                        {t('operatorConfirmation.sendDriverVerification')}
                    </Button>
                </ButtonContainer>
            </Space>
        </Modal>
    );
};

export default EDocketFormOperatorConfirmationModal;
