import Notification from 'components/ui/base/notification/Notification';
import { useFormik } from 'formik';
import useDeclarationRefund from 'hooks/useDeclarationRefund';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Declaration } from 'store/declarations/declaration';
import { IrelandRefundDeposit } from 'store/declarations/ireland/refunds/deposits/ireland-refund-deposit';
import RefundFooter from 'views/declarations/footer/RefundFooter';
import { FormContent } from '../IrelandImportRefunds.styles';
import AdditionalInformationCard from './cards/AdditionalInformationCard';
import AmountsHeldOnDepositCard from './cards/AmountsHeldOnDepositCard';
import DepositMasterDetailsCard from './cards/DepositMasterDetailsCard';
import ExportGoodsInformationCard from './cards/ExportGoodsInformationCard';
import ImportGoodsInformationCard from './cards/ImportGoodsInformationCard';
import OtherDocumentsCard from './cards/OtherDocumentsCard';
import validation from './validations/validation';

interface Props {
    declaration: Declaration;
}

const IrelandImportH1DepositsRefunds: FC<Props> = ({ declaration }) => {
    const { t } = useTranslation('common');
    const {
        deposit,
        depositHistory,
        submitIrelandDepositRefund,
        updateIrelandDepositRefund,
        createIrelandDepositRefund,
    } = useDeclarationRefund({
        declarationId: declaration.id!,
    });

    const { getFieldHelpers, getFieldMeta, getFieldProps, submitForm } = useFormik({
        initialValues: deposit ?? {},
        validationSchema: validation,
        onSubmit: async (deposit: IrelandRefundDeposit) => {
            if (depositHistory?.id) {
                const response = await updateIrelandDepositRefund(deposit, depositHistory.id);
                return response;
            } else {
                const response = await createIrelandDepositRefund(deposit);
                return response;
            }
        },
    });

    return (
        <>
            <FormContent>
                <DepositMasterDetailsCard
                    key={'deposit-master-details-form-card'}
                    keyCard={'deposit-master-details-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <AmountsHeldOnDepositCard
                    key={'deposit-amount-held-on-deposit-form-card'}
                    keyCard={'deposit-amount-held-on-deposit-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <AdditionalInformationCard
                    key={'deposit-additional-information-form-card'}
                    keyCard={'deposit-additional-information-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <ImportGoodsInformationCard
                    key={'deposit-import-goods-information-form-card'}
                    keyCard={'deposit-import-goods-information-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <ExportGoodsInformationCard
                    key={'deposit-export-goods-information-form-card'}
                    keyCard={'deposit-export-goods-information-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <OtherDocumentsCard
                    key={'other-documents-form-card'}
                    keyCard={'other-documents-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
            </FormContent>
            <RefundFooter
                saveDraft={{
                    trigger: async () => {
                        const response = await submitForm();
                        if (response?.id) {
                            Notification({
                                type: 'success',
                                messageTitle: t('formSubmitSuccessTitle'),
                                description: t('formSubmitSuccessMessage'),
                            });
                        } else {
                            Notification({
                                type: 'error',
                                messageTitle: t('error.formCreateErrorTitle'),
                                description: t('error.formCreateErrorMessage'),
                            });
                        }
                    },
                    loading: false,
                }}
                submitDeclaration={{
                    trigger: async () => {
                        const savedDeclaration = await submitForm();
                        if (savedDeclaration?.id) {
                            const response = await submitIrelandDepositRefund(savedDeclaration.id);
                            if (response?.id) {
                                Notification({
                                    type: 'success',
                                    messageTitle: t('formSubmitSuccessTitle'),
                                    description: t('formSubmitSuccessMessage'),
                                });
                            } else {
                                Notification({
                                    type: 'error',
                                    messageTitle: t('error.formCreateErrorTitle'),
                                    description: t('error.formCreateErrorMessage'),
                                });
                            }
                        }
                    },
                    loading: false,
                }}
            />
        </>
    );
};
export default IrelandImportH1DepositsRefunds;
