import { flow } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDeclarations from '../../../../hooks/useDeclarations';
import MirrorSeveringModal from './MirrorSeveringModal';

export type MetaObject = Record<string, { isMirrored: boolean }>;

const MirrorMetaContextProvider = ({ children }: { children: ReactNode }) => {
    const { declaration } = useDeclarations();
    const { productId } = useParams<{ productId: string }>();

    const [transformedMeta, setTransformedMeta] = useState<MetaObject | undefined>(undefined);

    const [severMirroringModalVisible, setSeverMirroringModalVisible] = useState<boolean>(false);
    const [revertValueCallback, setRevertValueCallback] = useState<(() => void) | undefined>(undefined);

    const handleOpenModal = useCallback((revertValueCallback: () => void) => {
        setRevertValueCallback(() => revertValueCallback);
        setSeverMirroringModalVisible(true);
    }, []);
    const handleCloseModal = useCallback(() => setSeverMirroringModalVisible(false), []);

    const productIndex = useMemo(() => {
        let index = declaration?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem?.findIndex(
            (item) => item.id === productId
        );

        if (index !== -1 && index !== undefined) index = index + 1;
        return index;
    }, [productId, declaration?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem]);

    useEffect(() => {
        if (!declaration?.mirrored) return setTransformedMeta(undefined);

        setTransformedMeta(
            flow([
                Object.entries,
                (entries) =>
                    productId && productIndex
                        ? transformMetaForProduct(entries, productIndex)
                        : transformMetaForMaster(entries),
                Object.fromEntries,
            ])(declaration?.meta)
        );
    }, [productId, productIndex, declaration?.meta, declaration?.mirrored]);

    return (
        <MirrorMetaContext.Provider value={{ meta: transformedMeta, handleOpenModal }}>
            {children}
            <MirrorSeveringModal
                visible={severMirroringModalVisible}
                onClose={handleCloseModal}
                onRevert={revertValueCallback}
            />
        </MirrorMetaContext.Provider>
    );
};

export default MirrorMetaContextProvider;

const MirrorMetaContext = createContext<{
    meta?: MetaObject;
    handleOpenModal?: Function;
}>({});
export const useMirrorMetaContext = () => useContext(MirrorMetaContext);

const transformMetaForMaster = (entries: string[][]) =>
    entries.filter(([path]: string[]) => !path.includes('goodsShipmentItem'));

const transformMetaForProduct = (entries: string[][], productIndex: number) =>
    entries
        .filter(([path]: string[]) => +path.split('.')[1] === productIndex)
        .map(([path, value]: string[]) => [path.split('.').slice(2).join('.'), value]);
