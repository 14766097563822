import useDeclarations from 'hooks/useDeclarations';
import { useMemo } from 'react';
import { TemplateData } from 'store/template/template';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import { FormType, useTemplateContext } from './TemplateContext';

const useFieldTemplateMetaData = (fieldName?: string | undefined) => {
    const { form } = useDeclarationContext();
    const { template, templateFormik, form: templateForm } = useTemplateContext();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { declarationTemplate } = useDeclarations();

    const templateData = useMemo(() => {
        if (productTemplateDeclarationTemplate) {
            return productTemplateDeclarationTemplate.template;
        }
        return template && templateFormik ? templateFormik.values : declarationTemplate?.template;
    }, [template, templateFormik, declarationTemplate?.template, productTemplateDeclarationTemplate]);
    const _form = useMemo(() => {
        if (productTemplateDeclarationTemplate) {
            return 'product';
        }
        return template && templateForm ? templateForm : form;
    }, [form, productTemplateDeclarationTemplate, template, templateForm]);

    const isViewable = useMemo(() => {
        if (!fieldName || !_form) return false;
        const viewable = templateData?.[_form]?.meta?.[fieldName]?.isViewable;
        return viewable === undefined ? false : viewable;
    }, [_form, templateData, fieldName]);

    const isEditable = useMemo(() => {
        if (!fieldName || !_form) return true;
        const editable = templateData?.[_form]?.meta?.[fieldName]?.isEditable;
        return editable === undefined ? true : editable;
    }, [_form, templateData, fieldName]);

    return { isViewable, isEditable, isInvisible: !isViewable && !isEditable };
};

export const getFieldTemplateMetaData = (
    fieldName: string | undefined,
    form: FormType | undefined,
    templateData: TemplateData | undefined
) => {
    const isViewable = () => {
        if (!fieldName || !form) return false;
        const viewable = templateData?.[form]?.meta?.[fieldName]?.isViewable;
        return viewable === undefined ? false : viewable;
    };

    const isEditable = () => {
        if (!fieldName || !form) return true;
        const editable = templateData?.[form]?.meta?.[fieldName]?.isEditable;
        return editable === undefined ? true : editable;
    };

    return { viewable: isViewable(), editable: isEditable(), isInvisible: !isViewable() && !isEditable() };
};

export default useFieldTemplateMetaData;
