import { Outlet } from 'react-router-dom';
import SetupStyledComponents from './styles/Setup.styles';

const { Main, Container } = SetupStyledComponents;

const Setup = () => {
    return (
        <Main>
            <Container>
                <Outlet />
            </Container>
        </Main>
    );
};

export default Setup;
