const EnsCodeToFieldMap: Map<string, Field> = new Map();

const replaceRange = (s: string, start: number, end: number, substitute: string) => {
    return s.substring(0, start) + substitute + s.substring(end + 1);
};

const substring = (s: string, range: { start?: number; end?: number }) => {
    return s.substring(range.start ?? 0, range.end ? range.end + 1 : undefined);
};

const setItemNumberOnCode = (code: string, itemNumber: number, range?: { start?: number; end?: number }) => {
    const start = range?.start ?? 0;
    const end = range?.end ?? code.length - 1;

    const itemNumberLength = itemNumber.toString().length;

    const rangeLength = end - start + 1;
    const zeroesToSuffix = rangeLength - itemNumberLength;

    let toReplace = '';
    for (let i = 0; i < zeroesToSuffix; i++) toReplace += '0';
    toReplace += itemNumber;
    return replaceRange(code, start, end, toReplace);
};

interface Field {
    name: string;
    label?: string;
    fields?: Field[];
}
interface AddEntryArgs {
    code: string;
    field: Field;
    options?: {
        ranges?: {
            parent?: { start?: number; end?: number };
            children?: { start?: number; end?: number };
        };
    };
}
const addEntry = ({ code, field, options }: AddEntryArgs) => {
    const { fields: childrenFields, ...fieldData } = field;

    // Children fields are present if the entry is a MIC
    if (childrenFields) {
        for (let i = 0; i < 1000; i++) {
            const fieldDataName = fieldData.name + `.${i}`;
            const parentItemNumber = i + Number(substring(code, options?.ranges?.parent ?? { start: 2, end: 4 }));
            const parentCode = setItemNumberOnCode(
                code,
                parentItemNumber,
                options?.ranges?.parent ?? { start: 2, end: 4 }
            );
            EnsCodeToFieldMap.set(parentCode, { ...fieldData, name: fieldDataName });

            for (const [j, childField] of childrenFields.entries()) {
                const childItemNumber =
                    j + Number(substring(parentCode, options?.ranges?.children ?? { start: 5 })) + 1;
                const childCode = setItemNumberOnCode(
                    parentCode,
                    childItemNumber,
                    options?.ranges?.children ?? { start: 5 }
                );
                const childFieldName = fieldDataName + (childField.name ? `.${childField.name}` : '');
                addEntry({ code: childCode, field: { ...childField, name: childFieldName } });
            }
        }
    } else {
        EnsCodeToFieldMap.set(code, fieldData);
    }
};

// H
// Master Details
addEntry({ code: '010002', field: { name: 'declarationType' } });
addEntry({ code: '010004', field: { name: 'numberOfItems' } });
addEntry({ code: '010005', field: { name: 'totalNumberOfPackages' } });
addEntry({ code: '010013', field: { name: 'totalGrossMass' } });
addEntry({ code: '010014', field: { name: 'specificCircumstanceIndicator' } });
addEntry({ code: '010017', field: { name: 'issuing.place' } });
// Parties
// Agent
addEntry({ code: '060000', field: { name: 'agent' } });
addEntry({ code: '060001', field: { name: 'agent.eori' } });
addEntry({ code: '060002', field: { name: 'agent.name' } });
addEntry({ code: '060003', field: { name: 'agent.streetAndNumber' } });
addEntry({ code: '060005', field: { name: 'agent.postCode' } });
addEntry({ code: '060006', field: { name: 'agent.country' } });
// Entry Carrier
addEntry({ code: '070000', field: { name: 'carrier' } });
addEntry({ code: '070001', field: { name: 'carrier.eori' } });
addEntry({ code: '070002', field: { name: 'carrier.name' } });
addEntry({ code: '070003', field: { name: 'carrier.streetAndNumber' } });
addEntry({ code: '070005', field: { name: 'carrier.postCode' } });
addEntry({ code: '070006', field: { name: 'carrier.country' } });
// Consignor
addEntry({ code: '020000', field: { name: 'goodsShipment.consignor' } });
addEntry({ code: '020001', field: { name: 'goodsShipment.consignor.eori' } });
addEntry({ code: '020002', field: { name: 'goodsShipment.consignor.name' } });
addEntry({ code: '020003', field: { name: 'goodsShipment.consignor.streetAndNumber' } });
addEntry({ code: '020005', field: { name: 'goodsShipment.consignor.postCode' } });
addEntry({ code: '020006', field: { name: 'goodsShipment.consignor.country' } });
// Consignee
addEntry({ code: '030000', field: { name: 'goodsShipment.consignee' } });
addEntry({ code: '030001', field: { name: 'goodsShipment.consignee.eori' } });
addEntry({ code: '030002', field: { name: 'goodsShipment.consignee.name' } });
addEntry({ code: '030003', field: { name: 'goodsShipment.consignee.streetAndNumber' } });
addEntry({ code: '030005', field: { name: 'goodsShipment.consignee.postCode' } });
addEntry({ code: '030006', field: { name: 'goodsShipment.consignee.country' } });
// Notify Party
addEntry({ code: '040000', field: { name: 'goodsShipment.notifyParty' } });
addEntry({ code: '040001', field: { name: 'goodsShipment.notifyParty.eori' } });
addEntry({ code: '040002', field: { name: 'goodsShipment.notifyParty.name' } });
addEntry({ code: '040003', field: { name: 'goodsShipment.notifyParty.streetAndNumber' } });
addEntry({ code: '040005', field: { name: 'goodsShipment.notifyParty.postCode' } });
addEntry({ code: '040006', field: { name: 'goodsShipment.notifyParty.country' } });
// Person Lodging The Summary Declaration
addEntry({ code: '050000', field: { name: 'personLodgingTheSummaryDeclaration' } });
addEntry({ code: '050001', field: { name: 'personLodgingTheSummaryDeclaration.eori' } });
addEntry({ code: '050002', field: { name: 'personLodgingTheSummaryDeclaration.name' } });
addEntry({ code: '050003', field: { name: 'personLodgingTheSummaryDeclaration.streetAndNumber' } });
addEntry({ code: '050005', field: { name: 'personLodgingTheSummaryDeclaration.postCode' } });
addEntry({ code: '050006', field: { name: 'personLodgingTheSummaryDeclaration.country' } });
// Goods Shipment
addEntry({ code: '010006', field: { name: 'goodsShipment.ucr' } });
// CUSTOMS OFFICE of first Entry
addEntry({ code: '190000', field: { name: 'goodsShipment.firstEntryCustomsOffice' } });
addEntry({ code: '190001', field: { name: 'goodsShipment.firstEntryCustomsOffice.referenceNumber' } });
addEntry({ code: '190002', field: { name: 'goodsShipment.firstEntryCustomsOffice.expectedDateTimeArrival' } });
// CUSTOMS OFFICE of subsequent Entry
addEntry({
    code: '200010',
    field: {
        name: 'goodsShipment.entryCustomsOffice',
        fields: [{ name: 'referenceNumber' }],
    },
});
// Transport Information
addEntry({ code: '010009', field: { name: 'goodsShipment.consignment.borderTransportMeans.identityCrossingBorder' } });
addEntry({
    code: '010010',
    field: { name: 'goodsShipment.consignment.borderTransportMeans.conveyanceReferenceNumber' },
});
addEntry({ code: '010007', field: { name: 'goodsShipment.consignment.borderTransportMeans.transportMode' } });
addEntry({ code: '010015', field: { name: 'goodsShipment.transportMethodOfPaymentCode' } });
addEntry({
    code: '010008',
    field: { name: 'goodsShipment.consignment.borderTransportMeans.nationalityCrossingBorder' },
});
// Consignment
addEntry({ code: '010011', field: { name: 'goodsShipment.consignment.loadingPlace' } });
addEntry({ code: '010012', field: { name: 'goodsShipment.consignment.unloadingPlace' } });
// Itinerary
addEntry({
    code: '080010',
    field: {
        name: 'goodsShipment.consignment.borderTransportMeans.itinerary.countryOfRoutingCode',
        fields: [{ name: '' }],
    },
});
// Seals
addEntry({
    code: '210001',
    field: {
        name: 'goodsShipment.consignment.transportEquipment',
        fields: [
            {
                name: 'sealsIdentity',
            },
        ],
    },
    options: {
        ranges: {
            parent: { start: 2 },
            children: { start: 1, end: 1 },
        },
    },
});
// I
// Taric code
addEntry({ code: '160000', field: { name: 'commodity' } });
addEntry({ code: '160001', field: { name: 'commodity.combinedNomenclature' } });
// Goods Information
addEntry({ code: '090001', field: { name: 'ucr' } });
addEntry({ code: '090005', field: { name: 'commodity.dangerousCode' } });
addEntry({ code: '090002', field: { name: 'commodity.description' } });
addEntry({ code: '090003', field: { name: 'itemNumber' } });
addEntry({ code: '090006', field: { name: 'transportChargesMethodOfPayment' } });
addEntry({ code: '090007', field: { name: 'loadingPlace' } });
addEntry({ code: '090008', field: { name: 'unloadingPlace' } });
addEntry({ code: '090004', field: { name: 'grossMass' } });
// Parties
// Consignor
addEntry({ code: '100000', field: { name: 'consignor' } });
addEntry({ code: '100001', field: { name: 'consignor.eori' } });
addEntry({ code: '100002', field: { name: 'consignor.name' } });
addEntry({ code: '100003', field: { name: 'consignor.streetAndNumber' } });
addEntry({ code: '100005', field: { name: 'consignor.postCode' } });
addEntry({ code: '100006', field: { name: 'consignor.country' } });
// Consignee
addEntry({ code: '110000', field: { name: 'consignee' } });
addEntry({ code: '110001', field: { name: 'consignee.eori' } });
addEntry({ code: '110002', field: { name: 'consignee.name' } });
addEntry({ code: '110003', field: { name: 'consignee.streetAndNumber' } });
addEntry({ code: '110005', field: { name: 'consignee.postCode' } });
addEntry({ code: '110006', field: { name: 'consignee.country' } });
// Notify Party
addEntry({ code: '120000', field: { name: 'notifyParty' } });
addEntry({ code: '120001', field: { name: 'notifyParty.eori' } });
addEntry({ code: '120002', field: { name: 'notifyParty.name' } });
addEntry({ code: '120003', field: { name: 'notifyParty.streetAndNumber' } });
addEntry({ code: '120005', field: { name: 'notifyParty.postCode' } });
addEntry({ code: '120006', field: { name: 'notifyParty.country' } });
// Containers
addEntry({
    code: '130010',
    field: {
        name: 'transportEquipment',
        fields: [{ name: 'equipmentIdentification' }],
    },
});
// Identity Of Means Of Transport At Border
addEntry({
    code: '140010',
    field: {
        name: 'borderTransportMeans',
        fields: [{ name: 'nationalityCrossingBorder' }, { name: 'identityCrossingBorder' }],
    },
});
// Packages
addEntry({
    code: '150010',
    field: {
        name: 'goodsPackaging',
        fields: [{ name: 'marks' }, { name: 'type' }, { name: 'numberOfPackages' }, { name: 'numberOfPieces' }],
    },
});
// Additional Document
addEntry({
    code: '170010',
    field: {
        name: 'additionalDocument',
        fields: [{ name: 'code' }, { name: 'identifier' }],
    },
});
// Additional Information
addEntry({
    code: '180010',
    field: {
        name: 'additionalInformation',
        fields: [{ name: 'code' }, { name: 'identifier' }],
    },
});

function roughSizeOfObject(object: any) {
    const objectList: any = [];
    const recurse = function (value: any) {
        var bytes = 0;
        if (typeof value === 'boolean') {
            bytes = 4;
        } else if (typeof value === 'string') {
            bytes = value.length * 2;
        } else if (typeof value === 'number') {
            bytes = 8;
        } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
            objectList[objectList.length] = value;
            value.forEach((_: any, i: number) => {
                bytes += 8; // an assumed existence overhead
                bytes += recurse(value[i]);
            });
        }

        return bytes;
    };
    return recurse(object);
}
// console.log('size of EnsCodeToFieldMap: ', `${Math.ceil(roughSizeOfObject(EnsCodeToFieldMap) / 1000)}kb`);

export default EnsCodeToFieldMap;
