import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import CountryOfDestinationCodeBlock, {
    countryOfDestinationCodeBlockValidation,
} from '../blocks/CountryOfDestinationCodeBlock';
import CountryOfDispatchExportCodeBlock, {
    countryOfDispatchExportCodeBlockOptionalValidation,
    countryOfDispatchExportCodeBlockRequiredValidation,
} from '../blocks/CountryOfDispatchExportCodeBlock';
import { ExtractValidationSchema } from '../validations/validations';

export const countryOfDestinationCardValidation = {
    selfValidators: [countryOfDestinationCodeBlockValidation, countryOfDispatchExportCodeBlockRequiredValidation],
};
export type CountryOfDestinationCardValidation = ExtractValidationSchema<typeof countryOfDestinationCardValidation>;

export const countryOfDestinationCardOptionalValidation = {
    selfValidators: [countryOfDestinationCodeBlockValidation, countryOfDispatchExportCodeBlockOptionalValidation],
};

interface Props extends DeclarationFormCardProps {}

const CountryOfDestinationCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('countryOfDestination')} hidden={props.hidden}>
            <FormCardContainer>
                <CountryOfDestinationCodeBlock />
                <CountryOfDispatchExportCodeBlock
                    declarationOptions={{ UK_EXPORT_B1: { fieldOptions: { id: { required: true } } } }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CountryOfDestinationCard;
