import { TextSmall } from 'components/ui/base/typography';
import SelectTag from 'components/ui/composed/selectTag/SelectTag';
import { allRecordEquals } from 'core/utils/arrays';
import { dateStandardFormat } from 'core/utils/date';
import useCustomers from 'hooks/useCustomers';
import { FC, useEffect, useMemo, useState } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import {
    getDeclarantName,
    getDeclarationPayload,
    getExporterName,
    getImporterName,
} from '../../declarations/utils/declaration-utils';
import { DetailsLabel, DetailsRow, StatusLabel, TagsLabel } from './tables/JobsTable.styles';

interface Props {
    declarationFromJobs: Declaration[];
    job: JobResponse;
    editJob: Function;
    tags: string[];
    listOfTags: string[];
    setListOfTags: Function;
    setTags: Function;
    jobId: string;
}

export const JobsDetailsInfo: FC<Props> = ({
    declarationFromJobs,
    job,
    editJob,
    tags,
    listOfTags,
    setListOfTags,
    setTags,
    jobId,
}) => {
    const { getCustomer } = useCustomers();
    const [haulierJob, setHaulier] = useState('-');
    /*
    const declarationStatus = useMemo(() => {
        if (declarationFromJobs) {
            const d = declarationFromJobs.map((res) => res.status);
            return allRecordEquals(d) ? <DeclarationStatus declarationStatus={d[0]} /> : <DeclarationStatus multiple />;
        }
        return <></>;
    }, [declarationFromJobs]);  */

    const exporter = useMemo(() => {
        if (declarationFromJobs) {
            const d = declarationFromJobs
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getExporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
        }
        return '-';
    }, [declarationFromJobs]);

    useEffect(() => {
        const fetchCustomer = async (id: string) => {
            const response = await getCustomer(id);
            if (response?.name) {
                setHaulier(response.name);
            }
        };

        if (job?.haulierId) {
            fetchCustomer(job.haulierId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job]);

    const importer = useMemo(() => {
        if (declarationFromJobs) {
            const d = declarationFromJobs
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getImporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
        }
        return '-';
    }, [declarationFromJobs]);

    const declarant = useMemo(() => {
        if (declarationFromJobs) {
            const d = declarationFromJobs
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getDeclarantName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
        }
        return '-';
    }, [declarationFromJobs]);

    return (
        <>
            <DetailsRow>
                <StatusLabel>Jobs status: </StatusLabel>
                {job?.status ? job.status : '-'}
            </DetailsRow>
            <DetailsRow>
                <DetailsLabel>Date created: </DetailsLabel>
                {<TextSmall>{job?.createdAt ? dateStandardFormat(new Date(job?.createdAt)) : '-'}</TextSmall>}
            </DetailsRow>
            <DetailsRow>
                <DetailsLabel>Job id: </DetailsLabel>
                <TextSmall>{job?.referenceId}</TextSmall>
            </DetailsRow>
            <>
                <DetailsRow>
                    <DetailsLabel>Declarant: </DetailsLabel>
                    <TextSmall>{declarant}</TextSmall>
                </DetailsRow>
                <DetailsRow>
                    <DetailsLabel>Consignee: </DetailsLabel>
                    <TextSmall>{importer}</TextSmall>
                </DetailsRow>
                <DetailsRow>
                    <DetailsLabel>Consignor: </DetailsLabel>
                    <TextSmall>{exporter}</TextSmall>
                </DetailsRow>
                <DetailsRow>
                    <DetailsLabel>Haulier: </DetailsLabel>
                    {haulierJob ? <TextSmall>{haulierJob}</TextSmall> : <TextSmall>-</TextSmall>}
                </DetailsRow>
                <DetailsRow>
                    <TagsLabel>Tags: </TagsLabel>
                    <SelectTag
                        usedTags={tags}
                        suggestedTags={listOfTags}
                        onSuggestedTagsChange={(value: string[]) => setListOfTags(value)}
                        onUsedTagsChange={(value: string[]) => {
                            setTags(value);
                            editJob(jobId, { ...job, tags: value });
                        }}
                    />
                </DetailsRow>
            </>
        </>
    );
};

export default JobsDetailsInfo;
