import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { doClearDeclarationFormErrors, doSetDeclarationFormErrors } from 'store/declaration-form-errors/action';
import { useAppSelector } from '../config/hooks';
import { ParsedForm } from '../store/declaration-form-errors/ParsedForm';

const useDeclarationFormErrors = () => {
    const dispatch = useDispatch();
    const declarationErrors = useAppSelector((state) => state.declarationFormErrors);

    const hasFormDeclarationErrors = useMemo(
        () => !isEmpty(declarationErrors.items) || !isEmpty(declarationErrors.masterDetails),
        [declarationErrors.items, declarationErrors.masterDetails]
    );

    const setFormDeclarationErrors = (errors: ParsedForm) => {
        dispatch(doSetDeclarationFormErrors(errors));
    };

    const clearFormDeclarationErrors = () => {
        dispatch(doClearDeclarationFormErrors());
    };

    return {
        declarationErrors,
        setFormDeclarationErrors,
        clearFormDeclarationErrors,
        hasFormDeclarationErrors,
    };
};

export default useDeclarationFormErrors;
