import useSession from 'hooks/useSession';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import { LegalInfoType } from './components/legal-info';
import { ContentDiv, StyledContent, StyledLayout } from './components/LegalInfo.styles';
import TextBlock from './components/TextBlock';

const TermsAndConditions: FC = () => {
    const { t } = useTranslation('termsAndConditions');
    const { isLogged } = useSession();

    const textBlockLabels = useMemo(
        () => [
            'overview',
            'trademarks-and-copyrights',
            'certain-disclaimers',
            'confidential-information',
            'availability',
            'business-relationships',
            'linking-to-this-site',
            'translations',
            'disclaimer-of-warranty',
            'limitation-of-liability',
            'indemnification',
            'anti-corruption-laws',
            'user-submissions-intellectual-property-rights',
            'entire-agreement-choice-of-law',
            'ddispatch-contact-information',
        ],
        []
    );

    return (
        <StyledLayout>
            <Header isLogged={isLogged} type={LegalInfoType.TERMS_AND_CONDITIONS} />
            <StyledContent>
                <ContentDiv>
                    {textBlockLabels.map((label, index) => (
                        <TextBlock
                            key={`${label}-${index}`}
                            title={t(`terms-and-condition.labels.${label}`)}
                            body={t(`terms-and-condition.${label}`)}
                        />
                    ))}
                </ContentDiv>
            </StyledContent>
        </StyledLayout>
    );
};
export default TermsAndConditions;
