import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { actions } from './actionType';
import { Tooltip } from './tooltip';

export interface TooltipsState {
    isLoading: boolean;
    error?: ErrorResponse;
    h7Tooltips?: Tooltip[];
    h1Tooltips?: Tooltip[];
    ensTooltips?: Tooltip[];
    cdsTooltips?: Tooltip[];
    revenueExportTooltips?: Tooltip[];
}

export const tooltipsInitialState: Readonly<TooltipsState> = {
    isLoading: false,
    h7Tooltips: [],
    h1Tooltips: [],
    ensTooltips: [],
    cdsTooltips: [],
};

const tooltips: Reducer<TooltipsState, actions> = (state = tooltipsInitialState, action): TooltipsState => {
    switch (action.type) {
        case 'GET_H7_TOOLTIPS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_H7_TOOLTIPS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                h7Tooltips: action.payload,
            };
        case 'GET_H7_TOOLTIPS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_H1_TOOLTIPS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_H1_TOOLTIPS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                h1Tooltips: action.payload,
            };
        case 'GET_H1_TOOLTIPS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_ENS_TOOLTIPS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_ENS_TOOLTIPS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                ensTooltips: action.payload,
            };
        case 'GET_ENS_TOOLTIPS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_UK_CDS_TOOLTIPS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_UK_CDS_TOOLTIPS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                cdsTooltips: action.payload,
            };
        case 'GET_UK_CDS_TOOLTIPS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_IRELAND_EXPORT_TOOLTIPS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_IRELAND_EXPORT_TOOLTIPS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                revenueExportTooltips: action.payload,
            };
        case 'GET_IRELAND_EXPORT_TOOLTIPS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default tooltips;
