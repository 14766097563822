import { FormikProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import useHideEntities from '../../../../../../hooks/useHideEntities';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import NumberOfPackagesBlock, {
    cdsImportNumberOfPackagesBlockValidation,
    numberOfPackagesBlockValidation,
} from '../../blocks/NumberOfPackagesBlock';
import ShippingMarksBlock, {
    cdsImportShippingMarksBlockValidation,
    shippingMarksBlockValidation,
} from '../../blocks/ShippingMarksBlock';
import TypeOfPackagesBlock, {
    cdsImportTypeOfPackagesBlockValidation,
    typeOfPackagesBlockValidation,
} from '../../blocks/TypeOfPackagesBlock';
import { ValidationSchema, validators } from '../../validations/validations';

export const packagingCardValidation: ValidationSchema<any> = {
    childValidators: {
        packaging: {
            selfValidators: [
                validators.minLength(1),
                typeOfPackagesBlockValidation,
                numberOfPackagesBlockValidation,
                shippingMarksBlockValidation,
            ],
            stopAtNull: true,
        },
    },
};

export const cdsImportPackagingCardValidation: ValidationSchema<any> = {
    childValidators: {
        packaging: {
            selfValidators: [
                validators.minLength(1),
                cdsImportTypeOfPackagesBlockValidation,
                cdsImportNumberOfPackagesBlockValidation,
                cdsImportShippingMarksBlockValidation,
            ],
            stopAtNull: true,
        },
    },
};

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'quantityQuantity' | 'marksNumbersID'>;
}

const PackagingCard: FC<Props & { formik: FormikProps<any> }> = (props) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <MultipleItemsCard
            title={t('packages.title')}
            keyCard={props.keyCard}
            viewOnly={props.viewOnly}
            path="packaging"
            getFieldProps={props.getFieldProps}
            getFieldMeta={props.getFieldMeta}
            getFieldHelpers={props.getFieldHelpers}
            toggleHelp={props.toggleHelp}
            cardTotal={13}
            cardNumber={10}
            expandAll={props.expandAll}
            initialValues={{
                typeCode: '',
                quantityQuantity: '',
                marksNumbersID: '',
            }}
            list={(list) => [
                { field: t('packages.typeOfPackages'), value: list.typeCode },
                { field: t('goodsItems.numberOfPackages'), value: list.quantityQuantity },
                { field: t('packages.shippingMarks'), value: list.marksNumbersID },
            ]}
            required
            formik={props.formik}
            condensed
            hidden={isFormType([])}
            refNumber={['6/9', '6/10', '6/11']}
        >
            {(path) => (
                <FormCardContainer>
                    <TypeOfPackagesBlock path={path} />
                    <NumberOfPackagesBlock path={path} />
                    <ShippingMarksBlock path={path} />
                </FormCardContainer>
            )}
        </MultipleItemsCard>
    );
};
export default PackagingCard;
