import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const AdditionsDeductionsCard: FC<DeclarationFormCardProps & { productDisabled?: boolean }> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{ additionsDeductionsCode: '', amount: '' }}
            list={(list) => [
                {
                    field: t('code'),
                    value: list.additionsDeductionsCode,
                },
                {
                    field: t('amount'),
                    value: list.amount,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('additionsDeductions')}
            condensed
            refNumber="4.9"
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, `additionsDeductionsCode`), formProps)}
                        refNumber="4.9"
                        disabled={props.productDisabled ?? false}
                        label={t('code')}
                        selectOptions={aisCodelists?.additionsDeductionsCode}
                        tooltip={getH1TooltipsByRefNumberAndField('4.9', t('Additions Deductions - Code'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, `amount`), formProps)}
                        refNumber="4.9"
                        disabled={props.productDisabled ?? false}
                        label={t('amount')}
                        refTooltip={getH1TooltipsByRefNumberAndField('4.9', t('Additions Deductions - Amount'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AdditionsDeductionsCard;
