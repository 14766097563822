export const ENS_DataStructure = {
    goodsShipment: {
        consignee: {},
        consignor: {},
        notifyParty: {},
        entryCustomsOffice: [],
        firstEntryCustomsOffice: {},
        consignment: {
            borderTransportMeans: {
                itinerary: {
                    countryOfRoutingCode: [],
                },
            },
            transportEquipment: [],
        },
    },
    agent: {},
    carrier: {},
    issuing: {},
    personLodgingTheSummaryDeclaration: {},
};

export const ENS_ItemDataStructure = {
    additionalDocument: [],
    additionalInformation: [],
    borderTransportMeans: [],
    commodity: {},
    consignee: {},
    consignor: {},
    goodsPackaging: [],
    notifyParty: {},
    transportEquipment: [],
};
