export enum AISMessageTypes {
    H1 = 'H1',
    H7 = 'H7',
}

export enum AESMessageTypes {
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    B4 = 'B4',
    C1 = 'C1',
    C2 = 'C2',
}

export enum CDSMessageTypes {
    H1 = 'H1',
    B1 = 'B1',
}

export enum ENSMessageTypes {
    ENS = 'ENS',
}

export type MessageTypes = AISMessageTypes | AESMessageTypes | CDSMessageTypes | ENSMessageTypes;
