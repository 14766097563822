import { PaginatedParams } from 'core/http/pagination';
import { MessageTypes } from '../declarations/enums/common/template-types';
import { ArchivedTemplatesParams, getArchivedTemplates, getTemplates } from './client';

export const doListTemplates =
    (
        country: 'ireland' | 'uk',
        internalType: 'import' | 'export',
        formType: MessageTypes,
        params?: Partial<PaginatedParams>
    ) =>
    async (dispatch: Function) => {
        dispatch({ type: 'LIST_TEMPLATES_REQUEST' });
        try {
            const payload = await getTemplates(country, internalType, formType, params);
            dispatch({
                type: 'LIST_TEMPLATES_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_TEMPLATES_ERROR', error: e?.response?.data });
        }
    };

export const doListArchivedTemplates = (params: ArchivedTemplatesParams) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_TEMPLATES_REQUEST' });
    try {
        const payload = await getArchivedTemplates(params);
        dispatch({
            type: 'LIST_TEMPLATES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_TEMPLATES_ERROR', error: e?.response?.data });
    }
};
