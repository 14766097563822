import config from 'config';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { Driver, DriversQueryParams } from './driver';

export const listDrivers = (params?: Partial<DriversQueryParams>): Promise<ListPayload<Driver>> =>
    axiosClient
        .get<ListResponse<Driver>>(`${config.fleestUrl}/drivers`, { params })
        .then((response) => response.data.payload);

export const postDriver = (driver: Driver): Promise<Driver> =>
    axiosClient
        .post<SuccessResponse<Driver>>(`${config.fleestUrl}/drivers`, driver)
        .then((response) => response.data.payload);

export const getDriver = (driverID: string): Promise<Driver> =>
    axiosClient
        .get<SuccessResponse<Driver>>(`${config.fleestUrl}/drivers/${driverID}`)
        .then((response) => response.data.payload);

export const deleteDriver = (driverID: string): Promise<Driver> =>
    axiosClient
        .delete<SuccessResponse<Driver>>(`${config.fleestUrl}/drivers/${driverID}`)
        .then((response) => response.data.payload);

export const putDriver = (driver: Driver): Promise<Driver> =>
    axiosClient
        .put<SuccessResponse<Driver>>(`${config.fleestUrl}/drivers/${driver.id}`, driver)
        .then((response) => response.data.payload);
