import { Col, Row } from 'antd';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { FormikProps } from 'formik';
import moment from 'moment';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import { EDocketWeightUnit } from './EDocketFormWeight';
import EDocketFormInput from './inputs/EDocketFormInput';
import EDocketFormTimePicker from './inputs/EDocketFormTimePicker';

const { SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik>;

const EDocketFormAfterWeight = <TFormik extends FormikProps<EDocketFormType>>({ formik, t }: Props<TFormik>) => {
    return (
        <SectionMain>
            <Row gutter={[8, 25]}>
                <Col xl={5}>
                    <EDocketFormInput
                        label={t('afterWeight.netWeight.label')}
                        name="afterWeight.netWeight"
                        value={formik.values.afterWeight?.netWeight}
                        placeholder={t('afterWeight.netWeight.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={4}>
                    <FormSelect
                        required
                        label={t('afterWeight.units.label')}
                        hideKeys
                        fieldMeta={formik.getFieldMeta(`afterWeight.units`)}
                        fieldProps={formik.getFieldProps(`afterWeight.units`)}
                        fieldHelper={formik.getFieldHelpers(`afterWeight.units`)}
                        selectOptions={Object.entries(EDocketWeightUnit).map(([key, value]) => ({
                            id: key,
                            value: value.replace('_', ' '),
                        }))}
                        hideCodelistMenu
                    />
                </Col>
                <Col xl={5}>
                    <EDocketFormTimePicker
                        label={t('afterWeight.elapsedTime.label')}
                        format="HH:mm"
                        value={
                            formik.values.afterWeight?.elapsedTime
                                ? moment(formik.values.afterWeight.elapsedTime, 'HH:mm')
                                : undefined
                        }
                        onChange={(date) => {
                            formik.setFieldValue('afterWeight.elapsedTime', date?.format('HH:mm') ?? '');
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
                <Col xl={14}>
                    <EDocketFormInput
                        label={t('afterWeight.comments.label')}
                        name="afterWeight.comments"
                        value={formik.values.afterWeight?.comments || ''}
                        placeholder={t('afterWeight.comments.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
            </Row>
        </SectionMain>
    );
};

export default EDocketFormAfterWeight;
