import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { Auth, UserInfo } from './user';

export interface SessionState {
    auth?: Auth | null;
    error?: ErrorResponse | string | Object;
    isLoading: boolean;
    userInfo?: UserInfo | null;
}

export const sessionInitialState: SessionState = {
    isLoading: false,
    auth: null,
    userInfo: null,
};

const session: Reducer<SessionState> = (state: SessionState = sessionInitialState, action): SessionState => {
    switch ((action as actionTypes.TestActions).type) {
        case 'USER_SIGN_IN_REQUEST':
            return {
                ...state,
                isLoading: true,
                auth: null,
                error: undefined,
            };
        case 'USER_SIGN_IN_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                auth: action.payload,
            };
        case 'USER_SIGN_IN_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                auth: null,
            };
        case 'SIGN_UP':
            return {
                isLoading: true,
                auth: null,
                error: undefined,
                userInfo: undefined,
            };
        case 'SIGN_UP_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                userInfo: action.payload,
            };
        }
        case 'SIGN_UP_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case 'REFRESH_TOKEN_REQUEST': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'REFRESH_TOKEN_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                auth: action.payload,
            };
        }
        case 'REFRESH_TOKEN_ERROR': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
                auth: null,
            };
        }
        case 'USER_LOGOUT_REQUEST':
            return {
                ...state,
                isLoading: true,
                auth: null,
            };
        case 'USER_LOGOUT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                auth: action.payload,
            };
        case 'USER_LOGOUT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                auth: null,
            };

        case 'FORGOT_PASSWORD_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };

        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                isLoading: false,
            };
        case 'FORGOT_PASSWORD_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                auth: null,
            };
        case 'RESET_PASSWORD_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };

        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                isLoading: false,
            };
        case 'RESET_PASSWORD_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
                auth: null,
            };

        default: {
            return state;
        }
    }
};

export default session;
