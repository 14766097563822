import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import useDrivers from 'hooks/useDrivers';
import useVehicles from 'hooks/useVehicles';
import { FC, useEffect } from 'react';
import { Leg } from 'store/journeys/legs/leg';

interface Props {
    data?: Leg[];
    onDelete: (ids: string) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    loading: boolean;
}

const LegsTable: FC<Props> = ({ data, onDelete, onEdit, onDetails, loading }) => {
    const { listDrivers, drivers } = useDrivers();
    const { listVehicles, vehicles } = useVehicles();

    useEffect(() => {
        listVehicles();
        listDrivers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: 'Leg Id',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            onCell: (record: Leg, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Leg, index: number) =>
                record.id ? <span>{record.id}</span> : <span>-</span>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            render: (text, record: Leg) => (record.description ? record.description : '-'),
        },
        {
            title: 'Start date',
            dataIndex: 'startDateTime',
            key: 'startDateTime',
            align: 'left',
            render: (text, record: Leg) => {
                if (record.startDateTime) {
                    const date = new Date(record.startDateTime);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                } else return '-';
            },
        },
        {
            title: 'End time',
            dataIndex: 'endDateTime',
            key: 'endDateTime',
            align: 'left',
            onCell: (record: Leg, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text, record: Leg) => {
                if (record.endDateTime) {
                    const date = new Date(record.endDateTime);
                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                } else return '-';
            },
        },
        {
            title: 'Driver',
            dataIndex: 'driverId',
            key: 'driverId',
            align: 'left',
            onCell: (record: Leg, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Leg, index: number) =>
                record.driverId ? (
                    <span>{drivers?.list?.find((e) => e.id === record.driverId)?.fullName || '-'}</span>
                ) : (
                    <span>-</span>
                ),
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicleId',
            key: 'vehicleId',
            align: 'left',
            onCell: (record: Leg, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Leg, index: number) =>
                record.vehicleId ? (
                    <span>{vehicles?.list?.find((obj) => obj.id === record.vehicleId)?.model || '-'}</span>
                ) : (
                    <span>-</span>
                ),
        },
        {
            title: 'Commands',
            dataIndex: 'Commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Leg, index: number) => {
                return (
                    <>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onEdit(record.id)}>
                            <EditOutlined />
                        </span>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onDelete(record.id)}>
                            <DeleteOutlined />
                        </span>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Table rowKey="id" style={{ marginTop: '2.5rem' }} columns={columns} dataSource={data} loading={loading} />
        </>
    );
};
export default LegsTable;
