import { FormikProps } from 'formik';
import { merge } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import AdditionalProcedureBlock, { additionalProcedureBlockValidation } from '../../blocks/AdditionalProcedureBlock';
import ProcedureBlock, { procedureBlockValidation } from '../../blocks/ProcedureBlock';

export const procedureCardValidation = {
    selfValidators: [merge(procedureBlockValidation, additionalProcedureBlockValidation)],
};

interface Props extends DeclarationFormCardProps {
    formik: FormikProps<any>;
}

const ProcedureCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('procedure')}>
            <FormCardContainer>
                <ProcedureBlock />
                <AdditionalProcedureBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ProcedureCard;
