import { Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import styled from 'styled-components';
import { colors } from 'theme';
import PackageIcon from './icons/PackageIcon';

export const StyledButton = styled(Button)`
    margin-right: 1.2rem;
`;

export const StyledPackageIcon = styled(PackageIcon)`
    margin-left: 0.5rem;
`;

export const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TooltipDiv = styled(FlexDiv)`
    color: ${colors.black};
`;

export const SpanEllipsis = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SpanEllipsisMR5 = styled(SpanEllipsis)`
    margin-right: 0.5rem;
`;

export const SpanMR5 = styled.span`
    margin-right: 0.5rem;
`;

export const SpanMR5Icon = styled.span`
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
`;

export const StyledRow = styled(Row)`
    overflow: hidden;
    text-overflow: ellipsis;
`;
