import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';

const BorderTransportMeansCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumber, getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    return (
        <NewFormCard title={t('transportInformation')}>
            <FormCardContainer>
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.consignment.borderTransportMeans.identityCrossingBorder`, props)}
                    refNumber="21.1"
                    tooltip="Click for more information"
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.identity')}
                    condensed
                />

                <FormInput
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(
                        `goodsShipment.consignment.borderTransportMeans.conveyanceReferenceNumber`,
                        props
                    )}
                    label={t('conveyanceReferenceNumber')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />

                <DeclarationSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.consignment.borderTransportMeans.transportMode`, props)}
                    refNumber="26"
                    tooltip={getIrelandExportTooltipsByRefNumber('26')}
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.modeOfTransport')}
                    selectOptions={[
                        { id: '1', value: 'Maritime transport' },
                        { id: '2', value: 'Rail transport' },
                        { id: '3', value: 'Road transport' },
                        { id: '4', value: 'Air transport' },
                        { id: '5', value: 'Mail (Active mode of transport unknown)' },
                        { id: '7', value: 'Fixed transport installations' },
                        { id: '8', value: 'Inland waterway transport' },
                        { id: '9', value: 'Mode unknown (i.e. own propulsion)' },
                    ]}
                    condensed
                    codeValidation={[validators.maxLength(2)]}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.transportMethodOfPaymentCode`, props)}
                    label={t('transportChargesMethodOfPayment')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Transport Charges - Method of Payment')}
                    refClicked={handleToggleHelp}
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Payment in cash',
                        },
                        {
                            id: 'B',
                            value: 'Payment by credit card',
                        },
                        {
                            id: 'C',
                            value: 'Payment by cheque',
                        },
                        {
                            id: 'D',
                            value: 'Other (e.g. direct debit to cash account)',
                        },
                        {
                            id: 'H',
                            value: 'Electronic credit transfer',
                        },
                        {
                            id: 'Y',
                            value: 'Account holder with carrier',
                        },
                        {
                            id: 'Z',
                            value: 'Not pre-paid',
                        },
                    ]}
                    condensed
                    codeValidation={[validators.exact(1)]}
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `goodsShipment.consignment.borderTransportMeans.nationalityCrossingBorder`,
                        props
                    )}
                    refNumber="21.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('21.2')}
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.nationality')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default BorderTransportMeansCard;
