import { Layout } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const FormContent = styled(Layout.Content)<{ hasLeftNavigation?: boolean }>`
    background: ${colors.white};
    padding: 0;
    overflow: auto;
    height: calc(100vh - 27.3rem);
    padding-right: 4px;
`;
