import { FormikProps } from 'formik';
import { get, some } from 'lodash';
import { useCallback, useEffect } from 'react';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { FormAction } from 'views/declarations/utils/form-utils';

interface Props {
    setFormAction: (formAction: FormAction) => void;
    declarationStatus: DeclarationStatus | undefined;
    productsFormik: FormikProps<any>;
    fieldsToAutosaveOn: string[] | undefined;
}

const useAutoSaveProduct = ({ declarationStatus, productsFormik, setFormAction, fieldsToAutosaveOn }: Props) => {
    const handleDifferencesInFormikValues = useCallback(
        (initialValues: FormikProps<any>, values: FormikProps<any>, fieldsPaths: string[]) =>
            some(fieldsPaths, (fieldPath) => {
                const initialValue = get(initialValues, fieldPath);
                const value = get(values, fieldPath);

                if (!initialValue || !value) return;

                if (Array.isArray(initialValue) && Array.isArray(value)) {
                    return initialValue.length !== value.length;
                }
                if (initialValue !== value) {
                    setFormAction(FormAction.PRODUCT);
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        const hasFieldsToAutosaveOn = fieldsToAutosaveOn !== undefined && fieldsToAutosaveOn.length !== 0;
        if (!hasFieldsToAutosaveOn) return;

        const inputFields = fieldsToAutosaveOn?.map(
            (field) => document.querySelector(`[name='${field}']`) as HTMLInputElement | undefined
        );

        const handleBlur = () =>
            handleDifferencesInFormikValues(productsFormik.initialValues, productsFormik.values, fieldsToAutosaveOn);

        const handleCleanup = () => inputFields?.forEach((input) => input?.removeEventListener('blur', handleBlur));

        const isDeclarationStatusGoodForAutoSave =
            declarationStatus === DeclarationStatus.DRAFT ||
            declarationStatus === DeclarationStatus.REJECTED ||
            declarationStatus === DeclarationStatus.INVALID;

        const hasFormikInitialValues =
            productsFormik?.initialValues && Object.keys(productsFormik?.initialValues).length > 0;

        if (!(hasFormikInitialValues && isDeclarationStatusGoodForAutoSave)) return handleCleanup;

        inputFields?.forEach((input) => input?.addEventListener('blur', handleBlur));

        return handleCleanup;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsFormik.initialValues, productsFormik.values]);
};

export default useAutoSaveProduct;
