import styled from 'styled-components';

export const CardContent = styled.div`
    padding: 0 0 0 30px;
`;

export const CardTitle = styled.span`
    font-weight: bold;
    white-space: nowrap;
    font-size: 2rem;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

export const HeaderTitle = styled.div`
    display: flex;
    gap: 20px;
`;

export const SuggestedTaricContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
`;

export const SuggestedTaricValueWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 65px;
`;
