import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { doGetCdsTooltips, doGetH1Tooltips, doGetH7Tooltips, doGetIrelandExportTooltips } from 'store/tooltips/action';
import { Tooltip } from 'store/tooltips/tooltip';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useTooltips = (load = false) => {
    const { t } = useTranslation('common');
    const h7Tooltips = useAppSelector((state) => state.tooltips.h7Tooltips);
    const h1Tooltips = useAppSelector((state) => state.tooltips.h1Tooltips);
    const revenueExportTooltips = useAppSelector((state) => state.tooltips.revenueExportTooltips);
    const cdsTooltips = useAppSelector((state) => state.tooltips.cdsTooltips);
    const ensTooltips = useAppSelector((state) => state.tooltips.ensTooltips);
    const error = useAppSelector((state) => state.tooltips.error);
    const isLoading = useAppSelector((state) => state.tooltips.isLoading);

    const dispatch = useAppDispatch();

    const getH7Tooltips = useCallback(() => dispatch(doGetH7Tooltips()), [dispatch]);

    const getH1Tooltips = useCallback(() => dispatch(doGetH1Tooltips()), [dispatch]);

    const getCdsTooltips = useCallback(() => dispatch(doGetCdsTooltips()), [dispatch]);

    const getRevenueExportTooltips = useCallback(() => dispatch(doGetIrelandExportTooltips()), [dispatch]);

    const handleNullValue = useCallback(
        (value?: string): string => {
            return !!value ? value : t('defaultTooltip');
        },
        [t]
    );

    const getDescriptionByRefNumber = useCallback((refNumber: string, tooltips?: Tooltip[]) => {
        const description = tooltips?.find((t) => t.fieldNumber === refNumber)?.description;
        return description;
    }, []);

    const getDescriptionByField = useCallback(
        (field: string, tooltips?: Tooltip[]) => {
            const description = tooltips?.find(
                (t) => t.field.toLowerCase().trim() === field.toLowerCase().trim()
            )?.description;
            return handleNullValue(description);
        },
        [handleNullValue]
    );

    const getDescriptionByRefNumberAndField = useCallback(
        (refNumber: string, field: string, tooltips?: Tooltip[]): string | undefined => {
            const description = tooltips?.find(
                (t) =>
                    t.fieldNumber === refNumber &&
                    t.field.toLocaleLowerCase().trim().includes(field.toLocaleLowerCase().trim())
            )?.description;
            return description;
        },
        []
    );

    const getH7TooltipsByRefNumber = useCallback(
        (refNumber: string): string | undefined => getDescriptionByRefNumber(refNumber, h7Tooltips),
        [h7Tooltips, getDescriptionByRefNumber]
    );

    const getH7TooltipsByRefNumberAndField = useCallback(
        (refNumber: string, field: string): string | undefined =>
            getDescriptionByRefNumberAndField(refNumber, field, h7Tooltips),
        [getDescriptionByRefNumberAndField, h7Tooltips]
    );

    const getH7TooltipsByField = useCallback(
        (field: string): string => getDescriptionByField(field, h7Tooltips),
        [getDescriptionByField, h7Tooltips]
    );

    const getH1TooltipsByRefNumber = useCallback(
        (refNumber: string): string | undefined => getDescriptionByRefNumber(refNumber, h1Tooltips),
        [h1Tooltips, getDescriptionByRefNumber]
    );

    const getH1TooltipsByRefNumberAndField = useCallback(
        (refNumber: string, field: string): string | undefined =>
            getDescriptionByRefNumberAndField(refNumber, field, h1Tooltips),
        [getDescriptionByRefNumberAndField, h1Tooltips]
    );

    const getH1TooltipsByField = useCallback(
        (field: string): string => getDescriptionByField(field, h1Tooltips),
        [getDescriptionByField, h1Tooltips]
    );

    const getEnsTooltipsByRefNumber = useCallback(
        (refNumber: string): string | undefined => getDescriptionByRefNumber(refNumber, ensTooltips),
        [ensTooltips, getDescriptionByRefNumber]
    );

    const getEnsTooltipsByRefNumberAndField = useCallback(
        (refNumber: string, field: string): string | undefined =>
            getDescriptionByRefNumberAndField(refNumber, field, ensTooltips),
        [getDescriptionByRefNumberAndField, ensTooltips]
    );

    const getEnsTooltipsByField = useCallback(
        (field: string): string => getDescriptionByField(field, ensTooltips),
        [getDescriptionByField, ensTooltips]
    );

    const getCdsTooltipsByRefNumber = useCallback(
        (refNumber: string): string | undefined => getDescriptionByRefNumber(refNumber, cdsTooltips),
        [cdsTooltips, getDescriptionByRefNumber]
    );

    const getCdsTooltipsByRefNumberAndField = useCallback(
        (refNumber: string, field: string): string | undefined =>
            getDescriptionByRefNumberAndField(refNumber, field, cdsTooltips),
        [getDescriptionByRefNumberAndField, cdsTooltips]
    );

    const getCdsTooltipsByField = useCallback(
        (field: string): string => getDescriptionByField(field, cdsTooltips),
        [getDescriptionByField, cdsTooltips]
    );

    const getIrelandExportTooltipsByRefNumber = useCallback(
        (refNumber: string): string | undefined => getDescriptionByRefNumber(refNumber, revenueExportTooltips),
        [revenueExportTooltips, getDescriptionByRefNumber]
    );

    const getIrelandExportTooltipsByRefNumberAndField = useCallback(
        (refNumber: string, field: string): string | undefined =>
            getDescriptionByRefNumberAndField(refNumber, field, revenueExportTooltips),
        [getDescriptionByRefNumberAndField, revenueExportTooltips]
    );

    const getIrelandExportTooltipsByField = useCallback(
        (field: string): string => getDescriptionByField(field, revenueExportTooltips),
        [getDescriptionByField, revenueExportTooltips]
    );

    useEffect(() => {
        if (load) {
            getH7Tooltips();
            getH1Tooltips();
            getCdsTooltips();
            getRevenueExportTooltips();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        h7Tooltips,
        h1Tooltips,
        ensTooltips,
        cdsTooltips,
        revenueExportTooltips,
        getH7TooltipsByRefNumber,
        getH7TooltipsByRefNumberAndField,
        getH7TooltipsByField,
        getH1TooltipsByRefNumber,
        getH1TooltipsByRefNumberAndField,
        getH1TooltipsByField,
        getEnsTooltipsByRefNumber,
        getEnsTooltipsByRefNumberAndField,
        getEnsTooltipsByField,
        getCdsTooltipsByRefNumber,
        getCdsTooltipsByRefNumberAndField,
        getCdsTooltipsByField,
        getIrelandExportTooltipsByRefNumber,
        getIrelandExportTooltipsByRefNumberAndField,
        getIrelandExportTooltipsByField,
    };
};

export default useTooltips;
