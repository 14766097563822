import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import useDeclarations from './useDeclarations';

const REFRESH_DELAY_TIME = 60000;

const useDeclarationAutoRefresh = () => {
    const { declaration, getDeclaration } = useDeclarations();
    const { declarationId } = useParams<{ declarationId: string | undefined }>();

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                declarationId &&
                declaration?.status !== DeclarationStatus.DRAFT &&
                declaration?.status !== DeclarationStatus.INVALID &&
                declaration?.status !== DeclarationStatus.REJECTED &&
                declaration?.status !== DeclarationStatus.RELEASED &&
                declaration?.status !== DeclarationStatus.NOT_RELEASED
            ) {
                getDeclaration(declarationId);
            }
        }, REFRESH_DELAY_TIME);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration?.status]);
};

export default useDeclarationAutoRefresh;
