import { Card, Tag, Tooltip, Typography } from 'antd';
import { HTMLAttributes, ReactElement, ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SearchCustomerButton from '../search-customer/SearchCustomerButton';
import TemplateCheckbox from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLocation, useOutletContext } from 'react-router-dom';
import { cloneDeep, kebabCase } from 'lodash';
import useDeclarations from 'hooks/useDeclarations';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { CardContext, FieldData } from 'components/ui/composed/template/CardContext';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';

const Meta = styled(Card.Meta)`
    .ant-card-meta-title {
        font-weight: 600;
        font-size: 2rem;
        color: #383752;
    }
`;

const SCard = styled(Card)<{ template?: boolean; error?: boolean }>`
    --color: ${({ error }) => (error ? 'red' : '#cecece')};
    border-color: var(--color);
    box-shadow: ${({ template }) => (template ? 'none' : '0px 2px 7px 0px #0900ff26')};
    border-radius: ${({ template }) => (template ? 'none' : '8px 0px 0px 8px')};
    border: ${({ template }) => (template ? 'none' : undefined)};
    border-bottom: ${({ template }) => (template ? '1px solid var(--color)' : undefined)};

    margin-bottom: ${({ template }) => (template ? '0' : '2rem')};

    .ant-card-body {
        padding: 2rem 2.5rem;
    }
`;

const LFormCardContainer = styled.div<{
    template?: boolean;
    oneColumn?: boolean;
    normal?: boolean;
    viewOnly?: boolean;
    invoice?: boolean;
}>`
    display: grid;
    grid-template-columns: ${({ oneColumn, template, normal }) =>
        template && !normal
            ? 'minmax(0, 1fr) 3.2rem 3.2rem minmax(0, 1fr)'
            : oneColumn
            ? 'repeat(2, minmax(0, 1fr))'
            : 'repeat(4, minmax(0, 1fr))'};
    gap: ${({ oneColumn, template, viewOnly }) =>
        template ? (viewOnly ? '1rem 2rem' : '0.5rem 2rem') : oneColumn ? '0.5rem 1rem' : '0.5rem 5rem'};

    @media (max-width: 1800px) {
        grid-template-columns: ${({ template, invoice }) =>
            template || invoice ? undefined : 'repeat(2, minmax(0, 1fr))'};
        gap: ${({ template }) => (template ? undefined : '0.5rem 1rem')};
    }

    margin-bottom: ${({ invoice }) => invoice && '1rem'};
`;

const TagsContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-left: 2rem;
    align-items: center;
    span {
        margin: 0;
    }
`;

interface FormCardContainerProps extends HTMLAttributes<HTMLDivElement> {
    oneColumn?: boolean;
    normal?: boolean;
    invoice?: boolean;
}
const FormCardContainer = ({ oneColumn, normal, invoice, ...divProps }: FormCardContainerProps) => {
    const { template, isViewOnly } = useTemplateContext();

    return (
        <LFormCardContainer
            {...divProps}
            viewOnly={isViewOnly}
            template={template}
            oneColumn={oneColumn}
            normal={normal}
            invoice={invoice}
        />
    );
};

interface Props {
    children: ReactNode;
    title: string;
    showCustomers?: () => void;
    disableTemplateCheckbox?: boolean;
    mic?: { path: string; required?: boolean };
    tags?: ReactNode;
    refNumber?: string | string[];
    special?: {
        partyPath?: string;
    };
    cardButtons?: ReactNode[] | false | null | undefined;
    error?: string;
    hidden?: boolean;
}

const NewFormCard = ({
    children,
    title,
    showCustomers,
    disableTemplateCheckbox,
    mic,
    tags,
    refNumber: refNumberProp,
    special,
    cardButtons,
    error,
    hidden,
}: Props): ReactElement | null => {
    const { form, registerCard } = useDeclarationContext();
    const { form: templateForm, template, templateFormik, isViewOnly: isTemplateViewOnly } = useTemplateContext();
    const { declarationTemplate: productTemplateDeclarationTemplate, inProductTemplate } = useProductTemplateContext();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const inProductForm = location.pathname.includes('products');
    const [fields, setFields] = useState<FieldData[]>([]);
    const { declarationTemplate } = useDeclarations();
    const { declaration } = useDeclarations();

    const templateData = useMemo(
        () => productTemplateDeclarationTemplate?.template || declarationTemplate?.template,
        [productTemplateDeclarationTemplate, declarationTemplate?.template]
    );

    const registerField = (fieldData: FieldData) => {
        setFields((prev) => {
            const index = prev.findIndex((el) => fieldData.path === el.path);
            if (index === -1) prev.push(fieldData);
            else prev[index] = fieldData;
            return [...prev];
        });
    };
    const registerSection = (sectionFields: FieldData[]) => {
        for (const sectionField of sectionFields) {
            registerField(sectionField);
        }
    };

    const cardHidden = useMemo(() => {
        if (template || !fields.length) return false;
        if (!fields.length) return false; // The card must render once in order to register the fields
        if (fields.every((field) => field.hidden)) return true;
        const _form = inProductTemplate ? 'product' : form;
        if (!_form) return false;
        const meta = cloneDeep(templateData?.[_form].meta);
        if (!meta) return false;
        if (mic && Object.keys(meta).includes(mic.path))
            return !meta[mic.path]?.isEditable && !meta[mic.path]?.isViewable;
        return fields.every(
            (field) => meta?.[field.path] && !meta[field.path]?.isEditable && !meta[field.path]?.isViewable
        );
    }, [template, inProductTemplate, form, templateData, mic, fields]);

    const cardContentHidden = useMemo(() => template && mic, [mic, template]);

    useEffect(() => {
        registerCard?.({
            title,
            visible: !(cardHidden || hidden),
            form: inProductForm ? 'product' : 'master',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardHidden]);

    const cardCheckboxChecked = useMemo(() => {
        if (!template || mic || !templateForm) return;
        const meta = cloneDeep(templateFormik?.values[templateForm].meta);
        let _fields = fields;
        if (special?.partyPath) _fields = fields.filter((field) => field.path.includes(special.partyPath!));
        return _fields.some(
            (field) =>
                !field.required &&
                (meta?.[field.path]?.isEditable || meta?.[field.path]?.isViewable || !meta?.[field.path])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, templateFormik?.values, special?.partyPath]);

    const viewOnly = useMemo(
        () => (inViewOnly && (!outletContext?.amendment ?? true)) || declaration?.archived,
        [declaration?.archived, inViewOnly, outletContext?.amendment]
    );

    const defaultTags = useMemo(() => {
        const tags = [];
        const tagsComponents = {
            mic: (
                <Tooltip overlay="Multiple Items Card">
                    <Tag color="blue">MIC</Tag>
                </Tooltip>
            ),
            required: <Tag color="red">Required</Tag>,
            ref: (refNum?: string) => <Tag color="rgba(56, 55, 82, 0.2)">{refNum ?? ''}</Tag>,
        };

        if (mic) {
            tags.push(tagsComponents.mic);
            if (mic.required) {
                tags.push(tagsComponents.required);
            }
        }

        if (Array.isArray(refNumberProp)) {
            refNumberProp.forEach((refNumFromArray) => tags.push(tagsComponents.ref(refNumFromArray)));
        } else if (typeof refNumberProp === 'string') {
            tags.push(tagsComponents.ref(refNumberProp));
        }

        return tags;
    }, [mic, refNumberProp]);

    const cardCheckboxDisabled = useMemo(() => {
        const micRequired = mic && mic.required;
        const micAllFieldsRequired = !mic && fields.every((field) => field.required);
        return micRequired || disableTemplateCheckbox || micAllFieldsRequired;
    }, [disableTemplateCheckbox, fields, mic]);

    const _cardCheckboxChecked = useMemo(() => {
        if (!templateForm) return false;
        if (mic) {
            let micMeta = cloneDeep(templateFormik?.values[templateForm].meta[mic.path]);
            return !micMeta || (micMeta?.isEditable && micMeta?.isViewable);
        }
        return cardCheckboxChecked;
    }, [cardCheckboxChecked, mic, templateForm, templateFormik]);

    const handleCardCheckboxCheck = useCallback(
        (e: CheckboxChangeEvent) => {
            if (!templateForm) return;
            if (mic) {
                templateFormik?.setFieldValue(`${templateForm}.meta.['${mic.path}']`, {
                    isEditable: e.target.checked,
                    isViewable: e.target.checked,
                });
                return;
            }
            let meta = cloneDeep(templateFormik?.values[templateForm].meta);
            if (!meta) return;
            fields.forEach((field) => {
                if (special?.partyPath && !field.path.includes(special.partyPath)) return;
                if (!field.required) meta![field.path] = { isEditable: e.target.checked, isViewable: false };
            });
            templateFormik?.setFieldValue(`${templateForm}.meta`, { ...meta });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, mic, special?.partyPath, templateForm, templateFormik?.values]
    );

    if (cardHidden || hidden) return null;

    return (
        <SCard id={`${kebabCase(title)}-form-card`} template={template} error={Boolean(error)}>
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <Meta title={title} />
                <TagsContainer>
                    {defaultTags}
                    {tags}
                </TagsContainer>
                <div style={{ display: 'flex', gap: '2rem', marginLeft: 'auto' }}>
                    {!(viewOnly || isTemplateViewOnly) && showCustomers && (
                        <SearchCustomerButton condensed onClick={() => showCustomers?.()} />
                    )}
                    {cardButtons}
                    {template && !isTemplateViewOnly && (
                        <TemplateCheckbox
                            disabled={cardCheckboxDisabled}
                            size="medium"
                            onChange={handleCardCheckboxCheck}
                            checked={_cardCheckboxChecked}
                        />
                    )}
                </div>
            </div>
            {!cardContentHidden && (
                <CardContext.Provider value={{ registerField, registerSection }}>{children}</CardContext.Provider>
            )}
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
        </SCard>
    );
};

export default memo(NewFormCard);
export { FormCardContainer };
