import { FormikProps } from 'formik';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormAction } from 'views/declarations/utils/form-utils';

interface Props {
    saveDeclaration: () => Promise<any>;
    submitDeclaration: Function;
    saveProduct: Function;
    validateForm: () => Promise<any>;
    productsFormik: FormikProps<any>;
    setIsSaving: (isSaving: boolean) => void;
    refetchDeclaration: () => Promise<void>;
}

const useHandleFormAction = ({
    saveDeclaration: saveForm,
    saveProduct,
    submitDeclaration,
    validateForm,
    productsFormik,
    setIsSaving,
    refetchDeclaration,
}: Props) => {
    const { t } = useTranslation('common');
    const { declarationValidation, setFormAction } = useDeclarationValidation({ formAction: null });
    const { hasFormDeclarationErrors } = useDeclarationFormErrors();
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();

    const validateAndSubmitDeclarationForm = useCallback(async () => {
        const isFormValid = await validateForm();

        if (!isFormValid) return;

        return submitDeclaration();
    }, [submitDeclaration, validateForm]);

    useEffect(() => {
        if (declarationValidation.formAction === null) return;

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: t('savingDeclaration') } });

        setIsSaving(true);

        let formActionFunction = null;

        switch (declarationValidation.formAction) {
            case FormAction.PRODUCT:
                formActionFunction = async () => {
                    await saveProduct(productsFormik.values);
                    if (hasFormDeclarationErrors) {
                        await refetchDeclaration();
                        return validateForm();
                    }
                };
                break;
            case FormAction.SUBMIT:
                formActionFunction = validateAndSubmitDeclarationForm;
                break;
            case FormAction.DRAFT:
                formActionFunction = saveForm;
                break;
        }

        formActionFunction().then(() => {
            hideGlobalOverlay();
            setIsSaving(false);

            setFormAction(null);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationValidation.formAction]);
};

export default useHandleFormAction;
