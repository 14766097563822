import { Col, Image, Row, Modal, Button, Space } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { InputLabel, LabelDiv } from 'components/ui/composed/formInput/FormInput.styles';
import { FormikProps } from 'formik';
import { useMemo, useState } from 'react';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/lib/upload';
import { PlusOutlined, ReloadOutlined, CameraOutlined, InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { EDocketFormStyledComponents, EDocketFormWeightStyledComponents } from './EDocketForm.styles';
import moment from 'moment';
import EDocketFormInput from './inputs/EDocketFormInput';
import EDocketFormTimePicker from './inputs/EDocketFormTimePicker';
import EDocketFormDatePicker from './inputs/EDocketFormDatePicker';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';

const { DraggerImageContainer, ChooseImageWrapper, ModalDraggerContainer, ModalFooter } =
    EDocketFormWeightStyledComponents;
const { SectionTitle, SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik> & {
    weight: 'first' | 'second';
};

export enum EDocketWeightUnit {
    KILOGRAM = 'KG',
    GRAM = 'G',
    POUNDS = 'LBS',
}

const EDocketFormWeight = <TFormik extends FormikProps<EDocketFormType>>({ formik, weight, t }: Props<TFormik>) => {
    const weightObjectKey = useMemo(() => `${weight}Weight` as 'firstWeight' | 'secondWeight', [weight]);

    const [isChooseImageModalVisible, setIsChooseImageModalVisible] = useState<boolean>(false);
    const [weightReadingPreview, setWeightReadingPreview] = useState<string | null>(null);

    const handleChooseImage = () => {
        setIsChooseImageModalVisible(true);
    };

    const handleRemoveImage = () => {
        setWeightReadingPreview(null);
        formik.setFieldValue(`${weightObjectKey}.image`, null);
    };

    const handleSetWeightReadingImage = async (options: UploadRequestOption<any>) => {
        const { file } = options;

        if (file) {
            formik.setFieldValue(`${weightObjectKey}.image`, file);

            if (file === typeof 'string') setWeightReadingPreview(file);
            else setWeightReadingPreview(URL.createObjectURL(file as Blob | RcFile));

            setIsChooseImageModalVisible(false);
        }
    };

    return (
        <SectionMain>
            <SectionTitle>{t(weightObjectKey)}</SectionTitle>

            <Row gutter={[8, 25]}>
                <Col xl={5}>
                    {/* TODO Change to select */}
                    <EDocketFormInput
                        label={t('weight.date.label')}
                        name={`${weightObjectKey}.goodsInOut`}
                        value={formik.values[weightObjectKey]?.goodsInOut}
                        placeholder={t('weight.goodsInOut.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={5}>
                    <EDocketFormDatePicker
                        label={t('weight.date.label')}
                        value={
                            formik.values[weightObjectKey]?.date
                                ? moment(formik.values[weightObjectKey]?.date)
                                : undefined
                        }
                        onChange={(date) => formik.setFieldValue(`${weightObjectKey}.date`, date?.toISOString() ?? '')}
                    />
                </Col>
                <Col xl={5}>
                    <EDocketFormTimePicker
                        label={t('weight.time.label')}
                        format="HH:mm"
                        value={
                            formik.values[weightObjectKey]?.time
                                ? moment(formik.values[weightObjectKey]?.time, 'HH:mm')
                                : undefined
                        }
                        onChange={(date) => {
                            formik.setFieldValue(`${weightObjectKey}.time`, date?.format('HH:mm') ?? '');
                        }}
                    />
                </Col>
                <Col xl={5}>
                    <EDocketFormInput
                        label={t('weight.weight.label')}
                        name={`${weightObjectKey}.weight`}
                        value={formik.values[weightObjectKey]?.weight}
                        placeholder={t('weight.weight.placeholder')}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xl={4}>
                    <FormSelect
                        required
                        label={t('weight.units.label')}
                        hideKeys
                        fieldMeta={formik.getFieldMeta(`${weightObjectKey}.units`)}
                        fieldProps={formik.getFieldProps(`${weightObjectKey}.units`)}
                        fieldHelper={formik.getFieldHelpers(`${weightObjectKey}.units`)}
                        selectOptions={Object.entries(EDocketWeightUnit).map(([key, value]) => ({
                            id: key,
                            value: value.replace('_', ' '),
                        }))}
                    />
                </Col>
                <Col>
                    <LabelDiv>
                        <InputLabel>{t('weight.image.label')}</InputLabel>
                    </LabelDiv>
                    <DraggerImageContainer>
                        <Space size={5} direction="vertical">
                            <ChooseImageWrapper onClick={handleChooseImage}>
                                {formik.values[weightObjectKey]?.image ? <ReloadOutlined /> : <PlusOutlined />}
                                <span className="ant-upload-drag-icon">
                                    {formik.values[weightObjectKey]?.image
                                        ? t('weight.image.reupload')
                                        : t('weight.image.upload')}
                                </span>
                            </ChooseImageWrapper>
                            {formik.values[weightObjectKey]?.image && (
                                <ChooseImageWrapper onClick={handleRemoveImage}>
                                    <CloseOutlined />
                                    <span className="ant-upload-drag-icon" style={{ textAlign: 'center' }}>
                                        {t('weight.image.remove')}
                                    </span>
                                </ChooseImageWrapper>
                            )}
                        </Space>
                        {(weightReadingPreview ?? formik.values[weightObjectKey]?.image) && (
                            <Image
                                src={(weightReadingPreview ?? formik.values[weightObjectKey]?.image) as string}
                                width={200}
                            />
                        )}
                    </DraggerImageContainer>
                </Col>
            </Row>
            <Modal
                visible={isChooseImageModalVisible}
                onCancel={() => setIsChooseImageModalVisible(false)}
                footer={null}
            >
                <ModalDraggerContainer>
                    <Dragger
                        id="file-upl"
                        maxCount={1}
                        customRequest={handleSetWeightReadingImage}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                            <InboxOutlined style={{ color: 'DimGray' }} />
                        </p>
                        <p className="ant-upload-text">{t('weight.image.modal.choose.text')}</p>
                        <p className="ant-upload-hint">{t('weight.image.modal.choose.hint')}</p>
                    </Dragger>
                    <Dragger
                        id="file-upl"
                        maxCount={1}
                        customRequest={handleSetWeightReadingImage}
                        showUploadList={false}
                        capture="environment"
                    >
                        <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                            <CameraOutlined style={{ color: 'DimGray', fontSize: 40 }} />
                        </p>
                        <p className="ant-upload-text">{t('weight.image.modal.capture.text')}</p>
                        <p className="ant-upload-hint">{t('weight.image.modal.capture.hint')}</p>
                    </Dragger>
                </ModalDraggerContainer>
                <ModalFooter>
                    <Button type="primary" onClick={() => setIsChooseImageModalVisible(false)}>
                        {t('weight.image.modal.close')}
                    </Button>
                </ModalFooter>
            </Modal>
        </SectionMain>
    );
};

export default EDocketFormWeight;
