import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import useCodelists from 'hooks/useCodelists';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'identificationTypeCode' | 'id';

export const identityOfActiveMeansOfTransportCrossingTheBorderBlockValidation: {
    childValidators: {
        borderTransportMeans: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        borderTransportMeans: {
            childValidators: {
                identificationTypeCode: [validators.number(), validators.exact(2)],
                id: [validators.maxLength(35)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const IdentityOfActiveMeansOfTransportCrossingTheBorderBlock = ({
    path = 'borderTransportMeans',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '7/14', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="identificationTypeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('transportIdentityMeansType')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.transportMeansIdentificationTypes}
                        condensed
                        codeValidation={[validators.number(), validators.exact(2)]}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={35}
                        label={t('transportIdentityMeansId')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default IdentityOfActiveMeansOfTransportCrossingTheBorderBlock;
