import { CloseCircleFilled, FileOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import Divider from 'components/ui/base/divider';
import { H5, H6 } from 'components/ui/base/typography';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationProductTaxes from 'hooks/useDeclarationProductTaxes';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    generateIrelandClearanceDraft,
    generateIrelandSadDraft,
    getIrelandClearanceDraft,
    getIrelandSadDraft,
} from 'store/declarations/client';
import { taxPaymentMethodLabels } from 'store/declarations/enums/ireland/tax-payment-method';
import useFormUtils from '../../../hooks/useFormUtils';
import CommodityCodeTable from './CommodityCodeTable';
import {
    Alert,
    CustomModal,
    ErrorGenerateDocument,
    HyperLinkDiv,
    LoadingDocuments,
    PreviewSadText,
    TotalInformationLabel,
    TotalInformationParam,
    TotalInformationSection,
    TotalInformationTitle,
} from './ModalPreviewSadAndClearanceSlip.styles';

interface Props {
    isOpen: boolean;
    close: Function;
    declarationId: string;
    confirm: Function;
    onClickErrorDetails: () => void;
}

interface Documents {
    sadDoc?: Document;
    clearanceslip?: Document;
}

interface Document {
    fileName?: string;
    type?: string;
    blob: Blob;
}

const ModalPreviewSadAndClearanceSlip: FC<Props> = ({ isOpen, close, declarationId, confirm, onClickErrorDetails }) => {
    const { commodityCodeTaxes } = useDeclarationProductTaxes(declarationId);
    const { declarationErrors } = useDeclarationFormErrors();
    const [documents, setDocuments] = useState<Documents>({});
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const { isAis } = useFormUtils();

    const methodOfPayment = useMemo(() => {
        return (
            taxPaymentMethodLabels.find((e) => e.id === commodityCodeTaxes?.methodOfPayment) || { id: '', value: '' }
        );
    }, [commodityCodeTaxes]);

    const generateSadDocument = async (declarationId: string) => {
        const sadDoc = await generateIrelandSadDraft(declarationId).then(() =>
            getIrelandSadDraft(declarationId, 'DRAFT')
        );

        return sadDoc;
    };

    const generateClearanceDocument = async (declarationId: string) => {
        const clearances = await generateIrelandClearanceDraft(declarationId).then(() =>
            getIrelandClearanceDraft(declarationId, 'DRAFT')
        );

        return clearances;
    };

    const handleFetchDocuments = useCallback(async (declarationId: string) => {
        setLoadingDocuments(true);

        const sadDoc = await generateSadDocument(declarationId);

        const clearances = await generateClearanceDocument(declarationId);

        setDocuments({
            sadDoc: sadDoc,
            clearanceslip: clearances,
        });
        setLoadingDocuments(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isOpen && isAis) {
            handleFetchDocuments(declarationId);
        }
    }, [isOpen, handleFetchDocuments, declarationId, isAis]);

    const handleOpenLink = (blob?: Blob) => {
        if (blob) {
            window.open(URL.createObjectURL(blob));
        }
    };

    const declarationWithErrors = useMemo(() => {
        return declarationErrors.items.length > 0 || declarationErrors.masterDetails.length > 0;
    }, [declarationErrors]);

    const errors = useMemo(() => {
        if (declarationWithErrors) {
            return (
                <Alert
                    message="This declaration contains errors."
                    type="error"
                    showIcon
                    action={
                        <Button size="small" type="text" onClick={onClickErrorDetails}>
                            Details
                        </Button>
                    }
                />
            );
        }
        return <></>;
    }, [declarationWithErrors, onClickErrorDetails]);

    const sadFile = useMemo(() => {
        const { sadDoc } = documents;

        if (!sadDoc) {
            return (
                <ErrorGenerateDocument>
                    <CloseCircleFilled /> Error generating SAD
                </ErrorGenerateDocument>
            );
        }

        const name = sadDoc?.fileName ?? 'SADdocument';
        const type = sadDoc?.type ?? '.pdf';
        const fullName = `${name}${type}`;

        return (
            <HyperLinkDiv onClick={() => handleOpenLink(documents?.sadDoc?.blob)}>
                <FileOutlined /> {fullName}
            </HyperLinkDiv>
        );
    }, [documents]);

    const clearanceDoc = useMemo(() => {
        const { clearanceslip } = documents;

        if (!clearanceslip) {
            return (
                <ErrorGenerateDocument>
                    <CloseCircleFilled /> Error generating Clearance slip
                </ErrorGenerateDocument>
            );
        }

        const name = clearanceslip?.fileName ?? 'Clearanceslip';
        const type = clearanceslip?.type ?? '.pdf';
        const fullName = `${name}${type}`;

        return (
            <HyperLinkDiv onClick={() => handleOpenLink(documents?.clearanceslip?.blob)}>
                <FileOutlined /> {fullName}
            </HyperLinkDiv>
        );
    }, [documents]);

    return (
        <CustomModal
            title={
                <>
                    <H5>Declaration Documents</H5>
                    {errors}
                </>
            }
            centered
            closable={false}
            visible={isOpen}
            onCancel={() => {}}
            width={800}
            zIndex={900}
            cancelText="Cancel"
            footer={[
                <Button
                    key="goToSettings"
                    onClick={() => {
                        close();
                    }}
                    size="large"
                >
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    disabled={declarationWithErrors}
                    key="goToDeclaration"
                    onClick={() => {
                        confirm();
                    }}
                    size="large"
                >
                    Confirm
                </Button>,
            ]}
        >
            {isAis && (
                <>
                    <PreviewSadText>
                        Now you can view the SAD and Clearance slip documents. Please make sure all is correct before
                        you confirm.
                    </PreviewSadText>
                    {loadingDocuments ? (
                        <div>
                            <LoadingDocuments />
                        </div>
                    ) : (
                        <div>
                            {sadFile}
                            {clearanceDoc}
                        </div>
                    )}
                    <Divider />
                </>
            )}

            <H6>Local Vat/Duty Summary</H6>
            <CommodityCodeTable data={commodityCodeTaxes} />
            <TotalInformationSection>
                <TotalInformationTitle>Total Information</TotalInformationTitle>
                <TotalInformationParam>
                    <TotalInformationLabel>Total Statistical Value: </TotalInformationLabel>
                    {commodityCodeTaxes?.totalStatisticalValue?.toFixed(2)}
                </TotalInformationParam>
                <TotalInformationParam>
                    <TotalInformationLabel>Total Liability: </TotalInformationLabel>
                    {commodityCodeTaxes?.totalLiability?.toFixed(2)}
                </TotalInformationParam>
                <TotalInformationParam>
                    <TotalInformationLabel>Method of Payment: </TotalInformationLabel>
                    {`${methodOfPayment?.id} - ${methodOfPayment?.value}`}
                </TotalInformationParam>
                <TotalInformationParam>
                    <TotalInformationLabel>Deferred Payment: </TotalInformationLabel>
                    {commodityCodeTaxes?.customsDutyPayer}
                </TotalInformationParam>
            </TotalInformationSection>
        </CustomModal>
    );
};

export default ModalPreviewSadAndClearanceSlip;
