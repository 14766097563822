import { useCallback, useEffect } from 'react';
import {
    doCreateCustomer,
    doDeleteCustomer,
    doEditCustomer,
    doGetCustomer,
    doListCustomers,
} from 'store/customers/action';
import { Customer, CustomerParams } from 'store/customers/customer';
import { useAppDispatch, useAppSelector } from '../config/hooks';

interface UseCustomersProps {
    customerId?: string;
}

const useCustomers = ({ customerId }: UseCustomersProps = {}) => {
    const customers = useAppSelector((state) => state.customers.customers);
    const customer = useAppSelector((state) => (customerId ? state.customers.entities[customerId] : ({} as Customer)));
    const isLoading = useAppSelector((state) => state.customers.isLoading);
    const error = useAppSelector((state) => state.customers.error);

    const dispatch = useAppDispatch();

    const createCustomer = useCallback((customer: Customer) => dispatch(doCreateCustomer(customer)), [dispatch]);

    const listCustomers = useCallback(
        (params?: Partial<CustomerParams>) => dispatch(doListCustomers(params)),
        [dispatch]
    );

    const getCustomer = useCallback((customerId: string) => dispatch(doGetCustomer(customerId)), [dispatch]);

    const editCustomer = useCallback(
        (customerId: string, customer: Customer) => dispatch(doEditCustomer(customerId, customer)),
        [dispatch]
    );

    const deleteCustomer = useCallback((customerId: string) => dispatch(doDeleteCustomer(customerId)), [dispatch]);

    useEffect(() => {
        if (customerId) {
            getCustomer(customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        if (!customers.list.length && !isLoading && !error) {
            listCustomers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        customers,
        customer,
        createCustomer,
        listCustomers,
        getCustomer,
        editCustomer,
        deleteCustomer,
    };
};

export default useCustomers;
