import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import config from 'config';

export type Coordinate = [number, number];

const useMap = () => {
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const mapContainer = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Create and attach map
        setMap(
            new mapboxgl.Map({
                container: mapContainer.current!,
                style: 'mapbox://styles/mapbox/outdoors-v11',
                center: [-6.272250963000388, 53.348783340728005],
                zoom: 10,
                accessToken: config.mapboxglAccessToken,
            })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { map, mapContainer };
};

export default useMap;
