import useSession from 'hooks/useSession';
import { useRoutes } from 'react-router-dom';
import routesConfig from './routes';

const AppRouter = () => {
    const { userInfo } = useSession();
    const routesConfiguration = routesConfig(userInfo);
    const routes = useRoutes(routesConfiguration);
    return routes;
};
export default AppRouter;
