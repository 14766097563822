import { deleteJourney, getJourney, listJourneys, postJourney, putJourney } from './client';
import { Journey, JourneyParams } from './journeys';

export const doCreateJourney: Function = (journey:Journey) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_JOURNEYS_REQUEST' });
    try {
        const payload = await postJourney(journey);

        dispatch({
            type: 'CREATE_JOURNEYS_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_JOURNEYS_ERROR', error: e?.response?.data });
        throw e;
    }
};


export const doListJourneys: Function = (params?: Partial<JourneyParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_JOURNEYS_REQUEST' });
    try {
        const payload = await listJourneys(params);
        dispatch({
            type: 'LIST_JOURNEYS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_JOURNEYS_ERROR', error: e?.response?.data });
    }
};

export const doGetJourney: Function = (journeyId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_JOURNEYS_REQUEST' });
    try {
        const payload = await getJourney(journeyId);
        dispatch({
            type: 'GET_JOURNEYS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_JOURNEYS_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditJourney: Function = (journey:Journey) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_JOURNEYS_REQUEST' });
    try {
        const payload = await putJourney(journey);
        dispatch({
            type: 'EDIT_JOURNEYS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_JOURNEYS_ERROR', error: e?.response?.data });
    }
};

export const doDeleteJourney: Function = (journeyId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_JOURNEY_REQUEST' });
    try {
        const payload = await deleteJourney(journeyId);
        dispatch({
            type: 'DELETE_JOURNEY_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_JOURNEY_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
