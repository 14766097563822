import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import { ValidationSchema, validators } from '../validations/validations';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';

export type Fields = 'tariffQuantity';

export const supplementaryUnitsBlockValidation: {
    childValidators: {
        'commodity.goodsMeasure': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.goodsMeasure': {
            childValidators: {
                tariffQuantity: [validators.number(), validators.float(16, 6)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const SupplementaryUnitsBlock = ({ path = 'commodity.goodsMeasure', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/2', []);

    return (
        <DeclarationField pathPrefix={path} name="tariffQuantity">
            {({ form, field }: FieldProps<any>) => (
                <DeclarationNumberInput
                    refNumber={ucc}
                    label={t('goodsItems.supplementaryUnits')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default SupplementaryUnitsBlock;
