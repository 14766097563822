import { FormikProps } from 'formik';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { declarationChanged } from 'views/declarations/utils/declaration-utils';

interface Props {
    declarationSaved: boolean;
    setDeclarationSaved: (value: boolean) => void;
    formik: FormikProps<any | undefined> | undefined;
    productsFormik: FormikProps<any | undefined> | undefined;
}

const useHandleUnsavedChanges = ({ declarationSaved, setDeclarationSaved, formik, productsFormik }: Props) => {
    const { declarationValidation, setHasUnsavedChanges } = useDeclarationValidation({
        formAction: null,
    });

    // Handle unsaved for the declaration
    useEffect(() => {
        // Reset declaration saved
        if (declarationSaved && declarationChanged(formik?.values, formik?.initialValues)) {
            setDeclarationSaved(false);
        }

        if (!declarationValidation.hasUnsavedChanges) {
            const values = formik?.values;
            const initialValues = formik?.initialValues;

            const hasChanges = !isEqual(values, initialValues);

            if (hasChanges) {
                setHasUnsavedChanges(hasChanges);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values]);

    // Handle unsaved for the product
    useEffect(() => {
        // Reset declaration saved
        if (declarationSaved && declarationChanged(productsFormik?.values, productsFormik?.initialValues)) {
            setDeclarationSaved(false);
        }

        if (!declarationValidation.hasUnsavedChanges) {
            const hasChanges = !isEqual(productsFormik?.values, productsFormik?.initialValues);

            if (hasChanges) {
                setHasUnsavedChanges(hasChanges);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsFormik?.values]);
};

export default useHandleUnsavedChanges;
