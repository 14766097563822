import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import useHideEntities from '../../../../../hooks/useHideEntities';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import InlandModeOfTransportBlock, { inlandModeOfTransportBlockValidation } from '../blocks/InlandModeOfTransportBlock';
import ModeOfTransportAtTheBorderBlock, {
    modeOfTransportAtTheBorderBlockValidation,
} from '../blocks/ModeOfTransportAtTheBorderBlock';
import NationalityOfActiveMeansOfTransportCrossingTheBorderBlock, {
    nationalityOfActiveMeansOfTransportCrossingTheBorderBlockValidation,
} from '../blocks/NationalityOfActiveMeansOfTransportCrossingTheBorderBlock';

export const modeOfTransportCardValidation = {
    selfValidators: [
        modeOfTransportAtTheBorderBlockValidation,
        inlandModeOfTransportBlockValidation,
        nationalityOfActiveMeansOfTransportCrossingTheBorderBlockValidation,
    ],
};

export const smallerModeOfTransportCardValidation = {
    selfValidators: [modeOfTransportAtTheBorderBlockValidation, inlandModeOfTransportBlockValidation],
};

interface Props extends DeclarationFormCardProps {}

const ModeOfTransportCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <NewFormCard title={t('modeOfTransportAtTheBorder')} hidden={props.hidden}>
            <FormCardContainer>
                <ModeOfTransportAtTheBorderBlock />
                <InlandModeOfTransportBlock />
                <NationalityOfActiveMeansOfTransportCrossingTheBorderBlock hide={isFormType(['IMPORT_H2'])} />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ModeOfTransportCard;
