import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { LMap } from './InvoiceModal';

export const SUGGESTED_TARICS_NUMERATION_REGEX = /\d+$/;

export const shouldDisableProduct = (
    suggestedValuePath: string,
    selectedProductsActions: Record<string, string | undefined>
) => {
    const productPath = suggestedValuePath?.split('.')[0];

    const productActionIsNotSelected = !(productPath in selectedProductsActions);
    const productActionIsUndefined = selectedProductsActions[productPath] === undefined;

    if (productActionIsNotSelected || productActionIsUndefined) return true;
};

export const shouldDisableCustomer = (suggestedValuePath: string, selectedCustomers: Record<string, string[]>) => {
    const customerPath = suggestedValuePath?.split('.')[0];

    const customerIsNotSelected = !(customerPath in selectedCustomers);
    const customerHasNoSelectedParty = selectedCustomers[customerPath]?.length === 0;

    if (customerIsNotSelected || customerHasNoSelectedParty) return true;
};

export const getSelectedSuggestedTaric = (selectedSuggestedValuesMap: LMap, suggestedTaricPath: string) => {
    const selectedKeys = Array.from(selectedSuggestedValuesMap.keys());

    for (let i = 0; i < selectedKeys.length; i++) {
        if (selectedKeys[i].includes(suggestedTaricPath)) return selectedKeys[i];
    }
};

export const shouldDisableCommodityCode = (suggestedValuePath: string, selectedSuggestedValuesMap: LMap) => {
    const productIndex = suggestedValuePath.split('.')[0];

    const selectedCommodityCode = getSelectedSuggestedTaric(
        selectedSuggestedValuesMap,
        `${productIndex}.goodsInformation.combinedNomenclatureCode`
    );
    if (!selectedCommodityCode) return false;

    const selectedCommodityCodeIsNotCurrentOne = selectedCommodityCode !== suggestedValuePath;

    if (selectedCommodityCodeIsNotCurrentOne) return true;
    else return false;
};

export const shouldDisableTaricCode = (suggestedValuePath: string, selectedSuggestedValuesMap: LMap) => {
    const pairTypeIndex = suggestedValuePath.slice(-1);
    const pairProductIndex = suggestedValuePath.split('.')[0];

    const selectedCommodityCodePair = getSelectedSuggestedTaric(
        selectedSuggestedValuesMap,
        `${pairProductIndex}.goodsInformation.combinedNomenclatureCode${pairTypeIndex}`
    );

    if (!selectedCommodityCodePair) return true;
    else return false;
};

export const getPathPrefix = (path: string): [string, string] => {
    const [pathPrefix, ...fieldPaths] = path.split('.');
    const fieldPath = fieldPaths.join('.');

    return [pathPrefix, fieldPath];
};

export const getProductFieldPath = (
    suggestedValuePath: string,
    selectedProductsActions: Record<string, string | undefined>
): string => {
    let [suggestedValueProductPath, suggestedValueFieldPath] = getPathPrefix(suggestedValuePath);

    if (suggestedValueFieldPath.includes('combinedNomenclatureCode') || suggestedValueFieldPath.includes('taricCode'))
        suggestedValueFieldPath = suggestedValueFieldPath.replace(SUGGESTED_TARICS_NUMERATION_REGEX, '');

    const selectedProductAction = selectedProductsActions[suggestedValueProductPath];
    const selectedProductPath = selectedProductAction?.split(' ')[0];

    /**Only Update Action would be truthy*/
    if (selectedProductPath) return addPathPrefix(selectedProductPath, suggestedValueFieldPath);
    else return suggestedValuePath;
};

export const getCustomerFieldPath = (
    suggestedValuePath: string,
    selectedCustomers: Record<string, string[]>
): string => {
    const [suggestedValueCustomerPath, suggestedValueFieldPath] = getPathPrefix(suggestedValuePath);

    const customersToUpdate = selectedCustomers[suggestedValueCustomerPath];

    if (customersToUpdate?.length === 1) return addPathPrefix(customersToUpdate[0], suggestedValueFieldPath);
    else return suggestedValuePath;
};
