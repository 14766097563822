import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    FormikConfig,
    FormikProps,
    FormikProvider,
    FormikValues,
    useFormikContext,
} from 'formik';
import { get, isEmpty, kebabCase } from 'lodash';
import { CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import NewFormCard from './cards/NewFormCard';
import FormCard from './cards/FormCard';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { ButtonProps, Divider } from 'antd';
import { HollowButton } from './box44/Box44';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import addPathPrefix from 'utils/addPathPrefix';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useProducts from 'hooks/useProducts';
import { useDeclarationContext } from 'utils/DeclarationContext';
import useDeclarations from 'hooks/useDeclarations';
import { useLocation, useOutletContext } from 'react-router-dom';

const AddItemButton = (props: ButtonProps): ReactElement => {
    return (
        <HollowButton size="small" {...props}>
            Add Item +
        </HollowButton>
    );
};

export interface FormProps {
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
}

type DataList = { field: string; value: string | undefined }[][];

interface Props<TValue extends FormikValues> extends Partial<DeclarationFormCardProps> {
    initialValues: FormikConfig<TValue>['initialValues'];
    validationSchema?: FormikConfig<TValue>['validationSchema'];
    path: string;
    title: string;
    list: (obj: TValue) => { field: string; value?: string | ReactNode[] | string[] }[];
    form?: (props: FormProps, path?: string) => ReactElement;
    children?: (path: string | null) => ReactNode;
    required?: boolean;
    formik?: FormikProps<any>;
    condensed?: boolean;
    onAdd?: (value: TValue) => void;
    onEdit?: (value: TValue) => void;
    onDelete?: (index: number) => void;
    refNumber?: string | string[];
    requiredFields?: string[];
    cardless?: boolean;
    style?: CSSProperties;
}

const MultipleItemsCard = <TValue extends FormikValues>(props: Props<TValue>) => {
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const { form } = useDeclarationContext();
    const { declaration } = useDeclarations();
    const { template, templateFormik } = useTemplateContext();
    const { products } = useProducts();
    const formik = useFormikContext<any>();

    const [status, setStatus] = useState<'IDLE' | 'ADDING' | 'EDITING'>('IDLE');
    const [recordToEdit, setRecordToEdit] = useState<{ index: number; value: any }>();
    const [dataList, setDataList] = useState<DataList | null>(null);

    const convertToDataList = useCallback(
        (data: any[] | undefined | null) => (Array.isArray(data) ? (data?.map(props.list) as DataList) : []),
        [props.list]
    );

    const formIsVisible = useMemo(
        () => status === 'EDITING' || status === 'ADDING' || (template && templateFormik),
        [status, template, templateFormik]
    );

    const items: any[] = useMemo(
        () => formik.getFieldProps(props.path)?.value ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.getFieldProps(props.path)?.value]
    );

    const isViewOnly = useMemo(() => {
        if (outletContext?.amendment) return false;
        return props.viewOnly || inViewOnly;
    }, [props.viewOnly, inViewOnly, outletContext?.amendment]);

    const updateDataList = () => {
        setDataList(convertToDataList(items));
    };

    const triggerAddFlow = () => {
        if (status === 'ADDING') return;
        setStatus('ADDING');
        if (recordToEdit !== undefined) setRecordToEdit(undefined);
        const newItems = [...items];
        newItems.push(props.initialValues);
        formik.setFieldValue(props.path, newItems);
    };
    const triggerEditFlow = (index: number) => {
        setStatus('EDITING');
        setRecordToEdit({ index, value: formik.getFieldProps(addPathPrefix(props.path, index.toString()))?.value });
    };
    const cancelFlow = useCallback(() => {
        setStatus('IDLE');
        setRecordToEdit(undefined);
        const newItems = [...items];
        if (status === 'EDITING' && recordToEdit) newItems[recordToEdit?.index] = recordToEdit?.value;
        else if (status === 'ADDING') newItems.pop();
        formik.setFieldValue(props.path, newItems);
        return newItems;
    }, [items, status, recordToEdit, formik, props.path]);

    const deleteRecord = (index: number) => {
        const values = [...items];
        values.splice(index, 1);
        formik.getFieldHelpers(props.path).setValue(values);

        props.onDelete?.(index);

        if (index === recordToEdit?.index) {
            setRecordToEdit(undefined);
            setStatus('IDLE');
        }

        /**
         * If deleting an item while adding we don't want to show
         * the added record in the data list so it is removed
         * from the data list but stays in the data itself
         */
        const newValues = [...values];
        if (status === 'ADDING') {
            newValues.pop();
        }

        setDataList(convertToDataList(newValues));
    };

    const handleAddEditButton = useCallback(async () => {
        setStatus('IDLE');
        const list = formik.getFieldProps(props.path)?.value;
        setDataList(convertToDataList(list));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertToDataList, formik.getFieldProps(props.path)?.value]);

    useEffect(() => {
        const subscriberId = window.EventBus.subscribe('afterDeclarationSave', () => {
            handleAddEditButton();
        });
        return () => window.EventBus.unsubscribe(subscriberId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAddEditButton]);

    /**
     * Update data list when the products or declaration changes
     */
    useEffect(() => {
        if (status === 'IDLE') {
            updateDataList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, form, declaration, items]);

    const templateForm = useMemo(() => {
        return (
            template &&
            templateFormik && (
                <FormikProvider value={templateFormik}>
                    {props.form ? props.form(templateFormik, `${props.path}.0`) : props.children?.(`${props.path}.0`)}
                </FormikProvider>
            )
        );
    }, [props, template, templateFormik]);

    const regularForm = useMemo(() => {
        const index: number = (status === 'EDITING' ? recordToEdit?.index : (items.length ?? 0) - 1) ?? 0;
        const path = addPathPrefix(props.path, index.toString());
        return (
            <FormikProvider value={formik}>
                {props.form ? props.form(formik, path) : props.children?.(path)}
            </FormikProvider>
        );
    }, [formik, items.length, props, recordToEdit, status]);

    const { declarationErrors } = useDeclarationFormErrors();

    const errors = useMemo(() => {
        if (isEmpty(declarationErrors.masterDetails) && isEmpty(declarationErrors.items)) return [];
        return get(formik.errors, props.path);
    }, [declarationErrors, formik.errors, props.path]);

    if (props.hidden) return null;

    return (
        <ConditionalWrapper
            condition={!props.condensed && !props.cardless}
            wrapper={(children) => (
                <FormCard
                    defaultOpen={props.defaultOpen}
                    viewOnly={isViewOnly}
                    cardNumber={props.cardNumber}
                    expandAll={props.expandAll}
                    total={props.cardTotal}
                    keyCard={props.keyCard}
                    title={props.title}
                >
                    {children}
                </FormCard>
            )}
        >
            <ConditionalWrapper
                condition={props.condensed && !props.cardless}
                wrapper={(children) => (
                    <NewFormCard
                        title={props.title}
                        mic={{ path: props.path, required: props.required }}
                        refNumber={props.refNumber}
                        cardButtons={
                            !isViewOnly &&
                            !template && [
                                <AddItemButton
                                    key={`add-item-button-${props.path}`}
                                    id={`${kebabCase(props.title)}-add-item-button`}
                                    onClick={triggerAddFlow}
                                />,
                            ]
                        }
                        error={typeof errors === 'string' ? errors : undefined}
                    >
                        {children}
                    </NewFormCard>
                )}
            >
                <>
                    {formIsVisible && (
                        <>
                            {template ? templateForm : regularForm}
                            {!template && !isViewOnly && (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '1rem',
                                        marginTop: '1rem',
                                    }}
                                >
                                    <HollowButton
                                        onClick={cancelFlow}
                                        size="small"
                                        id={`${kebabCase(props.title)}-cancel-button`}
                                    >
                                        Cancel <CloseOutlined height={'min-content'} />
                                    </HollowButton>
                                    <HollowButton
                                        onClick={handleAddEditButton}
                                        size="small"
                                        id={`${kebabCase(props.title)}-${!!recordToEdit ? 'edit' : 'add'}-button`}
                                    >
                                        {recordToEdit !== undefined ? (
                                            <>
                                                Edit <EditOutlined />
                                            </>
                                        ) : (
                                            <>
                                                Add <CheckOutlined />
                                            </>
                                        )}
                                    </HollowButton>
                                </div>
                            )}
                            <Divider style={{ marginTop: '1rem' }} />
                        </>
                    )}

                    {!template && (
                        <div style={{ marginTop: dataList?.length ? '1rem' : 0 }}>
                            <CardList
                                data={dataList ?? []}
                                errors={errors as any[]}
                                onDelete={(index: number) => {
                                    props.onDelete?.(index);
                                    deleteRecord(index);
                                }}
                                onEdit={triggerEditFlow}
                                condensed
                                viewOnly={isViewOnly ?? false}
                                cardTitle={kebabCase(props.title)}
                            />
                        </div>
                    )}
                </>
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
};
export default MultipleItemsCard;
