import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import { H5 } from 'components/ui/base/typography';
import FormCheckbox from 'components/ui/composed/formCheckbox/FormCheckbox';
import FormDatepicker from 'components/ui/composed/formDatepicker/FormDatepicker';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import CountrySelect from 'components/ui/composed/selects/CountrySelect';
import { useFormik } from 'formik';
import useDrivers from 'hooks/useDrivers';
import useJourneys from 'hooks/useJourneys';
import useLegs from 'hooks/useLegs';
import useRoutes from 'hooks/useRoutes';
import useSession from 'hooks/useSession';
import useVehicles from 'hooks/useVehicles';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Leg, TransportType } from 'store/journeys/legs/leg';
import { Route } from 'store/journeys/routes/route';
import * as Yup from 'yup';
import { RowTitle } from './CargoJourneys.style';
export const NEW_LEG = 'new';

const legSchema = () => {
    return Yup.object().shape({
        leg: Yup.object({
            id: Yup.string(),
            driverId: Yup.string().required('Driver is required.'),
            transportType: Yup.string().required('Transport Type is required.'),
            vehicleId: Yup.string().required('Vehicle is required.'),
            startDateTime: Yup.string().required('Start date is required.'),
            endDateTime: Yup.string().required('End date is required.'),
            description: Yup.string().nullable(),
            specialInstructions: Yup.string().nullable(),
            previousLegId: Yup.string().nullable(),
            nextLegId: Yup.string().nullable(),
            isFirstLeg: Yup.boolean().required().nullable(),
            isLastLeg: Yup.boolean().required().nullable(),
            originId: Yup.string().nullable(),
            destinationId: Yup.string().nullable(),
        }),
        origin: Yup.object({
            id: Yup.string(),
            specialInstructions: Yup.string().nullable(),
            description: Yup.string().nullable(),
            address: Yup.object({
                countryCode: Yup.string().required('Country code is required.'),
                city: Yup.string().required('City is required.'),
                addressLine1: Yup.string().required('Address Line 1 is required.').nullable(),
                addressLine2: Yup.string().nullable(),
                addressLine3: Yup.string().nullable(),
                addressLine4: Yup.string().nullable(),
                postalCode: Yup.string().required('Postal code is required.').nullable(),
                stateOrProvince: Yup.string().required('State or province is required.'),
            }),
            coordinates: Yup.object({
                latitude: Yup.string().required('Latitude is required.'),
                longitude: Yup.string().required('Longitude is required.'),
            }),
        }),
        destination: Yup.object({
            id: Yup.string(),
            specialInstructions: Yup.string().nullable(),
            description: Yup.string().nullable(),
            address: Yup.object({
                countryCode: Yup.string().required('Country code is required.'),
                city: Yup.string().required('City is required.'),
                addressLine1: Yup.string().required('Address Line 1 is required.').nullable(),
                addressLine2: Yup.string().nullable(),
                addressLine3: Yup.string().nullable(),
                addressLine4: Yup.string().nullable(),
                postalCode: Yup.string().required('Postal code is required.').nullable(),
                stateOrProvince: Yup.string().required('State or province is required.'),
            }),
            coordinates: Yup.object({
                latitude: Yup.string().required('Latitude is required.'),
                longitude: Yup.string().required('Longitude is required.'),
            }),
        }),
    });
};

interface Form {
    leg: Partial<Leg>;
    origin: Partial<Route>;
    destination: Partial<Route>;
}

const LegView: FC<{}> = () => {
    const { journeyId, legId } = useParams<{ journeyId: string; legId: string }>();
    const { getLeg, createLeg, editLeg } = useLegs({ journeyId: journeyId! }); // FIXME remove assertion
    const { journey } = useJourneys({ journeyId });
    const { userInfo } = useSession();
    const { createRoute, editRoute } = useRoutes();
    const { vehicles, listVehicles } = useVehicles();
    const { drivers, listDrivers } = useDrivers();
    const navigate = useNavigate();
    const [leg, setLeg] = useState<Form>({
        leg: {
            isFirstLeg: false,
            isLastLeg: false,
        },
    } as Form);

    useEffect(() => {
        const fetchLeg = async (id: string) => {
            const response: Leg = await getLeg(id);
            if (response) {
                const form = { ...leg };
                form.leg = response;
                if (response.destination) {
                    form.destination = response.destination;
                }
                if (response.origin) {
                    form.origin = response.origin;
                }

                setValues(form);
                setLeg(form);
            }
        };

        if (legId === NEW_LEG) {
            setLeg({ leg: { isFirstLeg: false, isLastLeg: false } } as Form);
        } else {
            if (legId !== undefined) {
                fetchLeg(legId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legId]);

    const { getFieldProps, resetForm, getFieldMeta, handleSubmit, isValid, getFieldHelpers, validateForm, setValues } =
        useFormik<Form>({
            initialValues: leg,
            validationSchema: legSchema(),
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => handleSubmitForm(values),
        });

    const handleSubmitForm = async (values: Form) => {
        if (values.leg.id) {
            handleEditLeg(values);
        } else {
            handleCreateLeg(values);
        }
    };

    const handleCreateLeg = async (values: Form) => {
        const leg = values.leg;
        const origin = values.origin;
        origin.customerId = userInfo?.customerId;

        const destination = values.destination;
        destination.customerId = userInfo?.customerId;

        const responseOrigin = await createRoute(origin as Route);
        const responseDestination = await createRoute(destination as Route);

        if (responseOrigin?.id && responseDestination?.id) {
            leg.originId = responseOrigin.id;
            leg.destinationId = responseDestination.id;

            const response = await createLeg(leg as Leg);

            if (response) {
                resetForm();
                navigate(`/cargo-journeys/${journeyId}`, { replace: true });
            }
        } else {
            //todo error;
        }
    };

    const handleEditLeg = async (values: Form) => {
        const leg: Partial<Leg> = values.leg;
        const origin = values.origin as Route;
        const destination = values.destination as Route;

        if (origin.id) {
            const response = await editRoute(origin);
            leg.originId = response.id;
        } else {
            const response = await createRoute(origin);
            leg.originId = response.id;
        }

        if (destination.id) {
            const response = await editRoute(destination);
            leg.destinationId = response.id;
        } else {
            const response = await createRoute(destination);
            leg.destinationId = response.id;
        }

        const response = editLeg(leg as Leg);
        if (response) {
            return navigate(`/cargo-journeys/${journeyId}`, { replace: true });
        }
    };

    useEffect(() => {
        listDrivers({ customerId: userInfo?.customerId });
        listVehicles({ customerId: userInfo?.customerId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const title = useMemo(() => {
        if (legId === NEW_LEG) {
            return 'Create Leg';
        } else {
            return 'Edit Leg';
        }
    }, [legId]);

    return (
        <Container>
            <RowTitle>
                <Col span={18}>
                    <H5>{title}</H5>
                </Col>
            </RowTitle>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <FormSelect
                        label="Transport Type"
                        fieldMeta={getFieldMeta('leg.transportType')}
                        fieldProps={getFieldProps('leg.transportType')}
                        fieldHelper={getFieldHelpers('leg.transportType')}
                        hideKeys
                        selectOptions={Object.entries(TransportType).map(([key, value]) => ({
                            id: key,
                            value: value,
                        }))}
                    />
                </Col>
                <Col span={12}>
                    <FormSelect
                        label="Driver"
                        fieldMeta={getFieldMeta('leg.driverId')}
                        fieldProps={getFieldProps('leg.driverId')}
                        fieldHelper={getFieldHelpers('leg.driverId')}
                        hideKeys
                        selectOptions={drivers.list.map((driver) => ({
                            id: driver.id,
                            value: driver.fullName,
                        }))}
                    />
                </Col>
                <Col span={12}>
                    <FormSelect
                        label="Vehicle"
                        hideKeys
                        fieldMeta={getFieldMeta('leg.vehicleId')}
                        fieldProps={getFieldProps('leg.vehicleId')}
                        fieldHelper={getFieldHelpers('leg.vehicleId')}
                        selectOptions={vehicles.list.map((vehicle) => ({
                            id: vehicle.id,
                            value: vehicle.number,
                        }))}
                    />
                </Col>
                <Col span={12}>
                    <FormDatepicker
                        label="Start Date"
                        showTime
                        fieldMeta={getFieldMeta('leg.startDateTime')}
                        fieldProps={getFieldProps('leg.startDateTime')}
                    />
                </Col>
                <Col span={12}>
                    <FormDatepicker
                        label="End Date"
                        showTime
                        fieldMeta={getFieldMeta('leg.endDateTime')}
                        fieldProps={getFieldProps('leg.endDateTime')}
                    />
                </Col>
                <Col span={12}>
                    <FormSelect
                        label="Previous Leg"
                        fieldMeta={getFieldMeta('leg.previousLegId')}
                        fieldProps={getFieldProps('leg.previousLegId')}
                        fieldHelper={getFieldHelpers('leg.previousLegId')}
                        selectOptions={
                            journey?.legs.map((journey) => ({
                                id: journey.id,
                                value: journey.description,
                            })) || []
                        }
                    />
                </Col>
                <Col span={12}>
                    <FormSelect
                        label="Next Leg"
                        fieldMeta={getFieldMeta('leg.nextLegId')}
                        fieldProps={getFieldProps('leg.nextLegId')}
                        fieldHelper={getFieldHelpers('leg.nextLegId')}
                        selectOptions={
                            journey?.legs.map((journey) => ({
                                id: journey.id,
                                value: journey.description,
                            })) || []
                        }
                    />
                </Col>
                <Col span={24}>
                    <FormInput
                        label="Description"
                        fieldMeta={getFieldMeta('leg.description')}
                        fieldProps={getFieldProps('leg.description')}
                        fieldHelper={getFieldHelpers('leg.description')}
                    />
                </Col>
                <Col span={24}>
                    <FormInput
                        label="Special Instractions"
                        fieldMeta={getFieldMeta('leg.specialInstructions')}
                        fieldProps={getFieldProps('leg.specialInstructions')}
                        fieldHelper={getFieldHelpers('leg.specialInstructions')}
                    />
                </Col>
                <Col span={24}>
                    <FormCheckbox
                        label="First Leg"
                        fieldMeta={getFieldMeta('leg.isFirstLeg')}
                        fieldProps={getFieldProps('leg.isFirstLeg')}
                        fieldHelper={getFieldHelpers('leg.isFirstLeg')}
                    />
                    <FormCheckbox
                        label="Last Leg"
                        fieldMeta={getFieldMeta('leg.isLastLeg')}
                        fieldProps={getFieldProps('leg.isLastLeg')}
                        fieldHelper={getFieldHelpers('leg.isLastLeg')}
                    />
                </Col>
                <Col span={12}>
                    <H5>Origin</H5>
                    <FormInput
                        label="Description"
                        fieldMeta={getFieldMeta('origin.description')}
                        fieldProps={getFieldProps('origin.description')}
                        fieldHelper={getFieldHelpers('origin.description')}
                    />
                    <FormInput
                        label="Special Instractions"
                        fieldMeta={getFieldMeta('origin.specialInstructions')}
                        fieldProps={getFieldProps('origin.specialInstructions')}
                        fieldHelper={getFieldHelpers('origin.specialInstructions')}
                    />
                    <CountrySelect
                        label="Country"
                        fieldMeta={getFieldMeta('origin.address.countryCode')}
                        fieldProps={getFieldProps('origin.address.countryCode')}
                        fieldHelper={getFieldHelpers('origin.address.countryCode')}
                    />
                    <FormInput
                        label="City"
                        fieldMeta={getFieldMeta('origin.address.city')}
                        fieldProps={getFieldProps('origin.address.city')}
                        fieldHelper={getFieldHelpers('origin.address.city')}
                    />
                    <FormInput
                        label="Address Line 1"
                        fieldMeta={getFieldMeta('origin.address.addressLine1')}
                        fieldProps={getFieldProps('origin.address.addressLine1')}
                        fieldHelper={getFieldHelpers('origin.address.addressLine1')}
                    />
                    <FormInput
                        label="Address Line 2"
                        fieldMeta={getFieldMeta('origin.address.addressLine2')}
                        fieldProps={getFieldProps('origin.address.addressLine2')}
                        fieldHelper={getFieldHelpers('origin.address.addressLine2')}
                    />
                    <FormInput
                        label="Postal Code"
                        fieldMeta={getFieldMeta('origin.address.postalCode')}
                        fieldProps={getFieldProps('origin.address.postalCode')}
                        fieldHelper={getFieldHelpers('origin.address.postalCode')}
                    />
                    <FormInput
                        label="State Or Province"
                        fieldMeta={getFieldMeta('origin.address.stateOrProvince')}
                        fieldProps={getFieldProps('origin.address.stateOrProvince')}
                        fieldHelper={getFieldHelpers('stateOrProvince')}
                    />
                    <FormInput
                        label="Latitude"
                        fieldMeta={getFieldMeta('origin.coordinates.latitude')}
                        fieldProps={getFieldProps('origin.coordinates.latitude')}
                        fieldHelper={getFieldHelpers('origin.coordinates.latitude')}
                    />
                    <FormInput
                        label="Longitude"
                        fieldMeta={getFieldMeta('origin.coordinates.longitude')}
                        fieldProps={getFieldProps('origin.coordinates.longitude')}
                        fieldHelper={getFieldHelpers('origin.coordinates.longitude')}
                    />
                </Col>
                <Col span={12}>
                    <H5>Destination</H5>
                    <FormInput
                        label="Description"
                        fieldMeta={getFieldMeta('destination.description')}
                        fieldProps={getFieldProps('destination.description')}
                        fieldHelper={getFieldHelpers('destination.description')}
                    />
                    <FormInput
                        label="Special Instractions"
                        fieldMeta={getFieldMeta('destination.specialInstructions')}
                        fieldProps={getFieldProps('destination.specialInstructions')}
                        fieldHelper={getFieldHelpers('destination.specialInstructions')}
                    />
                    <CountrySelect
                        label="Country"
                        fieldMeta={getFieldMeta('destination.address.countryCode')}
                        fieldProps={getFieldProps('destination.address.countryCode')}
                        fieldHelper={getFieldHelpers('destination.address.countryCode')}
                    />
                    <FormInput
                        label="City"
                        fieldMeta={getFieldMeta('destination.address.city')}
                        fieldProps={getFieldProps('destination.address.city')}
                        fieldHelper={getFieldHelpers('destination.address.city')}
                    />
                    <FormInput
                        label="Address Line 1"
                        fieldMeta={getFieldMeta('destination.address.addressLine1')}
                        fieldProps={getFieldProps('destination.address.addressLine1')}
                        fieldHelper={getFieldHelpers('destination.address.addressLine1')}
                    />
                    <FormInput
                        label="Address Line 2"
                        fieldMeta={getFieldMeta('destination.address.addressLine2')}
                        fieldProps={getFieldProps('destination.address.addressLine2')}
                        fieldHelper={getFieldHelpers('destination.address.addressLine2')}
                    />
                    <FormInput
                        label="Postal Code"
                        fieldMeta={getFieldMeta('destination.address.postalCode')}
                        fieldProps={getFieldProps('destination.address.postalCode')}
                        fieldHelper={getFieldHelpers('destination.address.postalCode')}
                    />
                    <FormInput
                        label="State Or Province"
                        fieldMeta={getFieldMeta('destination.address.stateOrProvince')}
                        fieldProps={getFieldProps('destination.address.stateOrProvince')}
                        fieldHelper={getFieldHelpers('destination.address.stateOrProvince')}
                    />
                    <FormInput
                        label="Latitude"
                        fieldMeta={getFieldMeta('destination.coordinates.latitude')}
                        fieldProps={getFieldProps('destination.coordinates.latitude')}
                        fieldHelper={getFieldHelpers('destination.coordinates.latitude')}
                    />
                    <FormInput
                        label="Longitude"
                        fieldMeta={getFieldMeta('destination.coordinates.longitude')}
                        fieldProps={getFieldProps('destination.coordinates.longitude')}
                        fieldHelper={getFieldHelpers('destination.coordinates.longitude')}
                    />
                </Col>
                <Col span={24}>
                    <Button
                        disabled={!isValid}
                        onClick={() => {
                            validateForm();
                            handleSubmit();
                        }}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
export default LegView;
