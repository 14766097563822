import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const FooterButton = styled(Button)`
    height: 4rem;
    min-width: 16.5rem;
    margin-left: 1rem;
`;

export const SaveProductButton = styled(FooterButton)`
    margin-left: 1rem;
`;

export const SubmitDiv = styled.div`
    background-color: ${colors.lightBlue1};
    width: 100%;
    bottom: 0;

    // bleed to the sides so it takes full width of parent
    box-shadow: 0 0 0 100vmax ${colors.lightBlue1};
    clip-path: inset(0 -100vmax);

    // Move it down the padding that the Skeleton gives the content
    margin-bottom: -1.9rem;

    // Add some space between the footer and the content
    margin-top: 5px;
`;

export const BtSubmit = styled(FooterButton)`
    background-color: ${colors.lightGreen};
    color: ${colors.white};
    border: none;
    margin-left: 1.6rem;
    &:hover {
        background: ${colors.lightGreenA70};
        border: none;
        color: ${colors.white};
    }
`;

export const CollapsedDiv = styled.div`
    display: flex;
    justify-content: right;
`;

export const CollapseFooter = styled.span`
    cursor: pointer;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    align-items: center;
`;

export const Saving = styled.span`
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.6rem;
    line-height: 2.2rem;
    align-items: center;
    margin-right: 2rem;
`;

export const CollapseIcon = styled(EyeInvisibleOutlined)`
    margin-right: 0.8rem;
`;

export const ExpandIcon = styled(EyeOutlined)`
    margin-right: 0.8rem;
`;

export const FooterRow = styled(Row)<{ padding: string }>`
    padding: ${({ padding }) => padding};
`;
