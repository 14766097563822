import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import OptionTooltip from 'components/ui/base/searchbar/search-options/OptionTooltip';
import { OptionsCol } from 'components/ui/base/searchbar/SearchBar.style';
import { Text } from 'components/ui/base/typography';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { allRecordEquals } from 'core/utils/arrays';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import { getDeclarationPayload, getExporterName, getImporterName } from 'views/declarations/utils/declaration-utils';
import {
    BlueText,
    CloseIcon,
    CustomDiv,
    CustomRow,
    InvoiceButton,
    ReverseCol,
    SectionTitle,
} from './InvoiceUpload.styles';

interface Props {
    disabled: boolean;
    job?: JobResponse;
    jobs: JobResponse[];
    createNewJob: Function;
    handleSelectJob: Function;
    setJob: Function;
    declarationFromJobs: {
        [key: string]: Declaration[];
    };
    search: (query: string) => void;
    setCustomer?: Function;
}
const SelectJob: FC<Props> = ({
    job,
    jobs,
    createNewJob,
    handleSelectJob,
    setJob,
    setCustomer,
    declarationFromJobs,
    search,
    disabled,
}) => {
    const { t } = useTranslation('customs_declarations');

    const jobsOptions = useMemo(() => {
        const getNumberOfDeclarationsOfJob = (jobId: string) => {
            if (declarationFromJobs[jobId]) {
                return declarationFromJobs[jobId].length;
            }
            return 0;
        };
        const getJobExporter = (jobId: string) => {
            if (declarationFromJobs[jobId]) {
                const d = declarationFromJobs[jobId]
                    .map((res) => {
                        const payload = getDeclarationPayload(res);
                        return getExporterName(payload);
                    })
                    .filter((res) => res !== '-');
                return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
            }
            return '-';
        };

        const getJobImporter = (jobId: string) => {
            if (declarationFromJobs[jobId]) {
                const d = declarationFromJobs[jobId]
                    .map((res) => {
                        const payload = getDeclarationPayload(res);
                        return getImporterName(payload);
                    })
                    .filter((res) => res !== '-');
                return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
            }
            return '-';
        };

        const getJobDeclarationStatus = (jobId: string) => {
            if (declarationFromJobs[jobId]) {
                const d = declarationFromJobs[jobId].map((res) => res.status);
                return allRecordEquals(d) ? (
                    <DeclarationStatusTag status={d[0]} />
                ) : (
                    <DeclarationStatusTag multiple status={d[0]} />
                );
            }
            return <></>;
        };

        const options = jobs?.map((job) => ({
            value: job.id,
            label: (
                <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col>
                        <OptionTooltip data={job.referenceId} />
                    </Col>
                    <Col>
                        <OptionTooltip data={`${getNumberOfDeclarationsOfJob(job.id)} declarations`} />
                    </Col>
                    <Col>
                        <OptionTooltip data={getJobExporter(job.id)} />
                    </Col>
                    <Col>
                        <OptionTooltip data={getJobImporter(job.id)} />
                    </Col>
                    <OptionsCol>{getJobDeclarationStatus(job.id)}</OptionsCol>
                </Row>
            ),
        }));
        return [
            {
                label: <span>jobs</span>,
                options: options,
            },
        ];
    }, [jobs, declarationFromJobs]);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${disabled && 'disabled'}`}>{t('job.searchOrCreate')}</SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <InvoiceButton
                        disabled={disabled}
                        size="large"
                        onClick={() => {
                            createNewJob();
                        }}
                    >
                        {t('job.create')}
                    </InvoiceButton>
                </ReverseCol>

                <CustomDiv>
                    {job && job.referenceId ? (
                        <Row align="middle">
                            <CloseIcon
                                className={`${disabled && 'disabled'}`}
                                onClick={() => {
                                    if (!disabled) {
                                        setJob(undefined);
                                        setCustomer?.(undefined);
                                    }
                                }}
                            />
                            <Text>
                                {t('jobId')}: <BlueText>{job?.referenceId}</BlueText>
                            </Text>
                        </Row>
                    ) : (
                        <SearchBar
                            options={jobsOptions}
                            onSelect={(value: string) => {
                                handleSelectJob(value);
                            }}
                            onClear={() => {
                                setJob(undefined);
                            }}
                            inputPlaceholder={t(`job.searchJob`)}
                            onSearch={(e) => search(e)}
                        />
                    )}
                </CustomDiv>
            </CustomRow>
        </>
    );
};

export default SelectJob;
