import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';
import { ValidationSchema, validators } from '../../export/validations/validations';

type Fields = 'itemChargeAmount' | 'itemChargeAmountCurrencyId';

export const itemPriceBlockValidation: {
    childValidators: {
        'commodity.invoiceLine': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.invoiceLine': {
            childValidators: {
                itemChargeAmount: [validators.number(), validators.float(16, 2)],
                itemChargeAmountCurrencyId: [validators.exact(3)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ItemPriceBlock = ({ path = 'commodity.invoiceLine', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/14', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="itemChargeAmount">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput refNumber={ucc} label={t('amount')} {...getFormikProps(field.name, form)} condensed />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="itemChargeAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <CurrencySelect
                        refNumber={ucc}
                        label={t('currency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default ItemPriceBlock;
