import { PaginatedParams } from 'core/http/pagination';
import { useCallback, useEffect } from 'react';
import {
    doDeleteDigitalCertificate,
    doGetDigitalCertificate,
    doListDigitalCertificate,
    doUpdateDigitalCertificate,
    doUploadDigitalCertificate,
} from 'store/digital-certificates/action';
import {
    DigitalCertificate,
    UpdateDigitalCertificate,
    UploadDigitalCertificateRequest,
} from 'store/digital-certificates/digital-certificates';
import { useAppDispatch, useAppSelector } from '../config/hooks';

interface Props {
    id?: string;
}

const useDigitalCertificates = ({ id }: Props = {}) => {
    const digitalCertificates = useAppSelector((state) => state.digitalCertificates.digitalCertificates);
    const digitalCertificate = useAppSelector((state) =>
        id ? state.digitalCertificates.digitalCertificate[id] : ({} as DigitalCertificate)
    );
    const isLoading = useAppSelector((state) => state.digitalCertificates.isLoading);
    const error = useAppSelector((state) => state.digitalCertificates.error);

    const dispatch = useAppDispatch();

    const uploadDigitalCertificate = useCallback(
        (request: UploadDigitalCertificateRequest) => dispatch(doUploadDigitalCertificate(request)),
        [dispatch]
    );

    const listDigitalCertificates = useCallback(
        (params?: Partial<PaginatedParams>) => dispatch(doListDigitalCertificate(params)),
        [dispatch]
    );

    const getDigitalCertificate = useCallback(
        (customerId: string) => dispatch(doGetDigitalCertificate(customerId)),
        [dispatch]
    );

    const editDigitalCertificate = useCallback(
        (id: string, body: UpdateDigitalCertificate) => dispatch(doUpdateDigitalCertificate(id, body)),
        [dispatch]
    );

    const deleteDigitalCertificate = useCallback((id: string) => dispatch(doDeleteDigitalCertificate(id)), [dispatch]);

    useEffect(() => {
        if (id) {
            getDigitalCertificate(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return {
        isLoading,
        error,
        digitalCertificates,
        digitalCertificate,
        uploadDigitalCertificate,
        listDigitalCertificates,
        getDigitalCertificate,
        editDigitalCertificate,
        deleteDigitalCertificate,
    };
};

export default useDigitalCertificates;
