import Button from 'components/ui/base/button/Button';
import { H5, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';

export const StyledDiv = styled.div`
    width: 100%;
    padding-bottom: 4rem;
`;

export const Info = styled(TextSmall)`
    text-align: center;
    margin-bottom: 2.8rem;
`;

export const DivInfo = styled.div`
    text-align: center;
    justify-content: center;
    display: flex;
`;

export const Title = styled(H5)`
    justify-content: center;
    margin-bottom: 2.8rem;
`;

export const SignInButton = styled(Button)`
    padding: 0;
    margin-left: 0.5rem;
    height: 2rem;
`;

export const SignUpButton = styled(Button)`
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
`;
