import {
    createRefundDepositDeclaration,
    submitRefundDepositDeclaration,
    updateRefundDepositDeclaration
} from '../client';
import { IrelandRefundDeposit } from './ireland-refund-deposit';

export const doPostIrelandImportDepositRefund: Function =
    (declarationId: string, body: IrelandRefundDeposit) => async (dispatch: Function) => {
        dispatch({ type: 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST' });
        try {
            const payload = await createRefundDepositDeclaration(declarationId, body);
            dispatch({
                type: 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'POST_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doPutIrelandImportDepositRefund: Function =
    (declarationId: string, body: IrelandRefundDeposit, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST' });
        try {
            const payload = await updateRefundDepositDeclaration(declarationId, body, refundId);
            dispatch({
                type: 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'PUT_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitIrelandImportDepositRefund: Function =
    (declarationId: string, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_REQUEST' });
        try {
            const payload = await submitRefundDepositDeclaration(declarationId, refundId);
            dispatch({
                type: 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_IRELAND_IMPORT_DEPOSIT_REFUND_ERROR', error: e?.response?.data });
        }
    };
