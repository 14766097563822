import { CheckCircleFilled, DownloadOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import Button from 'components/ui/base/button';
import { H5, Text } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const MainDiv = styled.div`
    background: ${colors.white};
    padding: 40px;
    border: 1px solid ${colors.darkGrey2};
    border-radius: 8px;
`;

export const CheckIcon = styled(CheckCircleFilled)`
    color: ${colors.polarGreen};
    margin-right: 2.4rem;
`;

export const Label = styled(Text)`
    font-weight: 600;
    margin-right: 0.5rem;
`;

export const GeyText = styled(Text)`
    font-weight: 600;
    color: ${colors.darkGrey};
`;

export const BlueText = styled(Text)`
    font-weight: 600;
    color: ${colors.blueLink};
`;

export const StyledRow = styled(Row)`
    margin-bottom: 1.6rem;
`;

export const ExtraSpaceRow = styled(Row)`
    margin-bottom: 4.8rem;
`;

export const StyledButton = styled(Button)`
    margin-right: 1.9rem;
`;

export const SadDocTitle = styled(H5)`
    margin-right: 3.6rem;
`;

export const StyledDownloadOutlined = styled(DownloadOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.purpleBlue};
    }
`;

export const StyledPrinterOutlined = styled(PrinterOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.darkPurple};
    }
`;

export const StyledEyeOutlined = styled(EyeOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.darkPurple};
    }
`;

export const DocumentsDiv = styled.div`
    margin-bottom: 1.5rem;
`;
