import Alert from 'components/ui/base/alert/Alert';
import { FC, useMemo } from 'react';

interface Props {
    documentsUploadRequested?: boolean;
    documentsPresentationRequested?: boolean;
}

const AlertSubmitDocuments: FC<Props> = ({
    documentsUploadRequested = false,
    documentsPresentationRequested = false,
}) => {
    const message = useMemo(() => {
        let message = '';
        if (documentsUploadRequested) {
            message = 'Revenue has requested documents to be uploaded';
        }

        if (documentsPresentationRequested) {
            message = 'Revenue has requested documents to be physically presented at a Customs Office';
        }

        return (
            (documentsUploadRequested || documentsPresentationRequested) && (
                <Alert message={<>{message}</>} closable type="warning" showIcon />
            )
        );
    }, [documentsUploadRequested, documentsPresentationRequested]);

    return <>{message}</>;
};
export default AlertSubmitDocuments;
