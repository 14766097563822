import { deleteDriver, getDriver, listDrivers, postDriver, putDriver } from './client';
import { Driver, DriversQueryParams } from './driver';

export const doListDrivers: Function = (params?: Partial<DriversQueryParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_DRIVERS_REQUEST' });

    try {
        const payload = await listDrivers(params);
        dispatch({
            type: 'LIST_DRIVERS_SUCCESS',
            payload,
        });
        return payload;
    } catch (error: any) {
        dispatch({
            type: 'LIST_DRIVERS_ERROR',
            error: error.response.data,
        });
    }
};

export const doCreateDriver: Function = (driver: Driver) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_DRIVER_REQUEST' });
    try {
        const payload = await postDriver(driver);

        dispatch({
            type: 'CREATE_DRIVER_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_DRIVER_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doGetDriver: Function = (driverId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DRIVER_REQUEST' });
    try {
        const payload = await getDriver(driverId);
        dispatch({
            type: 'GET_DRIVER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_DRIVER_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditDriver: Function = (driver: Driver) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_DRIVER_REQUEST' });
    try {
        const payload = await putDriver(driver);
        dispatch({
            type: 'EDIT_DRIVER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_DRIVER_ERROR', error: e?.response?.data });
    }
};

export const doDeleteDriver: Function = (driverId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_DRIVER_REQUEST' });
    try {
        const payload = await deleteDriver(driverId);
        dispatch({
            type: 'DELETE_DRIVER_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_DRIVER_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
