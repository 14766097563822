import { TimePicker, TimePickerProps } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';
import EDocketLabel from './EDocketLabel';

type Props = { label?: string } & TimePickerProps;

const TimePickerContainer = styled('div')`
    .ant-picker {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
    }
`;

const StyledTimePicker = (props: TimePickerProps) => {
    return (
        <TimePickerContainer>
            <TimePicker {...props} />
        </TimePickerContainer>
    );
};

const EDocketFormTimePicker = ({ label, ...timePickerProps }: Props) => {
    return (
        <>
            <EDocketLabel label={label} />
            <StyledTimePicker {...timePickerProps} />
        </>
    );
};

export default memo(EDocketFormTimePicker, (prev, next) => {
    return prev?.value === next?.value;
});
