import { SuccessResponse, ListResponse } from 'core/http/response';
import axiosClient, { buildQueryStringFromObject } from '../../config/axios';
import { DownloadFileResponse, PresignedUrlResponse } from './document';
import { ListPayload } from 'core/http/response';
import { Document } from './document';
import config from 'config';

export const documentsPreUpload = (params: {}): Promise<PresignedUrlResponse> => {
    const queryString = buildQueryStringFromObject(params);
    return axiosClient
        .get<SuccessResponse<PresignedUrlResponse>>(`${config.documentsUrl}/upload${queryString}`)
        .then((response) => {
            return response.data.payload;
        });
};

export const confirmUploadFile = (body: {}): Promise<SuccessResponse<void>> => {
    return axiosClient.post<SuccessResponse<void>>(`${config.documentsUrl}`, body).then((response) => {
        return response.data;
    });
};

export const downloadFile = (id: string): Promise<DownloadFileResponse> => {
    return axiosClient
        .get<SuccessResponse<DownloadFileResponse>>(`${config.documentsUrl}/${id}/download`)
        .then((response) => {
            return response.data.payload;
        });
};

export const deleteFileCms = (id: string): Promise<SuccessResponse<void>> => {
    return axiosClient.delete<SuccessResponse<void>>(`${config.documentsUrl}/${id}`).then((response) => {
        return response.data;
    });
};

export const updateCmsFile = (id: string | undefined, body: any): Promise<SuccessResponse<void>> => {
    return axiosClient.put<SuccessResponse<void>>(`${config.documentsUrl}/${id}`, body).then((response) => {
        return response.data;
    });
};

export const listDocuments = (params: any): Promise<ListPayload<Document>> => {
    return axiosClient
        .get<ListResponse<Document>>(`${config.documentsUrl}`, { params })
        .then((response) => response.data.payload);
};
