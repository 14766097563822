import { FC, useMemo } from 'react';
import { Alert } from './AlertInvalidationRequested.style';

interface Props {
    insufficientFunds?: boolean;
}

const AlertInsufficientFunds: FC<Props> = ({ insufficientFunds = false }) => {
    const message = useMemo(() => {
        return (
            insufficientFunds && (
                <Alert message="Insufficient funds to approve the declaration." type="warning" showIcon />
            )
        );
    }, [insufficientFunds]);

    return <>{message}</>;
};
export default AlertInsufficientFunds;
