import axiosClient from 'config/axios';
import { useEffect, useReducer } from 'react';
import config from '../config';
import { SuccessResponse } from '../core/http/response';

export interface JobDeclarationInformation {
    jobId: string;
    declarationType: string;
    importer: string;
    exporter: string;
    declarant: string;
    declarationsSize: number;
}

const reducerFunction = (
    state: {
        loading: boolean;
        jobsDeclarationInformation: JobDeclarationInformation[] | undefined;
    },
    action: Record<string, any>
) => {
    switch (action.type) {
        case 'FETCH_START':
            return {
                loading: true,
                jobsDeclarationInformation: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                loading: false,
                jobsDeclarationInformation: action.payload,
            };
        case 'FETCH_ERROR':
            return {
                loading: false,
                jobsDeclarationInformation: undefined,
            };
        default:
            return state;
    }
};

const useJobDeclarationInformation = ({ jobIdsPerPage }: { jobIdsPerPage: string[] }) => {
    const [state, dispatch] = useReducer(reducerFunction, {
        loading: false,
        jobsDeclarationInformation: undefined,
    });

    useEffect(() => {
        if (!jobIdsPerPage.length) return;

        dispatch({ type: 'FETCH_START' });
        axiosClient
            .get<SuccessResponse<JobDeclarationInformation[]>>(`${config.declarationsUrl}/declarations/job/info`, {
                params: { jobIds: jobIdsPerPage.join(), declarationsSize: 10 },
            })
            .then((response) => dispatch({ type: 'FETCH_SUCCESS', payload: response.data.payload }))
            .catch(() => dispatch({ type: 'FETCH_ERROR' }));
    }, [jobIdsPerPage]);

    return {
        declarationInformationIsLoading: state.loading,
        jobsDeclarationInformation: state.jobsDeclarationInformation,
    };
};

export default useJobDeclarationInformation;
