import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';

import ieH1ValidationSchema, {
    goodsShipmentItemSchemaRequired as ieH1ProductValidationSchema,
} from '../ireland/import/h1/validation/ValidationSchema';
import { parseDeclaration as h1ParseForm, ParserOptions } from '../ireland/import/h1/validation/utils';

import ieH7ValidationSchema from '../ireland/import/h7/validation/ValidationSchema';
import ieH7ProductValidationSchema from '../ireland/import/h7/validation/H7ProductValidationSchema';
import { parseValidationErrors as h7ParseValidationErrors } from '../ireland/import/h7/validation/utils';

import ieEnsValidationSchema, {
    goodsItems as ieEnsProductValidationSchema,
} from '../ireland/ens/validation/irelandEnsValidation';
import ieEnsParseValidationErrors from '../ireland/ens/validation/parseValidationErrors';

import ieEtdValidationSchema, {
    etdItemValidation as ieEtdProductValidationSchema,
} from '../ireland/etd/validations/irelandEtdSubmitValidation';

import ieTsdValidationSchema from '../ireland/tsd/validations/irelandTsdSubmitValidation';

import { ieArrivalAtExitSubmitValidation as ieArrivalValidationSchema } from '../ireland/arrival/validation/validation-submit';

import CdsExportDeclarationUtils from '../uk/export/utils';
import cdsParseValidationErrors from '../uk/export/parseValidationErrors';
import { cdsExportFormValidation } from '../uk/export/UkExportForm';
import { cdsExportProductSectionValidation } from '../uk/export/UkExportProductsSection';
import IrelandImportH1DeclarationUtils from '../ireland/import/h1/utils';
import IrelandImportH7DeclarationUtils from '../ireland/import/h7/utils';
import { DeclarationType } from '../common/declarationType';
import { cdsImportH2ProductValidation, cdsImportH2Validation } from '../uk/import/h2/validation/cdsImportH2Validation';
import { IEH1_DataStructure, IEH1_ItemDataStructure } from '../ireland/import/h1/ie-h1-data-structure';
import { IEH7_DataStructure, IEH7_ItemDataStructure } from '../ireland/import/h7/ie-h7-data-structure';
import { cdsImportH1ProductValidation, cdsImportH1Validation } from '../uk/import/h1/validation/cdsImportH1Validation';
import CdsImportH1DeclarationUtils from '../uk/import/h1/utils';
import { ENS_DataStructure, ENS_ItemDataStructure } from '../ireland/ens/ie-ens-data-structure';
import { DeclarationExternalEntity } from '../../../store/declarations/enums/common/declaration-external-entity';
import {
    irelandExportB1ValidationHeader,
    irelandExportB1ValidationItem,
} from '../ireland/export/validation/IrelandExportB1Validation';
import {
    irelandExportB2ValidationHeader,
    irelandExportB2ValidationItem,
} from '../ireland/export/validation/IrelandExportB2Validation';
import {
    irelandExportB3ValidationHeader,
    irelandExportB3ValidationItem,
} from '../ireland/export/validation/IrelandExportB3Validation';
import {
    irelandExportB4ValidationHeader,
    irelandExportB4ValidationItem,
} from '../ireland/export/validation/IrelandExportB4Validation';
import {
    irelandExportC1ValidationHeader,
    irelandExportC1ValidationItem,
} from '../ireland/export/validation/IrelandExportC1Validation';
import {
    irelandExportC2ValidationHeader,
    irelandExportC2ValidationItem,
} from '../ireland/export/validation/IrelandExportC2Validation';
import irelandExportValidationErrorsParser from '../ireland/export/validation/IrelandExportValidationErrorsParser';
import IrelandEnsDeclarationUtils from '../ireland/ens/utils';
import { ValidationSchema } from '../uk/export/validations/validations';
import { Declarations, TransformData } from '../common/declaration-view/DeclarationView';
import { AnyObjectSchema } from 'yup';
import { FormikErrors } from 'formik';
import useProducts from '../../../hooks/useProducts';
import useDeclarations from '../../../hooks/useDeclarations';
import { ParsedForm } from '../../../store/declaration-form-errors/ParsedForm';

export interface DeclarationViewMapValues<TDeclaration extends Declarations> {
    declarationValidationSchema?: AnyObjectSchema | ((numberOfItems?: number) => AnyObjectSchema);
    declarationFormValidations?: ValidationSchema;
    productFormValidations?: ValidationSchema;
    productValidationSchema?: AnyObjectSchema | undefined;
    parseForm?: (errors: FormikErrors<TDeclaration>, options?: ParserOptions) => ParsedForm;
    declarationType: DeclarationType;
    declarationInternalType: DeclarationInternalType;
    declarationName: string;
    updateDeclarationFuncName: keyof OnlyFunctions<ReturnType<typeof useDeclarations>>;
    updateProductFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    createProductFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    listProductsFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    transformData?: TransformData;
    productFieldsToAutosaveOn?: string[];
    payloadPath?: string;
    transformProductCreateResponse?: (res: any) => any;
    numberOfItemsFieldName?: string;
    itemPath: string;
    // structure and itemStructure used for template
    // default values of nested objects and arrays
    structure?: Record<string, any>;
    itemStructure?: Record<string, any>;
}

type DeclarationViewMap = {
    [key in string]: DeclarationViewMapValues<any> | { [key: string]: Partial<DeclarationViewMapValues<any>> };
};

const declarationViewMap: DeclarationViewMap = {
    [DeclarationType.IE_IMPORT_H1]: {
        declarationName: 'Import',
        declarationType: DeclarationType.IE_IMPORT_H1,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ieH1ValidationSchema,
        productValidationSchema: ieH1ProductValidationSchema,
        parseForm: h1ParseForm,
        createProductFuncName: 'createIrelandImportProduct',
        listProductsFuncName: 'listIrelandH1Products',
        updateDeclarationFuncName: 'updateIrelandImportDeclaration',
        updateProductFuncName: 'updateIrelandImportProduct',
        productFieldsToAutosaveOn: ['itemAmount', 'taxBox43bis'],
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return IrelandImportH1DeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.irelandImportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        irelandImportDeclaration:
                            IrelandImportH1DeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformProductForServer(data);
                },
            },
        },
        itemPath: 'goodsShipment.goodsShipmentItem',
        structure: IEH1_DataStructure,
        itemStructure: IEH1_ItemDataStructure,
    },
    [DeclarationType.IE_IMPORT_H7]: {
        declarationName: 'H7',
        declarationType: DeclarationType.IE_IMPORT_H7,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ieH7ValidationSchema,
        productValidationSchema: ieH7ProductValidationSchema,
        parseForm: h7ParseValidationErrors,
        createProductFuncName: 'createIrelandH7ImportProduct',
        listProductsFuncName: 'listIrelandH7Products',
        updateDeclarationFuncName: 'updateIrelandH7ImportDeclaration',
        updateProductFuncName: 'updateIrelandH7ImportProduct',
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return IrelandImportH7DeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.irelandH7ImportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        irelandH7ImportDeclaration:
                            IrelandImportH7DeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForServer(data);
                },
            },
        },
        itemPath: 'goodsShipment.governmentAgencyGoodsItem',
        structure: IEH7_DataStructure,
        itemStructure: IEH7_ItemDataStructure,
    },
    [DeclarationType.IE_EXPORT]: {
        default: {
            declarationName: 'Ireland Export',
            declarationType: DeclarationType.IE_EXPORT,
            declarationInternalType: DeclarationInternalType.EXPORT,
            parseForm: irelandExportValidationErrorsParser,
            createProductFuncName: 'createIrelandExportProduct',
            listProductsFuncName: 'listIrelandExportProducts',
            updateDeclarationFuncName: 'updateIrelandExportDeclaration',
            updateProductFuncName: 'updateIrelandExportProduct',
            itemPath: 'goodsShipment.goodsItem',
        },
        B1: {
            declarationFormValidations: irelandExportB1ValidationHeader,
            productFormValidations: irelandExportB1ValidationItem,
        },
        B2: {
            declarationFormValidations: irelandExportB2ValidationHeader,
            productFormValidations: irelandExportB2ValidationItem,
        },
        B3: {
            declarationFormValidations: irelandExportB3ValidationHeader,
            productFormValidations: irelandExportB3ValidationItem,
        },
        B4: {
            declarationFormValidations: irelandExportB4ValidationHeader,
            productFormValidations: irelandExportB4ValidationItem,
        },
        C1: {
            declarationFormValidations: irelandExportC1ValidationHeader,
            productFormValidations: irelandExportC1ValidationItem,
        },
        C2: {
            declarationFormValidations: irelandExportC2ValidationHeader,
            productFormValidations: irelandExportC2ValidationItem,
        },
    },
    [DeclarationType.IE_ENS]: {
        declarationName: 'ENS',
        declarationType: DeclarationType.IE_ENS,
        declarationInternalType: DeclarationInternalType.ENS,
        declarationValidationSchema: ieEnsValidationSchema,
        productValidationSchema: ieEnsProductValidationSchema,
        parseForm: ieEnsParseValidationErrors,
        createProductFuncName: 'createIrelandEnsProduct',
        numberOfItemsFieldName: 'numberOfItems',
        listProductsFuncName: 'listIrelandEnsProducts',
        updateDeclarationFuncName: 'updateEntrySummaryDeclaration',
        updateProductFuncName: 'updateIrelandEnsProduct',
        itemPath: 'goodsShipment.goodsItems',
        structure: ENS_DataStructure,
        itemStructure: ENS_ItemDataStructure,
        transformData: {
            product: {
                forClient: (data: any) => {
                    return IrelandEnsDeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandEnsDeclarationUtils.transformProductForServer(data);
                },
            },
        },
    },
    [DeclarationType.IE_ETD]: {
        declarationName: 'ETD',
        declarationType: DeclarationType.IE_ETD,
        declarationInternalType: DeclarationInternalType.ETD,
        declarationValidationSchema: ieEtdValidationSchema,
        productValidationSchema: ieEtdProductValidationSchema,
        parseForm: undefined,
        createProductFuncName: 'createIrelandEtdProduct',
        listProductsFuncName: 'listIrelandEtdProducts',
        updateDeclarationFuncName: 'updateElectronicTransportDocument',
        updateProductFuncName: 'updateIrelandEtdProduct',
        itemPath: 'goodsShipment.goodsShipmentItem',
    },
    [DeclarationType.IE_TSD]: {
        declarationName: 'TSD',
        declarationType: DeclarationType.IE_TSD,
        declarationInternalType: DeclarationInternalType.TSD,
        declarationValidationSchema: ieTsdValidationSchema,
        productValidationSchema: undefined,
        parseForm: undefined,
        createProductFuncName: 'createIrelandTsdProduct',
        listProductsFuncName: 'listIrelandTsdProducts',
        updateDeclarationFuncName: 'updateTemporaryStorageDeclaration',
        updateProductFuncName: 'updateIrelandTsdProduct',
        itemPath: 'goodsShipment.goodsShipmentItem',
    },
    [DeclarationType.IE_ARRIVAL]: {
        declarationName: 'Arrival At Exit',
        declarationType: DeclarationType.IE_ARRIVAL,
        declarationInternalType: DeclarationInternalType.ARRIVAL,
        declarationValidationSchema: ieArrivalValidationSchema,
        productValidationSchema: undefined,
        parseForm: undefined,
        createProductFuncName: undefined,
        listProductsFuncName: undefined,
        updateDeclarationFuncName: 'updateArrivalAtExitDeclaration',
        updateProductFuncName: undefined,
        itemPath: 'goodsShipment.goodsShipmentItem',
    },
    [DeclarationExternalEntity.CDS]: {
        default: {
            declarationName: 'Export',
            declarationType: DeclarationType.UK,
            declarationInternalType: DeclarationInternalType.EXPORT,
            parseForm: cdsParseValidationErrors,
            createProductFuncName: 'createUkExportProduct',
            listProductsFuncName: 'listUkExportProducts',
            updateDeclarationFuncName: 'updateUkExportDeclaration',
            updateProductFuncName: 'updateUkExportProduct',
            payloadPath: 'cdsExportDeclarationPayload',
            transformProductCreateResponse: (res: any) => res?.governmentAgencyGoodsItem,
            transformData: {
                declaration: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsExportDeclarationUtils.transformForClient(data);

                        const currentDeclaration = data?.cdsExportDeclaration;
                        if (!currentDeclaration) return;
                        return {
                            ...data,
                            cdsExportDeclaration: CdsExportDeclarationUtils.transformForClient(currentDeclaration),
                        };
                    },
                    forServer: (data: any) => {
                        return CdsExportDeclarationUtils.transformForServer(data);
                    },
                },
                product: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsExportDeclarationUtils.transformProductForClient(data);
                        return CdsExportDeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                    },
                    forServer: (data: any) => {
                        return { governmentAgencyGoodsItem: CdsExportDeclarationUtils.transformProductForServer(data) };
                    },
                },
            },
            numberOfItemsFieldName: 'goodsItemQuantity',
            itemPath: 'goodsShipment.governmentAgencyGoodsItem',
        },
        B1: {
            declarationFormValidations: cdsExportFormValidation,
            productFormValidations: cdsExportProductSectionValidation,
        },
        H1: {
            declarationName: 'Import',
            declarationInternalType: DeclarationInternalType.IMPORT_NEW,
            declarationFormValidations: cdsImportH1Validation,
            productFormValidations: cdsImportH1ProductValidation,
            transformData: {
                declaration: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsImportH1DeclarationUtils.transformForClient(data);

                        const currentDeclaration = data?.cdsExportDeclaration;
                        if (!currentDeclaration) return;
                        return {
                            ...data,
                            cdsExportDeclaration: CdsImportH1DeclarationUtils.transformForClient(currentDeclaration),
                        };
                    },
                    forServer: (data: any) => {
                        return CdsImportH1DeclarationUtils.transformForServer(data);
                    },
                },
                product: {
                    forClient: (data: any, straight?: boolean) => {
                        if (straight) return CdsImportH1DeclarationUtils.transformProductForClient(data);
                        return CdsImportH1DeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                    },
                    forServer: (data: any) => {
                        return {
                            governmentAgencyGoodsItem: CdsImportH1DeclarationUtils.transformProductForServer(data),
                        };
                    },
                },
            },
        },
        H2: {
            declarationName: 'Import',
            declarationInternalType: DeclarationInternalType.IMPORT_NEW,
            declarationFormValidations: cdsImportH2Validation,
            productFormValidations: cdsImportH2ProductValidation,
        },
    },
};

export default declarationViewMap;
