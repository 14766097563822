import { ColumnsType } from 'antd/lib/table';
import { ColumnType } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { ReactElement, useMemo, useState } from 'react';
import { EditOutlined, DeleteOutlined, CopyOutlined, EyeOutlined, ContainerOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Tooltip } from 'antd';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { getTableChangeParams, TableChangeParams } from '../../../../utils/tableHelpers';

interface CommandButtonProps extends ButtonProps {
    icon: ReactElement;
    tooltip: string;
}
export const CommandButton = ({ icon, tooltip, ...buttonProps }: CommandButtonProps) => {
    return (
        <Tooltip overlay={tooltip}>
            <Button type="text" shape="circle" icon={icon} {...buttonProps} />
        </Tooltip>
    );
};

type Entity = { id: string };

type Props<TEntity extends Entity> = {
    columns: ColumnsType<TEntity>;
    data: ListPayload<TEntity> | undefined;
    doSelectOnClick?: boolean;
    onEdit?: (entityId: string) => void;
    onDelete?: (entityId: string) => void;
    onDuplicate?: (entityId: string) => void;
    onRowSelection?: (ids: string[]) => void;
    onPaginatorChange?: any;
    onRowClick?: (entityId: string) => void;
    loading?: boolean;
    rowSelectionType?: 'checkbox' | 'radio';
    withPagination?: boolean;
    onView?: (entityId: string) => void;
    onUnarchive?: (id: string) => void;
    onArchive?: (id: string) => void;
    atArchivedPath?: boolean;
};

const DashboardTable = <TEntity extends Entity>({
    columns: columnsProp,
    data,
    onEdit,
    onDelete,
    onDuplicate,
    onRowClick,
    onRowSelection,
    onPaginatorChange,
    loading,
    doSelectOnClick,
    rowSelectionType = 'checkbox',
    withPagination = true,
    onView,
    onUnarchive,
    onArchive,
    atArchivedPath,
}: Props<TEntity>) => {
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const [selections, setSelections] = useState<string[]>([]);

    const handlePagination = async ({ pagination, sorter }: TableChangeParams) => {
        showGlobalOverlay({ type: 'LoadingOverlay' });
        await onPaginatorChange?.(getTableChangeParams({ pagination, sorter }));
        hideGlobalOverlay();
    };

    const columns: ColumnsType<TEntity> = useMemo(() => {
        return [
            ...columnsProp,
            ...(onEdit || onDelete || onView || onDuplicate || onArchive || onUnarchive
                ? [
                      {
                          title: 'Commands',
                          dataIndex: 'commands',
                          key: 'commands',
                          align: 'center',
                          render: (text: string, record) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          gap: '1rem',
                                          justifyContent: 'center',
                                      }}
                                  >
                                      {onEdit && !atArchivedPath && (
                                          <CommandButton
                                              icon={<EditOutlined />}
                                              tooltip="Edit"
                                              onClick={() => onEdit(record.id)}
                                          />
                                      )}
                                      {onDelete && (
                                          <CommandButton
                                              icon={<DeleteOutlined />}
                                              tooltip="Delete"
                                              onClick={() => onDelete(record.id)}
                                          />
                                      )}
                                      {onDuplicate && !atArchivedPath && (
                                          <CommandButton
                                              icon={<CopyOutlined />}
                                              tooltip="Duplicate"
                                              onClick={() => onDuplicate(record.id)}
                                          />
                                      )}
                                      {onView && (
                                          <CommandButton
                                              icon={<EyeOutlined />}
                                              tooltip="View"
                                              onClick={() => onView(record.id)}
                                          />
                                      )}
                                      {onArchive && onUnarchive && (
                                          <CommandButton
                                              icon={<ContainerOutlined />}
                                              tooltip={!atArchivedPath ? 'Archive' : 'Unarchive'}
                                              onClick={() =>
                                                  !atArchivedPath ? onArchive(record.id) : onUnarchive(record.id)
                                              }
                                          />
                                      )}
                                  </div>
                              );
                          },
                      } as ColumnType<TEntity>,
                  ]
                : []),
        ];
    }, [columnsProp, onDelete, onDuplicate, onEdit, onView, onArchive, onUnarchive, atArchivedPath]);

    return (
        <Table
            rowKey="id"
            style={{ marginTop: '2.5rem' }}
            columns={columns}
            dataSource={data?.list}
            onRow={(record, rowIndex) => ({
                onClick: () => {
                    if (doSelectOnClick) {
                        setSelections((prev) => {
                            if (rowIndex === undefined) return prev;

                            const id = record.id;

                            let result;
                            if (rowSelectionType === 'radio') {
                                result = [id];
                            } else if (prev.includes(id)) {
                                result = [...prev.filter((_id) => _id !== id)];
                            } else {
                                result = [...prev, id];
                            }
                            onRowSelection?.(result);
                            return result;
                        });
                    } else {
                        onRowClick?.(record.id);
                    }
                },
            })}
            rowSelection={
                onRowSelection
                    ? {
                          type: rowSelectionType,
                          onChange: (selectedRowKeys: unknown, selectedRows: TEntity[]) => {
                              const ids = selectedRows.map((c) => c.id);
                              onRowSelection(ids);
                              setSelections(ids);
                          },
                          selectedRowKeys: selections,
                      }
                    : undefined
            }
            pagination={
                withPagination
                    ? {
                          current: data?.pageNumber! + 1,
                          total: data?.total,
                          showSizeChanger: false,
                          pageSize: data?.pageSize,
                          position: ['bottomCenter'],
                      }
                    : false
            }
            onChange={(pagination, _, sorter) => handlePagination({ pagination, sorter })}
            loading={loading}
        />
    );
};

export default DashboardTable;
