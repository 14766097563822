import { Segmented, SegmentedProps } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/lib/segmented';
import { ReactElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Segment = (props: { children: ReactNode }): ReactElement => {
    return (
        <div style={{ padding: '0.15em 1.5em' }}>
            <b>{props.children}</b>
        </div>
    );
};

type Props = Omit<SegmentedProps, 'options'> & { options?: (SegmentedValue | SegmentedLabeledOption)[] };

const StyledSegmented = styled(Segmented)`
    border-radius: 8px;
    padding: 0.2em;
    .ant-segmented-item,
    .ant-segmented-item-selected,
    .ant-segmented-thumb {
        border-radius: 8px;
    }
`;

const StatusSegmented = ({ ...segmentedProps }: Props): ReactElement => {
    const navigate = useNavigate();

    const handleStatusChange = useCallback(
        (status: SegmentedValue) => {
            if (status === 'archived') {
                navigate('./archived');
            } else {
                navigate('./..');
            }
        },
        [navigate]
    );

    return (
        <StyledSegmented
            options={[
                { label: <Segment>active</Segment>, value: 'active' },
                { label: <Segment>archived</Segment>, value: 'archived' },
            ]}
            onChange={handleStatusChange}
            {...(segmentedProps as any)}
        />
    );
};

export default StatusSegmented;
