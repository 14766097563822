import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import { validators } from '../../export/validations/validations';
import HideComponent from '../../../../../utils/HideComponent';

type Fields = 'typeCode';

export const calculationOfTaxesTaxTypeBlockValidation = {
    childValidators: {
        typeCode: [validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields> {}

const CalculationOfTaxesTaxTypeBlock = ({ path = 'commodity.dutyTaxFee', fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/3', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('calculationOfTaxesTaxType')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.dutyTaxFeeTypes}
                        condensed
                        codeValidation={[validators.exact(3)]}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CalculationOfTaxesTaxTypeBlock;
