import { Button, Col, Row, Space } from 'antd';
import { FormikProps } from 'formik';
import { EDocketFormProps, EDocketFormType } from '../eDocketTypes';
import { EDocketFormStyledComponents } from './EDocketForm.styles';
import EDocketFormSignature from './inputs/EDocketFormSignature';

const { SectionMain } = EDocketFormStyledComponents;

type Props<TFormik> = EDocketFormProps<TFormik> & {
    onResend: () => void;
};

const EDocketFormSignatures = <TFormik extends FormikProps<EDocketFormType>>({
    formik,
    onResend,
    t,
}: Props<TFormik>) => {
    return (
        <SectionMain>
            <Space size={24} direction="vertical">
                <Row>
                    <Col>
                        <EDocketFormSignature
                            label={t('signatures.operatorSignature')}
                            disabled
                            value={formik.values.signatures?.operator}
                        />
                    </Col>
                </Row>
                <Row gutter={20} style={{ alignItems: 'center' }}>
                    <Col>
                        <EDocketFormSignature
                            label={t('signatures.driverSignature')}
                            disabled
                            value={formik.values.signatures?.driver || ''}
                        />
                    </Col>
                    {!formik.values.signatures?.driver && (
                        <Col>
                            <Button type="primary" onClick={onResend}>
                                {t('signatures.resendSignOffRequest')}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Space>
        </SectionMain>
    );
};

export default EDocketFormSignatures;
