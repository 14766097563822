import { useAppDispatch, useAppSelector } from 'config/hooks';
import { useCallback } from 'react';
import {
    doPostIrelandImportDepositRefund,
    doPutIrelandImportDepositRefund,
    doSubmitIrelandImportDepositRefund,
} from 'store/declarations/ireland/refunds/deposits/action';
import { IrelandRefundDeposit } from 'store/declarations/ireland/refunds/deposits/ireland-refund-deposit';
import {
    doPostIrelandImportH7RemissionRefund,
    doPostIrelandImportRemissionRefund,
    doPutIrelandImportH7RemissionRefund,
    doPutIrelandImportRemissionRefund,
    doSubmitIrelandImportH7RemissionRefund,
    doSubmitIrelandImportRemissionRefund,
} from 'store/declarations/ireland/refunds/remission/action';
import { IrelandRefundRemission } from 'store/declarations/ireland/refunds/remission/ireland-refund-remission';

interface Props {
    declarationId: string;
}
const useDeclarationRefund = ({ declarationId }: Props) => {
    const dispatch = useAppDispatch();
    const remission = useAppSelector((state) => state.refunds.refund?.form);
    const remissionHistory = useAppSelector((state) => state.refunds.refund);
    const deposit = useAppSelector((state) => state.depositRefunds.refund?.form);
    const depositHistory = useAppSelector((state) => state.depositRefunds.refund);

    // Import (H1-H6)

    const createIrelandRemissionsRefund = useCallback(
        (remissionDeclaration: IrelandRefundRemission) => {
            return dispatch(doPostIrelandImportRemissionRefund(declarationId, remissionDeclaration));
        },
        [declarationId, dispatch]
    );

    const updateIrelandRemissionsRefund = useCallback(
        (remissionDeclaration: IrelandRefundRemission, refundId: string) => {
            return dispatch(doPutIrelandImportRemissionRefund(declarationId, remissionDeclaration, refundId));
        },
        [declarationId, dispatch]
    );

    const submitIrelandRemissionsRefund = useCallback(
        (refundId: string) => {
            return dispatch(doSubmitIrelandImportRemissionRefund(declarationId, refundId));
        },
        [declarationId, dispatch]
    );

    const createIrelandDepositRefund = useCallback(
        (remissionDeclaration: IrelandRefundDeposit) => {
            return dispatch(doPostIrelandImportDepositRefund(declarationId, remissionDeclaration));
        },
        [declarationId, dispatch]
    );

    const updateIrelandDepositRefund = useCallback(
        (remissionDeclaration: IrelandRefundDeposit, refundId: string) => {
            return dispatch(doPutIrelandImportDepositRefund(declarationId, remissionDeclaration, refundId));
        },
        [declarationId, dispatch]
    );

    const submitIrelandDepositRefund = useCallback(
        (refundId: string) => {
            return dispatch(doSubmitIrelandImportDepositRefund(declarationId, refundId));
        },
        [declarationId, dispatch]
    );

    // Import (H7)

    const createIrelandImportH7RemissionsRefund = useCallback(
        (remissionDeclaration: IrelandRefundRemission) => {
            return dispatch(doPostIrelandImportH7RemissionRefund(declarationId, remissionDeclaration));
        },
        [declarationId, dispatch]
    );

    const updateIrelandImportH7RemissionsRefund = useCallback(
        (remissionDeclaration: IrelandRefundRemission, refundId: string) => {
            return dispatch(doPutIrelandImportH7RemissionRefund(declarationId, remissionDeclaration, refundId));
        },
        [declarationId, dispatch]
    );

    const submitIrelandImportH7RemissionsRefund = useCallback(
        (refundId: string) => {
            return dispatch(doSubmitIrelandImportH7RemissionRefund(declarationId, refundId));
        },
        [declarationId, dispatch]
    );

    return {
        createIrelandRemissionsRefund,
        updateIrelandRemissionsRefund,
        submitIrelandRemissionsRefund,
        submitIrelandDepositRefund,
        updateIrelandDepositRefund,
        createIrelandDepositRefund,
        createIrelandImportH7RemissionsRefund,
        updateIrelandImportH7RemissionsRefund,
        submitIrelandImportH7RemissionsRefund,
        remission,
        remissionHistory,
        deposit,
        depositHistory,
    };
};
export default useDeclarationRefund;
