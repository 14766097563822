import { ErrorResponse } from "core/http/response";
import { Reducer } from "redux";
import { CommodityCodeTaxesActions } from './actionType';
import { CommodityCodeTaxes } from "./CommodityCodeTaxes";

export interface CommodityCodeTaxesState {
    isLoading: boolean;
    error?: ErrorResponse;
    commodityCodeTaxes?: CommodityCodeTaxes;
}

export const commodityCodeTaxesInitialState: Readonly<CommodityCodeTaxesState> = {
    isLoading: false,
};

const commodityCodeTaxes: Reducer<CommodityCodeTaxesState, CommodityCodeTaxesActions> = (
    state = commodityCodeTaxesInitialState,
    action
): CommodityCodeTaxesState => {
    switch (action.type) {
        case 'GET_COMMODITY_TAXES':
            return {
                ...state,
                isLoading: true,
            }
        case 'GET_COMMODITY_TAXES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                commodityCodeTaxes: action.payload,
            }
        case 'GET_COMMODITY_TAXES_ERROR':
            return {
                ...state,
                isLoading: false,
                commodityCodeTaxes: state.commodityCodeTaxes,
                error: action.error,
            }
        default: return state;
    }
}
export default commodityCodeTaxes;