import { getCountries } from './client';

export const doGetCountries: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_COUNTRIES_REQUEST' });
    try {
        const payload = await getCountries();

        dispatch({ type: 'GET_COUNTRIES_SUCCESS', payload: payload });
    } catch (e: any) {
        dispatch({ type: 'GET_COUNTRIES_ERROR', error: e?.response?.data });
    }
};
