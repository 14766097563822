import { PaginatedParams } from 'core/http/pagination';
import { Route } from '../routes/route';

export interface Leg {
    id: string;
    status: LegStatus;
    journeyId: string;
    driverId: string;
    transportType: TransportType;
    vehicleId: string;
    startDateTime: string;
    endDateTime: string;
    description: string;
    specialInstructions: string;
    previousLegId: string;
    nextLegId: string;
    isFirstLeg: boolean;
    isLastLeg: boolean;
    originId?: string;
    destinationId?: string;
    origin?: Route;
    destination?: Route;
}

export enum LegStatus {
    UNASSIGNED = 'UNASSIGNED',
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export enum TransportType {
    AIR = 'AIR',
    SEA = 'SEA',
    ROAD = 'ROAD',
    RAIL = 'RAIL',
    PIPE = 'PIPE',
}

export interface LegParams extends PaginatedParams {}
