import { removeEmptyObjectsFromArray } from 'core/utils/objects';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikProps, isString } from 'formik';
import { capitalize, fromPairs, isArray, isEmpty, isFunction, isObject, isPlainObject, set } from 'lodash';
import { Customer } from 'store/customers/customer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { MetaData } from 'store/template/template';
import { CardState } from '../common/cards/card-state';

export const appendFieldPath = (fieldPath?: string) => (fieldName: string) =>
    fieldPath ? `${fieldPath}.${fieldName}` : fieldName;

export const initializeMultipleCards = (formik: FormikProps<any>, path: string) => {
    const array = formik.getFieldProps(path).value ?? [];
    if (array?.length === 0) {
        array.push({});
    }
    return array;
};

export const addCardToMultipleCards = (formik: FormikProps<any>, path: string, array: any[]) => {
    const copy = [...array];
    copy.push({});
    formik.setFieldValue(path, copy);
};

export const deleteCardToMultipleCards = (
    formik: FormikProps<any>,
    path: string,
    array: any[],
    indexToDelete: number
) => {
    const copy = [...array];
    copy.splice(indexToDelete, 1);
    formik.setFieldValue(path, copy);
};

export const getFormikProps = (
    path: string,
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        getFieldMeta?: (name: string) => FieldMetaProps<any>;
        getFieldHelpers: (name: string) => FieldHelperProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const fullFieldPath = appendFieldPath(props.propsPathPrefix);
    return {
        fieldProps: props.getFieldProps && props.getFieldProps(fullFieldPath(path)),
        fieldMeta: props.getFieldMeta && props.getFieldMeta(fullFieldPath(path)),
        fieldHelper: props.getFieldHelpers && props.getFieldHelpers(fullFieldPath(path)),
    };
};

export const getAddressFormikProps = (
    address: { streetAndNumber: string; country: string; postCode: string; city: string },
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        getFieldMeta?: (name: string) => FieldMetaProps<any>;
        getFieldHelpers?: (name: string) => FieldHelperProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const fullFieldPath = appendFieldPath(props.propsPathPrefix);
    return {
        fieldProps: {
            streetAndNumber: props.getFieldProps && props.getFieldProps(fullFieldPath(address.streetAndNumber)),
            country: props.getFieldProps && props.getFieldProps(fullFieldPath(address.country)),
            city: props.getFieldProps && props.getFieldProps(fullFieldPath(address.city)),
            postCode: props.getFieldProps && props.getFieldProps(fullFieldPath(address.postCode)),
        },
        fieldMeta: {
            streetAndNumber: props.getFieldMeta!(fullFieldPath(address.streetAndNumber)),
            country: props.getFieldMeta!(fullFieldPath(address.country)),
            city: props.getFieldMeta!(fullFieldPath(address.city)),
            postCode: props.getFieldMeta!(fullFieldPath(address.postCode)),
        },
        fieldHelper: {
            streetAndNumber: props.getFieldHelpers!(fullFieldPath(address.streetAndNumber)),
            country: props.getFieldHelpers!(fullFieldPath(address.country)),
            city: props.getFieldHelpers!(fullFieldPath(address.city)),
            postCode: props.getFieldHelpers!(fullFieldPath(address.postCode)),
        },
    };
};

const hasErrors = (
    path: string,
    props: {
        getFieldMeta?: (name: string) => FieldMetaProps<any>;
        propsPathPrefix?: string;
    }
) => {
    if (props.getFieldMeta) {
        const fullFieldPath = appendFieldPath(props.propsPathPrefix);
        return !!props.getFieldMeta(fullFieldPath(path)).error && !!props.getFieldMeta(fullFieldPath(path)).touched;
    }
    return false;
};

const hasEmpty = (
    path: string,
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const fullFieldPath = appendFieldPath(props.propsPathPrefix);
    return !!!props.getFieldProps(fullFieldPath(path)).value;
};

const getFieldMetaErrors = (
    paths: string[],
    props: {
        getFieldMeta?: (name: string) => FieldMetaProps<any>;
        propsPathPrefix?: string;
    }
) => {
    if (props.getFieldMeta) {
        const arr = paths?.map((path) => hasErrors(path, props));
        return arr?.includes(true) ? true : false;
    }
    return false;
};
const getFieldPropsEmpties = (
    paths: { name: string; required?: boolean }[],
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const arr = paths?.map((path) => hasEmpty(path.name, props));
    return arr?.every((elem) => !!elem);
};

const getFieldRequiredPropsEmpties = (
    paths: { name: string; required?: boolean }[],
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const arr = paths?.filter((path) => path.required && hasEmpty(path.name, props));
    return arr?.length > 0;
};

export const getCardState = (
    paths: { name: string; required?: boolean }[] | undefined,
    props: {
        getFieldProps: (name: string) => FieldInputProps<any>;
        getFieldMeta?: (name: string) => FieldMetaProps<any>;
        propsPathPrefix?: string;
    }
) => {
    const pathsName = paths?.map((obj) => obj.name);
    const error = getFieldMetaErrors(pathsName!, props);

    if (error) return CardState.INVALID;

    const hasEmpty = getFieldPropsEmpties(paths!, props);
    const hasRequiredEmpty = getFieldRequiredPropsEmpties(paths!, props);

    if (hasEmpty || hasRequiredEmpty) return CardState.EMPTY;

    return CardState.VALID;
};

interface FillCustomerData {
    template?: boolean;
    customerMeta?: Record<string, MetaData> | null;
}

interface PartyInfo {
    name?: string;
    streetAndNumber?: string;
    country?: string;
    postCode?: string;
    city?: string;
    eori?: string;
    identifier?: string;
}

interface CanFillPartyArgs {
    party: {
        path: string;
        meta: Record<string, MetaData> | null;
        info?: PartyInfo;
    };
    inTemplateForm?: boolean;
}
export const canFillParty = ({ party, inTemplateForm }: CanFillPartyArgs) => {
    party.info = Object.assign(
        {
            name: 'name',
            city: 'city',
            country: 'country',
            eori: 'eori',
            identifier: 'identifier',
            postCode: 'postCode',
            streetAndNumber: 'streetAndNumber',
        },
        party.info
    );

    const canFillInfo = (infoPath: string) => {
        const infoMeta = party.meta?.[infoPath];
        const editable = infoMeta?.isEditable;
        const viewable = infoMeta?.isViewable;
        if (!infoMeta) return true;
        if (inTemplateForm) return editable || viewable;
        return editable;
    };

    return Object.values(party.info).some((info) => canFillInfo(info));
};

export const fillCustomerFromListOfCustomers = (
    customer: Customer,
    path: string,
    setShowCustomers: Function,
    getFieldHelpers?: (name: string) => FieldHelperProps<any>,
    namesArg?: PartyInfo,
    data?: FillCustomerData,
    preferredFill?: 'eori' | 'address'
) => {
    const names = Object.assign(
        {
            name: 'name',
            city: 'city',
            country: 'country',
            eori: 'eori',
            identifier: 'identifier',
            postCode: 'postCode',
            streetAndNumber: 'streetAndNumber',
        } as NonNullable<typeof namesArg>,
        namesArg
    );

    const flags = {
        filledEori: false,
        filledAddress: false,
    };

    const canFillInfo = (infoPath: string) => {
        infoPath = data?.template ? infoPath.split('.').splice(2).join('.') : infoPath;
        const infoMeta = data?.customerMeta?.[infoPath];
        const editable = infoMeta?.isEditable;
        const viewable = infoMeta?.isViewable;
        if (!infoMeta) return true;
        if (data.template) return editable || viewable;
        return editable;
    };

    const addressFillable =
        canFillInfo(`${path}.${names.name}`) ||
        canFillInfo(`${path}.${names.streetAndNumber}`) ||
        canFillInfo(`${path}.${names.country}`) ||
        canFillInfo(`${path}.${names.postCode}`) ||
        canFillInfo(`${path}.${names.city}`);

    const setEori = (customer: Customer | null) => {
        let info = {};
        if (canFillInfo(`${path}.${names.eori}`) && names.eori) {
            info = set(info, names.eori, customer?.eori);
        }
        if (canFillInfo(`${path}.${names.identifier}`) && names.identifier) {
            info = set(info, names.identifier, customer?.eori);
        }
        getFieldHelpers!(path).setValue(info);
        getFieldHelpers!(path).setTouched(true);
    };
    const setAddress = (customer: Customer | null) => {
        let info = {};
        if (canFillInfo(`${path}.${names.name}`) && names.name) {
            info = set(info, names.name, customer?.name);
        }
        if (canFillInfo(`${path}.${names.streetAndNumber}`) && names.streetAndNumber) {
            info = set(info, names.streetAndNumber, customer?.address?.addressLine1);
        }
        if (canFillInfo(`${path}.${names.country}`) && names.country) {
            info = set(info, names.country, customer?.address?.country);
        }
        if (canFillInfo(`${path}.${names.postCode}`) && names.postCode) {
            info = set(info, names.postCode, customer?.address?.postCode);
        }
        if (canFillInfo(`${path}.${names.city}`) && names.city) {
            info = set(info, names.city, customer?.address?.city);
        }

        getFieldHelpers!(path).setValue(info);
        getFieldHelpers!(path).setTouched(true);
    };

    const tryFillEori = () => {
        if (!canFillInfo(`${path}.${names.eori}`) || !customer.eori) return false;
        setEori(customer);
        flags.filledEori = true;
        return true;
    };
    const tryFillAddress = () => {
        if (!addressFillable || !names.streetAndNumber) return false;
        setAddress(customer);
        flags.filledAddress = true;
        return true;
    };

    if (!preferredFill) {
        if (!tryFillEori()) {
            tryFillAddress();
        }
    } else {
        // Try preferred fill
        if (preferredFill === 'eori') tryFillEori();
        else if (preferredFill === 'address') tryFillAddress();

        // If preferred fill didn't succeed try the other fill
        if (preferredFill === 'address' && !flags.filledAddress) tryFillEori();
        else if (preferredFill === 'eori' && !flags.filledEori) tryFillAddress();
    }

    setShowCustomers(undefined);

    return flags;
};

export const handleToggleHelp = (
    refNumber: string | number,
    props: { toggleHelp?: (refNumber: string | number) => void }
): void => {
    props.toggleHelp && props.toggleHelp(refNumber);
};

export const removeEmptyObjectsFromDeclarationArrays = <TType extends Record<string, any>>(
    declaration: TType | undefined
) => {
    if (declaration === undefined) return {} as TType;

    const keys = Object.keys(declaration);
    const values = Object.values(declaration);
    const newValues = values.map((obj) => {
        if (Array.isArray(obj)) {
            return [...removeEmptyObjectsFromArray(obj)];
        } else {
            if (isString(obj) || Number.isFinite(obj) || isBoolean(obj) || !obj) {
                return obj;
            } else {
                const k = Object.keys(obj);
                const v = Object.values(obj);
                const newV = v.map((o) => {
                    return Array.isArray(o) ? [...removeEmptyObjectsFromArray(o)] : o;
                });
                return Object.fromEntries(k.map((_, i) => [k[i], newV[i]]));
            }
        }
    });
    return Object.fromEntries(keys.map((_, i) => [keys[i], newValues[i]])) as TType;
};

export const removeObjectEmptyValuesFromArray = <TData extends Record<string, any>>(data: TData) => {
    if (!data) return {};
    const newData = { ...(data as Record<string, any>) };
    Object.entries(data).forEach(([key, value]) => {
        if (isArray(value)) {
            const newValue = value.reduce((acc, v) => {
                if (!isObject(v)) {
                    acc.push(v);
                    return acc;
                } else {
                    if (Object.values(v).some((ov) => ov)) acc.push(v);
                    return acc;
                }
            }, []);
            newData[key] = newValue;
        } else if (isObject(value)) {
            removeObjectEmptyValuesFromArray(value);
        }
    });
    return newData;
};

export enum FormAction {
    DRAFT = 'draft',
    SUBMIT = 'submit',
    PRODUCT = 'product',
}

const removeIdFromObject = (obj: any) => {
    if (obj && obj.id) {
        obj.id = undefined;
    }
    return obj;
};

export const removeIdFromArrayObject = (arr: any[]) => {
    return arr.map((obj, index) => {
        const keys = Object.keys(obj);
        if (keys[index] === 'id' && isString(obj)) {
            return undefined;
        } else {
            const k = Object.keys(obj);
            const v = Object.values(obj);
            const newV: any = v.map((o, i) => {
                if (k[i] === 'id' && isString(o)) {
                    return undefined;
                } else {
                    if (Array.isArray(o)) {
                        return [...removeIdFromArrayObject(o)];
                    } else {
                        if (k[i] === 'id' && isString(o)) {
                            return undefined;
                        } else {
                            return removeIdFromObject(o);
                        }
                    }
                }
            });
            return Object.fromEntries(k.map((_, i) => [k[i], newV[i]]));
        }
    });
};

const isBoolean = (val: any) => !!val === val;

export const removeEmptyObjects = (o: any): any | any[] => {
    if (isFunction(o) || !isPlainObject(o)) return o;

    if (isArray(o)) return o.map((item) => removeEmptyObjects(item));

    return fromPairs(
        Object.entries(o)
            .map(([k, v]) => [k, removeEmptyObjects(v)])
            .filter(([k, v]) => !(v == null || (isPlainObject(v) && isEmpty(v))))
    );
};

export const declarationHasBeenSubmitted = (status: DeclarationStatus) => {
    return (
        status === DeclarationStatus.SUBMITTED ||
        status === DeclarationStatus.ACCEPTED ||
        status === DeclarationStatus.REGISTERED ||
        status === DeclarationStatus.RISKED ||
        status === DeclarationStatus.UNDER_CONTROL
    );
};

export const getFormInitialValuesByDeclarationType = (declaration: Declaration) => {
    if (declaration?.irelandImportDeclaration) {
        return declaration.irelandImportDeclaration;
    }

    if (declaration?.irelandH7ImportDeclaration) {
        return declaration.irelandH7ImportDeclaration;
    }

    if (declaration?.ieExportDeclaration) {
        return declaration.ieExportDeclaration;
    }

    if (declaration?.entrySummaryDeclaration) {
        return declaration.entrySummaryDeclaration;
    }

    if (declaration?.cdsExportDeclaration) {
        return declaration.cdsExportDeclaration;
    }

    return {};
};

export const getProductsOfDeclaration = (declaration: Declaration) => {
    if (declaration?.irelandImportDeclaration) {
        return declaration.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem || [];
    }

    if (declaration?.irelandH7ImportDeclaration) {
        return declaration.irelandH7ImportDeclaration?.goodsShipment?.governmentAgencyGoodsItem || [];
    }

    if (declaration?.ieExportDeclaration) {
        return declaration.ieExportDeclaration?.goodsShipment?.goodsItem || [];
    }

    if (declaration?.entrySummaryDeclaration) {
        return declaration.entrySummaryDeclaration.goodsShipment?.goodsItems || [];
    }

    if (declaration?.cdsExportDeclaration) {
        return (
            (declaration.cdsExportDeclaration.cdsExportGovernmentAgencyGoodsItems
                ?.map(({ governmentAgencyGoodsItem }) => governmentAgencyGoodsItem)
                .filter((product) => product !== null) as CdsExportGovernmentAgencyGoodsItem[]) || []
        );
    }

    return [];
};

export const clearFlash = () => {
    document.querySelector('.flashBackground')?.classList.remove('flashBackground');
};
export const doFlash = (element: HTMLElement | null | undefined, msDelay: number = 0) => {
    const _doFlash = () => {
        element?.classList.add('flashBackground');
    };

    if (msDelay) {
        setTimeout(_doFlash, msDelay);
    } else {
        _doFlash();
    }
};

export const goToDeclarationField = (fieldName: string, msDelay: number = 0) => {
    const _goToDeclarationField = () => {
        clearFlash();

        if (!fieldName) return;

        // Try to take the input from the field name
        let field = document.querySelector(`[name="${fieldName}"]`) as HTMLElement | null | undefined;
        let isField = true;

        // Try to take the card that the field is located at
        if (!field) {
            field = document.querySelector(`[id*="${fieldName}"]`) as HTMLElement | null | undefined;
            isField = false;
        }

        // Try customer
        if (!field) {
            const customer = capitalize(fieldName.split('.').at(-1));
            field = document.querySelector(`[id*="${customer}"]`) as HTMLElement | null | undefined;
            isField = false;
        }

        if (!field) {
            const parentPath = fieldName.split('.').slice(0, -1).join('.');
            goToDeclarationField(parentPath);
            return;
        }

        const card = field?.closest('[id*="card"]') as HTMLElement | null | undefined;
        const cardBody = card?.querySelector('.ant-card-body') as HTMLElement | null | undefined;

        setTimeout(() => {
            const offset = (card?.getBoundingClientRect()?.top ?? 0) - (field?.getBoundingClientRect()?.top ?? 0);
            card?.offsetParent?.scrollTo({
                top: card.offsetTop - offset - 30,
            });
        }, 200);

        if (isField && field?.tagName !== 'INPUT') {
            doFlash(field?.querySelector('input'), 300);
        } else if (!isField) {
            doFlash(cardBody, 300);
        } else {
            doFlash(field, 300);
        }
    };

    if (msDelay) {
        setTimeout(_goToDeclarationField, msDelay);
    } else {
        _goToDeclarationField();
    }
};

export const toPlaceholder = (label: string | undefined, before?: string) => {
    if (!label) return undefined;
    return `${before ?? 'Enter'} ${label}`;
};

export const getAfterFirstNumber = (path: string | undefined) => {
    return path?.match(/(\.\d+\.)(.*)/g)?.[2];
};

export const trimNumbers = (path: string | undefined) => {
    return path?.replaceAll(/\.\d+/g, '');
};

export const hasNumbers = (path: string | undefined) => {
    return path?.match(/\.\d+/g)?.length;
};

export const trimWhiteSpaces = <TData extends string | number | Object | Array<any> | null | undefined>(
    data: TData,
    options?: {
        emptyStringToNull?: boolean;
    }
): TData => {
    if (typeof data === 'string') {
        const trimmed = data.trim() as TData;
        if (options?.emptyStringToNull && trimmed === '') return null as TData;
        return trimmed;
    } else if (Array.isArray(data)) {
        return data.map((v) => trimWhiteSpaces(v, options)) as TData;
    } else if (isObject(data)) {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, trimWhiteSpaces(value, options)])
        ) as TData;
    } else {
        return data;
    }
};
