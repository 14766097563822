import { deleteLeg, getLeg, listLegs, postLeg, putLeg } from './client';
import { Leg, LegParams } from './leg';

export const doCreateLeg: Function = (journeyId: string, leg: Leg) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_LEG_REQUEST' });
    try {
        const payload = await postLeg(journeyId, leg);

        dispatch({
            type: 'CREATE_LEG_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_LEG_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doListLegs: Function = (journeyId: string, params?: Partial<LegParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_LEG_REQUEST' });
    try {
        const payload = await listLegs(journeyId, params);
        dispatch({
            type: 'LIST_LEG_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_LEG_ERROR', error: e?.response?.data });
    }
};

export const doGetLeg: Function = (journeyId: string, legId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_LEG_REQUEST' });
    try {
        const payload = await getLeg(journeyId, legId);
        dispatch({
            type: 'GET_LEG_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_LEG_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditLeg: Function = (journeyId: string, leg: Leg) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_LEG_REQUEST' });
    try {
        const payload = await putLeg(journeyId, leg);
        dispatch({
            type: 'EDIT_LEG_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_LEG_ERROR', error: e?.response?.data });
    }
};

export const doDeleteLeg: Function = (journeyId: string, legId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_JOURNEY_REQUEST' });
    try {
        const payload = await deleteLeg(journeyId, legId);
        dispatch({
            type: 'DELETE_JOURNEY_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_JOURNEY_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
