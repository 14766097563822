import { FC } from 'react';
import { FormHeaderH1 } from './Title.styles';

interface Props {
    children: React.ReactNode;
}

const Title: FC<Props> = ({ children }) => {
    return <FormHeaderH1>{children}</FormHeaderH1>;
};

export default Title;
