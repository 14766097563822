import SearchCustomerButton from './SearchCustomerButton';
import SearchCustomerDrawer from './SearchCustomerDrawer';
import SearchCustomerInputWrapper from './SearchCustomerInputWrapper';
import SearchCustomerWithInputWrapper from './SearchCustomerWithInputWrapper';

const SearchCustomer = {
    Button: SearchCustomerButton,
    Drawer: SearchCustomerDrawer,
    WithInputWrapper: SearchCustomerWithInputWrapper,
    InputWrapper: SearchCustomerInputWrapper,
};

export default SearchCustomer;
