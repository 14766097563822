import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'id';

export type SupervisingCustomsOfficeBlockValidation = ValidationSchema<Fields>;
export const supervisingCustomsOfficeBlockValidation: SupervisingCustomsOfficeBlockValidation = {
    childValidators: {
        id: [validators.exact(8)],
    },
};

export const supervisingCustomsOfficeBlockRequiredValidation: SupervisingCustomsOfficeBlockValidation = {
    childValidators: {
        id: [validators.required(), validators.exact(8)],
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const SupervisingCustomsOfficeBlock = ({ path = 'supervisingOffice', declarationOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '5/27', []);
    return (
        <DeclarationField pathPrefix={path} name="id">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    required={declarationOptions?.[formType]?.fieldOptions?.id?.required}
                    refNumber={ucc}
                    label={t('suppervisingCustomsOffice')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={cdsCodelists?.supervisingCustomsOffices}
                    condensed
                    codeValidation={[validators.exact(8)]}
                />
            )}
        </DeclarationField>
    );
};

export default SupervisingCustomsOfficeBlock;
