import DDatePicker from 'components/ui/base/datepicker/Datepicker';
import styled from 'styled-components';

export const StyledDatepicker = styled(DDatePicker)`
    width: 100%;
`;

export const DatePickerDiv = styled.div`
    width: 100%;
`;
