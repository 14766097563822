import { CheckCircleFilled } from '@ant-design/icons';
import AntFormItem from 'antd/lib/form/FormItem';
import { BoxNumber } from 'components/ui/base/box-number/box-number.styles';
import Input from 'components/ui/base/input/Input';
import NumberInput from 'components/ui/base/input/NumberInput';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

interface ErrorProp {
    error: boolean;
    touched: boolean;
    condensed?: boolean;
}

export const LabelDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
`;

export const RefDiv = styled(BoxNumber)`
    margin-left: 0.8rem;
`;

export const InputLabel = styled.label`
    color: ${colors.darkBlack};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
`;

export const InputLabelRequired = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-left: 0.8rem;
`;

export const ErrorLabel = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
`;

export const SuccessLabel = styled.label`
    color: ${colors.success};
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
`;

export const StyledInput = styled(Input).withConfig({
    shouldForwardProp: (prop) => !['showSuccess'].includes(prop),
})<{ showSuccess: boolean; condensed?: boolean }>`
    width: 100%;
    border-radius: ${({ condensed }) => (condensed ? '4px' : '2px')};
    border: ${({ condensed }) => (condensed ? '1px solid #cecece' : 'hsv(0, 0, 85%)')};
`;

export const FormItem = styled(AntFormItem)`
    width: 100%;
    margin-bottom: 0;
`;

export const StyledNumberInput = styled(NumberInput)`
    height: 4rem;
    width: 100%;
`;

export const InputDiv = styled.div`
    width: 100%;
    display: inline-flex;
`;

export const ErrorDiv = styled.div<ErrorProp>`
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: ${({ error, touched, condensed }) => (error && touched ? '0rem' : condensed ? '0' : '2.2rem')};
`;

export const SuccessDiv = styled.div`
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 2.4rem;
`;

export const SuccessIcon = styled(CheckCircleFilled)`
    color: ${colors.success};
`;
