import { Button, Drawer } from 'antd';
import { H5 } from 'components/ui/base/typography';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import TemplateModal from 'components/ui/composed/template/TemplateModal';
import { setNestedObjectValues, useFormik } from 'formik';
import { find, isEmpty } from 'lodash';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import createTemplateInitialValidation from '../validations/createTemplateInitialValidation';
import getAllDeclarationTemplateNames from 'utils/requests/getAllDeclarationTemplateNames';

interface FormData {
    country?: string;
    declarationType?: string;
    formType?: string;
    name?: string;
}

const Container = styled.div`
    margin-top: 2rem;
    > div:nth-child(even) {
        margin-bottom: 1rem;
    }
`;

interface Props {
    visible?: boolean;
    onClose: () => void;
}

const templateFormValues = {
    IRELAND: {
        IMPORT: ['H1', 'H7'],
        EXPORT: ['B1', 'B2', 'B3', 'B4', 'C1', 'C2'],
        ENS: ['ENS'],
    },
    UK: {
        EXPORT: ['B1'],
    },
};

const CreateTemplateDrawer = ({ visible, onClose }: Props): ReactElement => {
    const { openModal } = useTemplateContext();

    const [templateNames, setTemplateNames] = useState<Set<string> | undefined>(undefined);

    const formik = useFormik<FormData>({
        initialValues: {},
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: () => {},
        validationSchema: createTemplateInitialValidation(templateNames),
    });

    useEffect(() => {
        if (!formik.values.formType) return;
        getAllDeclarationTemplateNames({ templateData: formik.values as any }).then(setTemplateNames);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.formType]);

    const handleCancel = () => {
        onClose();
        formik.resetForm();
    };

    const handleCreate = async () => {
        const valid = await formik.validateForm();
        if (!isEmpty(valid)) {
            formik.setTouched(setNestedObjectValues(valid, true));
            return;
        }
        handleCancel();
        openModal?.(formik.values as any);
    };

    const declarationTypeBasedOnCountry = useMemo(
        () => templateFormValues[getFormikProps('country', formik).fieldProps.value as 'IRELAND' | 'UK'],
        [formik]
    );

    const formTypeBasedOnDeclarationType = useMemo(
        () =>
            declarationTypeBasedOnCountry?.[
                getFormikProps('declarationType', formik).fieldProps.value as keyof typeof declarationTypeBasedOnCountry
            ] as string[] | undefined,
        [formik, declarationTypeBasedOnCountry]
    );

    const getDeclarationTypeCodelist = useMemo(() => {
        if (!declarationTypeBasedOnCountry) return;

        return Object.keys(declarationTypeBasedOnCountry).map((key) => ({
            id: key,
            value: '',
        }));
    }, [declarationTypeBasedOnCountry]);

    const getFormTypeCodelist = useMemo(() => {
        if (!(declarationTypeBasedOnCountry || formTypeBasedOnDeclarationType)) return;

        return formTypeBasedOnDeclarationType?.map((code) => ({ id: code, value: '' }));
    }, [declarationTypeBasedOnCountry, formTypeBasedOnDeclarationType]);

    useEffect(() => {
        const declarationType = getFormikProps('declarationType', formik).fieldProps.value;

        if (!find(getDeclarationTypeCodelist, (code: any) => code.id === declarationType)) {
            const declarationTypeField = formik.getFieldHelpers('declarationType');
            const formTypeField = formik.getFieldHelpers('formType');

            declarationTypeField.setValue(null);
            declarationTypeField.setTouched(false);

            formTypeField.setValue(null);
            formTypeField.setTouched(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values]);

    return (
        <Drawer visible={visible} onClose={onClose} placement="right">
            <H5>Create New Template</H5>
            <Container>
                <DeclarationSelect
                    required
                    selectOptions={[
                        { id: 'IRELAND', value: '' },
                        { id: 'UK', value: '' },
                    ]}
                    {...getFormikProps(`country`, formik)}
                    condensed
                    label="Country"
                    hideCodelistMenu
                />
                <DeclarationSelect
                    required
                    selectOptions={getDeclarationTypeCodelist}
                    {...getFormikProps(`declarationType`, formik)}
                    condensed
                    label="Declaration Type"
                    hideCodelistMenu
                />
                <DeclarationSelect
                    required
                    selectOptions={getFormTypeCodelist}
                    {...getFormikProps(`formType`, formik)}
                    condensed
                    label="Form Type"
                    hideCodelistMenu
                />
                <DeclarationInput required {...getFormikProps(`name`, formik)} condensed label="Template Name" />
            </Container>

            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" onClick={handleCreate}>
                    Create
                </Button>
            </div>

            <TemplateModal creating />
        </Drawer>
    );
};

export default CreateTemplateDrawer;
