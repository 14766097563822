import { NotificationProps } from '../../components/ui/base/notification/Notification';

export const doSetNotification: Function = (notification: NotificationProps) => (dispatch: Function) => {
    dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
    });
};

export const doClearNotification: Function = () => (dispatch: Function) => {
    dispatch({ type: 'CLEAR_NOTIFICATION' });
};
