import Button from 'components/ui/base/button/Button';
import styled from 'styled-components';
import { fonts } from 'theme';

export const StyledMobileLegView = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledLegDetailsTabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    button {
        font-size: 1.6rem;
        font-weight: ${fonts.weight.bold};
    }
`;

export const SyledLegActionButton = styled(Button)`
    width: 100%;
`;
