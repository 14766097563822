import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { Text, TextSmallBold } from '../typography';
import Tooltip from './Tooltip';

export interface IconTooltipProps {
    title?: string;
    tooltip?: string;
    icon?: ReactNode;
    tooltipClicked?: () => void;
}

const Content = styled.div`
    color: ${colors.black};
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
`;

const StyledTitle = styled(TextSmallBold)`
    margin-bottom: 1.6rem;
`;

const StyledText = styled(Text)`
    white-space: pre-wrap;
`;

const TooltipContent = (label?: string, text?: string) => {
    return (
        <Content>
            <StyledTitle>{label}</StyledTitle>
            <StyledText>{text}</StyledText>
        </Content>
    );
};

const IconTooltip: FC<IconTooltipProps> = ({ title, tooltip, icon, tooltipClicked }) => (
    <Tooltip
        color={colors.white}
        placement="rightTop"
        overlay={TooltipContent(title, tooltip)}
        handleTooltipClick={tooltipClicked}
    >
        {icon}
    </Tooltip>
);

export default IconTooltip;
