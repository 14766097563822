import { CarOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const DetailsRow = styled.div`
    display: flex;
    margin-top: 0.8rem;
`;

export const StatusLabel = styled(TextSmallBold)`
    margin-right: 2.8rem;
`;

export const RowTitle = styled(Row)`
    margin-bottom: 2.8rem;
`;

export const RowLegs = styled.div`
    margin-top: 2.8rem;
`;

export const EmptyRouteDiv = styled.div`
    background: ${colors.lightGrey2};
    color: ${colors.lightGrey3};
    height: 15rem;
    margin: 1rem;
    cursor: pointer;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
`;

export const CarIconDiv = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 80%;
`;

export const TextCarIconDiv = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const IconCard = styled(CarOutlined)`
    font-size: 70pt;
    color: white;
`;
