import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WCOUsersStyledComponents from '../styles/WCOUsers.styles';
import { FirstAdminOutletContext, useWCOOutletContext } from '../WCO';
import { PlusOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import useFormikWCOUsers, { FormikWCOUsersProps } from '../formik/useFormikWCOUsers';
import { useTranslation } from 'react-i18next';
import useIndividuals from 'hooks/useIndividuals';
import { IndividualType } from 'store/individuals/individual';
import DeleteUserButton from '../components/DeleteUserButton';
import useRequest from 'hooks/useRequest';
import { deleteIndividual } from 'store/individuals/client';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';
import RadioCard from 'components/ui/composed/RadioCard';
import Notification from 'components/ui/base/notification/Notification';
import useSetup from 'hooks/useSetup';

const { DraggerContainer } = WCOUsersStyledComponents;
const { Main, Container, ScreenTitle, ButtonContainer, ScreenTitleSubtitleContainer } = SetupStyledComponents;

const Wrapper = ({ inWCO, children }: { inWCO: boolean; children: ReactNode }) => {
    return inWCO ? (
        <div>{children}</div>
    ) : (
        <Main>
            <Container>{children}</Container>
        </Main>
    );
};

type Props = {
    isFirstAdmin?: boolean;
};

const WCOUsers = ({ isFirstAdmin }: Props) => {
    const { t } = useTranslation('weighbridge');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const navigate = useNavigate();

    // Getting user for editing
    const { userId } = useParams() as { userId: string | undefined };
    const { individual } = useIndividuals({ individualId: userId });

    const isEditing: boolean = useMemo(() => userId != null, [userId]);

    const { isInSetupProcess } = useSetup();

    const { doRequest: doDeleteIndividual } = useRequest(deleteIndividual);

    const {
        formik: outletFormik,
        handleRegisterCompany = () => {},
        locations: outletLocations,
    } = useWCOOutletContext<FirstAdminOutletContext>() ?? {};

    const localFormikProps: FormikWCOUsersProps = useMemo(
        () => ({
            // TODO Map to the right information when the user has it (image & locations)
            initialValues: individual && {
                email: individual.email,
                firstName: individual.fullName.split(' ')[0],
                lastName: individual.fullName.split(' ')[0],
                password: '',
                confirmPassword: '',
                image: null,
                locations: [],
                phoneCode: individual.phoneCountryCode || '',
                phoneNumber: individual.phone,
                role: individual.type === IndividualType.BROKER_ADMIN ? 'admin' : 'weighbridgeOperator',
            },
            validationSchemaProps: {
                isEditing,
            },
        }),
        [individual, isEditing]
    );
    const localFormik = useFormikWCOUsers(localFormikProps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const formik = useMemo(() => outletFormik ?? localFormik, [outletFormik, localFormik]);
    const inWCO = useMemo(() => !!outletFormik, [outletFormik]);

    // TODO Get the right locations when coming after WCO (fetch locations from the company)
    const locations = useMemo(() => outletLocations ?? [], [outletLocations]);

    const createUser = async () => {
        // TODO Create user here
        // TODO Show Notification
    };

    const handleCreateUserAndClose = async () => {
        await createUser();
        navigate(-1);
    };

    const handleCreateUserAndAnother = async () => {
        await createUser();
        formik.resetForm();
    };

    const handleDeleteUser = async () => {
        if (!userId) {
            return;
        }

        const response = await doDeleteIndividual(userId);
        if (response) {
            Notification({
                type: 'success',
                messageTitle: t('notifications.user.delete.success.title'),
                description: t('notifications.user.delete.success.description'),
            });
        } else {
            Notification({
                type: 'error',
                messageTitle: t('notifications.user.delete.error.title'),
                description: t('notifications.user.delete.error.description'),
            });
        }
    };

    // Setting the route in the header
    useEffect(() => {
        if (isFirstAdmin) {
            setBreadcrumbRoutes([
                { breadcrumbName: t('breadcrumbs.setup'), path: '' },
                { breadcrumbName: t('breadcrumbs.companyRegistration'), path: '' },
                { breadcrumbName: t('breadcrumbs.users/s'), path: '' },
            ]);
        } else {
            setBreadcrumbRoutes([
                { breadcrumbName: t('breadcrumbs.setup'), path: '' },
                { breadcrumbName: t('breadcrumbs.users'), path: '' },
                { breadcrumbName: t('breadcrumbs.newUser'), path: '' },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImage = async (options: any) => {
        // TODO Implement image handle
        const { file } = options;

        if (!file) {
            return;
        }

        formik.setFieldValue('image', file);
    };

    return (
        <Wrapper inWCO={inWCO}>
            <ScreenTitleSubtitleContainer>
                <ScreenTitle>
                    {inWCO ? t('users.titleFirstAdmin') : !isEditing ? t('users.titleCreate') : t('users.titleEdit')}
                </ScreenTitle>
            </ScreenTitleSubtitleContainer>
            {isInSetupProcess && isEditing && <DeleteUserButton onOk={handleDeleteUser} />}
            <Row gutter={[30, 15]}>
                {/* Row 1 */}
                <Col xl={12}>
                    <FormInput
                        label={t('users.firstName.label')}
                        required
                        fieldProps={formik.getFieldProps('firstName')}
                        fieldMeta={formik.getFieldMeta('firstName')}
                    />
                </Col>
                <Col xl={12}>
                    <FormInput
                        label={t('users.email.label')}
                        required
                        fieldProps={formik.getFieldProps('email')}
                        fieldMeta={formik.getFieldMeta('email')}
                    />
                </Col>
                {/* Row 2 */}
                <Col xl={12}>
                    <FormInput
                        label={t('users.lastName.label')}
                        required
                        fieldProps={formik.getFieldProps('lastName')}
                        fieldMeta={formik.getFieldMeta('lastName')}
                    />
                </Col>
                <Col xl={12}>
                    <FormPhoneNumber
                        required
                        fieldNumberProps={formik.getFieldProps('phoneNumber')}
                        fieldCodeProps={formik.getFieldProps('phoneCode')}
                        fieldCodeMeta={formik.getFieldMeta('phoneCode')}
                        fieldNumberMeta={formik.getFieldMeta('phoneNumber')}
                    />
                </Col>
                {/* Row 3 */}
                <Col xl={12}>
                    <RadioCard
                        formik={formik}
                        formikProperty="role"
                        radioButtons={[
                            {
                                value: 'admin',
                                title: t('users.radio.admin.label'),
                                description: t('users.radio.admin.description'),
                            },
                            {
                                value: 'weighbridge-operator',
                                title: t('weighbridge.radio.unmanned.label'),
                                description: t('weighbridge.radio.unmanned.description'),
                            },
                        ]}
                    />
                </Col>
                <Col xl={12}>
                    <FormInput
                        label={t('users.password.label')}
                        type="password"
                        disabled={isEditing}
                        required={!isEditing}
                        fieldProps={formik.getFieldProps('password')}
                        fieldMeta={formik.getFieldMeta('password')}
                    />
                    <FormInput
                        label={t('users.confirmPassword.label')}
                        type="password"
                        disabled={isEditing}
                        required={!isEditing}
                        fieldProps={formik.getFieldProps('confirmPassword')}
                        fieldMeta={formik.getFieldMeta('confirmPassword')}
                    />
                </Col>
                {/* Row 4 */}
                <Col xl={12}>
                    <FormSelect
                        label={t('users.locations.label')}
                        required
                        mode="multiple"
                        hideKeys
                        selectOptions={locations.map((location, index) => ({
                            id: index,
                            value: `${location.locationName} - ${location.address}`,
                        }))}
                        fieldProps={formik.getFieldProps('locations')}
                        fieldMeta={formik.getFieldMeta('locations')}
                    />
                </Col>
                <Col xl={12}>
                    <DraggerContainer>
                        <Dragger showUploadList={false} maxCount={1} customRequest={handleImage}>
                            <p>
                                <PlusOutlined />
                            </p>
                            <p style={{ padding: '0 1rem' }} className="ant-upload-text">
                                {t('users.uploadPhoto')}
                            </p>
                        </Dragger>
                        {/* TODO Implement the solution in the eDocket (click -> modal) */}
                        {formik.values.image && 'Image uploaded'}
                    </DraggerContainer>
                </Col>
            </Row>

            <ButtonContainer>
                <Button onClick={() => navigate(-1)}>{t('back')}</Button>
                {isInSetupProcess && !isEditing && (
                    <Button disabled={!isEmpty(formik.errors)} onClick={handleCreateUserAndAnother}>
                        {t('users.saveAndAddAnotherUser')}
                    </Button>
                )}
                <Button
                    disabled={!isEmpty(formik.errors)}
                    type="primary"
                    onClick={async () => (inWCO ? handleRegisterCompany() : handleCreateUserAndClose())}
                >
                    {t('saveAndClose')}
                </Button>
            </ButtonContainer>
        </Wrapper>
    );
};

export default WCOUsers;
