import styled from 'styled-components';
import { colors, fonts } from 'theme';
import Button from '../../../components/ui/base/button/Button';

export const MainProgressDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 0.2rem solid ${colors.darkGrey};
    width: 100%;
    padding: 4.4rem;
`;

export const ProgressDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const LegendSpan = styled.span`
    font-size: 1.6rem;
    font-weight: ${fonts.weight.semibold};
    line-height: 2.2rem;
    color: ${colors.darkBlack2};
    margin-top: 1.3rem;
`;

export const ViewButton = styled(Button)`
    margin-top: 2.5rem;
`;
