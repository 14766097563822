import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import HideComponent from '../../../../../utils/HideComponent';

type Fields =
    | 'securityDetailsCode'
    | 'referenceID'
    | 'id'
    | 'accessCode'
    | 'amountAmount'
    | 'amountAmountCurrencyId'
    | 'guaranteeOffice.id';

export const guaranteeReferenceBlockValidation: {
    childValidators: {
        obligationGuarantee: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        obligationGuarantee: {
            childValidators: {
                securityDetailsCode: [validators.exact(1)],
                referenceID: [validators.maxLength(24)],
                id: [validators.maxLength(35)],
                accessCode: [validators.maxLength(4)],
                amountAmount: [validators.number(), validators.float(16, 2)],
                amountAmountCurrencyId: [validators.exact(3)],
                'guaranteeOffice.id': [validators.exact(8)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const GuaranteeReferenceBlock = ({ path = 'obligationGuarantee', fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => '8/3', []);
    return (
        <>
            <MultipleDeclarationField pathPrefix={path} name="securityDetailsCode">
                {({ form, field }, controls) => (
                    <FormSelect
                        refNumber={'8/2'}
                        label={t('guaranteeType')}
                        {...getFormikProps(field.name, form)}
                        multipleF={controls}
                        condensed
                        selectOptions={
                            formType?.includes('IMPORT')
                                ? cdsCodelists?.declaredGuaranteeTypes
                                : cdsCodelists?.exportDeclaredGuaranteeTypes
                        }
                        codeValidation={[validators.exact(1)]}
                    />
                )}
            </MultipleDeclarationField>

            <HideComponent hide={hide}>
                <DeclarationField pathPrefix={path} name="referenceID" maxNumberOfFields={9}>
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput
                            refNumber={ucc}
                            maxLength={24}
                            label={t('guaranteeReference.grn')}
                            {...getFormikProps(field.name, form)}
                            condensed
                        />
                    )}
                </DeclarationField>
            </HideComponent>

            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={35}
                        label={t('guaranteeReference.otherGuaranteeReference')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="accessCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={4}
                        label={t('guaranteeReference.accessCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="amountAmount">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('guaranteeReference.amountOfImportDuty')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="amountAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <CurrencySelect
                        refNumber={ucc}
                        label={t('guaranteeReference.currency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="guaranteeOffice.id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={8}
                        label={t('guaranteeReference.guaranteeOfficeID')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default GuaranteeReferenceBlock;
