import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FieldProps, useFormikContext } from 'formik';
import { ReactNode, ReactElement, useMemo, useCallback } from 'react';
import addPathPrefix from 'utils/addPathPrefix';
import { getFormikProps } from '../utils/form-utils';
import DeclarationField, { DeclarationFieldProps } from './DeclarationField';

interface Controls {
    onAdd: () => void;
    onRemove: () => void;
    canAdd?: boolean;
    canRemove?: boolean;
}

interface Props extends DeclarationFieldProps {
    children: (fieldProps: FieldProps<any>, controls: Controls, index: number) => ReactNode;
    max?: number;
    parent?: string | null;
}

const MultipleDeclarationField = ({ children, name, validation, pathPrefix, max, parent }: Props): ReactElement => {
    const _formik = useFormikContext();
    const { form, template, templateFormik } = useTemplateContext();
    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : _formik),
        [_formik, template, templateFormik]
    );

    const { fieldProps, fieldHelper } = useMemo(() => {
        return getFormikProps(
            addPathPrefix(addPathPrefix(template && `${form}.defaults`, pathPrefix), parent ? parent : name),
            formik
        );
    }, [form, formik, name, parent, pathPrefix, template]);

    const fieldValues: Array<any> = useMemo(
        () =>
            fieldProps.value && Array.isArray(fieldProps.value) && fieldProps.value.length
                ? [...fieldProps.value]
                : [null],
        [fieldProps.value]
    );

    const reachedMaxNumberOfFields = useMemo(() => fieldValues.length === max, [fieldValues.length, max]);

    const handleAdd = useCallback(
        (index: number) => {
            if (reachedMaxNumberOfFields) return;

            const newValues = fieldValues;
            newValues.splice(index + 1, 0, parent ? { [name]: null } : null);
            fieldHelper.setValue(newValues);
        },
        [fieldHelper, fieldValues, name, parent, reachedMaxNumberOfFields]
    );

    const handleRemove = useCallback(
        (index: number) => {
            const newValues = fieldValues;
            newValues.splice(index, 1);
            fieldHelper.setValue(newValues);
        },
        [fieldHelper, fieldValues]
    );

    return (
        <>
            {fieldValues?.map((_, index) => (
                <DeclarationField
                    key={addPathPrefix(addPathPrefix(pathPrefix ?? parent, name), index.toString())}
                    pathPrefix={pathPrefix}
                    name={
                        parent
                            ? addPathPrefix(addPathPrefix(parent, index.toString()), name)
                            : addPathPrefix(name, index.toString())
                    }
                    validation={validation}
                >
                    {(fieldProps) =>
                        children(
                            fieldProps,
                            {
                                onAdd: () => handleAdd(index),
                                onRemove: () => handleRemove(index),
                                canAdd: !reachedMaxNumberOfFields,
                                canRemove: fieldValues.length > 1,
                            },
                            index
                        )
                    }
                </DeclarationField>
            ))}
        </>
    );
};

export default MultipleDeclarationField;
