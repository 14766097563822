import config from 'config';
import axiosClient from '../../config/axios';
import { CodelistsPayload } from './code';

export const getIrelandCodeLists = (): Promise<CodelistsPayload[]> =>
    axiosClient
        .get<{ ieCodeLists: CodelistsPayload[] }>(`${config.cmsUrl}/codelist/iecodelists`)
        .then((response) => response.data.ieCodeLists);

export const getUkCodeLists = (): Promise<CodelistsPayload[]> =>
    axiosClient
        .get<{ ukCodeListsList: CodelistsPayload[] }>(`${config.cmsUrl}/codelist/ukcodelists`)
        .then((response) => response.data.ukCodeListsList);

export const createIrelandCodeLists = (data: {}): Promise<any> =>
    axiosClient.post(`${config.cmsUrl}/codelist/iecodelists/create`, data);

export const createUkCodeLists = (data: {}): Promise<any> =>
    axiosClient.post(`${config.cmsUrl}/codelist/ukcodelists/create`, data);

export const deleteIrelandCodeLists = (codelistId: string): Promise<any> =>
    axiosClient.delete(`${config.cmsUrl}/codelist/iecodelists/delete/${codelistId}`);

export const deleteUkCodeLists = (codelistId: string): Promise<any> =>
    axiosClient.delete(`${config.cmsUrl}/codelist/ukcodelists/delete/${codelistId}`);

export const updateIrelandCodeLists = (data: { id: string }): Promise<any> =>
    axiosClient.put(`${config.cmsUrl}/codelist/iecodelists/update/${data.id}`, data);

export const updateUkCodeLists = (data: { id: string }): Promise<any> =>
    axiosClient.put(`${config.cmsUrl}/codelist/ukcodelists/update/${data.id}`, data);
