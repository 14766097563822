import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { DriversActions } from './actionType';
import { Driver } from './driver';

interface DriverEntities {
    [key: string]: Driver;
}

export interface DriversState {
    isLoading: boolean;
    error?: ErrorResponse;
    drivers: ListPayload<Driver>;
    entities: DriverEntities;
}

export const driversInitialState: Readonly<DriversState> = {
    isLoading: false,
    drivers: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const drivers: Reducer<DriversState, DriversActions> = (
    state = driversInitialState,
    action: DriversActions
): DriversState => {
    switch (action.type) {
        case 'LIST_DRIVERS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_DRIVERS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                drivers: action.payload,
            };           
        case 'LIST_DRIVERS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'CREATE_DRIVER_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_DRIVER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_DRIVER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_DRIVER_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_DRIVER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_DRIVER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_DRIVER_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_DRIVER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_DRIVER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_DRIVER_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_DRIVER_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_DRIVER_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default drivers;
