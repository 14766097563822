import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const MainDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ImgDiv = styled.div`
    width: 11.2rem;
    height: 11.2rem;
`;

export const TextDiv = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-top: 4rem;
    text-align: center;
`;

export const HyperLink = styled.a`
    margin-top: 10.4rem;
    cursor: pointer;
    color: ${colors.purpleBlue};
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    &:hover {
        text-decoration: underline;
    }
`;
