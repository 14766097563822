import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { TextExtraSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const BtTag = styled.div`
    background: ${colors.white};
    width: 8rem;
    height: 2.2rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
    color: ${colors.darkGrey};
    background: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 1.6rem;
`;

export const ButtonLabel = styled(TextExtraSmall)`
    font-weight: ${fonts.weight.regular};
`;

export const PlusIcon = styled(PlusOutlined)`
    margin-left: 0.8rem;
`;

export const SaveIcon = styled(SaveOutlined)`
    cursor: pointer;
`;

export const SelectOptions = styled.div`
    margin-top: 2rem;
    background: ${colors.white};
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.2rem;
    line-height: 2.2rem;
    min-width: 20.3rem;
    height: 17.7rem;
    z-index: 5;
    margin-top: 3rem;
    position: absolute;
    border: 1px solid ${colors.darkGrey2};
    box-sizing: border-box;
    box-shadow: 0px 0px 24px rgba(9, 0, 255, 0.07);
    border-radius: 2px;
`;

export const Input = styled.input`
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: #383752;
    margin-left: 1.4rem;
    border: none;
    height: 3rem;
    -webkit-appearance: none;
    &:focus {
        outline: none;
    }
`;

export const SuggestedTagsList = styled.div`
    margin-top: 0.4rem;
    margin-left: 1.4rem;
    margin-bottom: 0.4rem;
    overflow: auto;
    height: 14rem;
`;

export const Divider = styled(Row)`
    border-bottom: 1px solid ${colors.darkGrey2};
    position: sticky;
    top: 0;
    z-index: 5;
`;
