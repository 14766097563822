import Notification, { NotificationProps } from 'components/ui/base/notification/Notification';
import { useFormik } from 'formik';
import useDeclarationRefund from 'hooks/useDeclarationRefund';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Declaration } from 'store/declarations/declaration';
import { IrelandRefundRemission } from 'store/declarations/ireland/refunds/remission/ireland-refund-remission';
import RefundFooter from 'views/declarations/footer/RefundFooter';
import { FormContent } from '../IrelandImportRefunds.styles';
import RemissionAttachedDocuments from './cards/RemissionAttachedDocuments';
import RemissionGoodsInformation from './cards/RemissionGoodsInformation';
import RemissionLocationType from './cards/RemissionLocationType';
import RemissionMasterDetailsCard from './cards/RemissionMasterDetailsCard';
import validation from './validations/DraftValidation';

interface Props {
    declaration: Declaration;
}

const IrelandImportRemissionRefund = ({ declaration }: Props) => {
    const { t } = useTranslation('common');

    const {
        remission,
        remissionHistory,
        createIrelandRemissionsRefund,
        updateIrelandRemissionsRefund,
        submitIrelandRemissionsRefund,
        createIrelandImportH7RemissionsRefund,
        updateIrelandImportH7RemissionsRefund,
        submitIrelandImportH7RemissionsRefund,
    } = useDeclarationRefund({
        declarationId: declaration.id!,
    });

    const actions = useMemo(() => {
        const isImportH7 = Boolean(declaration.irelandH7ImportDeclaration);

        return isImportH7
            ? {
                  update: updateIrelandImportH7RemissionsRefund,
                  create: createIrelandImportH7RemissionsRefund,
                  submit: submitIrelandImportH7RemissionsRefund,
              }
            : {
                  update: updateIrelandRemissionsRefund,
                  create: createIrelandRemissionsRefund,
                  submit: submitIrelandRemissionsRefund,
              };
    }, [
        createIrelandImportH7RemissionsRefund,
        createIrelandRemissionsRefund,
        declaration.irelandH7ImportDeclaration,
        submitIrelandImportH7RemissionsRefund,
        submitIrelandRemissionsRefund,
        updateIrelandImportH7RemissionsRefund,
        updateIrelandRemissionsRefund,
    ]);

    const { getFieldHelpers, getFieldMeta, getFieldProps, submitForm } = useFormik({
        initialValues: remission ?? {},
        validationSchema: validation,
        onSubmit: async (remission: IrelandRefundRemission) => {
            const response = remissionHistory?.id
                ? await actions.update(remission, remissionHistory.id)
                : await actions.create(remission);

            return response;
        },
    });

    const handleShowNotification = useCallback(
        (hasResponse: boolean) => {
            const notification: NotificationProps = hasResponse
                ? {
                      type: 'success',
                      messageTitle: t('formSubmitSuccessTitle'),
                      description: t('formSubmitSuccessMessage'),
                  }
                : {
                      type: 'error',
                      messageTitle: t('error.formCreateErrorTitle'),
                      description: t('error.formCreateErrorMessage'),
                  };

            Notification(notification);
        },
        [t]
    );

    return (
        <>
            <FormContent>
                <RemissionMasterDetailsCard
                    key={'ireland-remission-master-details'}
                    keyCard={'ireland-remission-master-details'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <RemissionLocationType
                    key={'ireland-remission-goods-information'}
                    keyCard={'ireland-remission-goods-information'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <RemissionAttachedDocuments
                    key={'remission-attached-documents-form-card'}
                    keyCard={'remission-attached-documents-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
                <RemissionGoodsInformation
                    key={'remission-goods-information-form-card'}
                    keyCard={'remission-goods-information-form-card'}
                    getFieldHelpers={getFieldHelpers}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                />
            </FormContent>
            <RefundFooter
                saveDraft={{
                    trigger: async () => {
                        const response = await submitForm();

                        handleShowNotification(response);
                    },
                    loading: false,
                }}
                submitDeclaration={{
                    trigger: async () => {
                        const savedDeclaration = await submitForm();

                        if (savedDeclaration?.id) {
                            const response = await actions.submit(savedDeclaration.id);

                            handleShowNotification(response);
                        }
                    },
                    loading: false,
                }}
            />
        </>
    );
};
export default IrelandImportRemissionRefund;
