import { Select } from 'components/ui/base/select/Select';
import styled from 'styled-components';

export const StyledDeclarationSelect = styled(Select)<{ condensed?: boolean; mirrored?: boolean }>`
    width: 100%;
    font-weight: normal;

    .ant-select-selector {
        border-radius: ${({ condensed }) => (condensed ? '4px !important' : 'initial')};
        border: ${({ condensed, mirrored }) =>
            condensed && !mirrored
                ? '1px solid #cecece !important'
                : mirrored
                ? '1.5px solid #00CCFF !important'
                : 'initial'};
    }

    .ant-select-selection-item {
        color: ${({ mirrored }) => mirrored && '#00CCFF'};
    }
`;
