import { DatePicker, DatePickerProps } from 'antd';
import { Fragment, memo } from 'react';
import styled from 'styled-components';
import EDocketLabel from './EDocketLabel';

type Props = { label?: string } & DatePickerProps;

const DatePickerContainer = styled('div')`
    .ant-picker {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
    }
`;

const StyledDatePicker = (props: DatePickerProps) => {
    return (
        <DatePickerContainer>
            <DatePicker {...props} />
        </DatePickerContainer>
    );
};

const EDocketFormDatePicker = ({ label, ...datePickerProps }: Props) => {
    return (
        <>
            <EDocketLabel label={label} />
            <StyledDatePicker {...datePickerProps} />
        </>
    );
};

export default memo(EDocketFormDatePicker, (prev, next) => {
    return prev?.value === next?.value;
});
