import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { useFormikContext } from 'formik';
import { find } from 'lodash';
import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { additionalProcedureCodeOptions } from 'store/declarations/enums/uk/cds-export-procedure-code-code-lists';
import { TemplateData } from 'store/template/template';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';
import { ValidationSchema, validators } from '../validations/validations';
import { GovernmentProcedure } from './ProcedureBlock';

export type Fields = 'additionalCurrentCode';

export const additionalProcedureBlockValidation: {
    childValidators: {
        governmentProcedure: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        governmentProcedure: {
            childValidators: {
                additionalCurrentCode: [validators.required(), validators.exact(3)],
            },
            selfValidators: [validators.hackArrayMinLength('additionalCurrentCode', 1, true)],
        },
    },
};

export const additionalProcedureFields = additionalProcedureBlockValidation.childValidators.governmentProcedure;

interface Props extends BlockProps<Fields> {}

const AdditionalProcedureBlock = ({ path = 'governmentProcedure', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { template, templateFormik, form } = useTemplateContext();
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const mainFormik = useFormikContext<GovernmentProcedure>();

    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : mainFormik),
        [mainFormik, template, templateFormik]
    );

    const { governmentProcedure, governmentProcedurePath } = useMemo(() => {
        if (template && form && templateFormik)
            return {
                governmentProcedurePath: `${form}.defaults.governmentProcedure`,
                governmentProcedure: (formik.values as TemplateData)[form].defaults
                    .governmentProcedure as unknown as GovernmentProcedure['governmentProcedure'],
            };
        return {
            governmentProcedurePath: 'governmentProcedure',
            governmentProcedure: (formik.values as GovernmentProcedure).governmentProcedure,
        };
    }, [form, formik.values, template, templateFormik]);

    const ucc = useMemo(() => '1/11', []);

    const additionalProcedureCodeCodeList = useMemo(() => {
        const procedureCode = governmentProcedure?.currentCode;
        const previousProcedureCode = governmentProcedure?.previousCode;

        if (procedureCode == null || previousProcedureCode == null) {
            return [];
        }

        const combination = (procedureCode + previousProcedureCode) as keyof typeof additionalProcedureCodeOptions;

        return additionalProcedureCodeOptions[combination];
    }, [governmentProcedure?.currentCode, governmentProcedure?.previousCode]);

    useEffect(() => {
        const additionalCurrentCode = governmentProcedure?.additionalCurrentCode;

        if (formType?.includes('IMPORT')) return;

        additionalCurrentCode?.forEach((eachAdditionalCurrentCode, index) => {
            if (
                eachAdditionalCurrentCode &&
                !find(additionalProcedureCodeCodeList, (code: any) => code.id === eachAdditionalCurrentCode)
            ) {
                formik.setFieldValue(`${governmentProcedurePath}.additionalCurrentCode.${index}`, null);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.setFieldValue, governmentProcedure?.additionalCurrentCode, additionalProcedureCodeCodeList]);

    return (
        <MultipleDeclarationField pathPrefix={path} name="additionalCurrentCode">
            {({ form, field }, controls) => (
                <FormSelect
                    required
                    {...getFormikProps(field.name, form)}
                    refNumber={ucc}
                    label={t('additionalProcedure')}
                    selectOptions={
                        formType?.includes('IMPORT')
                            ? cdsCodelists?.importSpecialProcedures
                            : additionalProcedureCodeCodeList
                    }
                    multipleF={controls}
                    condensed
                    codeValidation={[validators.exact(3)]}
                />
            )}
        </MultipleDeclarationField>
    );
};

export default AdditionalProcedureBlock;
