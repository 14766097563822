import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { TemplateResponse } from './template';
import { IrelandExportMessageType } from '../declarations/enums/ireland/message-type';
import { MessageTypes } from '../declarations/enums/common/template-types';

const isAes = (internalType: string, declarationType: string) =>
    internalType === 'export' &&
    Object.values(IrelandExportMessageType).includes(declarationType as IrelandExportMessageType);

const isAisH7 = (internalType: string, declarationType: string) =>
    internalType === 'import' && declarationType === 'H7';

export interface ArchivedTemplatesParams {
    declarationExternalType: string;
    declarationInternalType: string;
    declarationName?: string;
}

export const getTemplates = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens',
    declarationType: MessageTypes,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<TemplateResponse>> | undefined => {
    let urlPath;

    if (country === 'ireland') {
        if (isAes(internalType, declarationType)) {
            urlPath = `/ie/${internalType}?${new URLSearchParams({
                declarationName: declarationType,
            })}`;
        } else if (internalType === 'import' || internalType === 'ens') {
            urlPath = `/${country}/${internalType}`;
            if (isAisH7(internalType, declarationType)) urlPath = urlPath.concat('/h7');
        } else {
            return;
        }
    } else {
        urlPath = `/cds/${internalType}`;
    }

    return axiosClient
        .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}${urlPath}`, { params })
        .then((response) => response.data.payload);
};

export const getArchivedTemplates = (params: ArchivedTemplatesParams): Promise<ListPayload<TemplateResponse>> =>
    axiosClient
        .get<ListResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}/archive`, { params })
        .then((response) => response.data.payload);

export const archiveTemplate = (declarationTemplateId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationTemplatesUrl}/archive/${declarationTemplateId}`)
        .then((response) => response.data);
export const unarchiveTemplate = (declarationTemplateId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .put<SuccessResponse<void>>(`${config.declarationTemplatesUrl}/unarchive/${declarationTemplateId}`)
        .then((response) => response.data);

export const createTemplate = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens',
    declarationType: MessageTypes,
    template: Partial<TemplateResponse>
) => {
    let urlPath;
    if (country === 'ireland') {
        if (internalType === 'export') {
            urlPath = `/ie/${internalType}`;
        } else {
            urlPath = `/${country}/${internalType}`;
            if (declarationType === 'H7') urlPath = urlPath.concat('/h7');
        }
    } else {
        urlPath = `/cds/${internalType}`;
    }

    const { declarationName, templateName, template: templateData } = template;
    return axiosClient.post(`${config.declarationTemplatesUrl}${urlPath}`, {
        declarationName,
        templateName,
        template: templateData,
    });
};
