import styled from 'styled-components';
import { colors } from 'theme';

export const TaxesTable = styled.table`
    & td,
    th {
        padding: 0.8rem;
    }
    border-collapse: inherit;
    border-spacing: 0px;
`;

export const CommodityRow = styled.tr``;

export const CommodityCell = styled.td`
    border-color: ${colors.lightGrey4};
    border-style: solid;
    border-bottom: none;
    border-radius: 4px 4px 0px 0px;
    border-width: 1px;
    padding-top: 0.8rem;
`;

export const TaxRow = styled.tr<{ last?: boolean }>`
    & td {
        border-color: ${colors.lightGrey4};
        border-bottom-style: ${(props) => (props.last ? 'solid' : 'none')};
        border-width: 1px;
    }

    & td:first-child {
        border-style: solid;
        border-top-style: none;
        border-right-style: none;
        border-bottom-style: ${(props) => (props.last ? 'solid' : 'none')};
        border-width: 1px;
        border-bottom-left-radius: ${(props) => (props.last ? '4px' : '0px')};
    }

    & td:last-child {
        border-style: solid;
        border-top-style: none;
        border-left-style: none;
        border-bottom-style: ${(props) => (props.last ? 'solid' : 'none')};
        border-width: 1px;
        border-bottom-right-radius: ${(props) => (props.last ? '4px' : '0px')};
    }
`;

export const NumberCell = styled.td`
    text-align: right;
`;
