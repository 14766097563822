import { ButtonProps, ModalFuncProps } from 'antd';
import { TestProps } from 'core/utils/testTypes';
import { FC, ReactNode } from 'react';
import { TextSmall } from '../typography';
import { StyledModal } from './Modal.styles';

interface Props extends ModalFuncProps, TestProps {
    contentText?: string;
    children?: ReactNode;
    confirmText?: string | React.ReactNode;
    cancelText?: string;
    equalButtons?: boolean;
    footer?: React.ReactNode;
    disabledOkButton?: boolean;
    testId?: string;
}

const Modal: FC<Props> = (props) => {
    const { equalButtons } = props || false;

    const okButtonProps: ButtonProps & TestProps = equalButtons
        ? {
              disabled: props.disabledOkButton,
              testId: `ok-button-${props.testId ?? ''}`,
              style: {
                  color: `#0040E4`,
                  background: `#FFFFFF`,
                  borderColor: `#383752`,
                  border: '1px solid #0040E4',
              },
              size: 'large',
          }
        : {
              disabled: props.disabledOkButton,
              style: {
                  color: `#FFFFFF`,
                  background: props.disabledOkButton ? '#7978a1' : `#383752`,
                  borderColor: props.disabledOkButton ? '#7978a1' : `#383752`,
              },
              size: 'large',
              testId: `ok-button-${props.testId ?? ''}`,
          };

    const cancelButtonProps: ButtonProps & TestProps = {
        testId: `cancel-button-${props.testId ?? ''}`,
        style: {
            color: `#0040E4`,
            background: `#FFFFFF`,
            borderColor: `#383752`,
            border: '1px solid #0040E4',
        },
        size: 'large',
    };

    return (
        <StyledModal
            centered
            data-testid={props.testId}
            okText={props.confirmText ?? 'Yes, remove'}
            okButtonProps={okButtonProps}
            cancelText={props.cancelText ?? "Don't remove"}
            cancelButtonProps={cancelButtonProps}
            {...props}
        >
            {props.contentText && <TextSmall>{props.contentText}</TextSmall>}
            {props.children}
        </StyledModal>
    );
};

export default Modal;
