import { Tooltip } from 'antd';
import { FC, useMemo } from 'react';
import { OptionText } from '../SearchBar.style';

interface Props {
    data?: string;
    size?: number;
}

const OptionTooltip: FC<Props> = ({ data, size = 15 }) => {
    const showTooltip = useMemo(() => {
        if (data) {
            if (data.length > size) {
                return (
                    <Tooltip overlay={data}>
                        <OptionText>{data}</OptionText>
                    </Tooltip>
                );
            }
            return <OptionText>{data}</OptionText>;
        } else {
            return <OptionText>-</OptionText>;
        }
    }, [data, size]);

    return showTooltip;
};
export default OptionTooltip;
