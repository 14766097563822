import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { colors } from '../../../../../theme';

const { Text } = Typography;

export const Error = styled(Text)`
    color: ${colors.lightRed};
`;

export const ButtonText = styled(Button)`
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
`;

export const MainGrid = styled.div<{ columns?: string }>`
    display: grid;
    grid-template-columns: ${({ columns }) => (columns ? columns : '110px auto max-content')};
    column-gap: 3rem;
    row-gap: 1rem;
`;

export const DetailsGrid = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 3rem;
    row-gap: 1rem;
`;

export const FieldLink = styled(Text)`
    word-break: break-all;
    cursor: pointer;

    transition: color 100ms;
    :hover {
        color: ${colors.blueLink};
    }
`;
