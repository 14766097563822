import * as Yup from 'yup';
import {
    OperatorConfirmationForm,
    OperatorConfirmationFormDriverType,
} from './components/EDocketFormOperatorConfirmationModal';
import {
    EDocket,
    EDocketFormAfterWeightType,
    EDocketFormDriverType,
    EDocketFormProductType,
    EDocketFormSignaturesType,
    EDocketFormType,
    EDocketFormWeightType,
} from './eDocketTypes';

type ToFormikSchemeObject<TObjectType> = Record<keyof TObjectType, Yup.AnySchema>;

// TODO Update to right validation. Now it is only required that is used.
export const eDocketFormOperatorConfirmationValidationSchema = () => {
    const driverValidationSchema = Yup.object().shape<ToFormikSchemeObject<OperatorConfirmationFormDriverType>>({
        email: Yup.string().required(),
        name: Yup.string().required(),
        phoneCode: Yup.string().required(),
        phoneNumber: Yup.string().required(),
    });

    return Yup.object().shape<ToFormikSchemeObject<OperatorConfirmationForm>>({
        hasConfirmedDriverInformation: Yup.boolean().required().oneOf([true], 'Field must be checked'),
        driver: driverValidationSchema,
        netWeight: Yup.string().required(),
        operatorSignature: Yup.string().required(),
    });
};

// TODO Update to right validation. Now it is only required that is used.
const eDocketFormValidationSchema = (eDocket: EDocket | undefined | null) => {
    const driverValidationSchema = Yup.object().shape<ToFormikSchemeObject<EDocketFormDriverType>>({
        trailerIdNumber: Yup.string().required(),
        vehicleRegisterNumber: Yup.string().required(),
    });

    const productValidationSchema = Yup.object().shape<ToFormikSchemeObject<EDocketFormProductType>>({
        accountNumber: Yup.string().required(),
        code: Yup.string().required(),
        description: Yup.string().required(),
        from: Yup.string().required(),
    });

    const weightValidationSchema = Yup.object().shape<ToFormikSchemeObject<EDocketFormWeightType>>({
        date: Yup.string().required(),
        goodsInOut: Yup.string().required(),
        image: Yup.string().required(),
        time: Yup.string().required(),
        units: Yup.string().required(),
        weight: Yup.string().required(),
    });

    const afterWeightValidationSchema = Yup.object().shape<ToFormikSchemeObject<EDocketFormAfterWeightType>>({
        comments: Yup.string().nullable(),
        elapsedTime: Yup.string().required(),
        netWeight: Yup.string().required(),
        units: Yup.string().required(),
    });

    const signaturesValidationSchema = Yup.object().shape<ToFormikSchemeObject<EDocketFormSignaturesType>>({
        operator: Yup.string().required(),
        driver: Yup.string().nullable().required(),
    });

    return Yup.object().shape<ToFormikSchemeObject<EDocketFormType>>({
        trader: Yup.string().required(),
        receiver: Yup.string().required(),
        driver: driverValidationSchema,
        haulier: Yup.string().required(),
        product: productValidationSchema,
        firstWeight: weightValidationSchema,
        secondWeight: Yup.object().when([], {
            is: () => eDocket != null,
            then: weightValidationSchema,
            otherwise: Yup.object().nullable(),
        }),
        afterWeight: Yup.object().when([], {
            is: () => eDocket?.secondWeight != null,
            then: afterWeightValidationSchema,
            otherwise: Yup.object().nullable(),
        }),
        signatures: Yup.object().when([], {
            is: () => eDocket?.signatures?.operator != null,
            then: signaturesValidationSchema,
            otherwise: Yup.object().nullable(),
        }),
    });
};

export default eDocketFormValidationSchema;
