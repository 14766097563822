import config from 'config';
import { SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { CommodityCodeTaxes } from './CommodityCodeTaxes';

export const getDeclarationProductTaxes = (declarationId: string): Promise<CommodityCodeTaxes> =>
    axiosClient
        .get<SuccessResponse<CommodityCodeTaxes>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/taxes`
        )
        .then((response) => response.data.payload);
