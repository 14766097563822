import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyledA, VerticalNav as Navigation } from 'views/declarations/Form.styles';
import useProductTemplateFormErrors from '../../../hooks/useProductTemplateFormErrors';
import { clearFlash, doFlash } from '../utils/form-utils';
import { ParsedCard } from 'store/declaration-form-errors/ParsedForm';
import { kebabCase } from 'lodash';
import { NavItem } from './DeclarationVerticalNavigation';

interface Props {
    navs?: NavItem[];
    active?: number;
    setActive?: (index: number) => void;
}

const VerticalNav: FC<Props> = ({ navs, setActive: setActiveProp, active: activeProp }) => {
    const { productId, type: productTemplateType } = useParams<{ productId: string; type: string }>();

    const { declarationErrors } = useDeclarationFormErrors();
    const { productTemplateErrors } = useProductTemplateFormErrors();

    const { products } = useProducts();

    const [active, setActive] = useState(activeProp);
    const [clicked, setClicked] = useState(false);
    const [wasClicked, setWasClicked] = useState(false);

    const handleClick = useCallback(
        (elementId: string, index: number) => {
            setClicked(true);

            const element = document.getElementById(elementId);

            if (element !== null) {
                setActiveProp?.(index);
                setActive(index);

                // Remove flashing if present
                clearFlash();

                // Scroll to element
                element.offsetParent?.scrollTo({
                    top: element.offsetTop - 20, // Add 20 pixels above the element so it is better in view.
                });

                // Add a flash animation
                doFlash(element);
            }
        },
        [setActiveProp]
    );

    /**
     * Click the most bottom option while the cards section is not scrolled to the bottom.
     * The click says that the selected must be clicked one. But upon clicking, it scrolls
     * the cards section which causes for the `activeProp` to be changed which would in turn
     * change the active nav to the most top visible one. But that is not what the user has selected.
     *
     * This useEffect fixes that issue.
     */
    useEffect(() => {
        // Don't clear flash if active section is changed by the on click event
        // and reset the clicked to false to reset it
        // This double protection is needed to counter buggy interaction between nav bar and sections.
        if (clicked) {
            setClicked(false);
            setWasClicked(true);
            return;
        }
        if (wasClicked) {
            setWasClicked(false);
            return;
        }

        setActive(activeProp);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProp]);

    const productIndex = useMemo(
        () => (products?.list as any).findIndex(({ id }: { id: string }) => id === productId) + 1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productId]
    );

    const errors: Set<string> | undefined = useMemo(() => {
        if (productId) {
            return extractNamesFromErrorsIndexed(declarationErrors.items)[productIndex];
        } else if (productTemplateType) {
            return extractNamesFromErrors(productTemplateErrors.template);
        } else {
            return extractNamesFromErrors(declarationErrors.masterDetails);
        }
    }, [
        declarationErrors.items,
        declarationErrors.masterDetails,
        productTemplateErrors.template,
        productTemplateType,
        productId,
        productIndex,
    ]);

    return (
        <Navigation>
            {navs?.map(({ title, href }, i) => (
                <StyledA
                    error={errors?.has(title)}
                    onClick={() => handleClick(`${kebabCase(title)}-form-card`, i)}
                    active={active === i}
                    key={i}
                >
                    {title}
                </StyledA>
            ))}
        </Navigation>
    );
};
export default VerticalNav;

const extractNamesFromErrors = (arrErrors: ParsedCard[]) => {
    return arrErrors.reduce<Set<string>>((acc, error) => {
        let errorName = error.name;
        errorName = errorName.replace(/\d+/g, '');
        acc.add(errorName);
        return acc;
    }, new Set());
};

const extractNamesFromErrorsIndexed = (productErrors: any[]) => {
    const names: { [index: number]: Set<string> } = {};

    productErrors.forEach(({ index, errors }) => {
        names[index] = new Set(
            [...errors].map(({ name }: { name: string }) => {
                let errorName = name;
                errorName = errorName.replace(/ \d+/g, '');
                return errorName;
            })
        );
    });

    return names;
};
