import Setup from 'views/setup/Setup';
import { Route } from '../routes';
import wcRoutes from './wc/wc.routes';
import wcoRoutes from './wco/wco.routes';

const setupRoutes: Route[] = [
    {
        path: 'setup',
        name: 'WeighbridgeClientOnboarding',
        element: <Setup />,
        children: [...wcoRoutes, ...wcRoutes],
    },
];

export default setupRoutes;
