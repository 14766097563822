import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    .ant-modal-content {
        padding: 3.2rem;
        min-height: 236px;
        .ant-modal-header {
            border-bottom: 0;
        }
        .ant-modal-footer {
            border-top: 0;
        }
    }
`;
