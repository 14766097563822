import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import RadioCard from 'components/ui/composed/RadioCard';
import useSetup from 'hooks/useSetup';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';
import { CreateOutletContext, useWCOutletContext } from '../WC';

const { ScreenTitleSubtitleContainer, ScreenTitle, ButtonContainer } = SetupStyledComponents;

const WCWeighbridge = () => {
    const { t } = useTranslation('weighbridge');
    const navigate = useNavigate();

    const { formik } = useWCOutletContext<CreateOutletContext>();

    const { isInSetupScreen } = useSetup();

    const handleBack = () => {
        navigate(-1);
    };

    const handleSaveAndClose = () => {
        // TODO Save
        // TODO Navigate somewhere (for the close)
    };

    const handleNext = () => {
        // TODO Save?

        navigate('location');
    };

    return (
        <div>
            <ScreenTitleSubtitleContainer>
                <ScreenTitle>{isInSetupScreen ? t('weighbridge.setupTitle') : t('weighbridge.title')}</ScreenTitle>
            </ScreenTitleSubtitleContainer>
            <Row gutter={[30, 15]}>
                <Col xl={12}>
                    {/* TODO Set the data for this select */}
                    <FormSelect
                        required
                        label={t('weighbridge.location.label')}
                        fieldProps={formik.getFieldProps('location')}
                        fieldMeta={formik.getFieldMeta('location')}
                    />
                    <FormInput
                        required
                        label={t('weighbridge.label.label')}
                        fieldProps={formik.getFieldProps('label')}
                        fieldMeta={formik.getFieldMeta('label')}
                    />
                </Col>
                <Col xl={12}>
                    <RadioCard
                        formik={formik}
                        formikProperty="type"
                        radioButtons={[
                            {
                                value: 'manned',
                                title: t('weighbridge.radio.manned.label'),
                                description: t('weighbridge.radio.manned.description'),
                            },
                            {
                                value: 'unmanned',
                                title: t('weighbridge.radio.unmanned.label'),
                                description: t('weighbridge.radio.unmanned.description'),
                            },
                        ]}
                    />
                </Col>
                <ButtonContainer>
                    <Button onClick={handleBack}>{t('back')}</Button>
                    <Button onClick={handleSaveAndClose}>{t('saveAndClose')}</Button>
                    <Button type="primary" onClick={handleNext} disabled={!isEmpty(formik.errors)}>
                        {t('next')}
                    </Button>
                </ButtonContainer>
            </Row>
        </div>
    );
};

export default WCWeighbridge;
