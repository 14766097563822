import * as Yup from 'yup';

const commodityCode = Yup.object().shape({
    combinedNomenclatureCode: Yup.string().max(8).min(1).nullable(),
    taricCode: Yup.string().max(2).min(2).nullable(),
});

const importGoodsInformation = Yup.object().shape({
    commodityCode: commodityCode,
    goodsDescription: Yup.string().max(512).min(1).nullable(),
    valueOfGoods: Yup.string().max(16).nullable(),
    goodsQuantity: Yup.object().shape({
        quantity: Yup.string().max(16).nullable(),
        measurementUnit: Yup.string().max(4).min(1).nullable(),
    }),
});

const exportGoodsInformation = Yup.object().shape({
    commodityCode: commodityCode,
    goodsDescription: Yup.string().max(512).min(1).nullable(),
    netMass: Yup.string().max(16).nullable(),
    supplementaryUnits: Yup.string().max(16).nullable(),
});

const otherDocuments = Yup.object().shape({
    methodOfDischarge: Yup.string().max(1).min(1).nullable(),
    exportGoodsInformation: exportGoodsInformation,
});

const validation = Yup.object().shape({
    applicationReferenceId: Yup.string().max(18).min(18).nullable(),
    applicant: Yup.string().max(17).min(1).nullable(),
    procedureCode: Yup.string().max(2).min(2).nullable(),
    importGoodsInformation: Yup.array().of(importGoodsInformation).nullable(),
    amountsHeldOnDeposit: Yup.object({
        customDutyHeldOnDeposit: Yup.string().max(16).nullable(),
        vatHeldOnDeposit: Yup.string().max(16).nullable(),
        otherDutiesHeldOnDeposit: Yup.string().max(16).nullable(),
    }).nullable(),
    additionalInformation: Yup.object({
        periodOfDischarge: Yup.string().max(2).min(2).nullable(),
        rateOfYield: Yup.string().max(512).min(1).nullable(),
    }).nullable(),
    mrn: Yup.string().max(18).min(18).nullable(),
    exportGoodsInformation: Yup.array().of(exportGoodsInformation).nullable(),
    otherDocuments: Yup.array().of(otherDocuments).nullable(),
    importedGoods: Yup.boolean().nullable(),
    outstandingBalanceOnImportedGoods: Yup.string().max(16).nullable(),
    amountOfDepositRefundClaim: Yup.string().max(16).nullable(),
    payerEoriForRefund: Yup.string().max(18).min(1).nullable(),
});

export default validation;
