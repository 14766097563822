import styled from 'styled-components';
import { colors } from 'theme';

interface TagLayoutProps {
    color?: string;
}

export const TagLayout = styled.div<TagLayoutProps>`
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: ${colors.darkGrey};
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.3rem;
    min-width: 100px;
    text-align: center;
    background: ${({ color }) => (color ? color : `${colors.lightBlue1}`)};
`;
