import { useFormik } from 'formik';
import useDebouncedValidate from 'hooks/useDebouncedValidate';
import { WCWeighbridgeFormikFields } from '../WCTypes';
import { wcWeighbridgeValidationSchema } from './wcValidationSchemas';

const initialValues: WCWeighbridgeFormikFields = {
    label: '',
    location: '',
    type: 'manned',
};

const useFormikWeighbridge = () => {
    const formik = useFormik({
        initialValues,
        validationSchema: wcWeighbridgeValidationSchema,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    useDebouncedValidate(formik);

    return formik;
};

export default useFormikWeighbridge;
