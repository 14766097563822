import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthenticationContainer, ImageDivBackground } from './Auth.styles';

const Authentication: FC = () => {
    return (
        <>
            <AuthenticationContainer>
                <ImageDivBackground pageName="signIn" />
                <Outlet />
            </AuthenticationContainer>
        </>
    );
};
export default Authentication;
