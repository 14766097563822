import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import ValuationIndicatorsBlock, { valuationIndicatorsBlockValidation } from '../../blocks/ValuationIndicatorsBlock';

export const valuationIndicatorsCardValidation = {
    selfValidators: [valuationIndicatorsBlockValidation],
};

const ValuationIndicatorsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('valuationIndicators')} hidden={props.hidden}>
            <FormCardContainer>
                <ValuationIndicatorsBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ValuationIndicatorsCard;
