import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { set } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import AutoFillModal from '../components/AutoFillModal';
import { validators } from 'views/declarations/uk/export/validations/validations';

const TransportInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { aisCodelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByField } = useTooltips();
    const { template, templateFormik } = useTemplateContext();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const openModal = (e: any) => {
        setOpen(true);
        setValue(e?.target?.value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return props.formik;
    }, [props.formik, template, templateFormik]);

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    return (
        <FormikProvider value={props.formik!}>
            <NewFormCard title={t('Transport information')}>
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`customsOfficeLodgement`, props)}
                        label={t('customsOfficeLodgement')}
                        disabled={props.amendment}
                        tooltip={getH1TooltipsByField(t('customsOfficeLodgement'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        required
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        condensed
                        onChange={openModal}
                        codeValidation={[validators.exact(8)]}
                    />
                    <AutoFillModal
                        visible={open}
                        value={value}
                        prevValue={prevValue}
                        fields={[
                            {
                                name: 'presentationCustomsOffice',
                                label: t('presentationCustomsOffice'),
                                codeList: aisCodelists?.nationalCustomsOffice,
                            },
                            {
                                name: 'supervisingCustomsOffice',
                                label: t('authorisation.supervisingCustomsOffice'),
                                codeList: aisCodelists?.nationalCustomsOffice,
                            },
                        ]}
                        onCancel={closeModal}
                        onOk={autoFill}
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`presentationCustomsOffice`, props)}
                        refNumber="5.26"
                        label={t('presentationCustomsOffice')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.26', t('presentationCustomsOffice'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        condensed
                        codeValidation={[validators.exact(8)]}
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`supervisingCustomsOffice`, props)}
                        refNumber="5.27"
                        label={t('authorisation.supervisingCustomsOffice')}
                        selectOptions={aisCodelists?.nationalCustomsOffice}
                        tooltip={getH1TooltipsByRefNumberAndField('5.27', t('authorisation.supervisingCustomsOffice'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.exact(8)]}
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`goodsShipment.inlandBorderTransportMode`, props)}
                        refNumber="7.5"
                        label={t('goodsShipment.inlandBorderTransportMode')}
                        tooltip={getH1TooltipsByRefNumberAndField('7.5', t('goodsShipment.inlandBorderTransportMode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.transportMode}
                        condensed
                        codeValidation={[validators.exact(1)]}
                    />

                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`goodsShipment.meansIdentificationType`, props)}
                        label={t('goodsShipment.meansIdentificationType')}
                        refNumber="7.9"
                        selectOptions={aisCodelists?.meansIdentityType}
                        tooltip={getH1TooltipsByRefNumberAndField('7.9', t('goodsShipment.meansIdentificationType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.exact(2)]}
                    />
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`goodsShipment.meansIdentificationNumber`, props)}
                        required
                        refNumber="7.9"
                        label={t('goodsShipment.meansIdentificationNumber')}
                        tooltip={getH1TooltipsByRefNumberAndField('7.9', t('goodsShipment.meansIdentificationNumber'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`borderTransportMode`, props)}
                        refNumber="7.4"
                        label={t('borderTransportMode')}
                        tooltip={getH1TooltipsByRefNumberAndField('7.4', t('borderTransportMode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={aisCodelists?.transportMode}
                        condensed
                        codeValidation={[validators.maxLength(2)]}
                    />
                    <CountrySelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`activeBorderTransportMeansNationality`, props)}
                        refNumber="7.15"
                        label={t('consignment.borderTransportMeans.nationality')}
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '7.15',
                            t('consignment.borderTransportMeans.nationality')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </FormikProvider>
    );
};
export default TransportInformationCard;
