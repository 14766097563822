import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import UcrBlock, { itemUcrBlockValidation } from '../../blocks/UcrBlock';
import CountryOfDestinationCodeBlock, {
    countryOfDestinationCodeBlockProductValidation,
} from '../../blocks/CountryOfDestinationCodeBlock';
import DescriptionOfGoodsBlock, {
    cdsImportDescriptionOfGoodsBlockValidation,
    descriptionOfGoodsBlockValidation,
} from '../../blocks/DescriptionOfGoodsBlock';
import GrossMassBlock, {
    cdsImportGrossMassBlockProductValidation,
    grossMassBlockProductValidation,
} from '../../blocks/GrossMassBlock';
import NetMassBlock, { netMassBlockOptionalValidation, netMassBlockValidation } from '../../blocks/NetMassBlock';
import StatisticalValueBlock, {
    cdsImportStatisticalValueBlockValidation,
    statisticalValueBlockValidation,
} from '../../blocks/StatisticalValueBlock';
import SupplementaryUnitsBlock, { supplementaryUnitsBlockValidation } from '../../blocks/SupplementaryUnitsBlock';
import UnDangerousGoodsCodeBlock, { unDangerousGoodsCodeBlockValidation } from '../../blocks/UnDangerousGoodsCodeBlock';
import CountryOfDispatchExportCodeBlock, {
    countryOfDispatchExportCodeItemBlockValidation,
} from '../../blocks/CountryOfDispatchExportCodeBlock';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import useHideEntities from '../../../../../../hooks/useHideEntities';

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'netNetWeightMeasure' | 'grossMassMeasure' | 'statisticalValueAmount'>;
}

export const itemFieldsCardValidation = {
    selfValidators: [
        descriptionOfGoodsBlockValidation,
        itemUcrBlockValidation,
        countryOfDestinationCodeBlockProductValidation,
        netMassBlockValidation,
        supplementaryUnitsBlockValidation,
        grossMassBlockProductValidation,
        statisticalValueBlockValidation,
        unDangerousGoodsCodeBlockValidation,
    ],
};

export const cdsImportItemFieldsCardValidation = {
    selfValidators: [
        cdsImportDescriptionOfGoodsBlockValidation,
        itemUcrBlockValidation,
        countryOfDestinationCodeBlockProductValidation,
        countryOfDispatchExportCodeItemBlockValidation,
        netMassBlockOptionalValidation,
        supplementaryUnitsBlockValidation,
        cdsImportGrossMassBlockProductValidation,
        cdsImportStatisticalValueBlockValidation,
    ],
};

export const cdsImportRequiredNetMassItemFieldsCardValidation = {
    selfValidators: [
        cdsImportDescriptionOfGoodsBlockValidation,
        itemUcrBlockValidation,
        countryOfDestinationCodeBlockProductValidation,
        countryOfDispatchExportCodeItemBlockValidation,
        netMassBlockValidation,
        supplementaryUnitsBlockValidation,
        cdsImportGrossMassBlockProductValidation,
        cdsImportStatisticalValueBlockValidation,
    ],
};

const ItemFieldsCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');
    const { isFormType } = useHideEntities();

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <DescriptionOfGoodsBlock />

                <UcrBlock path="ucr" />

                <CountryOfDestinationCodeBlock path="destination" />

                <CountryOfDispatchExportCodeBlock hide={isFormType(['EXPORT_B1'])} path="exportCountry" />

                <NetMassBlock
                    declarationOptions={{
                        UK_EXPORT_B1: {
                            fieldOptions: { netNetWeightMeasure: { required: true } },
                        },
                        UK_IMPORT_H1: {
                            fieldOptions: { netNetWeightMeasure: { required: true } },
                        },
                    }}
                />

                <SupplementaryUnitsBlock />

                <GrossMassBlock path="commodity.goodsMeasure" isProduct />

                <StatisticalValueBlock />

                <UnDangerousGoodsCodeBlock hide={isFormType(['IMPORT_H1', 'IMPORT_H2'])} />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ItemFieldsCard;
