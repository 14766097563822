import { FC, useMemo } from 'react';
import { Alert } from './AlertInvalidationRequested.style';

interface Props {
    userInvalidationRequested?: boolean;
}

const AlertInvalidationRequested: FC<Props> = ({ userInvalidationRequested = false }) => {
    const message = useMemo(() => {
        return userInvalidationRequested && <Alert message="Invalidation has been requested" type="info" showIcon />;
    }, [userInvalidationRequested]);

    return <>{message}</>;
};
export default AlertInvalidationRequested;
