import { useFormik } from 'formik';
import useProducts from 'hooks/useProducts';
import React, { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import { ProductContext } from '../../../common/declaration-view/ProductContext';
import etdProductsDeclarationSkeleton from './etd-skeleton-product-declaration';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    selectCard?: (id: string) => void;
}

const EtdProductSection: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, selectCard }) => {
    const { productId, declarationId } = useParams<string>();
    const { getIrelandEnsProduct } = useProducts();
    const { setProductId } = useContext(ProductContext);

    //only load when user refresh the page.
    useEffect(() => {
        const fetchProduct = async (declarationId: string, productId: string) => {
            const product = await getIrelandEnsProduct(productId, declarationId);
            setProductId?.(productId);
            formik.setValues(product);
        };

        if (declarationId && productId && productId !== 'new') {
            const { values } = formik;

            if (values.id !== productId) {
                fetchProduct(declarationId, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId]);

    const cards = etdProductsDeclarationSkeleton.cards.map((obj, index) => {
        if (obj.type === MasterDetailsIrelandCardsType.SINGLE) {
            const card = React.createElement(
                obj.component as React.FC<DeclarationFormCardProps>,
                {
                    getFields: obj.getFields,
                    getFieldProps: formik.getFieldProps,
                    getFieldMeta: formik.getFieldMeta,
                    getFieldHelpers: formik.getFieldHelpers,
                    toggleHelp: toggleHelp,
                    cardTotal: etdProductsDeclarationSkeleton.cards.length,
                    cardNumber: obj.cardNumber,
                    defaultOpen: obj.defaultOpen,
                    keyCard: obj.key,
                    nestedComponents: obj.children,
                    key: obj.key,
                    propsPathPrefix: obj.propsPathPrefix,
                    viewOnly: viewOnly,
                    expandAll: expandAll,
                    declarationType: obj.declarationType,
                    selectCard: selectCard,
                    amendment: false,
                },
                obj.key
            );
            return card;
        }
        return <></>;
    });

    return <>{cards.map((obj) => obj)}</>;
};
export default EtdProductSection;
