import { CardContext, FieldData, useRegisterSection } from 'components/ui/composed/template/CardContext';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTemplateContext } from '../../../../components/ui/composed/template/TemplateContext';

const Section = styled.h3<{ template?: boolean }>`
    font-size: 18px;
    grid-column: 1/5;

    border-bottom: 1px solid #cecece;
    margin: 1rem 0;

    @media (max-width: 1800px) {
        grid-column: ${({ template }) => (template ? '1/5' : '1/3')};
    }
`;

type CardSectionProps = {
    title: string;
    children: ReactNode;
    hidden?: boolean;
};

const CardSection = ({ title, children, hidden: hiddenProp }: CardSectionProps): ReactElement | null => {
    const [fields, setFields] = useState<FieldData[]>([]);
    const { template } = useTemplateContext();

    const registerField = useCallback((fieldData: FieldData) => {
        setFields((prev) => {
            const index = prev.findIndex((el) => fieldData.path === el.path);
            if (index === -1) prev.push(fieldData);
            else prev[index] = fieldData;
            return [...prev];
        });
    }, []);

    useRegisterSection(fields);

    const hidden = useMemo(
        () => hiddenProp || (fields.length > 0 && fields.every((field) => field.hidden)),
        [fields, hiddenProp]
    );

    return (
        <CardContext.Provider value={{ registerField }}>
            {!hidden && <Section template={template}>{title}</Section>}
            {children}
        </CardContext.Provider>
    );
};

export default CardSection;
