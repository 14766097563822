import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import IrelandImportRemissionRefund from '../ireland/import/h1/refund/remissions/IrelandImportRemissionsRefunds';

const RemissionDeclaration: FC<{}> = () => {
    const { declaration } = useRemissionDeclarationContext();

    const remissionDeclaration = useMemo(() => {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return <IrelandImportRemissionRefund declaration={declaration} />;
        }

        return <></>;
    }, [declaration]);

    return remissionDeclaration;
};

export default RemissionDeclaration;

export function useRemissionDeclarationContext() {
    return useOutletContext<{
        declaration: Declaration;
    }>();
}
