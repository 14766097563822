import { Input, InputNumber } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
    /* font-size: 1.6rem;
    line-height: 2.4rem;
    width: 90%; */
`;

export const StyledNumberInput = styled(InputNumber)`
    /* font-size: 1.6rem;
    line-height: 3.5rem;
    width: 90%; */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    & {
        -moz-appearance: textfield;
    }
`;
