import config from 'config';
import mapboxgl from 'mapbox-gl';
import { FC, useEffect, useRef, useState } from 'react';
import { markerElement } from 'views/drivers/view-map/Map.styles';

const MobileLegView: FC = () => {
    //  const { listActiveWeighbridgeDevices } = useWeighbridges();
    // const [weighbridgeDevices, setWeighbridgeDevices] = useState<WeighbridgeDeviceResponse[]>([]);

    /* useEffect(() => {
        const requestActiveWeighbridgeDevices = async () => {
            const weighbridgesResponses = await listActiveWeighbridgeDevices();
            setWeighbridgeDevices(weighbridgesResponses.list);
        };

        requestActiveWeighbridgeDevices();
    }, [listActiveWeighbridgeDevices]); */

    const mapDiv = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<null | mapboxgl.Map>(null);

    useEffect(() => {
        const attachMap = (
            setMap: React.Dispatch<React.SetStateAction<any>>,
            mapDiv: React.RefObject<HTMLDivElement>
        ) => {
            if (!mapDiv.current) {
                return;
            }

            const map = new mapboxgl.Map({
                container: mapDiv.current || '', // NO ERROR
                style: 'mapbox://styles/mapbox/outdoors-v11',
                center: [-6.272250963000388, 53.348783340728005],
                zoom: 10,
                accessToken: config.mapboxglAccessToken,
            });

            const marker = new mapboxgl.Marker({ element: markerElement });
            marker.setLngLat([-6.272250963000388, 53.348783340728005]);
            marker.addTo(map);
        };

        !map && attachMap(setMap, mapDiv);

        const marker = new mapboxgl.Marker();
        marker.setLngLat([-6.272250963000388, 53.348783340728005]);
        if (map !== null) marker.addTo(map);
    }, [map]);

    return <div className="Map" ref={mapDiv} style={{ height: 550 }} />;
};

export default MobileLegView;
