import { PaginatedParams } from 'core/http/pagination';
import { useCallback, useEffect } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { UkProductTemplate } from 'store/products-templates/ukProducts';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doDeleteH1ProductTemplate,
    doDeleteUkProductTemplate,
    doGetH1Product,
    doGetUkProduct,
    doListH1Product,
    doListH1Tags,
    doListH7Tags,
    doListUkProduct,
    doSaveH1Product,
    doSaveUkProduct,
    doUpdateH1Product,
    doUpdateH7Product,
    doUpdateUkProduct,
    doSaveH7Product,
    doListH7Product,
    doGetH7Product,
    doDeleteH7ProductTemplate,
    doSaveCdsExportProduct,
    doListCdsExportProduct,
    doUpdateCdsExportProduct,
    doListCdsExportTags,
    doListEnsProducts,
    doSaveEnsProduct,
    doDeleteGeneralizedProductTemplate,
    doUpdateEnsProduct,
    doGetGeneralizedProductTemplate,
    doListEnsTags,
    doSaveAesProductTemplate,
    doListAesProductTemplates,
    doUpdateAesProduct,
    doListAesTags,
} from '../store/products-templates/action';
import { GeneralizedProductTemplate, ProductTemplate } from '../store/products-templates/products';
import { useTranslation } from 'react-i18next';
import useResponseNotification from 'hooks/useResponseNotification';
import { createProductTemplatesNotifs, editProductTemplatesNotifs } from '../utils/notificationMessages';
import { ListProductTemplateParams } from 'store/products-templates/client';
import { AISMessageTypes, ENSMessageTypes } from '../store/declarations/enums/common/template-types';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import useFormUtils from './useFormUtils';

interface UseProductsTemplatesProps {
    productId?: string;
}

const useProductsTemplates = ({ productId }: UseProductsTemplatesProps) => {
    const isLoading = useAppSelector((state) => state.productsTemplate.isLoading);
    const error = useAppSelector((state) => state.productsTemplate.error);
    const productsTemplates = useAppSelector((state) => state.productsTemplate.products);
    const productTemplate = useAppSelector((state) =>
        productId ? state.productsTemplate.entities[productId] : ({} as ProductTemplate)
    );
    const tagsList = useAppSelector((state) => state.productsTemplate.tags);
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');
    const { countryLowerCase: country, internalType, formType: messageType } = useFormUtils();

    const dispatch = useAppDispatch();

    const listH1ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH1Product(params)),
        [dispatch]
    );

    const listH7ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH7Product(params)),
        [dispatch]
    );

    const listCdsExportProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListCdsExportProduct(params)),
        [dispatch]
    );

    const listEnsProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListEnsProducts(params)),
        [dispatch]
    );

    const listAesProductsTemplates = useCallback(
        (params: ListProductTemplateParams) => dispatch(doListAesProductTemplates(params)),
        [dispatch]
    );

    const saveH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH1Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH7Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveCdsExportProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveEnsProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveAesProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(
                await dispatch(doSaveAesProductTemplate(product) as any),
                createProductTemplatesNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const editH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH1Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH7Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateCdsExportProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateEnsProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editAesProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateAesProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const getH1ProductTemplate = useCallback((productId: string) => dispatch(doGetH1Product(productId)), [dispatch]);

    const getH7ProductTemplate = useCallback((productId: string) => dispatch(doGetH7Product(productId)), [dispatch]);

    const getGeneralizedProductTemplate = useCallback(
        (productId: string) => dispatch(doGetGeneralizedProductTemplate(productId)),
        [dispatch]
    );

    const listUkProductTemplates = useCallback(
        (params?: Partial<PaginatedParams>) => dispatch(doListUkProduct(params)),
        [dispatch]
    );

    const saveUkProductTemplate = useCallback(
        async (product: UkProductTemplate) =>
            withResponseNotifications(dispatch(doSaveUkProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editUkProductTemplate = useCallback(
        async (product: UkProductTemplate) =>
            withResponseNotifications(dispatch(doUpdateUkProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const getUkProductTemplate = useCallback((productId: string) => dispatch(doGetUkProduct(productId)), [dispatch]);

    const listH1Tags = useCallback(() => dispatch(doListH1Tags()), [dispatch]);

    const listH7Tags = useCallback(() => dispatch(doListH7Tags()), [dispatch]);

    const listCdsExportTags = useCallback(() => dispatch(doListCdsExportTags()), [dispatch]);

    const listEnsTags = useCallback(() => dispatch(doListEnsTags()), [dispatch]);

    const listAesTags = useCallback((messageType: string) => dispatch(doListAesTags(messageType)), [dispatch]);

    const listProductTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for product templates listing function.');

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    listCdsExportProductsTemplates(params);
                } else {
                    listUkProductTemplates(params);
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    listAesProductsTemplates({ ...params, messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        listH7ProductsTemplates(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        listEnsProductsTemplates(params);
                    } else {
                        listH1ProductsTemplates(params);
                    }
                }
            }
        },
        [
            country,
            internalType,
            messageType,
            listH1ProductsTemplates,
            listH7ProductsTemplates,
            listUkProductTemplates,
            listCdsExportProductsTemplates,
            listEnsProductsTemplates,
            listAesProductsTemplates,
        ]
    );

    const saveProductTemplate = useCallback(
        (params: ProductTemplate): Promise<ProductTemplate | GeneralizedProductTemplate> => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for save product template function.');
            let response;

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = saveCdsExportProductTemplate(params);
                } else {
                    response = saveUkProductTemplate(params);
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = saveAesProductTemplate({ ...params, declarationName: messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        response = saveH7ProductTemplate(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        response = saveEnsProductTemplate(params);
                    } else {
                        response = saveH1ProductTemplate(params);
                    }
                }
            }

            return response;
        },
        [
            country,
            internalType,
            messageType,
            saveH1ProductTemplate,
            saveH7ProductTemplate,
            saveUkProductTemplate,
            saveCdsExportProductTemplate,
            saveEnsProductTemplate,
            saveAesProductTemplate,
        ]
    );

    const editProductTemplate = useCallback(
        (params: ProductTemplate): Promise<ProductTemplate | GeneralizedProductTemplate> => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for edit product template function.');
            let response;

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = editCdsExportProductTemplate(params);
                } else {
                    response = editUkProductTemplate(params);
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = editAesProductTemplate({ ...params, declarationName: messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        response = editH7ProductTemplate(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        response = editEnsProductTemplate(params);
                    } else {
                        response = editH1ProductTemplate(params);
                    }
                }
            }

            return response;
        },
        [
            country,
            internalType,
            messageType,
            editH1ProductTemplate,
            editH7ProductTemplate,
            editAesProductTemplate,
            editCdsExportProductTemplate,
            editEnsProductTemplate,
            editUkProductTemplate,
        ]
    );

    const deleteProductTemplate = useCallback(
        (productId: string): Promise<any> => {
            if (!(productId && country && internalType && messageType))
                throw new Error('No data provided for delete product template function.');

            if (internalType === DeclarationInternalType.EXPORT || internalType === DeclarationInternalType.ENS)
                return dispatch(doDeleteGeneralizedProductTemplate(productId));
            else {
                if (country === DeclarationCountry.UK) {
                    return dispatch(doDeleteUkProductTemplate(productId));
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        return dispatch(doDeleteH7ProductTemplate(productId));
                    } else {
                        return dispatch(doDeleteH1ProductTemplate(productId));
                    }
                }
            }
        },
        [internalType, country, dispatch, messageType]
    );

    const listProductTemplateTags = useCallback(() => {
        if (!(country && internalType && messageType))
            throw new Error('No data provided for product templates tags listing function.');

        if (country === DeclarationCountry.UK) {
            listCdsExportTags();
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                listAesTags(messageType);
            } else {
                if (messageType === AISMessageTypes.H7) {
                    listH7Tags();
                } else if (messageType === ENSMessageTypes.ENS) {
                    listEnsTags();
                } else {
                    listH1Tags();
                }
            }
        }
    }, [country, internalType, listAesTags, listCdsExportTags, listEnsTags, listH1Tags, listH7Tags, messageType]);

    useEffect(() => {
        if (!productId || productTemplate?.id === productId) return;

        if (internalType === DeclarationInternalType.EXPORT || internalType === DeclarationInternalType.ENS) {
            getGeneralizedProductTemplate(productId);
        } else {
            if (country === DeclarationCountry.UK) {
                getUkProductTemplate(productId);
            } else {
                if (messageType === AISMessageTypes.H7) {
                    getH7ProductTemplate(productId);
                } else {
                    getH1ProductTemplate(productId);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, messageType, country, internalType]);

    return {
        isLoading,
        error,
        productsTemplates,
        productTemplate,
        tagsList,
        listProductTemplates,
        deleteProductTemplate,
        saveProductTemplate,
        editProductTemplate,
        listProductTemplateTags,
    };
};

export default useProductsTemplates;
