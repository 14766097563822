import { ToFormikSchemaObject } from 'utils/ToFormikSchemaObject';
import * as Yup from 'yup';
import {
    WCOCompanyDetailsFormikFields,
    WCOLocation,
    WCOLocationsFormikFields,
    WCOUsersFormikFields,
} from '../WCOTypes';

// TODO Add correct validations
export const wcoCompanyDetailsValidationSchema = () =>
    Yup.object().shape<ToFormikSchemaObject<WCOCompanyDetailsFormikFields>>({
        companyName: Yup.string().required(),
        email: Yup.string().required(),
        addressLine1: Yup.string().required(),
        addressLine2: Yup.string(),
        phoneCode: Yup.string().required(),
        phoneNumber: Yup.string().required(),
        city: Yup.string().required(),
        country: Yup.string().required(),
    });

// TODO Add correct validations
export const wcoLocationsValidationSchema = () =>
    Yup.object().shape<ToFormikSchemaObject<WCOLocationsFormikFields>>({
        companyName: Yup.string().required(),
        locations: Yup.array().of(
            Yup.object().shape<ToFormikSchemaObject<WCOLocation>>({
                locationName: Yup.string().required(),
                address: Yup.string().required(),
            })
        ),
    });

export interface WCOUsersValidationSchemaProps {
    isEditing?: boolean;
}
// TODO Add correct validations
export const wcoUsersValidationSchema = ({ isEditing }: WCOUsersValidationSchemaProps = {}) =>
    Yup.object().shape<ToFormikSchemaObject<WCOUsersFormikFields>>({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().required(),
        phoneCode: Yup.string().required(),
        phoneNumber: Yup.string().required(),
        password: Yup.string().when([], {
            is: () => isEditing,
            then: Yup.string(),
            otherwise: Yup.string().required(),
        }),
        confirmPassword: Yup.string().when([], {
            is: () => isEditing,
            then: Yup.string(),
            otherwise: Yup.string().required(),
        }),
        role: Yup.string().required(),
        locations: Yup.array().min(1).required().of(Yup.string()),
        image: Yup.mixed().nullable(),
    });
