import { Col, Form, Modal, Row } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useDrivers from 'hooks/useDrivers';
import { FC, useState } from 'react';
import { Driver, DriverStatus } from 'store/drivers/driver';
import * as Yup from 'yup';
import Button from '../../../components/ui/base/button/Button';
import useNotification from '../../../hooks/useNotification';
import useSession from '../../../hooks/useSession';

interface Props {
    refreshCustomers: Function;
    closeModal: () => void;
    open: boolean;
    handleOk: () => void;
}

const formValidation = Yup.object().shape({
    fullName: Yup.string().required('Full name is missing.'),
    email: Yup.string().required('Email is missing.').email('Email is invalid'),
    address: Yup.object({
        addressLine1: Yup.string().required().nullable(),
        addressLine2: Yup.string().nullable(),
        postCode: Yup.string().required().nullable(),
        city: Yup.string().required().nullable(),
        country: Yup.string().required().nullable(),
    }),
    phoneNumber: Yup.number().required('Phone number is missing.'),
    phoneCode: Yup.string().required('Phone code is missing.'),
    insuranceCertificateNumber: Yup.string().required('Insurance certificate number is missing.'),
    driverLicenceNumber: Yup.string().required('Driver licence number is missing.'),
});

const AddDriver: FC<Props> = ({ refreshCustomers, open, closeModal, handleOk }) => {
    const [loading, setLoading] = useState(false);
    const { createDriver } = useDrivers();
    const { customerId } = useSession();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const { countries } = useCountries();
    const [form] = Form.useForm();

    const formik = useFormik({
        initialValues: {} as Driver,
        validateOnMount: false,
        validationSchema: formValidation,
        enableReinitialize: true,
        onSubmit: (values) => onClickCreateDriver(values),
    });

    const onClickCreateDriver = async (values: Partial<Driver>) => {
        setLoading(true);
        const driverRequest = { ...values, status: DriverStatus.NEW, customerId: customerId! };

        const driver: Driver = await createDriver(driverRequest as Driver);
        if (!driver) {
            setErrorNotification(
                'Error In Driver Creation',
                `An error occurred while creating a the driver ${values.fullName}.`
            );
        } else {
            setSuccessNotification('Driver create', 'New driver was created in the system.');
        }
        refreshCustomers();
        setLoading(false);
        closeModal();
    };

    return (
        <Modal
            title="Add Driver"
            visible={open}
            onOk={handleOk}
            onCancel={closeModal}
            centered
            footer={[
                <Button key="back" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={async () => {
                        formik.handleSubmit();
                    }}
                >
                    Confirm
                </Button>,
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                name="create-driver-form"
                initialValues={{
                    modifier: 'public',
                }}
            >
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Full Name"
                            fieldProps={formik.getFieldProps('fullName')}
                            fieldMeta={formik.getFieldMeta('fullName')}
                            fieldHelper={formik.getFieldHelpers('fullName')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Email Address"
                            fieldProps={formik.getFieldProps('email')}
                            fieldMeta={formik.getFieldMeta('email')}
                            fieldHelper={formik.getFieldHelpers('email')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormPhoneNumber
                            fieldNumberProps={formik.getFieldProps('phoneNumber')}
                            fieldCodeProps={formik.getFieldProps('phoneCode')}
                            fieldCodeMeta={formik.getFieldMeta('phoneCode')}
                            fieldNumberMeta={formik.getFieldMeta('phoneNumber')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Driver Licence Number"
                            fieldProps={formik.getFieldProps('driveLicenceNumber')}
                            fieldHelper={formik.getFieldHelpers('driveLicenceNumber')}
                            fieldMeta={formik.getFieldMeta('driveLicenceNumber')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Insurance Certificate Number"
                            fieldProps={formik.getFieldProps('insuranceCertificateNumber')}
                            fieldHelper={formik.getFieldHelpers('insuranceCertificateNumber')}
                            fieldMeta={formik.getFieldMeta('insuranceCertificateNumber')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Address Line1"
                            fieldProps={formik.getFieldProps('addressLine1')}
                            fieldHelper={formik.getFieldHelpers('addressLine1')}
                            fieldMeta={formik.getFieldMeta('addressLine1')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Address Line2"
                            fieldProps={formik.getFieldProps('addressLine2')}
                            fieldHelper={formik.getFieldHelpers('addressLine2')}
                            fieldMeta={formik.getFieldMeta('addressLine2')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="Post Code"
                            fieldProps={formik.getFieldProps('postCode')}
                            fieldHelper={formik.getFieldHelpers('postCode')}
                            fieldMeta={formik.getFieldMeta('postCode')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DeclarationInput
                            label="City"
                            fieldProps={formik.getFieldProps('city')}
                            fieldMeta={formik.getFieldMeta('city')}
                            fieldHelper={formik.getFieldHelpers('city')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormSelect
                            label="Coutry"
                            fieldProps={formik.getFieldProps('coutry')}
                            fieldMeta={formik.getFieldMeta('coutry')}
                            selectOptions={countries.list.map((obj) => {
                                return {
                                    id: obj.countryCode,
                                    value: obj.name,
                                };
                            })}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddDriver;
