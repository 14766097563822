export const declarationExternalTypeToCountry = (externalType: string | undefined) => {
    switch (externalType) {
        case 'REVENUE':
            return 'Ireland';
        case 'CDS':
            return 'Uk';
        default:
            return '';
        // throw new Error(`Can not parse external type to country: Value = ${externalType}`);
    }
};
