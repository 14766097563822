import {
    createImportH7RefundRemissionDeclaration,
    createRefundRemissionDeclaration,
    submitImportH7RefundRemissionDeclaration,
    submitRefundRemissionDeclaration,
    updateImportH7RefundRemissionDeclaration,
    updateRefundRemissionDeclaration,
} from '../client';
import { IrelandRefundRemission } from './ireland-refund-remission';

// Import (H1-H6)

export const doPostIrelandImportRemissionRefund: Function =
    (declarationId: string, body: IrelandRefundRemission) => async (dispatch: Function) => {
        dispatch({ type: 'POST_IRELAND_IMPORT_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await createRefundRemissionDeclaration(declarationId, body);
            dispatch({
                type: 'POST_IRELAND_IMPORT_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'POST_IRELAND_IMPORT_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doPutIrelandImportRemissionRefund: Function =
    (declarationId: string, body: IrelandRefundRemission, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'PUT_IRELAND_IMPORT_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await updateRefundRemissionDeclaration(declarationId, body, refundId);
            dispatch({
                type: 'PUT_IRELAND_IMPORT_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'PUT_IRELAND_IMPORT_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitIrelandImportRemissionRefund: Function =
    (declarationId: string, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_IRELAND_IMPORT_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await submitRefundRemissionDeclaration(declarationId, refundId);
            dispatch({
                type: 'SUBMIT_IRELAND_IMPORT_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_IRELAND_IMPORT_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };

// Import (H7)

export const doPostIrelandImportH7RemissionRefund: Function =
    (declarationId: string, body: IrelandRefundRemission) => async (dispatch: Function) => {
        dispatch({ type: 'POST_IRELAND_IMPORT_H7_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await createImportH7RefundRemissionDeclaration(declarationId, body);
            dispatch({
                type: 'POST_IRELAND_IMPORT_H7_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'POST_IRELAND_IMPORT_H7_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doPutIrelandImportH7RemissionRefund: Function =
    (declarationId: string, body: IrelandRefundRemission, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'PUT_IRELAND_IMPORT_H7_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await updateImportH7RefundRemissionDeclaration(declarationId, body, refundId);
            dispatch({
                type: 'PUT_IRELAND_IMPORT_H7_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'PUT_IRELAND_IMPORT_H7_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };

export const doSubmitIrelandImportH7RemissionRefund: Function =
    (declarationId: string, refundId: string) => async (dispatch: Function) => {
        dispatch({ type: 'SUBMIT_IRELAND_IMPORT_H7_REMISSION_REFUND_REQUEST' });
        try {
            const payload = await submitImportH7RefundRemissionDeclaration(declarationId, refundId);
            dispatch({
                type: 'SUBMIT_IRELAND_IMPORT_H7_REMISSION_REFUND_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'SUBMIT_IRELAND_IMPORT_H7_REMISSION_REFUND_ERROR', error: e?.response?.data });
        }
    };
