import MultiDeclarationInput from 'components/ui/composed/declarations/formInput/MultiDeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { appendFieldPath, getCardState, handleToggleHelp } from 'views/declarations/utils/form-utils';

const ContainerIdentificationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const fields = props.getFields ? props.getFields() : [];

    const fullFieldPath = appendFieldPath(props.propsPathPrefix);

    if (props.getFieldHelpers === undefined) {
        return <></>;
    }

    return (
        <section
            id={'ttsd-container-identification-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-container-identification-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('containerIdentificationNumbers')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <MultiDeclarationInput
                    viewOnly={props.viewOnly}
                    maxLength={17}
                    path={fullFieldPath(`containerIdentificationNumber`)}
                    refNumber="7.10"
                    label={t('containerIdentificationNumbers')}
                    tooltip={getH1TooltipsByRefNumberAndField('7.10', t('containerIdentificationNumbers'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    getFieldProps={(value: string) => props.getFieldProps(value)}
                    getFieldMeta={props.getFieldMeta}
                    fieldHelpers={props.getFieldHelpers(fullFieldPath(`containerIdentificationNumber`))}
                    getFieldHelpers={props.getFieldHelpers}
                />
            </FormCard>
        </section>
    );
};
export default ContainerIdentificationCard;
