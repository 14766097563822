import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { Package } from 'store/declarations/ireland/electronic-transport-document';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const ProducedDocumentsWritingOffCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { viewOnly } = props;
    const fields = props.getFields ? props.getFields() : [];
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    const formik = useFormik<Package>({
        initialValues: {},
        validationSchema: Yup.object().shape({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`${props.propsPathPrefix}.producedDocumentsWritingOff`),
            fieldMeta: props.getFieldMeta && props.getFieldMeta(`${props.propsPathPrefix}.producedDocumentsWritingOff`),
            fieldHelper: props.getFieldHelpers(`${props.propsPathPrefix}.producedDocumentsWritingOff`),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const producedDocumentsWritingOffHeader = useMemo(() => {
        const list = props.getFieldProps(`${props.propsPathPrefix}.producedDocumentsWritingOff`).value;
        if (Array.isArray(list)) {
            return list.map((obj: ProducedDocumentsWritingOffHeader, index) => {
                return [
                    { field: 'Previous document type', value: obj.documentType },
                    { field: 'Previous document identifier', value: obj.documentIdentifier },
                ];
            });
        }
        return [];
    }, [props]);

    return (
        <section
            id={'tsd-produced-documents-writing-off-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-produced-documents-writing-off-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('producedDocumentsWritingOff.title')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <CardList
                    data={producedDocumentsWritingOffHeader}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    viewOnly={viewOnly ?? false}
                />
                {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            required
                            maxLength={35}
                            {...getFormikProps(`documentIdentifier`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="2.3"
                            label={t('documentIdentifier')}
                            tooltip={getH1TooltipsByRefNumberAndField('2.3', 'documentIdentifier')}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};

export default ProducedDocumentsWritingOffCard;
