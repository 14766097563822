import { FC } from 'react';

const ExporterIcon: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 8L14 8M14 8L10.5 11.5M14 8L10.5 4.5" stroke="black" />
            <path d="M6 14L2 14L2 2L6 2" stroke="black" />
        </svg>
    );
};

export default ExporterIcon;
