import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { TextSmallBold } from 'components/ui/base/typography';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from '../../../../../store/declarations/enums/common/declaration-internal-type';
import { FilterCheckbox, FilterGroup, FilterOption } from './Filter.styles';

const declarationTypeMap = {
    [DeclarationCountry.IRELAND]: {
        [DeclarationInternalType.IMPORT]: ['H1', 'H7'],
        [DeclarationInternalType.EXPORT]: ['B1', 'B2', 'B3', 'B4', 'C1', 'C2'],
        [DeclarationInternalType.ENS]: ['ENS'],
    },
    [DeclarationCountry.UK]: {
        [DeclarationInternalType.IMPORT]: ['H1', 'H2'],
        [DeclarationInternalType.EXPORT]: ['B1'],
    },
} as Record<DeclarationCountry, Record<DeclarationInternalType, string[]>>;

interface Props {
    value: CheckboxValueType[];
    selectedType: (status: string[]) => void;
}

export const DeclarationTypeFilter: FC<Props> = ({ selectedType, value }) => {
    const { country, type } = useParams<{ country: DeclarationCountry; type: DeclarationInternalType }>();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        selectedType(checkedValues as string[]);
    };

    return (
        <FilterGroup onChange={onChange} value={value || ''}>
            {country &&
                type &&
                declarationTypeMap[country][type].map((t, i) => (
                    <FilterOption
                        key={`declaration-type-filter-${i}`}
                        data-testid={`${t?.toLowerCase()}-declaration-type-option`}
                    >
                        <FilterCheckbox value={t} data-testid={`${t?.toLowerCase()}-declaration-type-checkbox`}>
                            <TextSmallBold>{t}</TextSmallBold>
                        </FilterCheckbox>
                    </FilterOption>
                ))}
        </FilterGroup>
    );
};

export default DeclarationTypeFilter;
