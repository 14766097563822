import { useMemo } from 'react';
import { DeclarationExternalEntity } from '../store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import { getDeclarationKey } from '../views/declarations/utils/declaration-utils';
import declarationViewMap, { DeclarationViewMapValues } from '../views/declarations/utils/declarationViewMap';
import useFormUtils from './useFormUtils';

interface Props {
    declarationInternalType?: DeclarationInternalType;
    declarationExternalEntity?: DeclarationExternalEntity;
    h7?: boolean;
}

const useGetDeclarationViewMapValues = ({ options }: { options?: Props } = {}) => {
    const { internalType, formType, externalEntity } = useFormUtils();

    const declarationViewMapValues = useMemo(() => {
        const declarationKeyValues = options ?? {
            declarationInternalType: internalType as DeclarationInternalType,
            declarationExternalEntity: externalEntity as DeclarationExternalEntity,
            h7: formType === 'H7',
        };
        if (!declarationKeyValues.declarationExternalEntity) return {} as DeclarationViewMapValues<any>;

        const declarationKey = getDeclarationKey(declarationKeyValues);
        if (declarationKey === null) return {} as DeclarationViewMapValues<any>;

        const declarationViewValues = declarationViewMap[declarationKey];

        if (formType && 'default' in declarationViewValues && formType in declarationViewValues) {
            const newDeclarationViewValues = {};

            Object.assign(newDeclarationViewValues, declarationViewValues['default']);
            Object.assign(newDeclarationViewValues, declarationViewValues[formType]);

            return newDeclarationViewValues as DeclarationViewMapValues<any>;
        }

        return declarationViewValues as DeclarationViewMapValues<any>;
    }, [internalType, formType, externalEntity, options]);

    return declarationViewMapValues;
};

export default useGetDeclarationViewMapValues;
