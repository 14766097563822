import CheckCircleIcon from 'assets/check-circle.svg';
import { FC } from 'react';
import { InvitePageProps, OnboardingSection } from 'views/authentication/invite/Invite';
import {
    ButtonDiv,
    ContentDiv,
    ContinueButton,
    DivDDFeatures,
    DivRtl,
    Image,
    RowDiv,
    Subtitle,
    Text,
} from 'views/authentication/invite/sections/common/CheckList.styles';

interface HaulierFeaturesProps extends InvitePageProps {}

const HaulierFeatures: FC<HaulierFeaturesProps> = ({ onChangePage }) => {
    return (
        <>
            <DivDDFeatures>
                <Subtitle>Ddispatch features...</Subtitle>
            </DivDDFeatures>
            <ContentDiv>
                <RowDiv>
                    <Text>Accept Jobs </Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Create Cost Estimates</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Manage Drivers</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
                <RowDiv>
                    <Text>Manage Fleets</Text>
                    <DivRtl>
                        <Image src={CheckCircleIcon} alt="check" />
                    </DivRtl>
                </RowDiv>
            </ContentDiv>
            <ButtonDiv>
                <ContinueButton
                    type="primary"
                    onClick={() => {
                        onChangePage(OnboardingSection.INTRO);
                    }}
                    size="large"
                >
                    Continue
                </ContinueButton>
            </ButtonDiv>
        </>
    );
};
export default HaulierFeatures;
