import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { handleRequestActionsSinglePayloadProperty } from 'store/declarations/common/handle-request';
import { RefundsActions } from './actionType';
import { IrelandRefundRemissionHistory } from './ireland-refund-remission';

export interface RefundsState {
    isLoading: boolean;
    error?: ErrorResponse;
    refund?: IrelandRefundRemissionHistory;
}

export const initialRefundsState: RefundsState = {
    isLoading: false,
};

const refunds: Reducer<RefundsState, RefundsActions> = (state = initialRefundsState, action): RefundsState => {
    return (
        handleRequestActionsSinglePayloadProperty(action, state, 'refund', [
            // Import (H1-H6)
            'POST_IRELAND_IMPORT_REMISSION_REFUND',
            'PUT_IRELAND_IMPORT_REMISSION_REFUND',
            'SUBMIT_IRELAND_IMPORT_REMISSION_REFUND',
            // Import (H7)
            'POST_IRELAND_IMPORT_H7_REMISSION_REFUND',
            'PUT_IRELAND_IMPORT_H7_REMISSION_REFUND',
            'SUBMIT_IRELAND_IMPORT_H7_REMISSION_REFUND',
        ]) ?? state
    );
};

export default refunds;
