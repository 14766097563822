import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { useState } from 'react';

interface Props {
    formikProps: {
        fieldProps: FieldInputProps<any>;
        fieldMeta: FieldMetaProps<any> | undefined;
        fieldHelper: FieldHelperProps<any>;
    };
    setValues: Function;
    resetForm: Function;
}
const useModalMultipleCards = ({ formikProps, setValues, resetForm }: Props) => {
    const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
    const [modalVisible, setModalVisible] = useState(false);

    const handleEdit = (index: number) => {
        const list = formikProps.fieldProps.value as unknown[];
        const item = list[index];
        if (item) {
            setEditIndex(index);
            setValues(item);
            setModalVisible(true);
        }
    };

    const handleDelete = (index: number) => {
        const list = formikProps.fieldProps.value as unknown[];
        const newArray = list.filter((obj, i) => i !== index);
        formikProps.fieldHelper.setValue(newArray);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditIndex(undefined);
    };

    const onClickAddItem = () => {
        setModalVisible(true);
        setEditIndex(undefined);
        resetForm();
    };

    const addItem = (values: unknown): { didEdit: boolean } => {
        setModalVisible(false);
        const declarationValues = (formikProps.fieldProps.value as unknown[]) ?? [];
        if (editIndex === undefined) {
            formikProps.fieldHelper.setValue([...declarationValues, values]);
        } else {
            const listItems = [...declarationValues];
            listItems[editIndex] = values;
            formikProps.fieldHelper.setValue(listItems);
            resetForm();
        }

        return { didEdit: editIndex !== undefined };
    };

    return {
        editIndex,
        handleEdit,
        modalVisible,
        setModalVisible,
        handleDelete,
        handleCancel,
        onClickAddItem,
        addItem,
    };
};

export default useModalMultipleCards;
