import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import addPathPrefix from 'utils/addPathPrefix';
import { validators } from 'views/declarations/uk/export/validations/validations';

const PreviousDocumentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    const { aisCodelists } = useCodelists();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix ?? 'goodsShipment.documentsAuthorisations.previousDocument'}
            initialValues={{ previousDocumentReferenceNumber: '', previousDocumentType: '' }}
            list={(list) => [
                { field: t('referenceNumber'), value: list.previousDocumentReferenceNumber },
                { field: t('documentType'), value: list.previousDocumentType },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('previousDocument')}
            condensed
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormInput
                        maxLength={70}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentReferenceNumber'), formProps)}
                        refNumber="2.1"
                        label={t('referenceNumber')}
                        tooltip={getH7TooltipsByRefNumberAndField('2.1', t('referenceNumber'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formProps)}
                        refNumber="2.1"
                        label={t('documentType')}
                        selectOptions={aisCodelists?.previousDocumentType}
                        tooltip={getH7TooltipsByRefNumberAndField('2.1', t('documentType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                        codeValidation={[validators.maxLength(4)]}
                    />
                </FormCardContainer>
            )}
        />
    );
};

export default PreviousDocumentCard;
