import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { FormikProps, FormikProvider, setNestedObjectValues } from 'formik';
import Fuse from 'fuse.js';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationProducts from 'hooks/useDeclarationProducts';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { ProductTemplate } from 'store/products-templates/products';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { StyledHeader } from 'views/declarations/Form.styles';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import { Box44Context } from 'views/declarations/common/box44/Box44';
import IrelandImportH7DeclarationUtils from './utils';
import useDeclarations from 'hooks/useDeclarations';
import { box44PathAndFieldNames } from '../../../common/box44/box-44-utils';
import { ProductContext } from '../../../common/declaration-view/ProductContext';
import { FormSection } from '../../../common/declaration-view/DeclarationView';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
    declarationErrors: ProductsErrors;
}

const IrelandH7TabContent: FC<Props> = ({
    formik,
    productsFormik,
    declaration,
    cancelProducts,
    clearCancel,
    declarationErrors,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const { declarationId }: { declarationId?: string } = useParams();
    const { declarationTemplate } = useDeclarations();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
    );
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const [searchQuery, setSearchQuery] = useState('');
    const [myProducts, setMyProducts] = useState<H7GoodsShipmentItem[]>([]);
    const controlProduct = useContext(ProductContext);
    const {
        deleteIrelandH7ImportProduct,
        products,
        listIrelandH7Products,
        createIrelandH7ImportProduct,
        getIrelandH7Product,
    } = useProducts();
    const { addProductsTemplatesToIrelandH7 } = useDeclarationProducts();
    const { declarationValidation, setFormAction } = useDeclarationValidation();

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAILS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        expandDeclarationFooter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    useEffect(() => {
        if (products?.total) {
            const myProducts = products?.list as H7GoodsShipmentItem[];
            setMyProducts(myProducts);
        }
    }, [products]);

    useEffect(() => {
        if (cancelProducts) {
            productsFormik.resetForm();
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    useEffect(() => {
        if (searchQuery && products?.list) {
            const array = [...products.list];
            const fuse = new Fuse(array, {
                keys: ['itemAmount', 'descriptionOfGoods', 'commodityCodeHarmonizedSystemSubHeadingCode'],
            });
            const res: unknown[] = fuse.search(searchQuery);
            setMyProducts(res as H7GoodsShipmentItem[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const addNewProduct = useCallback(async () => {
        controlProduct.setProductId?.(undefined);
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        if (!declarationId) return;
        const product = await createIrelandH7ImportProduct(
            declarationTemplate?.template?.product.defaults ?? ({} as any),
            declarationId
        );
        listIrelandH7Products(declarationId);
        navigate(`/declarations/${declarationId}/products/${product.id}`);
    }, [
        controlProduct,
        productsFormik,
        setDeclarationFooterType,
        declarationId,
        createIrelandH7ImportProduct,
        declarationTemplate?.template?.product.defaults,
        listIrelandH7Products,
        navigate,
    ]);

    const handleAddProducts = async (products: ProductTemplate[]) => {
        setShowProducts(false);
        const productTemplatesIds = products.map((obj) => obj.id!);
        const response = await addProductsTemplatesToIrelandH7(declaration.id!, productTemplatesIds);
        if (response && declarationId) {
            Notification({
                type: 'success',
                messageTitle: 'Product Added',
                description: 'Product Template has been successfully added to declaration!',
            });
            await listIrelandH7Products(declarationId);
            navigate(`/declarations/${declarationId}/products`);
        }
    };

    const handleDeclarationView = useMemo(() => {
        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };

        const handleDeleteProducts = async (id: string) => {
            setShowProducts(false);
            const response = await deleteIrelandH7ImportProduct(id, declaration?.id!);
            if (response && declarationId) {
                Notification({
                    type: 'success',
                    messageTitle: 'Product Deleted',
                    description: 'Product has been successfully deleted!',
                });
                return await listIrelandH7Products(declarationId);
            }
        };

        const handleEditProducts = async (id: string) => {
            if (declarationHasItems) {
                const product = await getIrelandH7Product(declaration.id!, id);
                if (product) {
                    if (controlProduct.setProductId) {
                        controlProduct.setProductId(product.id);
                    }
                    productsFormik.setValues(IrelandImportH7DeclarationUtils.transformProductForClient(product));
                    if (!!declarationValidation.pressedSubmit) {
                        await productsFormik.validateForm().then((v) => {
                            productsFormik.setTouched(setNestedObjectValues(v, true));
                            return v;
                        });
                    }
                    navigate(`/declarations/${declarationId}/products/${product.id}`);
                }
            }
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    setSearchQuery,
                    errors: declarationErrors,
                    data: myProducts,
                    declarationType: DeclarationName.H7,
                    onDelete: handleDeleteProducts,
                    onEdit: handleEditProducts,
                    pageNumber: products?.pageNumber ?? 0,
                    itemsNumber: declarationHasItems,
                }}
            />
        );
    }, [
        formik,
        productsFormik,
        declarationErrors,
        myProducts,
        products?.pageNumber,
        declarationHasItems,
        deleteIrelandH7ImportProduct,
        declaration.id,
        declarationId,
        listIrelandH7Products,
        getIrelandH7Product,
        controlProduct,
        declarationValidation.pressedSubmit,
        navigate,
    ]);

    const box44Path = useMemo(
        () =>
            !location.pathname.includes('products')
                ? box44PathAndFieldNames.H7.masterPath
                : box44PathAndFieldNames.H7.productPath,
        [location.pathname]
    );
    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                declarationType={DeclarationName.H7}
                internalType={DeclarationInternalType.IMPORT}
                declarationCountry={DeclarationCountry.IRELAND}
            />
            <StyledHeader>
                {formikForProvider && (
                    /**
                     * Check if DeclarationContentComponent has returned correct content.
                     * This is needed when we switch between different tabs (Form, Documentation etc..)
                     */
                    <FormikProvider value={formikForProvider}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44PathAndFieldNames.H7.documentTypeName,
                                documentIdentifierName: box44PathAndFieldNames.H7.documentIdentifierName,
                                path: box44Path,
                            }}
                        >
                            <MasterProductDeclarationNav
                                selectedBt={selectedBt}
                                addNewProduct={addNewProduct}
                                setShowProducts={setShowProducts}
                                country={DeclarationCountry.IRELAND}
                            />
                        </Box44Context.Provider>
                    </FormikProvider>
                )}
            </StyledHeader>
            <DeclarationFormTabContent>{handleDeclarationView}</DeclarationFormTabContent>
            <Drawer
                title="View all H7 product templates"
                width="786"
                visible={showProducts}
                onClose={() => {
                    setShowProducts(false);
                }}
            >
                {declaration && showProducts && (
                    <DeclarationsProductsTemplates
                        addProductsToDeclaration={handleAddProducts}
                        declarationTemplate={declarationTemplate}
                    />
                )}
            </Drawer>
        </>
    );
};
export default IrelandH7TabContent;
