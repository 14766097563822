import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import ContainerBlock, { containerBlockValidation } from '../blocks/ContainerBlock';
import GrossMassBlock, { cdsImportGrossMassBlockValidation, grossMassBlockValidation } from '../blocks/GrossMassBlock';
import TotalPackagesBlock, {
    cdsImportTotalPackagesBlockValidation,
    totalPackagesBlockValidation,
} from '../blocks/TotalPackagesBlock';

export const grossMassCardValidation = {
    selfValidators: [grossMassBlockValidation, totalPackagesBlockValidation, containerBlockValidation],
};

export const cdsImportGrossMassCardValidation = {
    selfValidators: [
        cdsImportGrossMassBlockValidation,
        cdsImportTotalPackagesBlockValidation,
        containerBlockValidation,
    ],
};

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'totalGrossMassMeasure' | 'totalPackageQuantity' | 'containerCode'>;
}

const GrossMassCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('goodsItems.grossMass')} hidden={props.hidden}>
            <FormCardContainer>
                <GrossMassBlock />
                <TotalPackagesBlock />
                <ContainerBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GrossMassCard;
