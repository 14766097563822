import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC } from 'react';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';

interface Address<TType> {
    addressLine1: TType;
    addressLine2: TType;
    postCode: TType;
    city: TType;
    country: TType;
}
// FieldHelperProps
interface Props {
    fieldProps: Address<FieldInputProps<any>>;
    fieldMeta?: Address<FieldMetaProps<any>>;
    fieldHelper?: Address<FieldHelperProps<any>>;
    label?: Address<string>;
    icon?: boolean;
    required?: Partial<Address<boolean>>;
}

const CustomerAddressForm: FC<Props> = ({ fieldProps, fieldMeta, fieldHelper, label, icon, required }) => {
    return (
        <>
            <FormInput
                required={required?.addressLine1}
                label={label?.addressLine1}
                fieldProps={fieldProps.addressLine1}
                fieldMeta={fieldMeta?.addressLine1}
                fieldHelper={fieldHelper?.addressLine1}
            />

            <FormInput
                label={`${label?.addressLine2}`}
                fieldProps={fieldProps.addressLine2}
                fieldMeta={fieldMeta?.addressLine2}
                fieldHelper={fieldHelper?.addressLine2}
            />

            <FormInput
                required={required?.postCode}
                label={label?.postCode}
                fieldProps={fieldProps.postCode}
                fieldMeta={fieldMeta?.postCode}
                fieldHelper={fieldHelper?.postCode}
            />

            <FormInput
                required={required?.city}
                label={label?.city}
                fieldProps={fieldProps.city}
                fieldMeta={fieldMeta?.city}
                fieldHelper={fieldHelper?.city}
            />

            <CountrySelect
                required={required?.country}
                icon={icon}
                label={label?.country}
                fieldProps={fieldProps.country}
                fieldMeta={fieldMeta?.country}
                fieldHelper={fieldHelper?.country}
            />
        </>
    );
};
export default CustomerAddressForm;
