import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { GoodsShipment as TsdGoodsShipmentItem } from 'store/declarations/ireland/temporary-storage-declaration';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import axiosClient from '../../config/axios';

export const createIrelandImportProduct = (
    product: H1GoodsShipmentItem,
    declarationId: string
): Promise<H1GoodsShipmentItem> =>
    axiosClient
        .post<SuccessResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const updateIrelandImportProduct = (
    product: H1GoodsShipmentItem,
    declarationId: string,
    productId: string
): Promise<H1GoodsShipmentItem> =>
    axiosClient
        .put<SuccessResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const deleteIrelandImportProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => {
            return response.data;
        });

export const createIrelandH7ImportProduct = (
    product: H7GoodsShipmentItem,
    declarationId: string
): Promise<H7GoodsShipmentItem> =>
    axiosClient
        .post<SuccessResponse<H7GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/products`,
            product
        )
        .then((response) => response.data.payload);

export const deleteIrelandH7ImportProduct = (
    productId: string,
    declarationId: string
): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/products/${productId}`
        )
        .then((response) => {
            return response.data;
        });

export const updateIrelandH7ImportProduct = (
    product: H7GoodsShipmentItem,
    declarationId: string,
    productId: string
): Promise<H7GoodsShipmentItem> =>
    axiosClient
        .put<SuccessResponse<H7GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const createIrelandExportProduct = (
    product: IrelandExportItem,
    declarationId: string
): Promise<IrelandExportItem> =>
    axiosClient
        .post<SuccessResponse<IrelandExportItem>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const updateIrelandExportProduct = (
    product: IrelandExportItem,
    declarationId: string,
    productId: string
): Promise<IrelandExportItem> =>
    axiosClient
        .put<SuccessResponse<IrelandExportItem>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const deleteIrelandExportProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => {
            return response.data;
        });

export const deleteUkImportProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/uk/import/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => {
            return response.data;
        });

export const createUkExportProduct = (
    product: CdsExportGovernmentAgencyGoodsItem,
    declarationId: string
): Promise<CdsExportGovernmentAgencyGoodsItem> =>
    axiosClient
        .post<SuccessResponse<CdsExportGovernmentAgencyGoodsItem>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const updateUkExportProduct = (
    product: CdsExportGovernmentAgencyGoodsItem,
    declarationId: string,
    productId: string
): Promise<CdsExportGovernmentAgencyGoodsItem> =>
    axiosClient
        .put<SuccessResponse<CdsExportGovernmentAgencyGoodsItem>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const deleteUkExportProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => {
            return response.data;
        });

export const createIrelandEnsProduct = (
    product: EnsGoodsShipmentItem,
    declarationId: string
): Promise<EnsGoodsShipmentItem> =>
    axiosClient
        .post<SuccessResponse<EnsGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const updateIrelandEnsProduct = (
    product: EnsGoodsShipmentItem,
    declarationId: string
): Promise<EnsGoodsShipmentItem> =>
    axiosClient
        .put<SuccessResponse<EnsGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/products/${product.id}`,
            product
        )
        .then((response) => response.data.payload);

export const deleteIrelandEnsProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data);

export const getIrelandEnsProduct = (productId: string, declarationId: string): Promise<EnsGoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<EnsGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const listIrelandEnsProducts = (
    declarationId: string,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<EnsGoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<EnsGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/ens/declarations/${declarationId}/products`,
            { params }
        )
        .then((response) => response.data.payload);

export const listIrelandH1ImportProduct = (
    declarationId: string,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<H1GoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/products`,
            { params }
        )
        .then((response) => response.data.payload);

export const getIrelandH1Product = (declarationId: string, productId: string): Promise<H1GoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const listIrelandH7ImportProduct = (
    declarationId: string,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<H1GoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/products`,
            { params }
        )
        .then((response) => response.data.payload);

export const listUkExportProducts = (declarationId: string): Promise<ListPayload<CdsExportGovernmentAgencyGoodsItem>> =>
    axiosClient
        .get<ListResponse<CdsExportGovernmentAgencyGoodsItem>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/products`
        )
        .then((response) => response.data.payload);

export const getIrelandH7Product = (declarationId: string, productId: string): Promise<H1GoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/products/${productId}`
        )
        .then((response) => response.data.payload);

export const listIrelandExportProduct = (
    declarationId: string,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<H1GoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/products`,
            { params }
        )
        .then((response) => response.data.payload);

export const getIrelandExportProduct = (declarationId: string, productId: string): Promise<H1GoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<H1GoodsShipmentItem>>(
            `${config.declarationsUrl}/ie/export/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const getUkImportProduct = (declarationId: string, productId: string): Promise<any> =>
    axiosClient
        .get<SuccessResponse<any>>(
            `${config.declarationsUrl}/uk/import/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const getUkExportProduct = (
    declarationId: string,
    productId: string
): Promise<CdsExportGovernmentAgencyGoodsItem> =>
    axiosClient
        .get<SuccessResponse<CdsExportGovernmentAgencyGoodsItem>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const createIrelandTsdProduct = (
    product: TsdGoodsShipmentItem,
    declarationId: string
): Promise<TsdGoodsShipmentItem> =>
    axiosClient
        .post<SuccessResponse<TsdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const deleteIrelandTsdProduct = (productId: string, declarationId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}/products/${productId}`
        )
        .then((response) => response.data);

export const updateIrelandTsdProduct = (
    product: TsdGoodsShipmentItem,
    declarationId: string,
    productId: string
): Promise<TsdGoodsShipmentItem> =>
    axiosClient
        .put<SuccessResponse<TsdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const listIrelandTsdProductsOfDeclaration = (
    declarationId: string
): Promise<ListPayload<TsdGoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<TsdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}/products`
        )
        .then((response) => response.data.payload);

export const getIrelandTsdProduct = (declarationId: string, productId: string): Promise<TsdGoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<TsdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/temporary-storage/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);

export const createIrelandEtdProduct = (
    product: EtdGoodsShipmentItem,
    declarationId: string
): Promise<EtdGoodsShipmentItem> =>
    axiosClient
        .post<SuccessResponse<EtdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/etd/${declarationId}/products`,
            product
        )
        .then((response) => response.data.payload);

export const updateIrelandEtdProduct = (
    product: EtdGoodsShipmentItem,
    declarationId: string,
    productId: string
): Promise<EtdGoodsShipmentItem> =>
    axiosClient
        .put<SuccessResponse<EtdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/etd/${declarationId}/products/${productId}`,
            product
        )
        .then((response) => response.data.payload);

export const listIrelandEtdProductsOfDeclaration = (
    declarationId: string
): Promise<ListPayload<EtdGoodsShipmentItem>> =>
    axiosClient
        .get<ListResponse<EtdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/etd/${declarationId}/products`
        )
        .then((response) => response.data.payload);

export const getIrelandEtdProduct = (declarationId: string, productId: string): Promise<EtdGoodsShipmentItem> =>
    axiosClient
        .get<SuccessResponse<EtdGoodsShipmentItem>>(
            `${config.declarationsUrl}/ireland/import/etd/${declarationId}/products/${productId}`
        )
        .then((response) => response.data.payload);
