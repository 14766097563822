import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from 'hooks/useProducts';
import { useOutletContext } from 'react-router-dom';

type Fields = 'goodsItemQuantity';

export const totalNumberOfItemsBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        goodsItemQuantity: [
            validators.required(),
            validators.number(),
            validators.min(1),
            validators.maxLength(3),
            async (_, b) => {
                const itemsLength = b.getValues().goodsShipment?.governmentAgencyGoodsItem?.length;
                const goodsItemQuantity = b.getValues().goodsItemQuantity;
                if (itemsLength === undefined) return undefined;
                if (Number(itemsLength) !== Number(goodsItemQuantity)) {
                    return 'Number of items from the input does not match the number of items in the declaration.';
                }
                return undefined;
            },
        ],
    },
};

interface Props extends BlockProps<Fields> {}

const TotalNumberOfItemsBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { createUkExportProduct, listUkExportProducts } = useProducts();
    const { saveAsDraft } =
        useOutletContext<{ saveAsDraft: (withNotification: boolean, data?: any) => Promise<any> }>() ?? {};

    const ucc = useMemo(() => '1/9', []);

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createUkExportProduct,
        listProducts: listUkExportProducts,
        saveAsDraft,
    });

    return (
        <>
            <DeclarationField name="goodsItemQuantity">
                {({ form, field }) => (
                    <DeclarationNumberInput
                        required
                        {...getFormikProps(field.name, form)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        refNumber={ucc}
                        label={t('numberOfItems')}
                        condensed
                    />
                )}
            </DeclarationField>
            {modalContextHolder}
        </>
    );
};

export default TotalNumberOfItemsBlock;
