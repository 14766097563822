import { Reducer } from 'redux';
import { GlobalOverlayActions } from './actionType';
import { GlobalOverlayState } from './globalOverlay';

export const globalOverlayInitialState: Readonly<GlobalOverlayState> = {
    show: false,
    type: null,
};

const globalOverlay: Reducer<GlobalOverlayState, GlobalOverlayActions> = (
    state = globalOverlayInitialState,
    action: GlobalOverlayActions
): GlobalOverlayState => {
    switch (action.type) {
        case 'SHOW_GLOBAL_OVERLAY':
            return {
                show: true,
                type: action.overlayType,
                payload: action.payload,
            };
        case 'HIDE_GLOBAL_OVERLAY':
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
};

export default globalOverlay;
