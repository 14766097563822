import logo from 'assets/logo-login.svg';
import { FC } from 'react';
import { AuthenticationFormDiv, AuthLogo } from '../Auth.styles';
import SignInForm from './SignInForm';

const SignIn: FC = () => {
    return (
        <AuthenticationFormDiv>
            <AuthLogo>
                <img src={logo} alt="DDispatch" />
            </AuthLogo>
            <SignInForm />
        </AuthenticationFormDiv>
    );
};
export default SignIn;
