import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

const ValuationInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('valuationInformation.title')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationAmount`,
                        props
                    )}
                    refNumber="4.19"
                    label={t('valuationInformation.transportAndInsuranceCostsToTheDestinationAmount')}
                    refTooltip={getH7TooltipsByRefNumberAndField('4.19', t('amount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationCurrency`,
                        props
                    )}
                    label={t('valuationInformation.transportAndInsuranceCostsToTheDestinationCurrency')}
                    refNumber="4.19"
                    tooltip={getH7TooltipsByRefNumberAndField('4.19', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`itemAmountInvoicedIntrinsicValue.valueAmount`, props)}
                    label={t('valuationInformation.valueAmount')}
                    refNumber="4.18"
                    refTooltip={getH7TooltipsByRefNumberAndField('4.18', t('amount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`itemAmountInvoicedIntrinsicValue.valueCurrency`, props)}
                    label={t('valuationInformation.valueCurrency')}
                    refNumber="4.18"
                    tooltip={getH7TooltipsByRefNumberAndField('4.18', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ValuationInformationCard;
