import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { ModalProps, notification } from 'antd';
import axiosClient from 'config/axios';
import { SuccessResponse } from '../../../../core/http/response';
import { Declaration } from '../../../../store/declarations/declaration';
import config from '../../../../config';
import useDeclarations from '../../../../hooks/useDeclarations';

interface Props extends ModalProps {
    onClose?: () => void;
    onRevert?: () => void;
}

const MirrorSeveringModal = ({ visible, onClose, onRevert }: Props) => {
    const { declaration, getDeclaration } = useDeclarations();

    const severDeclarationMirroring = (declarationId: string) =>
        axiosClient
            .put<SuccessResponse<Declaration>>(`${config.declarationsUrl}/declarations/${declarationId}/unlink`)
            .then(() =>
                notification.success({
                    message: 'Declaration is no longer mirrored.',
                })
            )
            .catch(() =>
                notification.error({
                    message: 'An error occurred! Declaration unlink failed.',
                })
            )
            .finally(() => onClose?.());

    const handleConfirm = async () => {
        if (!declaration?.id) return;
        await severDeclarationMirroring(declaration.id);
        await getDeclaration(declaration.id);
    };

    return (
        <CustomModal
            title={<H5>Do yo want to remove the mirroring of this declaration?</H5>}
            centered
            visible={visible}
            onOk={handleConfirm}
            onCancel={() => {
                onClose?.();
                onRevert?.();
            }}
            width={762}
            confirmText="Remove"
            cancelText="Cancel"
            contentText={'If you edit this field, the mirroring will be removed.'}
        />
    );
};

export default MirrorSeveringModal;
