import { PaginatedParams } from 'core/http/pagination';

export interface Customer {
    id: string;
    name: string;
    address: Address;
    website: string;
    email?: string;
    phone?: string;
    hasAisCertificate?: boolean;
    hasAesCertificate?: boolean;
    hasAepIcsCertificate?: boolean;
    type: string;
    brokerIds: string[];
    brokerOfIds: string[];
    eori: string;
    vat: string;
    referenceCode?: string;
    deferredAccountNumber?: string;
    specialInstructions?: string;
    traderAccountNumber?: string;
    vatNumber?: string;
    contact?: CustomerContact;
}

export interface CustomerContact {
    name?: string;
    email?: string;
    phoneNumber?: string;
    phoneCountryCode?: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: string;
    country: string;
}

export enum CustomerType {
    TRADER = 'TRADER',
    CUSTOMS_BROKER = 'CUSTOMS_BROKER',
    HAULIER = 'HAULIER',
}

export const customerTypeLabels = [
    { id: CustomerType.TRADER, value: 'Trader ' },
    { id: CustomerType.CUSTOMS_BROKER, value: 'Customs Broker' },
    { id: CustomerType.HAULIER, value: 'Haulier' },
] as const;

export enum CustomerSortParameter {
    ID = 'ID',
    NAME = 'NAME',
    TYPE = 'TYPE',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    EORI = 'EORI',
    REFERENCE_CODE = 'REFERENCE_CODE',
}

export interface CustomerParams extends PaginatedParams {
    hasCertificates?: boolean;
    isRegistered?: boolean;
    type?: CustomerType;
}
