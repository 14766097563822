import { useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import useFormikWCOCompanyDetails from './formik/useFormikWCOCompanyDetails';
import useFormikWCOLocations from './formik/useFormikWCOLocations';
import useFormikWCOUsers from './formik/useFormikWCOUsers';
import { WCOCompanyDetailsFormikType, WCOLocationsFormikType, WCOUsersFormikType } from './WCOTypes';

export interface CompanyDetailsOutletContext {
    formik: WCOCompanyDetailsFormikType;
}

export interface LocationsOutletContext {
    formik: WCOLocationsFormikType;
}

export interface FirstAdminOutletContext {
    formik: WCOUsersFormikType;
    handleRegisterCompany: () => void;
    locations: WCOLocationsFormikType['values']['locations'];
}

export interface WCOOutletContext {
    'company-details': CompanyDetailsOutletContext;
    locations: LocationsOutletContext;
    'first-admin': FirstAdminOutletContext;
}

const WCO = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const companyDetailsFormik = useFormikWCOCompanyDetails();
    const locationsFormik = useFormikWCOLocations();
    const usersFormik = useFormikWCOUsers();

    const handleRegisterCompany = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const allData = { ...companyDetailsFormik.values, ...locationsFormik.values, ...usersFormik.values };

        // TODO Implement company registration

        navigate('/user-management');
    }, [companyDetailsFormik.values, locationsFormik.values, navigate, usersFormik]);

    const outletContext = useMemo(() => {
        const context: WCOOutletContext = {
            'company-details': { formik: companyDetailsFormik },
            locations: { formik: locationsFormik },
            'first-admin': { formik: usersFormik, handleRegisterCompany, locations: locationsFormik.values.locations },
        };

        // String after route's last slash
        const key = location.pathname.split('/').at(-1) as keyof typeof context;

        return context[key];
    }, [companyDetailsFormik, handleRegisterCompany, location.pathname, locationsFormik, usersFormik]);

    return <Outlet context={outletContext} />;
};

export const useWCOOutletContext = <TOutletContext extends WCOOutletContext[keyof WCOOutletContext]>() => {
    return useOutletContext<TOutletContext>();
};

export default WCO;
