import { H4, Text } from 'components/ui/base/typography';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthenticationFormDiv } from 'views/authentication/Auth.styles';
import EmailForm from './EmailForm';
import EmailSentSuccess from './EmailSentSuccess';
import { ForgotPasswordDiv, ForgotPasswordSubdiv } from './ForgotPassword.styles';
import ResetPassword from './ResetPassword';

export enum ForgotPasswordSection {
    EmailForm = 0,
    EmailSent = 1,
    ChangePWD = 2,
}

const ForgotPassword: FC<{}> = () => {
    const { token }: { token?: string } = useParams();
    const { t } = useTranslation('login');
    const [page, setPage] = useState<ForgotPasswordSection>(ForgotPasswordSection.EmailForm);

    useEffect(() => {
        if (token) {
            setPage(ForgotPasswordSection.ChangePWD);
        }
    }, [token]);

    const section = useMemo(() => {
        if (token) {
            return <ResetPassword token={token} />;
        }

        if (page === ForgotPasswordSection.EmailForm) {
            return (
                <EmailForm
                    handleEmailSent={() => {
                        setPage(ForgotPasswordSection.EmailSent);
                    }}
                />
            );
        } else {
            return <EmailSentSuccess />;
        }
    }, [page, token]);

    const title = useMemo(() => {
        return page === ForgotPasswordSection.EmailForm ? (
            <H4>{t('forgot_password.forgot_password')}</H4>
        ) : (
            <H4>{t('forgot_password.reset_password')}</H4>
        );
    }, [page, t]);

    const subTitle = useMemo(() => {
        return page === ForgotPasswordSection.EmailForm ? <Text>{t('forgot_password.subText')}</Text> : <></>;
    }, [page, t]);

    return (
        <AuthenticationFormDiv>
            <ForgotPasswordDiv>{title}</ForgotPasswordDiv>
            <ForgotPasswordSubdiv>{subTitle}</ForgotPasswordSubdiv>
            {section}
        </AuthenticationFormDiv>
    );
};
export default ForgotPassword;
