import { SelectOption } from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { sortBy } from 'core/utils/arrays';
import { Currency } from 'store/currencies/currency';
import { Country } from '../../../../store/countries/country';

export const normalizeCountriesToSelect = (countries: Country[]): SelectOption[] =>
    sortBy(
        countries.map((item) => ({
            id: item.countryCode,
            value: item.name,
        })),
        'value'
    );

export const normalizeCurrenciesToSelect = (currencies: Currency[]): SelectOption[] =>
    sortBy(
        currencies.map((item) => ({
            id: item.code,
            value: item.name,
        })),
        'value'
    );

// TODO improve typing
export const normalizeValuesToArray = (values: { id: string; value: string }[]): SelectOption[] =>
    values.map((item) => ({
        id: item.id,
        value: item.value,
    }));

export const normalizeCountryCodesToArray = (
    codes: { code: string; label: string; phone: string; suggested?: boolean }[]
): SelectOption[] =>
    codes.map((item) => ({
        id: item.phone,
        value: `${item.label} (${item.code}) +${item.phone}`,
    }));
