import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Spin, notification } from 'antd';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import InvalidateModal from '../common/invalidate-modal/InvalidateModal';
import { SubmitLoading } from '../Form.styles';
import { disableSaveOrSubmitDeclarationButton, showInvalidateButton } from '../utils/declaration-utils';
import { useLocation } from 'react-router-dom';
import {
    BtSubmit,
    CollapsedDiv,
    CollapseFooter,
    CollapseIcon,
    ExpandIcon,
    FooterButton,
    FooterRow,
    SaveProductButton,
    Saving,
    SubmitDiv,
} from './Footer.styles';
import { useMatch } from 'react-router-dom';
import CancelDeclarationModal from '../common/CancelDeclarationModal';
import { Declaration } from 'typescript';

interface Props {
    saveDraft: {
        trigger: Function;
        loading: boolean;
    };
    saveProduct: {
        trigger: Function;
        loading: boolean;
    };
    declarationStatus?: DeclarationStatus;
    submitDeclaration: {
        trigger: Function;
        loading: boolean;
    };
    cancelSaveProduct: Function;
    isSaving?: boolean;
    declarationId?: string;
    iconDeclarationSaved: boolean;
    isSubmiting?: boolean;
    disabled?: boolean;
    externalInvalidationRequested?: boolean;
    declartionInternalType: DeclarationInternalType;
}

const FormFooter: FC<Props> = ({
    isSaving,
    saveProduct,
    submitDeclaration,
    cancelSaveProduct,
    saveDraft,
    declarationStatus,
    declarationId,
    iconDeclarationSaved,
    isSubmiting = false,
    disabled = false,
    externalInvalidationRequested = false,
    declartionInternalType,
}) => {
    const [showInvalidateModal, setShowInvalidateModal] = useState(false);
    const [cancelDeclarationModalVisible, setCancelDeclarationModalVisible] = useState(false);
    const showCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(true), []);
    const hideCancelDeclarationModal = useCallback(() => setCancelDeclarationModalVisible(false), []);

    const {
        setDeclarationFooterType,
        declarationFooter,
        collapsed,
        expandDeclarationFooter,
        collapseDeclarationFooter,
    } = useDeclarationFooter();
    const { t } = useTranslation('common');
    const [renderSave, setRenderSave] = useState(isSaving);
    const { declaration, cancelCdsDeclaration: cancelCdsDeclarationRequest } = useDeclarations();

    const showCancelButton = useMemo(
        () => declarationStatus !== DeclarationStatus.DRAFT && declaration?.declarationExternalEntity === 'CDS',
        [declaration?.declarationExternalEntity, declarationStatus]
    );
    const location = useLocation();

    const inMasterDetails = useMatch('declarations/:declarationId');
    const inProductView = useMatch('declarations/:declarationId/products/:productId');

    useEffect(() => {
        if (inMasterDetails) setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
    }, [setDeclarationFooterType, inMasterDetails]);

    useEffect(() => {
        if (!isSaving) {
            setTimeout(() => {
                setRenderSave(isSaving);
            }, 2000);
        } else {
            setRenderSave(isSaving);
        }
    }, [isSaving]);

    const disabledSaveOrSubmit = useMemo(
        () => (declarationStatus ? disableSaveOrSubmitDeclarationButton(declarationStatus) : false),
        [declarationStatus]
    );

    const showInvalidate = useMemo(
        () =>
            (externalInvalidationRequested || showInvalidateButton(declarationStatus)) &&
            declaration?.declarationExternalEntity !== 'CDS',
        [declaration?.declarationExternalEntity, declarationStatus, externalInvalidationRequested]
    );

    const iconSavedInfo = useMemo(() => {
        if (iconDeclarationSaved === undefined) {
            return <></>;
        }

        return iconDeclarationSaved ? <CheckCircleOutlined /> : <EditOutlined />;
    }, [iconDeclarationSaved]);

    const savingMessage = useMemo(() => {
        if (isSubmiting) {
            return <Saving>{t('footer.submitting')}</Saving>;
        } else if (renderSave) {
            return <Saving>{t('footer.saving')}</Saving>;
        }

        return <></>;
    }, [renderSave, isSubmiting, t]);

    const cancelCdsDeclaration = useCallback(
        (data?: { reason?: string }) => {
            let message: string;
            if (!data?.reason) {
                message = 'Missing reason!';
                notification.error({ message });
                return Promise.reject({ message });
            }

            return cancelCdsDeclarationRequest(data.reason)
                .then((data: Declaration) => {
                    message = 'Successfully canceled declaration!';
                    notification.success({ message });
                    return { ...data, message };
                })
                .catch((error: any) => {
                    message = 'An error occurred while cancelling declaration!';
                    notification.error({ message });
                    return { ...error, message };
                });
        },
        [cancelCdsDeclarationRequest]
    );

    const footerButtons = useMemo(
        () =>
            declarationFooter === DeclarationFooterType.PRODUCTS || location.pathname.includes('products') ? (
                <Col>
                    {renderSave && <Saving>{t('footer.saving')}</Saving>}

                    {inProductView && (
                        <FooterButton onClick={() => cancelSaveProduct()}>{t('footer.backToProducts')}</FooterButton>
                    )}

                    {!!saveProduct && !disabled && (
                        <SaveProductButton
                            loading={saveProduct.loading}
                            disabled={disabledSaveOrSubmit}
                            onClick={() => {
                                saveProduct.trigger();
                            }}
                        >
                            {t('footer.saveProduct')}
                        </SaveProductButton>
                    )}

                    {!disabledSaveOrSubmit && !disabled && (
                        <BtSubmit
                            type="primary"
                            disabled={disabledSaveOrSubmit}
                            loading={submitDeclaration.loading}
                            onClick={() => {
                                submitDeclaration.trigger(true);
                            }}
                        >
                            {t('footer.submit')} {isSubmiting && <Spin indicator={<SubmitLoading spin />} />}
                        </BtSubmit>
                    )}
                </Col>
            ) : (
                <Col>
                    {(renderSave || isSubmiting) && savingMessage}
                    {showCancelButton && !disabled && (
                        <FooterButton onClick={showCancelDeclarationModal}>{t('footer.cancel')}</FooterButton>
                    )}
                    {showInvalidate && !disabled && (
                        <FooterButton
                            onClick={() => {
                                setShowInvalidateModal(true);
                            }}
                        >
                            {t('footer.invalidate')}
                        </FooterButton>
                    )}
                    {!disabledSaveOrSubmit && !disabled && (
                        <FooterButton
                            type="primary"
                            disabled={disabledSaveOrSubmit}
                            loading={saveDraft.loading}
                            onClick={() => {
                                saveDraft.trigger();
                            }}
                        >
                            {t(declaration?.status === 'DRAFT' ? 'footer.saveDraft' : 'footer.save')}
                            {iconSavedInfo}
                        </FooterButton>
                    )}
                    {!disabledSaveOrSubmit && !disabled && (
                        <BtSubmit
                            type="primary"
                            disabled={disabledSaveOrSubmit}
                            loading={submitDeclaration.loading}
                            onClick={() => {
                                submitDeclaration.trigger();
                            }}
                        >
                            {t('footer.submit')} {isSubmiting && <Spin indicator={<SubmitLoading spin />} />}
                        </BtSubmit>
                    )}
                </Col>
            ),
        [
            declarationFooter,
            location.pathname,
            renderSave,
            t,
            inProductView,
            saveProduct,
            disabled,
            disabledSaveOrSubmit,
            submitDeclaration,
            isSubmiting,
            savingMessage,
            showCancelButton,
            showInvalidate,
            showCancelDeclarationModal,
            saveDraft,
            declaration?.status,
            iconSavedInfo,
            cancelSaveProduct,
        ]
    );

    return (
        <>
            <SubmitDiv>
                {declarationId && (
                    <InvalidateModal
                        declarationType={declartionInternalType}
                        declarationId={declarationId}
                        visible={showInvalidateModal}
                        handleCancel={() => setShowInvalidateModal(false)}
                    />
                )}
                <CancelDeclarationModal
                    visible={cancelDeclarationModalVisible}
                    onClose={hideCancelDeclarationModal}
                    onDeclarationCancel={cancelCdsDeclaration}
                    reason
                />
                {!collapsed ? (
                    <FooterRow justify="space-between" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                        <CollapseFooter onClick={() => collapseDeclarationFooter()}>
                            <CollapseIcon /> {t('footer.hideFooter')}
                        </CollapseFooter>
                        {footerButtons}
                    </FooterRow>
                ) : (
                    <FooterRow align="middle" padding="0.1rem 0">
                        <Col span={12}>
                            <CollapsedDiv>
                                <CollapseFooter onClick={() => expandDeclarationFooter()}>
                                    <ExpandIcon /> {t('footer.viewFooter')}
                                </CollapseFooter>
                            </CollapsedDiv>
                        </Col>
                        {(isSubmiting || renderSave) && (
                            <Col span={12}>
                                <CollapsedDiv>{savingMessage}</CollapsedDiv>
                            </Col>
                        )}
                    </FooterRow>
                )}
            </SubmitDiv>
        </>
    );
};
export default FormFooter;
