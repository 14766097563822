import { FormikProps } from 'formik';
import { FC, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useDeclarations from '../../../../hooks/useDeclarations';
import useDeclarationValidation from '../../../../hooks/useDeclarationValidation';
import useFormUtils from '../../../../hooks/useFormUtils';
import { Declaration, NotifyEmails } from '../../../../store/declarations/declaration';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';
import DeclarationTabKeys from '../../utils/DeclarationTabKeys';
import { FormAction } from '../../utils/form-utils';
import DeclarationErrorsDrawer from '../declaration-errors-drawer/DeclarationErrorsDrawer';
import SubmitModal from '../submit-modal/SubmitModal';

interface DeclarationContentProps {
    activeTabKey: DeclarationTabKeys;
    setActiveTabKey: Function;
    declaration?: Declaration;
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    setEmails: Function;
    showSubmitModal: boolean;
    setShowSubmitModal: Function;
    setCancelClicked: Function;
    cancelClicked: boolean;
    showErrorDrawer: boolean;
    setShowErrorDrawer: Function;
    handleSubmitButton: () => void;
    saveAsDraft?: Function;
}

const DeclarationContent: FC<DeclarationContentProps> = ({
    activeTabKey,
    setActiveTabKey,
    declaration,
    formik,
    productsFormik,
    setEmails,
    setShowSubmitModal,
    showSubmitModal,
    cancelClicked,
    setCancelClicked,
    showErrorDrawer,
    setShowErrorDrawer,
    handleSubmitButton,
    saveAsDraft,
}) => {
    const { declarationId } = useParams<{ declarationId: string }>();
    const { getDeclaration } = useDeclarations();
    const declarationCustomerId = useMemo(() => declaration?.customerId ?? '', [declaration]);
    const { setFormAction } = useDeclarationValidation();
    const navigate = useNavigate();
    const [createAmendment, setCreateAmendment] = useState<boolean>(false);

    const { isAis, formType, isCds } = useFormUtils();
    const isIeH1 = useMemo(() => isAis && formType === 'H1', [isAis, formType]);

    const formTabContent = useMemo(() => {
        const handleSubmitAndNotify = (emails: NotifyEmails) => {
            setEmails(emails);
            setFormAction(FormAction.SUBMIT);
            setShowSubmitModal(false);
        };

        if (declaration && declaration?.id) {
            return (
                declaration.status !== DeclarationStatus.RELEASED && (
                    <>
                        <Outlet
                            context={{
                                formik,
                                productsFormik,
                                cancelProducts: cancelClicked,
                                clearCancel: async () => {
                                    await getDeclaration(declarationId!);
                                    setCancelClicked(false);
                                },
                                saveAsDraft,
                            }}
                        />
                        <SubmitModal
                            visible={showSubmitModal}
                            handleSubmit={handleSubmitButton}
                            handleCancel={() => {
                                setShowSubmitModal(false);
                            }}
                            handleSubmitAndNotify={handleSubmitAndNotify}
                            customerId={declarationCustomerId}
                        />
                    </>
                )
            );
        }
        return <></>;
    }, [
        saveAsDraft,
        handleSubmitButton,
        declaration,
        setEmails,
        setFormAction,
        setShowSubmitModal,
        formik,
        productsFormik,
        cancelClicked,
        showSubmitModal,
        declarationCustomerId,
        getDeclaration,
        declarationId,
        setCancelClicked,
    ]);

    const handleContent = useMemo(() => {
        switch (activeTabKey) {
            case DeclarationTabKeys.FORM:
                return formTabContent;
            case DeclarationTabKeys.SUMMARY:
                return (
                    <Outlet
                        context={{
                            declaration,
                            showFormInfo: () => {
                                setActiveTabKey(DeclarationTabKeys.VIEW_ONLY);
                                navigate(`/declarations/${declarationId}/${DeclarationTabKeys.VIEW_ONLY}`, {
                                    state: { activeTabKey: DeclarationTabKeys.VIEW_ONLY },
                                });
                            },
                            createAmendment: () => {
                                setCreateAmendment(true);
                                navigate(`/declarations/${declarationId}/${DeclarationTabKeys.VIEW_ONLY}`, {
                                    state: { activeTabKey: DeclarationTabKeys.VIEW_ONLY },
                                });
                                setActiveTabKey(DeclarationTabKeys.VIEW_ONLY);
                            },
                        }}
                    />
                );
            case DeclarationTabKeys.DOCUMENTATION:
                return (
                    <Outlet
                        context={{
                            customerId: declarationCustomerId,
                            formId: declarationId,
                        }}
                    />
                );
            case DeclarationTabKeys.CUSTOMER_DETAILS:
                return <Outlet context={{ customerId: declarationCustomerId }} />;
            case DeclarationTabKeys.VIEW_ONLY:
                return (
                    <Outlet
                        context={{
                            formik,
                            productsFormik,
                            declaration,
                            amendment: createAmendment,
                            createAmendment: () => {
                                setCreateAmendment(true);
                            },
                            cancelAmendment: () => {
                                setCreateAmendment(false);

                                if (declaration?.status === 'RISKED' && isCds) return;

                                setActiveTabKey(DeclarationTabKeys.FORM);

                                if (isIeH1) {
                                    navigate(`/declarations/${declarationId}/summary`, {
                                        state: { activeTabKey: DeclarationTabKeys.SUMMARY },
                                    });
                                    return;
                                }
                                navigate(`/declarations/${declarationId}`, {
                                    state: { activeTabKey: DeclarationTabKeys.FORM },
                                });
                            },
                            activeTabKey,
                        }}
                    />
                );
            case DeclarationTabKeys.HISTORY:
                return <Outlet />;
            case DeclarationTabKeys.NOTIFICATIONS:
            case DeclarationTabKeys.REFUNDS:
                return (
                    <Outlet
                        context={{
                            declaration: declaration!,
                        }}
                    />
                );
        }
    }, [
        activeTabKey,
        declaration,
        formTabContent,
        formik,
        productsFormik,
        declarationId,
        declarationCustomerId,
        createAmendment,
        navigate,
        setActiveTabKey,
        isIeH1,
        isCds,
    ]);

    return (
        <>
            {handleContent}
            <DeclarationErrorsDrawer
                visible={showErrorDrawer}
                onClose={() => setShowErrorDrawer(false)}
                declaration={declaration}
                formik={formik}
            />
        </>
    );
};

export default DeclarationContent;
