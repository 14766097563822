import { enumToText } from 'core/utils/enum-to-text';
import { FC, useMemo } from 'react';
import { IndividualType } from 'store/individuals/individual';
import { isAdmin } from 'store/individuals/utils';
import styled from 'styled-components';
import { StyledInactive, StyledOpenIcon, StyledRole, StyledRoleAdmin } from './TagsMenu.styles';

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

interface Props {
    value: IndividualType | string;
    setOpen: Function;
}

export const TagButton: FC<Props> = ({ value, setOpen }) => {
    const tag = useMemo(() => {
        const text = enumToText(value);

        if (value === 'Inactive') {
            return <StyledInactive>{text}</StyledInactive>;
        } else if (isAdmin(value)) {
            return <StyledRoleAdmin>{text}</StyledRoleAdmin>;
        }

        return <StyledRole>{text}</StyledRole>;
    }, [value]);

    return (
        <StyledButton onClick={() => setOpen((s: boolean) => !s)}>
            {tag}
            <StyledOpenIcon />
        </StyledButton>
    );
};

export default TagButton;
