import { useDispatch } from 'react-redux';
import {
    doClearDeclarationFooter,
    doCollapseDeclarationFooter,
    doExpandDeclarationFooter,
    doSetDeclarationFooter,
} from 'store/declaration-footer/action';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { useAppSelector } from '../config/hooks';

const useDeclarationFooter = () => {
    const dispatch = useDispatch();
    const declarationFooter = useAppSelector((state) => state.declarationFooter.type);
    const collapsed = useAppSelector((state) => state.declarationFooter.collapsed);

    const setDeclarationFooterType = (type: DeclarationFooterType) => {
        if (declarationFooter !== type) {
            dispatch(doSetDeclarationFooter(type));
        }
    };

    const clearDeclarationFooterType = () => {
        dispatch(doClearDeclarationFooter());
    };

    const collapseDeclarationFooter = () => {
        dispatch(doCollapseDeclarationFooter());
    };

    const expandDeclarationFooter = () => {
        dispatch(doExpandDeclarationFooter());
    };

    return {
        declarationFooter,
        collapsed,
        setDeclarationFooterType,
        clearDeclarationFooterType,
        collapseDeclarationFooter,
        expandDeclarationFooter,
    };
};

export default useDeclarationFooter;
