import { DownOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { fonts } from 'theme';

export const StyledNotificationRow = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const NotificationMessage = styled.div`
    cursor: pointer;
`;

export const StyledDownOutlined = styled(DownOutlined).withConfig({
    shouldForwardProp: (prop) => !['expanded'].includes(prop),
})<{ expanded: boolean }>`
    transform: rotate(0deg);
    transition: transform 0.3s ease-out;

    ${(props) =>
        props.expanded &&
        css`
            transform: rotate(-180deg);
        `};
`;

export const NotificationDescription = styled.p`
    padding-top: 0.3rem;
`;

export const NotificationCode = styled.span`
    margin-left: 0.5rem;
    font-weight: ${fonts.weight.bold};
    padding-right: 0.3rem;
`;

export const NotificationText = styled.span`
    font-weight: ${fonts.weight.regular};
    margin-left: 0.5rem;
`;

export const NotificationDescriptionCode = styled.div`
    margin-left: 0.5rem;
    font-weight: ${fonts.weight.bold};
    padding-right: 0.3rem;
`;
