import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import countries from 'utils/countries';
import FormInput from '../formInput/FormInput';
import { FormItem } from '../formInput/FormInput.styles';
import FormSelect from '../formSelect/FormSelect';

const FieldsContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

const PhoneCodeFieldContainer = styled.div`
    max-width: 150px;
    width: 100%;
`;
const PhoneNumberFieldContainer = styled.div`
    width: 100%;
    min-width: 200px;
`;

interface Props {
    children?: ReactNode;
    fieldCodeProps: FieldInputProps<any>;
    fieldNumberProps: FieldInputProps<any>;
    fieldNumberMeta: FieldMetaProps<any>;
    fieldCodeMeta: FieldMetaProps<any>;
    fieldNumberHelper?: FieldHelperProps<any>;
    fieldCodeHelper?: FieldHelperProps<any>;
    disabled?: boolean;
    required?: boolean;
}

export interface MultipleProps {
    node: ReactNode;
}

export interface SelectSearchOption {
    children: any;
    key: string;
    props: any;
    value: string;
}

const phoneCodeOptions = countries.map((country) => {
    const id = country.phone;
    const value = country.label;

    return { id, value };
});

const FormPhoneNumber: FC<Props> = ({
    fieldCodeMeta,
    fieldNumberMeta,
    fieldCodeProps,
    fieldNumberProps,
    fieldNumberHelper,
    fieldCodeHelper,
    disabled,
    required,
}) => {
    return (
        <>
            <FormItem>
                <FieldsContainer>
                    <PhoneCodeFieldContainer>
                        <FormSelect
                            required={required}
                            label="Code"
                            fieldProps={fieldCodeProps}
                            fieldMeta={fieldCodeMeta}
                            fieldHelper={fieldCodeHelper}
                            selectOptions={phoneCodeOptions}
                        />
                    </PhoneCodeFieldContainer>
                    <PhoneNumberFieldContainer>
                        <FormInput
                            required={required}
                            label="Phone Number"
                            disabled={disabled}
                            fieldProps={fieldNumberProps}
                            fieldMeta={fieldNumberMeta}
                            fieldHelper={fieldNumberHelper}
                        />
                    </PhoneNumberFieldContainer>
                </FieldsContainer>
            </FormItem>
        </>
    );
};

export default FormPhoneNumber;
