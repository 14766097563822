export const IEH1_DataStructure = {
    declarant: {},
    exporter: {},
    representative: {},
    authorisationHolder: [],
    guarantee: [],
    authorisation: {
        firstPlaceOfUse: {},
        placeOfUse: [],
        ownerOfTheGoods: [],
    },
    goodsShipment: {
        importer: {},
        seller: {},
        buyer: {},
        additionalInformation: [],
        simplifiedDeclarationDocumentWritingOff: [],
        additionsDeductions: [],
        supplyChainActor: [],
        locationGoods: { address: {} },
        producedDocumentsWritingOff: {},
        containerIdentificationNumber: [],
    },
};

export const IEH1_ItemDataStructure = {
    goodsInformation: {
        taricAdditionalCode: [],
        packaging: [],
        nationalAdditionalCommodityCode: [],
    },
    additionalProcedure: [],
    containerIdentificationNumber: [],
    simplifiedDeclarationDocumentWritingOff: [],
    producedDocumentsWritingOff: {},
    additionalInformation: [],
    taxBox43Bis: [],
    additionsDeductions: [],
    supplyChainActor: [],
    additionalFiscalReference: [],
    exporter: {},
    seller: {},
    buyer: {},
};
