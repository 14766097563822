import { FormikProps } from 'formik';
import { get, set } from 'lodash';

export const levelHasValues = (toggledLevelFieldPaths: any, toggledLevelFieldValues: any) =>
    Object.values(toggledLevelFieldPaths).some((fieldPath) => {
        if (typeof fieldPath !== 'string') return false;

        const fieldValue = get(toggledLevelFieldValues, fieldPath);

        return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
    });

export const deleteLevelValues = (
    partyPath: string | undefined,
    toggledLevelFieldPaths: any,
    formik: FormikProps<any> | undefined
) => {
    if (typeof partyPath !== 'string') return;

    const deletedToggledLevelFieldPaths = Object.values(toggledLevelFieldPaths).reduce((acc: {}, fieldPath) => {
        if (typeof fieldPath !== 'string') return acc;

        return set(acc, fieldPath, undefined);
    }, formik?.getFieldProps(partyPath).value);

    return formik?.getFieldHelpers(partyPath).setValue(deletedToggledLevelFieldPaths);
};
