import { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { SubmissionError } from './SubmissionErrorsDrawer';

const SubmissionErrorContainer = styled.ul``;
const SubmissionErrorRow = styled.li``;
const SubmissionErrorCode = styled.p`
    color: ${colors.lightRed};
`;
const SubmissionErrorMessage = styled.p`
    font-weight: 600;
`;

interface Props {
    errors: SubmissionError[] | null | undefined;
}

const SubmissionErrorsList: FC<Props> = ({ errors }) => {
    return (
        <SubmissionErrorContainer>
            {errors?.map((error) => (
                <SubmissionErrorRow>
                    {error.code || <SubmissionErrorCode>{error.code}</SubmissionErrorCode>}
                    <SubmissionErrorMessage>{error.message}</SubmissionErrorMessage>
                </SubmissionErrorRow>
            ))}
        </SubmissionErrorContainer>
    );
};

export default SubmissionErrorsList;
