import styled from 'styled-components';

export const HistoryViewContainer = styled.div`
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 0 5rem;
`;

export const HistoryStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
`;

export const HistoryFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
`;
