import styled from 'styled-components';
import { fonts } from 'theme';

export const H1Style = styled.h1`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 7.2rem;
    line-height: 9.6rem;
    display: flex;
    align-items: center;
`;

export const H2Style = styled.h2`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 5.6rem;
    line-height: 7.2rem;
    display: flex;
    align-items: center;
`;

export const H3Style = styled.h3`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 6.4rem;
    display: flex;
    align-items: center;
`;

export const H4Style = styled.h4`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 4rem;
`;

export const H5Style = styled.h5<{ marginBottom?: number }>`
    font-family: Inter;
    font-style: normal;
    font-weight: ${fonts.weight.bold};
    font-size: 2.4rem;
    line-height: 3.2rem;
    display: flex;
    align-items: center;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}rem` : 0)};
`;

export const H6Style = styled.h6`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
`;

export const TextLarge = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.bold};
    font-size: 1.8rem;
    line-height: 2.8rem;
`;

export const Text = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

export const TextBold = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
`;

export const TextSmall = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
`;

export const TextSmallBold = styled.span<{ marginRight?: number }>`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
`;

export const TextExtraSmall = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.2rem;
    line-height: 2.2rem;
`;

export const TextExtraSmallBold = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.bold};
    font-size: 1.2rem;
    line-height: 2.2rem;
`;

export const TextExtraSmallTable = styled.span`
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.2rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
`;
