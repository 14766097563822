import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { LegsActions } from './actionType';
import { Leg } from './leg';


interface LegEntities {
    [key: string]: Leg;
}
export interface LegsState {
    isLoading: boolean;
    error?: ErrorResponse;
    legs: ListPayload<Leg>;
    entities: LegEntities;
}

export const legsInitialState: Readonly<LegsState> = {
    isLoading: false,
    legs: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const legs: Reducer<LegsState, LegsActions> = (state = legsInitialState, action): LegsState => {
    switch (action.type) {
        case 'LIST_LEG_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'LIST_LEG_SUCCESS':
            console.log(action.payload)
            return {
                ...state,
                isLoading: false,
                legs: action.payload,
            };
        case 'LIST_LEG_ERROR':
            return {
                ...state,
                isLoading: false,
                legs: state.legs,
                error: action.error,
            };
        case 'CREATE_LEG_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'CREATE_LEG_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'CREATE_LEG_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_LEG_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'GET_LEG_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'GET_LEG_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'EDIT_LEG_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'EDIT_LEG_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id]: action.payload },
            };
        case 'EDIT_LEG_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_LEG_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        case 'DELETE_LEG_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_LEG_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default legs;
