import { Declaration, HasDocuments, Notifiable } from '../declaration';
import { DeclarationExternalEntity } from '../enums/common/declaration-external-entity';
import { DeclarationInternalType } from '../enums/common/declaration-internal-type';

export const CdsMessageType = {
    B1: 'B1',
    C1: 'C1',
    B2: 'B2',
    B4: 'B4',
    C21E: 'C21E',
    H1: 'H1',
    H2: 'H2',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CdsExportMessageType = keyof typeof CdsMessageType;

export interface CdsExportDeclarationPayload extends Declaration {
    declarationExternalEntity: DeclarationExternalEntity.CDS;
    declarationInternalType: DeclarationInternalType.EXPORT;
    isCoreTemplate?: boolean;
    cdsExportDeclaration?: CdsExportDeclaration;
}
// TODO: to be defined
// export type CdsExportDeclaration = Record<string, any>;

export interface CdsExportNotificationError {
    field: string;
    message: string;
    code: string;
    explanation: string;
    groupId: number;
}
export interface CdsExportNotificationAdditionalInformation {
    field: string;
    statementCode: string;
    statementDescription: string;
    statementTypeCode: string;
}
export interface CdsExportNotification {
    statusMessage?: string;
    issueDateTime?: string;
    validationMessages?: CdsExportNotificationError[];
    additionalInformationMessages?: CdsExportNotificationAdditionalInformation[];
}

// Declaration
export interface CdsExportDeclaration extends Notifiable<CdsExportNotification>, HasDocuments {
    id?: string;
    lrn?: string;
    declarant?: { id?: string; name?: string };
    messageType?: CdsExportMessageType;
    cdsExportDeclarationPayload?: CdsExportDeclarationPayloadPayload;
    cdsExportGovernmentAgencyGoodsItems?: {
        id?: string | undefined;
        governmentAgencyGoodsItem: CdsExportGovernmentAgencyGoodsItem | null;
    }[];
}

export interface CdsExportDeclarationPayloadPayload {
    id?: string;
    typeCode?: string;
    additionalTypeCode?: string;
    specificCircumstancesCodeCode?: string;
    invoiceAmount?: string;
    invoiceAmountCurrencyId?: string;
    totalGrossMassMeasure?: string;
    totalPackageQuantity?: string;

    ducr?: string;
    mucr?: string;

    goodsShipment?: CdsExportGoodsShipment;
    additionalInformation?: CdsExportAdditionalInformation;

    exporter?: CdsExportCustomer;
    declarant?: CdsExportCustomer;
    agent?: CdsExportCustomer;

    authorisationHolder?: Array<CdsExportAuthorisationHolder>;

    currencyExchange?: CdsExportCurrencyExchange;

    exitOffice?: CdsExportExitOffice;
    presentationOffice?: CdsExportPresentationOffice;
    supervisingOffice?: CdsExportSupervisingOffice;

    borderTransportMeans?: CdsExportBorderTransportMeans;
    departureTransportMeans?: CdsExportDepartureTransportMeans;

    obligationGuarantee?: CdsExportObligationGuarantee;
}

// Goods Shipment
export interface CdsExportGoodsShipment {
    transactionNatureCode?: string;
    governmentAgencyGoodsItem?: CdsExportGovernmentAgencyGoodsItem[];
    previousDocument?: CdsExportPreviousDocument[];
    ucr?: CdsExportUcr;
    warehouse?: CdsExportWarehouse;

    consignor?: CdsExportCustomer;
    consignee?: CdsExportCustomer;
    carrier?: CdsExportCustomer; // TODO Check if this is correct
    aeoMutualRecognitionParty?: CdsExportAeoMutualRecognitionParty[];
    domesticDutyTaxParty?: CdsExportDomesticDutyTaxParty[];
    freight?: CdsExportFreight;

    destination?: CdsExportDestination;
    exportCountry?: CdsExportExportCountry;

    container?: CdsExportContainer;
    consignment?: TransportEquipment;
    itinerary?: CdsExportItinerary;
    goodsLocation?: CdsExportGoodsLocation;
}

// export type CdsExportGovernmentAgencyGoodsItem = any;

// Product
export interface CdsExportGovernmentAgencyGoodsItem {
    id?: string;
    sequenceNumeric?: string;
    goodsDescription?: string;
    statisticalValueAmount?: string;
    statisticalValueAmountCurrencyId?: string;
    transactionNatureCode?: string;
    governmentProcedure?: CdsExportGovernmentProcedure;
    previousDocument: Array<CdsExportPreviousDocument>;
    additionalInformation?: Array<CdsExportAdditionalInformation>;
    additionalDocument?: Array<CdsExportAdditionalDocument>;
    ucr?: CdsExportUcr;

    consignee?: CdsExportCustomer;
    aeoMutualRecognitionParty?: Array<CdsExportAeoMutualRecognitionParty>;
    domesticDutyTaxParty?: CdsExportDomesticDutyTaxParty;
    freight?: CdsExportFreight; // TODO Do we need this?

    destination?: CdsExportDestination;
    origin?: CdsExportOrigin;
    commodity?: CdsExportCommodity;
    packaging?: Array<CdsExportPackaging>;
}

export interface CdsExportPreviousDocument {
    id?: string;
    categoryCode?: string;
    typeCode?: string;
    lineNumeric?: string;
}

export interface CdsExportGovernmentProcedure {
    currentCode?: string;
    additionalCurrentCode?: string[];
    previousCode?: string;
}

export type CdsExportGovernmentProcedureServer =
    | {
          currentCode?: string;
          previousCode?: string;
      }[]
    | undefined;

export interface CdsExportAdditionalInformation {
    statementCode?: string;
    statementDescription?: string;
}

export interface CdsExportAdditionalDocument {
    categoryCode?: string;
    typeCode?: string;
    id?: string;
    lpcoExemptionCode?: string;
    name?: string;
    effectiveDateTime?: string;
    submitter?: {
        name?: string;
    };
    writeOff?: {
        amountAmount?: string;
        quantityQuantity?: string;
    };
}

export interface CdsExportUcr {
    traderAssignedReferenceID?: string;
}

export interface CdsExportWarehouse {
    id?: string;
    typeCode?: string;
}

export interface CdsExportCustomer {
    id?: string;
    name?: string;
    address?: {
        cityName?: string;
        countryCode?: string;
        line?: string;
        postcodeID?: string;
    };
}

export interface CdsExportAeoMutualRecognitionParty {
    id?: string;
    roleCode?: string;
}

export interface CdsExportAuthorisationHolder {
    id?: string;
    categoryCode?: string;
}

export interface CdsExportDomesticDutyTaxParty {
    id?: string;
    roleCode?: string;
}

export interface CdsExportFreight {
    paymentMethodCode?: string;
}

export interface CdsExportCurrencyExchange {
    rateNumeric?: string;
}

export interface CdsExportDestination {
    countryCode?: string;
}

export interface CdsExportExitOffice {
    id?: string;
}

export interface CdsExportExportCountry {
    id?: string;
}

export interface CdsExportOrigin {
    countryCode?: string;
    typeCode?: string;
}

export interface CdsExportItinerary {
    sequenceNumeric?: string;
    routingCountryCode?: string;
}

export interface CdsExportGoodsLocation {
    id?: string;
    name?: string;
    typeCode?: string;
    address?: {
        countryCode?: string;
        typeCode?: string;
        line?: string;
        postcodeID?: string;
        cityName?: string;
    };
}

export interface CdsExportPresentationOffice {
    id?: string;
}

export interface CdsExportSupervisingOffice {
    id?: string;
}

export interface CdsExportCommodity {
    description?: string;
    classification?: CdsExportClassificationBE[];
    goodsMeasure?: CdsExportGoodsMeasure;
    dangerousGoods?: CdsExportDangerousGoods;
    transportEquipment?: Array<CdsExportTransportEquipment>;
}

export interface CdsExportClassificationBE {
    id?: string;
    identificationTypeCode?: string;
}

export interface CdsExportClassificationFE {
    cv?: { id: string };
    gn?: { id: string }[];
    tra?: { id: string }[];
    tsp?: { id: string };
}

export interface CdsExportGoodsMeasure {
    netNetWeightMeasure?: string;
    tariffQuantity?: string;
    grossMassMeasure?: string;
}

export interface CdsExportDangerousGoods {
    undgid?: string;
}

export interface CdsExportPackaging {
    typeCode?: string;
    quantityQuantity?: string;
    marksNumbersID?: string;
}

export interface CdsExportContainer {
    containerCode?: string;
}

export interface CdsExportBorderTransportMeans {
    id?: string;
    modeCode?: string;
    identificationTypeCode?: string;
    registrationNationalityCode?: string;
}

export interface CdsExportDepartureTransportMeans {
    id?: string;
    modeCode?: string;
    identificationTypeCode?: string;
}

export interface CdsExportTransportEquipment {
    id?: string;
    seal?: SealId;
}

export interface CdsExportObligationGuarantee {
    referenceID?: string;
    securityDetailsCode?: string;
    id?: string;
    accessCode?: string;
    amountAmount?: string;
    amountAmountCurrencyId?: string;
    guaranteeOffice?: {
        id?: string;
    };
}

interface TransportEquipment {
    transportEquipment: CdsExportTransportEquipment;
}

interface SealId {
    id: string;
}
