import { useAppDispatch, useAppSelector } from "config/hooks";
import { useCallback } from "react";
import { doCreateCargo, doDeleteCargo, doEditCargo, doGetCargo, doListCargos } from "store/cargo/action";
import { Cargo } from "store/cargo/cargo";

interface UseCargoProps {
    cargoId?: string;
}

const useCargo  = ({ cargoId }: UseCargoProps = {}) => {
    const cargos = useAppSelector((state) => state.cargos.cargos);
    const cargo = useAppSelector((state) => (cargoId ? state.cargos.entities[cargoId] : ({} as Cargo)));
    const isLoading = useAppSelector((state) => state.cargos.isLoading);
    const error = useAppSelector((state) => state.cargos.error);

    const dispatch = useAppDispatch();

    const listCargos = useCallback(() => {
        return dispatch(doListCargos());
    }, [dispatch]);

    const createCargo = useCallback((cargo: Cargo) => {
       return dispatch(doCreateCargo(cargo));
    },[dispatch]);

    const editCargo = useCallback((cargo: Cargo) => {
        return dispatch(doEditCargo(cargo))
    },[dispatch]);
    
    const getCargo = useCallback((cargoId: String) => {
        return dispatch(doGetCargo(cargoId));
    },[dispatch]);

    const deleteCargo = useCallback((cargoId: String) => {
        return dispatch(doDeleteCargo(cargoId))
    },[dispatch]);

    return{
        cargos,
        cargo,
        listCargos,
        createCargo,
        editCargo,
        getCargo,
        deleteCargo,
        isLoading,
        error
    }

}
export default useCargo;