import { useAppDispatch, useAppSelector } from 'config/hooks';
import { useCallback, useMemo, useState } from 'react';
import { doCreateLeg, doDeleteLeg, doEditLeg, doGetLeg, doListLegs } from 'store/journeys/legs/action';
import { startLeg as startLegClient, completeLeg as completeLegClient } from 'store/journeys/legs/client';
import { Leg, LegStatus } from 'store/journeys/legs/leg';

interface UseLegsProps {
    journeyId: string;
    legId?: string;
}

const useLegs = ({ journeyId, legId }: UseLegsProps) => {
    const legs = useAppSelector((state) => state.legs.legs);
    const leg = useAppSelector((state) => (legId ? state.legs.entities[legId] : undefined));
    const isLoading = useAppSelector((state) => state.legs.isLoading);
    const error = useAppSelector((state) => state.legs.error);

    const [isStarting, setIsStarting] = useState(false);
    const [errorStarting, setErrorStarting] = useState(false);

    const dispatch = useAppDispatch();

    const listLegs = useCallback(() => dispatch(doListLegs(journeyId)), [dispatch, journeyId]);

    const createLeg = useCallback((leg: Leg) => dispatch(doCreateLeg(journeyId, leg)), [dispatch, journeyId]);

    const editLeg = useCallback((leg: Leg) => dispatch(doEditLeg(journeyId, leg)), [dispatch, journeyId]);

    const getLeg = useCallback((legId: string) => dispatch(doGetLeg(journeyId, legId)), [dispatch, journeyId]);

    const deleteLeg = useCallback((legId: string) => dispatch(doDeleteLeg(journeyId, legId)), [dispatch, journeyId]);

    const startLeg = useCallback(async () => {
        if (journeyId && legId) {
            try {
                setIsStarting(true);
                setErrorStarting(false);

                await startLegClient(journeyId, legId);
            } catch {
                setErrorStarting(true);
            } finally {
                setIsStarting(false);
            }
        }
    }, [journeyId, legId]);

    const completeLeg = useCallback(async () => {
        if (journeyId && legId) {
            try {
                setIsStarting(true);
                setErrorStarting(false);

                await completeLegClient(journeyId, legId);
            } catch {
                setErrorStarting(true);
            } finally {
                setIsStarting(false);
            }
        }
    }, [journeyId, legId]);

    const isLegAssigned = useMemo(() => leg?.status === LegStatus.ASSIGNED, [leg?.status]);

    const isLegInProgress = useMemo(() => leg?.status === LegStatus.IN_PROGRESS, [leg?.status]);

    return {
        legs,
        leg,
        listLegs,
        createLeg,
        editLeg,
        getLeg,
        deleteLeg,
        isLoading,
        error,
        isStarting,
        errorStarting,
        startLeg,
        completeLeg,
        isLegAssigned,
        isLegInProgress,
    };
};
export default useLegs;
