import driverMarker from 'assets/driver.svg';
import styled from 'styled-components';
import { InfoCircleFilled } from '@ant-design/icons';

export const markerElement = document.createElement('div');
markerElement.style.backgroundImage = `url(${driverMarker})`;
markerElement.style.width = '25px';
markerElement.style.height = '25px';
markerElement.style.backgroundSize = 'cover';
markerElement.style.cursor = 'pointer';

export const MapStyledComponents = {
    MapWrapper: styled.div`
        position: relative;
    `,
    MapContainer: styled.div`
        height: 550px;
    `,
    HelperTextContainer: styled.div`
        z-index: 99;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;

        width: 200px;
        background-color: white;
        padding: 1rem 2rem;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    `,
    TooltipIcon: styled(InfoCircleFilled)`
        position: absolute;
        top: 5px;
        right: 5px;
    `,
};
