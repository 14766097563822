import { ColumnsType } from 'antd/lib/table';
import { Key } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { TextSmall } from 'components/ui/base/typography';
import useRequest from 'hooks/useRequest';
import { FC, useEffect, useState } from 'react';
import { DeclarationDocuments } from 'store/declarations/declaration';
import { StyledFileOutlined } from 'views/declarations/sections/customer-documents/CustomerDocuments.styles';
import { StyledDownloadOutlined, StyledEyeOutlined, StyledPrinterOutlined } from './SummaryView.styles';

interface Props {
    id: string | undefined;
    request: Function;
    fileName: string;
}

const DocumentTable: FC<Props> = ({ id, request, fileName }) => {
    const { data: downloadData, doRequest: doDownloadDoc } = useRequest(request as any);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [sadDocument, setSadDocument] = useState<DeclarationDocuments | undefined>();

    useEffect(() => {
        if (id) {
            doDownloadDoc(id, 'DRAFT');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!(downloadData && id)) return setSadDocument(undefined);

        const link = window.URL.createObjectURL(downloadData.blob);
        setSadDocument({
            filename: downloadData.fileName ?? fileName,
            documentType: downloadData.type ?? '.pdf',
            link,
            id,
        });
    }, [downloadData, id, fileName]);

    const columns: ColumnsType<any> = [
        {
            title: 'Document',
            render: (text: string, record: any) => {
                return record.id ? (
                    <TextSmall>
                        <StyledFileOutlined /> {`${sadDocument?.filename}${sadDocument?.documentType}`}
                    </TextSmall>
                ) : (
                    <span>-</span>
                );
            },
            align: 'left',
        },
        {
            title: 'actions',
            render: (text: string, record: DeclarationDocuments, index: number) => {
                return record.id && record.link && record.filename ? (
                    <>
                        <StyledEyeOutlined
                            onClick={() => {
                                const isExpanded = expandedKeys.find((e) => e === record.id);
                                if (isExpanded) {
                                    const expandedRows = expandedKeys.filter((e) => e !== record.id);
                                    setExpandedKeys(expandedRows);
                                } else {
                                    const expandedRows: Key[] = [record.id!];

                                    setExpandedKeys(expandedRows);
                                }
                            }}
                        />
                        <StyledDownloadOutlined
                            onClick={() => {
                                const link = document.createElement('a');
                                link.download = record.filename!;
                                link.href = record.link!;
                                document.body.appendChild(link);
                                link.click();
                            }}
                        />

                        <StyledPrinterOutlined
                            onClick={() => {
                                var iframe = document.createElement('iframe');
                                iframe.style.visibility = 'hidden';
                                iframe.src = record.link ?? '';
                                document.body.appendChild(iframe);
                                if (iframe && iframe.contentWindow) {
                                    iframe.contentWindow.focus();
                                    iframe.contentWindow.print();
                                }
                            }}
                        />
                    </>
                ) : (
                    <></>
                );
            },
            align: 'right',
        },
    ];

    return (
        <>
            {sadDocument && sadDocument.id ? (
                <Table
                    rowKey="id"
                    showHeader={false}
                    columns={columns}
                    dataSource={[sadDocument]}
                    pagination={false}
                    expandable={{
                        expandedRowKeys: expandedKeys,
                        expandedRowRender: () => {
                            if (downloadData) {
                                return (
                                    <div style={{ alignItems: 'center' }}>
                                        <object
                                            data={sadDocument?.link}
                                            type="application/pdf"
                                            width="100%"
                                            height="666px"
                                        >
                                            <iframe
                                                id="sad-iframe"
                                                src={`${sadDocument?.link}#view=fitH`}
                                                title="preview document"
                                                width="100%"
                                                height="666px"
                                            >
                                                <p>This browser does not support PDF!</p>
                                            </iframe>
                                        </object>
                                    </div>
                                );
                            }
                        },
                        expandIconColumnIndex: -1,
                    }}
                />
            ) : (
                <div>The document is not available.</div>
            )}
        </>
    );
};
export default DocumentTable;
