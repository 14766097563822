import { ColumnsType } from 'antd/lib/table';
import DashboardTable from 'components/ui/composed/dashboard/DashboardTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Template, TemplateResponse, TemplateSortParameter } from 'store/template/template';
import useTemplates from 'hooks/useTemplates';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { declarationExternalTypeToCountry } from 'components/ui/composed/template/template-utils';
import { getDeclarationNameLabels } from '../invoice-upload/utils';
import EditTemplateNameDrawer from './components/EditTemplateNameDrawer';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { notification } from 'antd';
import { ArchivedTemplatesParams, archiveTemplate, unarchiveTemplate } from '../../store/template/client';
import { H5 } from '../../components/ui/base/typography';
import CustomModal from 'components/ui/base/modal/Modal';
import { MessageTypes } from '../../store/declarations/enums/common/template-types';
import { TableChangeParams } from '../../utils/tableHelpers';

interface Props {
    country: DeclarationCountry;
    internalType: DeclarationInternalType;
    formType: MessageTypes;
    viewOnly?: boolean;
    onRowClick?: (id: string | undefined) => void;
    withPagination?: boolean;
    options?: {
        visible?: {
            country?: boolean;
            declarationType?: boolean;
            templateName?: boolean;
        };
    };
    atArchivedPath?: boolean;
}

const TemplatesTable = ({
    country,
    internalType,
    formType,
    viewOnly,
    onRowClick,
    withPagination = true,
    options = { visible: { country: true, declarationType: true, templateName: true } },
    atArchivedPath,
}: Props) => {
    const { templates, listTemplates, listArchivedTemplates } = useTemplates();
    const { openModal } = useTemplateContext();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [template, setTemplate] = useState<Template | undefined>();
    const [showEmpty, setShowEmpty] = useState(false);

    const [archiveId, setArchiveId] = useState<string | undefined>(undefined);
    const [archiveModalVisible, setArchiveModalVisible] = useState<boolean>(false);
    const showArchiveModal = useCallback(() => setArchiveModalVisible(true), []);
    const hideArchiveModal = useCallback(() => {
        setArchiveModalVisible(false);
        setArchiveId(undefined);
    }, []);
    const [unarchiveModalVisible, setUnarchiveModalVisible] = useState<boolean>(false);
    const showUnarchiveModal = useCallback(() => setUnarchiveModalVisible(true), []);
    const hideUnarchiveModal = useCallback(() => {
        setUnarchiveModalVisible(false);
        setArchiveId(undefined);
    }, []);

    const listRequest = useCallback(
        (params?: TableChangeParams) => {
            const size = { size: withPagination ? 10 : 9999 };

            const archivedTemplatesParams: ArchivedTemplatesParams = {
                declarationExternalType: country === 'uk' ? 'CDS' : 'REVENUE',
                declarationInternalType: internalType,
                declarationName: formType,
                ...size,
                ...params,
            };
            if (internalType === 'ENS') {
                delete archivedTemplatesParams.declarationName;
            }

            (!atArchivedPath
                ? listTemplates(country, internalType, formType, { ...size, ...params })
                : listArchivedTemplates(archivedTemplatesParams)
            ).then(() => setShowEmpty(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [atArchivedPath, country, internalType, formType, withPagination]
    );

    useEffect(() => {
        const inIrelandTemplate =
            country === DeclarationCountry.IRELAND &&
            (internalType === DeclarationInternalType.IMPORT ||
                internalType === DeclarationInternalType.ENS ||
                internalType === DeclarationInternalType.EXPORT);
        const inUkTemplate = country === DeclarationCountry.UK && internalType === DeclarationInternalType.EXPORT;

        if (!(inIrelandTemplate || inUkTemplate)) return setShowEmpty(true);

        listRequest();
    }, [country, internalType, listRequest]);

    const toFullFormType = useCallback(
        (formType: string | undefined) => {
            const labels = getDeclarationNameLabels(country as any, internalType as any) as any[];
            return labels.find(({ key }) => key === formType)?.value;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [templates]
    );

    const columns: ColumnsType<TemplateResponse> = useMemo(() => {
        const _columns: ColumnsType<TemplateResponse> = [];
        if (options.visible?.country)
            _columns.push({
                title: 'Country',
                dataIndex: TemplateSortParameter.DECLARATION_EXTERNAL_TYPE,
                key: TemplateSortParameter.DECLARATION_EXTERNAL_TYPE,
                align: 'left',
                render: (text: string, record) => (
                    <span>{declarationExternalTypeToCountry(record.declarationExternalType)}</span>
                ),
                sorter: true,
            });
        if (options.visible?.declarationType)
            _columns.push({
                title: 'Declaration Type',
                dataIndex: TemplateSortParameter.DECLARATION_NAME,
                key: TemplateSortParameter.DECLARATION_NAME,
                align: 'left',
                render: (text: string, record) => <span>{toFullFormType(record.declarationName)}</span>,
                sorter: true,
            });
        if (options.visible?.templateName)
            _columns.push({
                title: 'Template Name',
                dataIndex: TemplateSortParameter.TEMPLATE_NAME,
                key: TemplateSortParameter.TEMPLATE_NAME,
                align: 'left',
                render: (text: string, record) => <span>{record.templateName}</span>,
                sorter: true,
            });
        return _columns;
    }, [options.visible?.country, options.visible?.declarationType, options.visible?.templateName, toFullFormType]);

    const handleOpenModal = (id: string, viewOnly?: boolean) => {
        const template = templates.list.find((t) => t.id === id);
        const country = template?.declarationExternalType === 'REVENUE' ? 'IRELAND' : 'UK';
        const declarationType = template?.declarationInternalType as 'IMPORT' | 'EXPORT';

        openModal?.({
            templateId: template?.id,
            country,
            declarationType,
            formType: template?.declarationName as any,
            name: template?.templateName,
            viewOnly,
        });
    };

    const openDrawer = (template: Template | undefined) => {
        setDrawerOpen(true);
        setTemplate(template);
    };
    const closeDrawer = () => {
        setDrawerOpen(false);
        setTemplate(undefined);
    };

    const handleEdit = (id: string) => {
        const template = templates.list.find((t) => t.id === id);
        openDrawer(template);
    };

    const handleArchive = (id: string) => {
        setArchiveId(id);
        showArchiveModal();
    };

    const handleUnarchive = (id: string) => {
        setArchiveId(id);
        showUnarchiveModal();
    };

    const archiveTemplateFunc = () => {
        hideArchiveModal();
        if (!archiveId) return notification.error({ message: 'Could not archive the template!' });
        archiveTemplate(archiveId)
            .then(() => {
                listRequest();
                notification.success({ message: 'Template archived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not archive the template!' });
            })
            .finally(() => {
                setArchiveId(undefined);
            });
    };
    const unarchiveTemplateFunc = () => {
        hideUnarchiveModal();
        if (!archiveId) return notification.error({ message: 'Could not unarchive the template!' });
        unarchiveTemplate(archiveId)
            .then(() => {
                listRequest();
                notification.success({ message: 'Template unarchived successfully!' });
            })
            .catch(() => {
                notification.error({ message: 'Could not unarchive the template!' });
            })
            .finally(() => {
                setArchiveId(undefined);
            });
    };

    return (
        <>
            <DashboardTable
                columns={columns}
                data={showEmpty ? undefined : templates}
                withPagination={withPagination}
                onPaginatorChange={listRequest}
                doSelectOnClick={viewOnly}
                onRowSelection={viewOnly ? (ids) => onRowClick?.(ids[0]) : undefined}
                rowSelectionType="radio"
                onDuplicate={viewOnly ? undefined : handleOpenModal}
                onEdit={viewOnly ? undefined : handleEdit}
                onView={(r) => handleOpenModal(r, true)}
                onUnarchive={handleUnarchive}
                onArchive={handleArchive}
                atArchivedPath={atArchivedPath}
            />
            {!viewOnly && <EditTemplateNameDrawer onClose={closeDrawer} visible={drawerOpen} template={template} />}
            <CustomModal
                title={<H5>Do you want to archive this template?</H5>}
                centered
                visible={archiveModalVisible}
                onOk={archiveTemplateFunc}
                onCancel={hideArchiveModal}
                width={762}
                confirmText="Archive"
                cancelText="Cancel"
                contentText={'The template will be added to the archive.'}
            />
            <CustomModal
                title={<H5>Do you want to unarchive this template?</H5>}
                centered
                visible={unarchiveModalVisible}
                onOk={unarchiveTemplateFunc}
                onCancel={hideUnarchiveModal}
                width={762}
                confirmText="Unarchive"
                cancelText="Cancel"
                contentText={'The template will be unarchived and become active.'}
            />
        </>
    );
};

export default TemplatesTable;
