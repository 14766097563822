import { get, isEmpty } from 'lodash';
import { ParsedCard, ParsedField, ParsedItem } from 'store/declaration-form-errors/ParsedForm';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { SimplifiedDeclarationDocumentWritingOffHeader } from 'store/declarations/common/simplified-declaration-document-writing-off-header';
import { SupplyChainActor } from 'store/declarations/common/supply-chain-actor';
import {
    AdditionalFiscalReference,
    AdditionalInformation,
    AdditionsDeductions,
    AuthorisationHolder,
    GoodsLocation,
    GoodsShipment,
    GoodsShipmentItem,
    Guarantee,
    GuaranteeReference,
    IrelandImportDeclaration,
    Packages,
    SimplifiedDeclarationDocumentWritingOffItem,
    TaxBox,
} from 'store/declarations/ireland/import-declaration';

export type ParserOptions = Record<'withoutCustomers', boolean>;

export const handleMultiple = (
    parsedCards: ParsedCard[],
    cardValue: any[] | string | undefined,
    cardName: string,
    callback: () => void
) => {
    if (typeof cardValue === 'string') {
        parsedCards.push({
            name: cardName,
            fields: [{ name: cardName, message: cardValue }],
        });
    } else if (Array.isArray(cardValue)) {
        callback();
    }
};

export const parseDeclaration = (declaration: IrelandImportDeclaration, options?: ParserOptions) => {
    const masterDetailsParsedCards: ParsedCard[] = [];
    parseMasterDetails(masterDetailsParsedCards, declaration);
    if (!options?.withoutCustomers) {
        parseExporter(masterDetailsParsedCards, declaration);
        parseImporter(masterDetailsParsedCards, declaration);
        parseDeclarant(masterDetailsParsedCards, declaration);
        parseRepresentative(masterDetailsParsedCards, declaration);
        parseSeller(masterDetailsParsedCards, declaration);
        parseBuyer(masterDetailsParsedCards, declaration);
    }
    parseValueDetails(masterDetailsParsedCards, declaration);
    parsePreviousDocumentInformation(masterDetailsParsedCards, declaration);
    parseDeliveryTerms(masterDetailsParsedCards, declaration);
    parseTransportInformation(masterDetailsParsedCards, declaration);
    parseTransportDocumentInformation(masterDetailsParsedCards, declaration);
    parseGoodsLocation(masterDetailsParsedCards, declaration);
    parseOtherDetails(masterDetailsParsedCards, declaration);
    parseExtraDetails(masterDetailsParsedCards, declaration);
    parseAuthorisation(masterDetailsParsedCards, declaration);
    parseAuthorisationHolder(masterDetailsParsedCards, declaration);
    parseGuarantee(masterDetailsParsedCards, declaration);
    parseGoodsShipment(masterDetailsParsedCards, declaration);
    parseAdditionalInformation(masterDetailsParsedCards, declaration);
    parseAdditionalConditionalInformation(masterDetailsParsedCards, declaration);

    const parsedItemDetails: ParsedItem[] = [];
    parseDeclarationItems(parsedItemDetails, declaration);
    const parsedDeclaration = {
        masterDetails: masterDetailsParsedCards,
        items: parsedItemDetails,
    };
    return parsedDeclaration;
};

const parseMasterDetails = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const declarationTypePath = 'declarationType';
    const declarationTypeValue = get(declaration, declarationTypePath);
    if (declarationTypeValue) {
        fields.push({ name: 'Declaration Type', message: declarationTypeValue, path: declarationTypePath });
    }

    const additionalDeclarationTypePath = 'additionalDeclarationType';
    const additionalDeclarationTypeValue = get(declaration, additionalDeclarationTypePath);
    if (additionalDeclarationTypeValue) {
        fields.push({
            name: 'Additional Declaration Type',
            message: additionalDeclarationTypeValue,
            path: additionalDeclarationTypePath,
        });
    }

    const ucrPath = `goodsShipment.ucr`;
    const ucrPathValue = get(declaration, ucrPath);
    if (ucrPathValue) {
        fields.push({ name: 'Ucr', message: ucrPathValue, path: ucrPath });
    }

    const numberOfItemsPath = 'numberOfItems';
    const numberOfItemsValue = get(declaration, numberOfItemsPath);
    if (numberOfItemsValue) {
        fields.push({ name: 'Number of items', message: numberOfItemsValue, path: numberOfItemsPath });
    }

    const invoiceNumberPath = `goodsShipment.producedDocumentsWritingOff.N935.[0]`;
    const invoiceNumberValue = get(declaration, invoiceNumberPath);
    if (invoiceNumberValue) {
        fields.push({
            name: 'Invoice Number',
            message: invoiceNumberValue,
            path: invoiceNumberPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Master Details', fields });
    }
};

export const addToParties = (masterDetailsParsedCards: ParsedCard[], fields: ParsedField[]) => {
    const index = masterDetailsParsedCards.findIndex(({ name }) => name === 'Parties');
    if (index === -1) {
        masterDetailsParsedCards.push({ name: 'Parties', fields });
        return;
    }
    masterDetailsParsedCards[index] = {
        name: 'Parties',
        fields: masterDetailsParsedCards[index].fields?.concat(fields),
    };
};

const parseExporter = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const exporterPath = 'exporter';
    const exporterValue = get(declaration, exporterPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(exporterValue, 'Exporter', exporterPath);

    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};

const parseImporter = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const importerPath = 'goodsShipment.importer';
    const importerValue = get(declaration, importerPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(importerValue, 'Importer', importerPath);
    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};
const parseDeclarant = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const declarantPath = 'declarant';
    const declarantValue = get(declaration, declarantPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(declarantValue, 'Declarant', declarantPath);
    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};

const parseRepresentative = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const representativePath = 'representative';
    const representativeValue = get(declaration, representativePath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(representativeValue, 'Representative', representativePath);
    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};

const parseSeller = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const sellerPath = 'goodsShipment.seller';
    const sellerValue = get(declaration, sellerPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(sellerValue, 'Seller', sellerPath);
    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};

const parseBuyer = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const buyerPath = 'goodsShipment.buyer';
    const buyerValue = get(declaration, buyerPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(buyerValue, 'Buyer', buyerPath);
    if (fields.length === 0) return;
    addToParties(masterDetailsParsedCards, fields);
};

const parseValueDetails = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const invoiceCurrencyPath = 'invoiceCurrency';
    const invoiceCurrencyValue = get(declaration, invoiceCurrencyPath);
    if (invoiceCurrencyValue) {
        fields.push({ name: 'Invoice Currency', message: invoiceCurrencyValue, path: invoiceCurrencyPath });
    }

    const invoiceAmountPath = 'invoiceAmount';
    const invoiceAmountValue = get(declaration, invoiceAmountPath);
    if (invoiceAmountValue) {
        fields.push({ name: 'Total amount invoiced', message: invoiceAmountValue, path: invoiceAmountPath });
    }

    const exchangeRatePath = 'exchangeRate';
    const exchangeRateValue = get(declaration, exchangeRatePath);
    if (exchangeRateValue) {
        fields.push({ name: 'Exchange Rate', message: exchangeRateValue, path: exchangeRatePath });
    }

    const preferredPaymentMethodPath = 'preferredPaymentMethod';
    const preferredPaymentMethodValue = get(declaration, preferredPaymentMethodPath);
    if (preferredPaymentMethodValue) {
        fields.push({
            name: 'Preferred Payment Method',
            message: preferredPaymentMethodValue,
            path: preferredPaymentMethodPath,
        });
    }

    const customsDutyPayerPath = 'personPayingCustomsDuty';
    const customsDutyPayerValue = get(declaration, customsDutyPayerPath);
    if (customsDutyPayerValue) {
        fields.push({ name: 'Customs Duty Payer', message: customsDutyPayerValue, path: customsDutyPayerPath });
    }

    const dv1DeclarationPath = `goodsShipment.producedDocumentsWritingOff.N934.[0]`;
    const dv1DeclarationValue = get(declaration, dv1DeclarationPath);
    if (dv1DeclarationValue) {
        fields.push({ name: 'DV1 Declaration', message: dv1DeclarationValue, path: dv1DeclarationPath });
    }

    const vatFreeAuthorisationPath = `goodsShipment.producedDocumentsWritingOff.1A01.[0]`;
    const vatFreeAuthorisationValue = get(declaration, vatFreeAuthorisationPath);
    if (vatFreeAuthorisationValue) {
        fields.push({
            name: 'Vat Free Authorisation',
            message: vatFreeAuthorisationValue,
            path: vatFreeAuthorisationPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Value details', fields });
    }
};

export const parseDeclarationCustomer = (customer: DeclarationCustomer, customerName?: string, path?: string) => {
    const fields: ParsedField[] = [];

    const addCustomerName = (fieldName: string) => {
        if (!!!customerName) {
            return fieldName;
        }
        return customerName + ' - ' + fieldName;
    };

    const eoriPath = `eori`;
    const eoriValue = get(customer, eoriPath);
    if (eoriValue) {
        fields.push({
            name: addCustomerName('Identification Number'),
            message: eoriValue,
            path: `${path}.${eoriPath}`,
        });
    }

    const namePath = `name`;
    const nameValue = get(customer, namePath);
    if (nameValue) {
        fields.push({ name: addCustomerName('Name'), message: nameValue, path: `${path}.${namePath}` });
    }

    const streetAndNumberPath = `streetAndNumber`;
    const streetAndNumberValue = get(customer, streetAndNumberPath);
    if (streetAndNumberValue) {
        fields.push({
            name: addCustomerName('Street And Number'),
            message: streetAndNumberValue,
            path: `${path}.${streetAndNumberPath}`,
        });
    }

    const cityPath = `city`;
    const cityValue = get(customer, cityPath);
    if (cityValue) {
        fields.push({ name: addCustomerName('City'), message: cityValue, path: `${path}.${cityPath}` });
    }

    const postCodePath = `postCode`;
    const postCodeValue = get(customer, postCodePath);
    if (postCodeValue) {
        fields.push({ name: addCustomerName('Post Code'), message: postCodeValue, path: `${path}.${postCodePath}` });
    }

    const countryPath = `country`;
    const countryValue = get(customer, countryPath);
    if (countryValue) {
        fields.push({ name: addCustomerName('Country'), message: countryValue, path: `${path}.${countryPath}` });
    }

    const representativeStatusPath = 'statusCode';
    const representativeStatusValue = get(customer, representativeStatusPath);
    if (representativeStatusValue) {
        fields.push({
            name: addCustomerName('Representative Status'),
            message: representativeStatusValue,
            path: `${path}.${representativeStatusPath}`,
        });
    }

    return fields;
};

const parsePreviousDocumentInformation = (
    masterDetailsParsedCards: ParsedCard[],
    declaration: IrelandImportDeclaration
) => {
    const previousDocumentPath = 'goodsShipment.simplifiedDeclarationDocumentWritingOff';
    const previousDocumentValue = get(declaration, previousDocumentPath);
    if (previousDocumentValue && !Array.isArray(previousDocumentValue)) {
        masterDetailsParsedCards.push({
            name: `Previous Document`,
            fields: [{ name: 'Previous Document', message: previousDocumentValue, path: previousDocumentPath }],
        });
        return;
    }

    handleMultiple(masterDetailsParsedCards, previousDocumentValue, 'Previous Document', () =>
        previousDocumentValue?.forEach((element: SimplifiedDeclarationDocumentWritingOffHeader, index: number) => {
            const fields: ParsedField[] = [];

            const previousDocumentTypePath = 'previousDocumentType';
            const previousDocumentValue = get(element, previousDocumentTypePath);
            if (previousDocumentValue) {
                fields.push({
                    name: `Previous document type `,
                    message: previousDocumentValue,
                    path: previousDocumentTypePath,
                });
            }

            const previousDocumentIdentifierPath = 'previousDocumentIdentifier';
            const previousDocumentIdentifierValue = get(element, previousDocumentIdentifierPath);
            if (previousDocumentIdentifierValue) {
                fields.push({
                    name: `Previous document identifier`,
                    message: previousDocumentIdentifierValue,
                    path: previousDocumentIdentifierPath,
                });
            }

            const previousDocumentLineIdPath = 'previousDocumentLineId';
            const previousDocumentLineIdValue = get(element, previousDocumentLineIdPath);
            if (previousDocumentLineIdValue) {
                fields.push({
                    name: `Goods Item Identifier`,
                    message: previousDocumentLineIdValue,
                    path: previousDocumentLineIdPath,
                });
            }

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Previous Document ${index}`, fields });
            }
        })
    );
};

const parseDeliveryTerms = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const incotermCodePath = 'goodsShipment.incotermCode';
    const incotermCodeValue = get(declaration, incotermCodePath);
    if (incotermCodeValue) {
        fields.push({ name: 'INCOTERM code', message: incotermCodeValue, path: incotermCodePath });
    }

    const countryCodePath = 'goodsShipment.countryCode';
    const countryCodeValue = get(declaration, countryCodePath);
    if (countryCodeValue) {
        fields.push({ name: 'Country code', message: countryCodeValue, path: countryCodePath });
    }

    const locationNamePath = 'goodsShipment.place';
    const locationNameValue = get(declaration, locationNamePath);
    if (locationNameValue) {
        fields.push({ name: 'Location name', message: locationNameValue, path: locationNamePath });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Delivery terms', fields });
    }
};

const parseTransportInformation = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const customsOfficeOfLodgementPath = 'customsOfficeLodgement';
    const customsOfficeOfLodgementValue = get(declaration, customsOfficeOfLodgementPath);
    if (customsOfficeOfLodgementValue) {
        fields.push({
            name: 'Customs Office of Lodgement',
            message: customsOfficeOfLodgementValue,
            path: customsOfficeOfLodgementPath,
        });
    }

    const customsOfficeOfPresentationPath = 'presentationCustomsOffice';
    const customsOfficeOfPResentationValue = get(declaration, customsOfficeOfPresentationPath);
    if (customsOfficeOfPResentationValue) {
        fields.push({
            name: 'Customs office of presentation',
            message: customsOfficeOfPResentationValue,
            path: customsOfficeOfPresentationPath,
        });
    }

    const officeImportExportPath = 'supervisingCustomsOffice';
    const officeImportExportValue = get(declaration, officeImportExportPath);
    if (officeImportExportValue) {
        fields.push({ name: 'Office Import/Export', message: officeImportExportValue, path: officeImportExportPath });
    }

    const inlandModeOfTransportPath = 'goodsShipment.inlandBorderTransportMode';
    const inlandModeOfTransportValue = get(declaration, inlandModeOfTransportPath);
    if (inlandModeOfTransportValue) {
        fields.push({
            name: 'Inland mode of transport',
            message: inlandModeOfTransportValue,
            path: inlandModeOfTransportPath,
        });
    }

    const transportIdentityMeansTypePath = 'goodsShipment.meansIdentificationType';
    const transportIdentityMeansTypeValue = get(declaration, transportIdentityMeansTypePath);
    if (transportIdentityMeansTypeValue) {
        fields.push({
            name: 'Transport Identity Means Type',
            message: transportIdentityMeansTypeValue,
            path: transportIdentityMeansTypePath,
        });
    }

    const transportIdentityMeansIdPath = 'goodsShipment.meansIdentificationNumber';
    const transportIdentityMeansIdValue = get(declaration, transportIdentityMeansIdPath);
    if (transportIdentityMeansIdValue) {
        fields.push({
            name: 'Transport Identity Means Id',
            message: transportIdentityMeansIdValue,
            path: transportIdentityMeansIdPath,
        });
    }

    const modeOfTransportAtTheBorderPath = 'borderTransportMode';
    const modeOfTransportAtTheBorderValue = get(declaration, modeOfTransportAtTheBorderPath);
    if (modeOfTransportAtTheBorderValue) {
        fields.push({
            name: 'Mode of transport at the border',
            message: modeOfTransportAtTheBorderValue,
            path: modeOfTransportAtTheBorderPath,
        });
    }

    const transportNationalityMeansAtTheBorderPath = 'activeBorderTransportMeansNationality';
    const transportNationalityMeansAtTheBorderValue = get(declaration, transportNationalityMeansAtTheBorderPath);
    if (transportNationalityMeansAtTheBorderValue) {
        fields.push({
            name: 'Transport Nationality Means at the Border',
            message: transportNationalityMeansAtTheBorderValue,
            path: transportNationalityMeansAtTheBorderPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Transport information', fields });
    }
};

const parseTransportDocumentInformation = (
    masterDetailsParsedCards: ParsedCard[],
    declaration: IrelandImportDeclaration
) => {
    const fields: ParsedField[] = [];

    const airwayBillPath = 'goodsShipment.producedDocumentsWritingOff.N740.[0]';
    const airwayBillValue = get(declaration, airwayBillPath);
    if (airwayBillValue) {
        fields.push({
            name: 'Airway bill',
            message: airwayBillValue,
            path: airwayBillPath,
        });
    }

    const masterAirwayBillPath = 'goodsShipment.producedDocumentsWritingOff.N741.[0]';
    const masterAirwayBillValue = get(declaration, masterAirwayBillPath);
    if (masterAirwayBillValue) {
        fields.push({
            name: 'Master Airway Bill',
            message: masterAirwayBillValue,
            path: masterAirwayBillPath,
        });
    }

    const houseWayBillPath = 'goodsShipment.producedDocumentsWritingOff.N703.[0]';
    const houseWayBillValue = get(declaration, houseWayBillPath);
    if (houseWayBillValue) {
        fields.push({
            name: 'House Way Bill',
            message: houseWayBillValue,
            path: houseWayBillPath,
        });
    }

    const billOfLadingPath = 'goodsShipment.producedDocumentsWritingOff.N705.[0]';
    const billOfLadingValue = get(declaration, billOfLadingPath);
    if (billOfLadingValue) {
        fields.push({
            name: 'Bill of Lading',
            message: billOfLadingValue,
            path: billOfLadingPath,
        });
    }

    const roadConsignmentNotePath = 'goodsShipment.producedDocumentsWritingOff.N730.[0]';
    const roadConsignmentNoteValue = get(declaration, roadConsignmentNotePath);
    if (roadConsignmentNoteValue) {
        fields.push({
            name: 'Road Consignment Note',
            message: roadConsignmentNoteValue,
            path: roadConsignmentNotePath,
        });
    }

    const masterBillOfLadingPath = 'goodsShipment.producedDocumentsWritingOff.N704.[0]';
    const masterBillOfLadingValue = get(declaration, masterBillOfLadingPath);
    if (masterBillOfLadingValue) {
        fields.push({
            name: 'Master Bill of Lading',
            message: masterBillOfLadingValue,
            path: masterBillOfLadingPath,
        });
    }

    const houseBillOfLadingPath = 'goodsShipment.producedDocumentsWritingOff.N714.[0]';
    const houseBillOfLadingValue = get(declaration, houseBillOfLadingPath);
    if (houseBillOfLadingValue) {
        fields.push({
            name: 'House Bill of Lading',
            message: houseBillOfLadingValue,
            path: houseBillOfLadingPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Transport document information', fields });
    }
};

const parseGoodsLocation = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];
    const goodsLocation = 'goodsShipment.locationGoods';

    const typeOfLocationCodePath = `${goodsLocation}.locationTypeCode`;
    const typeOfLocationCodeValue = get(declaration, typeOfLocationCodePath);
    if (typeOfLocationCodeValue) {
        fields.push({ name: 'Type of location code', message: typeOfLocationCodeValue, path: typeOfLocationCodePath });
    }

    const qualifierOfTheIdentificationPath = `${goodsLocation}.qualifierIdentification`;
    const qualifierOfTheIdentificationValue = get(declaration, qualifierOfTheIdentificationPath);
    if (qualifierOfTheIdentificationValue) {
        fields.push({
            name: 'Qualifier of the identification',
            message: qualifierOfTheIdentificationValue,
            path: qualifierOfTheIdentificationPath,
        });
    }

    const identificationOfLocationPath = `${goodsLocation}.identificationOfLocation`;
    const identificationOfLocationValue = get(declaration, identificationOfLocationPath);
    if (identificationOfLocationValue) {
        fields.push({
            name: 'Identification of location',
            message: identificationOfLocationValue,
            path: identificationOfLocationPath,
        });
    }

    const additionalIdentifierPath = `${goodsLocation}.additionalIdentifier`;
    const additionalIdentifierValue = get(declaration, additionalIdentifierPath);
    if (additionalIdentifierValue) {
        fields.push({
            name: 'Additional Identifier',
            message: additionalIdentifierValue,
            path: additionalIdentifierPath,
        });
    }

    const countryPath = `${goodsLocation}.address.country`;
    const countryValue = get(declaration, countryPath);
    if (countryValue) {
        fields.push({ name: 'Country', message: countryValue, path: countryPath });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Goods location', fields });
    }
};

const parseOtherDetails = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const warehouseTypePath = 'goodsShipment.warehouseType';
    const warehouseTypeValue = get(declaration, warehouseTypePath);
    if (warehouseTypeValue) {
        fields.push({ name: 'Warehouse type', message: warehouseTypeValue, path: warehouseTypePath });
    }

    const warehouseIdentifierPath = 'goodsShipment.warehouseIdentifier';
    const warehouseIdentifierValue = get(declaration, warehouseIdentifierPath);
    if (warehouseIdentifierValue) {
        fields.push({ name: `Warehouse Identifier`, message: warehouseIdentifierValue, path: warehouseIdentifierPath });
    }

    const countryDestinationPath = 'goodsShipment.countryDestination';
    const countryDestinationValue = get(declaration, countryDestinationPath);
    if (countryDestinationValue) {
        fields.push({ name: 'Country destination', message: countryDestinationValue, path: countryDestinationPath });
    }

    const countryDispatchPath = 'goodsShipment.countryDispatch';
    const countryDispatchValue = get(declaration, countryDispatchPath);
    if (countryDispatchValue) {
        fields.push({ name: 'Country dispatch', message: countryDispatchValue, path: countryDispatchPath });
    }

    const dateOfAcceptancePath = 'goodsShipment.acceptanceDate';
    const dateOfAcceptanceValue = get(declaration, dateOfAcceptancePath);
    if (dateOfAcceptanceValue) {
        fields.push({ name: 'Date of acceptance', message: dateOfAcceptanceValue, path: dateOfAcceptancePath });
    }

    const estimatedTimeOfArrivalPath = 'goodsShipment.producedDocumentsWritingOff.1D24.[0]';
    const estimatedTimeOfArrivalValue = get(declaration, estimatedTimeOfArrivalPath);
    if (estimatedTimeOfArrivalValue) {
        fields.push({
            name: 'Estimated Time of Arrival',
            message: estimatedTimeOfArrivalValue,
            path: estimatedTimeOfArrivalPath,
        });
    }

    const natureOfTransactionPath = 'goodsShipment.transactionNature';
    const natureOfTransactionValue = get(declaration, natureOfTransactionPath);
    if (natureOfTransactionValue) {
        fields.push({
            name: 'Nature of transaction',
            message: natureOfTransactionValue,
            path: natureOfTransactionPath,
        });
    }

    const roroShipImoPath = 'goodsShipment.producedDocumentsWritingOff.1D94.[0]';
    const roroShipImoValue = get(declaration, roroShipImoPath);
    if (roroShipImoValue) {
        fields.push({
            name: 'RoRo Ship IMO',
            message: roroShipImoValue,
            path: roroShipImoPath,
        });
    }

    const roroUnaccompaniedTrailerRegistrationNumberPath = 'goodsShipment.producedDocumentsWritingOff.1D95.[0]';
    const roroUnaccompaniedTrailerRegistrationNumberValue = get(
        declaration,
        roroUnaccompaniedTrailerRegistrationNumberPath
    );
    if (roroUnaccompaniedTrailerRegistrationNumberValue) {
        fields.push({
            name: 'Roro unaccompanied trailer registration number',
            message: roroUnaccompaniedTrailerRegistrationNumberValue,
            path: roroUnaccompaniedTrailerRegistrationNumberPath,
        });
    }

    const textilesCertificateOfOriginPath = 'goodsShipment.producedDocumentsWritingOff.1Q35.[0]';
    const textilesCertificatesOfOriginValue = get(declaration, textilesCertificateOfOriginPath);
    if (textilesCertificatesOfOriginValue) {
        fields.push({
            name: 'Textiles certificate of origin',
            message: textilesCertificatesOfOriginValue,
            path: textilesCertificateOfOriginPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Other details', fields });
    }
};

const parseExtraDetails = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const deferredPaymentPath = 'deferredPayment';
    const deferredPaymentValue = get(declaration, deferredPaymentPath);
    if (deferredPaymentValue) {
        fields.push({ name: 'Deferred Payment', message: deferredPaymentValue, path: deferredPaymentPath });
    }

    const internalCurrencyPath = 'internalCurrency';
    const internalCurrencyValue = get(declaration, internalCurrencyPath);
    if (internalCurrencyValue) {
        fields.push({ name: 'Internal Currency', message: internalCurrencyValue, path: internalCurrencyPath });
    }

    const personProvidingAGuaranteeIdentificationPath = 'personProvidingGuarantee';
    const personProvidingAGuaranteeIdentificationValue = get(declaration, personProvidingAGuaranteeIdentificationPath);
    if (personProvidingAGuaranteeIdentificationValue) {
        fields.push({
            name: 'Person Providing a Guarantee identification',
            message: personProvidingAGuaranteeIdentificationValue,
            path: personProvidingAGuaranteeIdentificationPath,
        });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Extra details', fields });
    }
};

const parseAuthorisation = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const goodsItemRateOfYieldPath = 'authorisation.rateOfYield';
    const goodsItemRateOfYieldValue = get(declaration, goodsItemRateOfYieldPath);
    if (goodsItemRateOfYieldValue) {
        fields.push({
            name: `Rate of Yield`,
            message: goodsItemRateOfYieldValue,
            path: goodsItemRateOfYieldPath,
        });
    }

    const goodsItemCommodityCodePath = 'authorisation.processedProductsCommodityCode';
    const goodsItemCommodityCodeValue = get(declaration, goodsItemCommodityCodePath);
    if (goodsItemCommodityCodeValue) {
        fields.push({
            name: `Commodity Code`,
            message: goodsItemCommodityCodeValue,
            path: goodsItemCommodityCodePath,
        });
    }

    const goodsItemDescriptionOfGoodsPath = 'authorisation.processedProductsDescriptionOfGoods';
    const goodsItemDescriptionOfGoodsValue = get(declaration, goodsItemDescriptionOfGoodsPath);
    if (goodsItemDescriptionOfGoodsValue) {
        fields.push({
            name: `Description of Goods`,
            message: goodsItemDescriptionOfGoodsValue,
            path: goodsItemDescriptionOfGoodsPath,
        });
    }

    const goodsItemIdentificationOfGoodsCodePath = 'authorisation.identificationOfGoodsCode';
    const goodsItemIdentificationOfGoodsCodeValue = get(declaration, goodsItemIdentificationOfGoodsCodePath);
    if (goodsItemIdentificationOfGoodsCodeValue) {
        fields.push({
            name: `Identification of goods code`,
            message: goodsItemIdentificationOfGoodsCodeValue,
            path: goodsItemIdentificationOfGoodsCodePath,
        });
    }

    const identificationPath = 'authorisation.identification';
    const identificationValue = get(declaration, identificationPath);
    if (identificationValue) {
        fields.push({
            name: `identification`,
            message: identificationValue,
            path: identificationPath,
        });
    }

    const goodsItemProcessingProcedurePath = 'authorisation.economicConditionsProcessingProcedure';
    const goodsItemProcessingProcedureValue = get(declaration, goodsItemProcessingProcedurePath);
    if (goodsItemProcessingProcedureValue) {
        fields.push({
            name: `Processing Procedure`,
            message: goodsItemProcessingProcedureValue,
            path: goodsItemProcessingProcedurePath,
        });
    }

    const goodsItemDetailsPath = 'authorisation.economicConditionsDetails';
    const goodsItemDetailsValue = get(declaration, goodsItemDetailsPath);
    if (goodsItemDetailsValue) {
        fields.push({ name: `Details`, message: goodsItemDetailsValue, path: goodsItemDetailsPath });
    }

    const authorisationCustomsOfficeOfDischargePath = 'authorisation.customsOfficeOfDischarge';
    const authorisationCustomsOfficeOfDischargeValue = get(declaration, authorisationCustomsOfficeOfDischargePath);
    if (authorisationCustomsOfficeOfDischargeValue) {
        fields.push({
            name: 'Authorisation Customs office of discharge',
            message: authorisationCustomsOfficeOfDischargeValue,
            path: authorisationCustomsOfficeOfDischargePath,
        });
    }

    const authorisationOfficeImportExportPath = 'authorisation.supervisingCustomsOffice';
    const authorisationOfficeImportExportValue = get(declaration, authorisationOfficeImportExportPath);
    if (authorisationOfficeImportExportValue) {
        fields.push({
            name: 'Authorisation Office Import/Export',
            message: authorisationOfficeImportExportValue,
            path: authorisationOfficeImportExportPath,
        });
    }

    const authorisationPeriodPath = 'authorisation.period';
    const authorisationPeriodValue = get(declaration, authorisationPeriodPath);
    if (authorisationPeriodValue) {
        fields.push({ name: 'Authorisation Period', message: authorisationPeriodValue, path: authorisationPeriodPath });
    }

    const authorisationPeriodDetailsPath = 'authorisation.periodDetails';
    const authorisationPeriodDetailsValue = get(declaration, authorisationPeriodDetailsPath);
    if (authorisationPeriodDetailsValue) {
        fields.push({
            name: 'Authorisation Period details',
            message: authorisationPeriodDetailsValue,
            path: authorisationPeriodDetailsPath,
        });
    }

    const authorisationBillOfDischargeDeadlinePath = 'authorisation.billOfDischargeDeadline';
    const authorisationBillOfDischargeDeadlineValue = get(declaration, authorisationBillOfDischargeDeadlinePath);
    if (authorisationBillOfDischargeDeadlineValue) {
        fields.push({
            name: 'Authorisation Bill of discharge deadline',
            message: authorisationBillOfDischargeDeadlineValue,
            path: authorisationBillOfDischargeDeadlinePath,
        });
    }

    const authorisationBillOfDischargeDetailsPath = 'authorisation.billOfDischargeDetails';
    const authorisationBillOfDischargeDetailsValue = get(declaration, authorisationBillOfDischargeDetailsPath);
    if (authorisationBillOfDischargeDetailsValue) {
        fields.push({
            name: 'Authorisation Bill of discharge details',
            message: authorisationBillOfDischargeDetailsValue,
            path: authorisationBillOfDischargeDetailsPath,
        });
    }

    const authorisationDetailsOfPlannedActivitiesPath = 'authorisation.detailsOfPlannedActivities';
    const authorisationDetailsOfPlannedActivitiesValue = get(declaration, authorisationDetailsOfPlannedActivitiesPath);
    if (authorisationDetailsOfPlannedActivitiesValue) {
        fields.push({
            name: 'Authorisation Details of planned activities',
            message: authorisationDetailsOfPlannedActivitiesValue,
            path: authorisationDetailsOfPlannedActivitiesPath,
        });
    }

    const authorisationAdditionalInformationPath = 'authorisation.additionalInformation';
    const authorisationAdditionalInformationValue = get(declaration, authorisationAdditionalInformationPath);
    if (authorisationAdditionalInformationValue) {
        fields.push({
            name: 'Authorisation Additional Information',
            message: authorisationAdditionalInformationValue,
            path: authorisationAdditionalInformationPath,
        });
    }

    const ownerOfTheGoodsPath = 'authorisation.ownerOfTheGoods';
    const ownerOfTheGoodsValue = get(declaration, ownerOfTheGoodsPath);
    handleMultiple(masterDetailsParsedCards, ownerOfTheGoodsValue, 'Owner Of The Goods', () =>
        ownerOfTheGoodsValue.forEach((ownerGoods: DeclarationCustomer, index: number) => {
            const fieldsOwner = parseDeclarationCustomer(ownerGoods, 'Owner Of The Goods', ownerOfTheGoodsPath);
            if (fieldsOwner.length > 0) {
                fieldsOwner.forEach((owner) => {
                    const cutomerError: ParsedField = {
                        ...owner,
                        name: `Owner Of The Goods ${index} ${owner.name}`,
                    };
                    fields.push(cutomerError);
                });
            }
        })
    );

    const firstPlaceOfUsePath = 'authorisation.firstPlaceOfUse';
    const firstPlaceOfUseValue = get(declaration, firstPlaceOfUsePath);
    const errorsFirstPlaceOfUse = getGoodsLocation(firstPlaceOfUseValue, `First place of use`);
    if (errorsFirstPlaceOfUse.length > 0) {
        fields.push(...errorsFirstPlaceOfUse);
    }

    const placeOfUsePath = 'authorisation.placeOfUse';
    const placeOfUseValue = get(declaration, placeOfUsePath);
    handleMultiple(masterDetailsParsedCards, placeOfUseValue, 'Place of use', () =>
        placeOfUseValue.forEach((placesOfUse: GoodsLocation, index: number) => {
            const fieldsOwner = getGoodsLocation(placesOfUse, `Place of use ${index + 1}`);
            if (fieldsOwner.length > 0) {
                fields.push(...fieldsOwner);
            }
        })
    );

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: 'Authorisation', fields });
    }
};

const parseAuthorisationHolder = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const authorisationHolderPath = 'authorisationHolder';
    const authorisationHolderValue = get(declaration, authorisationHolderPath) as any;
    handleMultiple(masterDetailsParsedCards, authorisationHolderValue, 'Authorisation Holder', () =>
        authorisationHolderValue?.forEach((auth: AuthorisationHolder, index: number) => {
            const fields: ParsedField[] = [];

            const typeCodePath = 'authorisationTypeCode';
            const typeCodeValue = get(auth, typeCodePath);
            if (typeCodeValue) {
                fields.push({ name: `Authorisation type code`, message: typeCodeValue, path: typeCodePath });
            }

            const holderIdentificationPath = 'authorisationHolderIdentification';
            const holderIdentificationValue = get(declaration, holderIdentificationPath);
            if (holderIdentificationValue) {
                fields.push({
                    name: `Authorisation holder identification`,
                    message: holderIdentificationValue,
                    path: holderIdentificationPath,
                });
            }

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Authorisation Holder ${index + 1}`, fields });
            }
        })
    );
};

const parseGuarantee = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const guaranteePath = 'guarantee';
    const guaranteeValue = get(declaration, guaranteePath) as any;

    handleMultiple(masterDetailsParsedCards, guaranteeValue, 'Guarantee', () =>
        guaranteeValue?.forEach((guarantee: Guarantee, index: number) => {
            const fields: ParsedField[] = [];

            const guaranteeTypePath = 'guaranteeType';
            const guaranteeTypeValue = get(guarantee, guaranteeTypePath);
            if (guaranteeTypeValue) {
                fields.push({ name: `Guarantee Type`, message: guaranteeTypeValue, path: guaranteeTypePath });
            }

            const guaranteeReferencePath = 'guaranteeReference';
            const guaranteeReferenceValue = get(guarantee, guaranteeReferencePath) as unknown as any[];
            handleMultiple(masterDetailsParsedCards, guaranteeReferenceValue, 'Guarantee Reference', () =>
                guaranteeReferenceValue.forEach((ref: GuaranteeReference) => {
                    const grnPath = 'grn';
                    const grnValue = get(ref, grnPath);
                    if (grnValue) {
                        fields.push({ name: `GRN`, message: grnValue, path: grnPath });
                    }

                    const guaranteeReferenceTypePath = 'otherGuaranteeReference';
                    const guaranteeReferenceTypeValue = get(ref, guaranteeReferenceTypePath);
                    if (guaranteeReferenceTypeValue) {
                        fields.push({
                            name: `Guarantee Reference ${index + 1} type`,
                            message: guaranteeReferenceTypeValue,
                            path: guaranteeReferenceTypePath,
                        });
                    }

                    const accessCodePath = 'accessCode';
                    const accessCodeValue = get(ref, accessCodePath);
                    if (accessCodeValue) {
                        fields.push({
                            name: `Guarantee Reference ${index + 1} access code `,
                            message: accessCodeValue,
                            path: accessCodePath,
                        });
                    }

                    const currencyPath = 'currency';
                    const currencyValue = get(ref, currencyPath);
                    if (currencyValue) {
                        fields.push({
                            name: `Guarantee Reference ${index + 1} currency`,
                            message: currencyValue,
                            path: currencyPath,
                        });
                    }

                    const amountPath = 'amount';
                    const amountValue = get(ref, amountPath);
                    if (amountValue) {
                        fields.push({
                            name: `Guarantee Reference ${index + 1} amount`,
                            message: amountValue,
                            path: amountPath,
                        });
                    }

                    const customsOfficePath = 'guaranteeCustomsOffice';
                    const customsOfficeValue = get(ref, customsOfficePath);
                    if (customsOfficeValue) {
                        fields.push({
                            name: `Guarantee Reference ${index + 1} customs office`,
                            message: customsOfficeValue,
                            path: customsOfficePath,
                        });
                    }
                })
            );

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Guarantee ${index + 1}`, fields });
            }
        })
    );
};

const parseGoodsShipment = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const fields: ParsedField[] = [];

    const regionDestinationPath = 'goodsShipment.regionDestination';
    const regionDestinationValue = get(declaration, regionDestinationPath);
    if (regionDestinationValue) {
        fields.push({ name: `Region destination`, message: regionDestinationValue, path: regionDestinationPath });
    }

    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: `GoodsShipment`, fields });
    }
};

const parseAdditionalInformation = (masterDetailsParsedCards: ParsedCard[], declaration: IrelandImportDeclaration) => {
    const additionalInformationPath = 'goodsShipment.additionalInformation';
    const additionalInformationValue = get(declaration, additionalInformationPath) as unknown as any[];
    handleMultiple(masterDetailsParsedCards, additionalInformationValue, 'Additional Information', () =>
        additionalInformationValue?.forEach((info: AdditionalInformation, index) => {
            const fields: ParsedField[] = [];

            const additionalInformationCodePath = 'additionalInformationCode';
            const additionalInformationCodeValue = get(info, additionalInformationCodePath);
            if (additionalInformationCodeValue) {
                fields.push({
                    name: `Additional Information Code`,
                    message: additionalInformationCodeValue,
                    path: additionalInformationCodePath,
                });
            }

            const additionalInformationTextPath = 'additionalInformationText';
            const additionalInformationTextValue = get(info, additionalInformationTextPath);
            if (additionalInformationTextValue) {
                fields.push({
                    name: `Additional Information Text`,
                    message: additionalInformationTextValue,
                    path: additionalInformationTextPath,
                });
            }

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Additional Information ${index + 1}`, fields });
            }
        })
    );
};

const parseAdditionalConditionalInformation = (
    masterDetailsParsedCards: ParsedCard[],
    declaration: IrelandImportDeclaration
) => {
    const supplyChainActorPath = 'goodsShipment.supplyChainActor';
    const supplyChainActorValue = get(declaration, supplyChainActorPath) as unknown as any[];
    handleMultiple(masterDetailsParsedCards, supplyChainActorValue, 'Supply Chain Actor', () =>
        supplyChainActorValue?.forEach((supplyChainActor: SupplyChainActor, index) => {
            const fields: ParsedField[] = [];

            const roleCodePath = 'roleCode';
            const roleCodeValue = get(supplyChainActor, roleCodePath);
            if (roleCodeValue) {
                fields.push({ name: `Role code`, message: roleCodeValue, path: roleCodePath });
            }

            const traderIdentificationPath = 'traderIdentification';
            const traderIdentificationValue = get(supplyChainActor, traderIdentificationPath);
            if (traderIdentificationValue) {
                fields.push({
                    name: `Trader identification`,
                    message: traderIdentificationValue,
                    path: traderIdentificationPath,
                });
            }

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Supply Chain Actor ${index + 1}`, fields });
            }
        })
    );

    const additionsAndDeductionsPath = 'goodsShipment.additionsDeductions';
    const additionsAndDeductionsValue = get(declaration, additionsAndDeductionsPath) as unknown as any[];
    handleMultiple(masterDetailsParsedCards, additionsAndDeductionsValue, 'Additions and Deductions', () =>
        additionsAndDeductionsValue?.forEach((additionsAndDeductions: AdditionsDeductions, index) => {
            const fields: ParsedField[] = [];

            const codePath = 'additionsDeductionsCode';
            const codeValue = get(additionsAndDeductions, codePath);
            if (codeValue) {
                fields.push({ name: `Code`, message: codeValue, path: codePath });
            }

            const amountPath = 'amount';
            const amountValue = get(additionsAndDeductions, amountPath);
            if (amountValue) {
                fields.push({ name: `Amount`, message: amountValue, path: amountPath });
            }

            if (fields.length > 0) {
                masterDetailsParsedCards.push({ name: `Additions and Deductions ${index + 1}`, fields });
            }
        })
    );

    const containerPath = 'goodsShipment.container';
    const containerValue = get(declaration, containerPath);
    if (containerValue) {
        masterDetailsParsedCards.push({
            name: `Additional Conditional Information`,
            fields: [{ name: 'Container', message: containerValue, path: containerPath }],
        });
    }

    const containerIdentificationNumberPath = 'goodsShipment.containerIdentificationNumber';
    const containerIdentificationNumberValue = get(declaration, containerIdentificationNumberPath) as unknown as any[];
    const fields: ParsedField[] = [];
    handleMultiple(
        masterDetailsParsedCards,
        containerIdentificationNumberValue,
        'Container Identification Number',
        () =>
            containerIdentificationNumberValue?.forEach((container: string, index) => {
                fields.push({ name: `Container Identification Number ${index}`, message: container });
            })
    );
    if (fields.length > 0) {
        masterDetailsParsedCards.push({ name: `Container Identification Numbers`, fields });
    }
};

const getGoodsLocation = (goodsLocation: GoodsLocation, field: string) => {
    const fields: ParsedField[] = [];

    const identificationOfLocationPath = 'identificationOfLocationPath';
    const identificationOfLocationValue = get(goodsLocation, identificationOfLocationPath);
    if (identificationOfLocationValue) {
        fields.push({
            name: `${field} identification of location`,
            message: identificationOfLocationValue,
            path: identificationOfLocationPath,
        });
    }

    const qualifierOfTheIdentificationPath = 'qualifierIdentification';
    const qualifierOfTheIdentificationValue = get(goodsLocation, qualifierOfTheIdentificationPath);
    if (qualifierOfTheIdentificationValue) {
        fields.push({
            name: `${field}  qualifier of the identification`,
            message: qualifierOfTheIdentificationValue,
            path: qualifierOfTheIdentificationPath,
        });
    }

    const additionalIdentifierPath = 'additionalIdentifier';
    const additionalIdentifierValue = get(goodsLocation, additionalIdentifierPath);
    if (additionalIdentifierValue) {
        fields.push({
            name: `${field}  additional Identifier`,
            message: additionalIdentifierValue,
            path: additionalIdentifierPath,
        });
    }

    const typeOfLocationCodePath = 'identificationOfLocation';
    const typeOfLocationCodeValue = get(goodsLocation, typeOfLocationCodePath);
    if (typeOfLocationCodeValue) {
        fields.push({
            name: `${field}  type of location code`,
            message: typeOfLocationCodeValue,
            path: typeOfLocationCodePath,
        });
    }

    const streetAndNumberPath = 'address.addressLine1';
    const streetAndNumberValue = get(goodsLocation, streetAndNumberPath);
    if (streetAndNumberValue) {
        fields.push({ name: `${field} street and number`, message: streetAndNumberValue, path: streetAndNumberPath });
    }

    const cityPath = 'address.city';
    const cityValue = get(goodsLocation, cityPath);
    if (cityValue) {
        fields.push({ name: `${field} city`, message: cityValue, path: cityPath });
    }

    const postCodePath = 'address.postCode';
    const postCodeValue = get(goodsLocation, postCodePath);
    if (postCodeValue) {
        fields.push({ name: `${field} post code`, message: postCodeValue, path: postCodePath });
    }

    const countryPath = 'address.country';
    const countryValue = get(goodsLocation, countryPath);
    if (countryValue) {
        fields.push({ name: `${field} country`, message: countryValue, path: countryPath });
    }

    return fields;
};

const parseDeclarationItems = (parsedItemDetails: ParsedItem[], declaration: IrelandImportDeclaration) => {
    const itemsPath = 'goodsShipment.goodsShipmentItem';
    const itemsValue = (get(declaration, itemsPath) as unknown as any[]) || [];

    itemsValue?.forEach((item: GoodsShipmentItem, index) => {
        const itemValues = {
            index: index + 1,
            errors: [],
        };

        parseItemsGoodsInformation(itemValues.errors, item);
        parseItemDetails(itemValues.errors, item);
        parseItemPackages(itemValues.errors, item);
        parseItemValueDetails(itemValues.errors, item);
        parseItemOtherDetails(itemValues.errors, item);
        parseExtraItemDetails(itemValues.errors, item);
        parseItemExporter(itemValues.errors, item);
        parseItemSeller(itemValues.errors, item);
        parseItemBuyer(itemValues.errors, item);
        parseItemSimplifiedDeclarationDocumentWritingOffHeader(itemValues.errors, item);
        parseItemAdditionalInformation(itemValues.errors, item);
        parseItemAdditionalConditionalInformation(itemValues.errors, item);

        if (itemValues.errors.length > 0) {
            parsedItemDetails.push(itemValues);
        }
    });
};

export const parseH1ProductTemplate = (validation: GoodsShipment) => {
    const productTemplateErrors: ParsedCard[] = [];

    parseItemsGoodsInformation(productTemplateErrors, validation);
    parseItemDetails(productTemplateErrors, validation);
    parseItemPackages(productTemplateErrors, validation);
    parseItemValueDetails(productTemplateErrors, validation);
    parseItemOtherDetails(productTemplateErrors, validation);
    parseExtraItemDetails(productTemplateErrors, validation);
    parseItemExporter(productTemplateErrors, validation);
    parseItemSeller(productTemplateErrors, validation);
    parseItemBuyer(productTemplateErrors, validation);
    parseItemSimplifiedDeclarationDocumentWritingOffHeader(productTemplateErrors, validation);
    parseItemAdditionalInformation(productTemplateErrors, validation);
    parseItemAdditionalConditionalInformation(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseItemsGoodsInformation = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem | undefined) => {
    const fields: ParsedField[] = [];

    const commodityCodePath = 'goodsInformation.combinedNomenclatureCode';
    const commodityCodeValue = get(item, commodityCodePath);
    if (commodityCodeValue) {
        fields.push({
            name: `Commodity Code`,
            message: commodityCodeValue,
            path: commodityCodePath,
        });
    }

    const taricCodePath = 'goodsInformation.taricCode';
    const taricCodeValue = get(item, taricCodePath);
    if (taricCodeValue) {
        fields.push({ name: `Taric Code`, message: taricCodeValue, path: taricCodePath });
    }

    const taricAdditionalCodePath = 'goodsInformation.taricAdditionalCode';
    const taricAdditionalCodeValue = get(item, taricAdditionalCodePath);
    if (taricAdditionalCodeValue) {
        fields.push({
            name: `TARIC additional code`,
            message: taricAdditionalCodeValue,
            path: taricAdditionalCodePath,
        });
    }

    const commodityNationalCodePath = 'goodsInformation.nationalAdditionalCommodityCode';
    const commodityNationalCodeValue = get(item, commodityNationalCodePath);
    if (commodityNationalCodeValue) {
        fields.push({
            name: `Commodity National Code`,
            message: commodityNationalCodeValue,
            path: commodityNationalCodePath,
        });
    }

    const supplementaryUnitsPath = 'goodsInformation.supplementaryUnits';
    const supplementaryUnitsValue = get(item, supplementaryUnitsPath);
    if (supplementaryUnitsValue) {
        fields.push({
            name: `Supplementary Units`,
            message: supplementaryUnitsValue,
            path: supplementaryUnitsPath,
        });
    }

    const descriptionOfGoodsPath = 'goodsInformation.goodsDescription';
    const descriptionOfGoodsValue = get(item, descriptionOfGoodsPath);
    if (descriptionOfGoodsValue) {
        fields.push({
            name: `Description of Goods`,
            message: descriptionOfGoodsValue,
            path: descriptionOfGoodsPath,
        });
    }

    const commodityCondition1Path = 'producedDocumentsWritingOff.commodityCondition1';
    const commodityCondition1Value = get(item, commodityCondition1Path);
    if (commodityCondition1Value) {
        fields.push({
            name: `Commodity condition 1`,
            message: commodityCondition1Value,
            path: commodityCondition1Path,
        });
    }

    const commodityCondition2Path = 'goodsInformation.commodityCondition2';
    const commodityCondition2Value = get(item, commodityCondition2Path);
    if (commodityCondition2Value) {
        fields.push({
            name: `Commodity condition 2`,
            message: commodityCondition2Value,
            path: commodityCondition2Path,
        });
    }

    const vatRatePath = 'vatRate';
    const vatRateValue = get(item, vatRatePath);
    if (vatRateValue) {
        fields.push({ name: `Vat Rate`, message: vatRateValue, path: vatRatePath });
    }

    const dutyRatePath = 'dutyRate';
    const dutyRateValue = get(item, dutyRatePath);
    if (dutyRateValue) {
        fields.push({ name: `Duty Rate`, message: dutyRateValue, path: dutyRatePath });
    }

    const invoiceNumberPath = 'producedDocumentsWritingOff.N935.[0]';
    const invoiceNumberValue = get(item, invoiceNumberPath);
    if (invoiceNumberValue) {
        fields.push({
            name: `Invoice Number`,
            message: invoiceNumberValue,
            path: invoiceNumberPath,
        });
    }

    const preferencePath = 'preference';
    const preferenceValue = get(item, preferencePath);
    if (preferenceValue) {
        fields.push({
            name: `Preference Code`,
            message: preferenceValue,
            path: preferencePath,
        });
    }

    if (fields.length > 0) {
        parsedItemDetails.push({ name: 'Goods Information', fields });
    }
};

const parseItemDetails = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const fields: ParsedField[] = [];

    const countryOfOriginPath = 'countryOrigin';
    const countryOfOriginValue = get(item, countryOfOriginPath);
    if (countryOfOriginValue) {
        fields.push({
            name: `Country origin`,
            message: countryOfOriginValue,
            path: countryOfOriginPath,
        });
    }

    const grossMassPath = 'goodsInformation.grossMass';
    const grossMassValue = get(item, grossMassPath);
    if (grossMassValue) {
        fields.push({ name: `Gross Mass`, message: grossMassValue, path: grossMassPath });
    }

    const netMassPath = 'goodsInformation.netMass';
    const netMassValue = get(item, netMassPath);
    if (netMassValue) {
        fields.push({ name: `Net Mass`, message: netMassValue, path: netMassPath });
    }

    const procedureCodePath = 'procedureCode';
    const procedureCodeValue = get(item, procedureCodePath);
    if (procedureCodeValue) {
        fields.push({
            name: `Procedure Code`,
            message: procedureCodeValue,
            path: procedureCodePath,
        });
    }

    const previousProcedureCodePath = 'previousProcedureCode';
    const previousProcedureCodeValue = get(item, previousProcedureCodePath);
    if (previousProcedureCodeValue) {
        fields.push({
            name: `Previous Procedure Code`,
            message: previousProcedureCodeValue,
            path: previousProcedureCodePath,
        });
    }

    const additionalProcedureCodeArrayPath = 'additionalProcedure';
    const additionalProcedureCodeArrayValue = get(item, additionalProcedureCodeArrayPath) as unknown as any[];
    handleMultiple(parsedItemDetails, additionalProcedureCodeArrayValue, 'Additional Procedure Code', () => {
        additionalProcedureCodeArrayValue?.forEach((procedure: any) => {
            const additionalProcedureCodePath = 'additionalProcedure';
            const additionalProcedureCodeValue = get(procedure, additionalProcedureCodePath);
            if (additionalProcedureCodeValue) {
                fields.push({
                    name: `Additional Procedure Code`,
                    message: additionalProcedureCodeValue,
                    path: additionalProcedureCodePath,
                });
            }
        });
    });

    if (fields.length > 0) {
        parsedItemDetails.push({ name: 'Item Details', fields });
    }
};

const parseItemPackages = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const packagesPath = 'goodsInformation.packaging';
    const packagesValue = get(item, packagesPath) as unknown as any[];

    handleMultiple(parsedItemDetails, packagesValue, 'Packages', () =>
        packagesValue?.forEach((pack: Packages, index: number) => {
            const fields: ParsedField[] = [];

            const packageTypeCodePath = 'typePackage';
            const packageTypeCodeValue = get(pack, packageTypeCodePath);
            if (packageTypeCodeValue) {
                fields.push({
                    name: `Package Type Code`,
                    message: packageTypeCodeValue,
                    path: packageTypeCodePath,
                });
            }

            const numberOfPackagesPath = 'packageNumber';
            const numberOfPackagesValue = get(pack, numberOfPackagesPath);
            if (numberOfPackagesValue) {
                fields.push({
                    name: `Number of Packages`,
                    message: numberOfPackagesValue,
                    path: numberOfPackagesPath,
                });
            }

            const shippingMarksPath = 'shippingMarks';
            const shippingMarksValue = get(pack, shippingMarksPath);
            if (shippingMarksValue) {
                fields.push({
                    name: `Shipping Marks`,
                    message: shippingMarksValue,
                    path: shippingMarksPath,
                });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Packages ${index + 1}`, fields });
            }
        })
    );
};

const parseItemValueDetails = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const fields: ParsedField[] = [];

    const itemPricePath = 'itemAmount';
    const itemPriceValue = get(item, itemPricePath);
    if (itemPriceValue) {
        fields.push({ name: `Item Price`, message: itemPriceValue, path: itemPricePath });
    }

    const valuationMethodPath = 'valuationMethod';
    const valuationMethodValue = get(item, valuationMethodPath);
    if (valuationMethodValue) {
        fields.push({
            name: `Valuation Method`,
            message: valuationMethodValue,
            path: valuationMethodPath,
        });
    }

    const valuationIndicator1Path = 'valuationIndicator1';
    const valuationIndicator1Value = get(item, valuationIndicator1Path);
    if (valuationIndicator1Value) {
        fields.push({
            name: `Valuation Indicator 1`,
            message: valuationIndicator1Value,
            path: valuationIndicator1Path,
        });
    }

    const valuationIndicator2Path = 'valuationIndicator2';
    const valuationIndicator2Value = get(item, valuationIndicator2Path);
    if (valuationIndicator2Value) {
        fields.push({
            name: `Valuation Indicator 2`,
            message: valuationIndicator2Value,
            path: valuationIndicator2Path,
        });
    }

    const valuationIndicator3Path = 'valuationIndicator3';
    const valuationIndicator3Value = get(item, valuationIndicator3Path);
    if (valuationIndicator3Value) {
        fields.push({
            name: `Valuation Indicator 3`,
            message: valuationIndicator3Value,
            path: valuationIndicator3Path,
        });
    }

    const valuationIndicator4Path = 'valuationIndicator4';
    const valuationIndicator4Value = get(item, valuationIndicator4Path);
    if (valuationIndicator4Value) {
        fields.push({
            name: `Valuation Indicator 4`,
            message: valuationIndicator4Value,
            path: valuationIndicator4Path,
        });
    }

    if (fields.length > 0) {
        parsedItemDetails.push({ name: 'Value Details', fields });
    }
};

const parseItemOtherDetails = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const fields: ParsedField[] = [];

    const statisticalValuePath = 'statisticalValue';
    const statisticalValue = get(item, statisticalValuePath);
    if (statisticalValue) {
        fields.push({
            name: `Statistical Value`,
            message: statisticalValue,
            path: statisticalValuePath,
        });
    }

    if (fields.length > 0) {
        parsedItemDetails.push({ name: 'Other Details', fields });
    }
};

const parseExtraItemDetails = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem | undefined) => {
    const fields: ParsedField[] = [];

    const cusCodePath = 'goodsInformation.cusCode';
    const cusCodeValue = get(item, cusCodePath);
    if (cusCodeValue) {
        fields.push({ name: `CUS code`, message: cusCodeValue, path: cusCodePath });
    }

    const typeOfGoodsPath = 'goodsInformation.typeGoods';
    const typeOfGoodsValue = get(item, typeOfGoodsPath);
    if (typeOfGoodsValue) {
        fields.push({
            name: `Type of goods`,
            message: typeOfGoodsValue,
            path: typeOfGoodsPath,
        });
    }

    const ucrPath = 'ucr';
    const ucrValue = get(item, ucrPath);
    if (ucrValue) {
        fields.push({ name: `UCR`, message: ucrValue, path: ucrPath });
    }

    const calculationOfTaxesTotalPath = 'taxTotalAmount';
    const calculationOfTaxesTotalValue = get(item, calculationOfTaxesTotalPath);
    if (calculationOfTaxesTotalValue) {
        fields.push({
            name: `Calculation of taxes - Total`,
            message: calculationOfTaxesTotalValue,
            path: calculationOfTaxesTotalPath,
        });
    }

    const valuationInformationCurrencyPath = 'valuationInformationCurrency';
    const valuationInformationCurrencyValue = get(item, valuationInformationCurrencyPath);
    if (valuationInformationCurrencyValue) {
        fields.push({
            name: `Valuation information currency`,
            message: valuationInformationCurrencyValue,
            path: valuationInformationCurrencyPath,
        });
    }

    const valuationInformationAmountPath = 'valuationInformationAmount';
    const valuationInformationAmountValue = get(item, valuationInformationAmountPath);
    if (valuationInformationAmountValue) {
        fields.push({
            name: `Valuation information amount`,
            message: valuationInformationAmountValue,
            path: valuationInformationAmountPath,
        });
    }

    const countryDestinationPath = 'countryDestination';
    const countryDestinationValue = get(item, countryDestinationPath);
    if (countryDestinationValue) {
        fields.push({
            name: `Country destination`,
            message: countryDestinationValue,
            path: countryDestinationPath,
        });
    }

    const regionDestinationPath = 'regionDestination';
    const regionDestinationValue = get(item, regionDestinationPath);
    if (regionDestinationValue) {
        fields.push({
            name: `Region destination`,
            message: regionDestinationValue,
            path: regionDestinationPath,
        });
    }

    const countryDispatchPath = 'countryDispatch';
    const countryDispatchValue = get(item, countryDispatchPath);
    if (countryDispatchValue) {
        fields.push({
            name: `Country dispatch`,
            message: countryDispatchValue,
            path: countryDispatchPath,
        });
    }

    const countryOfPreferentialOriginCodePath = 'countryPreferentialOrigin';
    const countryOfPreferentialOriginCodeValue = get(item, countryOfPreferentialOriginCodePath);
    if (countryOfPreferentialOriginCodeValue) {
        fields.push({
            name: `Country of preferential origin code`,
            message: countryOfPreferentialOriginCodeValue,
            path: countryOfPreferentialOriginCodePath,
        });
    }

    const dateOfAcceptancePath = 'acceptanceDate';
    const dateOfAcceptanceValue = get(item, dateOfAcceptancePath);
    if (item?.acceptanceDate) {
        fields.push({
            name: `Date of acceptance`,
            message: dateOfAcceptanceValue,
            path: dateOfAcceptancePath,
        });
    }

    const quotaOrderNumberPath = 'quotaOrderNumber';
    const quotaOrderNumberValue = get(item, quotaOrderNumberPath);
    if (quotaOrderNumberValue) {
        fields.push({
            name: `Quota Order Number`,
            message: quotaOrderNumberValue,
            path: quotaOrderNumberPath,
        });
    }

    const natureOfTransactionPath = 'transactionNature';
    const natureOfTransactionValue = get(item, natureOfTransactionPath);
    if (natureOfTransactionValue) {
        fields.push({
            name: `Nature of transaction`,
            message: natureOfTransactionValue,
            path: natureOfTransactionPath,
        });
    }

    if (fields.length > 0) {
        parsedItemDetails.push({ name: 'Extra item details', fields });
    }
};

const parseItemExporter = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem | undefined) => {
    const exporterPath = 'exporter';
    const exporterValue = get(item, exporterPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(exporterValue, 'Exporter', exporterPath);
    if (fields.length === 0) return;
    addToParties(parsedItemDetails, fields);
};

const parseItemSeller = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem | undefined) => {
    const sellerPath = 'seller';
    const sellerValue = get(item, sellerPath) as any;

    const fields: ParsedField[] = parseDeclarationCustomer(sellerValue, 'Seller', sellerPath);
    if (fields.length === 0) return;
    addToParties(parsedItemDetails, fields);
};

const parseItemBuyer = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem | undefined) => {
    const buyerPath = 'buyer';
    const buyerValue = get(item, buyerPath) as any;
    const fields: ParsedField[] = parseDeclarationCustomer(buyerValue, 'Buyer', buyerPath);
    if (fields.length === 0) return;
    addToParties(parsedItemDetails, fields);
};

const parseItemSimplifiedDeclarationDocumentWritingOffHeader = (
    parsedItemDetails: ParsedCard[],
    item: GoodsShipmentItem | undefined
) => {
    const simplifiedDeclarationDocumentWritingOffHeaderPath = 'simplifiedDeclarationDocumentWritingOff';
    const simplifiedDeclarationDocumentWritingOffHeaderValue = get(
        item,
        simplifiedDeclarationDocumentWritingOffHeaderPath
    ) as unknown as any[];

    handleMultiple(
        parsedItemDetails,
        simplifiedDeclarationDocumentWritingOffHeaderValue,
        'Simplified Declaration Document Writing Off Header',
        () =>
            simplifiedDeclarationDocumentWritingOffHeaderValue?.forEach(
                (item: SimplifiedDeclarationDocumentWritingOffItem, index: number) => {
                    const fields: ParsedField[] = [];

                    const previousDocumentTypePath = 'previousDocumentType';
                    const previousDocumentTypeValue = get(item, previousDocumentTypePath);
                    if (previousDocumentTypeValue) {
                        fields.push({
                            name: `Previous document type`,
                            message: previousDocumentTypeValue,
                            path: previousDocumentTypePath,
                        });
                    }

                    const previousDocumentIdentifierPath = 'previousDocumentIdentifier';
                    const previousDocumentIdentifierValue = get(item, previousDocumentIdentifierPath);
                    if (previousDocumentIdentifierValue) {
                        fields.push({
                            name: `Previous document identifier`,
                            message: previousDocumentIdentifierValue,
                            path: previousDocumentIdentifierPath,
                        });
                    }

                    const goodsItemIdentifierPath = 'previousDocumentLineId';
                    const goodsItemIdentifierValue = get(item, goodsItemIdentifierPath);
                    if (goodsItemIdentifierValue) {
                        fields.push({
                            name: `Goods Item Identifier`,
                            message: goodsItemIdentifierValue,
                            path: goodsItemIdentifierPath,
                        });
                    }

                    const measurementUnitAndQualifierPath = 'measurementUnitAndQualifier';
                    const measurementUnitAndQualifierValue = get(item, measurementUnitAndQualifierPath);
                    if (measurementUnitAndQualifierValue) {
                        fields.push({
                            name: `Measurement unit and qualifier`,
                            message: measurementUnitAndQualifierValue,
                            path: measurementUnitAndQualifierPath,
                        });
                    }

                    const quantityPath = 'measurementUnitAndQualifier';
                    const quantityValue = get(item, quantityPath);
                    if (quantityValue) {
                        fields.push({
                            name: `Quantity`,
                            message: quantityValue,
                            path: quantityPath,
                        });
                    }

                    const packageTypeCodePath = 'typeOfPackages';
                    const packageTypeCodeValue = get(item, packageTypeCodePath);
                    if (packageTypeCodeValue) {
                        fields.push({
                            name: `Package Type Code`,
                            message: packageTypeCodeValue,
                            path: packageTypeCodePath,
                        });
                    }

                    const numberOfPackagesPath = 'numberOfPackages';
                    const numberOfPackagesValue = get(item, numberOfPackagesPath);
                    if (numberOfPackagesValue) {
                        fields.push({
                            name: `Number of Packages`,
                            message: numberOfPackagesValue,
                            path: numberOfPackagesPath,
                        });
                    }

                    if (fields.length > 0) {
                        parsedItemDetails.push({
                            name: `Simplified Declaration Document Writing Off Header ${index + 1}`,
                            fields,
                        });
                    }
                }
            )
    );
};

const parseItemAdditionalInformation = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const additionalInformationPath = 'additionalInformation';
    const additionalInformationValue = get(item, additionalInformationPath) as unknown as any[];
    handleMultiple(parsedItemDetails, additionalInformationValue, 'Additional Information', () =>
        additionalInformationValue?.forEach((additionalInformation: AdditionalInformation, index: number) => {
            const fields: ParsedField[] = [];

            const codePath = 'additionalInformationCode';
            const codeValue = get(additionalInformation, codePath);
            if (codeValue) {
                fields.push({ name: `Code`, message: codeValue, path: codePath });
            }

            const textPath = 'additionalInformationText';
            const textValue = get(additionalInformation, textPath);
            if (textValue) {
                fields.push({ name: `Text`, message: textValue, path: textPath });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Additional Information ${index + 1}`, fields });
            }
        })
    );
};

const parseItemAdditionalConditionalInformation = (parsedItemDetails: ParsedCard[], item: GoodsShipmentItem) => {
    const supplyChainActorPath = 'supplyChainActor';
    const supplyChainActorValue = get(item, supplyChainActorPath) as unknown as any[];
    handleMultiple(parsedItemDetails, supplyChainActorValue, 'Supply Chain Actor', () =>
        supplyChainActorValue?.forEach((supplyChainActor: SupplyChainActor, index) => {
            const fields: ParsedField[] = [];

            const roleCodePath = 'roleCode';
            const roleCodeValue = get(supplyChainActor, roleCodePath);
            if (supplyChainActor?.roleCode) {
                fields.push({ name: `Role code`, message: roleCodeValue, path: roleCodePath });
            }

            const traderIdentificationPath = 'traderIdentification';
            const traderIdentificationValue = get(supplyChainActor, traderIdentificationPath);
            if (traderIdentificationValue) {
                fields.push({
                    name: `Trader identification`,
                    message: traderIdentificationValue,
                    path: traderIdentificationPath,
                });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Supply Chain Actor ${index + 1}`, fields });
            }
        })
    );

    const additionsDeductionsPath = 'additionsDeductions';
    const additionsDeductionsValue = get(item, additionsDeductionsPath) as unknown as any[];
    handleMultiple(parsedItemDetails, additionsDeductionsValue, 'Additions and Deductions', () =>
        additionsDeductionsValue?.forEach((additionsAndDeductions: AdditionsDeductions, index) => {
            const fields: ParsedField[] = [];

            const codePath = 'additionsDeductionsCode';
            const codeValue = get(additionsAndDeductions, codePath);
            if (codeValue) {
                fields.push({ name: `Code`, message: codeValue, path: codePath });
            }

            const amountPath = 'amount';
            const amountValue = get(additionsAndDeductions, amountPath);
            if (amountValue) {
                fields.push({ name: `Amount`, message: amountValue, path: amountPath });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Additions and Deductions ${index + 1}`, fields });
            }
        })
    );

    const additionalFiscalReferencePath = 'additionalFiscalReference';
    const additionalFiscalReferenceValue = get(item, additionalFiscalReferencePath) as unknown as any[];
    handleMultiple(parsedItemDetails, additionalFiscalReferenceValue, 'Additional Fiscal Reference', () =>
        additionalFiscalReferenceValue?.forEach((fiscalReference: AdditionalFiscalReference, index) => {
            const fields: ParsedField[] = [];

            const referenceVatPath = 'roleCode';
            const referenceVatValue = get(fiscalReference, referenceVatPath);
            if (referenceVatValue) {
                fields.push({
                    name: `Reference VAT`,
                    message: referenceVatValue,
                    path: referenceVatPath,
                });
            }

            const referenceIdPath = 'vatNumber';
            const referenceIdValue = get(fiscalReference, referenceIdPath);
            if (referenceIdValue) {
                fields.push({
                    name: `Reference Id`,
                    message: referenceIdValue,
                    path: referenceIdPath,
                });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Additional Fiscal Reference ${index + 1}`, fields });
            }
        })
    );

    const additionalTaxesPath = 'taxBox43Bis';
    const additionalTaxesValue = get(item, additionalTaxesPath) as unknown as any[];
    handleMultiple(parsedItemDetails, additionalTaxesValue, 'Additional Taxes', () =>
        additionalTaxesValue?.forEach((tax: TaxBox, index) => {
            const fields: ParsedField[] = [];

            const taxTypePath = 'boxTaxType';
            const taxTypeValue = get(tax, taxTypePath);
            if (taxTypeValue) {
                fields.push({ name: `Tax Type`, message: taxTypeValue, path: taxTypePath });
            }

            const baseAmountPath = 'boxAmount';
            const baseAmountValue = get(tax, baseAmountPath);
            if (baseAmountValue) {
                fields.push({
                    name: `Base Amount`,
                    message: baseAmountValue,
                    path: baseAmountPath,
                });
            }

            const taxRatePath = 'boxTaxRate';
            const taxRateValue = get(tax, taxRatePath);
            if (taxRateValue) {
                fields.push({ name: `Tax Rate`, message: taxRateValue, path: taxRatePath });
            }

            const amountOfTaxPayablePath = 'boxTaxPayableAmount';
            const amountOfTaxPayableValue = get(tax, amountOfTaxPayablePath);
            if (amountOfTaxPayableValue) {
                fields.push({
                    name: `Amount of Tax Payable`,
                    message: amountOfTaxPayableValue,
                    path: amountOfTaxPayablePath,
                });
            }

            const paymentMethodPath = 'boxTaxPaymentMethod';
            const paymentMethodValue = get(tax, paymentMethodPath);
            if (paymentMethodValue) {
                fields.push({
                    name: 'Payment Method',
                    message: paymentMethodValue,
                    path: paymentMethodPath,
                });
            }

            if (fields.length > 0) {
                parsedItemDetails.push({ name: `Additional Taxes ${index + 1}`, fields });
            }
        })
    );

    const fields: ParsedField[] = [];

    const containerIdentificationNumberPath = 'containerIdentificationNumber';
    const containerIdentificationNumberValue = get(item, containerIdentificationNumberPath) as unknown as any[];

    if (!isEmpty(containerIdentificationNumberValue)) {
        fields.push({
            name: `Container Identification Number`,
            message: containerIdentificationNumberValue,
            path: containerIdentificationNumberPath,
        });
    }

    if (fields.length > 0) {
        parsedItemDetails.push({ name: `Container Identification Numbers`, fields });
    }
};
