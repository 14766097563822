import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    StyledNumberInput,
} from './FormInput.styles';

export interface SelectOptionProps {
    [index: string]: string;
}

export interface FormNumberInputProps {
    label?: string;
    children?: ReactNode;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    maxLength?: number;
}

const FormNumberInput: FC<FormNumberInputProps> = ({
    label,
    fieldMeta,
    fieldProps,
    fieldHelper,
    required,
    maxLength,
}) => {
    const { t } = useTranslation('common');

    useEffect(() => {
        if (fieldProps.value === '' && fieldHelper) {
            fieldHelper.setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.value]);

    /* 
    useMemo(() => {
        if (maxLength && fieldProps && fieldProps.value) {
            const len = fieldProps.value.toString();
            if (+len > +maxLength) {
                fieldHelper?.setValue(+fieldProps.value.toString().slice(0, maxLength));
            }
        }
    }, [fieldProps.value]); */

    return (
        <>
            <LabelDiv>
                {label && <InputLabel>{label}</InputLabel>}
                {required && <InputLabelRequired>{t('*')}</InputLabelRequired>}
            </LabelDiv>
            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <InputDiv>
                    <StyledNumberInput {...fieldProps}></StyledNumberInput>
                </InputDiv>
                <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                    {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                </ErrorDiv>
            </FormItem>
        </>
    );
};

export default FormNumberInput;
