import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormEori from 'components/ui/composed/formEori/FormEori';
import useCodelists from 'hooks/useCodelists';
import { FocusEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { addToItemOfUcc } from '../../../../common/parties/PartiesCard';
import { validators } from '../../validations/validations';

type EoriFields = 'id' | 'functionCode';

interface Props extends BlockProps<EoriFields> {
    path: string | null;
    options: {
        ucc: string;
    };
    condensed?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

const EoriBlock = ({ path, fieldOptions, options, condensed, onBlur }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormEori
                        declaration
                        required={fieldOptions?.id?.required}
                        {...getFormikProps(field.name, form)}
                        refNumber={addToItemOfUcc(options.ucc, 1)}
                        label={t(fieldOptions?.id?.label ?? t('EORI'))}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            {fieldOptions?.functionCode?.show && (
                <DeclarationField pathPrefix={path} name="functionCode">
                    {({ form, field }) => (
                        <FormSelect
                            {...getFormikProps(field.name, form)}
                            refNumber={addToItemOfUcc(options.ucc, 2)}
                            label={t('statusCode')}
                            selectOptions={cdsCodelists?.partyRoleStatusTypes}
                            condensed={condensed}
                            onChange={onBlur}
                            codeValidation={[validators.number(), validators.exact(1)]}
                        />
                    )}
                </DeclarationField>
            )}
        </>
    );
};

export default EoriBlock;
