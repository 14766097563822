import { TableRowSelection } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';
import { declarationHasBeenSubmitted } from '../../declarations/utils/form-utils';
import dashboardColumns, { declarationColumnsCoreTemplate, declarationColumsArchived } from './DeclarationColumns';
import { TablePaginationConfig } from 'antd';

export enum DeclarationTableType {
    ARCHIVED = 'ARCHIVED',
    CORE_TEMPLATE = 'CORE_TEMPLATE',
    DECLARATIONS = 'DECLARATIONS',
    SELECTION = 'SELECTION',
}

interface Props {
    data: ListPayload<DeclarationAndAgent>;
    handleDuplicate?: (declaration: Declaration) => void;
    onMirror?: (declaration: Declaration) => void;
    onUnarchive?: (ids: string[]) => void;
    onArchive?: (ids: string[]) => void;
    loading?: boolean;
    type: DeclarationTableType;
    handleCoreTemplate?: (id: string) => void;
    viewDocuments?: (id: string) => void;
    setAsTemplate?: (id: string, type: DeclarationTableType) => void;
    onSelect?: (record: Declaration) => void;
    onPrint?: (declarationId: string) => void;
    onDelete?: (declarationId: string) => void;
    onChange?: (pagination: TablePaginationConfig) => void;
}

const DeclarationTable: FC<Props> = ({
    data,
    handleDuplicate,
    onMirror: handleMirror,
    onArchive,
    onUnarchive,
    loading,
    handleCoreTemplate,
    viewDocuments,
    setAsTemplate,
    type,
    onSelect,
    onPrint,
    onDelete,
    onChange,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const showingArchivedDeclarations = useMemo(() => location.pathname.includes('archived'), [location.pathname]);

    const getInvalidStyle = useCallback((declaration: Declaration) => {
        return declaration.status === DeclarationStatus.REJECTED || declaration.status === DeclarationStatus.INVALID
            ? { style: { background: `${colors.invalid}`, border: 'none' } }
            : {};
    }, []);

    const rowSelection: TableRowSelection<any> = {
        type: 'checkbox',
        renderCell: (value: boolean, record: DeclarationAndAgent, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(record), children: originNode };
        },
    };

    const handleColumnActions = () => {
        switch (type) {
            case DeclarationTableType.DECLARATIONS:
                if (showingArchivedDeclarations) {
                    return dashboardColumns({ onUnarchive });
                } else {
                    return dashboardColumns({
                        onDuplicate: handleDuplicate,
                        onArchive,
                        onPrint,
                        onMirror: handleMirror,
                        onDelete,
                    });
                }

            case DeclarationTableType.ARCHIVED: {
                return declarationColumsArchived(handleCoreTemplate, setAsTemplate, viewDocuments);
            }
            case DeclarationTableType.CORE_TEMPLATE:
                return declarationColumnsCoreTemplate(handleCoreTemplate, setAsTemplate, viewDocuments);
            case DeclarationTableType.SELECTION:
                return dashboardColumns({});
        }
    };

    return (
        <>
            <Table
                rowKey="id"
                columns={handleColumnActions()}
                dataSource={data?.list}
                rowSelection={type ? undefined : rowSelection}
                onRow={(record: Declaration) => {
                    return {
                        'data-testid': `declaration-table-row`,
                        onClick: (event) => {
                            event.stopPropagation();
                            if (onSelect && type === DeclarationTableType.SELECTION) {
                                onSelect(record);
                            } else if (declarationHasBeenSubmitted(record.status) || record.archived) {
                                navigate(`/declarations/${record.id}/view-only`);
                            } else if (record.status === DeclarationStatus.RELEASED) {
                                navigate(`/declarations/${record.id}/summary`);
                            } else {
                                navigate(`/declarations/${record.id}`);
                            }
                        },
                    };
                }}
                pagination={{
                    current: data?.pageNumber + 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                }}
                onChange={onChange}
                loading={loading}
            />
        </>
    );
};

export default DeclarationTable;
