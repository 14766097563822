import { createContext, ReactNode, useContext } from 'react';

interface Props {
    children: ReactNode;
    productId?: string;
    setProductId?: (id?: string) => void;
}

const ProductContextProvider = ({ children, productId, setProductId }: Props) => {
    return (
        <ProductContext.Provider value={{ productId: productId, setProductId: (value) => setProductId?.(value) }}>
            {children}
        </ProductContext.Provider>
    );
};

export default ProductContextProvider;

export const ProductContext = createContext<{ productId?: string; setProductId?: (id?: string) => void }>({});
export const useProductContext = () => useContext(ProductContext);
