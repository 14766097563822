import {
    getStringMaxMessage,
    getRequiredMessage,
    getNumberMaxMessage,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

export type ValidationObj<TKey extends string = any> = { [name in TKey]?: Validations | ValidationObj<TKey> };

export const ValidationType = {
    MIXED: 'mixed',
    BOOL: 'bool',
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
    OBJECT: 'object',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ValidationType = typeof ValidationType[keyof typeof ValidationType];

export interface Validations {
    type?: ValidationType | Yup.AnySchema;
    label?: string;
    required?: string | boolean;
    max?: number;
    min?: number;
    nullable?: boolean;
}

const getMaxMessage = (type: ValidationType) => {
    return type === ValidationType.STRING ? getStringMaxMessage : getNumberMaxMessage;
};

const transformValidations = (validations: ValidationObj) => {
    const shape: { [name: string]: any } = {};

    for (const [property, validation] of Object.entries(validations)) {
        // If the validation is an object, we need to transform it. For usability, we can skip the type property.
        if (!validation?.type || validation.type === ValidationType.OBJECT) {
            shape[property] = transformValidations(validation as unknown as ValidationObj);
            continue;
        }

        let yupMock =
            typeof validation.type === 'string' ? (Yup[validation.type as ValidationType] as any)() : validation.type;

        for (const [key, value] of Object.entries(validation)) {
            if (key === 'type') {
                continue;
            }

            if (value instanceof Array) {
                yupMock = yupMock[key](...value);
                continue;
            }

            switch (key as keyof Validations) {
                case 'max':
                    yupMock = yupMock[key](
                        value,
                        getMaxMessage(yupMock.type)((validation.label as string) ?? property, value)
                    );
                    break;
                // TODO Add min handle
                case 'required':
                    if (value === true)
                        yupMock = yupMock[key](getRequiredMessage((validation.label as string) ?? property));
                    break;
                case 'nullable':
                    if (value === true) yupMock = yupMock[key]();
                    break;
                default:
                    yupMock = yupMock[key](value);
                    break;
            }
        }

        yupMock = yupMock.template();

        shape[property] = yupMock;
    }

    return Yup.object().shape(shape);
};

export default transformValidations;
