import { cloneDeep, merge } from 'lodash';
import { irelandExportB1ValidationHeader, irelandExportB1ValidationItem } from './IrelandExportB1Validation';
import { validators } from 'views/declarations/uk/export/validations/validations';
import DeepPartial from 'types/DeepPartial';

const REMOVE = null as any;

// -- Item
const item: DeepPartial<typeof irelandExportB1ValidationItem> = {
    childValidators: {
        origin: {
            childValidators: {
                countryOfOrigin: [
                    validators.required(),
                    ...irelandExportB1ValidationItem.childValidators.origin.childValidators.countryOfOrigin,
                ],
            },
        },
    },
};
export const irelandExportB2ValidationItem = merge(cloneDeep(irelandExportB1ValidationItem), item);

// --- Header
const header: DeepPartial<typeof irelandExportB1ValidationHeader> = {
    childValidators: {
        goodsShipment: {
            childValidators: {
                consignment: {
                    childValidators: {
                        consignor: REMOVE,
                        consignee: {
                            childValidators: {
                                identificationNumber: [
                                    validators.required(),
                                    ...irelandExportB1ValidationHeader.childValidators.goodsShipment.childValidators
                                        .consignment.childValidators.consignee.childValidators.identificationNumber,
                                ],
                            },
                        },
                        departureTransportMeans: {
                            childValidators: {
                                identificationNumber: [REMOVE],
                                nationality: [REMOVE],
                            },
                        },
                        activeBorderTransportMeans: REMOVE,
                    },
                },
                goodsItem: irelandExportB2ValidationItem,
            },
        },
    },
};
export const irelandExportB2ValidationHeader = merge(cloneDeep(irelandExportB1ValidationHeader), header);
