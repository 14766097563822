import StatusTag from 'components/ui/base/status/StatusTag';
import { FC } from 'react';
import { LegStatus } from 'store/journeys/legs/leg';
import { colors } from 'theme';

export const legStatusColor = {
    [LegStatus.ASSIGNED]: colors.lightGreen,
    [LegStatus.COMPLETED]: colors.green,
    [LegStatus.FAILED]: colors.red,
    [LegStatus.IN_PROGRESS]: colors.powderBlue,
    [LegStatus.UNASSIGNED]: colors.grey,
};

export const legStatusLabel = {
    [LegStatus.ASSIGNED]: 'Assigned',
    [LegStatus.COMPLETED]: 'Completed',
    [LegStatus.FAILED]: 'Failed',
    [LegStatus.IN_PROGRESS]: 'In progress',
    [LegStatus.UNASSIGNED]: 'Unassigned',
};

const LegStatusTag: FC<{ status: LegStatus }> = ({ status }) => {
    return <StatusTag color={legStatusColor[status]} status={legStatusLabel[status]} />;
};

export default LegStatusTag;
