import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import useCodelists from 'hooks/useCodelists';
import HideComponent from '../../../../../utils/HideComponent';

export type Fields = 'id';

export const customsOfficeOfExitBlockValidation: {
    childValidators: {
        exitOffice: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        exitOffice: {
            childValidators: {
                id: [validators.required(), validators.exact(8)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const CustomsOfficeOfExitBlock = ({ path = 'exitOffice', hide }: Props): ReactElement => {
    const { cdsCodelists } = useCodelists();
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/12', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('customsOfficeOfExit')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.exitCustomsOffices}
                        condensed
                        codeValidation={[validators.exact(8)]}
                        getPopupContainer={() => document.body}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CustomsOfficeOfExitBlock;
