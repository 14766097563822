import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';

const useDebouncedValidate = (formik: FormikProps<any>) => {
    const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);
    useEffect(() => {
        debouncedValidate(formik.values);
    }, [formik.values, debouncedValidate]);
};

export default useDebouncedValidate;
