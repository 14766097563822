import { AxiosResponse } from 'axios';

export const parseFileName = (response: AxiosResponse<any, any>) => {
    const headerLine = response?.headers['content-disposition']?.split('filename=')[1];
    let startFileNameIndex = headerLine.indexOf('"') + 1;
    let endFileNameIndex = headerLine.lastIndexOf('"');
    const fileName = headerLine.substring(startFileNameIndex, endFileNameIndex);
    const file = fileName.split('.');
    return file.length < 2 ? { fileName } : { fileName: file[0], type: `.${file[1]}` };
};
