import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import PlaceOfUseForm from './PlaceOfUseForm';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { firstPlaceOfUse } from '../../../validation/ValidationSchema';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

const PlaceOfUseCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <MultipleItemsCard
            title={t('authorisation.placeOfUse')}
            keyCard={props.keyCard}
            viewOnly={props.viewOnly}
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            getFieldMeta={props.getFieldMeta}
            path={props.propsPathPrefix ?? 'authorisation.placeOfUse'}
            initialValues={{
                identificationOfLocation: '',
                qualifierIdentification: '',
                additionalIdentifier: '',
                locationTypeCode: '',
                'address.streetAndNumber': '',
                'address.country': '',
                'address.postCode': '',
                'address.city': '',
            }}
            validationSchema={firstPlaceOfUse}
            list={(list) => {
                return [
                    {
                        field: t('firstPlaceOfUse.identificationOfLocation'),
                        value: list.identificationOfLocation,
                    },
                    {
                        field: t('firstPlaceOfUse.qualifierIdentification'),
                        value: list.qualifierIdentification,
                    },
                    {
                        field: t('firstPlaceOfUse.additionalIdentifier'),
                        value: list.additionalIdentifier,
                    },
                    {
                        field: t('firstPlaceOfUse.locationTypeCode'),
                        value: list.locationTypeCode,
                    },
                    {
                        field: t('address.streetAndNumber', { ns: 'common' }),
                        value: (list as any).address?.streetAndNumber,
                    },
                    {
                        field: t('address.country', { ns: 'common' }),
                        value: (list as any).address?.country,
                    },
                    {
                        field: t('address.postcode', { ns: 'common' }),
                        value: (list as any).address?.postCode,
                    },
                    {
                        field: t('address.city', { ns: 'common' }),
                        value: (list as any).address?.city,
                    },
                ];
            }}
            defaultOpen={props.defaultOpen}
            condensed
            form={(form, path) => (
                <FormCardContainer>
                    <PlaceOfUseForm propsPathPrefix={path} {...form} />
                </FormCardContainer>
            )}
            refNumber="4.9"
        />
    );
};
export default PlaceOfUseCard;
