import { Reducer } from 'redux';
import { DeclarationFormErrorActions } from './actionType';
import { ParsedForm } from './ParsedForm';

export const initialState: ParsedForm = {
    masterDetails: [],
    items: [],
};

const declarationFormErrors: Reducer<ParsedForm> = (state = initialState, action: DeclarationFormErrorActions) => {
    switch (action.type) {
        case 'SET_DECLARATION_FORM_ERRORS':
            return action.errors;
        case 'CLEAR_DECLARATION_FORM_ERRORS':
            return initialState;
        default:
            return state;
    }
};

export default declarationFormErrors;
