import { Reducer } from 'redux';
import { ProductTemplateFormErrorActions } from './actionType';
import { ProductTemplateFormErrors } from './ProductTemplateFormErrors';

export const initialState: ProductTemplateFormErrors = {
    template: [],
};

const productTemplateFormErrors: Reducer<ProductTemplateFormErrors> = (
    state = initialState,
    action: ProductTemplateFormErrorActions
) => {
    switch (action.type) {
        case 'SET_PRODUCT_TEMPLATE_FORM_ERRORS':
            return action.errors;
        case 'CLEAR_PRODUCT_TEMPLATE_FORM_ERRORS':
            return initialState;
        default:
            return state;
    }
};

export default productTemplateFormErrors;
