import { deleteRoute, getRoute, postRoute, putRoute } from './client';
import { Route } from './route';

export const doCreateRoute: Function = (route:Route) => async (dispatch: Function) => {
    dispatch({ type: 'CREATE_ROUTE_REQUEST' });
    try {
        const payload = await postRoute(route);

        dispatch({
            type: 'CREATE_ROUTE_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'CREATE_ROUTE_ERROR', error: e?.response?.data });
        throw e;
    }
};

export const doGetRoute: Function = (routeId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_ROUTE_REQUEST' });
    try {
        const payload = await getRoute(routeId);
        dispatch({
            type: 'GET_ROUTE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_ROUTE_ERROR', error: e?.response?.data });
        return e?.response.data;
    }
};

export const doEditRoute: Function = (route:Route) => async (dispatch: Function) => {
    dispatch({ type: 'EDIT_ROUTE_REQUEST' });
    try {
        const payload = await putRoute(route);
        dispatch({
            type: 'EDIT_ROUTE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'EDIT_ROUTE_ERROR', error: e?.response?.data });
    }
};

export const doDeleteRoute: Function = (routeId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_ROUTE_REQUEST' });
    try {
        const payload = await deleteRoute(routeId);
        dispatch({
            type: 'DELETE_ROUTE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_ROUTE_ERROR', error: e?.response?.data });
        return e?.response?.data;
    }
};
