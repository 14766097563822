import { ListPayload } from 'core/http/response';
import { JobResponse } from 'store/jobs/job';
export const getDeclarationsFromJob = async (
    jobs: ListPayload<JobResponse>,
    listDeclarationsReq: Function,
    setDeclarationFromJobs: Function
) => {
    return await Promise.all(jobs.list.map(async (j) => (await listDeclarationsReq({ jobId: j.id }))?.list)).then(
        (j) => {
            const declarations = j.map((res) => {
                if (res?.length > 0) {
                    const jobId: string = res[0].jobId!;
                    return { [jobId]: [...res] };
                } else {
                    return {};
                }
            });
            setDeclarationFromJobs(Object.assign({}, ...declarations));
            return Object.assign({}, ...declarations);
        }
    );
};
