import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'identificationTypeCode' | 'id';

export const identityOfMeansOfTransportAtDepartureBlockValidation: {
    childValidators: {
        'goodsShipment.consignment.departureTransportMeans': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment.departureTransportMeans': {
            childValidators: {
                identificationTypeCode: [validators.number(), validators.exact(2)],
                id: [validators.maxLength(27)],
            },
        },
    },
};

export const identityOfMeansOfTransportOnArrivalBlockValidation: {
    childValidators: {
        'goodsShipment.consignment.arrivalTransportMeans': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment.arrivalTransportMeans': {
            childValidators: {
                identificationTypeCode: [validators.number(), validators.exact(2)],
                id: [validators.maxLength(35)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const IdentityOfMeansOfTransportBlock = ({ path, fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();
    const { formTypeForCds: formType } = useFormUtils();

    const ucc = useMemo(() => (formType?.includes('EXPORT') ? '7/7' : '7/9'), [formType]);
    return (
        <>
            <DeclarationField pathPrefix={path} name="identificationTypeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('transportIdentityMeansType')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.transportMeansIdentificationTypes}
                        condensed
                        codeValidation={[validators.number(), validators.exact(2)]}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('transportIdentityMeansId')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default IdentityOfMeansOfTransportBlock;
