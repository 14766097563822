import logo from 'assets/logo-login.svg';
import useSession from 'hooks/useSession';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { IndividualType } from 'store/individuals/individual';
import { AuthenticationContainer, AuthenticationFormDiv, ImageDivBackground } from 'views/authentication/Auth.styles';
import { GettingStartDiv } from './Invite.styles';
import AddTeamMembers from './sections/add-team-members/AddTeamMembers';
import BrokerFeatures from './sections/broker/BrokerFeatures';
import ChangePassword from './sections/common/ChangePassword';
import VideoIntro from './sections/common/VideoIntro';
import HaulierFeatures from './sections/haulier/HaulierFeatures';
import TraderFeatures from './sections/trader/TraderFeatures';

export enum OnboardingSection {
    CHANGE_PWD = 0,
    CHECKLIST = 1,
    INTRO = 2,
    ADD_TEAM_MEMBERS = 3,
}

export interface InvitePageProps {
    onChangePage: (page: OnboardingSection) => void;
}

const Invite: FC<{}> = () => {
    const { token } = useParams<{ token: string }>();
    const { isLogged, userInfo } = useSession();

    const [page, setPage] = useState<OnboardingSection>(OnboardingSection.CHANGE_PWD);

    const handleChangePage = useCallback(
        (page: OnboardingSection) => {
            if ((isLogged && page !== OnboardingSection.CHANGE_PWD) || page === OnboardingSection.CHANGE_PWD) {
                setPage(page);
            }
        },
        [isLogged]
    );

    const section = useMemo(() => {
        if (isLogged && userInfo) {
            switch (page) {
                case OnboardingSection.CHECKLIST: {
                    if (userInfo.role === IndividualType.TRADER_ADMIN || userInfo.role === IndividualType.TRADER_USER) {
                        return <TraderFeatures onChangePage={handleChangePage} />;
                    } else if (userInfo.role === IndividualType.HAULIER_ADMIN) {
                        return <HaulierFeatures onChangePage={handleChangePage} />;
                    } else if (userInfo.role === IndividualType.DRIVER) {
                        // TODO change to correct features
                        return <HaulierFeatures onChangePage={handleChangePage} />;
                    } else {
                        return <BrokerFeatures onChangePage={handleChangePage} />;
                    }
                }
                case OnboardingSection.INTRO:
                    return <VideoIntro onChangePage={handleChangePage} />;
                case OnboardingSection.ADD_TEAM_MEMBERS:
                    return <AddTeamMembers onChangePage={handleChangePage} />;
                default:
                    break;
            }
        }

        if (page === OnboardingSection.CHANGE_PWD) {
            return <ChangePassword token={token!} onChangePage={handleChangePage} />;
        }
    }, [page, isLogged, handleChangePage, token, userInfo]);

    return (
        <AuthenticationContainer>
            <ImageDivBackground pageName="invite" />
            <AuthenticationFormDiv>
                <GettingStartDiv>
                    <img src={logo} alt="DDispatch" />
                </GettingStartDiv>
                {section}
            </AuthenticationFormDiv>
        </AuthenticationContainer>
    );
};
export default Invite;
