export const DeclarationType = {
    IE_IMPORT_H1: 'irelandImportDeclaration',
    IE_IMPORT_H7: 'irelandH7ImportDeclaration',
    IE_EXPORT: 'ieExportDeclaration',
    IE_ARRIVAL: 'ieArrivalExitDeclarationResponse',
    IE_ENS: 'entrySummaryDeclaration',
    IE_ETD: 'irelandETDDeclaration',
    IE_TSD: 'irelandImportTemporaryStorageDeclaration',
    UK: 'cdsExportDeclaration',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DeclarationType = typeof DeclarationType[keyof typeof DeclarationType];
