import { FC } from 'react';
import Icon from '@ant-design/icons';

const LogoSmallSvg = () => (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.9513 25.1089H14.4263V21.1979H17.6605L11.9997 9.72844L6.33939 21.1979H9.57309V25.1089H0.048584L11.9997 0.891357L23.9513 25.1089Z"
            fill="white"
        />
    </svg>
);

const LogoSmall: FC<any> = (props) => <Icon component={LogoSmallSvg} {...props} />;

export default LogoSmall;
